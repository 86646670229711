import { observer } from "mobx-react-lite";

import { Button } from "../../../../../components/Button";
import { ServiceCard } from "../ServiceCard";
import Pagination from "../../../../../components/Pagintaion";
import { Loader } from "../../../../../components/Loader";

import { IMyServices } from "../storeUI/interfaces";
import { IPaginationData } from "../../../../../interfaces";

import { ETabsServices } from "../../../../../types";

import { ReactComponent as ServiceEmpty } from "../../../../../assets/images/serviceEmpty.svg";

import styles from "./styles/myServices.module.scss";

interface IProps {
  isLoading: boolean;
  services: IMyServices[] | null;
  paginationData: IPaginationData | null;
  toAvailableServices(): void;
}

export const MyServices = observer(
  ({
    isLoading = false,
    services = null,
    paginationData = null,
    toAvailableServices = () => {},
  }: IProps) => {
    return (
      <div className={styles.myServices}>
        {isLoading ? (
          <div className={styles.myServices__loadingBlock}>
            <Loader className={styles.myServices__mainLoader} />
          </div>
        ) : (
          <div>
            {services?.length ? (
              <>
                <div className={styles.myServices__cardsWrapper}>
                  {services.map((item) => (
                    <ServiceCard
                      typeTab={ETabsServices.MyServices}
                      id={item.id}
                      key={item.id}
                      image={item.image}
                      serviceTitle={item.name}
                      price={item.price}
                      dateOfUse={item.date}
                    />
                  ))}
                </div>

                {paginationData && (
                  <Pagination
                    onPageChange={paginationData.onPageChange}
                    totalCount={paginationData.totalCount}
                    siblingCount={paginationData.siblingCount}
                    currentPage={paginationData.currentPage}
                    pageSize={paginationData.pageSize}
                    className={styles.myServices__pagination}
                  />
                )}
              </>
            ) : (
              <div className={styles.myServices__cardsEmpty}>
                <ServiceEmpty />
                <p className={styles.myServices__cardsEmptyTitle}>
                  У вас пока нет активных услуг
                </p>
                <Button
                  title={"Выбрать услугу"}
                  onClick={toAvailableServices}
                  theme={"gold"}
                  className={styles.myServices__cardsEmptyBtn}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
