import { ApiManager } from "../..";

import { BILL } from "../../../constants/api";

import { IBill } from "../../../interfaces/Documents";

import { TResponseApi } from "../../types";

export async function getBills(params: {
  bookingId: number;
}): Promise<TResponseApi<IBill[]>> {
  return await ApiManager.request<IBill[]>(
    {
      url: BILL,
      method: "GET",
      params: params,
    },
    true
  );
}
