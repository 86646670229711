import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Loader } from "../../Loader";
import { Button } from "../../Button";
import { Hint } from "../../Hint";
import { ButtonSberBusiness } from "../../ButtonSberBusiness";
import { PaymentMethods } from "../../PaymentMethods";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { INFO_PAYMENT, TYPE_PERSON_INDIVIDUAL } from "../../../constants";

import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as CircleCross } from "../../../assets/images/yellowCircleCross.svg";

import { IPaymentMethod } from "../../../interfaces/Payment";

import { EPaymentMethods } from "../../../types";
import { Store } from "../../../stores/types";

import styles from "./styles/deposit.module.scss";

interface IProps {
  visible: boolean;
  methods: IPaymentMethod[];
  isLoading: boolean;
  isAuth: boolean;
  onClose(): void;
  onContinue(): void;
  checkIsAuth(): void;
  loginSberBusiness(): void;
  onSelectMethod(value: EPaymentMethods): void;
  selectedPaymentMethod: IPaymentMethod;
  storeUser: Store.IUser;
}

export const Deposit = observer(
  ({
    visible = false,
    methods = [],
    isLoading = false,
    isAuth = false,
    onContinue = () => {},
    checkIsAuth = () => {},
    onClose = () => {},
    loginSberBusiness = () => {},
    onSelectMethod = () => {},
    selectedPaymentMethod,
    storeUser,
  }: IProps) => {
    let [visibleReference, setVisibleReference] = useState(false);
    const toggleReference = () => setVisibleReference(!visibleReference);

    const _onSelectMethod = (method: EPaymentMethods) => {
      if (!isLoading) {
        onSelectMethod(method);
        if (method === EPaymentMethods.SberBusiness) {
          checkIsAuth();
        }
      }
    };

    const checkAuthSber =
      selectedPaymentMethod.method === EPaymentMethods.SberBusiness && !isAuth;

    let paymentMethods = methods.filter((item) => {
      if (storeUser.profile.personalType === TYPE_PERSON_INDIVIDUAL) {
        return item.method !== EPaymentMethods.SberBusiness;
      } else {
        return item.method;
      }
    });

    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        onClose();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    return (
      <>
        {visible && (
          <div
            className={styles.deposit}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.deposit__popupWrapper}>
              <div
                className={styles.deposit__popup}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                <Close className={styles.deposit__close} onClick={onClose} />

                {isLoading && <Loader className={styles.deposit__loader} />}
                <h2
                  className={`${styles.deposit__title} ${styles.deposit__title_mainTitle}`}
                >
                  Необходимо оплатить залог, чтобы сохранить бронирование на
                  срок 3 дня
                </h2>

                <div className={styles.deposit__referenceBlock}>
                  <div className={styles.deposit__referenceHeader}>
                    <h2
                      className={`${styles.deposit__title} ${styles.deposit__title_gold}`}
                    >
                      Справка
                    </h2>
                    <button
                      className={`${styles.deposit__referenceBtn} ${
                        visibleReference
                          ? styles.deposit__referenceBtn_rotate
                          : ""
                      }`}
                      onClick={toggleReference}
                      disabled={isLoading}
                    >
                      <CircleCross />
                    </button>
                  </div>

                  <p
                    className={`${styles.deposit__referenceText} ${
                      visibleReference
                        ? styles.deposit__referenceText_active
                        : ""
                    }`}
                  >
                    Для сохранения бронирования на срок 3 дня, до полной оплаты
                    аренды, необходимо оплатить залог 10 000 рублей. Сумма
                    залога будет зачислена в счет аренды. В случае неоплаты
                    аренды, залог не возвращается
                  </p>

                  <span className={styles.deposit__bottomLine} />
                </div>

                <div className={styles.deposit__paymentBlock}>
                  <h2 className={styles.deposit__title}>
                    Способы оплаты
                    <Hint
                      text={[INFO_PAYMENT.BILL]}
                      className={styles.deposit__hint}
                      classNameIcon={styles.deposit__hintIcon}
                      classNameText={styles.deposit__hintText}
                    />
                  </h2>
                  <PaymentMethods
                    methods={paymentMethods}
                    onSelectMethod={_onSelectMethod}
                  />
                </div>

                <div className={styles.deposit__buttons}>
                  <Button
                    title={"Продолжить"}
                    onClick={onContinue}
                    theme={"gold"}
                    className={styles.deposit__button}
                    disabled={checkAuthSber || isLoading}
                    isDisabledStyle={checkAuthSber || isLoading}
                  />
                  {checkAuthSber ? (
                    <ButtonSberBusiness
                      isLoading={isLoading}
                      loginSberBusiness={loginSberBusiness}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
