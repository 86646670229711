import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../components/Button";

import { stopPropagation } from "../../../helpers/modalsFunc";

import { IServiceOfBasket } from "../../../interfaces/Basket";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/index.module.scss";

interface IProps {
  isVisible: boolean;
  services: IServiceOfBasket[];
  onDeleteService(serviceId: number): void;
  onClose(): void;
}

export const PopupAllServices: FC<IProps> = observer(
  ({
    isVisible = false,
    services = [],
    onClose = () => {},
    onDeleteService = () => {},
  }) => {
    return (
      <>
        {isVisible && (
          <div className={styles.popupAllServices} onClick={onClose}>
            <div className={styles.popupAllServices__popupWrapper}>
              <div
                className={styles.popupAllServices__popup}
                onClick={stopPropagation}
              >
                <button
                  className={styles.popupAllServices__close}
                  onClick={onClose}
                >
                  <Close className={styles.popupAllServices__closeIcon} />
                </button>
                <div className={styles.popupAllServices__content}>
                  <h3 className={styles.popupAllServices__title}>Все услуги</h3>

                  <div className={styles.popupAllServices__servicesList}>
                    {services?.map((item) => (
                      <div
                        key={item.id}
                        className={styles.popupAllServices__serviceItem}
                      >
                        <span className={styles.popupAllServices__serviceTitle}>
                          {item.name}
                        </span>
                        <button
                          onClick={() => onDeleteService(item.id)}
                          className={styles.popupAllServices__serviceDelete}
                        >
                          <Close
                            className={styles.popupAllServices__deleteIcon}
                          />
                        </button>
                      </div>
                    ))}
                  </div>

                  <Button
                    title="СОХРАНИТЬ"
                    theme="gold"
                    onClick={onClose}
                    className={styles.popupAllServices__button}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
