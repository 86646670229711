import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { disableScroll, enableScroll } from "../../../../../helpers/modalsFunc";

import {
  ONE_ROOMS_NAME,
  SERVICES,
  WARNING_BOOKINGS_ARRIVAL_DATE,
  WARNING_BOOKINGS_SERVICE_DATE,
  DOCUMENTS,
  PAGE_MY_BOOKING_BY_TAB,
  RESIDENTS,
} from "./../../../../../constants";

import {
  IBookingApartmentDetail,
  IBookingItem,
} from "./../../../../../interfaces/Account/index";
import { IBookingsProps, IStoreUI } from "./interfaces";

import { Store } from "../../../../../stores/types";
import {
  EChoosePayType,
  EStatusBill,
  ETabsDocuments,
  ETabsResidents,
  ETypeBill,
} from "../../../../../types";
import { ETabsServices } from "./../../../../../types/index";
import { IBill } from "../../../../../interfaces/Documents";

enum EPropsForBookings {
  WithWarning = "withWarning",
  UnpaidBooking = "unpaidBooking",
  UnpaidDeposit = "unpaidDeposit",
  UnpaidRent = "unpaidRent",
  Extension = "extension",
  EmptyBills = "emptyBills",
}

export class StoreUI implements IStoreUI {
  storeRoot: Store.IRootStore;
  storeMyBookings: Store.IMyBookings;
  storeMyApartment: Store.IMyApartment;
  storeBookingServices: Store.IAccountServices;
  storeBills: Store.IBillsStore;
  storeChoosePay: Store.IChoosePay;
  storePayment: Store.IPayment;
  navigate: NavigateFunction;

  isVisiblePopupLater: boolean = false;
  isVisiblePopupExist: boolean = false;

  popupExistCloseCallback: Function | null = null;

  isLoading: boolean = false;
  isLoadingButtons: boolean = false;

  propsForBookings: IBookingsProps = {
    [EPropsForBookings.WithWarning]: [],
    [EPropsForBookings.Extension]: [],
    // массивы для инфы о бронях с неоплаченными счетами
    [EPropsForBookings.UnpaidBooking]: [],
    [EPropsForBookings.UnpaidDeposit]: [],
    [EPropsForBookings.UnpaidRent]: [],
    [EPropsForBookings.EmptyBills]: [],
  };

  constructor(storeRoot: Store.IRootStore, navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
    this.navigate = navigate;
    this.storeMyBookings = storeRoot.storeMyBookings;
    this.storeBookingServices = storeRoot.storeBookingServices;
    this.storeMyApartment = storeRoot.storeMyApartment;
    this.storeBills = storeRoot.storeBills;
    this.storeChoosePay = storeRoot.storeModals.storeChoosePay;
    this.storePayment = storeRoot.storePayment;
  }

  // getters

  get myBookingsList() {
    return (
      this.storeMyBookings.bookings?.map((item) =>
        this.getReadyBooking(item)
      ) || []
    );
  }

  // functions

  *init() {
    this.setIsLoading(true);
    this.clearBookingProps(EPropsForBookings.UnpaidBooking);

    yield this.storeMyBookings.getBookings();

    if (this.storeMyBookings.bookings.length) {
      this.storeMyBookings.bookings.forEach((item: IBookingItem) => {
        this.getBookingDetail(item);
      });
    }

    this.setIsLoading(false);
  }

  *getBillsByBooking(booking: IBookingItem) {
    let isIncludeUnpaidBooking = this.propsForBookings[
      EPropsForBookings.UnpaidBooking
    ].some((item) => item.bookingId === booking.id);

    let isIncludeUnpaidDeposit = this.propsForBookings[
      EPropsForBookings.UnpaidDeposit
    ].some((item) => item.bookingId === booking.id);

    let isIncludeUnpaidRent = this.propsForBookings[
      EPropsForBookings.UnpaidRent
    ].some((item) => item.bookingId === booking.id);

    if (
      booking.id &&
      (!isIncludeUnpaidBooking ||
        !isIncludeUnpaidDeposit ||
        !isIncludeUnpaidRent)
    ) {
      yield this.storeBills.getBills(booking.id);

      if (this.storeBills.bills.length) {
        // записать неоплаченные счета по типам (если неоплаченные есть)
        this.writeUnpaidBooking(this.storeBills.bills, booking.id);
        this.writeUnpaidDeposit(this.storeBills.bills, booking.id);
        this.writeUnpaidRent(this.storeBills.bills, booking.id);
      } else {
        this.writeEmptyBills(booking.id);
      }
    }
  }

  writeUnpaidBooking(bills: IBill[], bookingId: number) {
    // найти счет на Залог в счетах
    let bookingBill = bills?.find((item) => item.type === ETypeBill.Booking);

    if (bookingBill && bookingBill.status === EStatusBill.NotPaid) {
      // добавить бронь в список броней с неоплаченным залогом
      this.propsForBookings[EPropsForBookings.UnpaidBooking].push({
        bookingId: bookingId,
        billId: bookingBill.id,
        documentId: bookingBill.documentId,
      });
    }
  }

  writeUnpaidDeposit(bills: IBill[], bookingId: number) {
    let depositBill = bills?.find((item) => item.type === ETypeBill.Deposit);

    if (depositBill && depositBill.status === EStatusBill.NotPaid) {
      // добавить бронь в список броней с неоплаченным депозитом
      this.propsForBookings[EPropsForBookings.UnpaidDeposit].push({
        bookingId: bookingId,
        billId: depositBill.id,
        documentId: depositBill.documentId,
      });
    }
  }

  writeUnpaidRent(bills: IBill[], bookingId: number) {
    let rentBill = bills?.find(
      (item) => item.type.toLowerCase() === ETypeBill.Rent.toLowerCase()
    );

    if (rentBill && rentBill.status === EStatusBill.NotPaid) {
      // добавить бронь в список броней с неоплаченной арендой
      this.propsForBookings[EPropsForBookings.UnpaidRent].push({
        bookingId: bookingId,
        billId: rentBill.id,
        documentId: rentBill.documentId,
      });
    }
  }

  writeEmptyBills(bookingId: number) {
    // добавить бронь в список броней без счетов
    this.propsForBookings[EPropsForBookings.EmptyBills].push({
      bookingId: bookingId,
    });
  }

  clearBookingProps(type: EPropsForBookings) {
    this.propsForBookings[type] = [];
  }

  *getBookingDetail(item: IBookingItem) {
    this.setIsLoadingButtons(true);
    yield this.storeMyApartment.getBookingById(item.id);

    let _details = this.storeMyApartment.apartmentDetail;

    if (_details) {
      yield this.getBillsByBooking(item);
      this.writeBookingWarnings(item.id, _details);

      if (_details.isExtension) {
        this.writeBookingIsExtension(item.id);
      }
    }
    this.setIsLoadingButtons(false);
  }

  writeBookingWarnings(id: number, apartmentDetail: IBookingApartmentDetail) {
    let haveServicesDates = !apartmentDetail.warning.serviceDate;
    let haveArrivalDate = !apartmentDetail.warning.arrivalDate;

    let _warningText = [];

    if (!haveServicesDates) {
      _warningText.push(WARNING_BOOKINGS_SERVICE_DATE);
    }
    if (!haveArrivalDate) {
      _warningText.push(WARNING_BOOKINGS_ARRIVAL_DATE);
    }

    this.propsForBookings[EPropsForBookings.WithWarning].push({
      id: id,
      withWarning: !haveServicesDates || !haveArrivalDate,
      warningText: _warningText,
    });
  }

  writeBookingIsExtension(id: number) {
    this.propsForBookings[EPropsForBookings.Extension].push({
      bookingId: id,
    });
  }

  *onClickReplacedBtn(
    billId: number | null | undefined,
    documentId: number | null = null
  ) {
    if (billId === null) {
      this.openPopupLater();
      return;
    }

    if (typeof billId === "number") {
      yield this.getPaymentExists(billId); // проверяет, была ли создана запись об оплате

      if (this.storePayment.isPaymentExist) {
        this.openPopupExist(() => this.openPaymentPopup(billId, documentId)); // если была, то открыть предупреждающий попап, а уже потом попап оплаты
      } else {
        this.openPaymentPopup(billId, documentId);
      }
    }
  }

  openPaymentPopup(billId: number, documentId: number | null = null) {
    this.storeChoosePay.setContinueCallback(this.init);
    this.storeChoosePay.openChoosePay({
      id: billId,
      type: EChoosePayType.Pawn,
      documentId: documentId,
    });
  }

  *getPaymentExists(billId: number) {
    yield this.storePayment.getExistPayment(billId);
  }

  getReadyBooking(booking: IBookingItem) {
    let _booking = this.getItemInWarning(booking.id);
    let _bookingExtension = this.getItemInExtension(booking.id);
    let _bookingUnpaidBooking = this.getItemInBooking(booking.id);
    let _bookingUnpaidDeposit = this.getItemInDeposit(booking.id);
    let _bookingUnpaidRent = this.getItemInRent(booking.id);
    let _bookingEmptyBills = this.getItemInEmptyBills(booking.id);

    let isExtension = Boolean(_bookingExtension); // продление ли эта бронь
    let isUnpaidBooking = Boolean(_bookingUnpaidBooking); // неоплачен ли залог
    let isUnpaidDeposit = Boolean(_bookingUnpaidDeposit); // неоплачен ли депозит
    let isUnpaidRent = Boolean(_bookingUnpaidRent); // неоплачена ли аренда
    let isEmptyBills = Boolean(_bookingEmptyBills); // пустой ли массив счетов

    let currentBillId: null | number = null; // текущий id счета для оплаты
    let isVisibleReplaceButton = false;
    let replaceButtonText = "";
    let disabledReplaceButton = false;

    // если не оплачен залог
    if (
      booking.needBail &&
      !isExtension &&
      isUnpaidBooking &&
      _bookingUnpaidBooking?.billId
    ) {
      currentBillId = _bookingUnpaidBooking.billId;
      disabledReplaceButton = !_bookingUnpaidBooking.billId;
      isVisibleReplaceButton = true;
      replaceButtonText = "Оплатить залог";
    }

    // если это продление, проверяем неоплаченные счета на аренду и депозит
    if (isExtension && (isUnpaidDeposit || isUnpaidRent)) {
      // нужно оплатить хотя бы один из этих счетов, чтобы квартира стала доступна для просмотра
      if (isUnpaidDeposit && _bookingUnpaidDeposit?.billId) {
        currentBillId = _bookingUnpaidDeposit.billId;
        disabledReplaceButton = !_bookingUnpaidDeposit.billId;
      }

      if (isUnpaidRent && _bookingUnpaidRent?.billId) {
        currentBillId = _bookingUnpaidRent.billId;
        disabledReplaceButton = !_bookingUnpaidRent.billId;
      }
      isVisibleReplaceButton = true;
      replaceButtonText = "Оплатить счет";
    }

    // если массив счетов пуст и нужно оплатить залог
    if (isEmptyBills && booking.needBail) {
      disabledReplaceButton = true;
      isVisibleReplaceButton = true;
      replaceButtonText = isExtension ? "Оплатить счет" : "Оплатить залог";
    }

    return {
      id: String(booking.id),
      apartmentName: this.getReadyName(booking.rooms, booking.square),
      square: String(Math.round(booking.square)),
      floor: String(booking.floor),
      flatNumber: String(booking.flatNumber),
      withWarning: _booking?.withWarning || false,
      warningText: _booking?.warningText || "",
      isUnpaidPawn: isUnpaidBooking,
      billId: currentBillId,
      documentId: _bookingUnpaidBooking?.documentId,
      isExtension: isExtension,
      status: booking.status,
      isVisibleReplaceButton: isVisibleReplaceButton,
      replaceButtonText: replaceButtonText,
      disabledReplaceButton: disabledReplaceButton,
    };
  }

  private getItemInExtension(bookingId: number) {
    // найти бронь из броней продления
    return this.propsForBookings[EPropsForBookings.Extension].find(
      (item) => item.bookingId === bookingId
    );
  }

  private getItemInDeposit(bookingId: number) {
    // найти бронь из броней неоплаченного депозита
    return this.propsForBookings[EPropsForBookings.UnpaidDeposit].find(
      (item) => item.bookingId === bookingId
    );
  }

  private getItemInBooking(bookingId: number) {
    // найти бронь из броней неоплаченного залога
    return this.propsForBookings[EPropsForBookings.UnpaidBooking].find(
      (item) => item.bookingId === bookingId
    );
  }

  private getItemInRent(bookingId: number) {
    // найти бронь из броней неоплаченной аренды
    return this.propsForBookings[EPropsForBookings.UnpaidRent].find(
      (item) => item.bookingId === bookingId
    );
  }

  private getItemInWarning(bookingId: number) {
    // найти бронь из броней с предупреждениями
    return this.propsForBookings[EPropsForBookings.WithWarning].find(
      (item) => item.id === bookingId
    );
  }

  private getItemInEmptyBills(bookingId: number) {
    // найти бронь из броней с пустым массивом счетов
    return this.propsForBookings[EPropsForBookings.EmptyBills].find(
      (item) => item.bookingId === bookingId
    );
  }

  getReadyName(roomLabel: string, square: number) {
    let _square = Math.round(square);
    let _rooms =
      roomLabel.toLocaleLowerCase() === ONE_ROOMS_NAME.toLowerCase()
        ? roomLabel
        : `${roomLabel} квартира`;
    return `${_rooms}, ${String(_square)} м²`;
  }

  convertDate(date: string) {
    return format(new Date(date), "d MMMM", { locale: ru });
  }

  onClickMenuButton(bookingId: string, tabName: string) {
    switch (tabName) {
      case DOCUMENTS:
        this.navigate(
          PAGE_MY_BOOKING_BY_TAB(bookingId, tabName) +
            `/${ETabsDocuments.Agreements}`
        );
        break;

      case RESIDENTS:
        this.navigate(
          PAGE_MY_BOOKING_BY_TAB(bookingId, tabName) +
            `/${ETabsResidents.Renter}`
        );
        break;

      case SERVICES:
        this.navigate(
          PAGE_MY_BOOKING_BY_TAB(bookingId, tabName) +
            `/${ETabsServices.Selected}`
        );
        break;

      default:
        this.navigate(PAGE_MY_BOOKING_BY_TAB(bookingId, tabName));
    }
  }

  openPopupLater() {
    this.setIsVisiblePopupLater(true);
    disableScroll();
  }

  closePopupLater() {
    this.setIsVisiblePopupLater(false);
    enableScroll();
  }

  openPopupExist(closeCallback: Function | null) {
    this.setIsVisiblePopupExist(true);
    disableScroll();

    if (closeCallback) {
      this.setPopupExistCloseCallback(closeCallback);
    }
  }

  closePopupExist() {
    this.setIsVisiblePopupExist(false);
    enableScroll();

    if (this.popupExistCloseCallback) {
      this.popupExistCloseCallback();
      this.setPopupExistCloseCallback(null);
    }
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingButtons(value: boolean) {
    this.isLoadingButtons = value;
  }

  setIsVisiblePopupLater(value: boolean) {
    this.isVisiblePopupLater = value;
  }

  setIsVisiblePopupExist(value: boolean) {
    this.isVisiblePopupExist = value;
  }

  setPopupExistCloseCallback(value: Function | null) {
    this.popupExistCloseCallback = value;
  }
}
