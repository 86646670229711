import { ApiManager } from "..";

import { MAIN_SOCIALS, TEXT_PAGES_GET } from "../../constants/api";

import { IMainSocials } from "../../interfaces/MainPage";
import { TTextPage } from "../../types";

import { TResponseApi } from "../types";

export async function getSocials(): Promise<TResponseApi<IMainSocials>> {
  return await ApiManager.request<IMainSocials>({
    url: MAIN_SOCIALS,
    method: "GET",
  });
}

export async function getTextConctreteBlock(params: {
  code: string;
}): Promise<TResponseApi<TTextPage[]>> {
  return await ApiManager.request<TTextPage[]>({
    url: TEXT_PAGES_GET,
    method: "GET",
    params: params,
  });
}
