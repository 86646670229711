import { makeAutoObservable } from "mobx";

import {
  addApartmentToBasket,
  addView,
  getApartment,
  getReviews,
} from "../../../api/Apartment";
import { TResponseApi } from "../../../api/types";
import { addFavorite, deleteFavorite, getFavorite } from "../../../api/User";

import { ICommonResponse, IFavoritesResponse } from "../../../interfaces";
import {
  IApartmentFromBasket,
  IApartmentDetail,
  IReviewItem,
  IReviewsApartment,
  IServerErrorsApartment,
} from "../../../interfaces/Apartment";

import { Store } from "../../types";

export class ModelApartment implements Store.IApartment {
  apartmentDetail: IApartmentDetail = {
    id: 0,
    capacity: 0,
    price: 0,
    roomValue: 0,
    roomLabel: "",
    square: 0,
    floor: 0,
    views: 0,
    description: "",
    facilities: [],
    terms: [],
    images: [],
    busy: false,
    canPets: false,
  };

  reviews: IReviewItem[] = [];
  reviewsTotal: number = 0;
  allFavoritesId: number[] = [];

  serverErrors: IServerErrorsApartment = {
    addBasket: [],
  };

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getApartmentDetail(apartmentId: number) {
    let response: TResponseApi<IApartmentDetail> = yield getApartment(
      apartmentId
    );
    if (response.isSuccess && response.data !== null) {
      this.setApartmentsDetail(response.data);
    }
  }

  *addToBasket(params: IApartmentFromBasket) {
    let response: TResponseApi<ICommonResponse> = yield addApartmentToBasket(
      params
    );

    if (response.isError && response.error?.message) {
      this.serverErrors.addBasket = [];
      this.setServerErrors({ addBasket: [response.error.message] });
    } else {
      this.serverErrors.addBasket = [];
    }
    return response.isSuccess;
  }

  *getReviews(apartmentId: number, limit: number) {
    let response: TResponseApi<IReviewsApartment> = yield getReviews({
      apartmentId,
      limit,
    });

    if (response.isSuccess && response.data !== null) {
      this.setReviews(response.data.list);
      this.setReviewsTotal(response.data.total);
    }
  }

  *addFavorite(apartmentId: number) {
    let response: TResponseApi<ICommonResponse> = yield addFavorite({
      apartmentId,
    });
    return response.isSuccess;
  }

  *deleteFavorite(apartmentId: number) {
    let response: TResponseApi<ICommonResponse> = yield deleteFavorite(
      apartmentId
    );
    return response.isSuccess;
  }

  *getFavorites() {
    let response: TResponseApi<IFavoritesResponse> = yield getFavorite({
      limit: 200,
    });

    if (response.isSuccess && response.data !== null) {
      response.data.list.map((apartment) =>
        this.allFavoritesId.push(apartment.id)
      );
    }

    return response.isSuccess;
  }

  *addView(apartmentId: number) {
    let response: TResponseApi<ICommonResponse> = yield addView({
      apartmentId,
    });

    if (response.isSuccess && response.data !== null) {
      return response.data.result;
    }
  }

  clearErrors() {
    this.serverErrors.addBasket = [];
  }

  // setters

  setApartmentsDetail(value: IApartmentDetail) {
    this.apartmentDetail = value;
  }

  setReviews(value: IReviewItem[]) {
    this.reviews = value;
  }

  setReviewsTotal(value: number) {
    this.reviewsTotal = value;
  }

  setServerErrors(value: IServerErrorsApartment) {
    this.serverErrors = { ...this.serverErrors, ...value };
  }
}
