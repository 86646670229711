import { makeAutoObservable } from "mobx";

import { getBasket } from "../../../api/Basket";
import { getBookingPDF, sendBooking } from "../../../api/Booking";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { IApartmentOfBasket, IBasket } from "../../../interfaces/Basket";
import { IBookRequest } from "../../../interfaces/Booking";
import { IBookingPdfResponse, IBookResponse } from "../../../interfaces";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

export class ModelBooking implements Store.IBooking {
  apartmentsList: IApartmentOfBasket[] = [];
  isSuccessBook: boolean = false;
  isCancelBookRequest: boolean = false;
  errorsBooking: string[] = [];
  errorsBookingPdf: string[] = [];

  bookingId: number | null = null;
  filePdfBase64: string = "";

  isSuccessCreatePdf: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions
  *getBasket() {
    let response: TResponseApi<IBasket> = yield getBasket();

    if (response.isSuccess && response.data !== null) {
      if (response.data.apartments.length) {
        this.setApartmentsList(response.data.apartments);
        return true;
      }
    }
    return false;
  }

  *sendBooking(params: IBookRequest) {
    let response: TResponseApi<IBookResponse> = yield sendBooking(params);
    this.setBookingId(null);
    this.clearErrorsBooking();

    this.setIsCancelBookRequest(response.isTimeoutEnd);

    if (
      response.isSuccess &&
      response.data !== null &&
      response.data.bookingId
    ) {
      this.setBookingId(response.data.bookingId);
      this.setIsSuccessBook(true);
    } else {
      this.setIsSuccessBook(false);
    }

    if (response.isError && !response.isTimeoutEnd) {
      let _error = response.error?.data?.message
        ? response.error?.data?.message
        : response.error?.message
        ? response.error.message
        : ERROR_TEXT_SERVER_COMMON;

      this.setError(_error);

      return;
    }

    this.errorsBooking = [];
  }

  *getBookingPdf(bookingId: number) {
    let response: TResponseApi<IBookingPdfResponse> = yield getBookingPDF({
      bookingId,
    });

    if (response.isSuccess && response.data !== null && response.data.file) {
      this.setFilePdfBase64(response.data.file);
      this.setIsSuccessCreatePdf(true);
    }

    if (response.isError) {
      this.setErrorBookingPdf(
        response.error?.message
          ? response.error.message
          : ERROR_TEXT_SERVER_COMMON
      );
      this.setIsSuccessCreatePdf(false);
    } else {
      this.errorsBookingPdf = [];
    }
  }

  clearErrorsBooking() {
    this.errorsBooking = [];
  }

  clearErrorsBookingPdf() {
    this.errorsBookingPdf = [];
  }

  // setters

  setApartmentsList(value: IApartmentOfBasket[]) {
    this.apartmentsList = value;
  }

  setIsSuccessBook(value: boolean) {
    this.isSuccessBook = value;
  }

  setError(value: string) {
    this.errorsBooking = [...this.errorsBooking, value];
  }

  setErrorBookingPdf(value: string) {
    this.errorsBookingPdf = [...this.errorsBookingPdf, value];
  }

  setBookingId(value: number | null) {
    this.bookingId = value;
  }

  setFilePdfBase64(value: string) {
    this.filePdfBase64 = value;
  }

  setIsSuccessCreatePdf(value: boolean) {
    this.isSuccessCreatePdf = value;
  }

  setIsCancelBookRequest(value: boolean) {
    this.isCancelBookRequest = value;
  }
}
