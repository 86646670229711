import Reveal, { RevealProps } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const animation = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const RevealAnimation = ({
  children,
  delay = 300,
  ...props
}: RevealProps) => (
  <Reveal delay={delay} triggerOnce keyframes={animation} {...props}>
    {children}
  </Reveal>
);
