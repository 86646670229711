import { makeAutoObservable } from "mobx";

import {
  addAccountService,
  changeAccountService,
  createBillByServices,
  deleteAccountService,
  getAccountAvailableServices,
  getAccountServices,
} from "../../../api/AccountServices";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

import {
  IAccountAddServiceParams,
  IAccountChangeServiceParams,
  IAccountService,
  IAccountServiceAvailable,
  IAccountServiceAvailableItem,
  IAccountServiceItem,
  IAccountServicesParams,
  IServiceErrors,
} from "../../../interfaces/AccountService";
import {
  ICommonResponse,
  IPaginateData,
  IPaginateParamsCommon,
} from "../../../interfaces";

import { Store } from "../../types";
import { TResponseApi } from "../../../api/types";
import { IBillByServices } from "../../../interfaces/Documents";

export class ModelAccountServices implements Store.IAccountServices {
  myServices: IAccountServiceItem[] = [];
  selectedSevices: IAccountServiceItem[] = [];
  availableServices: IAccountServiceAvailableItem[] = [];

  paginationData: IPaginateData = {
    lastPage: 0,
    limit: 0,
    page: 0,
    total: 0,
  };

  errors: IServiceErrors = {
    deleteService: [],
    addService: [],
    createBill: [],
  };

  isSuccessAdded: boolean = false;
  isSuccessChange: boolean = false;
  isSuccessDelete: boolean = false;
  isSuccessCreateBillByServices: boolean = false;
  isTimeoutEndCreateBillByServices: boolean = false;
  billId: number | null = null;
  documentId: number | null = null;

  bookingServicesHaveDates: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions
  *getMyServices(bookingId: string, params: IPaginateParamsCommon) {
    let _params: IAccountServicesParams = {
      page: params.page,
      limit: params.limit,
      issetBill: 1,
    };

    let response: TResponseApi<IAccountService> = yield getAccountServices(
      bookingId,
      _params
    );

    if (response.isSuccess && response.data !== null) {
      this.setMyServices(response.data.list);

      this.setPaginationData({
        lastPage: response.data.lastPage,
        limit: response.data.limit,
        page: response.data.page,
        total: response.data.total,
      });
    }
  }

  *getSelectedServices(bookingId: string, params: IPaginateParamsCommon) {
    this.setIsSuccessCreateBillByServices(false);

    let _params: IAccountServicesParams = {
      page: params.page,
      limit: params.limit,
      issetBill: 0,
    };

    let response: TResponseApi<IAccountService> = yield getAccountServices(
      bookingId,
      _params
    );

    if (response.isSuccess && response.data !== null) {
      this.setSelectedSevices(response.data.list);
      this.setPaginationData({
        lastPage: response.data.lastPage,
        limit: response.data.limit,
        page: response.data.page,
        total: response.data.total,
      });
    }
  }

  *getAvailableServices(bookingId: string, params: IPaginateParamsCommon) {
    let response: TResponseApi<IAccountServiceAvailable> =
      yield getAccountAvailableServices(bookingId, params);

    if (response.isSuccess && response.data !== null) {
      this.setAvailableServices(response.data.list);
      this.setPaginationData({
        lastPage: response.data.lastPage,
        limit: response.data.limit,
        page: response.data.page,
        total: response.data.total,
      });
    }
  }

  *addAccountService(bookingId: string, params: IAccountAddServiceParams) {
    let response: TResponseApi<ICommonResponse> = yield addAccountService(
      bookingId,
      params
    );

    this.setIsSuccessAdded(
      response.isSuccess && response.data?.result === "success"
    );

    if (response.isError) {
      this.addError(
        "addService",
        response.error?.message
          ? response.error?.message
          : ERROR_TEXT_SERVER_COMMON
      );
    } else {
      this.clearErrorsAddService();
    }
  }

  *changeAccountService(
    bookingId: string,
    params: IAccountChangeServiceParams
  ) {
    let response: TResponseApi<ICommonResponse> = yield changeAccountService(
      bookingId,
      params
    );

    this.setIsSuccessChange(
      response.isSuccess && response.data?.result === "success"
    );
  }

  *deleteAccountService(bookingId: string, serviceId: string) {
    let response: TResponseApi<ICommonResponse> = yield deleteAccountService(
      bookingId,
      serviceId
    );

    this.setIsSuccessDelete(
      response.isSuccess && response.data?.result === "success"
    );

    if (response.isError) {
      this.addError(
        "deleteService",
        response.error?.message
          ? response.error?.message
          : ERROR_TEXT_SERVER_COMMON
      );
    } else {
      this.clearErrorsDelete();
    }
  }

  *checkAvailabilityOfServiceDates(
    bookingId: string,
    params: IPaginateParamsCommon
  ) {
    yield this.getSelectedServices(bookingId, params);

    this.setBookingServicesHaveDates(true);

    this.selectedSevices.forEach((item) => {
      if (!item.date) {
        this.setBookingServicesHaveDates(false);
      }
    });
  }

  *createBillByServices(bookingId: string) {
    let response: TResponseApi<IBillByServices> = yield createBillByServices(
      bookingId
    );

    this.setIsSuccessCreateBillByServices(response.isSuccess);

    if (response.isSuccess) {
      this.setBillId(response.data?.billId || null);
      this.setDocumentId(response.data?.documentId || null);
    }

    this.setIsTimeoutEndCreateBillByServices(response.isTimeoutEnd);

    if (response.isError && !response.isTimeoutEnd) {
      this.addError(
        "createBill",
        response.error?.message
          ? response.error?.message
          : ERROR_TEXT_SERVER_COMMON
      );
    }
  }

  addError<T extends keyof IServiceErrors>(type: T, error: string) {
    this.errors[type] = [...this.errors[type], error];
  }

  clearErrorsCreateBill() {
    this.errors.createBill = [];
  }

  clearErrorsAddService() {
    this.errors.addService = [];
  }

  clearErrorsDelete() {
    this.errors.deleteService = [];
  }

  // setters

  setMyServices(value: IAccountServiceItem[]) {
    this.myServices = value;
  }

  setSelectedSevices(value: IAccountServiceItem[]) {
    this.selectedSevices = value;
  }

  setAvailableServices(value: IAccountServiceAvailableItem[]) {
    this.availableServices = value;
  }

  setIsSuccessAdded(value: boolean) {
    this.isSuccessAdded = value;
  }

  setIsSuccessChange(value: boolean) {
    this.isSuccessChange = value;
  }

  setBillId(value: number | null) {
    this.billId = value;
  }

  setDocumentId(value: number | null) {
    this.documentId = value;
  }

  setIsSuccessDelete(value: boolean) {
    this.isSuccessDelete = value;
  }

  setIsSuccessCreateBillByServices(value: boolean) {
    this.isSuccessCreateBillByServices = value;
  }

  setBookingServicesHaveDates(value: boolean) {
    this.bookingServicesHaveDates = value;
  }

  setPaginationData(value: IPaginateData) {
    this.paginationData = value;
  }
  setIsTimeoutEndCreateBillByServices(value: boolean) {
    this.isTimeoutEndCreateBillByServices = value;
  }
}
