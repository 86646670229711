import { FC } from "react";
import { observer } from "mobx-react-lite";

import { ISorting } from "../../../../interfaces/Catalog";

import styles from "./styles/sortingDrop.module.scss";

interface IProps {
  changeSort: (value: ISorting) => void;
  sortList: ISorting[];
}

export const SortingDrop: FC<IProps> = observer(
  ({ changeSort = () => {}, sortList = [] }) => {
    return (
      <ul className={styles.list}>
        {sortList?.map((item, index) => (
          <li
            key={index}
            className={styles.list__item}
            onClick={() => changeSort({ value: item.value, type: item.type })}
          >
            {item.value}
          </li>
        ))}
      </ul>
    );
  }
);
