import { FC } from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { MobileVersion } from "../../MobileVersion";

import { stopPropagation } from "../../../../helpers/modalsFunc";

import { Store } from "../../../../stores/types";

import { MAIN_NAV, MAIN_AUTH_NAV } from "../../../../constants";

import { ReactComponent as MenuImg } from "../../../../assets/images/menuImg.svg";
import { ReactComponent as Close } from "../../../../assets/images/closeModal.svg";
import { ReactComponent as Mobile } from "../../../../assets/images/mobileVersion.svg";

import styles from "./styles/menu.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
  phone: string;
  menu: boolean;
  isMobileVersion: boolean;
  closeMenu(): void;
  openMobileVersion(): void;
  closeMobileVersion(): void;
}

export const Menu: FC<IProps> = observer(
  ({
    menu = false,
    storeAuthentication,
    phone = "",
    isMobileVersion = false,
    closeMenu = () => {},
    openMobileVersion = () => {},
    closeMobileVersion = () => {},
  }) => {
    return (
      <>
        {menu && (
          <div className={styles.menu} onClick={closeMenu}>
            <div className={styles.menu__aside} />
            <div className={styles.menu__container}>
              <div className={styles.menu__content} onClick={stopPropagation}>
                <div className={styles.menu__header}>
                  <Mobile
                    className={styles.menu__mobileBtn}
                    onClick={openMobileVersion}
                  />

                  <Close className={styles.menu__close} onClick={closeMenu} />
                </div>
                <div className={styles.main}>
                  <div className={styles.main__nav}>
                    {MAIN_NAV.map((nav) => (
                      <NavLink
                        to={String(nav.path)}
                        key={nav.id}
                        className={styles.main__titles}
                        onClick={closeMenu}
                      >
                        <span className={styles.main__number}>
                          {nav.number}
                        </span>
                        <h1 className={styles.main__title}>{nav.title}</h1>
                      </NavLink>
                    ))}
                    {storeAuthentication.isAuth &&
                      MAIN_AUTH_NAV.map((nav) => (
                        <NavLink
                          to={String(nav.path)}
                          key={nav.id}
                          className={styles.main__titles}
                          onClick={closeMenu}
                        >
                          <span className={styles.main__number}>
                            {nav.number}
                          </span>
                          <h1 className={styles.main__title}>{nav.title}</h1>
                        </NavLink>
                      ))}
                    <h6 className={styles.menu__phone}>{phone}</h6>
                  </div>
                  <MenuImg className={styles.main__img} />
                </div>
              </div>
            </div>
          </div>
        )}
        <MobileVersion
          modal={isMobileVersion}
          closeModal={closeMobileVersion}
        />
      </>
    );
  }
);
