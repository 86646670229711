import { FC } from "react";

import styles from "./styles/floor.module.scss";

interface IProps {
  floor: number;
  className?: string;
}

export const Floor: FC<IProps> = ({ floor = 0, className = "" }) => {
  return (
    <div className={`${styles.floor} ${className}`}>
      <div className={styles.floor__info}>
        <div className={styles.floor__number}>{floor} этаж</div>
      </div>
    </div>
  );
};
