import { Location } from "react-router-dom";

// проверяет, передан ли при переходе параметр toBack в объекте state
// если передан, сравнивает state.toBack с параметром stateToBack из пропсов
export function checkStateLocationForToBack(
  stateToBack: string,
  location: Location
) {
  let { state } = location;

  if (state) {
    let { toBack }: any = state;
    return toBack === stateToBack;
  }

  return false;
}
