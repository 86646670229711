import { makeAutoObservable } from "mobx";

import {
  ERROR_MIN_CHARS_TWO,
  ERROR_SELECT,
  ERROR_TEXT_EMPTY,
  OPTION_HAVE_VET_PASSPORT,
  OPTION_NOT_HAVE_VET_PASSPORT,
} from "../../../../constants";

import { IOptionCommon } from "../../../../interfaces";
import { IPetErrors, IPetStoreUI } from "../interfaces";

export class PetStoreUI implements IPetStoreUI {
  localId: number = 0;

  type: string = "";
  breed: string = "";
  veterinaryPassport: IOptionCommon | null = null;

  veterinaryOptions: IOptionCommon[] = [
    OPTION_HAVE_VET_PASSPORT,
    OPTION_NOT_HAVE_VET_PASSPORT,
  ];

  validationErrors: IPetErrors = {
    type: [],
    breed: [],
    veterinaryPassport: [],
  };

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  changeType(value: string) {
    this.setType(value);
  }

  changeBreed(value: string) {
    this.setBreed(value);
  }

  changeVeterinaryPassport(value: IOptionCommon | null) {
    this.setVeterinaryPassport(value);
  }

  clearErrors() {
    this.validationErrors.breed = [];
    this.validationErrors.type = [];
    this.validationErrors.veterinaryPassport = [];
  }

  // --- validation ----
  validateType() {
    if (!this.type.length) {
      this.validationErrors.type = [
        ...this.validationErrors.type,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validationErrors.type = [];
    }

    if (this.type.length < 2) {
      this.validationErrors.type = [
        ...this.validationErrors.type,
        ERROR_MIN_CHARS_TWO,
      ];
      return false;
    } else {
      this.validationErrors.type = [];
    }
    return true;
  }

  validateBreed() {
    if (!this.breed.length) {
      this.validationErrors.breed = [
        ...this.validationErrors.breed,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validationErrors.breed = [];
    }

    if (this.breed.length < 2) {
      this.validationErrors.breed = [
        ...this.validationErrors.breed,
        ERROR_MIN_CHARS_TWO,
      ];
      return false;
    } else {
      this.validationErrors.breed = [];
    }
    return true;
  }

  validateVeterinaryPassport() {
    if (!this.veterinaryPassport) {
      this.validationErrors.veterinaryPassport = [
        ...this.validationErrors.veterinaryPassport,
        ERROR_SELECT,
      ];
      return false;
    } else {
      this.validationErrors.veterinaryPassport = [];
    }

    return true;
  }

  validateAll() {
    this.validateBreed();
    this.validateType();
    this.validateVeterinaryPassport();

    return (
      this.validateBreed() &&
      this.validateType() &&
      this.validateVeterinaryPassport()
    );
  }

  // setters

  setType(value: string) {
    this.type = value;
  }
  setBreed(value: string) {
    this.breed = value;
  }
  setVeterinaryPassport(value: IOptionCommon | null) {
    this.veterinaryPassport = value;
  }

  setLocalId(value: number) {
    this.localId = value;
  }
}
