import { ApiManager } from "../index";

import { TResponseApi } from "../types";
import { IBookRequest } from "../../interfaces/Booking";
import { IBookingPdfResponse, IBookResponse } from "../../interfaces";

import { BOOKING_BOOK, BOOKING_BOOKING_PDF } from "../../constants/api";
import { TIMEOUT_BOOKING_BOOK, TIMEOUT_PDF } from "../../constants";

export async function sendBooking(
  params: IBookRequest
): Promise<TResponseApi<IBookResponse>> {
  return await ApiManager.request<IBookResponse>(
    {
      url: BOOKING_BOOK,
      method: "POST",
      data: params,
      timeout: TIMEOUT_BOOKING_BOOK,
    },
    true
  );
}

export async function getBookingPDF(params: {
  bookingId: number;
}): Promise<TResponseApi<IBookingPdfResponse>> {
  return await ApiManager.request<IBookingPdfResponse>(
    {
      url: BOOKING_BOOKING_PDF,
      method: "GET",
      params: params,
      timeout: TIMEOUT_PDF,
    },
    true
  );
}
