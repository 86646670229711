import { observer } from "mobx-react-lite";

import { ServiceCard } from "../ServiceCard";
import Pagination from "../../../../../components/Pagintaion";
import { Loader } from "../../../../../components/Loader";

import { IServicesAvailable } from "../storeUI/interfaces";
import { IPaginationData } from "../../../../../interfaces";

import { ETabsServices } from "../../../../../types";

import { ReactComponent as ServiceEmpty } from "../../../../../assets/images/serviceEmpty.svg";

import styles from "./styles/available.module.scss";

interface IProps {
  services: IServicesAvailable[] | null;
  paginationData: IPaginationData | null;
  isLoading: boolean;
  onClickMore(
    serviceId: number,
    typeTab: ETabsServices,
    isParking: boolean
  ): void;
}

export const AvailableServices = observer(
  ({
    isLoading = false,
    services = null,
    paginationData = null,
    onClickMore = () => {},
  }: IProps) => {
    return (
      <div className={styles.availableServices}>
        {isLoading ? (
          <div className={styles.availableServices__loadingBlock}>
            <Loader className={styles.availableServices__mainLoader} />
          </div>
        ) : (
          <div>
            {services?.length ? (
              <>
                <div className={styles.availableServices__cardsWrapper}>
                  {services.map((item) => (
                    <ServiceCard
                      id={item.id}
                      key={item.id}
                      image={item.image}
                      serviceTitle={item.name}
                      descriptionService={item.description}
                      price={item.price}
                      typeTab={ETabsServices.Available}
                      onClickMore={(
                        serviceId: number,
                        typeTab: ETabsServices
                      ) => onClickMore(serviceId, typeTab, item.isParking)}
                    />
                  ))}
                </div>

                {paginationData && (
                  <Pagination
                    onPageChange={paginationData.onPageChange}
                    totalCount={paginationData.totalCount}
                    siblingCount={paginationData.siblingCount}
                    currentPage={paginationData.currentPage}
                    pageSize={paginationData.pageSize}
                    className={styles.availableServices__pagination}
                  />
                )}
              </>
            ) : (
              <div className={styles.availableServices__cardsEmpty}>
                <ServiceEmpty />
                <p className={styles.availableServices__cardsEmptyTitle}>
                  Услуги недоступны для данной квартиры
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
