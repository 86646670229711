import { FormEvent } from "react";

import styles from "./styles/textArea.module.scss";

interface IProps {
  placeholder?: string;
  label: string;
  value?: string;
  onChange?(value: string): void;
  className?: string;
  classNameWrapper?: string;
  errors?: string[];
  maxLength?: number | undefined;
  disabled?: boolean;
}

export const TextArea = ({
  placeholder = "",
  label = "",
  value = "",
  onChange = () => {},
  className = "",
  classNameWrapper = "",
  errors = [],
  maxLength = undefined,
  disabled = false,
}: IProps) => {
  const _onChange = (e: FormEvent<HTMLTextAreaElement>) => {
    onChange(e.currentTarget.value);
  };
  return (
    <div className={`${styles.textArea} ${classNameWrapper}`}>
      <label className={styles.textArea__label}>{label}</label>
      <textarea
        className={`${styles.textArea__area} ${className}`}
        value={value}
        onChange={_onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
      />
      {errors?.length !== 0 && (
        <span className={styles.textArea__errorText}>{errors[0]}</span>
      )}
    </div>
  );
};
