import { makeAutoObservable } from "mobx";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";
import { createUserCompany, getUserCompany } from "../../../api/Company";

import { ICommonResponse } from "../../../interfaces";
import { ICompany } from "../../../interfaces/Company";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

export class ModelCompany implements Store.ICompanyStore {
  company: ICompany = {
    name: "",
    inn: "",
    ogrn: "",
    director: "",
    settlement_account: "",
    correspondent_account: "",
    bik: "",
    phone: "",
    address: "",
    addressIndex: "",
    bankName: "",
  };
  isSuccessUpdatedCompany: boolean = false;
  serverErrors: string[] = [];

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getCompany() {
    let response: TResponseApi<ICompany> = yield getUserCompany();
    if (response.isSuccess && response.data !== null) {
      this.setCompany(response.data);
    }
  }

  *createCompany(params: ICompany) {
    let response: TResponseApi<ICommonResponse> = yield createUserCompany(
      params
    );
    if (response.isSuccess && response.data !== null) {
      this.setIsSuccessUpdatedCompany(response.data.result === "success");
    } else {
      this.setIsSuccessUpdatedCompany(false);
    }

    if (response.isError) {
      if (response.error?.message) {
        this.addServerErrors(response.error.message);
      } else {
        this.addServerErrors(ERROR_TEXT_SERVER_COMMON);
      }
    } else {
      this.serverErrors = [];
    }
  }

  clearCompany() {
    this.company = {
      name: "",
      inn: "",
      ogrn: "",
      director: "",
      settlement_account: "",
      correspondent_account: "",
      bik: "",
      phone: "",
      address: "",
      addressIndex: "",
      bankName: "",
    };
  }

  addServerErrors(value: string) {
    this.serverErrors = [...this.serverErrors, value];
  }

  setCompany(company: ICompany) {
    this.company = company;
  }

  setIsSuccessUpdatedCompany(value: boolean) {
    this.isSuccessUpdatedCompany = value;
  }
}
