export const BASE_URL = "https://welcodom.ru/api/v1";

export const API = BASE_URL;

export const AUTHORIZATION = "/auth/login";
export const LOGOUT = "/auth/logout";
export const PHONE_VERIFY_SEND = "/auth/phone-verify/send";
export const PHONE_VERIFY_CHECK = "/auth/phone-verify/check";
export const REGISTRATION = "/auth/register";
export const NEED_FILL = "/auth/need-fill";
export const PASSWORD_RESET_SEND = "/auth/password-reset/send";
export const PASSWORD_RESET_CHECK = "/auth/password-reset/check";
export const PASSWORD_RESET_CHANGE = "/auth/password-reset/change";

export const CATALOG_APARTMENT = "/apartment";
export const APARTMENT_BY_ID = (apartmentId: number) =>
  `/apartment/${apartmentId}`;
export const APARTMENT_VIEW = "/apartment/view";
export const CATALOG_FILTERS_FACILITIES = "apartment/filters/facilities";
export const CATALOG_FILTERS_TERM = "apartment/filters/terms";
export const CATALOG_FILTERS_ROOMS = "apartment/filters/rooms";

export const USER_GET_PROFILE = "/user/public-profile";
export const USER = "/user";
export const COMPANY = "/company";
export const USER_AVATAR = "/user/avatar";
export const FAVORITE = "/favorite";
export const FAVORITE_DELETE = (apartmentId: number) =>
  `/favorite/${apartmentId}`;
export const ARCHIVE = "/booking/getArchive";
export const FEEDBACK = "/callback";
export const REVIEWS = "/reviews";

export const MAIN_SOCIALS = "contact/socials";
export const CONTACT_CONTACT = "/contact/contact";
export const TEXT_PAGE = "text-page";
export const TEXT_PAGES_GET = "/text-page/get-pages";

export const BASKET = "/basket";
export const BASKET_DELETE = "/basket/delete";
export const BASKET_APARTMENT = "/basket/apartment";
export const BASKET_SERVICE = "/basket/service";

export const SERVICE = "/service";
export const SERVICE_AVAILABLE = "/basket/service/available";

export const BOOKING_BOOK = "/booking/book";
export const BOOKING_BOOKING_PDF = "/booking/booking-pdf";
export const BOOKING_EXTENSION = "/booking/extension";
export const BOOKING_CAN_EXTENSION = `/booking/can-extension`;
export const BOOKING_CHECK_APARTMENT = `/booking/check-apartment`;

export const BOOKING_SERVICES = (bookingId: string) =>
  `/booking/${bookingId}/services`;
export const BOOKING_SERVICES_AVAILABLE = (bookingId: string) =>
  `/booking/${bookingId}/services/available`;
export const BOOKING_SERVICE_DELETE = (bookingId: string, serviceId: string) =>
  `/booking/${bookingId}/services/${serviceId}`;

export const BOOKING_SERVICE_CREATE_BILL = (id: string) =>
  `/booking/${id}/services/createBill`;

export const BOOKING_TENANTS_BY_ID = (bookingId: number) =>
  `/booking/${bookingId}/tenants`;
export const DELETE_TENANT = (bookingId: string, tenantId: number) =>
  `/booking/${bookingId}/tenant/${tenantId}`;

export const PAYMENT = "/payment";
export const PAYMENT_CREATE = "/payment/create";
export const PAYMENT_UPDATE = "/payment/update";
export const PAYMENT_EXIST = (billId: number) => `/payment/exists/${billId}`;

export const CHECK_IS_AUTH = "/auth/integration-auth/is-authorize";
export const LOGIN_SBERBUSINESS = "/auth/integration-auth/login";
export const REGISTER_SBERBUSINESS = "/auth/integration-auth/register";

export const BOOKING = "/booking";
export const BOOKING_BY_ID = (id: number) => `/booking/${id}`;
export const FLOOR_PLAN_BY_ID = (numberFloor: number) =>
  `/apartment/floor/${numberFloor}`;
export const APARTMENT_PLAN_BY_ID = (apartmentId: number) =>
  `/apartment/plan/${apartmentId}`;
export const BOOKING_APARTMENT = "/booking/apartment";
export const ACCOUNT_DOCUMENTS_BILLS = (id: number) =>
  `/account/my-bookings/${id}/documents/bills`;

export const FAQ = "/faq";
export const QUESTION = "/question";
export const PARTNER = "/partner";

export const NEWS = "/news";
export const NEWS_BY_ID = (newsId: number) => `/news/${newsId}`;

// ДОКУМЕНТЫ
export const DOCUMENT_AGREEMENT = "/document/agreement"; // для получения списка договоров в ЛК
export const DOCUMENT_AGREEMENT_PDF = "/document/agreement/pdf"; // для получения пдф договора

export const DOCUMENT_ACT = "/document/act"; // получение актов
export const DOCUMENT_RECONCILIATION_ACT = "/document/reconciliation-act"; // запрос на генерацию акта сверки
export const DOCUMENT_OTHER = "/document/other"; // получение других документов

export const DOCUMENT_PDF = "/document/pdf"; // получение пдф (загруженного) документа

export const BILL = "/document/bill";

export const HINT_ADDRESS = "/hint/address"; // для получения подсказок названия города/улицы/дома

export const AMO_BACK_CALL = "/amo/back-call";
export const AMO_BOOKING = "/amo/booking";
export const BILLS = "/bills";
export const AGREEMENTS = "/agreements";
export const ACTS = "/acts";

// ФИНАНСЫ
export const FINANCE = "/finance";

// ДОБАВЛЕНИЕ ИНФОРМАЦИИ К БРОНИРОВАНИЮ
// инф-ия по арендаотру, жильцам, питомцам, дате заселения

export const BOOKING_ADDITION_ARRIVAL_DATE = (id: string) =>
  `/booking/${id}/addition/arrival-date`;

export const BOOKING_ADDITION_RENTER = (id: string) =>
  `/booking/${id}/addition/renter`;

export const BOOKING_ADDITION_TENANTS = (id: string) =>
  `/booking/${id}/addition/tenants`;

export const BOOKING_ADDITION_PETS = (id: string) =>
  `/booking/${id}/addition/pets`;

// получение title и description из админки
export const PAGE_DESCRIPTION = "/page-description";
