import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../components/Button";
import { BackTo } from "../../../components/BackTo";
import { PetForm } from "../PetForm";
import { RadioTabs } from "../../../components/RadioTabs";
import { Loader } from "../../../components/Loader";
import { AddPersonBtn } from "../../../components/AddPersonBtn";
import { ModalReuse } from "../../../components/Modals/ModalReuse";

import { IOptionCommon, IRadioItem } from "../../../interfaces";

import { Store } from "../../../stores/types";

import styles from "./styles/stepPets.module.scss";

interface IProps {
  storePets: Store.IPetsOrderingData | null;
  stepTitle: string;
  isDisabledAll: boolean;
  onBack(): void;
  toMyBookings(): void;
}

export const StepPets: FC<IProps> = observer(
  ({
    storePets = null,
    stepTitle = "Шаг 0 из 0",
    isDisabledAll = false,
    onBack = () => {},
    toMyBookings = () => {},
  }) => {
    const _changeWithPets = (value: IRadioItem) => {
      if (!isDisabledAll && !storePets?.isLoading) {
        storePets?.changeWithPet(value);
      }
    };

    return (
      storePets && (
        <div className={styles.stepPets}>
          {storePets.isLoading && <Loader />}
          <BackTo
            text={"Вернуться назад"}
            onClick={onBack}
            className={styles.stepPets__backBtn}
            disabled={storePets.isLoading || isDisabledAll}
          />
          <div className={styles.stepPets__header}>
            <h5 className={styles.stepPets__title}>
              Будут ли проживать питомцы?
            </h5>
            <span className={styles.stepPets__stepNum}>{stepTitle}</span>
          </div>

          <div className={styles.stepPets__descRadioWrapper}>
            <p className={styles.stepPets__desc}>
              При заселении с домашним животным, размер фиксированной платы за
              пользование помещением больше в 1,5 (полуторакратном) размере.
            </p>

            <div className={styles.stepPets__radioWrapper}>
              <RadioTabs
                currentValue={storePets.withPetsCurrent}
                items={storePets.withPetsOptions}
                onChange={_changeWithPets}
              />
            </div>
          </div>

          {storePets.withPets && (
            <div>
              <h5 className={styles.stepPets__petTitle}>Животное</h5>

              <div className={styles.stepPets__petsCards}>
                {storePets.petsForms?.map((pet, index, arr) => {
                  let isOnlyOneForm = arr.length === 1;
                  return (
                    <PetForm
                      showTrashCan={!isOnlyOneForm}
                      valueType={pet.type}
                      valueBreed={pet.breed}
                      valueVetPassport={pet.veterinaryPassport}
                      vetPassportOptions={pet.veterinaryOptions}
                      errorsType={pet.validationErrors.type}
                      errorsBreed={pet.validationErrors.breed}
                      errorsVetPassport={
                        pet.validationErrors.veterinaryPassport
                      }
                      changeType={(value: string) =>
                        storePets.changeType(value, pet.localId)
                      }
                      changeBreed={(value: string) =>
                        storePets.changeBreed(value, pet.localId)
                      }
                      changeVetPassport={(value: IOptionCommon) =>
                        storePets.changeVetPassport(value, pet.localId)
                      }
                      isDisabled={storePets.isLoading || isDisabledAll}
                      onDelete={() => storePets.openDeletePopup(pet.localId)}
                    />
                  );
                })}
              </div>

              <div className={styles.stepPets__addPetContainer}>
                <AddPersonBtn
                  title={"Добавить питомца"}
                  onClick={storePets.addPet}
                  theme="gold"
                  className={`${styles.stepPets__addPetBtn} ${
                    storePets.disabledAddPet
                      ? styles.stepPets__addPetBtn_disabled
                      : ""
                  }`}
                  disabled={storePets.isLoading || isDisabledAll}
                />
              </div>
            </div>
          )}

          {storePets.mainErrors.length > 0 && (
            <span className={styles.stepPets__mainError}>
              {storePets.mainErrors[0]}
            </span>
          )}
          <Button
            title={"Создать договор"}
            onClick={storePets.createAgreement}
            theme={"gold"}
            className={styles.stepPets__button}
            disabled={storePets.isLoading}
            isDisabledStyle={storePets.isLoading}
          />

          <ModalReuse
            isShowModal={storePets.isVisibleDeletePopup}
            type={"buttons"}
            btnLeft="Да"
            btnRight="Нет"
            onClick={() =>
              storePets.deletePet(storePets.currentIdForDelete ?? 0)
            }
            setIsShowModal={storePets.setIsVisibleDeletePopup}
          >
            <h2>Вы уверены, что хотите удалить питомца?</h2>
          </ModalReuse>
        </div>
      )
    );
  }
);
