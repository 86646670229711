import { FC, FormEvent } from "react";
import { observer } from "mobx-react-lite";

import InputMask from "react-input-mask";

import styles from "./styles/input.module.scss";

interface IProps {
  mask: string;
  placeholder?: string;
  label: string;
  value: string;
  errors: string[];
  className?: string;
  readOnly?: boolean;
  onChange(value: string): void;
  onBlur?(): void;
  onFocus?(): void;
}

export const InputWithMask: FC<IProps> = observer(
  ({
    mask = "999-999",
    placeholder = "",
    label = "",
    value = "",
    className = "",
    readOnly = false,
    errors = [],
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
  }) => {
    const _onChange = (e: FormEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    };
    return (
      <div className={`${styles.inputWithMask} ${className}`}>
        <label className={styles.inputWithMask__label}>{label}</label>
        <span className={styles.inputWithMask__inputWrapper}>
          <InputMask
            mask={mask}
            value={value}
            onChange={_onChange}
            placeholder={placeholder}
            type="text"
            readOnly={readOnly}
            className={`${styles.inputWithMask__input}`}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {errors?.length !== 0 && (
            <span className={styles.inputWithMask__errorText}>{errors[0]}</span>
          )}
        </span>
      </div>
    );
  }
);
