import { makeAutoObservable } from "mobx";
import { format } from "date-fns";
import { NavigateFunction } from "react-router-dom";

import { changeSpaceInNumber, declensionWordDays } from "../../../helpers";
import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";

import {
  BACK_TO_ORDERING_DATA,
  GENDER_FEMALE,
  GENDER_MALE,
  MY_APARTMENT_INFO_DEFAULT,
  OPTION_GENDER_FEMALE,
  OPTION_GENDER_MALE,
} from "../../../constants";
import { SCREENS } from "../../../navigation/endpoints";

import { IOrderApartmentInfo, ITenantStoreUI } from "../storeUI/interfaces";
import { IAdditionalRenter } from "../../../interfaces/AdditionalBookingData";

import { Store } from "../../../stores/types";
import { EBookingType, TGender } from "../../../types";

import { TenantStoreUI } from "./forms/tenantStoreUI";
import { IApartmentItem } from "../../../interfaces/Account";
import { ITenant } from "../../../interfaces/Tenants";

export class RenterStoreUI implements Store.IRenterOrderingData {
  navigate: NavigateFunction;
  formData: ITenantStoreUI | null = null;
  storeRoot: Store.IRootStore;
  storeMyApartment: Store.IMyApartment;
  storeAdditionalBookingData: Store.IAdditionalBookingData;
  storeAuthentication: Store.IStoreAuthentication | null = null;

  toStepTenants: Function = () => {};

  renterForSend: IAdditionalRenter | null = null;

  apartmentInfo: IOrderApartmentInfo = MY_APARTMENT_INFO_DEFAULT;

  isVisibleFillProfile: boolean = false;

  isLoading: boolean = false;
  isLoadingSend: boolean = false;

  bookingId: string | null = null;

  constructor(
    storeRoot: Store.IRootStore,
    storeAuthentication: Store.IStoreAuthentication | null,
    navigate: NavigateFunction,
    toStepTenants: Function
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
    this.storeMyApartment = storeRoot.storeMyApartment;
    this.storeAuthentication = storeAuthentication;
    this.storeAdditionalBookingData = storeRoot.storeAdditionalBookingData;
    this.navigate = navigate;
    this.toStepTenants = toStepTenants;

    this.formData = new TenantStoreUI();
  }

  // functions
  *init(bookingId: string) {
    // для проверки url
    yield this.storeRoot.getBookingsList(EBookingType.Active);

    if (
      this.storeAuthentication?.isAuth &&
      bookingId &&
      this.storeRoot.checkBookingIdByUrl(bookingId, EBookingType.Active)
    ) {
      this.setBookingId(bookingId);
    } else {
      this.navigate(SCREENS.SCREEN_404);
    }
  }

  fillFormsPrevEnteredData(tenants: ITenant[]) {
    this.setIsLoading(true);

    if (tenants.length) {
      let _renter = this.storeRoot.storeBookingResidents.tenants.find(
        (resident) => resident.renter === true
      );

      if (_renter) {
        this.formData?.changeFirstName(_renter.firstName || "");
        this.formData?.changeLastName(_renter.lastName || "");
        this.formData?.changeMiddleName(_renter.middleName || "");
        this.formData?.changeDateBirth(_renter.birthdate || "");
        this.formData?.changeDateBirth(
          this.reverseAndChangeSeparator(_renter.birthdate || "", "-", ".") ||
            ""
        );
        this.formData?.changePlaceBirth(_renter.placeBirth || "");

        this.formData?.changePhone(_renter.phone || "");
        this.formData?.changeGender(this.getGenderOption(_renter.gender));
        this.formData?.changeSeries(_renter.series || "", true);
        this.formData?.changeNumberPassport(_renter.number || "");
        this.formData?.changeDateIssue(
          this.reverseAndChangeSeparator(_renter.dateIssue, "-", ".") || ""
        );
        this.formData?.changeIssuedBy(_renter.issuedBy || "");
        this.formData?.changeSubdivisionCode(_renter.subdivisionCode || "");
        this.formData?.changeRegistrationAddress(
          _renter.registrationAddress || ""
        );

        this.formData?.changeEmail(_renter.email || "");
      }
    }
    this.setIsLoading(false);
  }

  *onContinue() {
    this.formData?.validateAllForRenter();
    this.getRenterForSend();

    if (
      this.formData?.validateAllForRenter() &&
      this.bookingId &&
      this.renterForSend
    ) {
      this.setIsLoadingSend(true);
      yield this.storeAdditionalBookingData.sendAdditionRenter(
        this.bookingId,
        this.renterForSend
      );
      this.setIsLoadingSend(false);
      if (this.storeAdditionalBookingData.isSuccessAdditionRenter) {
        this.toStepTenants();
      }
    }
  }

  *saveUpdatedProfileData(bookingId: string) {
    yield this.fillByProfile();

    this.getRenterForSend();

    if (bookingId && this.renterForSend) {
      this.setIsLoadingSend(true);
      yield this.storeAdditionalBookingData.sendAdditionRenter(
        bookingId,
        this.renterForSend
      );
      this.setIsLoadingSend(false);
    }
  }

  // проверка на заполненность профиля
  *checkFillProfile() {
    if (this.storeAuthentication?.isAuth) {
      this.setIsLoadingSend(true);
      yield this.storeRoot.checkFilledAllProfile(
        this.storeAuthentication.isAuth
      );
      this.setIsLoadingSend(false);
      return this.storeRoot.isFilledProfile;
    }

    return false;
  }

  *fillByProfile() {
    let _isFilled: boolean = yield this.checkFillProfile();
    if (_isFilled) {
      this.formData?.changeLastName(this.storeRoot.userProfile.lastName || "");
      this.formData?.changeFirstName(
        this.storeRoot.userProfile.firstName || ""
      );
      this.formData?.changeMiddleName(
        this.storeRoot.userProfile.middleName || ""
      );
      this.formData?.changeDateBirth(
        this.reverseAndChangeSeparator(
          this.storeRoot.userProfile?.birthdate || "",
          "-",
          "."
        ) || ""
      );
      this.formData?.changePlaceBirth(
        this.storeRoot.userProfile.placeBirth || ""
      );

      this.formData?.changePhone(this.storeRoot.userProfile.phone || "");
      this.formData?.changeGender(
        this.getGenderOption(this.storeRoot.userProfile.gender)
      );
      this.formData?.changeSeries(
        this.storeRoot.userProfile.passportSeries || "",
        true
      );
      this.formData?.changeNumberPassport(
        this.storeRoot.userProfile.passportNumber || ""
      );
      this.formData?.changeDateIssue(
        this.reverseAndChangeSeparator(
          this.storeRoot.userProfile.passportDateIssue,
          "-",
          "."
        ) || ""
      );
      this.formData?.changeIssuedBy(
        this.storeRoot.userProfile.passportIssued || ""
      );
      this.formData?.changeSubdivisionCode(
        this.storeRoot.userProfile.passportSubdivisionCode || ""
      );
      this.formData?.changeRegistrationAddress(
        this.storeRoot.userProfile.passportAddressRegister || ""
      );

      this.formData?.changeEmail(this.storeRoot.userProfile.email || "");
    } else {
      this.openFillProfile();
    }
  }

  // получить подсказки по строке
  *getHintByString(value: string) {
    yield this.storeRoot.userStore.getHintByString(value);

    if (this.storeRoot.userStore.hintStrings.length > 0) {
      return this.storeRoot.userStore.hintStrings;
    }

    return [];
  }

  writeApartmentInfo(apartment: IApartmentItem) {
    this.apartmentInfo = {
      apartmentName: this.getReadyName(apartment.rooms, apartment.square),
      floor: String(apartment.floor) || "0",
      apartmentNumber: String(apartment.flatNumber) || "0",
      period: `от: ${this.reverseAndChangeSeparator(
        apartment.startDate,
        "-",
        "."
      )} - до: ${this.reverseAndChangeSeparator(apartment.endDate, "-", ".")}`,
      amountDays: apartment.datePeriod
        ? `${apartment.datePeriod} ${declensionWordDays(apartment.datePeriod)}`
        : "",
      monthPrice: changeSpaceInNumber(apartment.monthPrice || 0),
      deposit: changeSpaceInNumber(apartment.monthPrice || 0),
      canPets: apartment.canPets ?? false,
    };
  }

  onClickHintAddress(value: string) {
    this.formData?.changeRegistrationAddress(value);
    this.formData?.setHintStringsAddress([]);
  }

  onClickHintPlaceBirth(value: string) {
    this.formData?.changePlaceBirth(value);
    this.formData?.setHintStringsPlaceBirth([]);
  }

  // ---- changes
  *changeRegistrationAddress(value: string) {
    this.formData?.changeRegistrationAddress(value);

    if (value.length > 0) {
      let _hints: string[] = yield this.getHintByString(value);

      this.formData?.setHintStringsAddress(_hints);
    }
  }

  *changePlaceBirth(value: string) {
    this.formData?.changePlaceBirth(value);

    if (value.length > 0) {
      let _hints: string[] = yield this.getHintByString(value);
      this.formData?.setHintStringsPlaceBirth(_hints);
    }
  }

  // ---- helpers
  getGenderOption(value: TGender) {
    switch (value) {
      case GENDER_MALE:
        return OPTION_GENDER_MALE;
      case GENDER_FEMALE:
        return OPTION_GENDER_FEMALE;
      default:
        return null;
    }
  }

  getRenterForSend() {
    if (this.formData) {
      // подготовка данных арендатора для отправки на бэк
      let _gender: TGender = GENDER_MALE;

      if (
        this.formData.gender?.value === GENDER_MALE ||
        this.formData.gender?.value === GENDER_FEMALE
      ) {
        _gender = this.formData.gender?.value;
      }

      this.renterForSend = {
        lastName: this.formData.lastName,
        firstName: this.formData.firstName,
        birthdate: this.reverseAndChangeSeparator(
          this.formData.birthdate,
          ".",
          "-"
        ),
        phone: this.formData.phone.replaceAll(" ", ""),
        placeBirth: this.formData.placeBirth,
        gender: _gender,
        series: this.formData.series,
        number: this.formData.number,
        dateIssue: this.reverseAndChangeSeparator(
          this.formData.dateIssue,
          ".",
          "-"
        ),
        issuedBy: this.formData.issuedBy,
        registrationAddress: this.formData.registrationAddress,
        subdivisionCode: this.formData.subdivisionCode,
        email: this.formData.email,
      };

      if (this.formData.middleName.length > 0) {
        this.renterForSend.middleName = this.formData.middleName;
      }
    }
  }

  getReadyName(roomsTitle: string, square: number) {
    let _square = square ? Math.round(square) : 0;
    return `${roomsTitle ? roomsTitle : ""}, ${String(_square)} м²`;
  }

  reverseAndChangeSeparator(
    str: string,
    fromSeparator: string,
    toSeparator: string
  ) {
    if (str && fromSeparator && toSeparator) {
      let elemArr = str.split(fromSeparator).reverse();
      return elemArr.join(toSeparator);
    }
    return "";
  }

  convertDate(date: string | Date) {
    return format(new Date(date), "dd.MM.yyyy");
  }

  // ---- modals
  openFillProfile() {
    this.setIsVisibleFillProfile(true);
    disableScroll();
  }

  // ---- navigation
  toCompany() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_COMPANY);
    enableScroll();
  }

  toProfile() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_PERSONAL, {
      // передаем параметр при переходе в профиль, что нужно вернуться на оформление после сохранения
      state: { toBack: BACK_TO_ORDERING_DATA, bookingId: this.bookingId },
    });
    enableScroll();
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingSend(value: boolean) {
    this.isLoadingSend = value;
  }

  setIsVisibleFillProfile(value: boolean) {
    this.isVisibleFillProfile = value;
  }

  setBookingId(value: string | null) {
    this.bookingId = value;
  }
}
