import { observer } from "mobx-react-lite";

import { Button } from "../../../../../components/Button";
import { ServiceCard } from "../ServiceCard";
import Pagination from "../../../../../components/Pagintaion";
import { Loader } from "../../../../../components/Loader";

import { IServicesSelected } from "../storeUI/interfaces";
import { IPaginationData } from "../../../../../interfaces";

import { ETabsServices } from "../../../../../types";

import { ReactComponent as ServiceEmpty } from "../../../../../assets/images/serviceEmpty.svg";

import styles from "./styles/selected.module.scss";

interface IProps {
  services: IServicesSelected[] | null;
  paginationData: IPaginationData | null;
  errors: string[];
  isLoading: boolean;
  isLoadingCreateBill: boolean;
  isSuccessCreateBill: boolean;
  onClickPay(): void;
  toAvailableServices(): void;
  openPopupDelete(serviceId: number): void;
  onClickSelectDate(serviceId: number): void;
  toBills(): void;
}

export const SelectedServices = observer(
  ({
    services = null,
    paginationData = null,
    errors = [],
    isLoading = false,
    isLoadingCreateBill = false,
    isSuccessCreateBill = false,
    toAvailableServices = () => {},
    toBills = () => {},
    onClickSelectDate = () => {},
    onClickPay = () => {},
    openPopupDelete = () => {},
  }: IProps) => {
    return (
      <div className={styles.selectedServices}>
        {isLoading ? (
          <div className={styles.selectedServices__loadingBlock}>
            <Loader className={styles.selectedServices__mainLoader} />
          </div>
        ) : (
          <div>
            {isLoadingCreateBill && <Loader />}
            {services?.length ? (
              <>
                <div className={styles.selectedServices__cardsWrapper}>
                  {services.map((item) => (
                    <ServiceCard
                      typeTab={ETabsServices.Selected}
                      id={item.id}
                      key={item.id}
                      image={item.image}
                      serviceTitle={item.name}
                      descriptionService={item.description}
                      price={item.price}
                      dateOfUse={item.date}
                      status={item.status}
                      isPaid={item.isPaid}
                      haveDate={item.haveDate}
                      onClickDelete={openPopupDelete}
                      onClickSelectDate={onClickSelectDate}
                      disabledButtons={
                        isLoadingCreateBill || isSuccessCreateBill
                      }
                    />
                  ))}
                </div>

                <div className={styles.selectedServices__textButtonWrapper}>
                  <span className={styles.selectedServices__text}>
                    Для оплаты будет сформирован счет
                  </span>

                  {isSuccessCreateBill ? (
                    <Button
                      title={"Перейти в счета"}
                      onClick={toBills}
                      theme={"gold"}
                      className={styles.selectedServices__billButton}
                      disabled={isLoadingCreateBill}
                      isDisabledStyle={isLoadingCreateBill}
                    />
                  ) : (
                    <Button
                      title={"Оплатить"}
                      onClick={onClickPay}
                      theme={"gold"}
                      className={styles.selectedServices__payButton}
                      disabled={isLoadingCreateBill}
                      isDisabledStyle={isLoadingCreateBill}
                    />
                  )}

                  {errors.length > 0 && (
                    <span className={styles.selectedServices__error}>
                      {errors[0]}
                    </span>
                  )}
                </div>

                {paginationData && (
                  <Pagination
                    onPageChange={paginationData.onPageChange}
                    totalCount={paginationData.totalCount}
                    siblingCount={paginationData.siblingCount}
                    currentPage={paginationData.currentPage}
                    pageSize={paginationData.pageSize}
                    className={styles.selectedServices__pagination}
                    disabled={isLoadingCreateBill}
                  />
                )}
              </>
            ) : (
              <div className={styles.selectedServices__cardsEmpty}>
                <ServiceEmpty />
                <p className={styles.selectedServices__cardsEmptyTitle}>
                  Вы пока не добавили услуги
                </p>
                <Button
                  title={"Выбрать услугу"}
                  onClick={toAvailableServices}
                  theme={"gold"}
                  className={styles.selectedServices__cardsEmptyBtn}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
