import { FC } from "react";

import styles from "./styles/slide.module.scss";

interface IProps {
  img: string;
  className?: string;
}

export const Slide: FC<IProps> = ({ img = "", className = "" }: IProps) => {
  return (
    <div className={`${styles.slide} ${className}`}>
      <img className={styles.slide__slideImg} src={img} alt="slide" />
    </div>
  );
};
