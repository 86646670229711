import { makeAutoObservable } from "mobx";

import { getText } from "../../../api/TextPage";

import { TResponseApi } from "../../../api/types";
import { TTextPage } from "../../../types";
import { Store } from "../../types";

export class StoreTextModel implements Store.ITextPage {
  contentText: string = "";
  title: string = "";

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  *getTextPage(textPageCode: string) {
    let response: TResponseApi<TTextPage> = yield getText(textPageCode);
    if (response.isSuccess && response.data) {
      this.setContentText(response.data.text);
      this.setTitle(response.data.title || "");
    }
  }

  setContentText(value: string) {
    this.contentText = value;
  }

  setTitle(value: string) {
    this.title = value;
  }
}

export const StoreTextPage = new StoreTextModel();
