import { makeAutoObservable } from "mobx";

import {
  getBookingById,
  getCanExtension,
  sendBookingExtension,
} from "../../../api/Account";

import { TOO_MANY_REQUEST, TOO_MANY_REQUEST_RU } from "../../../constants";

import {
  IProlongBookingResponse,
  ICanExtensionResponse,
} from "../../../interfaces";
import { IBookingApartmentDetail } from "../../../interfaces/Account";

import { Store } from "../../types";
import { TResponseApi } from "../../../api/types";

export class ModelMyApartment implements Store.IMyApartment {
  apartmentDetail: IBookingApartmentDetail | null = null;
  canExtension: boolean = false;
  responseExtension: IProlongBookingResponse | null = null;
  errorsSendBooking: string[] = [];

  apartmentHaveArrivalDate: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getBookingById(bookingId: number) {
    this.setApartmentDetail(null);
    this.setApartmentHaveArrivalDate(false);

    let response: TResponseApi<IBookingApartmentDetail> = yield getBookingById(
      bookingId
    );

    if (response.isSuccess && response.data !== null) {
      this.setApartmentDetail(response.data);

      this.setApartmentHaveArrivalDate(
        Boolean(this.apartmentDetail?.bookingCalendar?.arrivalDate)
      );
    }
  }

  *getCanExtension(bookingCalendarId: number) {
    let response: TResponseApi<ICanExtensionResponse> = yield getCanExtension({
      bookingCalendarId,
    });

    if (response.isSuccess && response.data !== null) {
      this.setCanExtension(response.data.result);
    } else {
      this.setCanExtension(false);
    }
  }

  *sendBookingExtension(date: string, bookingCalendarId: number) {
    this.setResponseExtension(null);

    let response: TResponseApi<IProlongBookingResponse> =
      yield sendBookingExtension({
        date,
        bookingCalendarId,
      });

    this.clearErrorsSendBooking();

    if (response.isSuccess && response.data !== null) {
      this.setResponseExtension(response.data);
    }

    if (response.isError) {
      if (response.error?.message) {
        if (response.error.message.toLowerCase() === TOO_MANY_REQUEST) {
          this.addErrorSendBooking(TOO_MANY_REQUEST_RU);
        } else {
          this.addErrorSendBooking(response.error?.message);
        }
      }
    }
  }

  addErrorSendBooking(value: string) {
    this.errorsSendBooking.push(value);
  }

  clearErrorsSendBooking() {
    this.errorsSendBooking = [];
  }

  // setters

  setApartmentDetail(value: IBookingApartmentDetail | null) {
    this.apartmentDetail = value;
  }

  setCanExtension(value: boolean) {
    this.canExtension = value;
  }

  setResponseExtension(value: IProlongBookingResponse | null) {
    this.responseExtension = value;
  }

  setApartmentHaveArrivalDate(value: boolean) {
    this.apartmentHaveArrivalDate = value;
  }
}
