import { TResponseApi } from "../types";

import { ApiManager } from "../index";

import { ICommonResponse } from "./../../interfaces/index";
import {
  IAvailableService,
  IServicesResponse,
} from "../../interfaces/Services";

import {
  BASKET_SERVICE,
  SERVICE,
  SERVICE_AVAILABLE,
} from "../../constants/api";

export async function getServices(params: {
  limit: number;
}): Promise<TResponseApi<IServicesResponse>> {
  return await ApiManager.request<IServicesResponse>(
    {
      url: SERVICE,
      method: "GET",
      params: params,
    },
    true
  );
}

export async function getAvailableServices(): Promise<
  TResponseApi<IAvailableService[]>
> {
  return await ApiManager.request<IAvailableService[]>(
    {
      url: SERVICE_AVAILABLE,
      method: "GET",
    },
    true
  );
}

export async function addServiceToBasket(params: {
  serviceId: number;
}): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BASKET_SERVICE,
      method: "POST",
      data: params,
    },
    true
  );
}

export async function deleteServiceToBasket(params: {
  basketServiceId: number;
}): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BASKET_SERVICE,
      method: "DELETE",
      data: params,
    },
    true
  );
}
