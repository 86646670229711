import { makeAutoObservable } from "mobx";

import { getBookings } from "../../../api/Account";
import { TResponseApi } from "../../../api/types";

import { IBookingItem } from "../../../interfaces/Account";
import { Store } from "../../types";

export class ModelMyBookings implements Store.IMyBookings {
  bookings: IBookingItem[] = [];
  bookingsIdSet: Set<number> = new Set();

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getBookings() {
    let response: TResponseApi<IBookingItem[]> = yield getBookings();

    if (response.isSuccess && response.data !== null) {
      this.clearBookingIdSet();

      this.setBookings(response.data);
      response.data?.map((item) => this.addBookingId(item.id));
    }
  }

  addBookingId(value: number) {
    this.bookingsIdSet.add(value);
  }

  clearBookingIdSet() {
    this.bookingsIdSet.clear();
  }

  checkBookingId(bookingId: string) {
    return this.bookingsIdSet.has(Number(bookingId));
  }

  // setters

  setBookings(value: IBookingItem[]) {
    this.bookings = value;
  }
}
