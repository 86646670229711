import { makeAutoObservable } from "mobx";

import { sendAmoFeedback, sendAmoLeaveRequest } from "../../../api/Amo";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

import { ICommonResponse } from "../../../interfaces";
import { IAmoLeaveRequest } from "../../../interfaces/Amo";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

export class ModelAmo implements Store.IAmo {
  errorsServer: string[] = [];

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  *sendAmoFeedback(name: string, phone: string) {
    let _phone = phone.replaceAll(" ", "");

    let response: TResponseApi<ICommonResponse> = yield sendAmoFeedback({
      name: name,
      phone: _phone,
    });

    if (response.isError) {
      this.addErrorServer(ERROR_TEXT_SERVER_COMMON);
    }

    return response.isSuccess;
  }

  *sendAmoLeaveRequest(params: IAmoLeaveRequest) {
    let _phone = params.phone.replaceAll(" ", "");

    let _params: IAmoLeaveRequest = {
      apartmentId: params.apartmentId,
      name: params.name,
      phone: _phone,
      startDate: params.startDate,
      endDate: params.endDate,
      email: params.email,
    };

    let response: TResponseApi<ICommonResponse> = yield sendAmoLeaveRequest(
      _params
    );

    if (response.isError) {
      this.addErrorServer(ERROR_TEXT_SERVER_COMMON);
    }

    return response.isSuccess;
  }

  addErrorServer(value: string) {
    this.errorsServer = [...this.errorsServer, value];
  }
}
