import { FC } from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

import { Slider } from "./Slider";

import { ISliderItem } from "../../../interfaces/Apartment";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/sliderPhoto.module.scss";
import "swiper/scss/navigation";

const customAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface IProps {
  modal: boolean;
  closeModal(): void;
  isEnabledAllows?: boolean;
  slides: ISliderItem[];
}

export const SliderPhoto: FC<IProps> = ({
  modal = false,
  closeModal = () => {},
  slides = [],
  isEnabledAllows = true,
}) => {
  return (
    <>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.modal__popup}>
            <Close className={styles.modal__close} onClick={closeModal} />
            <Reveal keyframes={customAnimation}>
              <div className={styles.modal__content}>
                <Slider slides={slides} isEnabledAllows={isEnabledAllows} />
              </div>
            </Reveal>
          </div>
        </div>
      )}
    </>
  );
};
