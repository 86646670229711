import { FC, useEffect, useState } from "react";

import { Button } from "../../Button";
import { CheckBox } from "../../CheckBox";
import { Loader } from "../../Loader";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as PdfIcon } from "../../../assets/images/pdfIcon.svg";

import { PDF_NAME_AGREEMENT } from "../../../constants";

import styles from "./styles/agreement.module.scss";

interface IProps {
  modal: boolean;
  linkAgreement: string;
  isChecked: boolean;
  checkboxErrors: string[];
  errors: string[];
  isLoading: boolean;
  setModal: (modal: boolean) => void;
  onClickConfirm(): void;
  changeCheckbox(): void;
  clickAgreement(): void;
}

export const Agreement: FC<IProps> = ({
  modal = false,
  isChecked = false,
  isLoading = false,
  linkAgreement = "",
  setModal = () => {},
  onClickConfirm = () => {},
  changeCheckbox = () => {},
  clickAgreement = () => {},
  checkboxErrors = [],
  errors = [],
}) => {
  let [isDownload, setIsDownload] = useState(false);
  let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
    useState<EventTarget | null>(null);

  const closeModal = () => {
    setModal(false);
    enableScroll();
  };

  const _closeModal = () => {
    if (backgroundOnDownTarget) {
      closeModal();
      enableScroll();
    }

    setBackgroundOnDownTarget(null);
  };

  useEffect(() => {
    setIsDownload(window.innerWidth < 576);
  }, []);

  return (
    <>
      {modal && (
        <div
          className={styles.agreement}
          onClick={_closeModal}
          onMouseDown={(e) => {
            setBackgroundOnDownTarget(e.target);
          }}
        >
          <div className={styles.agreement__popupWrapper}>
            {isLoading && <Loader className={styles.agreement__loader} />}
            <div
              className={styles.agreement__popup}
              onMouseDown={stopPropagation}
              onClick={stopPropagation}
            >
              <Close className={styles.agreement__close} onClick={closeModal} />
              <div className={styles.agreement__content}>
                <h3 className={styles.agreement__title}>
                  Ознакомьтесь с договором Аренды жилого помещения
                </h3>
                {isDownload ? (
                  <a
                    href={linkAgreement}
                    download={PDF_NAME_AGREEMENT}
                    className={styles.agreement__linkBtn}
                  >
                    <span className={styles.agreement__iconWrapper}>
                      <PdfIcon />
                    </span>
                    <p className={styles.agreement__linkText}>
                      Договор аренды жилого помещения
                    </p>
                  </a>
                ) : (
                  <button
                    onClick={clickAgreement}
                    className={styles.agreement__linkBtn}
                    disabled={isLoading}
                  >
                    <span className={styles.agreement__iconWrapper}>
                      <PdfIcon />
                    </span>
                    <p className={styles.agreement__linkText}>
                      Договор аренды жилого помещения
                    </p>
                  </button>
                )}

                <div className={styles.agreement__acceptBlock}>
                  {errors.length > 0 && (
                    <span className={styles.agreement__mainError}>
                      {errors[0]}
                    </span>
                  )}

                  <Button
                    title="Подтвердить"
                    type="submit"
                    theme="gold"
                    onClick={onClickConfirm}
                    className={styles.agreement__button}
                    isDisabledStyle={!isChecked || isLoading}
                    disabled={isLoading}
                  />
                  <div className={styles.agreement__checkbox}>
                    <CheckBox checked={isChecked} setChecked={changeCheckbox} />
                    <p className={styles.agreement__accept}>
                      Я согласен с условиями договора
                    </p>

                    {checkboxErrors?.length !== 0 && (
                      <span className={styles.agreement__errorText}>
                        {checkboxErrors[0]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
