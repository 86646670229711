// получить координаты элемента относительно окна
export function getCoordinatesByWindow(
  elementRef: React.RefObject<HTMLDivElement | HTMLButtonElement>
) {
  let rect = elementRef.current?.getBoundingClientRect();
  let scrollLeft: number | null = null;
  let scrollTop: number | null = null;

  if (rect) {
    scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    scrollTop = window.scrollY || document.documentElement.scrollTop;

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  return {
    top: null,
    left: null,
  };
}
