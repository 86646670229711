import { ApiManager } from "../index";

import { APARTMENT_PLAN_BY_ID } from "../../constants/api";

import { IApartmentPlanResponse } from "../../interfaces/FlatPlan";

import { TResponseApi } from "../types";

export async function getApartmentPlan(
  apartmentId: number
): Promise<TResponseApi<IApartmentPlanResponse>> {
  return await ApiManager.request<IApartmentPlanResponse>({
    method: "GET",
    url: APARTMENT_PLAN_BY_ID(apartmentId),
  });
}
