import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useOutSide } from "../../hooks/useOutSide";

import { Input } from "../Input";

import styles from "./styles/input.module.scss";

interface IProps {
  placeholder?: string;
  label: string;
  value: string;
  errors: string[];
  hintStrings: string[];
  className?: string;
  readonly?: boolean;
  maxLength?: number | undefined;
  onChange(value: string): void;
  onClickHint(value: string): void;
  onBlur?(): void;
}

export const InputWithHint: FC<IProps> = observer(
  ({
    placeholder = "",
    label = "",
    value = "",
    className = "",
    readonly = false,
    errors = [],
    hintStrings = [],
    maxLength = undefined,
    onChange = () => {},
    onClickHint = () => {},
    onBlur = () => {},
  }) => {
    const { ref, isShow, setIsShow } = useOutSide(
      hintStrings.length > 0 && !readonly
    );

    const _onChange = (value: string) => {
      onChange(value);
      setIsShow(true);
    };

    return (
      <div
        className={`${styles.inputWithHint} ${className}`}
        onClick={() => {
          setIsShow(false);
        }}
        ref={ref}
      >
        <Input
          className={styles.inputWithHint__input}
          label={label}
          value={value}
          errors={errors}
          onChange={_onChange}
          placeholder={placeholder}
          onBlur={onBlur}
          readonly={readonly}
          maxLength={maxLength}
        />

        {isShow && (
          <div className={styles.inputWithHint__hintsWrapper}>
            {hintStrings.map((item, index) => (
              <span
                key={index}
                onClick={() => onClickHint(item)}
                className={styles.inputWithHint__hintItem}
              >
                {item}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  }
);
