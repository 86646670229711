import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Favorite } from "./Favorite";
import { Empty } from "../../../../components/Empty";
import { Loader } from "../../../../components/Loader";
import { ModalReuse } from "../../../../components/Modals/ModalReuse";

import { StoreUI } from "./storeUI";

import { ReactComponent as Lock } from "../../../../assets/images/lock.svg";

import styles from "./styles/favorites.module.scss";

export const Favorites: FC = observer(() => {
  const navigate = useNavigate();
  const [storeUI] = useState(() => new StoreUI(navigate));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  return (
    <>
      {!storeUI.isLoading ? (
        storeUI.favorites?.length ? (
          <div className={styles.content}>
            <h2 className={styles.content__title}>
              Избранное{" "}
              <span className={styles.content__count}>
                ({storeUI.favoritesCount})
              </span>
            </h2>
            <div className={styles.content__favorites}>
              {storeUI.favorites?.map((fav) => (
                <Favorite
                  key={fav.id}
                  image={fav.image}
                  floor={fav.floor}
                  roomLabel={fav.roomLabel}
                  price={fav.price}
                  square={fav.square}
                  toApartment={() => storeUI.toApartment(fav.id)}
                  openDeleteModal={() => storeUI.openDeleteModal(fav.id)}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.content__empty}>
            <Empty
              title="Вы пока ничего не добавили в избранное"
              titleBtn="ВЫБРАТЬ КВАРТИРУ"
              Icon={Lock}
              onClick={storeUI.toApartments}
            />
          </div>
        )
      ) : (
        <Loader />
      )}
      <ModalReuse
        isShowModal={storeUI.isDeleteModal}
        setIsShowModal={storeUI.closeDeleteModal}
        onClick={() => storeUI.deleteCard(storeUI.apartmentId)}
        btnLeft="да"
        btnRight="нет"
        type="buttons"
        isLoading={storeUI.isLoadingDelete}
      >
        <h2>Вы уверены, что хотите удалить квартиру из избранного?</h2>
      </ModalReuse>
    </>
  );
});
