import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../../../../components/Button";
import { MenuDots } from "../../../../../../components/MenuDots";
import { Loader } from "../../../../../../components/Loader";

import { useOutSide } from "../../../../../../hooks/useOutSide";

import { STATUS_AGREEMENT_TERMINATED } from "../../../../../../constants";

import { ReactComponent as Menu } from "../../../../../../assets/images/menuDots.svg";

import styles from "./styles/agreement.module.scss";

interface IProps {
  id: number;
  numberDocument: string;
  status: string;
  isLoadingDownload: boolean;
  isUploaded: boolean;
  onClickDownload(id: number, isUploaded: boolean): void;
  onShowAgreement(id: number, isUploaded: boolean): void;
}

export const Agreement: FC<IProps> = observer(
  ({
    id = 0,
    numberDocument = "",
    status = "",
    isLoadingDownload = false,
    isUploaded = false,
    onShowAgreement = () => {},
    onClickDownload = () => {},
  }) => {
    const { ref, isShow, setIsShow } = useOutSide(false);

    return (
      <div className={styles.agreement}>
        <div className={styles.agreement__numberStatusWrapper}>
          <h5 className={styles.agreement__number}>
            {!isUploaded && "№"}
            {numberDocument}
          </h5>
          <h6
            className={`${
              status === STATUS_AGREEMENT_TERMINATED &&
              styles.agreement__dissolved
            } ${styles.agreement__status}`}
          >
            {status}
          </h6>
        </div>
        <div className={styles.agreement__buttons}>
          <Button
            title="Просмотр"
            theme="white"
            onClick={() => onShowAgreement(id, isUploaded)}
            className={styles.agreement__button}
            disabled={isLoadingDownload}
            isDisabledStyle={isLoadingDownload}
          />

          {!isLoadingDownload && (
            <Button
              title="Скачать"
              theme="white"
              onClick={() => onClickDownload(id, isUploaded)}
              className={styles.agreement__downloadAdaptive}
            />
          )}

          {isLoadingDownload ? (
            <div className={styles.agreement__menuLoading}>
              <Loader className={styles.agreement__loader} />
            </div>
          ) : (
            <span
              className={styles.agreement__menuWrapper}
              onClick={() => setIsShow(!isShow)}
              ref={ref}
            >
              <Menu className={styles.agreement__menu} />
              {isShow && (
                <MenuDots
                  className={styles.agreement__dropList}
                  onClickDownlooad={() => onClickDownload(id, isUploaded)}
                />
              )}
            </span>
          )}
        </div>
      </div>
    );
  }
);
