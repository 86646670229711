import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../../../components/Button";
import { Hint } from "../../../../../components/Hint";
import { Loader } from "../../../../../components/Loader";

import styles from "./styles/bookingCard.module.scss";

interface IProps {
  bookingId: string;
  apartmentName: string;
  apartmentNumber: string;
  floor: string;
  withWarning?: boolean;
  warningText?: string | string[];
  isLoadingButtons: boolean;
  isReplacedButton?: boolean;
  replacedBtnTitle?: string;
  isExtension: boolean;
  disabledReplaceButton: boolean;
  onClickReplacedBtn?(): void;
  onClickRent(bookingId: string): void;
  onClickDocuments(bookingId: string): void;
  onClickResidents(bookingId: string): void;
  onClickServices(bookingId: string): void;
}

export const BookingCard: FC<IProps> = observer(
  ({
    bookingId = "",
    apartmentName = "",
    apartmentNumber = "",
    floor = "",
    withWarning = false,
    warningText = "",
    isReplacedButton = false,
    replacedBtnTitle = "",
    isLoadingButtons = false,
    isExtension = false,
    disabledReplaceButton = false,
    onClickReplacedBtn = () => {},
    onClickRent = () => {},
    onClickDocuments = () => {},
    onClickResidents = () => {},
    onClickServices = () => {},
  }: IProps) => {
    return (
      <div className={styles.bookingCard}>
        <div className={styles.bookingCard__header}>
          <div className={styles.bookingCard__nameHintWrapper}>
            <span className={styles.bookingCard__name}>{apartmentName}</span>
            {withWarning && !isReplacedButton && (
              <Hint
                text={warningText}
                className={styles.bookingCard__warning}
                classNameIcon={styles.bookingCard__warningIcon}
                classNameText={styles.bookingCard__warningText}
              />
            )}

            {isExtension && (
              <span className={styles.bookingCard__infoLabel}>Продление</span>
            )}
          </div>

          <div className={styles.bookingCard__headerRight}>
            <span>№{apartmentNumber}</span>
            <span>Этаж: {floor}</span>
          </div>
        </div>

        {isLoadingButtons ? (
          <div className={styles.bookingCard__loadingPawnBlock}>
            <Loader className={styles.bookingCard__pawnLoader} />
          </div>
        ) : (
          <>
            {isReplacedButton ? (
              <Button
                title={replacedBtnTitle}
                onClick={onClickReplacedBtn}
                theme={"gold"}
                className={styles.bookingCard__replacedButton}
                disabled={disabledReplaceButton}
                isDisabledStyle={disabledReplaceButton}
              />
            ) : (
              <div className={styles.bookingCard__buttonsWrapper}>
                <Button
                  title={"Квартира"}
                  onClick={() => onClickRent(bookingId)}
                  className={styles.bookingCard__button}
                  theme={"transparent"}
                />
                <Button
                  title={"Документы"}
                  onClick={() => onClickDocuments(bookingId)}
                  className={styles.bookingCard__button}
                  theme={"transparent"}
                />
                <Button
                  title={"Жильцы"}
                  onClick={() => onClickResidents(bookingId)}
                  className={styles.bookingCard__button}
                  theme={"transparent"}
                />
                <Button
                  title={"Услуги"}
                  onClick={() => onClickServices(bookingId)}
                  className={styles.bookingCard__button}
                  theme={"transparent"}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);
