import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";

import { Loader } from "../../../../../components/Loader";
import { OtherCard } from "./OtherCard";

import { RootContext } from "../../../../../stores/Root";
import { StoreUI } from "./storeUI";

import styles from "./styles/others.module.scss";

interface IProps {
  bookingId: number;
  isArchiveMode?: boolean;
}

export const DocumentOthers = observer(
  ({ bookingId = 0, isArchiveMode = false }: IProps) => {
    const rootStore = useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(rootStore));

    useEffect(() => {
      (async () => {
        await storeUI.init(bookingId);
      })();
    }, [storeUI, bookingId]);

    return (
      <div className={styles.documentOthers}>
        {storeUI.isMainLoading ? (
          <div className={styles.documentOthers__mainLoad}>
            <Loader className={styles.documentOthers__loader} />
          </div>
        ) : (
          <div className={styles.documentOthers__cardsContainer}>
            {storeUI.otherDocuments?.length > 0 ? (
              <>
                {storeUI.otherDocuments.map((item) => (
                  <OtherCard
                    key={item.id}
                    id={item.id}
                    titleDocument={item.title}
                    date={item.date}
                    isLoadingDownload={item.id === storeUI.currentLoadingId}
                    onShowDocument={storeUI.onShowPdf}
                    onClickDownload={storeUI.onDownloadPdf}
                  />
                ))}
              </>
            ) : (
              <div className={styles.documentOthers__empty}>
                <span>
                  {isArchiveMode
                    ? "У вас нет других документов"
                    : "У вас пока нет других документов"}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
