import { FC } from "react";
import DatePicker from "react-datepicker";
import { observer } from "mobx-react-lite";

import { IDatesInterval } from "../../interfaces";
import { TDateMinMax } from "../../types";

import styles from "./styles/dateInline.module.scss";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

interface IProps {
  currentDay: Date | null;
  disabledDates?: IDatesInterval[];
  minDate?: TDateMinMax;
  maxDate?: TDateMinMax;
  changeCurrentDay(date: Date): void;
  className?: string;
  getIsActiveDay?(date: Date): boolean;
}

export const DateInline: FC<IProps> = observer(
  ({
    currentDay = null,
    minDate = "disable",
    maxDate = "disable",
    disabledDates = [],
    changeCurrentDay = () => {},
    getIsActiveDay = () => true,
    className = "",
  }: IProps) => {
    const getDate = (date: TDateMinMax) => {
      switch (date) {
        case "disable":
          return undefined;
        case "now":
          return new Date();
        case null:
          return new Date();
        default:
          return new Date(date);
      }
    };

    let _minDate = getDate(minDate);
    let _maxDate = getDate(maxDate);

    return (
      <div className={`${styles.pickerInline} ${className}`}>
        <DatePicker
          renderCustomHeader={({
            monthDate,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
          }) => (
            <div>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("ru-RU", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
          selected={currentDay}
          onChange={(date: Date) => changeCurrentDay(date)}
          inline
          shouldCloseOnSelect={false}
          className={styles.picker}
          minDate={_minDate}
          maxDate={_maxDate}
          excludeDateIntervals={disabledDates ? disabledDates : undefined}
          filterDate={getIsActiveDay}
          showDisabledMonthNavigation={false}
        />
      </div>
    );
  }
);
