import { NavigateFunction } from "react-router-dom";

import { Store } from "../../../stores/types";

export interface IStoreUI {
  storePayment: Store.IPayment;
  navigate: NavigateFunction;
  code: string | null;
  state: string | null;
  nonce: string | null;
  isLoading: boolean;
  isSuccessSberAuth: boolean;
}

export enum EPaymentStatus {
  Succeeded = "succeeded",
  Canceled = "canceled",
  Pending = "pending",
}
