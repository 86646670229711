import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";

import { ReactComponent as HintIcon } from "../../assets/images/hint-mini.svg";

import styles from "./styles/hint.module.scss";

interface IProps {
  text: string | string[];
  classNameText?: string;
  classNameIcon?: string;
  className?: string;
  visibleFromParent?: boolean;
  withVisibleFromParent?: boolean;
}

export const Hint = observer(
  ({
    withVisibleFromParent = false,
    visibleFromParent = false,
    text = "",
    className = "",
    classNameText = "",
    classNameIcon = "",
  }: IProps) => {
    let [visible, setVisible] = useState(false);

    let currentVisible = useMemo(() => {
      return withVisibleFromParent ? visibleFromParent : visible;
    }, [visible, visibleFromParent, withVisibleFromParent]);

    return (
      <div className={`${styles.hint} ${className}`}>
        <HintIcon
          className={`${styles.hint__icon} ${classNameIcon}`}
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        />
        {text.length > 0 && (
          <div
            className={`${styles.hint__text} ${
              currentVisible ? styles.hint__text_visible : ""
            } ${classNameText}`}
          >
            {Array.isArray(text)
              ? text.map((item, index) => <p key={index}>{item}</p>)
              : text}
          </div>
        )}
      </div>
    );
  }
);
