import { makeAutoObservable } from "mobx";
import {
  format,
  addDays,
  differenceInCalendarDays,
  isBefore,
} from "date-fns";
import { NavigateFunction } from "react-router-dom";
import { Validator } from "validator-pyrobyte";

import {
  ERROR_TEXT_CHARS_NUM,
  ERROR_TEXT_EMPTY,
  ERROR_TEXT_PHONE,
  FORMAT_DAY_MONTH,
  MAX_COUNT_PETS,
  MAX_DAYS_FROM_START_BOOKING,
  MIN_NUMBER_DAYS_OF_RENT,
  MIN_NUMBER_DAYS_OF_RENT_PREV,
  NUMBER_RANGE_DAYS_FOR_DATE_DEPARTURE,
  ONE_ROOMS_NAME,
  PRICE_ONE_PET_FOR_BOOKING,
  TODAY,
} from "../../../../constants";
import { SCREENS } from "../../../../navigation/endpoints";
import { newRules } from "../../../../helpers/validation";
import { disableScroll, enableScroll } from "../../../../helpers/modalsFunc";
import { ERROR_BOTH_DATES } from "../../../../constants";

import { IErrors, IErrorsValid, IStoreUI } from "./interfaces";

import { ModelApartment } from "../../../../stores/models/Apartment/apartment";
import { Store } from "../../../../stores/types";
import { getNextDay } from "../../../../helpers/getNextDay";

export class StoreUI implements IStoreUI {
  storeApartment: Store.IApartment;
  storeAmo: Store.IAmo;
  storeAuthentication: Store.IStoreAuthentication;
  storeRoot: Store.IRootStore;
  navigate: NavigateFunction;
  validator: Validator;
  isLoading: boolean = false;
  isLoadingModal: boolean = false;
  isLoadingReviews: boolean = false;
  isLoadingBooking: boolean = false;
  isFavorite: boolean = false;
  isOpenFloor: boolean = false;
  isOpenFlat: boolean = false;
  isBooking: boolean = false;
  isVisibleSendAmo: boolean = false;
  isSendedAmo: boolean = false;

  name: string = "";
  phone: string = "";
  email: string = "";
  errors: IErrors = {
    name: [],
    phone: [],
    email: [],
  };

  validationFormErrors: IErrorsValid = {
    name: [],
    phone: [],
    email: [],
  };

  startSelectedDay: Date | null = null;
  endSelectedDay: Date | null = null;
  minEndDate: Date | null = null;
  // дата с которой открывается календарь въезда
  startOpenDate: Date | null = null;

  countAdults: number = 1;
  countChildren: number = 0;
  countPets: number = 0;
  isDisableMax: boolean = false;

  isVisibleSliderInner: boolean = false;

  validationErrors: string[] = [];

  constructor(
    navigate: NavigateFunction,
    storeroot: Store.IRootStore,
    storeAuthentication: Store.IStoreAuthentication,
    storeAmo: Store.IAmo
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeApartment = new ModelApartment();
    this.storeAmo = storeAmo;
    this.storeAuthentication = storeAuthentication;
    this.storeRoot = storeroot;
    this.navigate = navigate;

    this.validator = new Validator(
      {
        name: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
      },
      newRules
    );
  }

  // getters

  get bookingPriceForPets() {
    return this.countPets * PRICE_ONE_PET_FOR_BOOKING
  }

  get apartment() {
    return {...this.storeApartment.apartmentDetail, 
      price: this.storeApartment.apartmentDetail.price + this.bookingPriceForPets
    };
  }

  get checkinDate() {
    return this.startSelectedDay
      ? getNextDay(this.startSelectedDay, FORMAT_DAY_MONTH)
      : null;
  }

  get descriptionParagraphs() {
    return this.storeApartment.apartmentDetail.description.split("\n") || [];
  }

  get isBusy() {
    return this.apartment.busy;
  }

  get canPets() {
    return this.storeApartment.apartmentDetail.canPets;
  }

  get isDisableMaxPets() {
    return this.countPets === MAX_COUNT_PETS;
  }

  get apartmentName() {
    return this.getReadyName(
      this.apartment.roomLabel,
      this.apartment.square,
      this.apartment.floor
    );
  }

  get reviews() {
    return this.storeApartment.reviews;
  }

  get reviewsTotal() {
    return this.storeApartment.reviewsTotal;
  }

  get errorsAddBasket() {
    return this.storeApartment.serverErrors.addBasket.concat(
      this.validationErrors
    );
  }

  get errorsName() {
    return this.errors.name.concat(
      this.validationFormErrors.name
      /*    this.storeFeedback.errorsServer */
    );
  }

  get errorsPhone() {
    return this.errors.phone.concat(
      this.validationFormErrors.phone
      /*       this.storeFeedback.errorsServer */
    );
  }

  get errorsEmail() {
    return this.errors.email.concat(
      this.validationFormErrors.email,
      this.storeAmo.errorsServer
    );
  }

  // functions

  *init(apartamentId: number) {
    enableScroll();
    this.setIsLoading(true);
    yield this.storeApartment.getApartmentDetail(apartamentId);
    yield this.storeApartment.getReviews(apartamentId, 3);

    if (!this.storeApartment.apartmentDetail.id) {
      this.navigate(SCREENS.SCREEN_404);
    } else {
      // если броней нет, установить ближайшую дату въезда с завтрашнего дня
      this.setStartOpenDate(addDays(new Date(), 1));

      if (this.storeAuthentication.isAuth) {
        yield this.getFavorites();
        this.addView();
      }

      if (this.startOpenDate) {
        this.setMinEndDate(this.startOpenDate, MIN_NUMBER_DAYS_OF_RENT);
      }
    }

    this.setIsLoading(false);
  }

  sendAmoLeaveRequest() {
    if (this.checkValidAll() && this.startSelectedDay && this.endSelectedDay) {
      this.setIsLoadingModal(true);

      let params = {
        apartmentId: this.apartment.id,
        name: this.name,
        phone: this.phone,
        startDate: format(this.startSelectedDay, "yyyy-MM-dd"),
        endDate: format(this.endSelectedDay, "yyyy-MM-dd"),
        email: this.email,
      };

      new Promise(() => {
        this.storeAmo.sendAmoLeaveRequest(params);
      });

      this.setIsLoadingModal(false);

      this.closeAmoModal();
      this.openSendedAmoModal();
      this.clearForm();
    }
  }

  checkIsFavorite() {
    let isFavorite = this.storeApartment.allFavoritesId?.includes(
      this.storeApartment.apartmentDetail.id
    );

    this.setIsFavorite(isFavorite);
  }

  *addView() {
    yield this.storeApartment.addView(this.storeApartment.apartmentDetail.id);
  }

  *addToBasket() {
    // валидация на заполненность дат
    if (this.validateDates()) {
      // проверка свободна ли квартира
      if (this.apartment.busy) {
        this.openAmoModal();
      } else {
        // валидация выбранной даты начала (<= 18 дням)
        if (this.checkStartDateForBasket()) {
          if (
            this.startSelectedDay !== null &&
            this.endSelectedDay !== null &&
            !this.validateDates.length
          ) {
            this.setIsLoadingBooking(true);
            let _params = {
              apartmentId: this.apartment.id,
              startDate: format(this.startSelectedDay, "yyyy-MM-dd"),
              endDate: format(this.endSelectedDay, "yyyy-MM-dd"),
              countAdults: this.countAdults,
              countChildren: this.countChildren,
              countPets: this.countPets,
            };

            let response: boolean = yield this.storeApartment.addToBasket(
              _params
            );
            this.setIsLoadingBooking(false);

            if (response) {
              this.navigate(SCREENS.SCREEN_BASKET);
            }
          }
        } else {
          this.openAmoModal();
        }
      }

      this.setIsLoadingBooking(false);
    }

    this.storeRoot.setIsBookingAddBasketError(this.errorsAddBasket.length > 0);
  }

  checkStartDateForBasket() {
    if (this.startSelectedDay) {
      let count = differenceInCalendarDays(
        new Date(this.startSelectedDay),
        TODAY
      );
      return count <= MAX_DAYS_FROM_START_BOOKING;
    }
  }

  *addToFavorite() {
    let response: boolean = yield this.storeApartment.addFavorite(
      this.storeApartment.apartmentDetail.id
    );
    if (response) {
      this.setIsFavorite(true);
    }
    return response;
  }

  *deleteFavorite() {
    let response: boolean = yield this.storeApartment.deleteFavorite(
      this.storeApartment.apartmentDetail.id
    );

    if (response) {
      this.setIsFavorite(false);
    }
  }

  *getFavorites() {
    let response: boolean = yield this.storeApartment.getFavorites();

    if (response) {
      this.checkIsFavorite();
    }
  }

  *getAllReviews() {
    this.setIsLoadingReviews(true);
    yield this.storeApartment.getReviews(
      this.storeApartment.apartmentDetail.id,
      this.storeApartment.reviewsTotal
    );
    this.setIsLoadingReviews(false);
  }

  getReadyName(roomLabel: string, square: number, floor: number) {
    let _square = Math.round(square);
    let _rooms =
      roomLabel === ONE_ROOMS_NAME ? roomLabel : `${roomLabel} квартира`;
    return `${_rooms}, ${String(_square)} м², ${String(floor)} этаж`;
  }

  convertDate(date: Date) {
    return format(new Date(date), "yyyy-MM-dd");
  }

  getIsActiveDay(date: Date) {
    // определяет, является ли день доступным для выбора (дата выезда)

    // @TODO: удалить после тестирования заказчиком и подтверждения, что эта логика не требует переделок -->
    // ШАГ 30 дней
    /*   if (this.minEndDate) { 
      let differenceNum = differenceInCalendarDays(this.minEndDate, date);
      return differenceNum % STEP_DAYS_OF_RENT === 0;
    }
    return true; */
    // <------------------

    // Текущая логика: если дата попадает в диапазон: выбранная дата заезда + 335 дней, то дата активна
    if (this.startSelectedDay) {
      // максимальная дата выезда
      let maxEndDate = addDays(
        this.startSelectedDay,
        NUMBER_RANGE_DAYS_FOR_DATE_DEPARTURE
      );

      // дата из календаря до максимальной даты?
      // true - активная, false - блокируем
      return isBefore(date, maxEndDate);
    }

    return true;
  }

  onChangeCountAdults(value: number) {
    if (
      value <= this.apartment?.capacity &&
      value + this.countChildren <= this.apartment?.capacity
    ) {
      this.setCountAdults(value);
    }

    if (this.countAdults + this.countChildren === this.apartment.capacity) {
      this.setIsDisableMax(true);
    } else {
      this.setIsDisableMax(false);
    }
  }

  onChangeCountChildren(value: number) {
    if (
      value < this.apartment.capacity &&
      this.countAdults + value <= this.apartment.capacity
    ) {
      this.setCountChildren(value);
    }
    if (this.countAdults + this.countChildren === this.apartment.capacity) {
      this.setIsDisableMax(true);
    } else {
      this.setIsDisableMax(false);
    }
  }

  onChangePets(value: number) {
    this.setCountPets(value);
  }

  validateName() {
    if (!this.name.length) {
      this.addValidationErrorsName(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationFormErrors.name = [];
    }

    if (this.name.length < 2) {
      this.addValidationErrorsName(ERROR_TEXT_CHARS_NUM);
      return false;
    } else {
      this.validationFormErrors.name = [];
    }

    return true;
  }

  validateEmail() {
    let validEmail = this.validator.check("email", this.email);

    if (!this.email.length) {
      this.addValidationErrorsEmail(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationFormErrors.email = [];
    }

    if (!validEmail.passed) {
      this.addValidationErrorsEmail(validEmail.errors[0]);
      return false;
    } else {
      this.validationFormErrors.email = [];
    }

    return true;
  }

  validatePhone() {
    if (!this.phone.length) {
      this.addValidationErrorsPhone(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationFormErrors.phone = [];
    }

    if (this.phone.replaceAll(" ", "").length < 12) {
      this.addValidationErrorsPhone(ERROR_TEXT_PHONE);
      return false;
    } else {
      this.validationFormErrors.phone = [];
    }
    return true;
  }

  checkValidAll() {
    this.validateName();
    this.validatePhone();
    this.validateDates();
    this.validateEmail();

    return (
      this.validateName() &&
      this.validatePhone() &&
      this.validateDates() &&
      this.validateEmail()
    );
  }

  // изменение значений

  changeName(value: string) {
    this.setName(value);

    if (this.validationFormErrors.name.length && this.name.length) {
      this.validationFormErrors.name = [];
    }
  }

  changePhone(value: string) {
    this.setPhone(value);

    if (this.validationFormErrors.phone.length) {
      this.validationFormErrors.phone = [];
    }
  }

  changeEmail(value: string) {
    this.setEmail(value);

    if (this.validationFormErrors.email.length) {
      this.validationFormErrors.email = [];
    }
  }

  addValidationErrorsPhone(value: string) {
    this.validationFormErrors.phone = [
      ...this.validationFormErrors.phone,
      value,
    ];
  }

  addValidationErrorsEmail(value: string) {
    this.validationFormErrors.email = [
      ...this.validationFormErrors.email,
      value,
    ];
  }

  addValidationErrorsName(value: string) {
    this.validationFormErrors.name = [...this.validationFormErrors.name, value];
  }

  openBooking() {
    this.isBooking = true;
    disableScroll();
  }

  closeBooking() {
    this.isBooking = false;
    enableScroll();
  }

  toApartments() {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  }

  openFloorModal() {
    this.isOpenFloor = true;
    disableScroll();
  }

  closeFloorModal() {
    this.isOpenFloor = false;
    enableScroll();
  }

  openFlatModal() {
    this.isOpenFlat = true;
    disableScroll();
  }

  closeFlatModal() {
    this.isOpenFlat = false;
    enableScroll();
  }

  openAmoModal() {
    this.isVisibleSendAmo = true;
    disableScroll();
  }

  closeAmoModal() {
    this.isVisibleSendAmo = false;
    this.clearForm();
    enableScroll();
  }

  openSendedAmoModal() {
    this.isSendedAmo = true;
    disableScroll();
  }

  closeSendedAmoModal() {
    this.isSendedAmo = false;
    enableScroll();
  }

  openSliderInner() {
    this.setIsVisibleSliderInner(true);
    disableScroll();
  }

  closeSliderInner() {
    this.setIsVisibleSliderInner(false);
    enableScroll();
  }

  validateDates() {
    if (this.startSelectedDay === null || this.endSelectedDay === null) {
      this.validationErrors = [...this.validationErrors, ERROR_BOTH_DATES];
      return false;
    } else {
      this.setValidationErrors([]);
      return true;
    }
  }

  clearErrorsDates() {
    this.setValidationErrors([]);
    this.storeApartment.clearErrors();
  }

  changeEndDay(date: Date) {
    this.setEndSelectedDay(date);

    if (this.startSelectedDay !== null && this.errorsAddBasket.length > 0) {
      this.clearErrorsDates();
    }
  }

  // очистить форму

  clearForm() {
    this.setName("");
    this.setPhone("");
    this.setEmail("");
    this.errors.name = [];
    this.errors.email = [];
    this.errors.phone = [];
    this.validationFormErrors.name = [];
    this.validationFormErrors.email = [];
    this.validationFormErrors.phone = [];
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingModal(value: boolean) {
    this.isLoadingModal = value;
  }

  setIsLoadingReviews(value: boolean) {
    this.isLoadingReviews = value;
  }

  setIsLoadingBooking(value: boolean) {
    this.isLoadingBooking = value;
  }

  setIsFavorite(value: boolean) {
    this.isFavorite = value;
  }

  setStartSelectedDay(date: Date) {
    this.startSelectedDay = date;
    this.setMinEndDate(date, MIN_NUMBER_DAYS_OF_RENT_PREV);
    if (this.endSelectedDay) {
      this.endSelectedDay = null;
    }
  }

  setEndSelectedDay(value: Date) {
    this.endSelectedDay = value;
  }

  setMinEndDate(date: Date, countDays: number) {
    this.minEndDate = addDays(date, countDays);
  }

  setStartOpenDate(date: Date) {
    this.startOpenDate = date;
  }

  setCountAdults(value: number) {
    this.countAdults = value;
  }

  setCountChildren(value: number) {
    this.countChildren = value;
  }

  setIsDisableMax(value: boolean) {
    this.isDisableMax = value;
  }

  setIsVisibleSliderInner(value: boolean) {
    this.isVisibleSliderInner = value;
  }

  setValidationErrors(value: string[]) {
    this.validationErrors = value;
  }

  setName(value: string) {
    this.name = value.replace(/[^a-zA-ZА-Я а-яЁё]/gi, "");
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setEmail(value: string) {
    this.email = value;
  }

  setCountPets(value: number) {
    this.countPets = value;
  }
}
