import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { Personal } from "../pages/Personal";
import { Company } from "../pages/Company";
import { Favorites } from "../pages/Favorites";
import { Archive } from "../pages/Archive";
import { ArchiveDocuments } from "../pages/Archive/ArchiveDocuments";
import { MyBookings } from "../pages/MyBookings";
import { MyBooking } from "../pages/MyBooking";
import { Finance } from "../pages/Finance";

import {
  PAGE_ARCHIVE,
  PAGE_FAVORITES,
  PAGE_PERSONAL,
  PAGE_COMPANY,
  PAGE_ARCHIVE_DOCS_TAB,
  PAGE_MY_BOOKINGS,
  PAGE_MY_BOOKING_TAB,
  PAGE_FINANCE,
} from "../../../constants";

import { Store } from "../../../stores/types";

import styles from "./styles/accountNav.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
}

export const AccountNav: FC<IProps> = ({ storeAuthentication }) => {
  return (
    <div className={styles.pages}>
      <Routes>
        <Route path={PAGE_MY_BOOKINGS} element={<MyBookings />} />
        <Route path={PAGE_MY_BOOKING_TAB} element={<MyBooking />} />
        <Route
          path={PAGE_PERSONAL}
          element={<Personal storeAuthentication={storeAuthentication} />}
        />

        <Route path={PAGE_FINANCE} element={<Finance />} />
        <Route path={PAGE_ARCHIVE_DOCS_TAB} element={<ArchiveDocuments />} />
        <Route path={PAGE_COMPANY} element={<Company />} />
        <Route path={PAGE_FAVORITES} element={<Favorites />} />
        <Route path={PAGE_ARCHIVE} element={<Archive />} />
      </Routes>
    </div>
  );
};
