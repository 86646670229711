import { addDays, format } from "date-fns";

import { FORMET_DAY_MONTH_YEAR } from "../constants";

export const getNextDay = (date: string | Date, formatDate: string) => {
  if (date) {
    let nextDay = addDays(new Date(date), 1);

    const { ru } = require("date-fns/locale");

    let formatingDate = format(nextDay, formatDate, {
      locale: ru,
    });

    let formatingDateDefault = format(nextDay, FORMET_DAY_MONTH_YEAR, {
      locale: ru,
    });
    return formatingDate ? formatingDate : formatingDateDefault;
  }

  return date;
};
