import { ApiManager } from "../index";

import { EPaymentMethods } from "../../types";
import { TResponseApi } from "../types";

import {
  IExistsPaymentResponse,
  ILoginResponse,
  IPaymentCreateResponse,
  IPaymentMethod,
  IPaymentUpdateResponse,
  IRegisterParams,
} from "../../interfaces/Payment";

import {
  CHECK_IS_AUTH,
  LOGIN_SBERBUSINESS,
  PAYMENT,
  PAYMENT_CREATE,
  PAYMENT_EXIST,
  PAYMENT_UPDATE,
  REGISTER_SBERBUSINESS,
} from "../../constants/api";

export async function getPaymentMethods(): Promise<
  TResponseApi<IPaymentMethod[]>
> {
  return await ApiManager.request<IPaymentMethod[]>(
    {
      method: "GET",
      url: PAYMENT,
    },
    true
  );
}

export async function createPayment(params: {
  billId: number;
  strategy: EPaymentMethods;
}): Promise<TResponseApi<IPaymentCreateResponse>> {
  return await ApiManager.request<IPaymentCreateResponse>(
    {
      method: "POST",
      url: PAYMENT_CREATE,
      data: params,
    },
    true
  );
}

export async function updatePayment(
  paymentId: number
): Promise<TResponseApi<IPaymentUpdateResponse>> {
  return await ApiManager.request<IPaymentUpdateResponse>(
    {
      method: "GET",
      url: PAYMENT_UPDATE,
      params: { paymentId },
    },
    true
  );
}

export async function checkIsAuth(): Promise<
  TResponseApi<IPaymentUpdateResponse>
> {
  return await ApiManager.request<IPaymentUpdateResponse>(
    {
      method: "GET",
      url: CHECK_IS_AUTH,
    },
    true
  );
}

export async function loginSberBusiness(): Promise<
  TResponseApi<ILoginResponse>
> {
  return await ApiManager.request<ILoginResponse>(
    {
      method: "GET",
      url: LOGIN_SBERBUSINESS,
    },
    true
  );
}

export async function registerSberBusiness(params: {
  code: string;
  state: string;
  nonce: string;
}): Promise<TResponseApi<IRegisterParams>> {
  return await ApiManager.request<IRegisterParams>(
    {
      method: "POST",
      url: REGISTER_SBERBUSINESS,
      data: params,
    },
    true
  );
}

export async function getPaymentExists(
  billId: number
): Promise<TResponseApi<IExistsPaymentResponse>> {
  return await ApiManager.request<IExistsPaymentResponse>(
    {
      method: "GET",
      url: PAYMENT_EXIST(billId),
    },
    true
  );
}
