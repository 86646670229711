import { FC, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { Question } from "./Question";
import { AskQuestion } from "../../components/Modals/AskQuestion";
import { Footer } from "../../components/Footer";
import { AskBtn } from "./AskBtn";
import { Loader } from "../../components/Loader";
import { ModalReuse } from "../../components/Modals/ModalReuse";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../stores/Root";

import { DESCRIPTION_PAGE_DEFAULT, PAGE_TITLE_FAQ } from "../../constants";

import { EPageType } from "../../types";

import { ReactComponent as Ask } from "../../assets/images/ask.svg";
import { ReactComponent as AskOrange } from "../../assets/images/askOrange.svg";

import styles from "./styles/faq.module.scss";

export const FAQ: FC = observer(() => {
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.Faq
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_FAQ}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <>
      <div className={styles.faq}>
        {helmet}
        <div className={styles.faq__faqBlock}>
          <h1 className={styles.faq__title}>Faq</h1>
          <AskBtn onClick={storeUI.openPopupQuestion} Logo={Ask} />
        </div>

        <div className={styles.faq__rightColumn}>
          <div className={styles.faq__header}>
            <h2>FAQ</h2>
            <AskBtn onClick={storeUI.openPopupQuestion} Logo={AskOrange} />
          </div>
          {storeUI.isLoading ? (
            <div className={styles.faq__questionsEmpty}>
              <Loader />
            </div>
          ) : (
            <div className={styles.faq__questions}>
              {storeUI.faqItems?.map((item) => (
                <Question
                  title={item.title}
                  text={item.text}
                  key={item.id}
                  id={item.id}
                  isOpen={storeUI.checkIsOpenedQuestion(item.id)}
                  toggleOpen={storeUI.toggleOpen}
                />
              ))}
            </div>
          )}
        </div>

        <AskQuestion
          visible={storeUI.isVisiblePopup}
          close={storeUI.closePopupQuestion}
          isLoading={storeUI.isLoadingPopup}
          name={storeUI.name}
          email={storeUI.email}
          text={storeUI.text}
          emailErrors={storeUI.emailErrors}
          textErrors={storeUI.textErrors}
          nameErrors={storeUI.nameErrors}
          changeName={storeUI.changeName}
          changeEmail={storeUI.changeEmail}
          changeText={storeUI.changeText}
          onSend={storeUI.onSendQuestion}
        />
        <ModalReuse
          isShowModal={storeUI.isSuccessModal}
          setIsShowModal={storeUI.closeSuccessModal}
          btnTitle="Хорошо"
          type="button"
        >
          <h2>Ваш вопрос отправлен</h2>
        </ModalReuse>
      </div>
      <div className={styles.faq__footerBlock}>
        <Footer className={styles.faq__footer} />
      </div>
    </>
  );
});
