import { ApiManager } from "../index";

import {
  REGISTRATION,
  PHONE_VERIFY_SEND,
  PHONE_VERIFY_CHECK,
  NEED_FILL,
} from "../../constants/api";

import {
  INeedFillParams,
  IRegistrationParams,
  IVerifyCheck,
  IVerifySend,
} from "../../interfaces/Registration";
import { ICommonResponse, IResult } from "./../../interfaces/index";

import { TResponseApi } from "../types";

export async function phoneVerifySend(
  params: IVerifySend
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>({
    url: PHONE_VERIFY_SEND,
    data: params,
    method: "POST",
  });
}

export async function phoneVerifyCheck(
  params: IVerifyCheck
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>({
    url: PHONE_VERIFY_CHECK,
    data: params,
    method: "POST",
  });
}

export async function registration(
  params: IRegistrationParams
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>({
    url: REGISTRATION,
    data: params,
    method: "POST",
  });
}

export async function getNeedFill(
  params: INeedFillParams
): Promise<TResponseApi<IResult>> {
  return await ApiManager.request<IResult>({
    url: NEED_FILL,
    params: params,
    method: "GET",
  });
}
