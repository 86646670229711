import {
  EPaymentMethods,
  ETabsDocuments,
  ETabsResidents,
} from "./../types/index";
import { SCREENS } from "../navigation/endpoints";
import { EFloorPlan } from "../types";
import { IPaymentMethod } from "../interfaces/Payment";

// сайты
export const PYROBYTE_SITE = "https://pyrobyte.ru/";
export const WELCODOM_SITE = "https://beta.welcodom.ru/";

// текстовые
export const ERROR_TEXT_EMPTY = "Заполните поле";
export const ERROR_RATING_EMPTY = "Поставьте оценку";
export const ERROR_TEXT_SERVER_COMMON = "Ошибка на сервере";
export const ERROR_TEXT_FORM_COMMON = "Проверьте правильность введенных данных";

export const PASSWORD_INFO_TEXT = `Пароль должен содержать не менее 6 символов: A-z, 0-9, спецсимволы (! + _)`;
export const ERROR_TEXT_CHARS_NUM = "Введите минимум два символа";
export const ERROR_TEXT_CHARS_NUM_SEVEN = "Введите минимум семь символов";
export const ERROR_MIN_CHARS_THREE = "Введите минимум три символа";
export const ERROR_MIN_CHARS_TWO = "Введите минимум два символа";
export const ERROR_MIN_CHARS_ONE = "Введите минимум один символ";
export const ERROR_TEXT_PHONE = "Введите корректный номер телефона";
export const ERROR_TEXT_CODE = "Введите 4 символа";
export const ERROR_TEXT_CONFIRM_PASSWORD = "Пароли не совпадают";
export const ERROR_AGREE = "Подтвердите согласие на обработку данных";
export const ERROR_AGREE_AGREEMENT =
  "Подтвердите согласие с условиями договора";
export const ERROR_SERIES_LENGTH = "Введите 4 цифры";
export const ERROR_SERIAL_TENANT = "Введите 4 или 6 символов";
export const ERROR_NUMBER_LENGTH = "Введите 6 цифр";

export const ERROR_CODE_LENGTH = "Введите 6 цифр";
export const ERROR_BIK_LENGTH = "Введите 8 цифр";
export const ERROR_KPP_LENGTH = "Введите 9 цифр";
export const ERROR_INN_LENGTH = "Введите 10 цифр";
export const ERROR_BILL_LENGTH = "Введите 20 цифр";
export const ERROR_OGRN_LENGTH = "Введите 13 цифр";

export const ERROR_PAYMENT_METHODS = "Выберите метод оплаты";
export const ERROR_COUNT_FORMS_TENANTS =
  "Заполните формы для всех взрослых жильцов";
export const ERROR_CORRECT_DATE = "Введите корректную дату";
export const ERROR_BOTH_DATES = "Выберите дату заезда и выезда";
export const ERROR_EMPTY_DATE = "Выберите дату";
export const ERROR_GENDER = "Выберите пол";
export const ERROR_SELECT = "Выберите значение";
export const ERROR_YEARS_OLD = "Вам должно быть более 18 лет";
export const ERROR_MIN_BOOKING_DATE =
  "Дата не может быть раньше даты начала брони";
export const ERROR_MAX_BOOKING_DATE =
  "Дата не может быть позже даты конца брони";
export const ERROR_DIFFERENCE_DATES = `Дата "До" не может быть меньше даты "От"`;

export const ERROR_FILTERS_PRICES = `Цена "До" не может быть больше цены "От"`;
export const ERROR_FILTERS_PRICES_ZERO = `Введите число больше ноля`;

export const ERROR_LIMIT_CAPACITY =
  "Квартира не вмещает большее количество жильцов";
export const ERROR_LIMIT_ADULTS_BY_CHILDREN =
  "Выбранное количество детей не позволяет добавить взрослого жильца";
export const ERROR_FILLED_ADULTS = "Заполните все поля во вкладке Жильцы";
export const ERROR_SERVICE_DATE = "Выберите дату оказания услуги";

export const ONE_ROOMS_NAME = "Студия";
export const TWO_ROOMS_NAME = "2-комнатная квартира";
export const THREE_ROOMS_NAME = "3-комнатная квартира";
export const FOUR_ROOMS_NAME = "4-комнатная квартира";

export const WARNING_BOOKINGS_SERVICE_DATE = "Выберите дату заказа услуги";
export const WARNING_BOOKINGS_ARRIVAL_DATE =
  "Выберите дату заселения в разделе Квартиры";

export const INFO_PAYMENT = {
  CASH: "Необходимо оплатить в офисе компании в течение суток",
  BILL: "Необходимо предоставить платежное поручение в течение суток.",
};

export const ACCESS_TOKEN = "accessToken";

// минимальный год рождения
export const MIN_YEAR = 1899;

// адрес дома
export const LONGITUDE_ADDRESS = 52.270248;
export const LATITUDE_ADDRESS = 104.302112;

// ширина экрана
export const DESKTOP_WIDTH = 768;

// минимальное кол-во дней для аренды
export const MIN_NUMBER_DAYS_OF_RENT = 90;

// максимальный отступ (дней) от сегодняшней даты при котором можно забронировать квартиру (иначе только оставление заявки)
export const MAX_DAYS_FROM_START_BOOKING = 18;

// кол-во дней для выбора даты заселения
export const COUNT_DAYS_FOR_CHECK_IN_DATE = 14;

// шаг в днях при аренде больше 90
export const STEP_DAYS_OF_RENT = 30;

// кол-во дней указываемое во второй календарь (date min) на странице квартиры
// меньше на 1, для правильного расчета разницы в датах (с 1 по 10 число - кол-во 10 дней)
export const MIN_NUMBER_DAYS_OF_RENT_PREV = MIN_NUMBER_DAYS_OF_RENT - 1;

// кол-во видимых карточек сервисов при загрузке страницы
export const LIMIT_SHOW_SERVICES = 6;
export const FIRST_NEWS = 4;
export const LIMIT_SHOW_NEWS = 3;

// длина данных форм
export const SERIES_PASSPORT_LENGTH = 4;
export const SERIES_BIRTH_CERTIFICATE_LENGTH = 6;
export const NUMBER_PASSPORT_LENGTH = 6;
export const NUMBER_BIK_LENGTH = 8;
export const NUMBER_KPP_LENGTH = 9;
export const NUMBER_OGRN_LENGTH = 13;
export const NUMBER_INN_LENGTH = 10;
export const NUMBER_BILL_LENGTH = 20;
export const NUMBER_PET_TYPE_LENGTH = 30;
export const NUMBER_PET_BREED_LENGTH = 40;
export const NUMBER_PLACE_BIRTH_LENGTH = 100;
export const NUMBER_LAST_NAME_LENGTH = 18;
export const NUMBER_FIRST_NAME_LENGTH = 18;
export const NUMBER_MIDDLE_NAME_LENGTH = 18;
export const NUMBER_PASSPORT_ISSUED_BY_LENGTH = 255;
export const NUMBER_PASSPORT_ADDRESS_LENGTH = 255;
export const NUMBER_RELATION_TENANT_LENGTH = 20;
export const CODE_LENGTH = 4;
export const COMMENT_LENGTH = 200;

// минимальное кол-во карточек жильцов при оформлении
export const MIN_COUNT_TENANTS = 1;

// минимально кол-во взрослых жильцов
export const MIN_COUNT_ADULTS = 1;

export const MAX_AVATAR_SIZE_MB = 5;
const RATIO_MB_TO_B = 1048576;
export const MAX_AVATAR_SIZE_B = MAX_AVATAR_SIZE_MB * RATIO_MB_TO_B;

export const IMG_TYPES = ["jpg", "jpeg", "png", "bmp", "webp"];
export const MAX_RESOLUTION = [5000, 3000];

// кол-во изначально видимых услуг в корзине
export const BASKET_SERVICES_VISIBLE = 4;

// минимальное кол-во жильцов в квартире (т к не приходит с бэка)
export const MIN_COUNT_TENANTS_OF_APARTMENT = 1;

// ошибка - множество запросов
export const TOO_MANY_REQUEST = "too many request";
export const TOO_MANY_REQUEST_RU = "Пожалуйста, повторите запрос позднее";

// сегодняшняя дата
export const TODAY = new Date();

// для правильного вывода при разных числах
export const DECLENSION_TENANTS = ["жилец", "жильца", "жильцов"];
export const DECLENSION_PERSONS = ["человек", "человека", "человек"];
export const DECLENSION_DAYS = ["день", "дня", "дней"];
export const DECLENSION_METER = ["метр", "метра", "метров"];
export const DECLENSION_APARTMENT_FREE = [
  " свободная квартира",
  "свободные квартиры",
  "свободных квартир",
];

// заголовки страницы контакты
export const HOUSE = "Жилой комплекс";
export const ADMIN = "Администрация";

// всего этажей
export const NUMBER_OF_FLOORS = 17;

// инициализационный номер слайда (при нем не срабатывает в слайдере onChangeSlide при первой загрузке страницы)
export const INITIAL_SLIDE_DEFAULT_SWIPER = 0;

// типы этажей
export const floorsList = [
  { numberFloor: 1, label: "1 этаж", typeFloor: EFloorPlan.NoApartments },
  {
    numberFloor: 2,
    label: "2 этаж",
    typeFloor: EFloorPlan.EightApartmentsSpace,
  },
  { numberFloor: 3, label: "3 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 4, label: "4 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 5, label: "5 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 6, label: "6 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 7, label: "7 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 8, label: "8 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 9, label: "9 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 10, label: "10 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 11, label: "11 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 12, label: "12 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 13, label: "13 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 14, label: "14 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 15, label: "15 этаж", typeFloor: EFloorPlan.TenApartments },
  { numberFloor: 16, label: "16 этаж", typeFloor: EFloorPlan.EightApartments },
  { numberFloor: 17, label: "17 этаж", typeFloor: EFloorPlan.EightApartments },
];

// для получения текстовой информации на главной
export enum EMainTextCode {
  Main = "main",
}

export enum ETextMainId {
  FirstBlockId = 3,
  SecondBlockId = 4,
  ThirdBlockId = 5,
  FourthBlockId = 6,
  SixthBlockId = 7,
}

export enum EPartnerTextCode {
  PartnersDescription = "partners-description",
}

// если есть такое поле в local storage, то в ЛК делать пункт меню Документы раскрытым
export const OPEN_MENU_DOCS = "docsOpen";

export const NAV_DOCS = "Документы";
export const NAV_RESIDENTS = "Жильцы";

// заголовки страниц
export const PAGE_TITLE_MAIN = "Клубный дом";
export const PAGE_TITLE_APARTMENTS = "Квартиры";
export const PAGE_TITLE_APARTMENT_DEFAULT = "Квартира";
export const PAGE_TITLE_BASKET = "Корзина";
export const PAGE_TITLE_CONTACTS = "Контакты";
export const PAGE_TITLE_FAQ = "Вопросы и ответы";
export const PAGE_TITLE_FLOOR = "План этажа";
export const PAGE_TITLE_NEWS = "Новости";
export const PAGE_TITLE_NEW_DEFAULT = "Новость";
export const PAGE_TITLE_NOT_FOUND = "Ошибка 404";
export const PAGE_TITLE_PARTNERS = "Партнеры";
export const PAGE_TITLE_PERSONAL = "Личный кабинет";
export const PAGE_TITLE_SERVICES = "Услуги";
export const PAGE_PAYMENT_RESULT = "Результат оплаты";
export const PAGE_TITLE_BOOKING = "Бронирование";
export const PAGE_TITLE_ORDERING_DATA = "Заполнение данных по аренде";
export const TITLE_TEXT_PAGE_DEFAULT = "Клубный дом";
export const DESCRIPTION_PAGE_DEFAULT = "Клубный дом на 25 октября";

// типы аккаунтов
export const TYPE_PERSON_INDIVIDUAL = "individual";
export const TYPE_PERSON_LEGAL = "legal";

// для лейбла селекта комнат
export const ROOMS_SELECT_ALL = "Все";
export const FLOOR_SELECT_ALL = "Все";

// для навигации в ЛК (для проверки и редиректа на 404)
export const MY_BOOKINGS = "my-bookings";
export const PERSONAL = "personal";
export const COMPANY = "company";
export const FAVORITES = "favorites";
export const ARCHIVE = "archive";

export const RENT = "rent";
export const RESIDENTS = "residents";
export const DOCUMENTS = "documents";
export const SERVICES = "services";
export const AGREEMENTS = "agreements";
export const BILLS = "bills";
export const ACTS = "acts";
export const OTHERS = "others";
export const FINANCE = "finance";

// навигация в личном кабинете
export const PAGE_RENT = `/${RENT}`;
export const PAGE_DOCUMENTS = `/${DOCUMENTS}/*`;
export const PAGE_RESIDENTS = `/${RESIDENTS}`;
export const PAGE_PERSONAL = `/${PERSONAL}`;
export const PAGE_COMPANY = `/${COMPANY}`;
export const PAGE_FAVORITES = `/${FAVORITES}`;
export const PAGE_ARCHIVE = `/${ARCHIVE}`;
export const PAGE_MY_BOOKINGS = `/${MY_BOOKINGS}`;
export const PAGE_MY_BOOKING_TAB = `/${MY_BOOKINGS}/:id/:tabName/*`;
export const PAGE_ARCHIVE_DOCS_TAB = `/${ARCHIVE}/:id/:tabName`;
export const PAGE_FINANCE = `/${FINANCE}`;

export const PAGE_MY_BOOKING_BY_TAB = (bookingId: string, tabName: string) =>
  `/account/${MY_BOOKINGS}/${bookingId}/${tabName}`;

export const PAGE_RESIDENTS_BY_ID = (id: string, tabName: string) =>
  `/account/${MY_BOOKINGS}/${id}/${RESIDENTS}/${tabName}`;

export const PAGE_RENT_BY_ID = (id: string) =>
  `/account/${MY_BOOKINGS}/${id}/${RENT}`;

export const PAGE_DOCUMENTS_BY_ID = (id: string, tabName: string) =>
  `/account/${MY_BOOKINGS}/${id}/${DOCUMENTS}/${tabName}`;

export const PAGE_ARCHIVE_DOCS_TAB_BY_ID = (id: string, tabName: string) =>
  `/account/archive/${id}/${tabName}`;

export const PAGE_SERVICES_TAB_BY_ID = (id: string, tabName: string) =>
  `/account/${MY_BOOKINGS}/${id}/${SERVICES}/${tabName}`;

export const PAGE_ORDERING_DATA_BY_ID = (id: string) => `/ordering-data/${id}`;

// для отправки типа на бэк
export const GENDER_MALE = "male";
export const GENDER_FEMALE = "female";

// для вывода в селект выбора пола
export const GENDER_MALE_LABEL = "М";
export const GENDER_FEMALE_LABEL = "Ж";

// для опций пола
export const OPTION_GENDER_MALE = { id: 1, label: "M", value: GENDER_MALE };
export const OPTION_GENDER_FEMALE = { id: 2, label: "Ж", value: GENDER_FEMALE };

// для опций наличия вет паспорта
export const OPTION_HAVE_VET_PASSPORT = {
  id: 1,
  label: "Присутствует",
  value: true,
};
export const OPTION_NOT_HAVE_VET_PASSPORT = {
  id: 2,
  label: "Отсутствует",
  value: false,
};

// для опций животных
export const OPTION_PASSPORT_TRUE = {
  id: 1,
  label: "Присутствует",
  value: true,
};
export const OPTION_PASSPORT_FALSE = {
  id: 2,
  label: "Отсутствует",
  value: false,
};

// длина кода подразделения вместе с тире
export const SUBDIVISION_CODE_LENGTH = 7;

// возраст для валидации совершеннолетия
export const ADULTHOOD = 18;

// для расчета кол-ва жильцов. кол-во арендаторов
export const RENTER_COUNT = 1;

// для перевода из кол-ва дней в мес/года в оформлении
export const MONTH_COUNT_DAYS = 30; // условно дней в месяце
export const YEAR_COUNT_MONTHS = 12; // месяцев в году

// для подстановки к строке с кодировкой пдф-файла
export const HEADER_PDF = "data:application/pdf;filename=generated.pdf;base64,";

// название файла скачиваемого договора аренды
export const PDF_NAME_AGREEMENT = "Договор аренды.pdf";
export const PDF_NAME_ACT = "Акт.pdf";
export const PDF_NAME_BILL = "Счет.pdf";
export const PDF_NAME_OTHER = "Документ.pdf";

// статусы договоров в ЛК
export const STATUS_AGREEMENT_NEW = "Новый";
export const STATUS_AGREEMENT_TERMINATED = "Расторженный";
export const STATUS_AGREEMENT_ACTIVE = "Активный";
export const STATUS_AGREEMENT_UPLOADED = "Загруженный";

// timeout для запроса генерации любых пдф-документов (в миллисекундах)
export const TIMEOUT_PDF = 30000;
// timeout для брони
export const TIMEOUT_BOOKING_BOOK = 60000;

export const TIMEOUT_CREATE_BILL = 30000;

// отправка даты заселения для формирования договора (долгий ответ от 1с)
export const TIMEOUT_SEND_ARRIVAL_DATE = 60000;

export const TIMEOUT_AXIOS_ERROR = "ECONNABORTED";

// timeout для формы АМО
export const TIMEOUT_AMO = 50000;

// главное меню
export const MAIN_NAV = [
  {
    id: 1,
    title: "FAQ",
    path: SCREENS.SCREEN_FAQ,
    number: "01",
  },
  {
    id: 2,
    title: "Партнеры",
    path: SCREENS.SCREEN_PARTNERS,
    number: "02",
  },
];

export const MAIN_AUTH_NAV = [
  {
    id: 3,
    title: "Личный кабинет",
    path: SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS,
    number: "03",
  },
];

// главное меню адаптив
export const MAIN_NAV_ADAPTIVE = [
  {
    id: 1,
    title: "Главная",
    path: SCREENS.SCREEN_MAIN,
  },
  {
    id: 2,
    title: "Квартиры",
    path: SCREENS.SCREEN_APARTMENTS,
  },
  {
    id: 3,
    title: "Услуги",
    path: SCREENS.SCREEN_SERVICES,
  },
  {
    id: 4,
    title: "Новости",
    path: SCREENS.SCREEN_NEWS,
  },
  {
    id: 5,
    title: "Контакты",
    path: SCREENS.SCREEN_CONTACTS,
  },
  {
    id: 6,
    title: "FAQ",
    path: SCREENS.SCREEN_FAQ,
  },
  {
    id: 7,
    title: "Партнеры",
    path: SCREENS.SCREEN_PARTNERS,
  },
];

export const MAIN_AUTH_NAV_ADAPTIVE = [
  {
    id: 1,
    title: "Мои брони",
    path: SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS,
  },
  {
    id: 2,
    title: "Финансы",
    path: SCREENS.SCREEN_ACCOUNT_FINANCE,
  },
  {
    id: 3,
    title: "Личные данные",
    path: SCREENS.SCREEN_ACCOUNT_PERSONAL,
  },
  {
    id: 4,
    title: "Избранное",
    path: SCREENS.SCREEN_ACCOUNT_FAVORITES,
  },
  {
    id: 5,
    title: "Архив квартир",
    path: SCREENS.SCREEN_ACCOUNT_ARCHIVE,
  },
];

// для меню в ЛК
export const NAV_MY_BOOKING = [
  {
    id: 1,
    title: "Квартира",
    getPath: PAGE_RENT_BY_ID,
    withTab: false,
    defaultTabName: "",
  },
  {
    id: 2,
    title: "Документы",
    getPath: PAGE_DOCUMENTS_BY_ID,
    withTab: true,
    defaultTabName: ETabsDocuments.Agreements,
  },
  {
    id: 3,
    title: "Жильцы",
    getPath: PAGE_RESIDENTS_BY_ID,
    withTab: true,
    defaultTabName: ETabsResidents.Renter,
  },

  {
    id: 4,
    title: "Услуги",
    getPath: PAGE_RESIDENTS_BY_ID,
    withTab: false,
    defaultTabName: "",
  },
];

export const NAV_TOP = [
  {
    id: 1,
    title: "Мои брони",
    path: SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS,
  },

  {
    id: 2,
    title: "Финансы",
    path: SCREENS.SCREEN_ACCOUNT_FINANCE,
  },
];

export const NAV_BOTTOM = [
  {
    id: 1,
    title: "Профиль",
    path: SCREENS.SCREEN_ACCOUNT_PERSONAL,
  },
  {
    id: 2,
    title: "Избранное",
    path: SCREENS.SCREEN_ACCOUNT_FAVORITES,
  },
  {
    id: 3,
    title: "Архив квартир",
    path: SCREENS.SCREEN_ACCOUNT_ARCHIVE,
  },
];

export const NAV_BOTTOM_COMPANY = [
  {
    id: 1,
    title: "Профиль",
    path: SCREENS.SCREEN_ACCOUNT_PERSONAL,
  },
  {
    id: 2,
    title: "Компания",
    path: SCREENS.SCREEN_ACCOUNT_COMPANY,
  },
  {
    id: 3,
    title: "Избранное",
    path: SCREENS.SCREEN_ACCOUNT_FAVORITES,
  },
  {
    id: 4,
    title: "Архив квартир",
    path: SCREENS.SCREEN_ACCOUNT_ARCHIVE,
  },
];

export const NAV_HEADER = [
  {
    id: 1,
    title: "ГЛАВНАЯ",
    path: SCREENS.SCREEN_MAIN,
  },
  {
    id: 2,
    title: "КВАРТИРЫ",
    path: SCREENS.SCREEN_APARTMENTS,
  },
  {
    id: 3,
    title: "УСЛУГИ",
    path: SCREENS.SCREEN_SERVICES,
  },
  {
    id: 4,
    title: "НОВОСТИ",
    path: SCREENS.SCREEN_NEWS,
  },
  {
    id: 5,
    title: "КОНТАКТЫ",
    path: SCREENS.SCREEN_CONTACTS,
  },
];

// id для табов
export const PERSONAL_TAB_ID_RENTER = 10;
export const PERSONAL_TAB_ID_TENANTS = 20;
export const PERSONAL_TAB_ID_PETS = 30;

// для фильтра по этажам
export const FLOORS_SELECT = [
  { id: 0, label: "Все", value: "Все" },
  { id: 2, label: "2 этаж", value: "2 этаж" },
  { id: 3, label: "3 этаж", value: "3 этаж" },
  { id: 4, label: "4 этаж", value: "4 этаж" },
  { id: 5, label: "5 этаж", value: "5 этаж" },
  { id: 6, label: "6 этаж", value: "6 этаж" },
  { id: 7, label: "7 этаж", value: "7 этаж" },
  { id: 8, label: "8 этаж", value: "8 этаж" },
  { id: 9, label: "9 этаж", value: "9 этаж" },
  { id: 10, label: "10 этаж", value: "10 этаж" },
  { id: 11, label: "11 этаж", value: "11 этаж" },
  { id: 12, label: "12 этаж", value: "12 этаж" },
  { id: 13, label: "13 этаж", value: "13 этаж" },
  { id: 14, label: "14 этаж", value: "14 этаж" },
  { id: 15, label: "15 этаж", value: "15 этаж" },
  { id: 16, label: "16 этаж", value: "16 этаж" },
  { id: 17, label: "17 этаж", value: "17 этаж" },
];

// акцентный цвет
export const COLOR_ACCENT = "#cfb023";

// для Амо попапа
export const AMO_DESCRIPTION_FREE_APARTMENT =
  "Чтобы забронировать квартиру на выбранные даты, оставьте свои контактные данные. В ближайшее время с вами свяжется менеджер";
export const AMO_DESCRIPTION_BUSY_APARTMENT =
  "Данная квартира находится в аренде, вы можете оставить заявку, чтобы встать в очередь. В ближайшее время с вами свяжется менеджер";

// для попапа Выбор даты заселения
export const POPUP_CHECK_IN_DATE_TITLE = "Выберите дату заселения";
export const POPUP_CHECK_IN_DATE_DESCRIPTION =
  "Дата заселения будет являться датой заключения договора. В случае изменения даты заселения с вами свяжется администратор.";
export const POPUP_CHECK_IN_DATE_HINT =
  "Дата заселения не может быть выбрана в день бронирования, так как требуется время на подготовку квартиры";

// статусы платежа в карточках финансов
export const FINANCE_STATUS = {
  PAID: "Оплачено",
  UNPAID: "Не оплачено",
  EMPTY: "Статус неизвестен",
  PARTIALLY_PAID: "Частично оплачено",
};

export const MY_APARTMENT_INFO_DEFAULT = {
  apartmentName: "Не удалось загрузить контент",
  floor: "0",
  apartmentNumber: "0",
  period: "00-00-0000 - 00-00-0000",
  amountDays: "000",
  monthPrice: "00 000",
  deposit: "00 000",
  canPets: false,
  checkinDate: "",
};

// методы оплаты
export const PAYMENT_METHODS: IPaymentMethod[] = [
  {
    name: "Картой онлайн",
    method: EPaymentMethods.YooKassa,
    isSelected: true,
  },

  {
    name: "Оплата Сбербизнес",
    method: EPaymentMethods.SberBusiness,
    isSelected: false,
  },

  {
    name: "Выставить счет",
    method: EPaymentMethods.Bill,
    isSelected: false,
  },
];

export const PAYMENT_METHODS_INDIVIDUAL: IPaymentMethod[] = [
  {
    name: "Картой онлайн",
    method: EPaymentMethods.YooKassa,
    isSelected: true,
  },

  {
    name: "Выставить счет",
    method: EPaymentMethods.Bill,
    isSelected: false,
  },
];

//  переменная для сохранения в параметрах роутинга, если перешли к Заполнению профиля из Заполнения данных по аренде (Шаг 1), чтобы был редирект обратно
export const BACK_TO_ORDERING_DATA = "backToOrderingData";

export const HEADER_HEIGHT = 72;

//  переменная для сохранения в параметрах роутинга, если перешли к Заполнению компании из Заполнения информации
// для бронирования (1ый шаг при нажатии Оформить заказ - кнопка в корзине), чтобы был редирект обратно
export const BACK_TO_ORDER = "backToOrder";

// имя поля для local storage
export const FILTERS = "filters";

// максимальное кол-во животных в квартире
export const MAX_COUNT_PETS = 10;

export const TEXT_DATE_IN = "Дата заезда";
export const TEXT_DATE_OUT = "Дата выезда";

export const TEXT_SELECT_DATE_IN = "Выберите дату заезда";
export const TEXT_SELECT_DATE_OUT = "Выберите дату выезда";

// кол-во доступных дней для диапазона при выборе даты Заезда
// например, дата заезда 01.09 -> максимальная дата выезда = 01.09 + NUMBER_RANGE_DAYS_FOR_DATE_DEPARTURE
// для блокировки дальнейших дат в календарях
export const NUMBER_RANGE_DAYS_FOR_DATE_DEPARTURE = 335;

export const FORMAT_DAY_MONTH = "dd.MM.";
export const FORMET_DAY_MONTH_YEAR = "dd.MM.yyyy";

export const PAYMENT_EXIST_POPUP_TEXT = "Ранее вы уже переходили к оплате";

export const PRICE_ONE_PET_FOR_BOOKING = 5000;