import { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../Button";
import { Check } from "../../Check";
import { Select } from "../../Select";
import { Input } from "../../Input";
import { CheckBox } from "../../CheckBox";

import { enableScroll, stopPropagation } from "./../../../helpers/modalsFunc";

import { IRooms, ITypes } from "../../../interfaces/Catalog";
import { ICheckbox } from "../../../interfaces";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/facilities.module.scss";

interface IProps {
  modal: boolean;
  countApartaments: number | null;
  fromPrice: string;
  toPrice: string;
  floor: ITypes | null;
  isCheckedAvailable: boolean;
  countOfRooms: IRooms | null;
  facilities: ICheckbox[];
  terms: ICheckbox[];
  floorOptions: ITypes[];
  flatsOptions: IRooms[];
  errorsPrices: string[];
  closeModal: () => void;
  changeFacilities?: (id: number) => void;
  changeFacilitiesAdaptive?: (id: number) => void;
  changeTerm?: (id: number) => void;
  changeTermAdaptive?: (id: number) => void;
  changeFromPrice: (value: string) => void;
  changeToPrice: (value: string) => void;
  onChangeFloor: (value: ITypes) => void;
  onChangeCount: (rooms: IRooms) => void;
  onChangeCheckedAvailable: (value: boolean) => void;
  submit: (pageNumber: number) => void;
  onClearAll: () => void;
  onSave: () => void;
  clearTermsFacilities: () => void;
}

// попап удобств на десктопе / попап всех фильтров для мобилки
export const Facilities: FC<IProps> = observer(
  ({
    modal = false,
    isCheckedAvailable = false,
    floorOptions = [],
    flatsOptions = [],
    toPrice = "",
    fromPrice = "",
    floor = null,
    countOfRooms = null,
    facilities = [],
    terms = [],
    countApartaments = null,
    errorsPrices = [],
    closeModal = () => {},
    changeFacilities = () => {},
    changeFacilitiesAdaptive = () => {},
    onChangeCheckedAvailable = () => {},
    changeTerm = () => {},
    changeTermAdaptive = () => {},
    changeFromPrice = () => {},
    changeToPrice = () => {},
    onChangeFloor = () => {},
    onChangeCount = () => {},
    onClearAll = () => {},
    submit = () => {},
    onSave = () => {},
    clearTermsFacilities = () => {},
  }) => {
    const onClickGoldButton = () => {
      if (window.innerWidth <= 576) {
        submit(1);
      } else {
        onSave();
      }
    };

    const onChangeFacilities = (id: number) => {
      if (window.innerWidth <= 576) {
        changeFacilitiesAdaptive(id);
      } else {
        changeFacilities(id);
      }
    };

    const onChangeTerms = (id: number) => {
      if (window.innerWidth <= 576) {
        changeTermAdaptive(id);
      } else {
        changeTerm(id);
      }
    };

    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        closeModal();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    return (
      <>
        {modal && (
          <div
            className={styles.facilities}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.facilities__contentWrapper}>
              <div
                className={styles.facilities__content}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                <div className={styles.facilities__display}>
                  <Close
                    onClick={closeModal}
                    className={styles.facilities__close}
                  />

                  <div className={styles.facilities__filtersM}>
                    <div className={styles.facilities__titleBtnWrapper}>
                      <h2 className={styles.facilities__title}>Фильтры</h2>
                      <button
                        onClick={onClearAll}
                        className={styles.facilities__buttonClearAll}
                      >
                        Очистить все
                      </button>
                    </div>
                    <div className={styles.facilities__inputs}>
                      <Select
                        value={floor}
                        onChange={onChangeFloor}
                        label="Этаж"
                        options={floorOptions}
                        className={styles.facilities__select}
                        placeholder="Выберите этаж"
                      />

                      <Select
                        value={countOfRooms}
                        onChange={onChangeCount}
                        label="Количество комнат"
                        options={flatsOptions}
                        placeholder="Количество комнат"
                        className={styles.facilities__select}
                      />
                      <div className={styles.facilities__fromTo}>
                        <Input
                          className={styles.facilities__fromToInput}
                          label="От (₽)"
                          placeholder="12 000"
                          value={fromPrice}
                          onChange={changeFromPrice}
                          maxLength={7}
                        />
                        <Input
                          className={styles.facilities__fromToInput}
                          label="До (₽)"
                          placeholder="20 000"
                          value={toPrice}
                          onChange={changeToPrice}
                          maxLength={7}
                        />
                        {errorsPrices?.length > 0 && (
                          <span className={styles.facilities__pricesError}>
                            {errorsPrices[0]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.facilities__checkboxContainer}>
                    <CheckBox
                      checked={isCheckedAvailable}
                      setChecked={onChangeCheckedAvailable}
                    />
                    <p className={styles.facilities__checkboxText}>
                      Доступные для бронирования
                    </p>
                  </div>
                  <div className={styles.facilities__conditionsListsWrapper}>
                    <h2 className={styles.facilities__secondTitle}>Удобства</h2>
                    <div className={styles.facilities__listFirst}>
                      {facilities?.map((item) => (
                        <Check
                          title={item.title}
                          key={item.id}
                          selected={onChangeFacilities}
                          id={item.id}
                          isSelected={item.isSelected}
                          className={styles.facilities__checkbox}
                        />
                      ))}
                    </div>
                    <h2 className={styles.facilities__thirdTitle}>
                      Условия проживания
                    </h2>
                    <div className={styles.facilities__listSecond}>
                      {terms?.map((item) => (
                        <Check
                          title={item.title}
                          key={item.id}
                          selected={onChangeTerms}
                          id={item.id}
                          isSelected={item.isSelected}
                          className={styles.facilities__checkbox}
                        />
                      ))}
                    </div>
                  </div>

                  <button
                    onClick={clearTermsFacilities}
                    className={styles.facilities__buttonClearFacilities}
                  >
                    Сбросить всё
                  </button>

                  <Button
                    className={styles.facilities__button}
                    title={
                      window.innerWidth <= 576
                        ? `ПОКАЗАТЬ ${
                            countApartaments !== null
                              ? `(${countApartaments})`
                              : ""
                          }`
                        : "СОХРАНИТЬ"
                    }
                    theme="gold"
                    onClick={onClickGoldButton}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
