import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";

import { SocialMedia } from "./SocialMedia";
import { Button } from "../../../components/Button";
import { RevealAnimation } from "../RevealAnimation";

import { SCREENS } from "../../../navigation/endpoints";
import { currentYear } from "../../../helpers";

import { StoreUI } from "./storeUI";

import building from "../../../assets/images/building.png";
import { ReactComponent as FooterLogo } from "../../../assets/images/footerLogo.svg";
import { ReactComponent as UpMain } from "../../../assets/images/upMain.svg";

import styles from "./styles/request.module.scss";

export const Request: FC = observer(() => {
  const navigate = useNavigate();
  const [storeUI] = useState(() => new StoreUI(navigate));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  window.onscroll = () => {
    window.scrollY > 510 ? storeUI.showScrollTop() : storeUI.closeScrollTop();
  };

  const onToScrollTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__leftColumn}>
        <div className={styles.container__offer}>
          <div className={styles.container__content}>
            <RevealAnimation>
              <h4 className={styles.container__title}>
                МЫ ОКРУЖИМ ВАС ЗАБОТОЙ
              </h4>
            </RevealAnimation>
            <RevealAnimation>
              <h1 className={styles.container__header}>ХОТИТЕ ЗДЕСЬ ЖИТЬ?</h1>
            </RevealAnimation>
            <RevealAnimation>
              <p className={styles.container__text}>
                Оставьте заявку и мы свяжемся с вами для бронирования квартиры.
              </p>
            </RevealAnimation>
            <RevealAnimation>
              <Button
                className={styles.container__button}
                title="ЗАБРОНИРОВАТЬ КВАРТИРУ"
                theme="gold"
                onClick={storeUI.toApartments}
              />
            </RevealAnimation>
          </div>
        </div>
        <div className={styles.contacts}>
          <RevealAnimation
            duration={800}
            className={styles.contacts__logoAnimate}
          >
            <FooterLogo className={styles.contacts__logo} />
          </RevealAnimation>
          <div className={styles.contacts__info}>
            <div className={styles.contacts__socials}>
              {storeUI.socials?.map((social, index) => (
                <RevealAnimation
                  delay={300 + social.id * 100}
                  duration={800}
                  key={index}
                >
                  <a href={social.value} target="_blank" rel="noreferrer">
                    <SocialMedia icon={social.icon} key={social.id} />
                  </a>
                </RevealAnimation>
              ))}
            </div>
            <RevealAnimation
              duration={800}
              className={styles.contacts__politicsWrapper}
            >
              <div className={styles.contacts__politics}>
                <p className={styles.contacts__rules}>
                  © {currentYear} ВЭЛКО Все права защищены
                </p>
                <Link
                  to={SCREENS.SCREEN_TEXT_PRIVACY}
                  target="_blank"
                  className={styles.contacts__privacy}
                >
                  Политика конфиденциальности
                </Link>
                <h6 className={styles.contacts__name}>ИП Захарова Я.В.</h6>
                <h6 className={styles.contacts__name}>ИНН 381114665200</h6>
                <h6 className={styles.contacts__name}>
                  ОГРНИП 314385009800237
                </h6>
              </div>
            </RevealAnimation>
          </div>
        </div>
      </div>
      <div className={styles.container__rightColumn}>
        <img className={styles.container__img} src={building} alt="map" />
        {storeUI.onToScrollTop && (
          <UpMain className={styles.container__up} onClick={onToScrollTop} />
        )}
      </div>
    </div>
  );
});
