import { FC } from "react";
import { observer } from "mobx-react-lite";

import { changeSpaceInNumber } from "../../../../../helpers";

import { ReactComponent as Close } from "../../../../../assets/images/close.svg";
import { ReactComponent as CloseMobile } from "../../../../../assets/mobile/close.svg";

import styles from "./styles/favorite.module.scss";

interface IProps {
  image: string | null;
  price: number;
  roomLabel: string;
  floor: number;
  square: number;
  toApartment: () => void;
  openDeleteModal: () => void;
}

export const Favorite: FC<IProps> = observer(
  ({
    image = null,
    roomLabel = "",
    square = 0,
    floor = 1,
    price = 0,
    toApartment = () => {},
    openDeleteModal = () => {},
  }) => {
    return (
      <div className={styles.container}>
        <div className={styles.container__content}>
          <CloseMobile
            className={styles.container__closeMobile}
            onClick={openDeleteModal}
          />
          <Close
            className={styles.container__close}
            onClick={openDeleteModal}
          />
          {image ? (
            <div
              className={styles.container__photoWrapper}
              onClick={toApartment}
            >
              <img className={styles.container__photo} src={image} alt="flat" />
            </div>
          ) : (
            <div className={styles.container__photoEmpty} onClick={toApartment}>
              Не удалось загрузить изображение
            </div>
          )}
          <div className={styles.container__info}>
            <h5 className={styles.container__title}>
              {roomLabel} {roomLabel === "Студия" ? "" : "квартира"}, {square}{" "}
              м², {floor} этаж
            </h5>
            <h2 className={styles.container__priceDesktop}>
              {changeSpaceInNumber(price)} ₽ / мес.
            </h2>
          </div>
        </div>
      </div>
    );
  }
);
