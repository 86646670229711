import { NavigateFunction } from "react-router-dom";
import { differenceInCalendarDays, format, isValid } from "date-fns";
import { makeAutoObservable } from "mobx";

import { SCREENS } from "../../../../../navigation/endpoints";
import {
  ERROR_CORRECT_DATE,
  ERROR_MAX_BOOKING_DATE,
  ERROR_MIN_BOOKING_DATE,
  ERROR_SERVICE_DATE,
  ERROR_TEXT_EMPTY,
  PAGE_DOCUMENTS_BY_ID,
  PAGE_SERVICES_TAB_BY_ID,
} from "../../../../../constants";

import { changeSpaceInNumber } from "../../../../../helpers";
import { disableScroll, enableScroll } from "../../../../../helpers/modalsFunc";

import {
  IPaginateData,
  IPaginateParamsCommon,
  IPeriod,
  ITab,
} from "../../../../../interfaces";
import {
  ILoadingServices,
  IMyServices,
  IServicesAvailable,
  IServicesSelected,
  IStoreUI,
  IVisiblePopup,
} from "./interfaces";
import {
  IAccountAddServiceParams,
  IAccountChangeServiceParams,
  IAllPaginationData,
  ISettingsService,
} from "../../../../../interfaces/AccountService";

import {
  EBookingType,
  EChoosePayType,
  EServiceCode,
  ETabsDocuments,
  ETabsServices,
} from "../../../../../types";
import { Store } from "../../../../../stores/types";
import { EServiceStatus } from "./../../../../../types/index";

import { EPaginationData, ELoading, EVisiblePopup } from "./enums";

const DEFAULT_PAGINATION_PARAMS = { page: 1, limit: 3 };

export class StoreUI implements IStoreUI {
  navigate: NavigateFunction;
  storeRoot: Store.IRootStore;
  storeBookingServices: Store.IAccountServices;
  storeChoosePay: Store.IChoosePay;

  currentTabName: ETabsServices | null = null;

  bookingId: string | null = null;
  serviceIdForDelete: number | null = null;

  validationErrorsSelected: string[] = [];

  bookingPeriod: IPeriod = {
    startDate: "",
    endDate: "",
  };

  paginationAllData: IAllPaginationData = {
    [EPaginationData.MyServices]: null,
    [EPaginationData.AvailableServices]: null,
    [EPaginationData.SelectedServices]: null,
  };

  loading: ILoadingServices = {
    [ELoading.Main]: false,
    [ELoading.MainMyServices]: false,
    [ELoading.MainAvailable]: false,
    [ELoading.MainSelected]: false,
    [ELoading.Popup]: false,
    [ELoading.Delete]: false,
    [ELoading.CreateBill]: false,
  };

  visiblePopup: IVisiblePopup = {
    [EVisiblePopup.DetailService]: false,
    [EVisiblePopup.Delete]: false,
    [EVisiblePopup.SuccessAddService]: false,
    [EVisiblePopup.BillPaymentLater]: false,
    [EVisiblePopup.ParkingInfo]: false,
  };

  // данные для настроек услуги (передаются в попап)
  settingsCurrentService: ISettingsService = {
    id: 0,
    image: "",
    title: "",
    description: "",
    date: "",
    comment: "",
    price: "",
    errorsDate: [],
  };

  constructor(navigate: NavigateFunction, storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.navigate = navigate;
    this.storeRoot = storeRoot;
    this.storeBookingServices = storeRoot.storeBookingServices;
    this.storeChoosePay = storeRoot.storeModals.storeChoosePay;
  }

  // getters

  get servicesSelected(): IServicesSelected[] {
    return (
      this.storeBookingServices.selectedSevices?.map((item) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        image: item.image,
        price: changeSpaceInNumber(item.price),
        status: this.getReadyStatus(item.status),
        date: item.date ? this.convertDate(item.date) : null,
        isPaid: item.status === EServiceStatus.Paid,
        haveDate: Boolean(item.date),
      })) || []
    );
  }

  get servicesAvailable(): IServicesAvailable[] {
    return (
      this.storeBookingServices.availableServices?.map((item) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        image: item.image,
        price: changeSpaceInNumber(item.price),
        isParking: item.code === EServiceCode.Parking || false,
      })) || []
    );
  }

  get myServices(): IMyServices[] {
    return (
      this.storeBookingServices.myServices?.map((item) => ({
        id: item.id,
        name: item.name,
        image: item.image,
        date: this.convertDate(item.date),
        price: changeSpaceInNumber(item.price),
      })) || []
    );
  }

  get serviceCommonErrors() {
    return this.storeBookingServices.errors.addService;
  }

  get tabs(): ITab[] {
    return [
      {
        id: 1,
        label: "Мои услуги",
        tabName: ETabsServices.MyServices,
      },
      {
        id: 2,
        label: "Доступные",
        tabName: ETabsServices.Available,
      },
      {
        id: 3,
        label: `Выбранные ${
          this.paginationAllData.selectedServices?.total || ""
        }`,
        tabName: ETabsServices.Selected,
      },
    ];
  }

  get selectedPaginationData() {
    return {
      totalCount: this.paginationAllData.selectedServices?.total ?? 0,
      siblingCount: 1,
      currentPage: this.paginationAllData.selectedServices?.page ?? 0,
      pageSize: this.paginationAllData.selectedServices?.limit ?? 0,
      onPageChange: this.getServiceByPage,
    };
  }

  get availablePaginationData() {
    return {
      totalCount: this.paginationAllData.availableServices?.total ?? 0,
      siblingCount: 1,
      currentPage: this.paginationAllData.availableServices?.page ?? 0,
      pageSize: this.paginationAllData.availableServices?.limit ?? 0,
      onPageChange: this.getServiceByPage,
    };
  }

  get myServicesPaginationData() {
    return {
      totalCount: this.paginationAllData.myServices?.total ?? 0,
      siblingCount: 1,
      currentPage: this.paginationAllData.myServices?.page ?? 0,
      pageSize: this.paginationAllData.myServices?.limit ?? 0,
      onPageChange: this.getServiceByPage,
    };
  }

  get successAddedDataForPopup() {
    switch (this.currentTabName) {
      case ETabsServices.Available:
        return {
          title: "Услуга добавлена",
          buttonTitle: "Посмотреть",
          onClick: this.toAddedServices,
        };
      case ETabsServices.Selected:
        return {
          title: "Изменения сохранены",
          buttonTitle: "Хорошо",
          onClick: this.closeSuccessAddService,
        };
      default:
        return {
          title: "",
          buttonTitle: "",
          onClick: () => {},
        };
    }
  }

  get errorsSelected() {
    return this.validationErrorsSelected.concat(
      this.storeBookingServices.errors.createBill
    );
  }

  // function

  *init(bookingId: string, currentTabName: string) {
    this.setLoading(ELoading.Main, true);
    yield this.storeRoot.getBookingsList(EBookingType.Active);

    if (
      bookingId &&
      this.storeRoot.checkBookingIdByUrl(bookingId, EBookingType.Active)
    ) {
      let bookingPeriod: IPeriod = yield this.storeRoot.getBookingPeriodById(
        bookingId
      );

      this.setBookingPeriod(bookingPeriod);
      this.setBookingId(bookingId);

      if (
        currentTabName === ETabsServices.Selected ||
        currentTabName === ETabsServices.Available ||
        currentTabName === ETabsServices.MyServices
      ) {
        this.setCurrentTabName(currentTabName);
        this.getDataServices(bookingId, currentTabName);
      }
    } else {
      this.navigate(SCREENS.SCREEN_404);
    }
    this.setLoading(ELoading.Main, false);
  }

  *getDataServices(
    bookingId: string,
    tabName: ETabsServices,
    paginationParams: IPaginateParamsCommon = DEFAULT_PAGINATION_PARAMS
  ) {
    switch (tabName) {
      case ETabsServices.Available:
        yield this.getAvailableServices(bookingId, paginationParams);
        break;
      case ETabsServices.MyServices:
        yield this.getMyServices(bookingId, paginationParams);
        break;
      case ETabsServices.Selected:
        yield this.getSelectedServices(bookingId, paginationParams);
        break;
    }
  }

  private *getAvailableServices(
    bookingId: string,
    paginationParams: IPaginateParamsCommon = DEFAULT_PAGINATION_PARAMS
  ) {
    this.setLoading(ELoading.MainAvailable, true);
    yield this.storeBookingServices.getAvailableServices(
      bookingId,
      paginationParams
    );
    this.writePaginationData(
      EPaginationData.AvailableServices,
      this.storeBookingServices.paginationData
    );
    this.setLoading(ELoading.MainAvailable, false);
  }

  private *getMyServices(
    bookingId: string,
    paginationParams: IPaginateParamsCommon = DEFAULT_PAGINATION_PARAMS
  ) {
    this.setLoading(ELoading.MainMyServices, true);
    yield this.storeBookingServices.getMyServices(bookingId, paginationParams);
    this.writePaginationData(
      EPaginationData.MyServices,
      this.storeBookingServices.paginationData
    );
    this.setLoading(ELoading.MainMyServices, false);
  }

  private *getSelectedServices(
    bookingId: string,
    paginationParams: IPaginateParamsCommon = DEFAULT_PAGINATION_PARAMS
  ) {
    this.setLoading(ELoading.MainSelected, true);
    yield this.storeBookingServices.getSelectedServices(
      bookingId,
      paginationParams
    );
    this.writePaginationData(
      EPaginationData.SelectedServices,
      this.storeBookingServices.paginationData
    );
    this.setLoading(ELoading.MainSelected, false);
  }

  *getServiceByPage(pageNumber: number) {
    this.setLoading(ELoading.Main, true);
    if (this.bookingId && this.currentTabName) {
      yield this.getDataServices(this.bookingId, this.currentTabName, {
        page: pageNumber,
        limit: 3,
      });
    }
    this.setLoading(ELoading.Main, false);
  }

  onClickTab(id: string, tabName: string) {
    this.navigate(PAGE_SERVICES_TAB_BY_ID(id, tabName));
    if (
      tabName === ETabsServices.Selected ||
      tabName === ETabsServices.Available ||
      tabName === ETabsServices.MyServices
    ) {
      this.setCurrentTabName(tabName);
      this.clearSelectedErrors();
    }
  }

  openServiceSettingsPopup(serviceId: number, typeTab: ETabsServices) {
    this.openPopupService();

    let currentService = null;

    switch (typeTab) {
      case ETabsServices.Selected:
        currentService = this.servicesSelected.find(
          (item) => item.id === serviceId
        );

        break;
      case ETabsServices.Available:
        currentService = this.servicesAvailable.find(
          (item) => item.id === serviceId
        );
        break;
    }

    if (currentService) {
      this.settingsCurrentService.id = currentService.id;
      this.settingsCurrentService.image = currentService.image ?? "";
      this.settingsCurrentService.title = currentService.name;
      this.settingsCurrentService.description =
        currentService.description ?? "";
      this.settingsCurrentService.price = currentService.price;
    }
  }

  onClickMoreFromAvailable(
    serviceId: number,
    typeTab: ETabsServices,
    isParking: boolean
  ) {
    if (isParking) {
      this.openParkingInfo();
    } else {
      this.openServiceSettingsPopup(serviceId, typeTab);
    }
  }

  *onClickOrder() {
    if (this.bookingId) {
      this.setLoading(ELoading.Popup, true);
      switch (this.currentTabName) {
        case ETabsServices.Available:
          yield this.orderAvailableService(this.bookingId);
          break;
        case ETabsServices.Selected:
          yield this.changeSelectedService(this.bookingId);
          break;
      }
      this.setLoading(ELoading.Popup, false);
    }
  }

  private *orderAvailableService(bookingId: string) {
    if (this.validateDateServiceDetail()) {
      let _params: IAccountAddServiceParams = {
        date: this.reverseAndReplaceSeparator(
          this.settingsCurrentService.date,
          ".",
          "-"
        ),
        comment: this.settingsCurrentService.comment,
        serviceId: this.settingsCurrentService.id,
      };

      yield this.storeBookingServices.addAccountService(bookingId, _params);

      if (this.storeBookingServices.isSuccessAdded) {
        this.openSuccessAddService();
        this.closePopupService();

        yield this.getDataServices(
          bookingId,
          ETabsServices.Selected,
          DEFAULT_PAGINATION_PARAMS
        );
      }
    }
  }

  private *changeSelectedService(bookingId: string) {
    if (this.validateDateServiceDetail()) {
      let _params: IAccountChangeServiceParams = {
        bookingServiceId: this.settingsCurrentService.id,
        date: this.reverseAndReplaceSeparator(
          this.settingsCurrentService.date,
          ".",
          "-"
        ),
        comment: this.settingsCurrentService.comment,
      };

      yield this.storeBookingServices.changeAccountService(bookingId, _params);

      if (this.storeBookingServices.isSuccessChange) {
        this.openSuccessAddService();
        this.closePopupService();

        yield this.getDataServices(
          bookingId,
          ETabsServices.Selected,
          DEFAULT_PAGINATION_PARAMS
        );
      }
    }
  }

  *onClickDeleteService() {
    if (this.bookingId && this.serviceIdForDelete) {
      this.setLoading(ELoading.Delete, true);
      yield this.storeBookingServices.deleteAccountService(
        this.bookingId,
        String(this.serviceIdForDelete)
      );

      if (this.storeBookingServices.isSuccessDelete) {
        yield this.storeBookingServices.getSelectedServices(this.bookingId, {
          page: 1,
          limit: 3,
        });

        this.writePaginationData(
          EPaginationData.SelectedServices,
          this.storeBookingServices.paginationData
        );
        this.closePopupDelete();
      }

      this.setLoading(ELoading.Delete, false);
    }
  }

  *onClickPay() {
    if (this.bookingId && this.validateSelectedServices()) {
      this.setLoading(ELoading.CreateBill, true);
      yield this.storeBookingServices.createBillByServices(this.bookingId);

      if (
        typeof this.storeBookingServices.billId === "number" &&
        typeof this.storeBookingServices.documentId === "number"
      ) {
        this.openPaymentPopup(
          this.storeBookingServices.billId,
          this.bookingId,
          this.storeBookingServices.documentId
        );
      }

      if (
        (this.storeBookingServices.isSuccessCreateBillByServices &&
          typeof this.storeBookingServices.billId !== "number") ||
        this.storeBookingServices.isTimeoutEndCreateBillByServices
      ) {
        this.openBillPaymentLater();
      }

      this.setLoading(ELoading.CreateBill, false);
    }
  }

  openPaymentPopup(billId: number, bookingId: string, documentId: number) {
    const _getDataSelectedServices = () =>
      this.getDataSelectedServices(bookingId);

    this.storeChoosePay.setContinueCallback(_getDataSelectedServices);
    this.storeChoosePay.openChoosePay({
      id: billId,
      type: EChoosePayType.Base,
      documentId: documentId,
    });
  }

  *getDataSelectedServices(bookingId: string) {
    yield this.getDataServices(
      bookingId,
      ETabsServices.Selected,
      DEFAULT_PAGINATION_PARAMS
    );
  }

  //-- changes
  changeCurrentDate(value: string) {
    this.setCurrentServiceDate(value);
  }

  changeCurrentComment(value: string) {
    this.setCurrentServiceComment(value);
  }

  // -- validation
  validateDateServiceDetail() {
    if (!this.settingsCurrentService.date.length) {
      this.addSettingErrorDate(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearSettingErrorDate();
    }

    let _formatDate = this.reverseAndReplaceSeparator(
      this.settingsCurrentService.date,
      ".",
      "-"
    );

    let chosenDate = new Date(_formatDate);
    let bookingEndDate = new Date(this.bookingPeriod.endDate);
    let bookingStartDate = new Date(this.bookingPeriod.startDate);

    let differenceFromDateStart = differenceInCalendarDays(
      chosenDate,
      bookingStartDate
    );
    let differenceFromDateEnd = differenceInCalendarDays(
      bookingEndDate,
      chosenDate
    );

    let isCorrect = isValid(chosenDate);
    let isBeforeBooking = differenceFromDateStart < 0;
    let isAfterBooking = differenceFromDateEnd < 0;

    if (!isCorrect) {
      this.addSettingErrorDate(ERROR_CORRECT_DATE);
      return false;
    } else {
      this.clearSettingErrorDate();
    }

    if (isBeforeBooking) {
      this.addSettingErrorDate(ERROR_MIN_BOOKING_DATE);
      return false;
    } else {
      this.clearSettingErrorDate();
    }

    if (isAfterBooking) {
      this.addSettingErrorDate(ERROR_MAX_BOOKING_DATE);
      return false;
    } else {
      this.clearSettingErrorDate();
    }

    return true;
  }

  validateSelectedServices() {
    let allHaveDate = true;
    this.servicesSelected?.forEach((item) => {
      if (!item.haveDate) {
        allHaveDate = false;
      }
    });

    allHaveDate
      ? this.clearSelectedErrors()
      : this.addSelectedErrors(ERROR_SERVICE_DATE);

    return allHaveDate;
  }

  // -- helpers
  writePaginationData(type: EPaginationData, data: IPaginateData) {
    this.paginationAllData[type] = data;
  }

  addSettingErrorDate(value: string) {
    this.settingsCurrentService.errorsDate = [
      ...this.settingsCurrentService.errorsDate,
      value,
    ];
  }

  clearSettingErrorDate() {
    this.settingsCurrentService.errorsDate = [];
  }

  convertDate(date: string | Date) {
    return format(new Date(date), "dd.MM.yyyy");
  }

  reverseAndReplaceSeparator(
    value: string,
    current: string,
    replacedBy: string
  ) {
    return value.split(current).reverse().join(replacedBy);
  }

  getReadyStatus(status: EServiceStatus) {
    switch (status) {
      case EServiceStatus.Paid:
        return "Оплачено";
      case EServiceStatus.Waiting:
        return "Не оплачено";
      default:
        return "";
    }
  }

  addSelectedErrors(value: string) {
    this.validationErrorsSelected = [...this.validationErrorsSelected, value];
  }

  clearSelectedErrors() {
    this.validationErrorsSelected = [];
    this.storeBookingServices.clearErrorsCreateBill();
  }

  // -- open / close
  openPopupService() {
    this.setVisiblePopup(EVisiblePopup.DetailService, true);
    disableScroll();
  }

  closePopupService() {
    this.setVisiblePopup(EVisiblePopup.DetailService, false);
    this.clearPopupService();
    this.storeBookingServices.clearErrorsAddService();
    enableScroll();
  }

  openPopupDelete(serviceId: number) {
    this.setVisiblePopup(EVisiblePopup.Delete, true);
    this.setServiceIdForDelete(serviceId);
    disableScroll();
  }

  closePopupDelete() {
    this.setVisiblePopup(EVisiblePopup.Delete, false);
    enableScroll();
  }

  clearPopupService() {
    this.settingsCurrentService.date = "";
    this.settingsCurrentService.comment = "";
    this.clearSettingErrorDate();
  }

  openSuccessAddService() {
    this.setVisiblePopup(EVisiblePopup.SuccessAddService, true);
    disableScroll();
  }

  closeSuccessAddService() {
    this.setVisiblePopup(EVisiblePopup.SuccessAddService, false);
    enableScroll();
  }

  openBillPaymentLater() {
    this.setVisiblePopup(EVisiblePopup.BillPaymentLater, true);
    disableScroll();
  }

  closeBillPaymentLater() {
    this.setVisiblePopup(EVisiblePopup.BillPaymentLater, false);
    enableScroll();
  }

  openParkingInfo() {
    this.setVisiblePopup(EVisiblePopup.ParkingInfo, true);
    disableScroll();
  }

  closeParkingInfo() {
    this.setVisiblePopup(EVisiblePopup.ParkingInfo, false);
    enableScroll();
  }

  toAvailableServices() {
    if (this.bookingId) {
      this.navigate(
        PAGE_SERVICES_TAB_BY_ID(this.bookingId, ETabsServices.Available)
      );
    }
  }

  toAddedServices() {
    this.closeSuccessAddService();

    if (this.bookingId) {
      this.navigate(
        PAGE_SERVICES_TAB_BY_ID(this.bookingId, ETabsServices.Selected)
      );
    }
  }

  toBills() {
    this.closeBillPaymentLater();
    if (this.bookingId) {
      this.navigate(PAGE_DOCUMENTS_BY_ID(this.bookingId, ETabsDocuments.Bills));
    }
  }

  // setters
  setLoading(typeLoading: ELoading, value: boolean) {
    this.loading[typeLoading] = value;
  }

  setVisiblePopup(typePopup: EVisiblePopup, value: boolean) {
    this.visiblePopup[typePopup] = value;
  }

  setCurrentServiceDate(value: string) {
    this.settingsCurrentService.date = value;
  }

  setCurrentServiceComment(value: string) {
    this.settingsCurrentService.comment = value;
  }

  setBookingPeriod(value: IPeriod) {
    this.bookingPeriod = value;
  }

  setBookingId(value: string | null) {
    this.bookingId = value;
  }

  setCurrentTabName(value: ETabsServices | null) {
    this.currentTabName = value;
  }

  setServiceIdForDelete(value: number | null) {
    this.serviceIdForDelete = value;
  }
}
