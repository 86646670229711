import { observer } from "mobx-react-lite";
import { Link, NavLink } from "react-router-dom";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

import { stopPropagation } from "../../../../helpers/modalsFunc";

import { SCREENS } from "../../../../navigation/endpoints";

import { Store } from "../../../../stores/types";

import {
  MAIN_AUTH_NAV_ADAPTIVE,
  MAIN_NAV_ADAPTIVE,
} from "../../../../constants";

import { ReactComponent as Close } from "../../../../assets/mobile/close.svg";

import styles from "./styles/menuMobile.module.scss";

const customAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
  menuMobile: boolean;
  showRules: boolean;
  personalType?: string;
  closeMenuMobile(): void;
  onLogout(): void;
  onLogin(): void;
}

export const MenuMobile = observer(
  ({
    storeAuthentication,
    menuMobile = false,
    showRules = false,
    personalType = "",
    closeMenuMobile = () => {},
    onLogout = () => {},
    onLogin = () => {},
  }: IProps) => {
    return menuMobile ? (
      <div className={styles.modal} onClick={closeMenuMobile}>
        <Reveal keyframes={customAnimation}>
          <div className={styles.content} onClick={stopPropagation}>
            <div className={styles.content__close} onClick={closeMenuMobile}>
              <Close />
            </div>
            <nav className={styles.content__mainNav}>
              {MAIN_NAV_ADAPTIVE.map((nav) => (
                <NavLink
                  to={String(nav.path)}
                  key={nav.id}
                  className={styles.content__mainNavTitle}
                  onClick={closeMenuMobile}
                >
                  {nav.title}
                </NavLink>
              ))}
            </nav>
            {storeAuthentication.isAuth ? (
              <>
                <h6 className={styles.content__subTitle}>Личный кабинет</h6>
                <nav className={styles.content__accountNav}>
                  {MAIN_AUTH_NAV_ADAPTIVE.map((nav) => (
                    <NavLink
                      to={String(nav.path)}
                      key={nav.id}
                      className={styles.content__accountNavTitle}
                      onClick={closeMenuMobile}
                    >
                      {nav.title}
                    </NavLink>
                  ))}

                  {personalType === "legal" && (
                    <NavLink
                      to={SCREENS.SCREEN_ACCOUNT_COMPANY}
                      className={styles.content__accountNavTitle}
                      onClick={closeMenuMobile}
                    >
                      Компания
                    </NavLink>
                  )}

                  {showRules && (
                    <Link
                      to={SCREENS.SCREEN_TEXT_RULES}
                      className={styles.content__accountNavTitle}
                      target="_blank"
                    >
                      Правила
                    </Link>
                  )}

                  <button
                    onClick={onLogout}
                    className={styles.content__logoutBtn}
                  >
                    Выйти из аккаунта
                  </button>
                </nav>
              </>
            ) : (
              <button onClick={onLogin} className={styles.content__logoutBtn}>
                Войти
              </button>
            )}
          </div>
        </Reveal>
      </div>
    ) : (
      <></>
    );
  }
);
