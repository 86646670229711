import React, { FC, useState } from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { observer } from "mobx-react-lite";

import { RevealAnimation } from "../RevealAnimation";

import { RootContext } from "../../../stores/Root";
import { StoreUI } from "./storeUI";

import styles from "./styles/services.module.scss";
import "./styles/index.scss";

const animationFormLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-16px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const animationFormRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(16px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Services: FC = observer(() => {
  const rootStore = React.useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore));

  return (
    <div className={styles.container}>
      {storeUI.storeRoot.isMainLoading ? (
        <div className={styles.container__empty} />
      ) : (
        <div className={styles.container__content}>
          <RevealAnimation className={styles.container__contentAnimateTitle}>
            {storeUI.title}
          </RevealAnimation>

          <div className={styles.container__cardsColumns}>
            <div className={styles.container__cardsWrapperLeft}>
              <Reveal
                cascade
                triggerOnce
                damping={0.05}
                keyframes={animationFormLeft}
                className={styles.container__contentAnimateLeft}
              >
                {storeUI.leftItems}
              </Reveal>
            </div>

            <div className={styles.container__cardsWrapperRight}>
              <Reveal
                cascade
                triggerOnce
                damping={0.05}
                keyframes={animationFormRight}
                className={styles.container__contentAnimateRight}
              >
                {storeUI.rightItems}
              </Reveal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
