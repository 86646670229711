import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { Service } from "./Service";
import { Button } from "../../components/Button";
import { Footer } from "../../components/Footer";
import { Loader } from "../../components/Loader";
import { ModalReuse } from "../../components/Modals/ModalReuse";
import { NoContent } from "../../components/NoContent";
import { BackTo } from "../../components/BackTo";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../stores/Root";

import { EPageType } from "../../types";

import { DESCRIPTION_PAGE_DEFAULT, PAGE_TITLE_SERVICES } from "../../constants";

import styles from "./styles/services.module.scss";

export const Services: FC = observer(() => {
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  const _onBack = () => {
    window.history.back();
  };

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.Services
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_SERVICES}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <div className={styles.services}>
      {helmet}
      {!storeUI.isLoading ? (
        storeUI.services?.length ? (
          <div className={styles.services__content}>
            <BackTo
              text={"Назад"}
              onClick={_onBack}
              className={styles.services__backBtn}
            />
            <div className={styles.services__servicesList}>
              {storeUI.services?.map((service) => (
                <Service
                  title={service.name}
                  desc={service.description}
                  image={service.image}
                  key={service.id}
                  id={service.id}
                  price={service.price}
                  addToBasket={(serviseId: number) =>
                    storeUI.onClickAddBasket(service.isParking, serviseId)
                  }
                  isAvailable={service.isAvailable}
                  isAdded={service.isAdded}
                  haveApartment={storeUI.haveApartmentOfBasket}
                  deleteFromBasket={storeUI.deleteFromBasket}
                />
              ))}
            </div>
            {storeUI.total !== storeUI.services?.length && (
              <Button
                className={styles.services__button}
                title="ПОКАЗАТЬ ЕЩЁ"
                theme="transparent"
                onClick={storeUI.showMoreServices}
              />
            )}
            <Footer className={styles.services__footer} />
          </div>
        ) : (
          <div className={styles.noContent}>
            <NoContent
              title="Услуг пока нет"
              className={styles.noContent__empty}
            />
            <Footer className={styles.noContent__footer} />
          </div>
        )
      ) : (
        <div className={styles.services__empty}>
          <Loader />
        </div>
      )}

      <ModalReuse
        isShowModal={storeUI.showPopupInfo}
        type={"button"}
        btnTitle="Хорошо"
        onClickSingleButton={storeUI.closePopupInfo}
        setIsShowModal={storeUI.setShowPopupInfo}
      >
        <h2>Для аренды парковки свяжитесь с менеджером</h2>
      </ModalReuse>
    </div>
  );
});
