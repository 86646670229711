import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { RootContext } from "../../../../stores/Root";

import { Loader } from "../../../../components/Loader";
import { BookingCard } from "./BookingCard";
import { Empty } from "../../../../components/Empty";
import { ModalReuse } from "../../../../components/Modals/ModalReuse";

import { ReactComponent as Lock } from "../../../../assets/images/lock.svg";

import { StoreUI } from "./storeUI";

import {
  DOCUMENTS,
  PAYMENT_EXIST_POPUP_TEXT,
  RENT,
  RESIDENTS,
  SERVICES,
} from "../../../../constants";
import { SCREENS } from "../../../../navigation/endpoints";

import styles from "./styles/myBookings.module.scss";

export const MyBookings: FC = observer(() => {
  const navigate = useNavigate();
  const rootStore = React.useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore, navigate));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  const toCatalog = () => navigate(SCREENS.SCREEN_APARTMENTS);

  return (
    <div className={styles.myBookings}>
      <h2 className={styles.myBookings__title}>Мои брони</h2>
      {storeUI.isLoading ? (
        <Loader />
      ) : (
        <>
          {storeUI.myBookingsList && storeUI.myBookingsList.length ? (
            <div className={styles.myBookings__cardsContainer}>
              {storeUI.myBookingsList.map((item) => {
                return (
                  <BookingCard
                    isLoadingButtons={storeUI.isLoadingButtons}
                    bookingId={item.id}
                    apartmentName={item.apartmentName}
                    apartmentNumber={item.flatNumber}
                    floor={item.floor}
                    onClickRent={(bookingId: string) =>
                      storeUI.onClickMenuButton(bookingId, RENT)
                    }
                    onClickDocuments={(bookingId: string) =>
                      storeUI.onClickMenuButton(bookingId, DOCUMENTS)
                    }
                    onClickResidents={(bookingId: string) =>
                      storeUI.onClickMenuButton(bookingId, RESIDENTS)
                    }
                    onClickServices={(bookingId: string) =>
                      storeUI.onClickMenuButton(bookingId, SERVICES)
                    }
                    withWarning={item.withWarning}
                    warningText={item.warningText}
                    isReplacedButton={item.isVisibleReplaceButton}
                    replacedBtnTitle={item.replaceButtonText}
                    disabledReplaceButton={item.disabledReplaceButton}
                    onClickReplacedBtn={() =>
                      storeUI.onClickReplacedBtn(item.billId, item.documentId)
                    }
                    isExtension={item.isExtension}
                  />
                );
              })}
            </div>
          ) : (
            <div className={styles.myBookings__empty}>
              <Empty
                title="Вы пока ничего не арендовали"
                titleBtn="ВЫБРАТЬ КВАРТИРУ"
                Icon={Lock}
                onClick={toCatalog}
              />
            </div>
          )}
        </>
      )}

      <ModalReuse
        isShowModal={storeUI.isVisiblePopupLater}
        setIsShowModal={storeUI.setIsVisiblePopupLater}
        type={"button"}
        btnTitle={"Хорошо"}
        classNameModal={styles.myBookings__modalReuse}
        onClickSingleButton={storeUI.closePopupLater}
      >
        <p className={styles.myBookings__modalReuseTitle}>
          Счет на оплату пока не сформирован. Пожалуйста, попробуйте позже
        </p>
      </ModalReuse>

      <ModalReuse
        isShowModal={storeUI.isVisiblePopupExist}
        setIsShowModal={storeUI.setIsVisiblePopupExist}
        type={"button"}
        btnTitle={"Продолжить"}
        classNameModal={styles.myBookings__modalReuse}
        onClickSingleButton={storeUI.closePopupExist}
      >
        <p className={styles.myBookings__modalReuseTitle}>
          {PAYMENT_EXIST_POPUP_TEXT}
        </p>
      </ModalReuse>
    </div>
  );
});
