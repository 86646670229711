import { FC, FormEvent } from "react";
import { observer } from "mobx-react-lite";

import styles from "./styles/input.module.scss";

const InputMask = require("react-input-mask");

interface IProps {
  placeholder?: string;
  label: string;
  value: string;
  errors: string[];
  className?: string;
  readOnly?: boolean;
  onChange(value: string): void;
  onBlur?(): void;
  onFocus?(): void;
}

export const InputPhone: FC<IProps> = observer(
  ({
    placeholder = "+7 (000) 000 00-00",
    label = "",
    value = "",
    className = "",
    readOnly = false,
    errors = [],
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
  }) => {
    const _onChange = (e: FormEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    };
    return (
      <div className={`${styles.inputPhone} ${className}`}>
        <label className={styles.inputPhone__label}>{label}</label>
        <span className={styles.inputPhone__inputWrapper}>
          <InputMask
            mask={"+7 999 999 99 99"}
            maskChar={""}
            value={value}
            onChange={_onChange}
            placeholder={placeholder}
            type="text"
            readOnly={readOnly}
            className={`${styles.inputPhone__input}`}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {errors?.length !== 0 && (
            <span className={styles.inputPhone__errorText}>{errors[0]}</span>
          )}
        </span>
      </div>
    );
  }
);
