import { makeAutoObservable } from "mobx";

import { TResponseApi } from "../../../api/types";
import {
  changeTenants,
  deleteTenant,
  getResidents,
} from "../../../api/Residents";

import {
  IPet,
  IResidents,
  IServerErrorsTenants,
  ITenant,
  ITenantsAllSend,
} from "../../../interfaces/Tenants";
import { ICommonResponse } from "../../../interfaces";

import { Store } from "../../types";

export class ModelBookingResidents implements Store.IBookingResidents {
  tenants: ITenant[] = [];
  pets: IPet[] = [];
  countChildren: number = 0;
  isSuccessAddTenant: boolean = false;
  isSuccessDeleteTenant: boolean = false;

  serverErrors: IServerErrorsTenants = {
    addTenant: [],
  };

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  *getResidents(bookingId: number) {
    let response: TResponseApi<IResidents> = yield getResidents(bookingId);
    if (response.isSuccess && response.data !== null) {
      this.setTenants(response.data.tenants);
      this.setPets(response.data.pets);
      this.setCountChildren(response.data.countChildren);
    }
    return false;
  }

  *deleteTenant(bookingId: string, tenantId: number) {
    let response: TResponseApi<ICommonResponse> = yield deleteTenant(
      bookingId,
      tenantId
    );
    if (response.isSuccess && response.data !== null) {
      this.setTenants(this.tenants.filter((tenant) => tenant.id !== tenantId));
      this.setIsSuccessDeleteTenant(response.data.result === "success");
    } else {
      this.setIsSuccessDeleteTenant(false);
    }
  }

  *addTenant(params: ITenantsAllSend, bookingId: number) {
    let response: TResponseApi<ICommonResponse> = yield changeTenants(
      params,
      bookingId
    );

    if (response.isSuccess && response.data !== null) {
      this.setIsSuccessAddTenant(response.data.result === "success");
    } else {
      this.setIsSuccessAddTenant(false);
    }

    if (response.isError && response.error?.message) {
      this.serverErrors.addTenant = [];
      this.setServerErrors({ addTenant: [response.error.message] });
    } else {
      this.serverErrors.addTenant = [];
    }
    return response.isSuccess;
  }

  setTenants(value: ITenant[]) {
    this.tenants = value;
  }

  setPets(value: IPet[]) {
    this.pets = value;
  }

  setServerErrors(value: IServerErrorsTenants) {
    this.serverErrors = { ...this.serverErrors, ...value };
  }

  setIsSuccessAddTenant(value: boolean) {
    this.isSuccessAddTenant = value;
  }

  setIsSuccessDeleteTenant(value: boolean) {
    this.isSuccessDeleteTenant = value;
  }

  setCountChildren(value: number) {
    this.countChildren = value;
  }
}
