import { makeAutoObservable } from "mobx";

import {
  addZeroToEndPrice,
  changeSpaceInNumber,
} from "../../../../../../helpers";
import { openPdf } from "../../../../../../helpers/openPdf";
import { downloadPdf } from "../../../../../../helpers/downloadPdf";

import { PDF_NAME_BILL } from "../../../../../../constants";

import { IStoreUI } from "./interfaces";

import { Store } from "../../../../../../stores/types";
import { EChoosePayType, EStatusBill } from "../../../../../../types";
import {
  disableScroll,
  enableScroll,
} from "../../../../../../helpers/modalsFunc";

export class StoreUI implements IStoreUI {
  storeBills: Store.IBillsStore;
  storeDocuments: Store.IDocumentsStore;
  storeChoosePay: Store.IChoosePay;
  storePayment: Store.IPayment;

  isLoading: boolean = false;
  isVisiblePaymentPopup: boolean = false;
  isVisiblePopupExist: boolean = false;

  popupExistCloseCallback: Function | null = null;

  currentLoadingId: number | null = null;
  currentBillId: number | null = null;

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeBills = storeRoot.storeBills;
    this.storeDocuments = storeRoot.storeDocuments;
    this.storeChoosePay = storeRoot.storeModals.storeChoosePay;
    this.storePayment = storeRoot.storePayment;
  }

  // getters

  get bills() {
    return (
      this.storeBills.bills?.map((item) => ({
        id: item.id,
        documentId: item.documentId,
        number: item.number,
        status: item.status,
        readyStatus: item.status ? this.getReadyStatus(item.status) : null,
        date: this.reverseAndReplaceSeparator(item.date, "-", "."),
        endDate: this.reverseAndReplaceSeparator(item.endDate, "-", "."),
        amount: this.getReadyPrice(item.amount),
        disablePaid: item.status !== EStatusBill.NotPaid,
      })) || []
    );
  }

  // functions

  *init(bookingId: number) {
    this.setIsLoading(true);
    yield this.storeBills.getBills(bookingId);
    this.setIsLoading(false);
  }

  *onShowPdf(documentId: number) {
    this.setCurrentLoadingId(documentId);
    let agreement: string = yield this.getBillData(documentId);
    this.setCurrentLoadingId(null);
    openPdf(agreement);
  }

  *onDownloadPdf(documentId: number) {
    this.setCurrentLoadingId(documentId);
    let act: string = yield this.getBillData(documentId);

    if (act.length) {
      downloadPdf(act, PDF_NAME_BILL);
    }
    this.setCurrentLoadingId(null);
  }

  *getBillData(id: number) {
    yield this.storeDocuments.getDocumentPdf(id);

    if (this.storeDocuments?.documentBase64.length) {
      return this.storeDocuments?.documentBase64;
    }
    return "";
  }

  *onClickPay(billId: number, documentId: number) {
    yield this.getPaymentExists(billId); // проверяет, была ли создана запись об оплате

    if (this.storePayment.isPaymentExist) {
      this.openPopupExist(() => this.openChoosePay(billId, documentId)); // если была, то открыть предупреждающий попап, а уже потом попап оплаты
    } else {
      this.openChoosePay(billId, documentId);
    }
  }

  *getPaymentExists(billId: number) {
    yield this.storePayment.getExistPayment(billId);
  }

  openChoosePay(billId: number, documentId: number) {
    this.storeChoosePay.openChoosePay({
      id: billId,
      documentId: documentId,
      type: EChoosePayType.Base,
    });
  }

  openPopupExist(closeCallback: Function | null) {
    this.setIsVisiblePopupExist(true);
    disableScroll();

    if (closeCallback) {
      this.setPopupExistCloseCallback(closeCallback);
    }
  }

  closePopupExist() {
    this.setIsVisiblePopupExist(false);
    enableScroll();

    if (this.popupExistCloseCallback) {
      this.popupExistCloseCallback();
      this.setPopupExistCloseCallback(null);
    }
  }

  // ---- helpers

  getReadyStatus(status: EStatusBill) {
    console.log(status);

    switch (status) {
      case EStatusBill.NotPaid:
        return "Не оплачен";
      case EStatusBill.Paid:
        return "Оплачен";
      case EStatusBill.ParticallyPaid:
        return "Частично оплачен";
      default:
        return "";
    }
  }

  getReadyPrice(price: number) {
    let priceWithSpace = changeSpaceInNumber(price);
    return price ? addZeroToEndPrice(priceWithSpace) : "0";
  }

  reverseAndReplaceSeparator(
    str: string,
    separatorFrom: string,
    separatorTo: string
  ) {
    return str.split(separatorFrom).reverse().join(separatorTo);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setCurrentLoadingId(value: number | null) {
    this.currentLoadingId = value;
  }

  setCurrentBillId(value: number | null) {
    this.currentBillId = value;
  }

  setIsVisiblePaymentPopup(value: boolean) {
    this.isVisiblePaymentPopup = value;
  }

  setIsVisiblePopupExist(value: boolean) {
    this.isVisiblePopupExist = value;
  }

  setPopupExistCloseCallback(value: Function | null) {
    this.popupExistCloseCallback = value;
  }
}
