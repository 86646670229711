import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../Button";
import { InputPhone } from "../../../InputPhone/";
import { InputPassword } from "../../../InputPassword/";
import { Loader } from "../../../Loader/";

import { IAuthorizationUI } from "../../../../stores/ui/Authetication/interfaces";

import { PASSWORD_INFO_TEXT } from "../../../../constants/index";

import styles from "./styles/login.module.scss";

interface IProps {
  storeUI: IAuthorizationUI;
  isVisible: boolean;
}

export const LogIn: FC<IProps> = observer(({ isVisible = false, storeUI }) => {
  return (
    <>
      {isVisible && (
        <div className={styles.modal__contentWrapper}>
          {storeUI.isLoading && <Loader />}
          <h2 className={styles.modal__contentTitle}>Личный кабинет</h2>
          <p className={styles.modal__descsiption}>
            Для входа в личный кабинет <br /> введите номер телефона и пароль
          </p>
          <InputPhone
            value={storeUI.phone}
            onChange={storeUI.changePhone}
            errors={storeUI.errorsPhone}
            className={styles.modal__input}
            label="Телефон"
          />
          <InputPassword
            value={storeUI.password}
            onChange={storeUI.changePassword}
            errors={storeUI.errorsPassword}
            className={styles.modal__input}
            infoIconText={PASSWORD_INFO_TEXT}
            label="Пароль"
            placeholder="Введите пароль"
            withInfoIcon={storeUI.withInfoIcon}
          />

          <button
            onClick={storeUI.toPasswordRecovery}
            className={styles.modal__recovery}
          >
            Забыли пароль?
          </button>

          <Button
            className={styles.modal__button}
            title="ВОЙТИ"
            theme="gold"
            onClick={storeUI.onSend}
            disabled={storeUI.isLoading}
            isDisabledStyle={storeUI.phone === "" || storeUI.password === ""}
          />

          <button className={styles.modal__signup} onClick={storeUI.toRegister}>
            Регистрация
          </button>
        </div>
      )}
    </>
  );
});
