import { TResponseApi } from "../types";
import { ApiManager } from "../index";

import {
  FAVORITE,
  FAVORITE_DELETE,
  USER_GET_PROFILE,
  USER,
  USER_AVATAR,
  HINT_ADDRESS,
} from "../../constants/api";

import { IUserProfile } from "../../interfaces/User";
import { ICommonResponse, IFavoritesResponse } from "../../interfaces";

export async function getPublicProfile(): Promise<TResponseApi<IUserProfile>> {
  return await ApiManager.request<IUserProfile>(
    {
      url: USER_GET_PROFILE,
      method: "GET",
    },
    true
  );
}

export async function updateProfile(
  params: IUserProfile
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: USER,
      method: "PUT",
      params: params,
    },
    true
  );
}

export async function changeAvatar(
  image: File
): Promise<TResponseApi<IUserProfile>> {
  let formData = new FormData();
  formData.append("image", image);
  return await ApiManager.request<IUserProfile>(
    {
      url: USER_AVATAR,
      method: "POST",
      data: formData,
    },
    true
  );
}

export async function deleteProfile(): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: USER,
      method: "DELETE",
    },
    true
  );
}

export async function addFavorite(params: {
  apartmentId: number;
}): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: FAVORITE,
      method: "POST",
      data: params,
    },
    true
  );
}

export async function getFavorite(params: {
  limit: number;
}): Promise<TResponseApi<IFavoritesResponse>> {
  return await ApiManager.request<IFavoritesResponse>(
    {
      url: FAVORITE,
      method: "GET",
      params: params,
    },
    true
  );
}

export async function deleteFavorite(
  apartmentId: number
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: FAVORITE_DELETE(apartmentId),
      method: "DELETE",
    },
    true
  );
}

export async function getHintByString(params: {
  query: string;
}): Promise<TResponseApi<string[]>> {
  return await ApiManager.request<string[]>({
    url: HINT_ADDRESS,
    method: "GET",
    params: params,
  });
}
