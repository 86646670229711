import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Tenant } from "../Tenant";
import { Button } from "../../../components/Button";
import { AddPersonBtn } from "../../../components/AddPersonBtn";
import { BackTo } from "../../../components/BackTo";
import { Loader } from "../../../components/Loader";
import { ModalReuse } from "../../../components/Modals/ModalReuse";

import { IOptionCommon } from "../../../interfaces";

import { EFormType } from "../../../types";
import { Store } from "../../../stores/types";

import styles from "./styles/stepTenants.module.scss";

interface IProps {
  storeTenants: Store.ITenantsOrderingData | null;
  stepTitle: string;
  canPets: boolean;
  isDisabledAll: boolean;
  onBack(): void;
}

export const StepTenants: FC<IProps> = observer(
  ({
    storeTenants = null,
    stepTitle = "Шаг 0 из 0",
    canPets = false,
    isDisabledAll = false,
    onBack = () => {},
  }: IProps) => {
    return (
      storeTenants && (
        <div className={styles.stepTenants}>
          <BackTo
            text={"Вернуться назад"}
            onClick={onBack}
            className={styles.stepTenants__backBtn}
            disabled={storeTenants.isLoading || isDisabledAll}
          />
          <div className={styles.stepTenants__header}>
            <h5 className={styles.stepTenants__title}>
              Заполните данные о жильцах
            </h5>
            <span className={styles.stepTenants__stepNum}>{stepTitle}</span>
          </div>

          <div className={styles.stepTenants__mainContent}>
            {storeTenants.isLoading && (
              <Loader className={styles.stepTenants__loader} />
            )}

            <div className={styles.stepTenants__adultsBlock}>
              <div className={styles.stepTenants__tenantsCards}>
                <h5 className={styles.stepTenants__subtitle}>Жильцы</h5>
                {storeTenants.tenantsForms?.length > 0 ? (
                  storeTenants.tenantsForms.map((tenant, index) => (
                    <Tenant
                      key={index}
                      isReadonly={storeTenants.isLoading || isDisabledAll}
                      showTrashCan={true}
                      typeForm={EFormType.Tenant}
                      className={
                        index === 0
                          ? styles.stepTenants__tenantFirstCard
                          : styles.stepTenants__tenantCard
                      }
                      valuePhone={tenant.phone}
                      valueSeries={tenant.series}
                      valueNumberPassport={tenant.number}
                      valueDateIssue={tenant.dateIssue}
                      valueIssuedBy={tenant.issuedBy}
                      valueRegistrationAddress={tenant.registrationAddress}
                      valueDateBirth={tenant.birthdate}
                      valuePlace={tenant.placeBirth}
                      valueRelationTenant={tenant.relationTenant}
                      valueLastName={tenant.lastName}
                      valueFirstName={tenant.firstName}
                      valueMiddleName={tenant.middleName}
                      valueGender={tenant.gender}
                      genderOptions={tenant.genderOptions}
                      errorsGender={tenant.validateErrors.gender}
                      errorsPhone={tenant.validateErrors.phone}
                      errorsSeries={tenant.validateErrors.series}
                      errorsNumberPassport={tenant.validateErrors.number}
                      errorsDateIssue={tenant.validateErrors.dateIssue}
                      errorsIssuedBy={tenant.validateErrors.issuedBy}
                      errorsRegistrationAddress={
                        tenant.validateErrors.registrationAddress
                      }
                      errorsDateBirth={tenant.validateErrors.birthdate}
                      errorsPlace={tenant.validateErrors.placeBirth}
                      errorsRelationTenant={
                        tenant.validateErrors.relationTenant
                      }
                      errorsLastName={tenant.validateErrors.lastName}
                      errorsFirstName={tenant.validateErrors.firstName}
                      errorsMiddleName={tenant.validateErrors.middleName}
                      hintAddressStrings={tenant.hintStringsAddress}
                      hintStringsPlaceBirth={tenant.hintStringsPlaceBirth}
                      changePhone={(value: string) =>
                        storeTenants.changePhone(value, tenant.localId)
                      }
                      changeSeries={(value: string) =>
                        storeTenants.changeSeries(value, tenant.localId)
                      }
                      changeNumberPassport={(value: string) =>
                        storeTenants.changeNumberPassport(value, tenant.localId)
                      }
                      changeDateIssue={(value: string) =>
                        storeTenants.changeDateIssue(value, tenant.localId)
                      }
                      changeIssuedBy={(value: string) =>
                        storeTenants.changeIssuedBy(value, tenant.localId)
                      }
                      changeRegistrationAddress={(value: string) =>
                        storeTenants.changeAddress(value, tenant.localId)
                      }
                      changeDateBirth={(value: string) =>
                        storeTenants.changeDateBirth(value, tenant.localId)
                      }
                      changePlace={(value: string) =>
                        storeTenants.changePlaceBirth(value, tenant.localId)
                      }
                      changeRelationTenant={(value: string) =>
                        storeTenants.changeRelationTenant(value, tenant.localId)
                      }
                      changeGender={(value: IOptionCommon | null) =>
                        storeTenants.changeGender(value, tenant.localId)
                      }
                      onDelete={() =>
                        storeTenants.openDeletePopup(tenant.localId)
                      }
                      changeLastName={(value: string) =>
                        storeTenants.changeLastName(value, tenant.localId)
                      }
                      changeFirstName={(value: string) =>
                        storeTenants.changeFirstName(value, tenant.localId)
                      }
                      changeMiddleName={(value: string) =>
                        storeTenants.changeMiddleName(value, tenant.localId)
                      }
                      onClickHintAddress={(value: string) =>
                        storeTenants.onClickHintAddress(value, tenant.localId)
                      }
                      onClickHintPlaceBirth={(value: string) =>
                        storeTenants.onClickHintPlaceBirth(
                          value,
                          tenant.localId
                        )
                      }
                    />
                  ))
                ) : (
                  <div className={styles.stepTenants__noTenantsContainer}>
                    <p className={styles.stepTenants__noTenantsParagraph}>
                      Вы можете добавить информацию о взрослых или
                      несовершеннолетних жильцах
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.stepTenants__addTenantContainer}>
                <AddPersonBtn
                  title={"Добавить жильца"}
                  onClick={storeTenants.addTenant}
                  theme="gold"
                  className={`${styles.stepTenants__addTenantsBtn} ${
                    storeTenants.disabledAddTenant
                      ? styles.stepTenants__addTenantsBtn_disabled
                      : ""
                  }`}
                  disabled={storeTenants.isLoading || isDisabledAll}
                />
              </div>
            </div>

            {storeTenants.mainErrors.length > 0 && (
              <span className={styles.stepTenants__mainError}>
                {storeTenants.mainErrors[0]}
              </span>
            )}
            {canPets ? (
              <Button
                title={"Продолжить"}
                onClick={storeTenants.onContinue}
                theme={"gold"}
                className={styles.stepTenants__button}
                disabled={storeTenants.isLoading || isDisabledAll}
                isDisabledStyle={storeTenants.isLoading || isDisabledAll}
              />
            ) : (
              <Button
                title={
                  isDisabledAll ? "Перейти в Мои брони" : "Создать договор"
                }
                onClick={storeTenants.onContinue}
                theme={"gold"}
                className={styles.stepTenants__button}
                disabled={storeTenants.isLoading}
                isDisabledStyle={storeTenants.isLoading}
              />
            )}
          </div>

          <ModalReuse
            isShowModal={storeTenants.isVisibleDeletePopup}
            type={"buttons"}
            btnLeft="Да"
            btnRight="Нет"
            onClick={() =>
              storeTenants.deleteTenant(storeTenants.currentIdForDelete ?? 0)
            }
            setIsShowModal={storeTenants.setIsVisibleDeletePopup}
          >
            <h2>Вы уверены, что хотите удалить жильца?</h2>
          </ModalReuse>
        </div>
      )
    );
  }
);
