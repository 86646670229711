import { TResponseApi } from "../types";
import { ApiManager } from "../index";

import { COMPANY } from "../../constants/api";

import { ICommonResponse } from "../../interfaces";
import { ICompany } from "../../interfaces/Company";

export async function getUserCompany(): Promise<TResponseApi<ICompany>> {
  return await ApiManager.request<ICompany>(
    {
      url: COMPANY,
      method: "GET",
    },
    true
  );
}

export async function createUserCompany(
  params: ICompany
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: COMPANY,
      method: "POST",
      params: params,
    },
    true
  );
}
