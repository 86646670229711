import { makeAutoObservable } from "mobx";
import { Validator } from "validator-pyrobyte";
import { parse, isValid } from "date-fns";

import { newRules } from "../../../../../helpers/validation";
import {
  ERROR_CODE_LENGTH,
  ERROR_CORRECT_DATE,
  ERROR_GENDER,
  ERROR_MIN_CHARS_THREE,
  ERROR_MIN_CHARS_TWO,
  ERROR_NUMBER_LENGTH,
  ERROR_SERIES_LENGTH,
  ERROR_TEXT_EMPTY,
  ERROR_TEXT_PHONE,
  NUMBER_PASSPORT_LENGTH,
  SERIES_PASSPORT_LENGTH,
  SUBDIVISION_CODE_LENGTH,
} from "../../../../../constants";

import { ITenantErrors, ITenantStoreUI } from "./interfaces";
import { IOptionGender } from "../../../../../interfaces";
import { ITypes } from "../../../../../interfaces/Catalog";

export class TenantStoreUI implements ITenantStoreUI {
  validator: Validator;
  idTenant: number = 0;
  localId: number = 0;

  lastName: string = "";
  firstName: string = "";
  middleName: string = "";
  phone: string = "";
  birthdate: string = "";
  series: string = "";
  number: string = "";
  dateIssue: string = "";
  issuedBy: string = "";
  registrationAddress: string = "";
  relationTenant: string = "";
  placeBirth: string = "";
  subdivisionCode: string = "";
  gender: IOptionGender | null = null;

  hintStringsAddress: string[] = [];
  hintStringsPlaceBirth: string[] = [];

  validateErrors: ITenantErrors = {
    lastName: [],
    firstName: [],
    middleName: [],
    phone: [],
    birthdate: [],
    gender: [],
    series: [],
    number: [],
    dateIssue: [],
    issuedBy: [],
    registrationAddress: [],
    placeBirth: [],
    subdivisionCode: [],
    relationTenant: [],
  };

  isRenterMode: boolean = false;

  constructor({ isRenterMode = false }) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.validator = new Validator(
      {
        email: {
          email: true,
          required: true,
        },

        specialChars: {
          specialChars: true,
        },
      },
      newRules
    );

    this.setIsRenterMode(isRenterMode);
  }

  //FUNCTIONS

  // validation

  validateLastName() {
    if (!this.lastName.length) {
      this.validateErrors.lastName = [
        ...this.validateErrors.lastName,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.lastName = [];
    }

    if (this.lastName.length < 2) {
      this.validateErrors.lastName = [
        ...this.validateErrors.lastName,
        ERROR_MIN_CHARS_TWO,
      ];
      return false;
    } else {
      this.validateErrors.lastName = [];
    }

    return true;
  }

  validateFirstName() {
    if (!this.firstName.length) {
      this.validateErrors.firstName = [
        ...this.validateErrors.firstName,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.firstName = [];
    }

    if (this.firstName.length < 2) {
      this.validateErrors.firstName = [
        ...this.validateErrors.firstName,
        ERROR_MIN_CHARS_TWO,
      ];
      return false;
    } else {
      this.validateErrors.firstName = [];
    }

    return true;
  }

  validatePhone() {
    if (!this.phone.length) {
      this.validateErrors.phone = [
        ...this.validateErrors.phone,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.phone = [];
    }

    if (this.phone.replaceAll(" ", "").length < 12) {
      this.validateErrors.phone = [
        ...this.validateErrors.phone,
        ERROR_TEXT_PHONE,
      ];
      return false;
    } else {
      this.validateErrors.phone = [];
    }
    return true;
  }

  validateDateBirth() {
    if (!this.birthdate.length) {
      this.validateErrors.birthdate = [
        ...this.validateErrors.birthdate,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.birthdate = [];
    }

    //проверка на ввод будущих дат
    let birthdate = parse(this.birthdate, "dd.MM.yyyy", new Date());
    let now = new Date();

    let nowTime = now.getTime();
    let birthdateTime = birthdate.getTime();
    let isNextDate = birthdateTime > nowTime;

    let isCorrect = isValid(birthdate);

    if (!isCorrect || isNextDate || birthdate.getFullYear() <= 1899) {
      this.validateErrors.birthdate = [
        ...this.validateErrors.birthdate,
        ERROR_CORRECT_DATE,
      ];
      return false;
    } else {
      this.validateErrors.birthdate = [];
    }

    return true;
  }

  validateSeries() {
    if (!String(this.series).length) {
      this.validateErrors.series = [
        ...this.validateErrors.series,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.series = [];
    }

    if (String(this.series).length < SERIES_PASSPORT_LENGTH) {
      this.validateErrors.series = [
        ...this.validateErrors.series,
        ERROR_SERIES_LENGTH,
      ];
      return false;
    } else {
      this.validateErrors.series = [];
    }
    return true;
  }

  validatePassportNumber() {
    if (!String(this.number).length) {
      this.validateErrors.number = [
        ...this.validateErrors.number,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.number = [];
    }

    if (String(this.number).length < NUMBER_PASSPORT_LENGTH) {
      this.validateErrors.number = [
        ...this.validateErrors.number,
        ERROR_NUMBER_LENGTH,
      ];
      return false;
    } else {
      this.validateErrors.number = [];
    }
    return true;
  }

  validateDate() {
    if (!this.dateIssue.length) {
      this.validateErrors.dateIssue = [
        ...this.validateErrors.dateIssue,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.dateIssue = [];
    }

    //проверка на ввод будущих дат
    let birthdate = parse(this.dateIssue, "dd.MM.yyyy", new Date());
    let now = new Date();

    let nowTime = now.getTime();
    let birthdateTime = birthdate.getTime();
    let isNextDate = birthdateTime > nowTime;

    let isCorrect = isValid(birthdate);

    if (!isCorrect || isNextDate || birthdate.getFullYear() <= 1899) {
      this.validateErrors.dateIssue = [
        ...this.validateErrors.dateIssue,
        ERROR_CORRECT_DATE,
      ];
      return false;
    } else {
      this.validateErrors.dateIssue = [];
    }

    return true;
  }

  validateIssuedBy() {
    if (!this.issuedBy.length) {
      this.validateErrors.issuedBy = [
        ...this.validateErrors.issuedBy,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.issuedBy = [];
    }

    if (this.issuedBy.length < 3) {
      this.validateErrors.issuedBy = [
        ...this.validateErrors.issuedBy,
        ERROR_MIN_CHARS_THREE,
      ];
      return false;
    } else {
      this.validateErrors.issuedBy = [];
    }

    return true;
  }

  validateAddress() {
    if (!this.registrationAddress.length) {
      this.validateErrors.registrationAddress = [
        ...this.validateErrors.registrationAddress,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.registrationAddress = [];
    }

    if (this.registrationAddress.length < 3) {
      this.validateErrors.registrationAddress = [
        ...this.validateErrors.registrationAddress,
        ERROR_MIN_CHARS_THREE,
      ];
      return false;
    } else {
      this.validateErrors.registrationAddress = [];
    }

    return true;
  }

  validateGender() {
    if (!this.gender) {
      this.validateErrors.gender = [
        ...this.validateErrors.gender,
        ERROR_GENDER,
      ];
      return false;
    } else {
      this.validateErrors.gender = [];
    }

    return true;
  }

  validatePlaceBirth() {
    if (!this.placeBirth.length) {
      this.validateErrors.placeBirth = [
        ...this.validateErrors.placeBirth,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.placeBirth = [];
    }

    if (this.placeBirth.length < 3) {
      this.validateErrors.placeBirth = [
        ...this.validateErrors.placeBirth,
        ERROR_MIN_CHARS_THREE,
      ];
      return false;
    } else {
      this.validateErrors.placeBirth = [];
    }

    return true;
  }

  validateSubdivisionCode() {
    if (!this.subdivisionCode.length) {
      this.validateErrors.subdivisionCode = [
        ...this.validateErrors.subdivisionCode,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.subdivisionCode = [];
    }

    if (
      this.subdivisionCode.replaceAll("_", "").length < SUBDIVISION_CODE_LENGTH
    ) {
      this.validateErrors.subdivisionCode = [
        ...this.validateErrors.subdivisionCode,
        ERROR_CODE_LENGTH,
      ];
      return false;
    } else {
      this.validateErrors.subdivisionCode = [];
    }

    return true;
  }

  validateRealtionTenant() {
    if (!this.relationTenant.length) {
      this.validateErrors.relationTenant = [
        ...this.validateErrors.relationTenant,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.subdivisionCode = [];
    }

    if (this.relationTenant.length < 2) {
      this.validateErrors.relationTenant = [
        ...this.validateErrors.relationTenant,
        ERROR_MIN_CHARS_TWO,
      ];
      return false;
    } else {
      this.validateErrors.relationTenant = [];
    }

    return true;
  }

  validateAll() {
    if (this.isRenterMode) {
      this.validateLastName();
      this.validateFirstName();
      this.validateDateBirth();
      this.validatePhone();
      this.validateGender();
      this.validatePlaceBirth();
      this.validateSeries();
      this.validatePassportNumber();
      this.validateDate();
      this.validateIssuedBy();
      this.validateSubdivisionCode();
      this.validateAddress();
    } else {
      this.validateLastName();
      this.validateFirstName();
      this.validateDateBirth();
      this.validatePhone();
      this.validateGender();
      this.validateRealtionTenant();
      this.validatePlaceBirth();
      this.validateSeries();
      this.validatePassportNumber();
      this.validateIssuedBy();
      this.validateDate();
      this.validateAddress();
    }
  }

  checkValidateAll() {
    if (this.isRenterMode) {
      return (
        this.validateLastName() &&
        this.validateFirstName() &&
        this.validateDateBirth() &&
        this.validatePhone() &&
        this.validateGender() &&
        this.validatePlaceBirth() &&
        this.validateSeries() &&
        this.validatePassportNumber() &&
        this.validateDate() &&
        this.validateIssuedBy() &&
        this.validateSubdivisionCode() &&
        this.validateAddress()
      );
    } else {
      return (
        this.validateLastName() &&
        this.validateFirstName() &&
        this.validateDateBirth() &&
        this.validatePhone() &&
        this.validateGender() &&
        this.validateRealtionTenant() &&
        this.validatePlaceBirth() &&
        this.validateSeries() &&
        this.validatePassportNumber() &&
        this.validateIssuedBy() &&
        this.validateDate() &&
        this.validateAddress()
      );
    }
  }

  // changes

  changeLastName(value: string) {
    this.setLastName(value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, ""));

    if (this.validateErrors.lastName.length) {
      this.validateErrors.lastName = [];
    }
  }

  changeFirstName(value: string) {
    this.setFirstName(value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, ""));

    if (this.validateErrors.firstName.length) {
      this.validateErrors.firstName = [];
    }
  }

  changeMiddleName(value: string) {
    this.setMiddleName(value.replace(/[^a-zA-ZА-Я а-яЁё]/gi, ""));

    if (this.validateErrors.middleName.length) {
      this.validateErrors.middleName = [];
    }
  }

  changeDateBirth(value: string) {
    this.setDateBirth(value);

    if (this.validateErrors.birthdate.length && this.birthdate.length) {
      this.validateErrors.birthdate = [];
    }
  }

  changePhone(value: string) {
    this.setPhone(value);

    if (this.validateErrors.phone.length) {
      this.validateErrors.phone = [];
    }
  }

  changeSeries(value: string) {
    this.setSeries(value.replace(/[^0-9]+/, ""));

    if (this.validateErrors.series.length && this.series.length) {
      this.validateErrors.series = [];
    }
  }

  changeNumberPassport(value: string) {
    this.setNumberPassport(value.replace(/[^0-9]+/, ""));

    if (this.validateErrors.number.length && this.number.length) {
      this.validateErrors.number = [];
    }
  }

  changeDateIssue(value: string) {
    this.setDateIssue(value);

    if (this.validateErrors.dateIssue.length && this.dateIssue.length) {
      this.validateErrors.dateIssue = [];
    }
  }

  changeIssuedBy(value: string) {
    this.setIssuedBy(value);

    if (this.validateErrors.issuedBy.length) {
      this.validateErrors.issuedBy = [];
    }
  }

  changeRegistrationAddress(value: string) {
    this.setRegistrationAddress(value);

    if (this.validateErrors.registrationAddress.length) {
      this.validateErrors.registrationAddress = [];
    }
  }

  changeGender(value: ITypes | null) {
    this.setGender(value);

    if (this.validateErrors.gender.length) {
      this.validateErrors.gender = [];
    }
  }

  changeSubdivisionCode(value: string) {
    this.setSubdivisionCode(value);

    if (this.validateErrors.subdivisionCode.length) {
      this.validateErrors.subdivisionCode = [];
    }
  }

  changePlaceBirth(value: string) {
    this.setPlaceBirth(value);

    if (this.validateErrors.placeBirth.length) {
      this.validateErrors.placeBirth = [];
    }
  }

  changeRelationTenant(value: string) {
    this.setRelationTenant(value);

    if (this.validateErrors.relationTenant.length) {
      this.validateErrors.relationTenant = [];
    }
  }

  // helpers
  /*   
  writeInfoByBackend(isRenterMode: boolean, tenant: ITenant ) {
      if (isRenterMode) {
        this.changeLastName(tenant.lastName ?? "");
        this.changeFirstName(tenant.firstName ?? "");
        this.changeMiddleName(tenant.middleName ?? "");
        this
        this.changePhone(tenant.phone ?? "");


      }
  } */

  // setters

  setIdTetant(value: number) {
    this.idTenant = value;
  }

  setLocalId(value: number) {
    this.localId = value;
  }

  setLastName(value: string) {
    this.lastName = value;
  }

  setFirstName(value: string) {
    this.firstName = value;
  }

  setMiddleName(value: string) {
    this.middleName = value;
  }

  setDateBirth(value: string) {
    this.birthdate = value;
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setSeries(value: string) {
    this.series = value;
  }

  setNumberPassport(value: string) {
    this.number = value;
  }

  setDateIssue(value: string) {
    this.dateIssue = value;
  }

  setIssuedBy(value: string) {
    this.issuedBy = value;
  }

  setRegistrationAddress(value: string) {
    this.registrationAddress = value;
  }

  setHintStringsAddress(value: string[]) {
    this.hintStringsAddress = value;
  }

  setHintStringsPlaceBirth(value: string[]) {
    this.hintStringsPlaceBirth = value;
  }

  setRelationTenant(value: string) {
    this.relationTenant = value;
  }

  setGender(gender: IOptionGender | null) {
    this.gender = gender;
  }

  setSubdivisionCode(value: string) {
    this.subdivisionCode = value;
  }

  setPlaceBirth(value: string) {
    this.placeBirth = value;
  }

  setIsRenterMode(value: boolean) {
    this.isRenterMode = value;
  }
}
