import { makeAutoObservable } from "mobx";

import { deleteFavorite, getFavorite } from "../../../api/Account";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { ICommonResponse } from "../../../interfaces";
import { IFavoriteItem, IFavoritesResponse } from "../../../interfaces/Account";

export class ModelFavorites implements Store.IFavorites {
  favorites: IFavoriteItem[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *getFavorites() {
    let response: TResponseApi<IFavoritesResponse> = yield getFavorite();
    if (response.isSuccess && response.data !== null) {
      this.setFavorites(response.data.list);
    }
  }

  *deleteFavorite(apartmentId: number) {
    let response: TResponseApi<ICommonResponse> = yield deleteFavorite(
      apartmentId
    );
    if (response.isSuccess && response.data !== null) {
      this.setFavorites(this.favorites.filter((fav) => fav.id !== apartmentId));
    }
  }

  setFavorites(favorites: IFavoriteItem[]) {
    this.favorites = favorites;
  }
}

export const StoreFavorites = new ModelFavorites();
