import { FC } from "react";
import { observer } from "mobx-react-lite";

import check from "../../assets/images/check.svg";
import uncheck from "../../assets/images/uncheck.svg";

import styles from "./styles/check.module.scss";

interface IProps {
  id: number;
  title: string;
  selected: (id: number) => void;
  isSelected: boolean;
  className?: string;
  withCircleCheck?: boolean;
}

export const Check: FC<IProps> = observer(
  ({
    title = "",
    selected = () => {},
    id = 0,
    isSelected = false,
    className = "",
    withCircleCheck = true,
  }) => {
    return (
      <div
        className={`${styles.container} ${
          isSelected ? styles.yellow : styles.pale
        } ${className}`}
        onClick={() => selected(id)}
      >
        <span className={isSelected ? styles.black : styles.grey}>{title}</span>
        {withCircleCheck && (
          <>
            {isSelected ? (
              <img src={check} alt="check" />
            ) : (
              <img src={uncheck} alt="uncheck" />
            )}
          </>
        )}
      </div>
    );
  }
);
