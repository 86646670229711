import { makeAutoObservable } from "mobx";

import { changeSpaceInNumber } from "../../../helpers";
import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";

import { Store } from "../../../stores/types";
import { EServiceCode } from "../../../types";

import { IStoreUI } from "./interfaces";

import { LIMIT_SHOW_SERVICES } from "../../../constants";

export class StoreUI implements IStoreUI {
  storeServices: Store.IServices;
  isLoading: boolean = true;
  countShowMoreServices: number = LIMIT_SHOW_SERVICES;

  showPopupInfo: boolean = false;

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeServices = storeRoot.storeServices;
  }

  // getters

  get services() {
    return this.storeServices.services?.map((service) => {
      return {
        id: service.id,
        name: service.name ?? null,
        image: service.image ?? null,
        price: changeSpaceInNumber(service.price) ?? null,
        description: service.description ?? null,
        isAvailable: this.checkIsAvailable(service.id),
        isAdded: this.checkIsAdded(service.id),
        isParking: service.code === EServiceCode.Parking || false,
      };
    });
  }

  get total() {
    return this.storeServices.total;
  }

  get haveApartmentOfBasket() {
    return this.storeServices.haveApartment;
  }

  // functions

  *init() {
    enableScroll();
    this.setIsLoading(true);
    yield this.storeServices.getServices(LIMIT_SHOW_SERVICES);
    yield this.storeServices.getBasket();

    if (this.storeServices.haveApartment) {
      yield this.storeServices.getAvailableServicesId();
    }
    this.setIsLoading(false);
  }

  *showMoreServices() {
    this.setCountShowMoreServices();
    yield this.storeServices.getServices(this.countShowMoreServices);
  }

  *onClickAddBasket(isParking: boolean, serviceId: number) {
    if (isParking) {
      this.openPopupInfo();
    } else {
      yield this.addToBasket(serviceId);
    }
  }

  *addToBasket(serviceId: number) {
    let response: boolean = yield this.storeServices.addServiceToBasket(
      serviceId
    );

    if (response) {
      this.storeServices.addServicesId(serviceId);
      yield this.storeServices.getBasket();
    }
  }

  *deleteFromBasket(serviceId: number) {
    let _service = this.storeServices.servicesInBasket?.find(
      (service) => service.serviceId === serviceId
    );

    if (_service) {
      let response: boolean = yield this.storeServices.deleteService(
        _service?.id
      );

      if (response) {
        this.storeServices.deleteServiceId(serviceId);
      }
    }
  }

  checkIsAvailable(serviceId: number) {
    return this.storeServices.availableServicesId?.includes(serviceId);
  }

  checkIsAdded(serviceId: number) {
    return this.storeServices.addedServicesId?.includes(serviceId);
  }

  openPopupInfo() {
    this.setShowPopupInfo(true);
    disableScroll();
  }

  closePopupInfo() {
    this.setShowPopupInfo(false);
    enableScroll();
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setCountShowMoreServices() {
    this.countShowMoreServices =
      this.countShowMoreServices + LIMIT_SHOW_SERVICES;
  }

  setShowPopupInfo(value: boolean) {
    this.showPopupInfo = value;
  }
}
