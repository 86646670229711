import { FC } from "react";

import styles from "./styles/description.module.scss";

interface IProps {
  title: string;
  moreInfo?: boolean;
  className?: string;
}

export const Description: FC<IProps> = ({
  title = "",
  moreInfo = false,
  className = "",
}) => {
  return (
    <div className={`${styles.info} ${className}`}>
      <div className={styles.info__content}>
        <p className={styles.info__title}>{title}</p>
        {moreInfo && (
          <p className={styles.info__places}>
            Парковочных мест:
            <span className={styles.info__count}> 70</span>
          </p>
        )}
      </div>
    </div>
  );
};
