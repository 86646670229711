import { RefObject } from "react";

const HEIGHT_TOP_BAR = 80;

export function spinElementOnScroll(
  ref: RefObject<HTMLElement>,
  maxDeg: number
) {
  let visibleElement = false;
  let element = ref.current;
  let initScrollYValue = 0;

  function rotateElement(degree: number, elem: HTMLElement | null) {
    if (elem) {
      elem.style.transform = `rotate(-${degree}deg)`;
    }
  }

  function clearRotate(elem: HTMLElement | null) {
    if (elem) {
      elem.style.transform = `rotate(0deg)`;
    }
  }

  function animateElement() {
    initScrollYValue = initScrollYValue || window.scrollY;
    const halfElementHeight = (element?.offsetHeight ?? 1) / 2;
    const currentScrollYVallue = window.scrollY;

    if (currentScrollYVallue > initScrollYValue + halfElementHeight) {
      const rotateHeight =
        document.documentElement.clientHeight -
        halfElementHeight -
        HEIGHT_TOP_BAR;

      let offsetPx =
        currentScrollYVallue - initScrollYValue - halfElementHeight;
      let step = maxDeg / rotateHeight;
      let currentDeg = offsetPx * step;

      rotateElement(Math.min(maxDeg, currentDeg), element);
    }
  }

  // observe visible element

  let observer = new IntersectionObserver(function (
    entries: IntersectionObserverEntry[]
  ) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      visibleElement = entry.isIntersecting;

      if (visibleElement) {
        document.addEventListener("scroll", animateElement);

        return;
      }

      clearRotate(element);
      document.removeEventListener("scroll", animateElement);
    });
  });

  if (element) {
    observer.observe(element);
  }
}
