import { FC } from "react";

import { ReactComponent as StarIcon } from "../../../../assets/images/star.svg";

import styles from "./styles/star.module.scss";

interface IProps {
  starId: number;
  rating: any;
  onClick: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
}

export const Star: FC<IProps> = ({
  starId = 0,
  rating = 0,
  onMouseOver = () => {},
  onMouseLeave = () => {},
  onClick = () => {},
}) => {
  return (
    <div
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <StarIcon
        className={
          rating && rating > starId ? styles.starRating : styles.starFilled
        }
      />
    </div>
  );
};
