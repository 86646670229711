import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../../../components/Button";

import { fullDateFormat } from "../../../../../helpers";

import { ReactComponent as TillLine } from "../../../../../assets/images/tillLine.svg";
import { ReactComponent as DocsIcon } from "../../../../../assets/images/docsIcon.svg";

import styles from "./styles/archiveItem.module.scss";

interface IProps {
  id: number;
  image: string | null;
  rooms?: string;
  square?: number;
  floor?: number;
  startDate?: string;
  endDate?: string;
  openReviewModal: () => void;
  toApartment: () => void;
  onClickDocs: (id: string) => void;
  isReview: boolean;
}

export const ArchiveItem: FC<IProps> = observer(
  ({
    id = 0,
    image = null,
    rooms = "",
    square = 0,
    floor = 1,
    startDate = "",
    endDate = "",
    onClickDocs = () => {},
    openReviewModal = () => {},
    toApartment = () => {},
    isReview = false,
  }) => {
    return (
      <div className={styles.archive}>
        <div className={styles.archive__content}>
          {image ? (
            <div className={styles.archive__photoWrapper} onClick={toApartment}>
              <img className={styles.archive__photo} src={image} alt="flat" />
            </div>
          ) : (
            <div className={styles.archive__photoEmpty} onClick={toApartment}>
              Не удалось загрузить фото
            </div>
          )}
          <div className={styles.archive__info}>
            <p className={styles.archive__title}>
              {rooms} {rooms === "Студия" ? "" : "квартира"},{" "}
              {Math.round(square)} м², {floor} этаж
            </p>
            <div className={styles.daysBlock}>
              <h6 className={styles.daysBlock__days}>
                {fullDateFormat(startDate)}
              </h6>
              <TillLine className={styles.daysBlock__arrow} />
              <h6 className={styles.daysBlock__days}>
                {fullDateFormat(endDate)}
              </h6>
            </div>
            <div className={styles.archive__buttonsWrapper}>
              <button
                className={styles.archive__docsButton}
                onClick={() => onClickDocs(String(id))}
              >
                <DocsIcon />{" "}
                <span className={styles.archive__docsButtonTitle}>
                  Документы
                </span>
              </button>
              <>
                {isReview ? (
                  <div className={styles.archive__reviewBlock}>
                    <span className={styles.archive__leftReview}>
                      СПАСИБО ЗА ОТЗЫВ!
                    </span>
                  </div>
                ) : (
                  <div className={styles.archive__reviewBlock}>
                    <button
                      className={styles.archive__review}
                      onClick={openReviewModal}
                    >
                      ОСТАВИТЬ ОТЗЫВ
                    </button>
                  </div>
                )}
                {isReview ? (
                  <p className={styles.archive__reviewBlockMobile}>
                    СПАСИБО ЗА ОТЗЫВ!
                  </p>
                ) : (
                  <Button
                    className={styles.archive__reviewBtn}
                    theme="gold"
                    title="оставить отзыв"
                    onClick={openReviewModal}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
