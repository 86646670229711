import axios, { AxiosError, AxiosResponse } from "axios";
import { IAxiosInterceptorResponse } from "../api/types";

import { TIMEOUT_AXIOS_ERROR } from "../constants";

export function axiosInterceptorResponseSuccess<T>(
  response: AxiosResponse
): IAxiosInterceptorResponse<T> {
  let result: IAxiosInterceptorResponse<T> = {
    isSuccess: false,
    isError: false,
    isNotAuth: false,
    isTooManyRequests: false,
    isTimeoutEnd: false,
    data: null,
    error: null,
  };

  switch (response.status) {
    case 200:
      result.isSuccess = true;
      result.data = response.data;
      break;
    case 201:
      result.isSuccess = true;
      result.data = response.data;
      break;
    case 204:
      result.isSuccess = true;
      break;
    case 400:
      result.isError = true;
      result.error = response.data;
      break;
    case 404:
      result.isError = true;
      result.error = response.data;
      break;
    case 422:
      result.isError = true;
      result.error = response.data;
      break;
    case 429:
      result.isError = true;
      result.isTooManyRequests = true;
      result.error = response.data;
      break;
    case 401:
      result.isNotAuth = true;
      break;
    default:
      result.isError = true;
      break;
  }

  return result;
}

export function axiosInterceptorResponseError(
  error: AxiosError | Error
): IAxiosInterceptorResponse<null> {
  let _isTimeoutEnd = false;

  if (axios.isAxiosError(error) && error.code) {
    _isTimeoutEnd =
      error.code.toLowerCase() === TIMEOUT_AXIOS_ERROR.toLowerCase();
  }

  return {
    isSuccess: false,
    isError: true,
    isNotAuth: false,
    isTooManyRequests: false,
    isTimeoutEnd: _isTimeoutEnd,
    data: null,
    error: {
      code: "ServerError",
      message: "Ошибка на сервере",
    },
  };
}
