import { observer } from "mobx-react-lite";

import { LogIn } from "./LogIn";
import { SignUp } from "./SignUp";
import { Recovery } from "./Recovery";
import { EStepAuth } from "../../../types/StoreAuthentication";
import { Store } from "../../../stores/types";

import { useMediaQuery } from "../../../hooks/useMediaQuery";

import { ReactComponent as Close } from "../../../assets/images/closeModal.svg";
import { stopPropagation } from "../../../helpers/modalsFunc";

import { ReactComponent as CloseAdaptive } from "../../../assets/images/close.svg";

import styles from "./styles/index.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
}

export const Auth = observer(({ storeAuthentication }: IProps) => {
  let isDesktop = useMediaQuery(574);

  return (
    <>
      {storeAuthentication.isVisible && (
        <div
          className={styles.modal}
          onClick={!isDesktop ? storeAuthentication.closeAuthModal : () => {}}
        >
          <div className={styles.modal__contentWrapper}>
            <div className={styles.modal__content} onClick={stopPropagation}>
              <Close
                className={styles.modal__close}
                onClick={storeAuthentication.closeAuthModal}
              />

              <CloseAdaptive
                className={styles.modal__closeAdaptive}
                onClick={storeAuthentication.closeAuthModal}
              />

              {storeAuthentication.stepAuth === EStepAuth.Authorization && (
                // авторизация
                <LogIn
                  isVisible={storeAuthentication.isVisible}
                  storeUI={storeAuthentication.authorizationStore}
                />
              )}

              {storeAuthentication.stepAuth === EStepAuth.Registration && (
                // регистрация
                <SignUp
                  isVisible={storeAuthentication.isVisible}
                  storeUI={storeAuthentication.registrationStoreUI}
                  checkIsAuth={storeAuthentication.checkIsAuth}
                />
              )}

              {storeAuthentication.stepAuth === EStepAuth.PasswordRecovery && (
                // восстановление
                <Recovery
                  isVisible={storeAuthentication.isVisible}
                  storeUI={storeAuthentication.passwordRecoveryStoreUI}
                  onClose={storeAuthentication.closeAuthModal}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
});
