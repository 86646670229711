import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { Button } from "../../components/Button";
import { Footer } from "../../components/Footer";
import { MainMap } from "../../components/Map";
import { Loader } from "../../components/Loader";

import {
  LONGITUDE_ADDRESS,
  LATITUDE_ADDRESS,
  PAGE_TITLE_CONTACTS,
  DESCRIPTION_PAGE_DEFAULT,
} from "../../constants";
import { SCREENS } from "../../navigation/endpoints";

import { StoreUI } from "./storeUI";

import { RootContext } from "../../stores/Root";

import { EPageType } from "../../types";

import styles from "./styles/contacts.module.scss";

export const Contacts: FC = observer(() => {
  const navigate = useNavigate();
  const rootStore = React.useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore.storeContacts));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  const _toApartments = () => {
    navigate(SCREENS.SCREEN_APARTMENTS);
  };

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.Contacts
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_CONTACTS}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <div className={styles.contacts}>
      {helmet}
      <div className={styles.contacts__wrapper}>
        {storeUI.isLoading ? (
          <div className={styles.contacts__leftEmpty}>
            <Loader />
          </div>
        ) : (
          <div className={styles.contacts__left}>
            <div className={styles.contacts__leftContent}>
              {storeUI.contactsInfo?.map((contact) => (
                <div className={styles.contacts__contactItem} key={contact.id}>
                  <p className={styles.contacts__title}>{contact.title}</p>
                  <p className={styles.contacts__text}>{contact.text}</p>
                </div>
              ))}

              <a
                href="mailto:sales@welcodom.ru"
                className={`${styles.contacts__text} ${styles.contacts__email}`}
              >
                sales@welcodom.ru
              </a>

              <Button
                title="ВЫБРАТЬ КВАРТИРУ"
                theme="white"
                onClick={_toApartments}
                className={styles.contacts__button}
              />
              <div className={styles.contacts__socials}>
                {storeUI.socials?.map((social) => (
                  <a
                    target={"_blank"}
                    href={social.value}
                    key={social.id}
                    className={styles.contacts__socialLink}
                    rel="noreferrer"
                  >
                    {social.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className={styles.contacts__right}>
          <MainMap longitude={LONGITUDE_ADDRESS} latitude={LATITUDE_ADDRESS} />
        </div>
      </div>
      <Footer className={styles.contacts__footer} />
    </div>
  );
});
