import { FC, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import { Button } from "../../components/Button";
import { Partner } from "./Partner";
import { BePartner } from "../../components/Modals/BePartner";
import { Footer } from "../../components/Footer";
import { ModalReuse } from "../../components/Modals/ModalReuse";
import { Loader } from "../../components/Loader";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../stores/Root";

import { EPageType } from "../../types";

import { DESCRIPTION_PAGE_DEFAULT, PAGE_TITLE_PARTNERS } from "../../constants";

import building from "../../assets/images/partnersImg.png";

import styles from "./styles/partners.module.scss";

export const Partners: FC = observer(() => {
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore.storeParter));

  const countDefaultVisibleItems = 4;
  const maxDescriptionLength = 206;

  const countVisibleItems = useMemo(() => {
    return storeUI.partners?.length <= countDefaultVisibleItems
      ? storeUI.partners?.length
      : countDefaultVisibleItems;
  }, [storeUI.partners?.length]);

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  let seoDescriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.Partners
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{seoDescriptionPage?.title ?? PAGE_TITLE_PARTNERS}</title>
        <meta
          name="description"
          content={seoDescriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [seoDescriptionPage]
  );

  let partnersList = useMemo(() => {
    return storeUI.visibleAllPartners
      ? storeUI.partners
      : storeUI.partners?.slice(0, countVisibleItems);
  }, [countVisibleItems, storeUI.partners, storeUI.visibleAllPartners]);

  return (
    <div className={styles.partners}>
      {helmet}
      <div className={styles.partners__wrapper}>
        <div className={styles.partners__leftColumn}>
          <img
            className={styles.partners__building}
            src={building}
            alt="building"
          />
        </div>
        <div className={styles.partners__rightColumn}>
          <div className={styles.partners__info}>
            <h1 className={styles.partners__title}>Партнеры</h1>
            <p className={styles.partners__text}>
              {parse(storeUI.partnersDescription, {
                trim: true,
              })}
            </p>
            <Button
              className={styles.partners__button}
              title="СТАТЬ ПАРТНЕРОМ"
              theme="gold"
              onClick={storeUI.openPartnerModal}
            />
          </div>
          {storeUI.isLoading ? (
            <div className={styles.partners__loadingBlock}>
              <Loader className={styles.partners__loader} />
            </div>
          ) : (
            <div className={styles.partners__partners}>
              {partnersList?.map((item) => (
                <Partner
                  key={item.id}
                  title={item.title}
                  description={item.description}
                  url={item.url}
                  maxDescriptionLength={maxDescriptionLength}
                  subtitle={item.subTitle}
                />
              ))}

              {storeUI.partners.length > countDefaultVisibleItems && (
                <button
                  className={styles.partners__moreButton}
                  onClick={storeUI.openAllPartners}
                >
                  {storeUI.visibleAllPartners ? "Свернуть" : "Показать всё"}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer className={styles.partners__footer} />
      <BePartner
        modal={storeUI.isModalPartner}
        isLoading={storeUI.isLoading}
        closeModal={storeUI.closePartnerModal}
        name={storeUI.name}
        organization={storeUI.organization}
        phone={storeUI.phone}
        text={storeUI.text}
        changeName={storeUI.onChangeName}
        changeOrganization={storeUI.onChangeOrganization}
        changePhone={storeUI.onChangePhone}
        changeText={storeUI.onChangeText}
        sendPartnerRequest={storeUI.sendPartnerRequest}
        errorsName={storeUI.errors.name}
        errorsOrganization={storeUI.errors.organization}
        errorsPhone={storeUI.errors.phone}
        errorsText={storeUI.errors.text}
      />

      <ModalReuse
        isShowModal={storeUI.isModalSave}
        setIsShowModal={storeUI.closeSaveModal}
        btnTitle="Хорошо"
        type="button"
      >
        <h2>Ваша заявка отправлена. Мы дадим ответ в ближайшее время</h2>
      </ModalReuse>
    </div>
  );
});
