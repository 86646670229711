import { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { Input } from "../Input";

import { ReactComponent as Eye } from "../../assets/images/eye.svg";
import { ReactComponent as EyeOff } from "../../assets/images/eyeOff.svg";

import styles from "./styles/input.module.scss";

interface IProps {
  placeholder?: string;
  label: string;
  value: string;
  errors: string[];
  onChange(value: string): void;
  onBlur?: (e: any) => void;
  className?: string;
  classNameInput?: string;
  infoIconText?: string;
  pattern?: string;
  maxLength?: number;
  withInfoIcon?: boolean;
}

export const InputPassword: FC<IProps> = observer(
  ({
    placeholder = "",
    label = "",
    value = "",
    className = "",
    classNameInput = "",
    infoIconText = "",
    errors = [],
    pattern = "",
    maxLength = 524288,
    withInfoIcon = true,
    onChange = () => {},
    onBlur = () => {},
  }) => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
      setVisible((prev) => !prev);
    };

    return (
      <div className={`${styles.inputPassword} ${className}`}>
        <Input
          label={label}
          placeholder={placeholder}
          value={value}
          className={classNameInput}
          type={visible ? "text" : "password"}
          infoIconText={infoIconText}
          withInfoIcon={withInfoIcon}
          errors={errors}
          pattern={pattern}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
        />

        {value && (
          <button
            className={styles.inputPassword__eyeBtn}
            onClick={toggleVisible}
          >
            {visible ? <EyeOff /> : <Eye />}
          </button>
        )}
      </div>
    );
  }
);
