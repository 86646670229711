import { FINANCE } from "../../constants";

import { ApiManager } from "../index";

import { TResponseApi } from "../types";

import { IFinance } from "../../interfaces/Finance";

export async function getFinance(): Promise<TResponseApi<IFinance>> {
  return await ApiManager.request<IFinance>(
    {
      url: FINANCE,
      method: "GET",
    },
    true
  );
}
