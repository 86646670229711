import { makeAutoObservable } from "mobx";

import {
  changeAvatar,
  deleteProfile,
  getHintByString,
  getPublicProfile,
  updateProfile,
} from "../../../api/User";
import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

import { IUserProfile } from "../../../interfaces/User";
import { ICommonResponse } from "../../../interfaces";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

export class User implements Store.IUser {
  profile: IUserProfile = {
    id: 0,
    personalType: "",
    image: "",
    email: "",
    lastName: "",
    firstName: "",
    middleName: "",
    birthdate: "",
    placeBirth: "",
    phone: "",
    gender: null,
    passportNumber: "",
    passportSeries: "",
    passportSubdivisionCode: "",
    passportIssued: "",
    passportDateIssue: "",
    passportAddressRegister: "",
  };
  isSuccessUpdatedProfile: boolean = false;
  serverErrors: string[] = [];
  chosenAvatar: File | null = null;

  hintStrings: string[] = [];

  isNotAuth: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getPublicProfile() {
    let response: TResponseApi<IUserProfile> = yield getPublicProfile();
    if (response.isSuccess && response.data !== null) {
      this.setProfile(response.data);
    }

    this.setIsNotAuth(response.isNotAuth);
  }

  *updateProfile(params: IUserProfile) {
    let response: TResponseApi<ICommonResponse> = yield updateProfile(params);
    if (response.isSuccess && response.data !== null) {
      this.setIsSuccessUpdatedProfile(response.data.result === "success");
    } else {
      this.setIsSuccessUpdatedProfile(false);
    }

    if (response.isError) {
      if (response.error?.message) {
        this.addServerErrors(response.error.message);
      } else {
        this.addServerErrors(ERROR_TEXT_SERVER_COMMON);
      }
    } else {
      this.serverErrors = [];
    }
  }

  *changeUserAvatar() {
    let response: TResponseApi<IUserProfile> = yield changeAvatar(
      this.chosenAvatar!
    );
    if (response.isError) {
      if (response.error?.message) {
        this.addServerErrors(response.error.message);
      } else {
        this.addServerErrors(ERROR_TEXT_SERVER_COMMON);
      }
    } else {
      this.serverErrors = [];
    }
  }

  *deleteProfile() {
    let response: TResponseApi<ICommonResponse> = yield deleteProfile();
    if (response.isSuccess && response.data !== null) {
      return response.data?.result === "success";
    }
  }

  *getHintByString(query: string) {
    let response: TResponseApi<string[]> = yield getHintByString({ query });

    if (response.isSuccess && response.data !== null) {
      this.setHintStrings(response.data);
    }
  }

  clearPublicProfile() {
    this.profile = {
      id: 0,
      personalType: "",
      image: "",
      email: "",
      lastName: "",
      firstName: "",
      middleName: "",
      birthdate: "",
      placeBirth: "",
      phone: "",
      gender: null,
      passportNumber: "",
      passportSeries: "",
      passportSubdivisionCode: "",
      passportIssued: "",
      passportDateIssue: "",
      passportAddressRegister: "",
    };
  }

  addServerErrors(value: string) {
    this.serverErrors = [...this.serverErrors, value];
  }

  setProfile(profile: IUserProfile) {
    this.profile = profile;
  }

  setIsSuccessUpdatedProfile(value: boolean) {
    this.isSuccessUpdatedProfile = value;
  }

  setIsNotAuth(value: boolean) {
    this.isNotAuth = value;
  }

  setHintStrings(value: string[]) {
    this.hintStrings = value;
  }
}

export const StoreUser = new User();
