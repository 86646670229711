import { FC } from "react";

import { ProlongBtn } from "../ProlongBtn";

import {
  changeSpaceInNumber,
  declensionWordDays,
  declensionWordPersons,
} from "../../../../../helpers";

import { ONE_ROOMS_NAME } from "../../../../../constants";

import styles from "./styles/rentItem.module.scss";

interface IProps {
  id: number;
  rooms: string;
  flatNumber: number;
  square: number;
  floor: number;
  tenants: number;
  datePeriod: string;
  dateEnding: number;
  deposit: number;
  startDate: string;
  dateEnd: string;
  isVisibleEndBooking: boolean;
  haveCheckInDate: boolean;
  checkinDate: string | null;
  openExtension(apartmentId: number, dateEnd: string): void;
  onClickSelectArrivalDate(): void;
  toTenants(): void;
}

export const RentItem: FC<IProps> = ({
  id = 0,
  rooms = "",
  flatNumber = 0,
  square = 0,
  floor = 1,
  tenants = 1,
  dateEnd = "",
  startDate = "",
  datePeriod = "",
  dateEnding = 0,
  deposit = 0,
  isVisibleEndBooking = false,
  haveCheckInDate = false,
  checkinDate = false,
  openExtension = () => {},
  onClickSelectArrivalDate = () => {},
  toTenants = () => {},
}) => {
  return (
    <div className={styles.rentItem}>
      <div className={styles.rent}>
        <div className={styles.rent__top}>
          <h5 className={styles.rent__title}>
            {rooms}
            {rooms.toLowerCase() !== ONE_ROOMS_NAME.toLowerCase() &&
              ` квартира`}
            , {square} м²
          </h5>
          <div className={styles.rent__floor}>
            <h5 className={`${styles.rent__title} ${styles.rent__titleFloor}`}>
              №{flatNumber}
            </h5>
            <h5 className={`${styles.rent__title} ${styles.rent__titleFloor}`}>
              Этаж: {floor}
            </h5>
          </div>
        </div>
        <div className={styles.rent__bottom}>
          <div className={styles.rent__info}>
            <div className={styles.rent__period}>
              <h6 className={styles.rent__subTitle}>Период</h6>
              <p>
                {startDate} — {dateEnd}
              </p>
            </div>
            <div className={styles.rent__term}>
              <h6 className={styles.rent__subTitle}>Срок</h6>
              <p>{datePeriod}</p>
            </div>
            {isVisibleEndBooking && (
              <div className={styles.rent__endBooking}>
                <h6 className={styles.rent__subTitle}>Закончится</h6>
                <p>
                  Через {dateEnding} {declensionWordDays(dateEnding)}
                </p>
              </div>
            )}
            <div className={styles.rent__tenants}>
              <h6 className={styles.rent__subTitle}>Жильцы</h6>
              <p>
                {tenants} {declensionWordPersons(tenants)}
              </p>
            </div>
            <div className={styles.rent__tenants}>
              <h6 className={styles.rent__subTitle}>Депозит</h6>
              <p>{changeSpaceInNumber(deposit)} ₽</p>
            </div>
          </div>
          <div className={styles.rent__buttons}>
            {haveCheckInDate ? (
              <ProlongBtn
                onClick={() => openExtension(id, dateEnd)}
                className={styles.rent__button}
              />
            ) : (
              <>
                <button
                  className={styles.rent__checkInDateButton}
                  onClick={onClickSelectArrivalDate}
                >
                  Выберите дату заселения
                </button>
                {checkinDate && (
                  <span className={styles.rent__checkinDate}>
                    Ближайшая дата заселения {checkinDate}
                  </span>
                )}
              </>
            )}

            {/*  {!hideAddPerson && (
              <AddPersonBtn
                title={"Добавить жильца"}
                onClick={toTenants}
                className={styles.rent__button}
              />
            )} */}
          </div>
        </div>
      </div>

      {/*   <div className={styles.additional}>
        <div className={styles.additional__parking}>
          <div className={styles.additional__top}>
            <h5 className={styles.additional__title}>Парковочное место, №5</h5>
            <h6 className={styles.additional__floor}>Этаж: -2</h6>
          </div>
          <div className={styles.additional__bottom}>
            <div className={styles.additional__line}>
              <p className={styles.additional__subTitle}>Период</p>
              <p className={styles.additional__lineValue}>
                01.01.2022 — 01.06.2022
              </p>
            </div>
            <div className={styles.additional__line}>
              <p className={styles.additional__subTitle}>Срок</p>
              <p>6 мес (~180 дней)</p>
            </div>
            <div className={styles.additional__line}>
              <p className={styles.additional__subTitle}>Закончится</p>
              <p>Через 265 дней</p>
            </div>
          </div>
        </div>
        <div className={styles.additional__payments}>
          <div className={styles.additional__top}>
            <h5 className={styles.additional__title}>Платежи</h5>
          </div>
          <div className={styles.additional__bottom}>
            <div
              className={`${styles.additional__line} ${styles.additional__line_payments}`}
            >
              <p className={styles.additional__subTitle}>Ежемесячный</p>
              <p className={styles.additional__price}>40 000 ₽</p>
            </div>
            <div
              className={`${styles.additional__line} ${styles.additional__line_payments}`}
            >
              <p className={styles.additional__subTitle}>Внесено</p>
              <p className={styles.additional__price}>15 000 ₽</p>
            </div>
            <div
              className={`${styles.additional__line} ${styles.additional__line_payments}`}
            >
              <p className={styles.additional__subTitle}>Задолженность</p>
              <p className={styles.additional__price}>25 000 ₽</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
