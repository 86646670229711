import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { SCREENS } from "../../../navigation/endpoints";
import { disableScroll, enableScroll } from "./../../../helpers/modalsFunc";

import { IStoreUI } from "./interfaces";

import { Store } from "../../../stores/types";
import { EContact } from "../../../types";

import defaultAvatar from "../../../assets/images/profileDefault.svg";

export class StoreUI implements IStoreUI {
  storeAuthentication: Store.IStoreAuthentication;
  storeRoot: Store.IRootStore;
  storeUser: Store.IUser;
  isOpenMenu: boolean = false;
  isMobileVersion: boolean = false;
  isOpenMobileMenu: boolean = false;
  isOpenLogout: boolean = false;
  navigate: NavigateFunction;
  chosenAvatar: File | null = null;
  image: string = "";
  isLoading: boolean = false;

  constructor(
    storeAuthentication: Store.IStoreAuthentication,
    storeRoot: Store.IRootStore,
    navigate: NavigateFunction
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeAuthentication = storeAuthentication;
    this.storeRoot = storeRoot;
    this.storeUser = storeRoot.userStore;
    this.navigate = navigate;
  }

  // getters

  get avatarImg() {
    return this.storeUser.profile.image || defaultAvatar;
  }

  get phoneNumber() {
    return this.storeRoot.storeContacts.contacts?.length
      ? this.storeRoot.storeContacts.contacts.find(
          (item) => item.name === EContact.Phone
        )?.value || ""
      : "";
  }

  get personalType() {
    return this.storeUser.profile.personalType;
  }

  get isAuth() {
    return this.storeAuthentication.isAuth;
  }

  // functions
  *init() {
    if (
      !this.storeRoot.storeContacts.contacts?.find(
        (item) => item.name === EContact.Phone
      )
    ) {
      yield this.storeRoot.storeContacts.getContacts();
    }
  }

  onClickAvatar() {
    if (this.storeAuthentication.isAuth) {
      this.toAccount();
    } else {
      this.storeAuthentication.openAuthModal();
      this.closeMobileMenu();
    }
  }

  *logout() {
    this.setIsLoading(true);
    yield this.storeAuthentication.logout(this.navigate);
    this.setIsLoading(false);
    this.closeMobileMenu();
    this.closeLogout();
  }

  toAccount() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS);
  }

  toBasket() {
    this.navigate(SCREENS.SCREEN_BASKET);
    this.closeMenu();
  }

  toMain() {
    this.navigate(SCREENS.SCREEN_MAIN);
    this.closeMenu();
  }

  openMenu() {
    this.isOpenMenu = true;
    disableScroll();
  }

  closeMenu() {
    this.isOpenMenu = false;
    enableScroll();
  }

  openMobileVersion() {
    this.isMobileVersion = true;
    this.closeMenu();
    disableScroll();
  }

  closeMobileVersion() {
    this.isMobileVersion = false;
    enableScroll();
  }

  openMobileMenu() {
    this.isOpenMobileMenu = true;
  }

  closeMobileMenu() {
    this.isOpenMobileMenu = false;
  }

  openLogout() {
    this.setIsOpenLogout(true);
    disableScroll();
  }

  closeLogout() {
    this.setIsOpenLogout(false);
    enableScroll();
  }

  // setters

  setIsOpenLogout(value: boolean) {
    this.isOpenLogout = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
