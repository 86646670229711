import { FC } from "react";
import { observer } from "mobx-react-lite";

import { stopPropagation } from "../../../../helpers/modalsFunc";
import { useMediaQuery } from "./../../../../hooks/useMediaQuery";

import { IPasswordRecoveryUI } from "../../../../stores/ui/Authetication/interfaces";

import { EStepsRecovery } from "../../../../types/PasswordRecovery";

import { EnterCode } from "../components/EnterCode/index";
import { EnterPasswords } from "../components/EnterPasswords/index";
import { EnterPhone } from "./components/EnterPhone/index";

import styles from "./styles/recovery.module.scss";

interface IProps {
  storeUI: IPasswordRecoveryUI;
  isVisible: boolean;
  onClose(): void;
}

export const Recovery: FC<IProps> = observer(
  ({ storeUI, isVisible = false, onClose = () => {} }) => {
    let isDesktop = useMediaQuery(574);

    const _onClick = () => {
      if (!isDesktop) {
        onClose();
      }
    };

    return (
      <>
        {isVisible && (
          <div className={styles.modal} onClick={_onClick}>
            <div className={styles.modal__content} onClick={stopPropagation}>
              <h2 className={styles.modal__contentTitle}>
                Восстановление пароля
              </h2>

              {storeUI.step === EStepsRecovery.EnterPhone && (
                <EnterPhone storeUI={storeUI} />
              )}

              {storeUI.step === EStepsRecovery.EnterCode && (
                <EnterCode storeUI={storeUI} />
              )}

              {storeUI.step === EStepsRecovery.EnterPasswords && (
                <EnterPasswords storeUI={storeUI} isRegister={false} />
              )}
            </div>
          </div>
        )}
      </>
    );
  }
);
