import { observer } from "mobx-react-lite";

import styles from "./styles/index.module.scss";

interface IProps {
  isActive: boolean;
  isAuth: boolean;
  addFavorite(): void;
  deleteFavorite(): void;
  openAuthModal(): void;
}

export const FavoriteIcon = observer(
  ({
    isActive = false,
    isAuth = false,
    addFavorite = () => {},
    deleteFavorite = () => {},
    openAuthModal = () => {},
  }: IProps) => {
    const onClick = () => {
      if (isAuth) {
        return isActive ? deleteFavorite() : addFavorite();
      } else {
        return openAuthModal();
      }
    };

    return (
      <button onClick={onClick}>
        <svg
          className={`${styles.favoriteIcon} ${
            isActive && styles.favoriteIcon_active
          }`}
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.04609 13.6268L6.13494 13.1086L5.75853 12.7417L1.26999 8.36591L7.47297 7.46453L7.99324 7.38893L8.22591 6.91748L11 1.2963L13.7739 6.91745L14.0066 7.38893L14.5269 7.46453L20.7299 8.36589L16.2413 12.7417L15.8649 13.1087L15.9537 13.6268L17.0134 19.8051L11.4655 16.8881L11.0001 16.6435L10.5348 16.8881L4.98659 19.8051L6.04609 13.6268ZM17.4355 20.027L17.4351 20.0268L17.4355 20.027Z"
            strokeWidth="2"
          />
        </svg>
      </button>
    );
  }
);
