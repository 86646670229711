import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { enableScroll } from "../../../helpers/modalsFunc";

import { SCREENS } from "../../../navigation/endpoints";

import { StoreTextModel } from "../../../stores/models/TextPage";

import { Store } from "../../../stores/types";

export class StoreUI {
  storeTextPage: Store.ITextPage;
  navigation: NavigateFunction;
  isLoading: boolean = false;

  constructor(navigation: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeTextPage = new StoreTextModel();
    this.navigation = navigation;
  }

  // getters

  get title() {
    return this.storeTextPage.title;
  }

  get content() {
    return this.storeTextPage.contentText;
  }

  //functions

  *init(pathname: string) {
    enableScroll();
    let pathArr = pathname.split("/");
    let lastPath = pathArr[pathArr.length - 1];

    this.setIsLoading(true);
    yield this.storeTextPage.getTextPage(lastPath);

    if (!this.storeTextPage.title && !this.storeTextPage.contentText) {
      this.navigation(SCREENS.SCREEN_404);
    }
    this.setIsLoading(false);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
