import { NavigateFunction } from "react-router-dom";
import { makeAutoObservable } from "mobx";
import Swiper from "swiper";

import {
  floorsList,
  INITIAL_SLIDE_DEFAULT_SWIPER,
  NUMBER_OF_FLOORS,
} from "../../../constants";

import { SCREENS } from "./../../../navigation/endpoints";

import { changeLastUrlPath } from "../../../helpers/changeLastUrlPath";
import { enableScroll } from "../../../helpers/modalsFunc";
import { fullNumbersFormat } from "../../../helpers";

import { IOptionFloor, IFloorPlan } from "../../../interfaces/Floor";
import { IStoreUI } from "./interfaces";
import { Store } from "../../../stores/types";
import { EFloorPlan } from "../../../types";

export class StoreUI implements IStoreUI {
  navigate: NavigateFunction;
  storeFloor: Store.IFloor;

  // выбранный этаж
  currentFloor: number = 1;
  currentFloorForSelect: IOptionFloor | null = null;
  // тип этажа
  typeFloorPlan: EFloorPlan | null = null;
  // список планов этажей с типами
  floorsPlansList: IFloorPlan[] = floorsList;

  isLoading: boolean = false;
  isLoadingSlider: boolean = false;
  isVisibleCalendar: boolean = false;
  isLastFloor: boolean = false;

  swiper: Swiper | null = null;

  constructor(storeFloor: Store.IFloor, navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeFloor = storeFloor;
    this.navigate = navigate;
  }

  // getters

  get floorsListForOptions() {
    return this.floorsPlansList.map((item) => {
      return {
        id: item.numberFloor,
        label: item.label,
        value: item.label,
      };
    });
  }

  get floorApartments() {
    return this.storeFloor.floorPlan.slice().sort((a, b) => {
      return a.typeApartment - b.typeApartment;
    });
  }

  get blockedApartments() {
    return this.storeFloor.blockedApartments.map((item) => ({
      id: item.id,
      endDate: item.endDate ? fullNumbersFormat(item.endDate) : item.endDate,
    }));
  }

  get typeFloorBySelect() {
    let _floorNum = this.currentFloorForSelect?.id;

    let _selectedPlan = this.floorsPlansList.find(
      (item) => item.numberFloor === _floorNum
    );
    return _selectedPlan?.typeFloor;
  }

  get errorPlan() {
    return this.storeFloor.errorFloorPlan;
  }

  get errorBlockedApartment() {
    return this.storeFloor.errorBlockedApartments;
  }

  // functions
  *init(numberFloor: number) {
    enableScroll();
    this.setIsLoading(true);
    if (numberFloor > NUMBER_OF_FLOORS || numberFloor <= 0) {
      this.navigate(SCREENS.SCREEN_404);
    } else {
      // инициализируем номера этажей десктоп/адаптив
      this.setCurrentFloor(numberFloor);
      this.changeCurrentFloorAdaptive(this.currentFloor);

      // получаем индекс текущего слайда который должен отображаться
      let numberFloorToIndex = numberFloor - 1;

      /* если он равен инит-слайду свайпера, то в свайпер не вызовет onSlideChange, на котором
    завязаны запросы на получение id квартир, поэтому нужно вызвать вручную */
      if (numberFloorToIndex === INITIAL_SLIDE_DEFAULT_SWIPER) {
        // запрашиваем квартиры текущего этажа (чтобы распределить id)
        yield this.storeFloor.getFloorPlan(numberFloor);
      }
    }
    this.setIsLoading(false);
  }

  *getBlockedApartmentsForPaginate(floorNumber: number) {
    yield this.storeFloor.getBlockedApartments({
      floor: floorNumber,
    });
  }

  *refreshData(numberFloor: number) {
    // запрос данных при переключении этажа
    yield this.storeFloor.getFloorPlan(numberFloor);
    yield this.getBlockedApartmentsForPaginate(numberFloor);
  }

  private *changeFloor(floorNumber: number) {
    changeLastUrlPath(String(floorNumber));
    this.setIsLoadingSlider(true);
    yield this.refreshData(floorNumber);
    // менять этаж для десктопа
    this.setCurrentFloor(floorNumber);
    // менять этаж для адаптива
    this.changeCurrentFloorAdaptive(floorNumber);
    this.setIsLoadingSlider(false);
  }

  private changeCurrentFloorAdaptive(floorNumber: number) {
    this.setCurrentFloorForSelect({
      id: floorNumber,
      value: `${floorNumber} этаж`,
      label: `${floorNumber} этаж`,
    });
  }

  *incrementFloor() {
    if (this.currentFloor <= NUMBER_OF_FLOORS && !this.isLastFloor) {
      yield this.changeFloor(this.currentFloor);
      if (this.currentFloor === NUMBER_OF_FLOORS) {
        this.setIsLastFloor(true);
      }
    }
  }

  *decrementFloor() {
    if (this.currentFloor > 1) {
      yield this.changeFloor(this.currentFloor);
      this.setIsLastFloor(false);
    }
  }

  *onSlideChange(swiper: Swiper) {
    let _numFloor = swiper.realIndex + 1;
    yield this.changeFloor(_numFloor);
  }

  *changeCurrentFloorForSelect(value: IOptionFloor | null) {
    this.setIsLoading(true);
    // смена этажа для адаптива
    this.setCurrentFloorForSelect(value);
    // смена этажа для десктопа
    if (value && value.id) {
      this.setCurrentFloor(value.id);
    }

    if (this.currentFloorForSelect) {
      yield this.getBlockedApartmentsForPaginate(this.currentFloorForSelect.id);
      changeLastUrlPath(String(this.currentFloorForSelect.id));

      if (this.swiper) {
        this.swiper.slideTo(this.currentFloorForSelect.id - 1);
      }
    }
    this.setIsLoading(false);
  }

  toApartmentPage(id: number) {
    this.navigate(`${SCREENS.SCREEN_APARTMENTS}/${id}`);
  }

  // setters
  setCurrentFloor(value: number) {
    this.currentFloor = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingSlider(value: boolean) {
    this.isLoadingSlider = value;
  }

  setIsVisibleCalendar(value: boolean) {
    this.isVisibleCalendar = value;
  }

  setTypeFloorPlan(value: EFloorPlan | null) {
    this.typeFloorPlan = value;
  }

  setCurrentFloorForSelect(value: IOptionFloor | null) {
    this.currentFloorForSelect = value;
  }

  setSwiper(swiper: Swiper) {
    this.swiper = swiper;
  }

  setIsLastFloor(value: boolean) {
    this.isLastFloor = value;
  }
}
