import { ApiManager } from "../index";

import { TResponseApi } from "../types";

import { QUESTION } from "../../constants/api";

import { ICommonResponse } from "./../../interfaces/index";
import { IQuestionRequest } from "../../interfaces/QuestionModal";

export async function sendQuestion(
  params: IQuestionRequest
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: QUESTION,
      data: params,
      method: "POST",
    },
    true
  );
}
