import { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { FlatBtn } from "../FlatBtn";

import styles from "./styles/index.module.scss";

//TODO: перенести в 4м спринте
interface IPoint {
  id: number;
  src: string;
}

interface IProps {
  pointsList: IPoint[];
  planImage: string;
  openInnerPhoto(idPoint: number): void;
}

export const PlanWithPoints: FC<IProps> = observer(
  ({ pointsList = [], planImage = "", openInnerPhoto = () => {} }) => {
    let [isErrorImg, setIsErrorImg] = useState(false);

    return (
      <div className={styles.planWithPoints}>
        {isErrorImg ? (
          <div className={styles.planWithPoints__emptyImg}>
            Не удалось загрузить изображение
          </div>
        ) : (
          <>
            {/* Пока скрыть кнопки Плюс, вернуть когда будет необходимо заказчику */}
            {/* <div className={styles.planWithPoints__grid}>
              {Array.from(Array(100)).map((_, index) => (
                <span key={index} className={styles.planWithPoints__cell}>
                  {pointsList.map((item) => {
                    if (index === item.id) {
                      return (
                        <FlatBtn
                          key={item.id}
                          onClick={() => openInnerPhoto(item.id)}
                          className={styles.planWithPoints__pointBtn}
                        />
                      );
                    } else {
                      return "";
                    }
                  })}
                </span>
              ))}
            </div> */}
            <img
              src={planImage}
              alt="apartment"
              className={styles.planWithPoints__planImg}
              onLoad={() => setIsErrorImg(false)}
              onError={() => setIsErrorImg(true)}
            />
          </>
        )}
      </div>
    );
  }
);
