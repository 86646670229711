import { makeAutoObservable } from "mobx";

import { ApiManager } from "../../../api";
import { authorization } from "../../../api/Authorization";
import {
  phoneVerifySend,
  phoneVerifyCheck,
  registration,
  getNeedFill,
} from "../../../api/Registration";

import {
  ERROR_TEXT_SERVER_COMMON,
  TOO_MANY_REQUEST,
  TOO_MANY_REQUEST_RU,
} from "../../../constants";

import { IAuthorizationResponse } from "../../../interfaces/Authorization";
import {
  INeedFillParams,
  IRegistrationParams,
  IServerErrorsRegister,
} from "../../../interfaces/Registration";
import { ICommonResponse, IResult } from "../../../interfaces";

import { EMethodSend } from "../../../types";
import { Store } from "../../types";
import { TResponseApi } from "../../../api/types";

export class ModelRegistration implements Store.IRegistration {
  serverErrors: IServerErrorsRegister = {
    phone: [],
    password: [],
    confirmPassword: [],
    code: [],
  };

  needFill: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *sendCode(phone: string, methodSend: EMethodSend) {
    let params = { phone: phone.replaceAll(" ", ""), methodSend: methodSend };

    let response: TResponseApi<ICommonResponse> = yield phoneVerifySend(params);

    if (response.isError) {
      if (response.error?.message) {
        let _error =
          response.error.message.toLowerCase() === TOO_MANY_REQUEST
            ? TOO_MANY_REQUEST_RU
            : response.error?.message;
        this.addErrorPhone(_error);
      }

      if (!response.error?.message && response.error?.data?.message) {
        this.addErrorPhone(response.error?.data?.message);
      }

      this.addErrorPhone(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.serverErrors.phone = [];
    }

    return response.isSuccess;
  }

  *checkVerificationCode(phone: string, code: string) {
    let params = { phone: phone.replaceAll(" ", ""), code: code };

    let response: TResponseApi<ICommonResponse> = yield phoneVerifyCheck(
      params
    );

    if (response.isError) {
      if (response.error?.message) {
        if (response.error.message.toLowerCase() === TOO_MANY_REQUEST) {
          this.addErrorCode(TOO_MANY_REQUEST_RU);
        } else {
          this.addErrorCode(response.error?.message);
        }
      }

      if (!response.error?.message && response.error?.data?.message) {
        this.addErrorCode(response.error?.data?.message);
      }

      this.addErrorCode(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.serverErrors.code = [];
    }

    return response.isSuccess;
  }

  *sendRegistration(params: IRegistrationParams) {
    let response: TResponseApi<ICommonResponse> = yield registration(params);

    if (response.isError) {
      if (response.error?.message) {
        this.serverErrors.password = [];
        this.serverErrors.confirmPassword = [];
        let _error =
          response.error.message.toLowerCase() === TOO_MANY_REQUEST
            ? TOO_MANY_REQUEST_RU
            : response.error?.message;

        this.addErrorPassword(_error);
        this.addErrorConfirmPassword(_error);
      }

      if (!response.error?.message && response.error?.data?.message) {
        this.serverErrors.password = [];
        this.serverErrors.confirmPassword = [];
        this.addErrorPassword(response.error?.data?.message);
        this.addErrorConfirmPassword(response.error?.data?.message);
      }

      this.addErrorPassword(ERROR_TEXT_SERVER_COMMON);
      this.addErrorConfirmPassword(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.serverErrors.password = [];
      this.serverErrors.confirmPassword = [];
    }

    return response.isSuccess;
  }

  *getNeedFill(params: INeedFillParams) {
    let response: TResponseApi<IResult> = yield getNeedFill(params);
    if (response.isSuccess && response.data !== null) {
      this.setNeedFill(response.data.result);
    }

    if (response.isError) {
      this.addErrorPhone(
        response.error?.message
          ? response.error?.message
          : ERROR_TEXT_SERVER_COMMON
      );
    } else {
      this.clearServerErrors();
    }
  }

  *authorization(phone: string, password: string) {
    let params = { phone: phone.replaceAll(" ", ""), password: password };

    let response: TResponseApi<IAuthorizationResponse> = yield authorization(
      params
    );

    if (
      response.isSuccess &&
      response.data !== null &&
      response.data.accessToken
    ) {
      this.saveDataToLocalStorage(response);
      return true;
    }

    if (response.isError) {
      this.addErrorPassword(ERROR_TEXT_SERVER_COMMON);
      this.addErrorConfirmPassword(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.serverErrors.password = [];
      this.serverErrors.confirmPassword = [];
    }

    return response.isSuccess;
  }

  clearServerErrors() {
    this.serverErrors.phone = [];
    this.serverErrors.password = [];
    this.serverErrors.confirmPassword = [];
    this.serverErrors.code = [];
  }

  addErrorPhone(value: string) {
    this.serverErrors.phone = [...this.serverErrors.phone, value];
  }

  addErrorPassword(value: string) {
    this.serverErrors.password = [...this.serverErrors.password, value];
  }

  addErrorConfirmPassword(value: string) {
    this.serverErrors.confirmPassword = [
      ...this.serverErrors.confirmPassword,
      value,
    ];
  }

  addErrorCode(value: string) {
    this.serverErrors.code = [...this.serverErrors.code, value];
  }

  saveDataToLocalStorage(response: TResponseApi<IAuthorizationResponse>) {
    if (response.data !== null) {
      ApiManager.writeToken({
        accessToken: response.data.accessToken,
        tokenType: response.data.tokenType,
      });
    }
  }

  setNeedFill(value: boolean) {
    this.needFill = value;
  }
}
