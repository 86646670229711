import { ApiManager } from "..";
import {
  CATALOG_APARTMENT,
  CATALOG_FILTERS_FACILITIES,
  CATALOG_FILTERS_ROOMS,
  CATALOG_FILTERS_TERM,
} from "../../constants/api";

import {
  IApartamentsParams,
  IApartment,
  IFacilities,
  IRooms,
  ITerms,
} from "../../interfaces/Catalog";

import { TResponseApi } from "../types";

export async function getApartments(
  params?: IApartamentsParams
): Promise<TResponseApi<IApartment>> {
  return await ApiManager.request<IApartment>({
    url: CATALOG_APARTMENT,
    method: "GET",
    params: params,
  });
}

export async function getFacilities(): Promise<TResponseApi<IFacilities>> {
  return await ApiManager.request<IFacilities>({
    url: CATALOG_FILTERS_FACILITIES,
    method: "GET",
  });
}

export async function getTerms(): Promise<TResponseApi<ITerms>> {
  return await ApiManager.request<ITerms>({
    url: CATALOG_FILTERS_TERM,
    method: "GET",
  });
}

export async function getRooms(): Promise<TResponseApi<IRooms>> {
  return await ApiManager.request<IRooms>({
    url: CATALOG_FILTERS_ROOMS,
    method: "GET",
  });
}
