import { NavigateFunction } from "react-router-dom";
import { makeAutoObservable } from "mobx";

import {
  EBookingType,
  EStatusBill,
  ETabsDocuments,
  ETabsMyBookings,
  ETabsResidents,
  ETabsServices,
} from "./../../../../../types/index";
import { Store } from "../../../../../stores/types";

import { ITab } from "../../../../../interfaces";
import { IStoreUI } from "./interfaces";

import { SERVICES } from "./../../../../../constants/index";
import {
  DOCUMENTS,
  PAGE_MY_BOOKING_BY_TAB,
  RESIDENTS,
} from "../../../../../constants";

import { SCREENS } from "./../../../../../navigation/endpoints";

export class StoreUI implements IStoreUI {
  navigate: NavigateFunction;
  storeRoot: Store.IRootStore;
  storeMyApartment: Store.IMyApartment;
  storeBills: Store.IBillsStore;

  isLoading: boolean = false;

  constructor(navigate: NavigateFunction, storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.navigate = navigate;
    this.storeRoot = storeRoot;
    this.storeMyApartment = storeRoot.storeMyApartment;
    this.storeBills = storeRoot.storeBills;
  }

  get tabs(): ITab[] {
    return [
      {
        id: 1,
        label: "Моя квартира",
        tabName: ETabsMyBookings.Rent,
      },
      {
        id: 2,
        label: "Документы",
        tabName: ETabsMyBookings.Documents,
      },
      {
        id: 3,
        label: "Жильцы",
        tabName: ETabsMyBookings.Residents,
      },
      {
        id: 4,
        label: "Услуги",
        tabName: ETabsMyBookings.Services,
      },
    ];
  }

  // functions

  *init(bookingId: string) {
    this.setIsLoading(true);
    yield this.storeRoot.getBookingsList(EBookingType.Active);

    if (
      bookingId &&
      this.storeRoot.checkBookingIdByUrl(bookingId, EBookingType.Active)
    ) {
      yield this.storeMyApartment?.getBookingById(Number(bookingId));

      if (
        !this.storeMyApartment.apartmentDetail?.isExtension &&
        this.storeMyApartment.apartmentDetail?.bookingCalendar.needBail
      ) {
        yield this.checkPaidPawn(Number(bookingId));
      }

      this.writeCountResidentsFromCounter();
    } else {
      this.navigate(SCREENS.SCREEN_404);
    }

    this.setIsLoading(false);
  }

  *checkPaidPawn(bookingId: number) {
    // редирект на 404 если не оплачен залог, но ввели адрес ссылки вручную
    yield this.storeBills.getBills(bookingId);

    if (!this.storeBills.bills.length) {
      this.navigate(SCREENS.SCREEN_404);
      return;
    }

    if (this.storeBills.bills.length === 1) {
      let _bill = this.storeBills.bills[0];

      if (_bill.status === EStatusBill.NotPaid) {
        this.navigate(SCREENS.SCREEN_404);
      }
    }
  }

  onClickTab(id: string, tabName: string) {
    switch (tabName) {
      case DOCUMENTS:
        this.navigate(
          PAGE_MY_BOOKING_BY_TAB(id, tabName) + `/${ETabsDocuments.Agreements}`
        );
        break;

      case RESIDENTS:
        this.navigate(
          PAGE_MY_BOOKING_BY_TAB(id, tabName) + `/${ETabsResidents.Renter}`
        );
        break;

      case SERVICES:
        this.navigate(
          PAGE_MY_BOOKING_BY_TAB(id, tabName) + `/${ETabsServices.Selected}`
        );
        break;

      default:
        this.navigate(PAGE_MY_BOOKING_BY_TAB(id, tabName));
    }
  }

  writeCountResidentsFromCounter() {
    let _countResidents =
      Number(
        this.storeMyApartment.apartmentDetail?.bookingCalendar.countAdults
      ) +
      Number(
        this.storeMyApartment.apartmentDetail?.bookingCalendar.countChildren
      );
    this.storeRoot.setCurrentCountTenantsFromMyBooking(_countResidents);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
