import { observer } from "mobx-react-lite";

import { usePagination, DOTS } from "../../hooks/usePagination";

import { ReactComponent as Left } from "../../assets/images/left.svg";
import { ReactComponent as Right } from "../../assets/images/right.svg";

import styles from "./styles/pagination.module.scss";

interface IProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
  className: string;
  disabled?: boolean;
}

const Pagination = observer(
  ({
    onPageChange = () => {},
    totalCount = 0,
    siblingCount = 0,
    currentPage = 0,
    pageSize = 0,
    className = "",
    disabled = false,
  }: IProps) => {
    const paginationRange = usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize,
    });

    const pageCount = Math.ceil(totalCount / pageSize);

    if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
      return null;
    }

    const changePageLeft = () => {
      if (currentPage !== 1 && !disabled) {
        onPageChange(currentPage - 1);
      }
    };

    const changePageRight = () => {
      if (currentPage !== pageCount && !disabled) {
        onPageChange(currentPage + 1);
      }
    };

    const clickNumber = (pageNumber: number) => {
      if (!disabled) {
        onPageChange(pageNumber);
      }
    };

    return (
      <nav className={`${styles.pagination} ${className}`}>
        <Left className={styles.pagination__button} onClick={changePageLeft} />
        <ul className={styles.pagination__pages}>
          {paginationRange?.map((pageNumber: string | number, index) => {
            if (pageNumber === DOTS) {
              return (
                <li key={index} className={styles.pagination__dots}>
                  &#8230;
                </li>
              );
            }
            return (
              <li
                key={index}
                className={`${styles.pagination__page} ${
                  pageNumber === currentPage
                    ? styles.pagination__activePage
                    : ""
                }`}
                onClick={() => clickNumber(Number(pageNumber))}
              >
                {pageNumber}
              </li>
            );
          })}
        </ul>
        <Right
          className={styles.pagination__button}
          onClick={changePageRight}
        />
      </nav>
    );
  }
);

export default Pagination;
