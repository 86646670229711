import { FC } from "react";

import { ReactComponent as FiltBtn } from "../../../../assets/images/filtersBtn.svg";

import styles from "./styles/filtersBtn.module.scss";

interface IProps {
  title: string;
  onClick: () => void;
}

export const FiltersBtn: FC<IProps> = ({ title = "", onClick = () => {} }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <FiltBtn />
      <div className={styles.container__title}>{title}</div>
    </div>
  );
};
