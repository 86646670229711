import { NavigateFunction, Params } from "react-router-dom";
import { makeAutoObservable } from "mobx";

import { PAGE_MY_BOOKING_BY_TAB, DOCUMENTS } from "../../../../../constants";
import { SCREENS } from "../../../../../navigation/endpoints";

import { ITab } from "./../../../../../interfaces/index";
import { IStoreUI } from "./interfaces";

import { EBookingType, ETabsDocuments } from "../../../../../types";
import { Store } from "../../../../../stores/types";

export class StoreUI implements IStoreUI {
  navigate: NavigateFunction;
  storeRoot: Store.IRootStore;
  isLoading: boolean = false;

  tabs: ITab[] = [
    {
      id: 1,
      label: "Договора",
      tabName: ETabsDocuments.Agreements,
    },
    {
      id: 2,
      label: "Счета",
      tabName: ETabsDocuments.Bills,
    },
    {
      id: 3,
      label: "Акты",
      tabName: ETabsDocuments.Acts,
    },

    {
      id: 4,
      label: "Другое",
      tabName: ETabsDocuments.Others,
    },
  ];

  params: Readonly<Params<string>> | null = null;

  constructor(
    navigate: NavigateFunction,
    params: Readonly<Params<string>>,
    storeRoot: Store.IRootStore
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.navigate = navigate;
    this.params = params;
    this.storeRoot = storeRoot;
  }

  // functions

  *init(bookingId: string) {
    this.setIsLoading(true);
    yield this.storeRoot.getBookingsList(EBookingType.Active);

    if (
      !bookingId ||
      !this.storeRoot.checkBookingIdByUrl(bookingId, EBookingType.Active)
    ) {
      this.navigate(SCREENS.SCREEN_404);
    }

    this.setIsLoading(false);
  }

  onClickTab(id: string, tabName: string) {
    this.navigate(PAGE_MY_BOOKING_BY_TAB(id, DOCUMENTS) + `/${tabName}`);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
