import { FC, forwardRef, ReactNode, useCallback, useRef } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { observer } from "mobx-react-lite";

import { InputDate } from "../InputDate";
import { Duration } from "../../pages/Apartments/Apartment/Booking/Duration";
import { Button } from "../Button";

import { TDateMinMax, TDatePicker } from "../../types";
import { IDatesInterval } from "../../interfaces";

import styles from "./styles/selectDate.module.scss";
import "react-datepicker/dist/react-datepicker.css";

interface IPropsWrap {
  isShowModal: boolean;
  title: string;
  type: TDatePicker;
  currentDay: Date | null;
  children?: ReactNode;
  placeholder?: string;
  className?: string;
  classNameWrapper?: string;
  label?: string;
  timing?: boolean;
  minDate?: TDateMinMax;
  maxDate?: TDateMinMax;
  errors?: string[];
  disabledDates?: IDatesInterval[];
  readOnly?: boolean;
  countTimingDays?: number;
  pickerIsPopup?: boolean;
  withIcon?: boolean;
  classNameCalendar?: string;
  classNameCalendarContainer?: string;
  calendarIsBlack?: boolean;
  changeCurrentDay(date: Date): void;
  getIsActiveDay?(date: Date): boolean;
  setIsShowModal?(isShowModal: boolean): void;
  closeDateModal?(): void;
  openDateModal?(): void;
}

export const SelectDate: FC<IPropsWrap> = observer(
  ({
    isShowModal = false,
    title = "",
    type = "",
    placeholder = "",
    className = "",
    label = "",
    currentDay = null,
    timing = false,
    minDate = "disable",
    maxDate = "disable",
    readOnly = false,
    errors = [],
    disabledDates = [],
    countTimingDays = 90,
    pickerIsPopup = false,
    withIcon = true,
    classNameCalendar = "",
    calendarIsBlack = false,
    classNameWrapper = "",
    classNameCalendarContainer = "",
    getIsActiveDay = () => true,
    setIsShowModal = () => {},
    closeDateModal = () => {},
    openDateModal = () => {},
    changeCurrentDay = () => {},
  }) => {
    const getDate = (date: TDateMinMax) => {
      switch (date) {
        case "disable":
          return undefined;
        case "now":
          return new Date();
        case null:
          return new Date();
        default:
          return new Date(date);
      }
    };

    const _minDate = getDate(minDate);
    const _maxDate = getDate(maxDate);

    const ref = useRef<DatePicker>(null);
    const inputRef = useRef(null);

    const activeBackground = useCallback(() => {
      setIsShowModal(true);
      openDateModal();
    }, [setIsShowModal, openDateModal]);
    const hideBackground = useCallback(() => {
      closeDateModal();
      setIsShowModal(false);
    }, [setIsShowModal, closeDateModal]);

    const CustomInput = forwardRef((props: any, ref) => {
      return (
        <InputDate
          {...props}
          innerRef={ref}
          classNameInput={className}
          label={label}
          errors={errors}
          withIcon={withIcon}
          readonly={true}
          calendarIsBlack={calendarIsBlack}
        />
      );
    });
    const CustomBookInput = forwardRef((props: any, ref) => {
      return <Duration {...props} innerRef={ref} />;
    });

    const _closeModal = () => {
      closeDateModal();
      setIsShowModal(false);
    };

    const wrapperDate = ({ children }: IPropsWrap) => {
      return (
        <>
          {isShowModal && (
            <div
              className={`${styles.wrapperDate} ${
                pickerIsPopup && styles.wrapperDate_popupMode
              } ${classNameCalendar}`}
            >
              <CalendarContainer className={classNameCalendarContainer}>
                <div
                  className={`${styles.wrapperDate__container} ${
                    pickerIsPopup ? styles.wrapperDate__container_popupMode : ""
                  } ${classNameCalendarContainer}`}
                >
                  <div
                    className={`${styles.wrapperDate__content} ${
                      pickerIsPopup && styles.wrapperDate__content_popupMode
                    }`}
                  >
                    <div className={styles.wrapperDate__header}>
                      <h3>{title}</h3>
                    </div>
                    <div
                      className={`${styles.wrapperDate__calendar} ${
                        pickerIsPopup && styles.wrapperDate__calendar_popupMode
                      }`}
                    >
                      {children}
                    </div>
                    {!pickerIsPopup && (
                      <Button
                        className={styles.wrapperDate__button}
                        title="СОХРАНИТЬ"
                        theme="gold"
                        onClick={_closeModal}
                      />
                    )}
                  </div>
                </div>
              </CalendarContainer>
            </div>
          )}
        </>
      );
    };

    return (
      <div
        className={`${styles.select} ${
          pickerIsPopup ? styles.select_popupMode : ""
        } ${classNameWrapper}`}
      >
        <DatePicker
          renderCustomHeader={({
            monthDate,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
          }) => (
            <div>
              <button
                aria-label="Previous Month"
                className={`${"react-datepicker__navigation react-datepicker__navigation--previous"}
                ${
                  prevMonthButtonDisabled &&
                  "react-datepicker__navigation--previous_disabled"
                }`}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("ru-RU", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
          selected={currentDay}
          ref={ref}
          onChange={(date: Date) => changeCurrentDay(date)}
          dateFormat="dd.MM.yyyy"
          shouldCloseOnSelect={pickerIsPopup}
          calendarContainer={wrapperDate}
          onCalendarOpen={activeBackground}
          onCalendarClose={hideBackground}
          placeholderText={placeholder}
          calendarStartDay={1}
          minDate={_minDate}
          maxDate={_maxDate}
          showDisabledMonthNavigation={false}
          readOnly={readOnly}
          customInput={
            type === "book" ? (
              <CustomBookInput inputRef={inputRef} />
            ) : (
              <CustomInput inputRef={inputRef} />
            )
          }
          excludeDateIntervals={disabledDates ? disabledDates : undefined}
          filterDate={
            getIsActiveDay
          } /* для дизейбла дат между шагами 30 дней */
          withPortal={pickerIsPopup}
        />
      </div>
    );
  }
);
