import { makeAutoObservable } from "mobx";

import { getFinance } from "../../../api/Finance";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { IFinance } from "../../../interfaces/Finance";

export class ModelFinance implements Store.IFinanceStore {
  financeInfo: IFinance | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // functions

  *getFinance() {
    let response: TResponseApi<IFinance> = yield getFinance();

    if (response.isSuccess && response.data !== null) {
      this.setFinanceInfo(response.data);
    }
  }

  // setters

  setFinanceInfo(value: IFinance | null) {
    this.financeInfo = value;
  }
}
