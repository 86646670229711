import { FC, useMemo } from "react";

import { ReactComponent as AddTenant } from "../../assets/images/addTenant.svg";

import { TAddPersonBtnTneme } from "../../types";

import styles from "./styles/addPersonBtn.module.scss";

interface IProps {
  onClick: () => void;
  title: string;
  theme?: TAddPersonBtnTneme;
  className?: string;
  disabled?: boolean;
  isDisabledStyle?: boolean;
}

export const AddPersonBtn: FC<IProps> = ({
  title = "",
  onClick = () => {},
  theme = "white",
  className = "",
  disabled = false,
  isDisabledStyle = false,
}) => {
  let themeStyle = useMemo(() => {
    switch (theme) {
      case "gold":
        return `${styles.button_gold}`;
      default:
        return `${styles.button_white}`;
    }
  }, [theme]);

  const _onClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <button
      className={`${styles.button} ${
        isDisabledStyle ? styles.button_disabled : ""
      } ${themeStyle} ${className}`}
      onClick={_onClick}
    >
      <AddTenant
        className={theme === "white" ? styles.button__icon_black : ""}
      />
      <p
        className={`${styles.button__title} ${
          theme === "gold" ? styles.button__title_white : ""
        }`}
      >
        {title}
      </p>
    </button>
  );
};
