import { observer } from "mobx-react-lite";
import { FC, useState } from "react";

import { Polygon } from "../Polygon";

import {
  IBlockedApartment,
  IFloorPlanApartment,
} from "../../../interfaces/Floor";

import floor8 from "../../../assets/images/floorPlan8.png";
import floor8space from "../../../assets/images/floorPlan8Space.png";
import floor10 from "../../../assets/images/floorPlan10.png";

import styles from "./styles/index.module.scss";

import { EFloorPlan } from "../../../types";

const eightApartmentsData = [
  {
    typeApartment: 7,
    viewBox: "0 0 166 292",
    points:
      "105.000 1.000, 14.000 1.000, 14.000 35.000, 1.000 35.000, 1.000 291.500, 165.500 291.500, 165.500 193.000, 105.000 193.000, 105.000 190.000, 105.000 1.000",
    className: styles.eightApartments__polygon_one,
  },
  {
    typeApartment: 8,
    viewBox: "0 0 199 196",
    points:
      "154.679 0.000, 62.126 0.000, 62.126 28.419, 0.000 28.419, 0.000 195.419, 199.000 195.419, 199.000 28.419, 154.679 28.419, 154.679 0.000",
    className: styles.eightApartments__polygon_two,
  },
  {
    typeApartment: 1,
    viewBox: "0 0 138 196",
    points:
      "137.341 0.000, 44.788 0.000, 44.788 31.419, 0.000 31.419, 0.000 195.419, 112.000 195.419, 112.000 151.419, 134.000 151.419, 134.000 31.713, 137.341 31.713, 137.341 0.000",
    className: styles.eightApartments__polygon_three,
  },
  {
    typeApartment: 2,
    viewBox: "0 0 245 168",
    points:
      "1.000 120.000, 1.000 167.000, 232.500 167.500, 232.500 109.000, 236.000 109.000, 236.000 21.000, 244.000 21.000, 244.000 1.000, 34.000 1.000, 34.000 120.000, 1.000 120.000",
    className: styles.eightApartments__polygon_four,
  },
  {
    typeApartment: 3,
    viewBox: "0 0 240 199",
    points:
      "239.000 1.000, 37.500 1.000, 37.500 46.000, 1.000 46.000, 1.000 150.000, 37.500 150.000, 37.500 198.000, 203.500 198.000, 203.500 190.000, 239.000 190.000, 239.000 1.000",
    className: styles.eightApartments__polygon_five,
  },
  {
    typeApartment: 4,
    viewBox: "0 0 345 165",
    points:
      "307.000 9.000, 307.000 1.000, 1.000 1.000, 1.000 164.000, 307.000 164.000, 307.000 132.000, 344.500 132.000, 344.500 9.000, 307.000 9.000",
    className: styles.eightApartments__polygon_six,
  },
  {
    typeApartment: 5,
    viewBox: "0 0 148 194",
    points:
      "147.000 1.000, 1.000 1.000, 1.000 162.000, 4.500 162.000, 4.500 193.500, 96.500 193.500, 96.500 164.000, 147.500 164.000, 147.500 1.000",
    className: styles.eightApartments__polygon_seven,
  },
  {
    typeApartment: 6,
    viewBox: "0 0 208 267",
    points:
      "207.726 0.266, 42.264 0.266, 42.264 93.896, 0.332 93.896, 0.332 112.018, 12.421 112.018, 12.421 248.687, 0.332 248.687, 0.332 266.809, 42.264 266.809, 207.726 266.809, 207.726 0.266",
    className: styles.eightApartments__polygon_eight,
  },
];

const eightApartmentsSpaceData = [
  {
    typeApartment: 7,
    viewBox: "0 0 147 288",
    points:
      "94.000 1.000, 14.000 1.000, 14.000 30.000, 1.000 30.000, 1.000 287.000, 146.000 287.000, 146.000 183.000, 94.000 183.000, 94.000 1.000",
    className: styles.eightApartmentsSpace__polygon_one,
  },
  {
    typeApartment: 8,
    viewBox: "0 0 186 192",
    points:
      "1.000 191.000, 185.000 191.000, 185.000 32.000, 140.000 32.000, 140.000 1.000, 59.000 1.000, 59.000 32.000, 1.000 32.000, 1.000 191.000",
    className: styles.eightApartmentsSpace__polygon_two,
  },

  {
    typeApartment: 1,
    viewBox: "0 0 130 188",
    points:
      "41.000 31.000, 1.000 31.000, 1.000 187.000, 99.000 187.000, 99.000 143.000, 129.000 143.000, 129.000 31.000, 123.000 31.000, 123.000 1.000, 41.000 1.000, 41.000 31.000",
    className: styles.eightApartmentsSpace__polygon_three,
  },

  {
    typeApartment: 2,
    viewBox: "0 0 212 161",
    points:
      "1.000 118.000, 1.000 160.000, 176.000 160.000, 176.000 106.000, 206.500 106.000, 206.500 18.500, 211.500 18.500, 211.500 1.000, 28.000 1.000, 28.000 4.000, 28.000 118.000, 1.000 118.000",
    className: styles.eightApartmentsSpace__polygon_four,
  },

  {
    typeApartment: 3,
    viewBox: "0 0 214 176",
    points:
      "174.000 16.500, 1.000 16.500, 1.000 62.500, 32.000 62.500, 32.000 175.000, 213.500 175.000, 213.500 156.000, 203.000 156.000, 203.000 89.500, 208.000 89.500, 208.000 0.500, 174.000 0.500, 174.000 16.500",
    className: styles.eightApartmentsSpace__polygon_seven,
  },

  {
    typeApartment: 4,
    viewBox: "0 0 134 191",
    points:
      "97.500 1.000, 1.000 1.000, 1.000 190.500, 133.500 190.500, 133.500 159.000, 127.000 159.000, 127.000 46.500, 97.500 46.500, 97.500 1.000",
    className: styles.eightApartmentsSpace__polygon_eight,
  },

  {
    typeApartment: 5,
    viewBox: "0 0 135 191",
    points:
      "3.500 0.500, 134.000 0.500, 134.000 190.000, 0.500 190.000, 0.500 0.500, 3.500 0.500",
    className: styles.eightApartmentsSpace__polygon_nine,
  },

  {
    typeApartment: 6,
    viewBox: "0 0 181 261",
    points:
      "36.500 93.000, 0.500 93.000, 0.500 111.000, 9.500 111.000, 9.500 242.500, 0.500 242.500, 0.500 260.500, 180.000 260.500, 180.000 1.000, 36.500 1.000, 36.500 93.000",
    className: styles.eightApartmentsSpace__polygon_ten,
  },
];

const tenApartmentsData = [
  {
    typeApartment: 9,
    viewBox: "0 0 147 288",
    points:
      "94.000 1.000, 14.000 1.000, 14.000 30.000, 1.000 30.000, 1.000 287.000, 146.000 287.000, 146.000 183.000, 94.000 183.000, 94.000 1.000",
    className: styles.tenApartments__polygon_one,
  },
  {
    typeApartment: 10,
    viewBox: "0 0 186 192",
    points:
      "1.000 191.000, 185.000 191.000, 185.000 32.000, 140.000 32.000, 140.000 1.000, 59.000 1.000, 59.000 32.000, 1.000 32.000, 1.000 191.000",
    className: styles.tenApartments__polygon_two,
  },

  {
    typeApartment: 1,
    viewBox: "0 0 130 188",
    points:
      "41.000 31.000, 1.000 31.000, 1.000 187.000, 99.000 187.000, 99.000 143.000, 129.000 143.000, 129.000 31.000, 123.000 31.000, 123.000 1.000, 41.000 1.000, 41.000 31.000",
    className: styles.tenApartments__polygon_three,
  },

  {
    typeApartment: 2,
    viewBox: "0 0 212 161",
    points:
      "1.000 118.000, 1.000 160.000, 176.000 160.000, 176.000 106.000, 206.500 106.000, 206.500 18.500, 211.500 18.500, 211.500 1.000, 28.000 1.000, 28.000 4.000, 28.000 118.000, 1.000 118.000",
    className: styles.tenApartments__polygon_four,
  },

  {
    typeApartment: 3,
    viewBox: "0 0 195 106",
    points:
      "167.500 1.000, 32.000 1.000, 32.000 53.500, 1.000 53.500, 1.000 105.000, 194.000 105.000, 194.000 14.000, 167.500 14.000, 167.500 1.000",
    className: styles.tenApartments__polygon_five,
  },

  {
    typeApartment: 4,
    viewBox: "0 0 194 100",
    points:
      "193.500 1.000, 0.500 1.000, 0.500 52.000, 29.000 52.000, 29.000 99.500, 166.000 99.500, 166.000 87.000, 193.500 87.000, 193.500 1.000",
    className: styles.tenApartments__polygon_six,
  },

  {
    typeApartment: 5,
    viewBox: "0 0 214 176",
    points:
      "174.000 16.500, 1.000 16.500, 1.000 62.500, 32.000 62.500, 32.000 175.000, 213.500 175.000, 213.500 156.000, 203.000 156.000, 203.000 89.500, 208.000 89.500, 208.000 0.500, 174.000 0.500, 174.000 16.500",
    className: styles.tenApartments__polygon_seven,
  },

  {
    typeApartment: 6,
    viewBox: "0 0 134 191",
    points:
      "97.500 1.000, 1.000 1.000, 1.000 190.500, 133.500 190.500, 133.500 159.000, 127.000 159.000, 127.000 46.500, 97.500 46.500, 97.500 1.000",
    className: styles.tenApartments__polygon_eight,
  },

  {
    typeApartment: 7,
    viewBox: "0 0 135 191",
    points:
      "3.500 0.500, 134.000 0.500, 134.000 190.000, 0.500 190.000, 0.500 0.500, 3.500 0.500",
    className: styles.tenApartments__polygon_nine,
  },

  {
    typeApartment: 8,
    viewBox: "0 0 181 261",
    points:
      "36.500 93.000, 0.500 93.000, 0.500 111.000, 9.500 111.000, 9.500 242.500, 0.500 242.500, 0.500 260.500, 180.000 260.500, 180.000 1.000, 36.500 1.000, 36.500 93.000",
    className: styles.tenApartments__polygon_ten,
  },
];

interface IProps {
  typeFloor: EFloorPlan | undefined;
  floorApartments: IFloorPlanApartment[];
  blockedApartments?: IBlockedApartment[];
  onClickApartment(id: number): void;
  toApartmentPage(id: number): void;
}

export const FloorPlan: FC<IProps> = observer(
  ({
    typeFloor = undefined,
    floorApartments = [],
    blockedApartments = [],
    onClickApartment = () => {},
    toApartmentPage = () => {},
  }) => {
    const [isGoodImg, setIsGoodImg] = useState(true);

    const getId = (numberFloor: number) => {
      let apartmentByType = floorApartments?.find(
        (item) => item.typeApartment === numberFloor
      );
      return apartmentByType?.id || 0;
    };

    const getImgSrc = (type: EFloorPlan | undefined) => {
      switch (type) {
        case EFloorPlan.EightApartments:
          return floor8;
        case EFloorPlan.EightApartmentsSpace:
          return floor8space;
        case EFloorPlan.TenApartments:
          return floor10;
        default:
          return "";
      }
    };

    return (
      <div className={styles.floorPlan}>
        {typeFloor === EFloorPlan.EightApartments && (
          <div className={styles.eightApartments}>
            {isGoodImg &&
              eightApartmentsData.map((item, index) => {
                let _id = getId(item.typeApartment);
                return (
                  <Polygon
                    key={index}
                    id={_id}
                    viewBow={item.viewBox}
                    points={item.points}
                    className={`${styles.eightApartments__polygon} ${item.className}`}
                    onClick={() => onClickApartment(_id)}
                    toApartmentPage={toApartmentPage}
                    blockedApartments={blockedApartments}
                  />
                );
              })}
          </div>
        )}

        {typeFloor === EFloorPlan.EightApartmentsSpace && (
          <div className={styles.eightApartmentsSpace}>
            {isGoodImg &&
              eightApartmentsSpaceData.map((item, index) => {
                let _id = getId(item.typeApartment);
                return (
                  <Polygon
                    key={index}
                    id={_id}
                    viewBow={item.viewBox}
                    points={item.points}
                    className={`${styles.eightApartmentsSpace__polygon} ${item.className}`}
                    onClick={() => onClickApartment(_id)}
                    blockedApartments={blockedApartments}
                    toApartmentPage={toApartmentPage}
                  />
                );
              })}
          </div>
        )}

        {typeFloor === EFloorPlan.TenApartments && (
          <div className={styles.tenApartments}>
            {isGoodImg &&
              tenApartmentsData.map((item, index) => {
                let _id = getId(item.typeApartment);
                return (
                  <Polygon
                    key={index}
                    id={_id}
                    viewBow={item.viewBox}
                    points={item.points}
                    className={`${styles.tenApartments__polygon} ${item.className}`}
                    onClick={() => onClickApartment(_id)}
                    blockedApartments={blockedApartments}
                    toApartmentPage={toApartmentPage}
                  />
                );
              })}
          </div>
        )}

        {isGoodImg ? (
          <img
            src={getImgSrc(typeFloor)}
            alt={"slide"}
            onError={() => setIsGoodImg(false)}
            onLoad={() => setIsGoodImg(true)}
          />
        ) : (
          <div className={styles.eightApartments__empty}>
            Не удалось загрузить изображение
          </div>
        )}
      </div>
    );
  }
);
