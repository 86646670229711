import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { disableScroll, enableScroll } from "../../../../../helpers/modalsFunc";
import {
  ERROR_MIN_CHARS_THREE,
  ERROR_RATING_EMPTY,
  ERROR_TEXT_EMPTY,
  PAGE_ARCHIVE_DOCS_TAB_BY_ID,
} from "../../../../../constants";
import { SCREENS } from "../../../../../navigation/endpoints";

import { IReviewErrors, IStoreUI } from "./interfaces";

import { Store } from "../../../../../stores/types";
import { ETabsDocuments } from "../../../../../types";

export class StoreUI implements IStoreUI {
  storeArchives: Store.IArchive;
  navigate: NavigateFunction;
  isModalReview: boolean = false;
  isLoading: boolean = false;
  isLoadingReview: boolean = false;
  rating: number = 0;
  apartmentId: number = 0;
  bookingId: number = 0;
  hoverRating: undefined | number = undefined;
  stars: number[] = Array(5).fill(0);
  text: string = "";
  errors: IReviewErrors = {
    text: [],
    rating: [],
  };

  constructor(navigate: NavigateFunction, storeArchives: Store.IArchive) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeArchives = storeArchives;
    this.navigate = navigate;
  }

  // getters

  get archives() {
    return this.storeArchives.archivesItems;
  }

  get countArchives() {
    return this.storeArchives.archivesItems?.length || 0;
  }

  get textMainErrors() {
    return this.errors.text.concat(this.storeArchives.errorsReview);
  }

  // functions

  *init() {
    this.setIsLoading(true);
    yield this.storeArchives.getArchives();
    this.setIsLoading(false);
  }

  *sendReview(apartmentId: number, bookingId: number) {
    this.validateText();
    this.validateRating();

    if (this.checkValidAll()) {
      this.setIsLoadingReview(true);
      let response: boolean = yield this.storeArchives.sendReview(
        apartmentId,
        bookingId,
        this.rating,
        this.text
      );

      this.setIsLoadingReview(false);
      if (response) {
        this.clearForm();
        this.closeReview();
      }
      yield this.storeArchives.getArchives();
      return true;
    }

    return false;
  }

  onClickDocs(id: string) {
    this.navigate(PAGE_ARCHIVE_DOCS_TAB_BY_ID(id, ETabsDocuments.Agreements));
  }

  validateText() {
    if (this.text.length === 0) {
      this.errors.text = [...this.errors.text, ERROR_TEXT_EMPTY];
      return false;
    } else {
      this.errors.text = [];
    }

    if (this.text.length < 3) {
      this.errors.text = [...this.errors.text, ERROR_MIN_CHARS_THREE];
      return false;
    } else {
      this.errors.text = [];
    }

    return true;
  }

  validateRating() {
    if (this.rating === 0) {
      this.errors.rating = [...this.errors.rating, ERROR_RATING_EMPTY];
      return false;
    } else {
      this.errors.rating = [];
      return true;
    }
  }

  checkValidAll() {
    return this.validateText() && this.validateRating();
  }

  clearForm() {
    this.setText("");
    this.setRating(0);
    this.errors.rating = [];
    this.errors.text = [];
    this.storeArchives.clearErrorReview();
  }

  onChangeText(value: string) {
    this.setText(value);

    if (this.errors.text.length) {
      this.errors.text = [];
    }
  }

  openReview(apartmentId: number, bookingId: number) {
    this.isModalReview = true;
    this.setApartment(apartmentId);
    this.setBookingId(bookingId);
    disableScroll();
  }

  closeReview() {
    this.isModalReview = false;
    this.clearForm();
    enableScroll();
  }

  toApartment = (apartmentId: number) => {
    this.navigate(`${SCREENS.SCREEN_APARTMENTS}/${apartmentId}`);
  };

  toApartments = () => {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  };

  //setters

  setApartment(value: number) {
    this.apartmentId = value;
  }

  setBookingId(value: number) {
    this.bookingId = value;
  }

  setText(value: string) {
    this.text = value;
  }

  setRating(value: number) {
    this.rating = value;
  }

  setHoverRating(value: number | undefined) {
    this.hoverRating = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingReview(value: boolean) {
    this.isLoadingReview = value;
  }
}
