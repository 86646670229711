import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { changeSpaceInNumber } from "../../../helpers";

import { Store } from "../../../stores/types";
import { EStatusAvailability } from "../../../types";
import { IStoreUI } from "./interfaces";

import {
  BASKET_SERVICES_VISIBLE,
  ERROR_TEXT_SERVER_COMMON,
} from "../../../constants";
import { SCREENS } from "../../../navigation/endpoints";

import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";

export class StoreUI implements IStoreUI {
  storeBasket: Store.IBasket;
  storeAuthentication: Store.IStoreAuthentication;
  navigate: NavigateFunction;
  isLoading: boolean = false;
  visibleAllServicesPopup: boolean = false;

  visibleConfirmationPopup: boolean = false;
  visibleCheckFreePopup: boolean = false;

  mainError: string = "";

  private basketCalendarId: number | null = null;

  constructor(
    storeAuthentication: Store.IStoreAuthentication,
    navigate: NavigateFunction,
    storeRoot: Store.IRootStore
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeBasket = storeRoot.storeBasket;
    this.storeAuthentication = storeAuthentication;
    this.navigate = navigate;
  }

  // getters

  get apartments() {
    return this.storeBasket.apartmentsList?.map((apartment) => {
      return {
        id: apartment.id,
        apartmentId: apartment.apartmentId,
        title: this.getReadyName(
          apartment.roomLabel,
          apartment.square,
          apartment.floor
        ),
        image: apartment.image,
        price: changeSpaceInNumber(apartment.monthPrice),
        period: apartment.period,
      };
    });
  }

  get visibleServices() {
    return (
      this.storeBasket.servicesList?.slice(0, BASKET_SERVICES_VISIBLE) || []
    );
  }

  get allServices() {
    return this.storeBasket.servicesList || [];
  }

  get price() {
    return changeSpaceInNumber(this.storeBasket.price);
  }

  get countAllServices() {
    return this.storeBasket.servicesList?.length || 0;
  }

  // functions

  *init() {
    enableScroll();
    this.setIsLoading(true);
    yield this.storeBasket.getBasket();
    this.setIsLoading(false);
  }

  private *clearBasket() {
    this.setIsLoading(true);
    yield this.storeBasket.clearBasket();
    this.setIsLoading(false);
  }

  private *deleteApartment(basketCalendarId: number) {
    yield this.storeBasket.deleteApartment(basketCalendarId);
  }

  *onClearBasket() {
    if (
      this.basketCalendarId !== null &&
      typeof this.basketCalendarId === "number"
    ) {
      yield this.deleteApartment(this.basketCalendarId);
      this.setBasketCalendarId(null);
    } else {
      yield this.clearBasket();
    }
    this.closeConfirmationPopup();
  }

  *deleteService(serviceId: number) {
    yield this.storeBasket.deleteService(serviceId);
  }

  getReadyName(roomsTitle: string, square: number, floor: number) {
    let _square = Math.round(square);

    return `${roomsTitle}, ${String(_square)} м², ${String(floor)} этаж`;
  }

  openAllServices() {
    this.setVisibleAllServicesPopup(true);
    disableScroll();
  }

  closeAllServices() {
    this.setVisibleAllServicesPopup(false);
    enableScroll();
  }

  openConfirmationPopup(basketCalendarId?: number) {
    this.setVisibleConfirmationPopup(true);
    disableScroll();

    if (basketCalendarId) {
      this.setBasketCalendarId(basketCalendarId);
    }
  }

  closeConfirmationPopup() {
    this.setVisibleConfirmationPopup(false);
    enableScroll();
  }

  *toOrder() {
    if (this.storeAuthentication.isAuth) {
      let params = {
        apartmentId: this.apartments[0].apartmentId,
        startDate: this.apartments[0].period.from,
        endDate: this.apartments[0].period.to,
      };

      this.setIsLoading(true);
      let response: EStatusAvailability | null =
        yield this.storeBasket.checkFreeApartment(params);
      this.setIsLoading(false);

      switch (response) {
        case EStatusAvailability.Free:
          this.navigate(SCREENS.SCREEN_ORDER);
          this.setMainError("");
          break;
        case EStatusAvailability.Busy:
          this.setVisibleCheckFreePopup(true);
          this.setMainError("");
          break;
        case null:
          this.setMainError(ERROR_TEXT_SERVER_COMMON);
          break;
      }
    } else {
      this.storeAuthentication.openAuthModal();
    }
  }

  toCatalog() {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  }

  clearBasketAndToCatalog() {
    this.clearBasket();
    this.toCatalog();
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setVisibleAllServicesPopup(value: boolean) {
    this.visibleAllServicesPopup = value;
  }

  setVisibleConfirmationPopup(value: boolean) {
    this.visibleConfirmationPopup = value;
  }

  setBasketCalendarId(value: number | null) {
    this.basketCalendarId = value;
  }

  setVisibleCheckFreePopup(value: boolean) {
    this.visibleCheckFreePopup = value;
  }

  setMainError(value: string) {
    this.mainError = value;
  }
}
