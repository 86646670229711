import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useLottie } from "lottie-react";

import { MenuMobile } from "../Modals/Menu/Mobile";
import { Menu } from "../Modals/Menu/Desktop";
import { ModalReuse } from "../Modals/ModalReuse";
import { Auth } from "../Modals/Auth";

import { stopPropagation } from "../../helpers/modalsFunc";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../stores/Root";

import { SCREENS } from "../../navigation/endpoints";

import { Store } from "../../stores/types";

import { COLOR_ACCENT, NAV_HEADER } from "../../constants";

import logo from "../../assets/animations/logo.json";

import { ReactComponent as Burger } from "../../assets/images/burger.svg";
import { ReactComponent as LogoM } from "../../assets/mobile/logoM.svg";
import { ReactComponent as Basket } from "../../assets/images/basket.svg";
import { ReactComponent as Enter } from "../../assets/images/enter.svg";

import styles from "./styles/header.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
  basketCount: number;
  className?: string;
  closeModal?: () => void;
  isFloatMode?: boolean;
  innerRef?: React.LegacyRef<HTMLDivElement> | undefined;
}

export const Header = observer(
  ({
    storeAuthentication,
    basketCount = 0,
    className = "",
    closeModal = () => {},
    isFloatMode = false,
    innerRef = undefined,
  }: IProps) => {
    const navigate = useNavigate();
    const rootStore = useContext(RootContext);

    const [storeUI] = useState(
      () => new StoreUI(storeAuthentication, rootStore, navigate)
    );

    useEffect(() => {
      (async () => {
        await storeUI.init();
      })();
    }, [storeUI]);

    const {
      View: Logo,
      play: playLogo,
      setDirection: reverseLogo,
    } = useLottie({
      animationData: logo,
      loop: false,
      autoplay: false,
    });

    const onMouseEnterLogo = () => {
      reverseLogo(1);
      playLogo();
    };

    const onMouseLeaveLogo = () => {
      reverseLogo(-1);
      playLogo();
    };

    return (
      <header
        ref={innerRef}
        className={`${styles.header} ${
          isFloatMode ? styles.header_floatMode : ""
        } ${className}`}
        onClick={stopPropagation}
      >
        <div className={styles.header__content}>
          <div className={styles.header__contentLeft}>
            <NavLink to={SCREENS.SCREEN_MAIN}>
              <div
                className={styles.header__logo}
                onMouseEnter={onMouseEnterLogo}
                onMouseLeave={onMouseLeaveLogo}
              >
                {Logo}
              </div>
            </NavLink>
            <LogoM className={styles.header__logoM} onClick={storeUI.toMain} />
            <nav className={styles.header__nav}>
              {NAV_HEADER.map((title) => (
                <NavLink
                  to={String(title.path)}
                  key={title.id}
                  className={styles.header__navTitle}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? COLOR_ACCENT : "",
                    };
                  }}
                >
                  {title.title}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className={styles.header__contentRight}>
            <div
              className={styles.header__avatarWrapper}
              onClick={storeUI.onClickAvatar}
            >
              {!storeUI.isAuth ? (
                <Enter className={styles.header__enterImg} />
              ) : (
                <img
                  className={styles.header__avatarImg}
                  src={storeUI.avatarImg}
                  alt="avatar"
                />
              )}
            </div>

            <button
              className={styles.header__basketBtn}
              onClick={storeUI.toBasket}
            >
              <Basket className={styles.header__basketIcon} />
              {basketCount > 0 && (
                <span className={styles.header__basketCount}>
                  {basketCount}
                </span>
              )}
            </button>

            <div
              className={styles.header__rightBurger}
              onClick={storeUI.openMenu}
            >
              <span className={styles.header__rightBurgerLine} />
              <span className={styles.header__rightBurgerLine} />
            </div>
            <Burger
              className={styles.header__rightBurgerM}
              onClick={storeUI.openMobileMenu}
            />
          </div>
        </div>
        <MenuMobile
          menuMobile={storeUI.isOpenMobileMenu}
          closeMenuMobile={storeUI.closeMobileMenu}
          onLogout={storeUI.openLogout}
          onLogin={storeUI.onClickAvatar}
          storeAuthentication={storeAuthentication}
          personalType={storeUI.personalType}
          showRules={rootStore.haveArchivedOrActiveBooking}
        />
        <Menu
          menu={storeUI.isOpenMenu}
          closeMenu={storeUI.closeMenu}
          storeAuthentication={storeAuthentication}
          phone={storeUI.phoneNumber}
          isMobileVersion={storeUI.isMobileVersion}
          closeMobileVersion={storeUI.closeMobileVersion}
          openMobileVersion={storeUI.openMobileVersion}
        />
        <Auth storeAuthentication={storeAuthentication} />
        <ModalReuse
          isShowModal={storeUI.isOpenLogout}
          setIsShowModal={storeUI.setIsOpenLogout}
          type={"buttons"}
          onClick={storeUI.logout}
          btnLeft={"да"}
          btnRight={"нет"}
          isLoading={storeUI.isLoading}
        >
          <h2>Вы уверены, что хотите выйти из аккаунта?</h2>
        </ModalReuse>
      </header>
    );
  }
);
