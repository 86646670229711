import { observer } from "mobx-react-lite";

import { RadioButton } from "./RadioButton";

import { IRadioItem } from "../../interfaces";

import styles from "./styles/radioTabs.module.scss";

interface IProps {
  currentValue: IRadioItem;
  items: IRadioItem[];
  className?: string;
  onChange(value: IRadioItem): void;
}

export const RadioTabs = observer(
  ({
    className = "",
    items = [],
    currentValue,
    onChange = () => {},
  }: IProps) => {
    return (
      <div className={`${styles.radioTabs} ${className}`}>
        {items?.map((item) => (
          <RadioButton
            key={item.id}
            label={item.label}
            checked={currentValue.value === item.value}
            onChangeValue={() => onChange(item)}
          />
        ))}
      </div>
    );
  }
);
