import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Button } from "../../Button";
import { Loader } from "../../Loader";
import { SelectDate } from "../../DatePicker";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import close from "../../../assets/images/close.svg";

import { TDateMinMax } from "../../../types";

import styles from "./styles/prolong.module.scss";

interface IProps {
  isLoading: boolean;
  visible: boolean;
  isCanBeExtended: boolean;
  maxDate: TDateMinMax;
  minDate: TDateMinMax;
  selectedDay: Date | null;
  errors: string[];
  closeModal(): void;
  onClickContinue(): void;
  changeSelectedDay(date: Date): void;
  closeModal(): void;
}

export const Prolong = observer(
  ({
    isLoading = false,
    visible = false,
    isCanBeExtended = false,
    errors = [],
    closeModal = () => {},
    onClickContinue = () => {},
    maxDate = "disable",
    minDate = "now",
    selectedDay = null,
    changeSelectedDay = () => {},
  }: IProps) => {
    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        closeModal();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    const [modalDate, setModalDate] = useState<boolean>(false);
    return (
      <>
        {visible && (
          <div
            className={styles.prolong}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.prolong__popupWrapper}>
              <div
                className={`${styles.prolong__popup} ${
                  !isCanBeExtended && styles.prolong__popup_noExtended
                }`}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                <img
                  onClick={closeModal}
                  className={styles.prolong__close}
                  src={close}
                  alt="close"
                />
                {isLoading && <Loader className={styles.prolong__loader} />}
                {isCanBeExtended ? (
                  <div className={styles.prolong__withCalendarBlock}>
                    <p className={styles.prolong__title}>
                      Продлить проживание до
                    </p>
                    <SelectDate
                      type="info"
                      title="Выберите дату"
                      label={window.innerWidth <= 375 ? "Выберите дату" : ""}
                      isShowModal={modalDate}
                      closeDateModal={() => setModalDate(false)}
                      openDateModal={() => setModalDate(true)}
                      placeholder="16.02.2015"
                      className={styles.prolong__input}
                      currentDay={selectedDay}
                      changeCurrentDay={changeSelectedDay}
                      maxDate={maxDate}
                      minDate={minDate}
                      timing={true}
                      countTimingDays={30}
                      errors={errors}
                      pickerIsPopup={true}
                    />
                    <div className={styles.prolong__buttonsWrapper}>
                      <button
                        className={styles.prolong__buttonCancel}
                        onClick={closeModal}
                        disabled={isLoading}
                      >
                        Отмена
                      </button>

                      <Button
                        className={styles.prolong__button}
                        title="Подтвердить"
                        theme="gold"
                        onClick={onClickContinue}
                        disabled={isLoading}
                        isDisabledStyle={isLoading}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.prolong__noExtended}>
                    Извините, продление невозможно, обратитесь к администратору
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
