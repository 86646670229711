import { makeAutoObservable } from "mobx";
import { Validator } from "validator-pyrobyte";

import { newRules } from "../../../helpers/validation";

import { IServerErrorsRecovery } from "../../../interfaces/PasswordRecovery";
import { Store } from "../../types";
import { EStepsRecovery } from "../../../types/PasswordRecovery";
import { EStepAuth } from "../../../types/StoreAuthentication";
import { EMethodSend } from "../../../types";
import { IPasswordRecoveryUI } from "./interfaces";

import {
  ERROR_TEXT_EMPTY,
  ERROR_TEXT_PHONE,
  ERROR_TEXT_CODE,
  ERROR_TEXT_CONFIRM_PASSWORD,
} from "../../../constants";

import { ModelPasswordRecovery } from "../../models/Authetication/passwordRecovery";

import { enableScroll } from "../../../helpers/modalsFunc";

export class StorePasswordRecovery implements IPasswordRecoveryUI {
  storeAuthentication: Store.IStoreAuthentication;
  storePasswordRecovery: Store.IPasswordRecovery;

  step: EStepsRecovery = EStepsRecovery.EnterPhone;

  phone: string = "";
  password: string = "";
  confirmPassword: string = "";
  code: string = "";

  isLoading: boolean = false;
  validator: Validator;

  errors: IServerErrorsRecovery = {
    phone: [],
    password: [],
    confirmPassword: [],
    code: [],
  };

  validationErrors: IServerErrorsRecovery = {
    phone: [],
    password: [],
    confirmPassword: [],
    code: [],
  };

  constructor(storeAuthentication: Store.IStoreAuthentication) {
    this.storePasswordRecovery = new ModelPasswordRecovery();
    this.storeAuthentication = storeAuthentication;

    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.validator = new Validator(
      {
        password: {
          password: true,
          required: true,
        },

        specialChars: {
          specialChars: true,
        },
      },
      newRules
    );
  }

  //getters

  get errorsPhone() {
    return this.errors.phone.concat(
      this.validationErrors.phone,
      this.storePasswordRecovery.serverErrors.phone
    );
  }

  get errorsPassword() {
    return this.errors.password.concat(
      this.validationErrors.password,
      this.storePasswordRecovery.serverErrors.password
    );
  }

  get errorsConfirmPassword() {
    return this.errors.confirmPassword.concat(
      this.validationErrors.confirmPassword,
      this.storePasswordRecovery.serverErrors.confirmPassword
    );
  }

  get errorsCode() {
    return this.errors.code.concat(
      this.validationErrors.code,
      this.storePasswordRecovery.serverErrors.code
    );
  }

  // function

  *getCodeToCall() {
    if (this.validatePhone()) {
      this.setIsLoading(true);

      let isSuccessSend: boolean = yield this.storePasswordRecovery.sendCode(
        this.phone,
        EMethodSend.Call
      );

      if (isSuccessSend) {
        this.toStepEnterCode();
      }

      this.setIsLoading(false);
    }
  }

  *getCodeToSms() {
    this.setIsLoading(true);
    yield this.storePasswordRecovery.sendCode(this.phone, EMethodSend.Sms);
    this.setIsLoading(false);
  }

  *onSendCode() {
    if (this.validateCode()) {
      this.setIsLoading(true);

      let response: boolean = yield this.storePasswordRecovery.checkCode(
        this.phone,
        this.code
      );

      if (response) {
        this.toStepEnterPasswords();
      }
      this.setIsLoading(false);
    }
  }

  *onSignin() {
    this.validatePassword();
    this.validateConfirmPassword();

    if (this.validatePassword() && this.validateConfirmPassword()) {
      this.setIsLoading(true);

      let _phone = this.phone.replaceAll(" ", "");
      let isSuccess: boolean = yield this.storePasswordRecovery.changePassword({
        phone: _phone,
        password: this.password,
        confirmPassword: this.confirmPassword,
        code: this.code,
      });

      if (isSuccess) {
        let isAuth: boolean = yield this.storePasswordRecovery.authorization(
          this.phone,
          this.password
        );

        if (isAuth) {
          this.storeAuthentication.closeAuthModal();
          this.storeAuthentication.setIsAuth(isAuth);
          this.resetModal();
          enableScroll();
        }
      }

      this.setIsLoading(false);
    }
  }

  // валидация
  validatePhone() {
    if (this.phone.length === 0) {
      this.addValidationErrorsPhone(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.phone = [];
    }

    if (this.phone.replaceAll(" ", "").length < 12) {
      this.addValidationErrorsPhone(ERROR_TEXT_PHONE);
      return false;
    } else {
      this.validationErrors.phone = [];
    }
    return true;
  }

  validateCode() {
    if (this.code.length === 0) {
      this.addValidationErrorsCode(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.code = [];
    }

    if (this.code.length < 4) {
      this.addValidationErrorsCode(ERROR_TEXT_CODE);
      return false;
    } else {
      this.validationErrors.code = [];
    }
    return true;
  }

  validatePassword() {
    let validPassword = this.validator.check("password", this.password);

    if (this.password.length === 0) {
      this.addValidationErrorsPassword(
        validPassword.errors[validPassword.errors.length - 1]
      );
    } else {
      this.validationErrors.password = [];
    }

    if (!validPassword.passed) {
      this.addValidationErrorsPassword(validPassword.errors[0]);
    } else {
      this.validationErrors.password = [];
    }
    return this.validationErrors.password.length === 0;
  }

  validateConfirmPassword() {
    if (
      this.password !== this.confirmPassword ||
      this.confirmPassword.length === 0
    ) {
      this.addValidationErrorsConfirmPassword(ERROR_TEXT_CONFIRM_PASSWORD);
    } else {
      this.validationErrors.confirmPassword = [];
    }

    return this.validationErrors.confirmPassword.length === 0;
  }

  // изменение шагов

  toStepEnterPhone() {
    this.step = EStepsRecovery.EnterPhone;
  }

  toStepEnterCode() {
    this.step = EStepsRecovery.EnterCode;
  }

  toStepEnterPasswords() {
    this.step = EStepsRecovery.EnterPasswords;
  }

  toRegister() {
    this.storeAuthentication.setStepAuth(EStepAuth.Registration);
    this.clearValues();
  }

  changePhone(value: string) {
    this.setPhone(value);

    if (this.validationErrors.phone.length) {
      this.validationErrors.phone = [];
    }
  }

  changePassword(value: string) {
    this.setPassword(value);

    if (this.validationErrors.password.length) {
      this.validationErrors.password = [];
    }
  }

  changeConfirmPassword(value: string) {
    this.setConfirmPassword(value);

    if (
      this.validationErrors.confirmPassword.length &&
      this.password === this.confirmPassword
    ) {
      this.validationErrors.confirmPassword = [];
    }
  }

  changeCode(value: string) {
    this.setCode(value);

    if (this.validationErrors.code.length && this.code.length) {
      this.validationErrors.code = [];
    }
  }

  // сеттеры

  setPhone(value: string) {
    this.phone = value;
  }

  setCode(value: string) {
    this.code = value.replace(/[^0-9]/gi, "");
  }

  setPassword(value: string) {
    this.password = value;
  }

  setConfirmPassword(value: string) {
    this.confirmPassword = value;
    this.validateConfirmPassword();
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  // методы добавления ошибок

  addValidationErrorsPhone(value: string) {
    this.validationErrors.phone = [...this.validationErrors.phone, value];
  }

  addValidationErrorsCode(value: string) {
    this.validationErrors.code = [...this.validationErrors.code, value];
  }

  addValidationErrorsPassword(value: string) {
    this.validationErrors.password = [...this.validationErrors.password, value];
  }

  addValidationErrorsConfirmPassword(value: string) {
    this.validationErrors.confirmPassword = [
      ...this.validationErrors.confirmPassword,
      value,
    ];
  }

  // очистка формы и переход на начальный шаг
  resetModal() {
    this.toStepEnterPhone();
    this.clearValues();
  }

  clearValues() {
    this.setPhone("");
    this.setPassword("");
    this.setConfirmPassword("");
    this.setCode("");
    this.validationErrors.phone = [];
    this.validationErrors.password = [];
    this.validationErrors.confirmPassword = [];
    this.validationErrors.code = [];
    this.errors.password = [];
    this.errors.confirmPassword = [];
    this.errors.phone = [];
    this.errors.code = [];
    this.storePasswordRecovery.clearErrors();
  }
}
