import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../../../components/Button";

import { EHistotyCard } from "../../../../../types";

import { ReactComponent as AccrualIcon } from "../../../../../assets/images/financeAccrual.svg";
import { ReactComponent as PaymentIcon } from "../../../../../assets/images/financePayment.svg";
import { ReactComponent as TriangleBtn } from "../../../../../assets/images/triangleBtn.svg";

import styles from "./styles/historyCard.module.scss";

interface IProps {
  typeCard: EHistotyCard | null;
  price: string;
  isPaid: boolean;
  numberDocument?: string;
  status?: string;
  dateFrom?: string;
  dateTo?: string;
  billId?: number | null;
  onClickPay?(): void;
}

export const HistoryCard = observer(
  ({
    typeCard = null,
    price = "",
    numberDocument = "",
    status = "",
    dateFrom = "",
    dateTo = "",
    isPaid = false,
    billId = null,
    onClickPay = () => {},
  }: IProps) => {
    let [isVisibleAdditional, setIsVisibleAdditional] = useState(false);
    let visibleBorder =
      typeCard === EHistotyCard.Accrual && isVisibleAdditional;

    const toggleAdditional = () => setIsVisibleAdditional(!isVisibleAdditional);

    let icon = useMemo(() => {
      switch (typeCard) {
        case EHistotyCard.Accrual:
          return <AccrualIcon className={styles.historyCard__icon} />;
        case EHistotyCard.Payment:
          return <PaymentIcon className={styles.historyCard__icon} />;
        default:
          return "";
      }
    }, [typeCard]);

    let title = useMemo(() => {
      switch (typeCard) {
        case EHistotyCard.Accrual:
          return "Начисления";
        case EHistotyCard.Payment:
          return "Оплата";
        default:
          return "";
      }
    }, [typeCard]);

    let symbol = useMemo(() => {
      switch (typeCard) {
        case EHistotyCard.Accrual:
          return "-";
        case EHistotyCard.Payment:
          return "+";
        default:
          return "";
      }
    }, [typeCard]);

    return (
      typeCard && (
        <div className={styles.historyCard}>
          <div
            className={`${styles.historyCard__mainContent}  ${
              visibleBorder ? styles.historyCard__mainContent_withBorder : ""
            }`}
          >
            {icon}

            <div className={styles.historyCard__content}>
              <div className={styles.historyCard__titlePriceWrapper}>
                <span>{title}</span>
                <span>
                  {symbol}
                  {price} ₽
                </span>
              </div>

              {typeCard === EHistotyCard.Accrual && (
                <div className={styles.historyCard__numberStatusWrapper}>
                  <span>№{numberDocument}</span>
                  <span>{status}</span>
                </div>
              )}
            </div>

            {typeCard === EHistotyCard.Accrual && (
              <TriangleBtn
                className={`${styles.historyCard__triangleBtn} ${
                  isVisibleAdditional
                    ? styles.historyCard__triangleBtn_rotate
                    : ""
                }`}
                onClick={toggleAdditional}
              />
            )}
          </div>

          {typeCard === EHistotyCard.Accrual && isVisibleAdditional && (
            <div className={styles.historyCard__additionalContent}>
              <div className={styles.historyCard__propsItemsWrapper}>
                <div className={styles.historyCard__propsItem}>
                  <span className={styles.historyCard__propsTitle}>
                    Выставлен от
                  </span>
                  <span className={styles.historyCard__propsValue}>
                    {dateFrom}
                  </span>
                </div>

                <div className={styles.historyCard__propsItem}>
                  <span className={styles.historyCard__propsTitle}>
                    Оплатить до
                  </span>
                  <span className={styles.historyCard__propsValue}>
                    {dateTo}
                  </span>
                </div>
              </div>

              {!isPaid && billId !== null && (
                <Button
                  title={"Оплатить"}
                  onClick={onClickPay}
                  theme={"gold"}
                  className={styles.historyCard__payButton}
                />
              )}
            </div>
          )}
        </div>
      )
    );
  }
);
