import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { InputDate } from "../../InputDate";
import { Loader } from "../../Loader";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { COMMENT_LENGTH } from "../../../constants";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/popupService.module.scss";

interface IProps {
  visible: boolean;
  title: string;
  description: string;
  img: string;
  date: string;
  comment: string;
  price: string;
  errorsDate: string[];
  errorsCommon: string[];
  isLoading: boolean;
  changeDate(value: string): void;
  changeComment(value: string): void;
  onClickOrder(): void;
  closeModal(): void;
}

export const PopupService = observer(
  ({
    visible = false,
    title = "",
    description = "",
    date = "",
    comment = "",
    price = "",
    img = "",
    errorsDate = [],
    isLoading = false,
    errorsCommon = [],
    changeDate = () => {},
    changeComment = () => {},
    onClickOrder = () => {},
    closeModal = () => {},
  }: IProps) => {
    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        closeModal();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    return (
      <>
        {visible && (
          <div
            className={styles.popupService}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div
              className={styles.popupService__popupWrapper}
              onClick={stopPropagation}
              onMouseDown={stopPropagation}
            >
              <div className={styles.popupService__popup}>
                <Close
                  className={styles.popupService__close}
                  onClick={closeModal}
                />

                {isLoading && (
                  <Loader className={styles.popupService__loader} />
                )}
                <div className={styles.popupService__contentWrapper}>
                  <div className={styles.popupService__imgWrapper}>
                    {img?.length ? (
                      <img
                        src={img}
                        alt="service"
                        className={styles.popupService__img}
                      />
                    ) : (
                      <p className={styles.popupService__imgEmpty}>
                        Не удалось загрузить изображение
                      </p>
                    )}
                  </div>

                  <div className={styles.popupService__rightContent}>
                    <h3 className={styles.popupService__title}>{title}</h3>

                    <p className={styles.popupService__description}>
                      {description}
                    </p>

                    <InputDate
                      label="Дата"
                      value={date}
                      placeholder={"01.01.2023"}
                      errors={errorsDate}
                      onChange={changeDate}
                      className={styles.popupService__inputDate}
                      classNameInput={styles.popupService__input}
                    />

                    <Input
                      label={"Комментарий"}
                      value={comment}
                      placeholder={"Ваш комментарий"}
                      onChange={changeComment}
                      className={styles.popupService__inputComment}
                      maxLength={COMMENT_LENGTH}
                    />

                    <div className={styles.popupService__priceButtonWrapper}>
                      <span className={styles.popupService__price}>
                        {price} ₽
                      </span>

                      {errorsCommon.length > 0 && (
                        <span className={styles.popupService__error}>
                          {errorsCommon[0]}
                        </span>
                      )}

                      <Button
                        title={"Заказать"}
                        onClick={onClickOrder}
                        theme={"gold"}
                        className={styles.popupService__buttonOrder}
                        disabled={isLoading}
                        isDisabledStyle={isLoading || !date.length}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
