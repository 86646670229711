import { FC, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { MainNews } from "./MainNews/index";
import { NewsItem } from "./NewsItem";
import { Button } from "../../components/Button";
import { Footer } from "../../components/Footer";
import { Loader } from "../../components/Loader";
import { NoContent } from "../../components/NoContent";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../stores/Root";

import { DESCRIPTION_PAGE_DEFAULT, PAGE_TITLE_NEWS } from "../../constants";

import { EPageType } from "../../types";

import styles from "./styles/news.module.scss";

export const News: FC = observer(() => {
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore.storeNews));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.News
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_NEWS}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <div className={styles.container}>
      {helmet}
      {!storeUI.isLoading ? (
        storeUI.news?.length ? (
          <div className={styles.container__content}>
            {storeUI.news[0] && (
              <MainNews
                id={storeUI.news[0].id}
                created_at={storeUI.news[0].created_at}
                name={storeUI.news[0].name}
                image={storeUI.news[0].image}
                className={styles.container__mainNews}
              />
            )}
            <div className={styles.container__news}>
              {storeUI.news?.slice(1).map((item) => (
                <NewsItem
                  id={item.id}
                  created_at={item.created_at}
                  name={item.name}
                  image={item.image}
                  key={item.id}
                />
              ))}
            </div>
            {storeUI.total !== storeUI.news?.length && (
              <Button
                className={styles.container__button}
                title="ПОКАЗАТЬ ЕЩЁ"
                theme="transparent"
                onClick={storeUI.showMoreNews}
              />
            )}
            <Footer className={styles.container__footer} />
          </div>
        ) : (
          <div className={styles.noContent}>
            <NoContent
              title="Новостей пока нет"
              className={styles.noContent__empty}
            />
            <Footer className={styles.noContent__footer} />
          </div>
        )
      ) : (
        <div className={styles.container__empty}>
          <Loader />
        </div>
      )}
    </div>
  );
});
