import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../components/Button";

import { SCREENS } from "../../../navigation/endpoints";
import { fullDateFormat } from "../../../helpers";

import styles from "./styles/mainNews.module.scss";

interface IProps {
  id?: number;
  created_at: string;
  name: string;
  image: string;
  className?: string;
}

export const MainNews: FC<IProps> = ({
  id = 0,
  created_at = "",
  name = "",
  image = "",
  className = "",
}) => {
  const navigate = useNavigate();

  const _toNews = () => {
    navigate(`${SCREENS.SCREEN_NEWS}/${id}`);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.container__info}>
        <p className={styles.container__date}>{fullDateFormat(created_at)}</p>
        <h2 className={styles.container__title}>{name}</h2>
        <Button
          className={styles.container__button}
          title="ПОДРОБНЕЕ"
          theme="transparent"
          onClick={_toNews}
        />
      </div>
      <div className={styles.container__imgBlock}>
        <img className={styles.container__img} src={image} alt="main news" />
      </div>
    </div>
  );
};
