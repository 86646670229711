import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { addZeroToEndPrice, changeSpaceInNumber } from "../../../../../helpers";

import { FINANCE_STATUS } from "../../../../../constants";

import { IStoreUI } from "./interfaces";

import { Store } from "./../../../../../stores/types/index";
import { EChoosePayType, EFinanceItemStatus } from "../../../../../types";

export class StoreUI implements IStoreUI {
  storeRoot: Store.IRootStore;
  storeFinance: Store.IFinanceStore;
  storeChoosePay: Store.IChoosePay;
  navigate: NavigateFunction;

  isLoading: boolean = false;

  constructor(navigate: NavigateFunction, storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.navigate = navigate;
    this.storeRoot = storeRoot;
    this.storeFinance = storeRoot.storeFinance;
    this.storeChoosePay = storeRoot.storeModals.storeChoosePay;
  }

  // getters
  get balance() {
    let _balance = this.storeFinance.financeInfo?.balance || 0;
    return this.convertPrice(_balance);
  }

  get deposit() {
    let _deposit = this.storeFinance.financeInfo?.deposit || 0;
    return this.convertPrice(_deposit);
  }

  get financeItems() {
    console.log(this.storeFinance.financeInfo?.list);

    return (
      this.storeFinance.financeInfo?.list.map((item) => {
        return {
          billId: item.billId,
          number: item.number || "",
          dateFrom: item.date
            ? this.reverseAndReplaceSeparator(item.date, "-", ".")
            : "-- -- ----",
          dateTo: item.endDate
            ? this.reverseAndReplaceSeparator(item.endDate, "-", ".")
            : "-- -- ----",
          type: item.type,
          status: this.convertStatus(item.status),
          sum: this.convertPrice(item.sum) || "0",
          isPaid: item.status === EFinanceItemStatus.Paid,
        };
      }) || []
    );
  }

  // functions

  *init() {
    this.setIsLoading(true);
    yield this.storeFinance.getFinance();
    this.setIsLoading(false);
  }

  onPay(billId: number | null) {
    if (billId) {
      this.storeChoosePay.openChoosePay({
        id: billId,
        type: EChoosePayType.Base,
      });
    }
  }

  // ---- helpers
  convertPrice(price: number) {
    let priceWithSpace = changeSpaceInNumber(price);
    return price ? addZeroToEndPrice(priceWithSpace) : "0";
  }

  convertStatus(status: string) {
    switch (status) {
      case EFinanceItemStatus.Paid:
        return FINANCE_STATUS.PAID;
      case EFinanceItemStatus.Unpaid:
        return FINANCE_STATUS.UNPAID;
      case EFinanceItemStatus.PartiallyPaid:
        return FINANCE_STATUS.PARTIALLY_PAID;
      case EFinanceItemStatus.Empty:
        return FINANCE_STATUS.EMPTY;
      default:
        return "";
    }
  }

  reverseAndReplaceSeparator(
    str: string,
    fromSeparator: string,
    toSeparator: string
  ) {
    let charArr = str.split(fromSeparator);
    return charArr.reverse().join(toSeparator);
  }

  // setters
  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
