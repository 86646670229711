import { makeAutoObservable } from "mobx";

import { disableScroll, enableScroll } from "../../helpers/modalsFunc";
import { openPdf } from "../../helpers/openPdf";

import {
  PAYMENT_METHODS,
  PAYMENT_METHODS_INDIVIDUAL,
  TYPE_PERSON_INDIVIDUAL,
  TYPE_PERSON_LEGAL,
} from "../../constants";

import { Store } from "../types";
import { CallbackFunction, EChoosePayType, EPaymentMethods } from "../../types";

import { IPaymentMethod } from "../../interfaces/Payment";

export class ChoosePay implements Store.IChoosePay {
  storePayment: Store.IPayment;
  storeDocuments: Store.IDocumentsStore;
  storeUser: Store.IUser;

  visibleChoosePay: boolean = false;
  visibleChoosePayPawn: boolean = false;
  visibleChoosePayPdf: boolean = false;

  currentBillId: number | null = null;

  paymentMethods: IPaymentMethod[] = [];

  selectedPaymentMethod: IPaymentMethod = PAYMENT_METHODS[0];

  isSuccessPayment: boolean = false;
  isLoading: boolean = false;

  currentTypePopup: EChoosePayType | null = null;
  pdfLink: string = "";
  documentId: number | null = null;

  continueCallback: CallbackFunction = () => {};

  constructor(
    storePayment: Store.IPayment,
    storeDocuments: Store.IDocumentsStore,
    storeUser: Store.IUser
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storePayment = storePayment;
    this.storeDocuments = storeDocuments;
    this.storeUser = storeUser;
  }

  // getters
  get isAuth() {
    return this.storePayment.isAuth;
  }

  get paymentCreateErrors() {
    return this.storePayment.paymentCreateErrors;
  }

  get userType() {
    return this.storeUser?.profile.personalType;
  }

  // functions
  *onContinue() {
    if (this.currentBillId) {
      if (this.selectedPaymentMethod.method === EPaymentMethods.Bill) {
        if (!this.documentId) return;
        this.setIsLoading(true);
        yield this.onShowPdf(this.documentId);

        if (this.currentTypePopup) {
          this.closeChoosePay({ type: this.currentTypePopup });
        }

        this.setIsLoading(false);
      } else {
        this.setIsLoading(true);
        yield this.storePayment.createPayment(
          this.selectedPaymentMethod.method,
          this.currentBillId
        );
        this.setIsLoading(false);

        this.setIsSuccessPayment(this.storePayment.isSuccessPayment);
        this.continueCallback();
      }

      if (this.currentTypePopup && this.storePayment.isSuccessPayment) {
        this.closeChoosePay({ type: this.currentTypePopup });
      }
    }
  }

  changePaymentMethod(method: EPaymentMethods) {
    this.paymentMethods.forEach((item, ind, arr) => {
      if (item.method === method) {
        item.isSelected = true;
        this.setSelectedPaymentMethod(arr[ind]);
      } else {
        item.isSelected = false;
      }
    });
  }

  *onShowPdf(documentId: number) {
    let agreement: string = yield this.getBillData(documentId);
    openPdf(agreement);
  }

  *getBillData(id: number) {
    yield this.storeDocuments.getDocumentPdf(id);

    return this.storeDocuments?.documentBase64 || "";
  }

  getPaymentMethodsByUserType() {
    switch (this.userType) {
      case TYPE_PERSON_INDIVIDUAL:
        return PAYMENT_METHODS_INDIVIDUAL;
      case TYPE_PERSON_LEGAL:
        return PAYMENT_METHODS;
      default:
        return PAYMENT_METHODS_INDIVIDUAL;
    }
  }

  openChoosePay(params: {
    id: number;
    type: EChoosePayType;
    documentId?: number | null;
    pdfLink?: string;
  }) {
    this.setPaymentMethods(this.getPaymentMethodsByUserType());

    this.setCurrentTypePopup(params.type);
    this.setCurrentBillId(params.id);
    this.storePayment.clearPaymentCreateErrors();

    if (params.pdfLink?.length) {
      this.setPdfLink(params.pdfLink);
    }

    if (params.documentId) {
      this.setDocumentId(params.documentId);
    }

    this.toggleVisiblePopup(params.type, true);
    disableScroll();
  }

  closeChoosePay(params: { type: EChoosePayType }) {
    this.toggleVisiblePopup(params.type, false);
    this.changePaymentMethod(EPaymentMethods.YooKassa);
    this.setCurrentTypePopup(null);
    enableScroll();
  }

  toggleVisiblePopup(type: EChoosePayType, value: boolean) {
    switch (type) {
      case EChoosePayType.Base:
        this.setVisibleChoosePay(value);
        break;
      case EChoosePayType.Pawn:
        this.setVisibleChoosePayPawn(value);
        break;
      case EChoosePayType.WithPdf:
        this.setVisibleChoosePayPdf(value);
        break;
      default:
        this.setVisibleChoosePay(value);
    }
  }

  *checkIsAuth() {
    this.setIsLoading(true);

    yield this.storePayment.checkIsAuth();

    this.setIsLoading(false);
  }

  *loginSberBusiness() {
    this.setIsLoading(true);

    yield this.storePayment.loginSberBusiness();

    this.setIsLoading(false);
  }

  // setters
  setVisibleChoosePay(value: boolean) {
    this.visibleChoosePay = value;
  }

  setVisibleChoosePayPawn(value: boolean) {
    this.visibleChoosePayPawn = value;
  }

  setVisibleChoosePayPdf(value: boolean) {
    this.visibleChoosePayPdf = value;
  }

  setSelectedPaymentMethod(value: IPaymentMethod) {
    this.selectedPaymentMethod = value;
  }

  setCurrentBillId(value: number | null) {
    this.currentBillId = value;
  }

  setContinueCallback(value: CallbackFunction) {
    this.continueCallback = value;
  }

  setCurrentTypePopup(value: EChoosePayType | null) {
    this.currentTypePopup = value;
  }

  setIsSuccessPayment(value: boolean) {
    this.isSuccessPayment = value;
  }

  setPdfLink(value: string) {
    this.pdfLink = value;
  }

  setDocumentId(value: number | null) {
    this.documentId = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setPaymentMethods(value: IPaymentMethod[]) {
    this.paymentMethods = value;
  }
}
