import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";

import { Button } from "../../Button";
import { Loader } from "../../Loader";
import { Hint } from "../../Hint";
import { PaymentMethods } from "../../PaymentMethods";
import { ButtonSberBusiness } from "../../ButtonSberBusiness";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { INFO_PAYMENT } from "../../../constants";

import { IPaymentMethod } from "../../../interfaces/Payment";

import { EPaymentMethods } from "../../../types";

import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as PdfIcon } from "../../../assets/images/pdfIcon.svg";

import styles from "./styles/choosePay.module.scss";

interface IProps {
  visible: boolean;
  methods: IPaymentMethod[];
  errors?: string[];
  isLoading?: boolean;
  pdfLink?: string;
  pdfName?: string;
  pdfLinkTitle?: string;
  chooseMethod(method: EPaymentMethods): void;
  onContinue(): void;
  onClose(): void;
  checkIsAuth(): void;
  loginSberBusiness(): void;
  selectedPaymentMethod: IPaymentMethod;
  isAuth: boolean;
}

export const ChoosePay = observer(
  ({
    visible = false,
    methods = [],
    errors = [],
    isLoading = false,
    pdfLink = "",
    pdfName = "",
    pdfLinkTitle = "",
    chooseMethod = () => {},
    onContinue = () => {},
    onClose = () => {},
    loginSberBusiness = () => {},
    checkIsAuth = () => {},
    selectedPaymentMethod,
    isAuth = false,
  }: IProps) => {
    let selectedMethod = (method: EPaymentMethods) => {
      if (!isLoading) {
        chooseMethod(method);
        if (method === EPaymentMethods.SberBusiness) {
          checkIsAuth();
        }
      }
    };

    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        onClose();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    const checkAuthSber = useMemo(() => {
      return (
        selectedPaymentMethod.method === EPaymentMethods.SberBusiness && !isAuth
      );
    }, [selectedPaymentMethod, isAuth]);

    return (
      <>
        {visible && (
          <div
            className={styles.choosePay}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.choosePay__popupWrapper}>
              <div
                className={styles.choosePay__popup}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                <Close onClick={onClose} className={styles.choosePay__close} />
                <div className={styles.choosePay__content}>
                  {isLoading && <Loader className={styles.choosePay__loader} />}
                  <div className={styles.choosePay__titleMethodsWrapper}>
                    <div className={styles.choosePay__title}>
                      Выберите способ оплаты
                      <Hint
                        text={[INFO_PAYMENT.BILL]}
                        className={styles.choosePay__hint}
                        classNameIcon={styles.choosePay__hintIcon}
                        classNameText={styles.choosePay__hintText}
                      />
                    </div>

                    <PaymentMethods
                      methods={methods}
                      onSelectMethod={selectedMethod}
                    />
                  </div>
                  <div className={styles.choosePay__wrapperButton}>
                    {errors?.length !== 0 && (
                      <span className={styles.choosePay__error}>
                        {errors[0]}
                      </span>
                    )}
                    <Button
                      title="Продолжить"
                      type="submit"
                      theme="gold"
                      onClick={onContinue}
                      className={styles.choosePay__button}
                      disabled={checkAuthSber || isLoading}
                      isDisabledStyle={checkAuthSber || isLoading}
                    />

                    {checkAuthSber ? (
                      <ButtonSberBusiness
                        isLoading={isLoading}
                        loginSberBusiness={loginSberBusiness}
                        className={styles.choosePay__sberButton}
                      />
                    ) : null}

                    {pdfLink.length > 0 && (
                      <a
                        href={pdfLink}
                        download={pdfName}
                        className={styles.choosePay__linkBtn}
                      >
                        <span className={styles.choosePay__iconWrapper}>
                          <PdfIcon />
                        </span>
                        <p className={styles.choosePay__linkText}>
                          {pdfLinkTitle}
                        </p>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
