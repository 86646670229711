import { makeAutoObservable } from "mobx";

import { getFacilities, getRooms, getTerms } from "../../../api/Catalog";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { IFacilities, IRooms, ITerms } from "../../../interfaces/Catalog";

export class ModelFilters implements Store.ICatalogFilters {
  facilities: IFacilities[] = [];
  terms: ITerms[] = [];
  rooms: IRooms[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *getFacilities() {
    let response: TResponseApi<IFacilities[]> = yield getFacilities();
    if (response.isSuccess && response.data !== null) {
      this.setFacilities(response.data);
    }
  }

  *getTerms() {
    let response: TResponseApi<ITerms[]> = yield getTerms();
    if (response.isSuccess && response.data !== null) {
      this.setTerms(response.data);
    }
  }

  *getRooms() {
    let response: TResponseApi<IRooms[]> = yield getRooms();
    if (response.isSuccess && response.data !== null) {
      this.setRooms(response.data);
    }
  }

  setFacilities(facilities: IFacilities[]) {
    this.facilities = facilities;
  }

  setTerms(terms: ITerms[]) {
    this.terms = terms;
  }

  setRooms(rooms: IRooms[]) {
    this.rooms = rooms;
  }
}
