import { FC, useEffect, useState } from "react";

import { shortenString } from "../../../../helpers";

import { ReactComponent as StarActive } from "../../../../assets/images/starActive.svg";
import { ReactComponent as StarDisabled } from "../../../../assets/images/starDisabled.svg";
import defaultAvatar from "../../../../assets/images/defaultAvatar.png";

import styles from "./styles/review.module.scss";

interface IProps {
  avatar: string | null;
  name: string;
  rating: number;
  text: string;
  className?: string;
}

export const Review: FC<IProps> = ({
  avatar = "",
  name = "",
  rating = 0,
  text = "",
}) => {
  const [description, setDescription] = useState("");
  const [visibleButton, setVisibleButton] = useState(false);
  const countLetters = 250;

  useEffect(() => {
    let showButtonMore = () => setVisibleButton(true);
    let result = shortenString(text, countLetters, showButtonMore);
    setDescription(result);
  }, [text]);

  const showText = () => {
    setDescription(text);
    setVisibleButton(false);
  };

  return (
    <div className={styles.review}>
      <div className={styles.review__header}>
        <div className={styles.review__imgWrapper}>
          {avatar ? (
            <img className={styles.review__img} src={avatar} alt="avatar" />
          ) : (
            <img
              className={styles.review__img}
              src={defaultAvatar}
              alt="avatar"
            />
          )}
        </div>
        <div>
          <h3>{name}</h3>
          <span className={styles.review__stars}>
            {[...Array(5)].map((_, index) =>
              rating <= index ? (
                <StarDisabled key={index} />
              ) : (
                <StarActive key={index} />
              )
            )}
          </span>
        </div>
      </div>

      <p className={styles.review__text}>
        {description}
        {visibleButton && (
          <button className={styles.review__readMoreButton} onClick={showText}>
            Читать больше
          </button>
        )}
      </p>
    </div>
  );
};
