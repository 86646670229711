import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Agreement } from "./Agreement";
import { Loader } from "../../../../../components/Loader";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../../../stores/Root";

import styles from "./styles/agreements.module.scss";

export interface IProps {
  bookingId: number;
  isArchiveMode?: boolean;
}

export const Agreements = observer(
  ({ bookingId = 0, isArchiveMode = false }: IProps) => {
    const rootStore = useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(rootStore));

    useEffect(() => {
      (async () => {
        await storeUI.init(bookingId);
      })();
    }, [storeUI, bookingId]);

    return (
      <div className={styles.content}>
        {storeUI.generatedAgreements?.length ||
        storeUI.uploadedAgreements?.length ? (
          <>
            {storeUI.isMainLoading ? (
              <div className={styles.content__agreementsEmpty}>
                <Loader className={styles.content__loader} />
              </div>
            ) : (
              <div className={styles.content__agreements}>
                {storeUI.uploadedAgreements?.map((agreement) => (
                  <Agreement
                    key={agreement.id}
                    id={agreement.id}
                    isUploaded={agreement.isUploaded}
                    status={agreement.status}
                    numberDocument={agreement.numberDocument}
                    onShowAgreement={storeUI.onShowPdf}
                    isLoadingDownload={
                      agreement.id === storeUI.currentLoadingId
                    }
                    onClickDownload={storeUI.onDownloadPdf}
                  />
                ))}

                {storeUI.generatedAgreements?.map((agreement) => (
                  <Agreement
                    key={agreement.id}
                    id={agreement.id}
                    status={agreement.status}
                    numberDocument={agreement.numberDocument}
                    onShowAgreement={storeUI.onShowPdf}
                    isLoadingDownload={
                      agreement.id === storeUI.currentLoadingId
                    }
                    onClickDownload={storeUI.onDownloadPdf}
                    isUploaded={agreement.isUploaded}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <span className={styles.content__empty}>
            {isArchiveMode ? "У вас нет договоров" : "У вас пока нет договоров"}
          </span>
        )}
      </div>
    );
  }
);
