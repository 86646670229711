import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { enableScroll } from "../../../../helpers/modalsFunc";

import { SCREENS } from "../../../../navigation/endpoints";

import { Store } from "../../../../stores/types";

import { IStoreUI } from "./interfaces";

export class StoreUI implements IStoreUI {
  storeNews: Store.IAllNews;
  navigate: NavigateFunction;
  isLoading: boolean = false;

  constructor(storeNews: Store.IAllNews, navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeNews = storeNews;
    this.navigate = navigate;
  }

  get newsDetails() {
    return this.storeNews.newsDetails;
  }

  get newsParagraphs() {
    return this.storeNews.newsDetails.text.split("\n");
  }

  *init(newsId: number) {
    enableScroll();
    this.setIsLoading(true);
    yield this.storeNews.getNewsDetails(newsId);
    this.setIsLoading(false);
  }

  toNews() {
    this.navigate(SCREENS.SCREEN_NEWS);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
