import { Map, YMaps, Placemark } from "react-yandex-maps";

import styles from "./styles/index.module.scss";

interface IProps {
  longitude?: number;
  latitude?: number;
  className?: string;
}

const MainMap = ({ className = "", longitude = 0, latitude = 0 }: IProps) => {
  return (
    <>
      <YMaps>
        <Map
          className={`${styles.mainMap} ${className}`}
          defaultState={{ center: [longitude!, latitude!], zoom: 17 }}
        >
          <Placemark
            geometry={[longitude!, latitude!]}
            options={{
              iconColor: "red",
            }}
          />
        </Map>
      </YMaps>
    </>
  );
};

export { MainMap };
