import { ApiManager } from "../index";

import { BOOKING_APARTMENT, FLOOR_PLAN_BY_ID } from "../../constants/api";

import {
  IBlockedApartment,
  IBlockedApartmentsRequest,
  IFloorPlanApartment,
} from "../../interfaces/Floor";

import { TResponseApi } from "../types";

export async function getFloorPlan(
  floorNumber: number
): Promise<TResponseApi<IFloorPlanApartment[]>> {
  return await ApiManager.request<IFloorPlanApartment[]>({
    method: "GET",
    url: FLOOR_PLAN_BY_ID(floorNumber),
  });
}

export async function getBlockedApartments(
  params: IBlockedApartmentsRequest
): Promise<TResponseApi<IBlockedApartment[]>> {
  return await ApiManager.request<IBlockedApartment[]>({
    method: "GET",
    url: BOOKING_APARTMENT,
    params: params,
  });
}
