import { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../../components/Button";
import { SelectDate } from "../../../../components/DatePicker";
import { Counter } from "./Counter";
import { Loader } from "../../../../components/Loader";

import { changeSpaceInNumber } from "../../../../helpers";

import { IDatesInterval } from "../../../../interfaces";

import {
  TEXT_DATE_IN,
  TEXT_DATE_OUT,
  TEXT_SELECT_DATE_IN,
  TEXT_SELECT_DATE_OUT,
} from "../../../../constants";

import styles from "./styles/booking.module.scss";

interface IProps {
  startDay: Date | null;
  endDay: Date | null;
  minEndDate: Date | null;
  price: number;
  adultsCount: number;
  chlidrenCount: number;
  petsCount: number;
  isLoading: boolean;
  className?: string;
  errors: string[];
  disabledDates?: IDatesInterval[];
  startDate?: Date | null;
  isDisableMaxAdults?: boolean;
  isDisableMaxChildren?: boolean;
  isDisableMaxPets?: boolean;
  refBlock?: React.LegacyRef<HTMLDivElement> | undefined;
  withPets: boolean;
  checkinDate: string | null;
  isBusy: boolean;
  addToBasket(): void;
  changeCountAdults(value: number): void;
  changeCountChildren(value: number): void;
  changeCountPets(value: number): void;
  changeStartDay(date: Date): void;
  changeEndDay(date: Date): void;
  getIsActiveDay(date: Date): boolean;
}

export const Booking: FC<IProps> = observer(
  ({
    adultsCount = 1,
    chlidrenCount = 0,
    petsCount = 0,
    startDay = null,
    endDay = null,
    minEndDate = null,
    className = "",
    price = 0,
    isLoading = false,
    errors = [],
    disabledDates = [],
    startDate = null,
    isDisableMaxAdults = false,
    isDisableMaxChildren = false,
    isDisableMaxPets = false,
    withPets = false,
    checkinDate = null,
    isBusy = false,
    addToBasket = () => false,
    changeCountAdults = () => {},
    changeCountChildren = () => {},
    changeCountPets = () => {},
    changeStartDay = () => {},
    changeEndDay = () => {},
    getIsActiveDay = () => true,
    refBlock,
  }) => {
    const [arrival, setArrival] = useState<boolean>(false);
    const [leaving, setLeaving] = useState<boolean>(false);

    return (
      <div className={`${styles.container} ${className}`} ref={refBlock}>
        <div className={styles.container__content}>
          <p className={styles.container__title}>Бронирование</p>
          {isLoading && <Loader />}
          <div className={styles.container__countersDateWrapper}>
            <div className={styles.container__counters}>
              <Counter
                title="Взрослые"
                subtitle="Старше 18 лет"
                persons={adultsCount}
                setPersons={changeCountAdults}
                minPersons={1}
                isDisableMax={isDisableMaxAdults}
                className={styles.container__counterFirst}
              />
              <Counter
                title="Дети"
                subtitle="от 0 до 18 лет"
                persons={chlidrenCount}
                setPersons={changeCountChildren}
                minPersons={0}
                isDisableMax={isDisableMaxChildren}
              />

              {withPets && (
                <Counter
                  title="Питомцы"
                  subtitle="Домашние животные"
                  persons={petsCount}
                  setPersons={changeCountPets}
                  minPersons={0}
                  isDisableMax={isDisableMaxPets}
                />
              )}
            </div>
            <div className={styles.container__duration}>
              <SelectDate
                currentDay={startDay}
                changeCurrentDay={changeStartDay}
                type="book"
                isShowModal={arrival}
                setIsShowModal={setArrival}
                placeholder={TEXT_DATE_IN}
                title={TEXT_SELECT_DATE_IN}
                timing={true}
                minDate={startDate}
                disabledDates={disabledDates}
                classNameWrapper={styles.container__datepickerTop}
              />
              <SelectDate
                currentDay={endDay}
                changeCurrentDay={changeEndDay}
                type="book"
                isShowModal={leaving}
                setIsShowModal={setLeaving}
                placeholder={TEXT_DATE_OUT}
                title={TEXT_SELECT_DATE_OUT}
                timing={true}
                minDate={minEndDate}
                disabledDates={disabledDates}
                classNameWrapper={styles.container__datepicker}
                getIsActiveDay={getIsActiveDay}
              />
              {errors.length !== 0 && (
                <span className={styles.container__error}>{errors[0]}</span>
              )}

              {checkinDate && !isBusy && (
                <span className={styles.container__checkinDate}>
                  Ближайшая дата заселения {checkinDate}
                </span>
              )}
            </div>
          </div>
          <div className={styles.container__footer}>
            <p className={styles.container__price}>
              {changeSpaceInNumber(price)} ₽ / мес.
            </p>
            <Button
              className={`${styles.container__button} ${
                (!startDay || !endDay || isLoading) &&
                styles.container__button_disabled
              }`}
              title="ЗАБРОНИРОВАТЬ"
              theme="gold"
              disabled={isLoading}
              onClick={addToBasket}
            />
          </div>
        </div>
      </div>
    );
  }
);
