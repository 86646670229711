import { makeAutoObservable } from "mobx";

import { ETextMainId } from "../../../../constants";

import { Store } from "../../../../stores/types";

import { IStoreUI } from "./interfaces";

export class StoreUI implements IStoreUI {
  storeRoot: Store.IRootStore;

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
  }

  // getters

  get content() {
    if (this.storeRoot.mainTextBlocks?.length) {
      return (
        this.storeRoot.mainTextBlocks.find(
          (block) => block.id === ETextMainId.SecondBlockId
        )?.text || `Не удалось загрузить контент`
      );
    }
    return `Не удалось загрузить контент`;
  }
}
