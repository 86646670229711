import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { FC, useContext, useEffect, useState } from "react";

import { AddPersonBtn } from "../../../../components/AddPersonBtn";
import { Button } from "../../../../components/Button";
import { Resident } from "./Resident";
import { Empty } from "../../../../components/Empty";
import { Loader } from "../../../../components/Loader";
import { ModalReuse } from "../../../../components/Modals/ModalReuse";
import { Tabs } from "../../../../components/Tabs";
import { PetForm } from "../../../OrderingData/PetForm";

import { IOptionCommon } from "../../../../interfaces";
import { ITypes } from "../../../../interfaces/Catalog";

import { EFormType, ETabsResidents } from "../../../../types";

import { RootContext } from "../../../../stores/Root";
import { StoreUI } from "./storeUI";

import { ReactComponent as Lock } from "../../../../assets/images/lock.svg";
import { ReactComponent as TenantsEmpty } from "../../../../assets/images/tenantsEmpty.svg";

import styles from "./styles/residents.module.scss";

export const Residents: FC = observer(() => {
  let params = useParams();
  let splatParams = params["*"];

  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(
    () => new StoreUI(rootStore, navigate, params.tabName ?? "")
  );

  useEffect(() => {
    (async () => {
      await storeUI.init(params.id ?? "");
    })();
  }, [storeUI, params.id]);

  return !storeUI.isLoading ? (
    storeUI.renter ? (
      <div className={styles.residents}>
        {storeUI.canArrivalDate ? (
          <div className={styles.residents__content}>
            <Tabs
              className={styles.residents__tabs}
              onClick={storeUI.onClickTab}
              bookingId={params.id ?? null}
              activeTabName={splatParams ?? null}
              tabs={storeUI.tabs}
              isButtonMode={true}
            />

            <div className={styles.residents__tabPageWrapper}>
              {splatParams && splatParams === ETabsResidents.Renter && (
                <div className={styles.residents__list}>
                  {storeUI.renter && (
                    <Resident
                      lastName={storeUI.renter.lastName}
                      firstName={storeUI.renter.firstName}
                      middleName={storeUI.renter.middleName}
                      personalType={storeUI.personalType}
                      phone={storeUI.renter.phone}
                      placeBirth={storeUI.renter.placeBirth}
                      subdivisionCode={storeUI.renter.subdivisionCode}
                      series={storeUI.renter.series}
                      number={storeUI.renter.number}
                      dateIssue={storeUI.renter.dateIssue}
                      dateBirth={storeUI.renter.birthdate}
                      issuedBy={storeUI.renter.issuedBy}
                      gender={storeUI.renter.gender}
                      genderOptions={storeUI.genderOptions}
                      registrationAddress={storeUI.renter.registrationAddress}
                      isReadonly={storeUI.isDisabledAll}
                      isRenterMode={true}
                    />
                  )}
                </div>
              )}

              {splatParams && splatParams === ETabsResidents.Tenants && (
                <div>
                  {storeUI.tenants?.length > 0 ? (
                    <>
                      <div className={styles.residents__list}>
                        {storeUI.tenants?.map((tenant, index) => {
                          let _countTenant = index + 1;
                          return (
                            <Resident
                              key={index}
                              countTenant={_countTenant}
                              lastName={tenant.lastName}
                              firstName={tenant.firstName}
                              middleName={tenant.middleName}
                              phone={tenant.phone}
                              series={tenant.series}
                              number={tenant.number}
                              dateIssue={tenant.dateIssue}
                              dateBirth={tenant.birthdate}
                              issuedBy={tenant.issuedBy}
                              gender={tenant.gender}
                              relationTenant={tenant.relationTenant}
                              genderOptions={storeUI.genderOptions}
                              subdivisionCode={tenant.subdivisionCode}
                              placeBirth={tenant.placeBirth}
                              errorsPlaceBirth={
                                tenant.validateErrors.placeBirth
                              }
                              errorsSubdivisionCode={
                                tenant.validateErrors.subdivisionCode
                              }
                              registrationAddress={tenant.registrationAddress}
                              errorsPhone={tenant.validateErrors.phone}
                              errorsFirstName={tenant.validateErrors.firstName}
                              errorsLastName={tenant.validateErrors.lastName}
                              errorsDateBirth={tenant.validateErrors.birthdate}
                              errorsSeries={tenant.validateErrors.series}
                              errorsNumberPassport={
                                tenant.validateErrors.number
                              }
                              errorsDateIssue={tenant.validateErrors.dateIssue}
                              errorsIssuedBy={tenant.validateErrors.issuedBy}
                              errorsGender={tenant.validateErrors.gender}
                              openDeleteModal={() =>
                                storeUI.openDeleteTenantModal(
                                  tenant.idTenant,
                                  tenant.localId
                                )
                              }
                              errorsRegistrationAddress={
                                tenant.validateErrors.registrationAddress
                              }
                              changeLastName={(value: string) =>
                                storeUI.changeLastName(value, tenant.localId)
                              }
                              changeFirstName={(value: string) =>
                                storeUI.changeFirstName(value, tenant.localId)
                              }
                              changeMiddleName={(value: string) =>
                                storeUI.changeMiddleName(value, tenant.localId)
                              }
                              changePhone={(value: string) =>
                                storeUI.changePhone(value, tenant.localId)
                              }
                              changeDateBirth={(value: string) =>
                                storeUI.changeDateBirth(value, tenant.localId)
                              }
                              changeSeries={(value: string) =>
                                storeUI.changeSeries(value, tenant.localId)
                              }
                              changeNumberPassport={(value: string) =>
                                storeUI.changeNumberPassport(
                                  value,
                                  tenant.localId
                                )
                              }
                              changeDateIssue={(value: string) =>
                                storeUI.changeDateIssue(value, tenant.localId)
                              }
                              changeIssuedBy={(value: string) =>
                                storeUI.changeIssuedBy(value, tenant.localId)
                              }
                              changeRegistrationAddress={(value: string) =>
                                storeUI.changeAddress(value, tenant.localId)
                              }
                              changePlaceBirth={(value: string) =>
                                storeUI.changePlaceBirth(value, tenant.localId)
                              }
                              changeRelationTenant={(value: string) =>
                                storeUI.changeRelationTenant(
                                  value,
                                  tenant.localId
                                )
                              }
                              changeSubdivisionCode={(value: string) =>
                                storeUI.changeSubdivisionCode(
                                  value,
                                  tenant.localId
                                )
                              }
                              changeGender={(value: ITypes) =>
                                storeUI.changeGender(value, tenant.localId)
                              }
                              hintAddressStrings={tenant.hintStringsAddress}
                              hintStringsPlaceBirth={
                                tenant.hintStringsPlaceBirth
                              }
                              onClickHintAddress={(value: string) =>
                                storeUI.onClickHintAddress(
                                  value,
                                  EFormType.Tenant,
                                  tenant.localId
                                )
                              }
                              onClickHintPlaceBirth={(value: string) =>
                                storeUI.onClickHintPlaceBirth(
                                  value,
                                  EFormType.Tenant,
                                  tenant.localId
                                )
                              }
                              personalType={storeUI.personalType}
                              isReadonly={storeUI.isDisabledAll}
                              isTenantMode={true}
                            />
                          );
                        })}

                        {storeUI.isLoadingSend && <Loader />}
                      </div>
                      {!storeUI.isDisabledAll && (
                        <div
                          className={`${styles.residents__buttons} ${
                            !storeUI.isVisibleAdded &&
                            styles.residents__buttons_oneBtnMode
                          }`}
                        >
                          {storeUI.isVisibleAdded && (
                            <AddPersonBtn
                              title={"Добавить жильца"}
                              onClick={storeUI.onClickAddTenant}
                              theme="white"
                              className={styles.residents__addPersonBtn}
                              isDisabledStyle={
                                storeUI.disabledAddTenant ||
                                storeUI.isDisabledAll
                              }
                              disabled={
                                storeUI.isDisabledAll || storeUI.isLoadingSend
                              }
                            />
                          )}

                          {storeUI.tenantsErrors.length > 0 && (
                            <span
                              className={`${styles.residents__error} ${styles.residents__error_tenants}`}
                            >
                              {storeUI.tenantsErrors[0]}
                            </span>
                          )}

                          <Button
                            theme="gold"
                            title="Сохранить"
                            onClick={() =>
                              storeUI.onClickSendAllTenants(
                                Number(params.id) ?? 0
                              )
                            }
                            className={styles.residents__saveBtn}
                            disabled={storeUI.isLoadingSend}
                            isDisabledStyle={storeUI.isLoadingSend}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className={styles.residents__tenantsEmpty}>
                      <TenantsEmpty />
                      <h3 className={styles.residents__tenantsEmptyText}>
                        {storeUI.isDisabledAll
                          ? "Жильцы не были добавлены при заключении договора"
                          : "Здесь будет информация о жильцах"}
                      </h3>

                      {!storeUI.isDisabledAll && (
                        <AddPersonBtn
                          title={"Добавить жильца"}
                          onClick={storeUI.onClickAddTenant}
                          theme="white"
                          className={styles.residents__tenantsEmptyAddButton}
                          isDisabledStyle={
                            storeUI.disabledAddTenant || storeUI.isDisabledAll
                          }
                          disabled={
                            storeUI.isDisabledAll || storeUI.isLoadingSend
                          }
                        />
                      )}

                      {storeUI.tenantsErrors.length > 0 && (
                        <span
                          className={`${styles.residents__error} ${styles.residents__error_emptyTenants}`}
                        >
                          {storeUI.tenantsErrors[0]}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}

              {splatParams && splatParams === ETabsResidents.Pets && (
                <>
                  {storeUI.pets?.map((pet) => (
                    <PetForm
                      className={styles.residents__pet}
                      valueBreed={pet.breed}
                      valueType={pet.type}
                      valueVetPassport={pet.veterinaryPassport}
                      vetPassportOptions={storeUI.veterinaryOptions}
                      errorsType={[]}
                      errorsBreed={[]}
                      errorsVetPassport={[]}
                      isDisabled={true}
                      isTenantsMode={true}
                      // TODO: сделать необязательными если нельзя будет редактировать животное
                      changeType={function (value: string): void {
                        throw new Error("Function not implemented.");
                      }}
                      changeBreed={function (value: string): void {
                        throw new Error("Function not implemented.");
                      }}
                      changeVetPassport={function (value: IOptionCommon): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.residents__messageBlock}>
            <p className={styles.residents__messageBlockText}>
              Заполните информацию о брони и выберите дату заселения
            </p>
            <Button
              title={"Перейти к заполнению"}
              onClick={storeUI.toOrderingData}
              theme={"gold"}
            />
          </div>
        )}
        <ModalReuse
          isShowModal={storeUI.isModalSave}
          setIsShowModal={storeUI.closeSaveModal}
          btnTitle="Хорошо"
          type="button"
        >
          <h2>Изменения сохранены</h2>
        </ModalReuse>
        <ModalReuse
          isShowModal={storeUI.isDeleteAddTenant}
          setIsShowModal={storeUI.closeDeleteTenantModal}
          onClick={() =>
            storeUI.onClickDeleteTenant(
              storeUI.currentTenantId.tenantId,
              storeUI.currentTenantId.localId,
              params.id ?? "0"
            )
          }
          btnLeft="да"
          btnRight="нет"
          type="buttons"
          isLoading={storeUI.isLoading}
        >
          <h2>Вы уверены, что хотите удалить жильца?</h2>
        </ModalReuse>
      </div>
    ) : (
      <div className={styles.residents__empty}>
        <Empty
          title="Забронируйте квартиру"
          titleBtn="ВЫБРАТЬ КВАРТИРУ"
          Icon={Lock}
          onClick={storeUI.toApartments}
        />
      </div>
    )
  ) : (
    <Loader />
  );
});
