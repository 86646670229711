import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect, useState, useContext, useMemo } from "react";

import { Tabs } from "../../../../components/Tabs";
import { Agreements } from "./Agreements";
import { Bills } from "./Bills";
import { Acts } from "./Acts";
import { Loader } from "../../../../components/Loader";
import { DocumentOthers } from "./DocumentOthers";

import { StoreUI } from "./storeUI";

import { ETabsDocuments } from "../../../../types";

import { RootContext } from "../../../../stores/Root";

import styles from "./styles/documents.module.scss";

export const Documents: FC = observer(() => {
  let params = useParams();
  let splatParams = params["*"];

  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(navigate, params, rootStore));

  useEffect(() => {
    (async () => {
      await storeUI.init(params.id ?? "");
    })();
  }, [storeUI, params.id]);

  let activeTabPage = useMemo(() => {
    switch (splatParams) {
      case ETabsDocuments.Agreements:
        return <Agreements bookingId={Number(params.id)} />;
      case ETabsDocuments.Bills:
        return <Bills bookingId={Number(params.id)} />;
      case ETabsDocuments.Acts:
        return <Acts bookingId={Number(params.id)} />;
      case ETabsDocuments.Others:
        return <DocumentOthers bookingId={Number(params.id)} />;
      default:
        return "";
    }
  }, [params.id, splatParams]);

  return (
    <div className={styles.documents}>
      <Tabs
        bookingId={params.id ?? null}
        activeTabName={splatParams ?? null}
        tabs={storeUI.tabs}
        onClick={storeUI.onClickTab}
        isButtonMode={true}
        className={styles.documents__tabs}
      />

      {storeUI.isLoading ? (
        <Loader className={styles.documents__loader} />
      ) : (
        <>{activeTabPage}</>
      )}
    </div>
  );
});
