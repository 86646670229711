import { FC } from "react";

import { ReactComponent as Velko } from "../../assets/images/noContent.svg";

import styles from "./styles/noContent.module.scss";

interface IProps {
  title: string;
  className?: string;
}

export const NoContent: FC<IProps> = ({ title = "", className = "" }) => {
  return (
    <div className={`${styles.content} ${className}`}>
      <Velko className={styles.content__img} />
      <span className={styles.content__title}>{title}</span>
    </div>
  );
};
