import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../components/Button";

import { SCREENS } from "../../../navigation/endpoints";
import { fullDateFormat } from "../../../helpers";

import styles from "./styles/newsItem.module.scss";

interface IProps {
  id?: number;
  created_at: string;
  name: string;
  image: string;
}

export const NewsItem: FC<IProps> = observer(
  ({ id = 0, created_at = "", name = "", image = "" }) => {
    const navigate = useNavigate();

    const _toNews = () => {
      navigate(`${SCREENS.SCREEN_NEWS}/${id}`);
    };

    return (
      <div className={styles.news}>
        <div className={styles.news__imgBlock}>
          <img className={styles.news__img} src={image} alt="news" />
        </div>
        <div className={styles.news__content}>
          <p className={styles.news__date}>{fullDateFormat(created_at)}</p>
          <h3 className={styles.news__title}>{name}</h3>
          <Button
            className={styles.news__button}
            title="Подробнее"
            theme="transparent"
            onClick={_toNews}
          />
        </div>
      </div>
    );
  }
);
