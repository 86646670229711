import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { MIN_COUNT_TENANTS_OF_APARTMENT } from "../../../../constants";

import { SCREENS } from "../../../../navigation/endpoints";

import { Store } from "./../../../../stores/types/index";
import { IStoreUI } from "./interfaces";

export class StoreUI implements IStoreUI {
  storeCatalog: Store.ICatalog;
  navigate: NavigateFunction;
  isLoading: boolean = true;

  constructor(navigate: NavigateFunction, storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeCatalog = storeRoot.storeCatalog;
    this.navigate = navigate;
  }

  // getters

  get apartments() {
    return this.storeCatalog.apartments?.map((apartment) => {
      return {
        id: apartment.id,
        image: apartment.image,
        square: String(apartment.square),
        maxPeople: this.getMaxPeopleString(apartment.capacity),
        minPeople: String(MIN_COUNT_TENANTS_OF_APARTMENT),
        pets: apartment.pets,
        flatNumber: apartment.flatNumber,
        floor: String(apartment.floor),
        price: apartment.price,
      };
    });
  }

  // functions

  *init() {
    this.setIsLoading(true);
    yield this.storeCatalog.getOurFlats();
    this.setIsLoading(false);
  }

  getMaxPeopleString(capacity: number) {
    return capacity > 1 ? String(capacity) : "";
  }

  toApartments() {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
