import { makeAutoObservable, toJS } from "mobx";

import {
  IFacilities,
  IFiltersPopup,
  IRooms,
  ISorting,
  ITerms,
  ITypes,
} from "../../../interfaces/Catalog";
import { ICheckbox } from "./../../../interfaces/index";
import { ICatalogStoreUI, IFiltersLocalStorage } from "./interfaces";

import { ESort } from "../../../types";
import { Store } from "./../../../stores/types";

import {
  ERROR_FILTERS_PRICES,
  ERROR_FILTERS_PRICES_ZERO,
  FILTERS,
  FLOORS_SELECT,
  FLOOR_SELECT_ALL,
  MIN_COUNT_TENANTS_OF_APARTMENT,
  ROOMS_SELECT_ALL,
} from "../../../constants";

import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";

export class StoreUI implements ICatalogStoreUI {
  storeCatalog: Store.ICatalog;
  storeFilters: IFiltersPopup;
  isLoading: boolean = true;
  countApartaments: number | null = null;
  fromPrice: string = "";
  toPrice: string = "";
  floor: ITypes | null = null;
  rooms: IRooms | null = null;
  sort: ESort | null = ESort.PriceDown;
  isCheckedAvailable: boolean = true;

  terms: ICheckbox[] = [];
  facilities: ICheckbox[] = [];

  selectedTermsId: number[] = [];
  selectedFacilitiesId: number[] = [];

  errorsPrices: string[] = [];

  isSubmit: boolean = false;
  prevTotal: number = 0;
  sortList: ISorting[] = [
    {
      value: "сначала недорогие",
      type: ESort.PriceGrow,
    },
    {
      value: "сначала дорогие",
      type: ESort.PriceDown,
    },
  ];
  sortSelected: ISorting = {
    value: "сначала недорогие",
    type: ESort.PriceGrow,
  };

  isVisiblePopupFilters: boolean = false;
  isVisiblePopupText: boolean = false;

  floors = FLOORS_SELECT;

  constructor(rootStore: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeCatalog = rootStore.storeCatalog;
    this.storeFilters = rootStore.storeFilters;
  }

  get paginateData() {
    return this.storeCatalog.paginateData;
  }

  get apartments() {
    return this.storeCatalog.apartments?.map((apartment) => {
      return {
        id: apartment.id,
        image: apartment.image,
        square: String(apartment.square),
        maxPeople: this.getMaxPeopleString(apartment.capacity),
        minPeople: String(MIN_COUNT_TENANTS_OF_APARTMENT),
        pets: apartment.pets,
        flatNumber: apartment.flatNumber,
        floor: String(apartment.floor),
        price: apartment.price,
      };
    });
  }

  get countFilters() {
    return this.selectedFacilitiesId?.concat(this.selectedTermsId).length;
  }

  *init() {
    enableScroll();
    this.setIsLoading(true);

    yield this.storeFilters.getFacilities();
    yield this.storeFilters.getTerms();
    this.setFacilities(this.storeFilters.facilities);
    this.setTerms(this.storeFilters.terms);

    // если есть фильтры в локал сторадж, подставляем их
    this.writeFiltersFromLocalStorage();

    yield this.getApartaments(1);
    this.setIsLoading(false);
  }

  *getApartaments(pageNumber: number) {
    if (this.validateFilters()) {
      let _params = {
        sort: this.sortSelected.type || ESort.PriceGrow,
        floor: this.floor?.id || null,
        rooms: this.rooms?.id || null,
        fromPrice: Number(this.fromPrice) || null,
        toPrice: Number(this.toPrice) || null,
        facilities: toJS(this.selectedFacilitiesId),
        terms: toJS(this.selectedTermsId),
        available: this.isCheckedAvailable,
      };

      this.setIsLoading(true);
      yield this.storeCatalog.getApartments(_params, pageNumber);
      this.setIsSubmit(true);
      this.setPrevTotal(this.storeCatalog.paginateData.total);

      if (this.isVisiblePopupFilters) {
        this.closePopupFilters();
      }
      this.setIsLoading(false);
    }
  }

  *getApartamentsForCounter() {
    if (this.validateFilters()) {
      let _params = {
        sort: this.sortSelected.type || ESort.PriceGrow,
        floor: this.floor?.id || null,
        rooms: this.rooms?.id || null,
        fromPrice: Number(this.fromPrice) || null,
        toPrice: Number(this.toPrice) || null,
        facilities: toJS(this.selectedFacilitiesId),
        terms: toJS(this.selectedTermsId),
        available: this.isCheckedAvailable,
      };

      yield this.storeCatalog.getApartamentsForCounter(_params);
      this.setCountApartaments(this.storeCatalog.paginateData.total);
      this.setIsSubmit(false);
    }
  }

  onSaveFacilities() {
    this.getApartamentsForCounter();
    if (this.isVisiblePopupFilters) {
      this.closePopupFilters();
    }
  }

  getMaxPeopleString(capacity: number) {
    return capacity > 1 ? String(capacity) : "";
  }

  removeItemFromArray(id: number, arr: number[]) {
    let myIndex = arr.indexOf(id);
    if (myIndex !== -1) {
      arr.splice(myIndex, 1);
    }
  }

  *clearAllFilters() {
    this.setFromPrice("");
    this.setToPrice("");
    this.setFloor(null);
    this.setRooms(null);
    this.setSelectedFacilitiesId([]);
    this.setSelectedTermsId([]);
    this.facilities.forEach((item) => (item.isSelected = false));
    this.terms.forEach((item) => (item.isSelected = false));
    this.removeFiltersFromLocalStorage();

    yield this.getApartamentsForCounter();
  }

  toggleSelect(
    arrayObjects: ICheckbox[],
    arrayId: number[],
    elemId: number,
    addIdToArrayId: (id: number) => void
  ) {
    // индекс в массиве объектов счетчиков
    let elemInd = arrayObjects.findIndex((item) => item.id === elemId);

    // индекс id в массиве выбранных id
    let elemIndexOfIdArray = arrayId.indexOf(elemId);

    if (elemIndexOfIdArray >= 0) {
      this.removeItemFromArray(elemId, arrayId);
      arrayObjects[elemInd].isSelected = false;
    } else {
      addIdToArrayId(arrayObjects[elemInd].id);
      arrayObjects[elemInd].isSelected = true;
    }
  }

  toggleSelectFacilities(id: number) {
    this.toggleSelect(
      this.facilities,
      this.selectedFacilitiesId,
      id,
      this.addSelectedFacilitiesId
    );
  }

  toggleSelectFacilitiesAdaptive(id: number) {
    this.toggleSelectFacilities(id);
    this.getApartamentsForCounter();
  }

  toggleSelectTerms(id: number) {
    this.toggleSelect(
      this.terms,
      this.selectedTermsId,
      id,
      this.addSelectedTermsId
    );
  }

  toggleSelectTermsAdaptive(id: number) {
    this.toggleSelectTerms(id);
    this.getApartamentsForCounter();
  }

  addSelectedFacilitiesId(value: number) {
    this.selectedFacilitiesId = [...this.selectedFacilitiesId, value];
  }

  addSelectedTermsId(value: number) {
    this.selectedTermsId = [...this.selectedTermsId, value];
  }

  changeFromPrice(value: string) {
    // приводить к числу, чтобы убрать несколько нулей из начала, если их ввели
    let _value = value ? Number(value) : "";
    this.setFromPrice(String(_value));
  }

  changeToPrice(value: string) {
    // приводить к числу, чтобы убрать несколько нулей из начала, если их ввели
    let _value = value ? Number(value) : "";
    this.setToPrice(String(_value));
  }

  changeRooms(rooms: IRooms | null) {
    this.setRooms(rooms?.label === ROOMS_SELECT_ALL ? null : rooms);
    this.getApartamentsForCounter();
  }

  changeFloor(value: ITypes | null) {
    this.setFloor(value?.label === FLOOR_SELECT_ALL ? null : value);
    this.getApartamentsForCounter();
  }

  changeIsCheckedAvailable(value: boolean) {
    this.setIsCheckedAvailable(value);
    this.getApartamentsForCounter();
  }

  *changeSorting(value: ISorting) {
    this.setSortSelected(value);
    yield this.getApartaments(1);
  }

  writeFiltersToLocalStorage() {
    let filters: IFiltersLocalStorage = {
      floor: this.floor,
      rooms: this.rooms,
      toPrice: this.toPrice,
      fromPrice: this.fromPrice,
      isCheckedAvailable: this.isCheckedAvailable,
      sortSelected: this.sortSelected,
      facilities: this.facilities,
      terms: this.terms,
    };

    localStorage.setItem(FILTERS, JSON.stringify(filters));
  }

  writeFiltersFromLocalStorage() {
    let _filters = localStorage.getItem(FILTERS);
    let _parsedFilters: IFiltersLocalStorage | null = _filters
      ? JSON.parse(_filters)
      : null;

    if (_parsedFilters) {
      this.setParsedFilters(_parsedFilters);
    }
  }

  setParsedFilters(filters: IFiltersLocalStorage) {
    this.changeFromPrice(filters.fromPrice);
    this.changeToPrice(filters.toPrice);

    // setter вместо changes чтобы не вызывать лишние запросы (для счетчика)
    this.setFloor(filters.floor);
    this.setIsCheckedAvailable(filters.isCheckedAvailable);
    this.setRooms(filters.rooms);
    this.setSortSelected(filters.sortSelected);

    this.setParsedFacilities(filters);
    this.setParsedTerms(filters);
  }

  setParsedFacilities(filters: IFiltersLocalStorage) {
    // если записаны удобства
    if (filters?.facilities.length) {
      filters.facilities.forEach((item) => {
        // активировать выбранные удобства
        if (item.isSelected) {
          this.activateFacilitiesItem(item.id);
        }
      });
    }
  }

  activateFacilitiesItem(itemId: number) {
    // найти удобство по id
    let _facilitiesItem = this.facilities.find((item) => item.id === itemId);

    if (_facilitiesItem) {
      _facilitiesItem.isSelected = true; // выделить выбранным

      if (!this.selectedFacilitiesId.includes(_facilitiesItem.id)) {
        // добавить в массив для отправки на бэк
        this.addSelectedFacilitiesId(_facilitiesItem.id);
      }
    }
  }

  setParsedTerms(filters: IFiltersLocalStorage) {
    // если записаны условия проживания
    if (filters?.terms.length) {
      filters.terms.forEach((item) => {
        // активировать выбранные условия проживания
        if (item.isSelected) {
          this.activateTerm(item.id);
        }
      });
    }
  }

  activateTerm(itemId: number) {
    // найти условие проживания по id
    let _term = this.terms.find((item) => item.id === itemId);

    if (_term) {
      _term.isSelected = true; // выделить выбранным

      if (!this.selectedTermsId.includes(_term.id)) {
        // добавить в массив для отправки на бэк
        this.addSelectedTermsId(_term.id);
      }
    }
  }

  removeFiltersFromLocalStorage() {
    localStorage.removeItem(FILTERS);
  }

  openPopupFilters() {
    this.setIsVisiblePopupFilters(true);
    disableScroll();
  }

  closePopupFilters() {
    this.setIsVisiblePopupFilters(false);
    enableScroll();
  }

  clearTermsFacilities() {
    this.facilities?.map((item) => (item.isSelected = false));
    this.terms?.map((item) => (item.isSelected = false));
    this.selectedFacilitiesId = [];
    this.selectedTermsId = [];
  }

  openPopupText() {
    this.setIsVisiblePopupText(true);
    disableScroll();
  }

  closePopupText() {
    this.setIsVisiblePopupText(false);
    enableScroll();
  }

  validateFilters() {
    this.validatePrices();
    return this.validatePrices();
  }

  validatePrices() {
    if (this.toPrice && Number(this.toPrice) <= 0) {
      this.errorsPrices = [];
      this.errorsPrices = [...this.errorsPrices, ERROR_FILTERS_PRICES_ZERO];
      return false;
    }

    if (this.fromPrice && this.toPrice) {
      if (Number(this.fromPrice) > Number(this.toPrice)) {
        this.errorsPrices = [];
        this.errorsPrices = [...this.errorsPrices, ERROR_FILTERS_PRICES];
        return false;
      }
    }
    this.errorsPrices = [];
    return true;
  }

  // setters

  setCountApartaments(value: number) {
    this.countApartaments = value;
  }

  setFromPrice(value: string) {
    this.fromPrice = value.replace(/[^0-9]+/, "");
  }

  setToPrice(value: string) {
    this.toPrice = value.replace(/[^0-9]+/, "");
  }

  setRooms(rooms: IRooms | null) {
    this.rooms = rooms;
  }

  setFloor(value: ITypes | null) {
    this.floor = value;
  }

  setSorting(sort: ESort) {
    this.sort = sort;
  }

  setSortSelected(value: ISorting) {
    this.sortSelected = value;
  }

  setIsSubmit(value: boolean) {
    this.isSubmit = value;
  }

  setPrevTotal(value: number) {
    this.prevTotal = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setTerms(value: ITerms[]) {
    this.terms = value
      ? value.map((item) => ({ ...item, isSelected: false }))
      : [];
  }

  setFacilities(value: IFacilities[]) {
    this.facilities = value
      ? value.map((item) => ({ ...item, isSelected: false }))
      : [];
  }

  setSelectedFacilitiesId(value: number[]) {
    this.selectedFacilitiesId = value;
  }

  setSelectedTermsId(value: number[]) {
    this.selectedTermsId = value;
  }

  setIsVisiblePopupFilters(value: boolean) {
    this.isVisiblePopupFilters = value;
  }

  setIsVisiblePopupText(value: boolean) {
    this.isVisiblePopupText = value;
  }

  setIsCheckedAvailable(value: boolean) {
    this.isCheckedAvailable = value;
  }
}
