import { makeAutoObservable } from "mobx";

import { getApartments } from "../../../api/Catalog";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import {
  IResponseCatalog,
  IServerApartment,
  TSort,
} from "../../../interfaces/Catalog/index";
import { IPaginateData } from "../../../interfaces";

export type TParams = {
  [key in string]: number | string | number[] | TSort | boolean | null;
};

export class ModelCatalog implements Store.ICatalog {
  apartments: IServerApartment[] = [];
  paginateData: IPaginateData = {
    lastPage: 0,
    limit: 0,
    page: 0,
    total: 0,
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *getApartments(params?: TParams, numberPage?: number) {
    let _params: TParams = {
      limit: 9,
      page: numberPage || 1,
      sort: "price_grow",
    };

    for (let key in params) {
      if (params[key]) {
        _params[`${key}`] = params[key];
      }
    }

    _params.available = params?.available ? 1 : 0;

    let response: TResponseApi<IResponseCatalog> = yield getApartments(_params);
    if (response.isSuccess && response.data !== null) {
      this.setApartments(response.data.list);

      this.setPaginateData({
        lastPage: response.data.lastPage,
        limit: response.data.limit,
        page: response.data.page,
        total: response.data.total,
      });
    }
  }

  *getOurFlats() {
    let _params: TParams = {
      limit: 3,
      available: 1,
    };

    let response: TResponseApi<IResponseCatalog> = yield getApartments(_params);
    if (response.isSuccess && response.data !== null) {
      this.setApartments(response.data.list);
    }
  }

  *getApartamentsForCounter(params?: TParams) {
    let _params: TParams = {
      limit: 9,
      page: 1,
      sort: "price_grow",
    };

    for (let key in params) {
      if (params[key]) {
        _params[`${key}`] = params[key];
      }
    }

    _params.available = params?.available ? 1 : 0;

    let response: TResponseApi<IResponseCatalog> = yield getApartments(_params);
    if (response.isSuccess && response.data !== null) {
      this.setPaginateData({
        lastPage: response.data.lastPage,
        limit: response.data.limit,
        page: response.data.page,
        total: response.data.total,
      });
    }
  }

  setPaginateData(value: IPaginateData) {
    this.paginateData = value;
  }

  setApartments(apartments: IServerApartment[]) {
    this.apartments = apartments;
  }
}
