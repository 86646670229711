import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as CloseMobile } from "../../../assets/mobile/close.svg";

import styles from "./styles/index.module.scss";

interface IProps {
  id: number;
  apartmentId: number;
  image: string | null;
  price: string;
  title: string;
  onDelete(): void;
}

export const ApartmentItem: FC<IProps> = observer(
  ({
    id = 0,
    apartmentId = 0,
    image = null,
    price = "",
    title = "",
    onDelete = () => {},
  }: IProps) => {
    let navigate = useNavigate();

    const _toApartment = () => {
      navigate(`/apartments/${apartmentId}`);
    };
    return (
      <div className={styles.apartmentItem}>
        <div className={styles.apartmentItem__content}>
          <button
            className={styles.apartmentItem__closeMobile}
            onClick={onDelete}
          >
            <CloseMobile />
          </button>

          <button className={styles.apartmentItem__close} onClick={onDelete}>
            <Close className={styles.apartmentItem__closeIcon} />
          </button>

          <div
            className={styles.apartmentItem__photoWrapper}
            onClick={_toApartment}
          >
            {image ? (
              <img
                className={styles.apartmentItem__photo}
                src={image}
                alt="flat"
              />
            ) : (
              <div className={styles.apartmentItem__photoEmpty}>
                Не удалось загрузить фото
              </div>
            )}
          </div>

          <div className={styles.apartmentItem__info}>
            <h5 className={styles.apartmentItem__infoTitle}>{title}</h5>
            <h2 className={styles.apartmentItem__price}>{price} ₽ / мес.</h2>
          </div>
        </div>
      </div>
    );
  }
);
