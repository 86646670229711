import React from "react";
import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router";

import { EMainTextCode } from "../../constants";

import { StoreAuthentication } from "../ui/storeAuthentication";
import { ModelFaq } from "../models/FAQ";
import { User } from "../models/User/user";
import { ModelContacts } from "./../models/Contacts/index";
import { ModelQuestion } from "./../models/Question/index";
import { ModelPartner } from "../models/Partner";
import { ModelFloor } from "./../models/Floor/";
import { ModelNews } from "../models/News/news";
import { Modals } from "../Modals";
import { ModelApartmentPlan } from "./../models/FlatPlan/index";
import { ModelMain } from "../models/MainPage";
import { ModelBills } from "../models/Documents/Bills";
import { ModelAgreements } from "../models/Documents/Agreements";
import { ModelActs } from "../models/Documents/Acts";
import { ModelCompany } from "../models/Company";
import { ModelBooking } from "../models/Booking";
import { ModelBasket } from "./../models/Basket/index";
import { ModelServices } from "./../models/Services/index";
import { ModelAmo } from "../models/Amo";
import { ModelMyBookings } from "../models/Account/myBookings";
import { ModelBookingResidents } from "./../models/Account/bookingResidents";
import { ModelMyApartment } from "./../models/Account/myApartment";
import { ModelArchive } from "../models/Account/archive";
import { ModelAccountServices } from "../models/Account/services";
import { ModelDocuments } from "../models/Documents";
import { ModelOtherDocuments } from "../models/Documents/OtherDocuments";
import { ModelFinance } from "../models/Account/finance";
import { ModelCatalog } from "../models/Catalog/apartments";
import { ModelFilters } from "../models/Catalog/filters";
import { ModelAdditionalBookingData } from "../models/AdditionalBookingData";
import { ModelPayment } from "../models/Payment";
import { ModelMetaData } from "../models/MetaData";

import { Store } from "../types";
import { EBookingType, EInputsNames } from "../../types";

import { IWindowPositions } from "../../interfaces";

export class Root implements Store.IRootStore {
  userStore: Store.IUser;
  storeContacts: Store.IContacts;
  storeFaq: Store.IFaq;
  storeQuestionModal: Store.IQuestion;
  storeParter: Store.IPartner;
  storeFloor: Store.IFloor;
  storeNews: Store.IAllNews;
  storeApartmentPlan: Store.IApartmentPlan;
  storeModals: Store.IModals;
  storeMain: Store.IMain;
  storeBookingResidents: Store.IBookingResidents;
  storeBills: Store.IBillsStore;
  storeAgreements: Store.IAgreementsStore;
  storeActs: Store.IActsStore;
  storeCompany: Store.ICompanyStore;
  storeBooking: Store.IBooking;
  storeAdditionalBookingData: Store.IAdditionalBookingData;
  storeArchiveBookings: Store.IArchive;
  storeMyBookings: Store.IMyBookings;
  storeMyApartment: Store.IMyApartment;
  storeBookingServices: Store.IAccountServices;
  storeAuthentication: Store.IStoreAuthentication;
  storeBasket: Store.IBasket;
  storeServices: Store.IServices;
  storeAmo: Store.IAmo;
  storeDocuments: Store.IDocumentsStore;
  storeOtherDocuments: Store.IOtherDocumentsStore;
  storeFinance: Store.IFinanceStore;
  storeCatalog: Store.ICatalog;
  storeFilters: Store.ICatalogFilters;
  storePayment: Store.IPayment;
  storeMetaData: Store.IMetaData;

  isMainLoading: boolean = false;
  isFilledProfile: boolean = false;
  isFilledCompany: boolean = false;
  isFilledInputsProfile: boolean = false;

  numberServices: number = 0;

  haveArchivedOrActiveBooking: boolean = false;

  currentCountTenantsFromMyBooking: number | null = null;

  isVisibleFloatHeader: boolean = false;

  isBookingAddBasketError: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeMetaData = new ModelMetaData();
    this.userStore = new User();
    this.storeDocuments = new ModelDocuments();
    this.storeContacts = new ModelContacts();
    this.storeFaq = new ModelFaq();
    this.storeQuestionModal = new ModelQuestion();
    this.storeParter = new ModelPartner();
    this.storeFloor = new ModelFloor();
    this.storeNews = new ModelNews();
    this.storePayment = new ModelPayment();
    this.storeModals = new Modals(this);
    this.storeApartmentPlan = new ModelApartmentPlan();
    this.storeMain = new ModelMain();
    this.storeBookingResidents = new ModelBookingResidents();
    this.storeBills = new ModelBills();
    this.storeAgreements = new ModelAgreements();
    this.storeActs = new ModelActs();
    this.storeCompany = new ModelCompany();
    this.storeBooking = new ModelBooking();
    this.storeAuthentication = new StoreAuthentication();
    this.storeBasket = new ModelBasket(this);
    this.storeServices = new ModelServices(this);
    this.storeAmo = new ModelAmo();
    this.storeMyBookings = new ModelMyBookings();
    this.storeMyApartment = new ModelMyApartment();
    this.storeBookingServices = new ModelAccountServices();
    this.storeArchiveBookings = new ModelArchive();
    this.storeOtherDocuments = new ModelOtherDocuments();
    this.storeFinance = new ModelFinance();
    this.storeCatalog = new ModelCatalog();
    this.storeFilters = new ModelFilters();
    this.storeAdditionalBookingData = new ModelAdditionalBookingData();
  }

  // getters

  get userProfile() {
    return this.userStore.profile;
  }

  get mainTextBlocks() {
    return this.storeMain.textBlocks;
  }

  get numberItemsInBasket() {
    return this.storeBasket.numberApartments + this.numberServices;
  }

  get haveApartmentInBasket() {
    return Boolean(this.storeBasket.numberApartments);
  }

  get metaDescriptionsList() {
    return this.storeMetaData.descriptionsList;
  }

  // functions
  *init(navigate: NavigateFunction) {
    this.storeAuthentication.init();

    if (!this.storeMain.textBlocks.length) {
      this.setIsMainLoading(true);
      // получить тексты для главной
      yield this.storeMain.getTextConcreteBlock(EMainTextCode.Main);
      this.setIsMainLoading(false);
    }

    if (this.storeAuthentication.isAuth) {
      yield this.userStore.getPublicProfile();
      // проверка наличия активных + архивных броней (для вывода ссылки на Правила)
      yield this.checkHaveArchivedOrActiveBooking();

      if (this.userStore.isNotAuth) {
        this.storeAuthentication.hardLogout(navigate);
      }
    } else {
      this.userStore.clearPublicProfile();
    }

    // для кол-ва товаров в корзине
    yield this.storeBasket.getBasket();

    if (!this.storeMetaData.descriptionsList.length) {
      // запрос на заголовки и описания страниц
      yield this.storeMetaData.getPagesDescription();
    }
  }

  *checkFilledAllProfile(isAuth: boolean) {
    if (isAuth) {
      yield this.userStore.getPublicProfile();

      let _isFilled =
        this.userStore.profile.lastName?.length > 0 &&
        this.userStore.profile.firstName?.length > 0 &&
        this.userStore.profile.birthdate?.length > 0 &&
        this.userStore.profile.placeBirth?.length > 0 &&
        this.userStore.profile.phone?.length > 0 &&
        this.userStore.profile.gender !== null &&
        this.userStore.profile.passportSeries?.length > 0 &&
        this.userStore.profile.passportNumber?.length > 0 &&
        this.userStore.profile.passportDateIssue?.length > 0 &&
        this.userStore.profile.passportIssued?.length > 0 &&
        this.userStore.profile.passportAddressRegister?.length > 0 &&
        this.userStore.profile.passportSubdivisionCode?.length > 0;

      this.setIsFilledProfile(_isFilled);
    } else {
      this.setIsFilledProfile(false);
      this.userStore.clearPublicProfile();
    }
  }

  *checkFilledInputsProfile(inputsNames: EInputsNames[]) {
    if (inputsNames.length) {
      yield this.userStore.getPublicProfile();

      let booleanArr: boolean[] = [];

      inputsNames.forEach((item: EInputsNames) => {
        switch (item) {
          case EInputsNames.LastName:
            booleanArr.push(Boolean(this.userStore.profile.lastName?.length));
            break;
          case EInputsNames.FirstName:
            booleanArr.push(Boolean(this.userStore.profile.firstName?.length));
            break;
          case EInputsNames.MiddleName:
            booleanArr.push(Boolean(this.userStore.profile.middleName?.length));
            break;
          case EInputsNames.Email:
            booleanArr.push(Boolean(this.userStore.profile.email?.length));
            break;
          case EInputsNames.Birthdate:
            booleanArr.push(Boolean(this.userStore.profile.birthdate?.length));
            break;
          case EInputsNames.PlaceBirth:
            booleanArr.push(Boolean(this.userStore.profile.placeBirth?.length));
            break;
          case EInputsNames.Phone:
            booleanArr.push(Boolean(this.userStore.profile.phone?.length));
            break;
          case EInputsNames.Gender:
            booleanArr.push(Boolean(this.userStore.profile.gender !== null));
            break;
          case EInputsNames.PassportSeries:
            booleanArr.push(
              Boolean(this.userStore.profile.passportSeries?.length)
            );
            break;
          case EInputsNames.PassportNumber:
            booleanArr.push(
              Boolean(this.userStore.profile.passportNumber?.length)
            );
            break;
          case EInputsNames.PassportDateIssue:
            booleanArr.push(
              Boolean(this.userStore.profile.passportDateIssue?.length)
            );
            break;
          case EInputsNames.PassportIssued:
            booleanArr.push(
              Boolean(this.userStore.profile.passportIssued?.length)
            );
            break;
          case EInputsNames.PassportAddressRegister:
            booleanArr.push(
              Boolean(this.userStore.profile.passportAddressRegister?.length)
            );
            break;
          case EInputsNames.PassportSubdivisionCode:
            booleanArr.push(
              Boolean(this.userStore.profile.passportSubdivisionCode?.length)
            );
            break;
          default:
            booleanArr.push(true);
            break;
        }
      });

      this.setIsFilledInputsProfile(!booleanArr.includes(false));
    }
  }

  *checkFilledCompany(isAuth: boolean) {
    if (isAuth) {
      yield this.storeCompany.getCompany();

      let _isFilledCompany =
        this.storeCompany.company.bik?.length > 0 &&
        this.storeCompany.company.inn?.length > 0 &&
        this.storeCompany.company.ogrn?.length > 0 &&
        this.storeCompany.company.correspondent_account?.length > 0 &&
        this.storeCompany.company.director?.length > 0 &&
        this.storeCompany.company.name?.length > 0 &&
        this.storeCompany.company.settlement_account?.length > 0 &&
        this.storeCompany.company.phone?.length > 0 &&
        this.storeCompany.company.address?.length > 0 &&
        this.storeCompany.company.addressIndex?.length > 0 &&
        this.storeCompany.company.bankName?.length > 0;

      this.setIsFilledCompany(_isFilledCompany);
    } else {
      this.setIsFilledCompany(false);
      this.storeCompany.clearCompany();
    }
  }

  *getBookingsList(typeBooking: EBookingType) {
    switch (typeBooking) {
      case EBookingType.Active:
        if (!this.storeMyBookings.bookings.length) {
          yield this.storeMyBookings.getBookings();
        }
        break;

      case EBookingType.Archive:
        if (!this.storeArchiveBookings.archivesItems.length) {
          yield this.storeArchiveBookings.getArchives();
        }
        break;
    }
  }

  *getBookingPeriodById(bookingId: string) {
    if (
      !this.storeMyApartment.apartmentDetail?.bookingCalendar?.startDate ||
      !this.storeMyApartment.apartmentDetail?.bookingCalendar?.endDate
    ) {
      yield this.storeMyApartment.getBookingById(Number(bookingId));
    }

    return {
      startDate:
        this.storeMyApartment.apartmentDetail?.bookingCalendar?.startDate,
      endDate: this.storeMyApartment.apartmentDetail?.bookingCalendar?.endDate,
    };
  }

  *getBasket() {
    yield this.storeBasket.getBasket();
  }

  clearNumberItemsFromBasket() {
    this.storeBasket.setNumberApartments(0);
    this.setNumberServices(0);
  }

  checkBookingIdByUrl(bookingId: string, typeBooking: EBookingType) {
    switch (typeBooking) {
      case EBookingType.Active:
        return this.storeMyBookings.checkBookingId(bookingId);

      case EBookingType.Archive:
        return this.storeArchiveBookings.checkBookingId(bookingId);

      default:
        return false;
    }
  }

  *checkHaveArchivedOrActiveBooking() {
    if (!this.storeMyBookings.bookings.length) {
      yield this.storeMyBookings.getBookings();
    }

    if (!this.storeArchiveBookings.archivesItems.length) {
      yield this.storeArchiveBookings.getArchives();
    }

    let activeBookingsLength = this.storeMyBookings.bookings.length;
    let archiveBookingsLength = this.storeArchiveBookings.archivesItems.length;

    let countBookings = activeBookingsLength + archiveBookingsLength;

    this.setHaveArchivedOrActiveBooking(countBookings > 0);
  }

  activateFloatHeader(props: {
    headerVisible: boolean;
    headerHeight: number;
    scrollPositions: IWindowPositions;
    additionalOffset?: number;
  }) {
    const positionY = props.scrollPositions.scrollY;
    const headerHeight = props.headerHeight;
    const _additionalOffset = props.additionalOffset ?? 0;

    let isVisible = positionY >= headerHeight + _additionalOffset;

    this.setIsVisibleFloatHeader(!props.headerVisible && isVisible);
  }

  // setters

  setIsMainLoading(value: boolean) {
    this.isMainLoading = value;
  }

  setIsFilledProfile(value: boolean) {
    this.isFilledProfile = value;
  }

  setIsFilledCompany(value: boolean) {
    this.isFilledCompany = value;
  }

  setIsFilledInputsProfile(value: boolean) {
    this.isFilledInputsProfile = value;
  }

  setNumberServices(value: number) {
    this.numberServices = value;
  }

  setHaveArchivedOrActiveBooking(value: boolean) {
    this.haveArchivedOrActiveBooking = value;
  }

  setCurrentCountTenantsFromMyBooking(value: number | null) {
    this.currentCountTenantsFromMyBooking = value;
  }

  setIsVisibleFloatHeader(value: boolean) {
    this.isVisibleFloatHeader = value;
  }

  setIsBookingAddBasketError(value: boolean) {
    this.isBookingAddBasketError = value;
  }
}

export const StoreRoot = new Root();
export const RootContext = React.createContext(StoreRoot);
