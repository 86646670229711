import { useMemo } from "react";
import { observer } from "mobx-react-lite";

import { EnterRegisterPhone } from "./components/EnterRegisterPhone";
import { EnterPasswords } from "../components/EnterPasswords/index";
import { EnterCode } from "../components/EnterCode/index";
import { EnterFio } from "./components/EnterFio";

import { EStepsRegister } from "../../../../types/Registration";

import { IRegistrationUI } from "../../../../stores/ui/Authetication/interfaces";

import styles from "./styles/signup.module.scss";

interface IProps {
  storeUI: IRegistrationUI;
  isVisible: boolean;
  checkIsAuth(): boolean;
}

export const SignUp = observer(
  ({ storeUI, isVisible = false, checkIsAuth = () => false }: IProps) => {
    let _step = storeUI.step;

    let stepRegister = useMemo(() => {
      switch (_step) {
        case EStepsRegister.EnterPhone:
          return <EnterRegisterPhone storeUI={storeUI} />;
        case EStepsRegister.EnterCode:
          return <EnterCode storeUI={storeUI} />;
        case EStepsRegister.EnterFio:
          return <EnterFio storeUI={storeUI} />;
        case EStepsRegister.EnterPasswords:
          return (
            <EnterPasswords
              storeUI={storeUI}
              checkIsAuth={checkIsAuth}
              isRegister={true}
            />
          );
        default:
          return "";
      }
    }, [checkIsAuth, storeUI, _step]);

    return (
      <>
        {isVisible && (
          <div className={styles.modal__contentWrapper}>
            <h2 className={styles.modal__contentTitle}>Личный кабинет</h2>
            {stepRegister}
          </div>
        )}
      </>
    );
  }
);
