import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { Additional } from "./Additional";
import { Button } from "../../components/Button";
import { Footer } from "../../components/Footer";
import { ApartmentItem } from "./ApartmentItem";
import { BackTo } from "../../components/BackTo";
import { PopupAllServices } from "./PopupAllServices";
import { Loader } from "../../components/Loader";
import { ModalReuse } from "../../components/Modals/ModalReuse";

import { StoreUI } from "./storeUI/index";
import { RootContext } from "../../stores/Root";

import { Store } from "../../stores/types";
import { EPageType } from "../../types";

import { DESCRIPTION_PAGE_DEFAULT, PAGE_TITLE_BASKET } from "../../constants";

import styles from "./styles/basket.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
}

export const Basket = observer(({ storeAuthentication }: IProps) => {
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(
    () => new StoreUI(storeAuthentication, navigate, rootStore)
  );

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI, storeAuthentication.isAuth]);

  const _onBack = () => {
    window.history.back();
  };

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.Basket
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_BASKET}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <div className={styles.basket}>
      {helmet}

      <div className={styles.basket__contentWrapper}>
        {storeUI.isLoading ? (
          <div className={styles.basket__empty}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.basket__contentHeader}>
              <BackTo
                text={"Назад"}
                onClick={_onBack}
                className={styles.basket__backButton}
              />
            </div>
            <div className={styles.basket__content}>
              {
                <Additional
                  className={styles.basket__servicesMobile}
                  services={storeUI.visibleServices}
                  price={storeUI.price}
                  onDeleteService={storeUI.deleteService}
                  onClickOrder={storeUI.toOrder}
                  onClearBasket={storeUI.openConfirmationPopup}
                  haveApartment={storeUI.apartments?.length > 0}
                  countServices={storeUI.countAllServices}
                  onClickAllServices={storeUI.openAllServices}
                />
              }

              <div className={styles.basket__flats}>
                {storeUI.apartments?.map((flat) => (
                  <ApartmentItem
                    id={flat.id}
                    key={flat.id}
                    apartmentId={flat.apartmentId}
                    image={flat.image}
                    price={flat.price}
                    title={flat.title}
                    onDelete={() => storeUI.openConfirmationPopup(flat.id)}
                  />
                ))}
                {storeUI.apartments?.length === 0 && (
                  <div className={styles.basket__flatsEmpty}>
                    <h3>В корзине нет заказов</h3>
                    <Button
                      title={"Выбрать квартиру"}
                      onClick={storeUI.toCatalog}
                      theme={"gold"}
                      className={styles.basket__flatsEmptyButton}
                    />
                  </div>
                )}
              </div>
              <div className={styles.basket__buttonWrapper}>
                <Button
                  className={styles.basket__button}
                  title="ОФОРМИТЬ ЗАКАЗ"
                  theme="gold"
                  onClick={storeUI.toOrder}
                  disabled={storeUI.isLoading}
                  isDisabledStyle={storeUI.apartments.length === 0}
                />
                {storeUI.mainError.length > 0 && (
                  <span className={styles.basket__error}>
                    {storeUI.mainError}
                  </span>
                )}
              </div>

              <Additional
                className={styles.basket__services}
                services={storeUI.visibleServices}
                price={storeUI.price}
                onClickOrder={storeUI.toOrder}
                onClearBasket={storeUI.openConfirmationPopup}
                onDeleteService={storeUI.deleteService}
                haveApartment={storeUI.apartments.length > 0}
                countServices={storeUI.countAllServices}
                onClickAllServices={storeUI.openAllServices}
                mainError={storeUI.mainError}
              />
            </div>
          </>
        )}
      </div>
      <Footer className={styles.basket__footer} />

      <PopupAllServices
        isVisible={storeUI.visibleAllServicesPopup}
        services={storeUI.allServices}
        onDeleteService={storeUI.deleteService}
        onClose={storeUI.closeAllServices}
      />
      <ModalReuse
        isShowModal={storeUI.visibleConfirmationPopup}
        setIsShowModal={storeUI.setVisibleConfirmationPopup}
        type={"buttons"}
        btnRight={"нет"}
        btnLeft={"да"}
        onClick={storeUI.onClearBasket}
        isLoading={storeUI.isLoading}
      >
        <p className={styles.basket__modalText}>
          Вы действительно хотите удалить товары из корзины?
        </p>
      </ModalReuse>

      <ModalReuse
        isShowModal={storeUI.visibleCheckFreePopup}
        setIsShowModal={storeUI.setVisibleCheckFreePopup}
        type={"button"}
        btnTitle="Выбрать квартиру"
        onClickSingleButton={storeUI.clearBasketAndToCatalog}
      >
        <p className={styles.basket__modalText}>
          Квартира была забронирована. Пожалуйста, выберите другую квартиру или
          измените период проживания
        </p>
      </ModalReuse>
    </div>
  );
});
