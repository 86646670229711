import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { SCREENS } from "../../../../navigation/endpoints";

import { IStoreUI } from "./interfaces";
import { Store } from "../../../../stores/types";

import { ETextMainId } from "../../../../constants";

enum Descrpition {
  Parking = "parking",
  Kidsclub = "kidsclub",
  Fit = "fit",
  Supermarket = "supermarket",
  Cleaning = "cleaning",
}

export class StoreUI implements IStoreUI {
  navigate: NavigateFunction;
  isOpenFloor: boolean = false;
  isParking: boolean = false;
  isKidsclub: boolean = false;
  isFit: boolean = false;
  isSupermarket: boolean = false;
  isCleaning: boolean = false;
  isLoading: boolean = true;
  indexFloor: number = 0;
  show: number | undefined = 0;

  storeRoot: Store.IRootStore;

  constructor(storeRoot: Store.IRootStore, navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.navigate = navigate;
    this.storeRoot = storeRoot;
  }

  get content() {
    if (this.storeRoot.mainTextBlocks?.length) {
      return (
        this.storeRoot.mainTextBlocks.find(
          (block) => block.id === ETextMainId.FirstBlockId
        )?.text || `Не удалось загрузить контент`
      );
    }
    return `Не удалось загрузить контент`;
  }

  openDescriptions(advantage: string) {
    this.setParking(advantage === Descrpition.Parking && !this.isParking);
    this.setKidsclub(advantage === Descrpition.Kidsclub && !this.isKidsclub);
    this.setFit(advantage === Descrpition.Fit && !this.isFit);
    this.setSupermarket(
      advantage === Descrpition.Supermarket && !this.isSupermarket
    );
    this.setCleaning(advantage === Descrpition.Cleaning && !this.isCleaning);
  }

  openParking() {
    this.openDescriptions(Descrpition.Parking);
  }

  openKidsClub() {
    this.openDescriptions(Descrpition.Kidsclub);
  }

  openFit() {
    this.openDescriptions(Descrpition.Fit);
  }

  openSupermarket() {
    this.openDescriptions(Descrpition.Supermarket);
  }

  openCleaning() {
    this.openDescriptions(Descrpition.Cleaning);
  }

  openFloorModal() {
    this.isOpenFloor = true;
  }

  closeFloorModal() {
    this.isOpenFloor = false;
  }

  showConcreteFloor(floorId: number) {
    this.navigate(`${SCREENS.SCREEN_FLOOR}/${floorId}`);
  }

  toApartments() {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  }

  setIndexFloor(floorId: number) {
    this.indexFloor = floorId;
  }

  setShow(show: number) {
    this.show = show;
  }

  setUnShow() {
    this.show = undefined;
  }

  setParking(isParking: boolean) {
    this.isParking = isParking;
  }

  setKidsclub(isKidsclub: boolean) {
    this.isKidsclub = isKidsclub;
  }

  setFit(isFit: boolean) {
    this.isFit = isFit;
  }

  setSupermarket(isSupermarket: boolean) {
    this.isSupermarket = isSupermarket;
  }

  setCleaning(isCleaning: boolean) {
    this.isCleaning = isCleaning;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
