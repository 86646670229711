import { makeAutoObservable } from "mobx";

import { sendQuestion } from "../../../api/QuestionModal";

import { TResponseApi } from "../../../api/types";
import { ICommonResponse } from "../../../interfaces";
import { IQuestionRequest } from "../../../interfaces/QuestionModal";
import { Store } from "../../types";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

export class ModelQuestion implements Store.IQuestion {
  errors: string[] = [];
  isSuccessSend: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *sendQuestion(params: IQuestionRequest) {
    let response: TResponseApi<ICommonResponse> = yield sendQuestion(params);

    if (response.isSuccess && response.data !== null) {
      this.setIsSuccessSend(response.data?.result === "success");
    } else {
      this.setIsSuccessSend(false);
    }

    if (response.isError) {
      if (response.error?.message) {
        this.addError(response.error.message);
      } else {
        this.addError(ERROR_TEXT_SERVER_COMMON);
      }
    } else {
      this.setError([]);
    }

    return false;
  }

  addError(value: string) {
    this.errors = [...this.errors, value];
  }

  clearErrors() {
    this.errors = [];
  }

  // setters

  setError(value: string[]) {
    this.errors = value;
  }

  setIsSuccessSend(value: boolean) {
    this.isSuccessSend = value;
  }
}
