import { makeAutoObservable } from "mobx";

import { ApiManager } from "../../../api";
import { authorization } from "../../../api/Authorization";
import {
  checkPasswordResetCode,
  getPasswordResetCode,
  passwordResetChange,
} from "../../../api/PasswordRecovery";

import {
  ERROR_TEXT_SERVER_COMMON,
  TOO_MANY_REQUEST,
  TOO_MANY_REQUEST_RU,
} from "../../../constants";

import {
  IResetChange,
  IServerErrorsRecovery,
} from "../../../interfaces/PasswordRecovery";
import { IAuthorizationResponse } from "../../../interfaces/Authorization";
import { ICommonResponse } from "../../../interfaces";

import { EMethodSend } from "../../../types";
import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

export class ModelPasswordRecovery implements Store.IPasswordRecovery {
  serverErrors: IServerErrorsRecovery = {
    phone: [],
    password: [],
    confirmPassword: [],
    code: [],
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *sendCode(phone: string, methodSend: EMethodSend) {
    let params = { phone: phone.replaceAll(" ", ""), methodSend: methodSend };

    let response: TResponseApi<ICommonResponse> = yield getPasswordResetCode(
      params
    );

    if (response.isError) {
      if (response.error?.errors?.phone) {
        this.serverErrors.phone = [];
        this.addErrorPhone(response.error?.errors?.phone);
      } else if (response.error?.data?.message) {
        this.serverErrors.phone = [];
        this.addErrorPhone(response.error?.data?.message);
      } else if (response.error?.message) {
        this.serverErrors.phone = [];

        if (response.error.message.toLowerCase() === TOO_MANY_REQUEST) {
          this.addErrorPhone(TOO_MANY_REQUEST_RU);
        } else {
          this.addErrorPhone(response.error?.message);
        }
      }
      this.addErrorPhone(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.serverErrors.phone = [];
    }

    return response.isSuccess;
  }

  *checkCode(phone: string, code: string) {
    let _phone = phone.replaceAll(" ", "");

    let response: TResponseApi<ICommonResponse> = yield checkPasswordResetCode({
      phone: _phone,
      code: code,
    });

    if (response.isError) {
      if (response.error?.data?.message) {
        this.serverErrors.code = [];
        this.addErrorCode(response.error?.data?.message);
      } else if (response.error?.message) {
        this.serverErrors.code = [];

        if (response.error.message.toLowerCase() === TOO_MANY_REQUEST) {
          this.addErrorCode(TOO_MANY_REQUEST_RU);
        } else {
          this.addErrorCode(response.error?.message);
        }
      }
      this.addErrorCode(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.serverErrors.code = [];
    }

    return response.isSuccess;
  }

  *changePassword(params: IResetChange) {
    let response: TResponseApi<ICommonResponse> = yield passwordResetChange(
      params
    );

    if (response.isError) {
      if (response.error?.data?.message) {
        this.serverErrors.password = [];
        this.serverErrors.confirmPassword = [];
        this.addErrorPassword(response.error?.data?.message);
        this.addErrorConfirmPassword(response.error?.data?.message);
      } else if (response.error?.message) {
        this.serverErrors.password = [];
        this.serverErrors.confirmPassword = [];

        if (response.error.message.toLowerCase() === TOO_MANY_REQUEST) {
          this.addErrorPassword(TOO_MANY_REQUEST_RU);
          this.addErrorConfirmPassword(TOO_MANY_REQUEST_RU);
        } else {
          this.addErrorPassword(response.error?.message);
          this.addErrorConfirmPassword(response.error?.message);
        }
      }
      this.addErrorCode(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.serverErrors.password = [];
      this.serverErrors.confirmPassword = [];
    }

    return response.isSuccess;
  }

  *authorization(phone: string, password: string) {
    let params = { phone: phone.replaceAll(" ", ""), password: password };

    let response: TResponseApi<IAuthorizationResponse> = yield authorization(
      params
    );

    if (
      response.isSuccess &&
      response.data !== null &&
      response.data.accessToken
    ) {
      this.saveDataToLocalStorage(response);
      return true;
    }

    if (response.isError) {
      this.addErrorPassword(ERROR_TEXT_SERVER_COMMON);
      this.addErrorConfirmPassword(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.serverErrors.password = [];
      this.serverErrors.confirmPassword = [];
    }

    return response.isSuccess;
  }

  addErrorPhone(value: string) {
    this.serverErrors.phone = [...this.serverErrors.phone, value];
  }

  addErrorCode(value: string) {
    this.serverErrors.code = [...this.serverErrors.code, value];
  }

  addErrorPassword(value: string) {
    this.serverErrors.password = [...this.serverErrors.password, value];
  }

  addErrorConfirmPassword(value: string) {
    this.serverErrors.confirmPassword = [
      ...this.serverErrors.confirmPassword,
      value,
    ];
  }

  saveDataToLocalStorage(response: TResponseApi<IAuthorizationResponse>) {
    if (response.data !== null) {
      ApiManager.writeToken({
        accessToken: response.data.accessToken,
        tokenType: response.data.tokenType,
      });
    }
  }

  clearErrors() {
    this.serverErrors.code = [];
    this.serverErrors.phone = [];
    this.serverErrors.password = [];
    this.serverErrors.confirmPassword = [];
  }
}
