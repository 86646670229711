import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Loader } from "../../components/Loader";
import { Footer } from "../../components/Footer";
import { NoContent } from "../../components/NoContent";

import { StoreUI } from "./storeUI";

import { TITLE_TEXT_PAGE_DEFAULT } from "../../constants";

import styles from "./styles/index.module.scss";

export const TextPage = observer(() => {
  const navigation = useNavigate();
  const [storeUI] = useState(() => new StoreUI(navigation));
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      await storeUI.init(pathname);
    })();
  }, [storeUI, pathname, navigation]);

  return (
    <>
      <div className={styles.textPage}>
        <Helmet>
          <title>
            {!storeUI.isLoading && storeUI.title
              ? storeUI.title
              : TITLE_TEXT_PAGE_DEFAULT}
          </title>
        </Helmet>
        {!storeUI.isLoading ? (
          storeUI.content ? (
            <>
              <h2 className={styles.textPage__title}>{storeUI.title}</h2>
              <div
                className={styles.textPage__text}
                dangerouslySetInnerHTML={{
                  __html: storeUI.content,
                }}
              />
            </>
          ) : (
            <NoContent
              title="Информации пока нет"
              className={styles.textPage__noContent}
            />
          )
        ) : (
          <div className={styles.textPage__empty}>
            <Loader />
          </div>
        )}
      </div>
      <Footer className={styles.textPage__footer} />
    </>
  );
});
