import { makeAutoObservable } from "mobx";
import { format, isValid, parse } from "date-fns";

import { IStoreUI } from "./interfaces";

import { Store } from "../../../../../../stores/types";

import {
  disableScroll,
  enableScroll,
} from "../../../../../../helpers/modalsFunc";
import { openPdf } from "../../../../../../helpers/openPdf";
import { downloadPdf } from "../../../../../../helpers/downloadPdf";

import {
  ERROR_CORRECT_DATE,
  ERROR_TEXT_EMPTY,
  MIN_YEAR,
  PDF_NAME_ACT,
} from "../../../../../../constants";
import { fullNumbersDashFormat } from "../../../../../../helpers";

interface IErrors {
  dateFrom: string[];
}

enum EInputs {
  DateFrom = "dateFrom",
}

export class StoreUI implements IStoreUI {
  storeActs: Store.IActsStore;
  storeDocuments: Store.IDocumentsStore;

  isLoading: boolean = false;
  isLoadingGenerateAct: boolean = false;
  isVisibleActRevice: boolean = false;
  isVisibleSuccessRevice: boolean = false;

  currentLoadingId: number | null = null;
  bookingId: number | null = null;

  // поле попапа
  dateFrom: string = "";

  errors: IErrors = {
    [EInputs.DateFrom]: [],
  };

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeActs = storeRoot.storeActs;
    this.storeDocuments = storeRoot.storeDocuments;
  }

  // getters

  get acts() {
    return this.storeActs.acts.map((item) => ({
      documentId: item.documentId ?? 0,
      name: item.name ?? "",
      date: item.date ? this.convertDate(item.date) : "00.00.0000",
    }));
  }

  // functions

  *init(bookingId: number) {
    this.setIsLoading(true);
    yield this.storeActs.getActs(bookingId);
    this.setBookingId(bookingId);
    this.setIsLoading(false);
  }

  *onShowPdf(documentId: number) {
    this.setCurrentLoadingId(documentId);
    let agreement: string = yield this.getAgreementData(documentId);
    this.setCurrentLoadingId(null);
    openPdf(agreement);
  }

  *onDownloadPdf(documentId: number) {
    this.setCurrentLoadingId(documentId);
    let act: string = yield this.getAgreementData(documentId);

    if (act.length) {
      downloadPdf(act, PDF_NAME_ACT);
    }
    this.setCurrentLoadingId(null);
  }

  *getAgreementData(id: number) {
    yield this.storeDocuments.getDocumentPdf(id);

    if (this.storeDocuments?.documentBase64.length) {
      return this.storeDocuments?.documentBase64;
    }
    return "";
  }

  *sendRequestAct() {
    if (this.validateDates() && this.bookingId !== null) {
      let params = {
        startDate: this.reverseChangeSeparator(this.dateFrom, ".", "-"),
        endDate: fullNumbersDashFormat(new Date()),
        bookingId: this.bookingId,
      };

      this.setIsLoadingGenerateAct(true);
      yield this.storeActs.generateReconciliationAct(params);
      this.setIsLoadingGenerateAct(false);

      if (this.storeActs.isGenerationAct) {
        this.closeActRevisePopup();
        this.openSuccessRevice();

        yield this.storeActs.getActs(this.bookingId);
      }
    }
  }

  validateDates() {
    this.validateDate(this.dateFrom, EInputs.DateFrom);

    return this.validateDate(this.dateFrom, EInputs.DateFrom);
  }

  validateDate(date: string, inputType: EInputs) {
    if (!date.length) {
      this.addValidationError(inputType, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(inputType);
    }

    //проверка на ввод будущих дат
    let currentDate = parse(date, "dd.MM.yyyy", new Date());
    let now = new Date();

    let nowTime = now.getTime();
    let currentDateTime = currentDate.getTime();
    let isNextDate = currentDateTime > nowTime;

    let isCorrect = isValid(currentDate);

    if (!isCorrect || isNextDate || currentDate.getFullYear() <= MIN_YEAR) {
      this.addValidationError(inputType, ERROR_CORRECT_DATE);
      return false;
    } else {
      this.clearValidationErrors(inputType);
    }

    return true;
  }

  openActRevisePopup() {
    this.clearErrors();
    this.setIsVisibleActRevice(true);
    disableScroll();
  }

  closeActRevisePopup() {
    this.setIsVisibleActRevice(false);
    enableScroll();
  }

  convertDate(date: string | Date) {
    return format(new Date(date), "dd.MM.yyyy");
  }

  reverseChangeSeparator(
    str: string,
    separatorFrom: string,
    separatorTo: string
  ) {
    return str.split(separatorFrom).reverse().join(separatorTo);
  }

  addValidationError(type: EInputs, value: string) {
    this.errors[type] = [...this.errors[type], value];
  }

  clearValidationErrors(type: EInputs) {
    this.errors[type] = [];
  }

  clearErrors() {
    this.clearValidationErrors(EInputs.DateFrom);
    this.storeActs.clearGenerationErrors();
    this.dateFrom = "";
  }

  openSuccessRevice() {
    this.setIsVisibleSuccessRevice(true);
    disableScroll();
  }

  closeSuccessRevice() {
    this.setIsVisibleSuccessRevice(false);
    enableScroll();
  }

  // setter

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingGenerateAct(value: boolean) {
    this.isLoadingGenerateAct = value;
  }

  setIsVisibleActRevice(value: boolean) {
    this.isVisibleActRevice = value;
  }

  setCurrentLoadingId(value: number | null) {
    this.currentLoadingId = value;
  }

  setDateFrom(value: string) {
    this.dateFrom = value;
  }

  setBookingId(value: number | null) {
    this.bookingId = value;
  }

  setIsVisibleSuccessRevice(value: boolean) {
    this.isVisibleSuccessRevice = value;
  }
}
