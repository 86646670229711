import { FC, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { ArchiveItem } from "./ArchiveItem";
import { Empty } from "../../../../components/Empty";
import { Loader } from "../../../../components/Loader";
import { LeaveReview } from "../../../../components/Modals/Review";

import { StoreUI } from "./storeUI";

import { RootContext } from "../../../../stores/Root";

import { ReactComponent as Lock } from "../../../../assets/images/lock.svg";

import styles from "./styles/archive.module.scss";

export const Archive: FC = observer(() => {
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(
    () => new StoreUI(navigate, rootStore.storeArchiveBookings)
  );

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  return (
    <>
      {!storeUI.isLoading ? (
        storeUI.countArchives > 0 ? (
          <div className={styles.content}>
            <h2 className={styles.content__count}>
              Архив квартир{" "}
              <span className={styles.content__color}>
                ({storeUI.countArchives})
              </span>
            </h2>
            <div className={styles.content__archives}>
              {storeUI.archives?.map((item) => (
                <ArchiveItem
                  key={item.id}
                  id={item.id}
                  isReview={item.review}
                  image={item.image}
                  floor={item.floor}
                  rooms={item.rooms}
                  square={item.square}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  openReviewModal={() =>
                    storeUI.openReview(item.apartmentId, item.id)
                  }
                  toApartment={() => storeUI.toApartment(item.apartmentId)}
                  onClickDocs={storeUI.onClickDocs}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.content__empty}>
            <Empty
              title="В вашем архиве нет квартир"
              titleBtn="ВЫБРАТЬ КВАРТИРУ"
              Icon={Lock}
              onClick={storeUI.toApartments}
            />
          </div>
        )
      ) : (
        <Loader />
      )}
      <LeaveReview
        reviewModal={storeUI.isModalReview}
        closeReviewModal={storeUI.closeReview}
        errorsText={storeUI.textMainErrors}
        errorsRating={storeUI.errors.rating}
        text={storeUI.text}
        rating={storeUI.rating}
        stars={storeUI.stars}
        sendReview={storeUI.sendReview}
        isLoading={storeUI.isLoadingReview}
        onChangeText={storeUI.onChangeText}
        hoverRating={storeUI.hoverRating}
        setHoverRating={storeUI.setHoverRating}
        setRating={storeUI.setRating}
        apartmentId={storeUI.apartmentId}
        bookingId={storeUI.bookingId}
      />
    </>
  );
});
