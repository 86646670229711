import { FC } from "react";

import RSelect from "react-select";

import styles from "./styles/select.module.scss";

interface IProps {
  label?: string;
  placeholder: string;
  className?: string;
  value: any;
  onChange: any;
  options: {
    id: number;
    label: string;
    value?: string | number | boolean;
  }[];
  errors?: string[];
  disabled?: boolean;
  isGenderMode?: boolean;
  isSearchable?: boolean;
}

const GREY = "#999999";
const BLACK = "#222222";
const WHITE = "#ffffff";
const BORDER = "#DFDFDF";

export const Select: FC<IProps> = ({
  label = "",
  placeholder = "",
  className = "",
  options,
  value,
  onChange,
  disabled = false,
  isGenderMode = false,
  isSearchable = false,
  errors = [],
}) => {
  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 70,
      paddingLeft: isGenderMode ? 0 : 6,

      borderRadius: 15,
      border: 0,
      boxShadow: "none",
      position: "relative",
      fontSize: 22,
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      color: BLACK,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      position: "absolute",
      top: 18,
      right: isGenderMode ? 0 : 16,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: GREY,
    }),
    option: (base: any, state: any) => ({
      ...base,
      height: 48,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: state.isSelected ? BLACK : GREY,
      ":hover": {
        color: state.isFocus ? GREY : BLACK,
      },
      backgroundColor: state.isSelected ? WHITE : WHITE,
      ":active": {
        backgroundColor: state.isSelected ? WHITE : WHITE,
      },
      borderBottom: `1px solid ${BORDER}`,
      "&:last-child": {
        borderBottom: "0",
      },
      scrollbar: "none",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (base: any) => ({
      ...base,
      position: "absolute",
      width: isGenderMode ? 120 : `100%`,
      left: isGenderMode ? 0 : "",
      borderRadius: 16,
      right: 0,
      fontSize: 16,
      boxShadow: `0px 4px 24px rgba(0, 0, 0, 0.24)`,
    }),
    menuList: (base: any) => ({
      ...base,
      borderRadius: 16,
      "::-webkit-scrollbar": {
        display: "none",
        opacity: 0,
      },
      scrollbarWidth: "none",
    }),
  };
  return (
    <div className={`${styles.selectBlock} ${className}`}>
      <label className={styles.selectBlock__label}>{label}</label>
      <span className={styles.selectBlock__selectWrapper}>
        <RSelect
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          styles={customStyles}
          className={styles.selectBlock__select}
          options={options}
          isSearchable={isSearchable}
          isDisabled={disabled}
        />
        {errors?.length !== 0 && (
          <span className={styles.selectBlock__errorText}>{errors[0]}</span>
        )}
      </span>
    </div>
  );
};
