import { FC, useEffect, useContext, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  DESCRIPTION_PAGE_DEFAULT,
  PAGE_PAYMENT_RESULT,
} from "../../../constants";

import { Button } from "../../../components/Button";
import { Footer } from "../../../components/Footer";
import { Loader } from "../../../components/Loader";

import { RootContext } from "../../../stores/Root";
import { StoreUI } from "../storeUI";

import { EPaymentStatus } from "../storeUI/interfaces";

import { EPageType, EPaymentMethods } from "../../../types";

import { ReactComponent as Pending } from "../../../assets/images/paymentPending.svg";
import { ReactComponent as Canceled } from "../../../assets/images/paymentCanceled.svg";
import { ReactComponent as Succeeded } from "../../../assets/images/paymentSucceeded.svg";

import styles from "./styles/index.module.scss";

export const BankResult: FC = observer(() => {
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(
    () => new StoreUI(rootStore.storePayment, navigate)
  );

  useEffect(() => {
    storeUI.init();
    (async () => {
      await storeUI.updatePaymentStatus();
    })();
  }, [storeUI]);

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.PaymentResult
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_PAYMENT_RESULT}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  let paymentStatus = useMemo(() => {
    switch (storeUI.paymentStatus) {
      case EPaymentStatus.Pending:
        return !storeUI.isLoading ? (
          <>
            <Pending />
            <h2 className={styles.container__title}>
              Подтверждение оплаты банка
            </h2>
            <p className={styles.container__description}>
              {storeUI.paymentStrategy === EPaymentMethods.SberBusiness
                ? "Статус оплаты можете отслеживать в СберБизнес"
                : "Оплата будет произведена в течение 1 часа"}
            </p>
            <Button
              className={styles.container__button}
              title="продолжить"
              theme="gold"
              onClick={storeUI.toDocuments}
            />
          </>
        ) : (
          <Loader />
        );
      case EPaymentStatus.Canceled:
        return !storeUI.isLoading ? (
          <>
            <Canceled />
            <h2 className={styles.container__title}>Платеж не прошел</h2>
            <p className={styles.container__description}>
              При обработке платежа произошла ошибка
            </p>
            <Button
              className={styles.container__button}
              title="продолжить"
              theme="gold"
              onClick={storeUI.toDocuments}
            />
          </>
        ) : (
          <Loader />
        );
      case EPaymentStatus.Succeeded:
        return !storeUI.isLoading ? (
          <>
            <Succeeded />
            <h2 className={styles.container__title}>Оплата прошла успешно</h2>
            <Button
              className={styles.container__button}
              title="продолжить"
              theme="gold"
              onClick={storeUI.toDocuments}
            />
          </>
        ) : (
          <Loader />
        );

      default:
        break;
    }
  }, [
    storeUI.paymentStatus,
    storeUI.isLoading,
    storeUI.paymentStrategy,
    storeUI.toDocuments,
  ]);

  return (
    <div className={styles.container}>
      {helmet}

      {storeUI.isLoading ? (
        <div className={styles.container__loaderBlock}>
          <Loader className={styles.container__loader} />
        </div>
      ) : (
        <div className={styles.container__content}>{paymentStatus}</div>
      )}

      <Footer className={styles.container__footer} />
    </div>
  );
});
