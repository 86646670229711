import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Button } from "../Button";
import { InfoItem } from "./InfoItem";

import { SCREENS } from "../../navigation/endpoints";
import { changeSpaceInNumber } from "../../helpers";

import { FlatProps } from "../../interfaces";

import styles from "./styles/flat.module.scss";

export const Flat: FC<FlatProps> = observer(
  ({
    id = 0,
    image = "",
    className = "",
    square = "0",
    flatNumber = "0",
    floor = "0",
    pets = false,
    minPeople = "1",
    maxPeople = "",
    openFeedback = () => {},
    price = 0,
    onClickFlatCallback = () => {},
  }) => {
    const navigate = useNavigate();

    const _toApartment = () => {
      navigate(`${SCREENS.SCREEN_APARTMENTS}/${id}`);
      onClickFlatCallback();
    };

    return (
      <div className={`${styles.card} ${className}`}>
        <div className={styles.card__info} onClick={_toApartment}>
          <div className={styles.card__wrapper}>
            {image ? (
              <img src={image} className={styles.card__img} alt="flat" />
            ) : (
              <div className={styles.card__imgEmpty}>
                Не удалось загрузить изображение
              </div>
            )}
            <div className={styles.card__price}>
              {changeSpaceInNumber(price)} ₽ / мес.
            </div>
            <div className={styles.card__labels}>
              <InfoItem type="square" square={square} />
              <InfoItem
                type="people"
                minPeople={minPeople}
                maxPeople={maxPeople}
              />
              {pets && <InfoItem type="pets" />}
            </div>
          </div>
        </div>
        <div className={styles.card__desc}>
          <h5>
            Квартира {flatNumber}, {floor} этаж
          </h5>
          <div className={styles.card__buttons}>
            <Button
              className={styles.card__button}
              title="ЗАБРОНИВАТЬ"
              theme="gold"
              onClick={_toApartment}
            />
            <p className={styles.card__request} onClick={openFeedback}>
              ОСТАВИТЬ ЗАЯВКУ
            </p>
          </div>
        </div>
      </div>
    );
  }
);
