import { FC, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { observer } from "mobx-react-lite";

import { spinElementOnScroll } from "../../../../helpers/spinElementOnScroll";

import { Slide } from "./Slide";

import { ReactComponent as ImgFrame } from "../../../../assets/images/imgFrame.svg";

import slide from "../../../../assets/images/slides/slide.png";

import "swiper/scss";
import "swiper/scss/pagination";
import "./styles/slider.scss";

const slides = [
  {
    id: 1,
    img: slide,
  },
  {
    id: 2,
    img: slide,
  },
  {
    id: 3,
    img: slide,
  },
  {
    id: 4,
    img: slide,
  },
  {
    id: 5,
    img: slide,
  },
  {
    id: 6,
    img: slide,
  },
];

const maxDegreeCircle = 90;

export const Slider: FC = observer(() => {
  let circleRef = useRef(null);

  useEffect(() => {
    spinElementOnScroll(circleRef, maxDegreeCircle);
  }, []);

  return (
    <div className="sliderWrapper">
      <div id="circleFrame" className="circleWrapper" ref={circleRef}>
        <ImgFrame className="slideFrame" />
      </div>

      <Swiper
        className="mainSwiper"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        modules={[Autoplay, Pagination]}
        loop={true}
        slidesPerView={1}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <Slide img={slide.img} className="slideItem" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});
