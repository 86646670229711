import { observer } from "mobx-react-lite";

import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";
import { Sorting } from "../Sorting";
import { FilterBtn } from "./FilterBtn";
import { CheckBox } from "../../../components/CheckBox";

import { IRooms, ISorting, ITypes } from "../../../interfaces/Catalog";

import styles from "./styles/filters.module.scss";

interface IProps {
  count: number;
  isCheckedAvailable: boolean;
  fromPrice: string;
  toPrice: string;
  floorOptions: ITypes[];
  flatsOptions: IRooms[];
  floor: ITypes | null;
  countOfRooms: IRooms | null;
  sortSelected: ISorting;
  sortList: ISorting[];
  errorsPrices: string[];
  countApartaments: number | null;
  className?: string;
  openModalFilters(): void;
  onChangeFloor(value: ITypes): void;
  onChangeCount(rooms: IRooms): void;
  onChangeCheckedAvailable(value: boolean): void;
  submit(pageNumber: number): void;
  changeSort(value: ISorting): void;
  onClearAll(): void;
  changeToPrice(value: string): void;
  changeFromPrice(value: string): void;
  getApartments(): void;
}

export const Filters = observer(
  ({
    isCheckedAvailable = false,
    className = "",
    fromPrice = "",
    toPrice = "",
    count = 0,
    floor = null,
    countOfRooms = null,
    countApartaments = null,
    floorOptions = [],
    flatsOptions = [],
    errorsPrices = [],
    sortList = [],
    sortSelected = { value: "", type: null },
    onChangeFloor = () => {},
    onChangeCount = () => {},
    onChangeCheckedAvailable = () => {},
    changeFromPrice = () => {},
    changeToPrice = () => {},
    submit = () => {},
    changeSort = () => {},
    onClearAll = () => {},
    getApartments = () => {},
    openModalFilters = () => {},
  }: IProps) => {
    return (
      <div className={`${styles.filters} ${className}`}>
        <div className={styles.filters__content}>
          <div className={styles.filters__topInputs}>
            <div className={styles.filters__selects}>
              <Select
                value={floor}
                onChange={onChangeFloor}
                label="Этаж"
                options={floorOptions}
                placeholder="Выберите этаж"
                className={styles.filters__select}
              />

              <Select
                value={countOfRooms}
                onChange={onChangeCount}
                label="Количество комнат"
                options={flatsOptions}
                placeholder="Количество комнат"
                className={styles.filters__select}
              />
            </div>
            <div className={styles.filters__prices}>
              <Input
                value={fromPrice}
                onChange={changeFromPrice}
                className={styles.filters__input}
                label="От (₽)"
                placeholder=""
                maxLength={7}
                onBlur={getApartments}
              />
              <Input
                value={toPrice}
                onChange={changeToPrice}
                className={styles.filters__input}
                label="До (₽)"
                placeholder=""
                maxLength={7}
                onBlur={getApartments}
              />
              {errorsPrices?.length > 0 && (
                <span className={styles.filters__pricesError}>
                  {errorsPrices[0]}
                </span>
              )}
            </div>
            <FilterBtn
              onClick={openModalFilters}
              className={styles.filters__filterBtn}
              count={count}
            />
          </div>
          <div className={styles.filters__bottomBlock}>
            <Sorting
              changeSort={changeSort}
              sortList={sortList}
              sortSelected={sortSelected}
              className={styles.filters__sorting}
            />

            <div className={styles.filters__checkboxContainer}>
              <CheckBox
                checked={isCheckedAvailable}
                setChecked={onChangeCheckedAvailable}
              />
              <p className={styles.filters__checkboxText}>
                Доступные для бронирования
              </p>
            </div>

            <div className={styles.filters__buttonsWrapper}>
              <button
                onClick={onClearAll}
                className={styles.filters__buttonClearAll}
              >
                Очистить все
              </button>
              <Button
                className={styles.filters__buttonShow}
                title={`ПОКАЗАТЬ ${
                  countApartaments !== null ? `(${countApartaments})` : ""
                }`}
                theme="gold"
                onClick={() => submit(1)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
