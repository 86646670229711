import { observer } from "mobx-react-lite";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Loader } from "../../components/Loader";
import { RentalInfo } from "../../components/RentalInfo";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Footer } from "../../components/Footer";
import { ModalReuse } from "../../components/Modals/ModalReuse";

import { RootContext } from "../../stores/Root";
import { StoreUI } from "./storeUI";

import { Store } from "../../stores/types";
import { EPageType } from "../../types";

import { DESCRIPTION_PAGE_DEFAULT, PAGE_TITLE_BOOKING } from "../../constants";

import styles from "./styles/order.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
}

export const Order = observer(({ storeAuthentication }: IProps) => {
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);

  const [storeUI] = useState(
    () => new StoreUI(storeAuthentication, rootStore, navigate)
  );

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.Booking
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_BOOKING}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <div className={styles.order}>
      {helmet}
      {storeUI.isLoading ? (
        <div className={styles.order__loadingBlock}>
          <Loader className={styles.order__loader} />
        </div>
      ) : (
        <>
          <h1 className={styles.order__title}>
            Заполните информацию для бронирования
          </h1>

          {storeUI.isLoadingBooking && (
            <Loader className={styles.order__loaderBooking} />
          )}

          <div className={styles.order__block}>
            <h2
              className={`${styles.order__subtitle} ${styles.order__subtitle_rental}`}
            >
              Информация по аренде
            </h2>
            <RentalInfo
              apartmentName={storeUI.firstApartment.apartmentName}
              apartmentNumber={storeUI.firstApartment.apartmentNumber}
              floor={storeUI.firstApartment.floor}
              period={storeUI.firstApartment.period}
              amountOfDays={storeUI.firstApartment.amountDays}
              monthPrice={storeUI.firstApartment.monthPrice}
              deposit={storeUI.firstApartment.deposit}
              className={styles.order__rentalInfo}
            />

            <span className={styles.order__checkinDate}>
              *Ближайшая дата заселения {storeUI.firstApartment.checkinDate}
            </span>
          </div>

          <div
            className={`${styles.order__block} ${styles.order__block_renter}`}
          >
            <h2 className={styles.order__subtitle}>Арендатор</h2>

            <button
              onClick={storeUI.fillFromProfile}
              className={`${styles.order__fillBtn} ${styles.order__fillBtn_mobileBtn}`}
              disabled={storeUI.isDisabledAll}
            >
              Заполнить данные из профиля
            </button>

            <div className={styles.order__formWrapper}>
              <div className={styles.order__twoInputs}>
                <Input
                  label={"Фамилия"}
                  placeholder="Иванов"
                  value={storeUI.lastName}
                  onChange={storeUI.changeLastName}
                  errors={storeUI.validateErrors.lastName}
                  readonly={storeUI.isDisabledAll}
                  className={styles.order__input}
                />

                <Input
                  label={"Имя"}
                  placeholder="Петр"
                  value={storeUI.firstName}
                  onChange={storeUI.changeFirstName}
                  errors={storeUI.validateErrors.firstName}
                  readonly={storeUI.isDisabledAll}
                  className={styles.order__input}
                />
              </div>

              <div className={styles.order__twoInputs}>
                <Input
                  label={"Отчество"}
                  placeholder="Иванович"
                  value={storeUI.middleName}
                  onChange={storeUI.changeMiddleName}
                  errors={storeUI.validateErrors.middleName}
                  readonly={storeUI.isDisabledAll}
                  className={styles.order__input}
                />

                <Input
                  label={"E-mail"}
                  placeholder="example@mail.ru"
                  value={storeUI.email}
                  onChange={storeUI.changeEmail}
                  errors={storeUI.validateErrors.email}
                  readonly={storeUI.isDisabledAll}
                  className={styles.order__input}
                />
              </div>
            </div>
          </div>

          <div className={styles.order__errorButtonWrapper}>
            {storeUI.serverErrors.length > 0 && (
              <span className={styles.order__error}>
                {storeUI.serverErrors[0]}
              </span>
            )}

            <Button
              title={
                storeUI.isSuccessBooking ? "Перейти в Мои брони" : "Продолжить"
              }
              onClick={storeUI.onClickContinue}
              theme={"gold"}
              className={styles.order__buttonContinue}
              disabled={storeUI.isLoading || storeUI.isLoadingBooking}
              isDisabledStyle={storeUI.isLoading || storeUI.isLoadingBooking}
            />
          </div>
        </>
      )}

      <Footer className={styles.order__footer} />

      <ModalReuse
        isShowModal={storeUI.storeModals.isVisibleFillProfile}
        setIsShowModal={storeUI.storeModals.setIsVisibleFillProfile}
        type={"button"}
        btnTitle={"ПЕРЕЙТИ В ПРОФИЛЬ"}
        classNameModal={styles.order__modalReuse}
        onClickSingleButton={storeUI.toProfile}
      >
        <p className={styles.order__modalReuseTitle}>
          Заполните свой профиль <br /> полностью
        </p>
      </ModalReuse>

      <ModalReuse
        isShowModal={storeUI.storeModals.isVisibleFillCompany}
        setIsShowModal={storeUI.storeModals.setIsVisibleFillCompany}
        type={"button"}
        btnTitle={"ЗАПОЛНИТЬ КОМПАНИЮ"}
        classNameModal={styles.order__modalReuse}
        onClickSingleButton={storeUI.toCompany}
      >
        <p className={styles.order__modalReuseTitle}>
          Заполните данные о компании
        </p>
      </ModalReuse>

      <ModalReuse
        isShowModal={storeUI.storeModals.isVisibleBillLater}
        setIsShowModal={storeUI.storeModals.setIsVisibleBillLater}
        type={"button"}
        btnTitle={"Перейти в Мои брони"}
        classNameModal={styles.order__modalReuse}
        onClickSingleButton={storeUI.toMyBookings}
      >
        <p className={styles.order__modalReuseTitle}>
          Счет на оплату залога будет сформирован позже. Вы сможете оплатить его
          в разделе Мои брони. В течение нескольких минут там появится новая
          бронь
        </p>
      </ModalReuse>
    </div>
  );
});
