import { TDateMinMax } from "../../../../../types";

export interface IReadyApartment {
  image: string;
  rooms: string;
  square: number;
  floor: number;
  flatNumber: number;
  status: string;
  tenants: number;
  startDate: string;
  endDate: string; // в измененном относительно бэка формате ("2022-10-10" -> "10 октября")
  endDateOriginal: string; // изначальная строка с бэка
  datePeriod: string;
  dateEnding: number;
  isVisibleEndBooking: boolean;
  checkinDate: string | null;
}

export interface IStoreUI {
  isLoading: boolean;
  isLoadingPopup: boolean;
  visibleExtension: boolean;
  isCanBeExtended: boolean;
  apartmentInfo: IReadyApartment | null;
  bookingId: number;
  haveArrivalDate: boolean;
  init(bookingId: string): void;
  toApartments(): void;
  toTenants(idBooking: number): void;
  closeExtension(): void;
  openExtension(): void;
}

export interface IDatePeriod {
  minDate: TDateMinMax;
  maxDate: TDateMinMax;
}

export const BILL_NOT_PAID = "not paid";
