import { FC, useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../components/Button";
import { Footer } from "../../../components/Footer";
import { Loader } from "../../../components/Loader";

import { RootContext } from "../../../stores/Root";
import { StoreUI } from "../storeUI";

import { ReactComponent as Success } from "../../../assets/images/sberSuccess.svg";
import { ReactComponent as SberNoSuccess } from "../../../assets/images/sberNoSuccess.svg";

import styles from "./styles/index.module.scss";

export const SberAuth: FC = observer(() => {
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(
    () => new StoreUI(rootStore.storePayment, navigate)
  );

  useEffect(() => {
    storeUI.init();
    (async () => {
      await storeUI.registerSberBusiness();
    })();
  }, [storeUI]);

  return (
    <div className={styles.container}>
      <div className={styles.container__content}>
        {storeUI.isLoading && <Loader className={styles.container__loader} />}
        {storeUI.isSuccessSberAuth ? <Success /> : <SberNoSuccess />}
        <h2 className={styles.container__title}>
          {storeUI.isSuccessSberAuth
            ? "Авторизация прошла успешно"
            : "Ошибка авторизации"}
        </h2>
        <p className={styles.container__description}>
          {storeUI.isSuccessSberAuth
            ? "Теперь вы можете оплачивать счета через СберБизнес"
            : "Выполнение операции прервано из-за ошибки авторизации"}
        </p>
        <Button
          className={styles.container__button}
          title={storeUI.isSuccessSberAuth ? "продолжить" : "в личный кабинет"}
          theme="gold"
          onClick={storeUI.toDocuments}
          disabled={storeUI.isLoading}
          isDisabledStyle={storeUI.isLoading}
        />
      </div>
      <Footer className={styles.container__footer} />
    </div>
  );
});
