import { FC, FormEvent, useState } from "react";
import { observer } from "mobx-react-lite";

import styles from "./styles/input.module.scss";

import { ReactComponent as ErrorIcon } from "../../assets/images/errorIcon.svg";

interface IProps {
  placeholder?: string;
  label: string;
  value?: string;
  errors?: string[];
  onChange?(value: string): void;
  onBlur?: (e: any) => void;
  className?: string;
  type?: string;
  withInfoIcon?: boolean;
  infoIconText?: string;
  pattern?: string;
  maxLength?: number;
  readonly?: boolean;
}

export const Input: FC<IProps> = observer(
  ({
    placeholder = "",
    label = "",
    value = "",
    className = "",
    type = "",
    infoIconText = "",
    withInfoIcon = false,
    errors = [],
    pattern = "",
    maxLength = 524288,
    onChange = () => {},
    onBlur = () => {},
    readonly = false,
  }) => {
    const [visibleIconText, setVisibleIconText] = useState(false);

    const changeVisibleIconText = () => {
      setVisibleIconText(!visibleIconText);
    };

    const _onChange = (e: FormEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    };
    return (
      <div className={`${styles.inputBlock} ${className}`}>
        <label className={styles.inputBlock__label}>{label}</label>
        <span className={styles.inputBlock__inputWrapper}>
          <input
            className={styles.inputBlock__input}
            value={value}
            onChange={_onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            type={type}
            pattern={pattern}
            maxLength={maxLength}
            readOnly={readonly}
          />
          {errors?.length !== 0 && (
            <span className={styles.inputBlock__errorText}>
              {errors[0]}
              {withInfoIcon && (
                <span className={styles.inputBlock__infoIconWrapper}>
                  <ErrorIcon
                    onClick={changeVisibleIconText}
                    className={styles.inputBlock__infoIcon}
                  />
                  {visibleIconText && (
                    <div className={styles.inputBlock__infoIconPopup}>
                      {infoIconText}
                    </div>
                  )}
                </span>
              )}
            </span>
          )}
        </span>
      </div>
    );
  }
);
