import { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, FreeMode } from "swiper";
import { ThumbsOptions } from "swiper/types";

import { ISliderItem } from "../../../../interfaces/Apartment";

import { ReactComponent as Loupe } from "../../../../assets/images/loupe.svg";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/thumbs";
import "./styles/slider.scss";

type SwiperSlides = ThumbsOptions["swiper"];

interface IProps {
  slides: ISliderItem[];
  openSliderInner(): void;
}

const Slider: FC<IProps> = observer(
  ({ slides = [], openSliderInner = () => {} }) => {
    const [thumbsSwiperMore, setThumbsSwiperMore] =
      useState<SwiperSlides>(null);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperSlides>(null);

    const countMoreImage =
      slides.length > 5 ? slides.slice(4, slides.length).length : null;

    const cutToIndex = countMoreImage ? 4 : 5;

    const _openSlider = () => {
      openSliderInner();
    };

    return (
      <div className={"wrapperSlider"}>
        {slides.length > 0 && (
          <button className={"loupeButton"} onClick={_openSlider}>
            <Loupe />
          </button>
        )}
        <div className="fullslider">
          <Swiper
            loop={true}
            spaceBetween={10}
            navigation={slides.length > 1}
            modules={[FreeMode, Navigation, Thumbs]}
            grabCursor={true}
            thumbs={{
              swiper: !thumbsSwiper?.destroyed ? thumbsSwiper : null,
            }}
            className="product-images-slider flats-slider"
            allowSlideNext={slides.length > 1}
            allowSlidePrev={slides.length > 1}
          >
            {slides.length ? (
              slides.map((slide) => (
                <SwiperSlide key={slide.id}>
                  <img src={slide.image} alt="flat" onClick={_openSlider} />
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <div className="imgEmpty">
                  <p>Не удалось загрузить изображение</p>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          {/* SLIDER 1*/}
          <Swiper
            loop={true}
            spaceBetween={10}
            navigation={slides.length > 1}
            modules={[FreeMode, Navigation, Thumbs]}
            grabCursor={true}
            thumbs={{
              swiper: !thumbsSwiperMore?.destroyed ? thumbsSwiperMore : null,
            }}
            className="product-images-slider flats-slider-more"
            allowSlideNext={slides.length > 1}
            allowSlidePrev={slides.length > 1}
          >
            {slides.length ? (
              slides.map((slide) => (
                <SwiperSlide key={slide.id}>
                  <img src={slide.image} alt="flat" />
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <div className="imgEmpty">Не удалось загрузить изображение</div>
              </SwiperSlide>
            )}
          </Swiper>
          {/* НИЖНЯЯ ЧАСТЬ СЛАЙДЕРА адаптив (МИНИ КАРТИНКИ)*/}
          {
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={8}
              slidesPerView={window.innerWidth <= 576 ? "auto" : 5}
              modules={[FreeMode, Navigation, Thumbs]}
              freeMode={true}
              watchSlidesProgress={true}
              className="product-images-slider-thumbs flats-thumbs"
            >
              {slides.map((slide) => (
                <SwiperSlide key={slide.id}>
                  <div className={"product-images-slider-thumbs-wrapper"}>
                    <img src={slide.image} alt="flat" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          }
          {/* НИЖНЯЯ ЧАСТЬ СЛАЙДЕРА ДЕСКТОП (МИНИ КАРТИНКИ)*/}
          <div className="thumbs-more-wrapper">
            <Swiper
              onSwiper={setThumbsSwiperMore}
              spaceBetween={16}
              slidesPerView={window.innerWidth <= 576 ? 3 : 4}
              modules={[FreeMode, Navigation, Thumbs]}
              freeMode={true}
              watchSlidesProgress={true}
              className="product-images-slider-thumbs flats-thumbs-more"
            >
              {slides.slice(0, cutToIndex).map((slide) => (
                <SwiperSlide key={slide.id}>
                  <div className={"product-images-slider-thumbs-wrapper"}>
                    <img src={slide.image} alt="flat" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {countMoreImage && (
              <div className={"more-slides"} onClick={_openSlider}>
                <span className="moreSlidesCounter">+{countMoreImage}</span>
                <img
                  src={slides[5].image}
                  alt="flat"
                  className="moreSlidesImg"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default Slider;
