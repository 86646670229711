import { observer } from "mobx-react-lite";

import { ReactComponent as SberID } from "../../assets/images/sberID.svg";

import styles from "./styles/buttonSberBusiness.module.scss";

interface IProps {
  isLoading: boolean;
  loginSberBusiness(): void;
  className?: string;
}
export const ButtonSberBusiness = observer(
  ({
    isLoading = false,
    loginSberBusiness = () => {},
    className = "",
  }: IProps) => {
    return (
      <button
        disabled={isLoading}
        onClick={loginSberBusiness}
        className={`${styles.buttonSberBusiness} ${className}`}
      >
        <SberID />
        СберБизнес
      </button>
    );
  }
);
