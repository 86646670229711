import { useEffect, useState } from "react";

export default function useWindowScrollPositions() {
  const [positions, setPositions] = useState({ scrollX: 0, scrollY: 0 });

  function updatePosition() {
    setPositions({ scrollX: window.scrollX, scrollY: window.scrollY });
  }

  useEffect(() => {
    document.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return { scrollPositions: positions };
}
