import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../components/Button";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/service.module.scss";

interface IProps {
  id: number;
  title: string | null;
  desc: string | null;
  image: string | null;
  price: string | null;
  isAvailable: boolean;
  isAdded: boolean;
  haveApartment: boolean;
  deleteFromBasket(serviseId: number): void;
  addToBasket(serviceId: number): void;
}

export const Service: FC<IProps> = observer(
  ({
    id = 0,
    title = "",
    desc = "",
    price = 0,
    isAvailable = false,
    isAdded = false,
    haveApartment = false,
    image = "",
    deleteFromBasket = () => {},
    addToBasket = () => {},
  }) => {
    // длины строк для уменьшения шрифта (применяются только здесь)
    const _lengthNormalStyle = 16;
    const _lengthSmallStyle = 35;
    const _lengthPriceSmallStyle = 7;

    const getClass = (lengthNum: number) => {
      if (lengthNum <= _lengthNormalStyle) {
        return `normal`;
      }

      if (lengthNum <= _lengthSmallStyle) {
        return `small`;
      }

      if (lengthNum > _lengthSmallStyle) {
        return `supersmall`;
      }
    };

    return (
      <div className={styles.container}>
        <div className={styles.container__imgWrapper}>
          {image?.length ? (
            <img
              className={styles.container__img}
              src={image}
              alt="serviceImg"
            />
          ) : (
            <div className={styles.container__imgEmpty}>
              Не удалось загрузить изображение
            </div>
          )}
        </div>

        <div className={styles.container__content}>
          <div className={styles.container__titlePriceWrapper}>
            <h3
              className={`${styles.container__title} ${
                title && styles[`container__title_${getClass(title.length)}`]
              }`}
            >
              {title ?? "Заголовок не загружен"}
            </h3>
            <span
              className={`${styles.container__price} ${
                (title?.length ?? 0) > _lengthSmallStyle &&
                String(price).length >= _lengthPriceSmallStyle &&
                styles.container__price_small
              }`}
            >
              {price ?? "0 000"} ₽
            </span>
          </div>

          <p className={styles.container__description}>{desc ?? ""}</p>

          {haveApartment && (
            <div className={styles.container__statusWrapper}>
              {isAvailable ? (
                <>
                  {isAdded ? (
                    <div className={styles.container__statusAdded}>
                      <Button
                        className={styles.container__buttonAdded}
                        title="ДОБАВЛЕНО"
                        theme="gold"
                        onClick={() => {}}
                      />
                      <button
                        className={styles.container__buttonDelete}
                        onClick={() => deleteFromBasket(id)}
                      >
                        <Close className={styles.container__buttonDeleteIcon} />
                      </button>
                    </div>
                  ) : (
                    <Button
                      className={styles.container__button}
                      title="В КОРЗИНУ"
                      theme="transparent"
                      onClick={() => addToBasket(id)}
                    />
                  )}
                </>
              ) : (
                <div className={styles.container__statusNoAvailable}>
                  <Button
                    className={styles.container__buttonNoAvailable}
                    title="В КОРЗИНУ"
                    theme="transparent"
                    onClick={() => {}}
                  />
                  <span className={styles.container__spanNoAvailable}>
                    Недоступно для выбранной квартиры
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
