import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";
import { Validator } from "validator-pyrobyte";

import { newRules } from "../../../helpers/validation";
import { changeSpaceInNumber, declensionWordDays } from "../../../helpers";
import { getNextDay } from "../../../helpers/getNextDay";
import { enableScroll } from "../../../helpers/modalsFunc";

import { SCREENS } from "../../../navigation/endpoints";

import {
  BACK_TO_ORDER,
  ERROR_MIN_CHARS_TWO,
  ERROR_TEXT_EMPTY,
  FORMAT_DAY_MONTH,
  MY_APARTMENT_INFO_DEFAULT,
  PAGE_ORDERING_DATA_BY_ID,
  PAGE_RENT_BY_ID,
  TYPE_PERSON_LEGAL,
} from "../../../constants";

import {
  IApartmentInfo,
  IFormErrors,
  IModalsStoreUI,
  IStoreUI,
} from "./interfaces";

import { Store } from "./../../../stores/types/index";
import { EChoosePayType, EInputsNames } from "../../../types";

import { ModalsStoreUI } from "./modals";
export class StoreUI implements IStoreUI {
  validator: Validator;
  storeRoot: Store.IRootStore;
  storeBooking: Store.IBooking;
  storeAuthentication: Store.IStoreAuthentication;
  storeBills: Store.IBillsStore;
  storeChoosePay: Store.IChoosePay;

  storeModals: IModalsStoreUI;

  navigate: NavigateFunction;

  isLoading: boolean = false;
  isLoadingBooking: boolean = false;
  isFillCompany: boolean = false;

  isSuccessBooking: boolean = false;

  isTypeLegal: boolean = false;

  bookingId: number | null = null;

  // форма
  lastName: string = "";
  firstName: string = "";
  middleName: string = "";
  email: string = "";

  validateErrors: IFormErrors = {
    lastName: [],
    firstName: [],
    middleName: [],
    email: [],
  };

  serverErrors: string[] = [];

  constructor(
    storeAuthentication: Store.IStoreAuthentication,
    storeRoot: Store.IRootStore,
    navigate: NavigateFunction
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
    this.storeBooking = storeRoot.storeBooking;
    this.storeAuthentication = storeAuthentication;
    this.storeChoosePay = storeRoot.storeModals.storeChoosePay;
    this.storeBills = storeRoot.storeBills;
    this.navigate = navigate;
    this.storeModals = new ModalsStoreUI(this);

    this.validator = new Validator(
      {
        email: {
          email: true,
          required: true,
        },

        specialChars: {
          specialChars: true,
        },
      },
      newRules
    );
  }

  // getters
  get firstApartment(): IApartmentInfo {
    return this.apartmentsList[0];
  }

  get apartmentsList() {
    if (this.storeBooking.apartmentsList?.length) {
      return this.storeBooking.apartmentsList.map((apartment) => ({
        apartmentName: this.getReadyName(apartment.roomLabel, apartment.square),
        floor: String(apartment.floor) || "0",
        apartmentNumber: String(apartment.flatNumber) || "0",
        period: `от: ${this.reverseAndChangeSeparator(
          apartment.period.from,
          "-",
          "."
        )} - до: ${this.reverseAndChangeSeparator(
          apartment.period.to,
          "-",
          "."
        )}`,
        amountDays: this.convertDaysToValidity(apartment.amountOfDays),
        monthPrice: changeSpaceInNumber(apartment.monthPrice || 0),
        deposit: changeSpaceInNumber(apartment.monthPrice || 0),
        checkinDate: getNextDay(apartment.period.from, FORMAT_DAY_MONTH),
      }));
    } else {
      return [MY_APARTMENT_INFO_DEFAULT];
    }
  }

  get isDisabledAll() {
    return this.isSuccessBooking || this.isLoading || this.isLoadingBooking;
  }

  // functions
  *init() {
    enableScroll();
    this.setIsLoading(true);
    let response: boolean = yield this.storeBooking.getBasket();
    if (!response && !this.storeBooking.apartmentsList?.length) {
      this.navigate(SCREENS.SCREEN_404);
      return;
    }

    this.setIsTypeLegal(
      this.storeRoot.userProfile.personalType === TYPE_PERSON_LEGAL
    );

    if (this.isTypeLegal) {
      yield this.checkFillCompany();
    }

    this.fillFromProfile();
    this.setIsLoading(false);
  }

  private *sendBooking() {
    this.setServerErrors([]);

    let params = {
      renter: {
        lastName: this.lastName,
        firstName: this.firstName,
        middleName: this.middleName,
        email: this.email,
      },
    };

    yield this.storeBooking.sendBooking(params);

    this.setIsSuccessBooking(this.storeBooking.isSuccessBook);
    this.setServerErrors(this.storeBooking.errorsBooking);

    if (this.isSuccessBooking && this.storeBooking.bookingId) {
      this.setBookingId(this.storeBooking.bookingId);
    }
  }

  private *getBookingBill() {
    this.setIsLoadingBooking(true);
    yield this.sendBooking();

    // если запрос успел пройти за 60 секунд
    if (this.storeBooking.isSuccessBook) {
      this.storeRoot.clearNumberItemsFromBasket();
      // переход на попап Оплата залога
      if (this.bookingId) {
        //получить счета по id букинга, первый (единственный) счет будет счетом на оплату залога
        yield this.storeBills.getBills(this.bookingId);

        if (this.storeBills.bills.length === 1) {
          let _bill = this.storeBills.bills[0];
          this.openPaymentPopup(_bill.id, _bill.documentId);
        }
      }
    } else {
      if (this.storeBooking.isCancelBookRequest) {
        // переход на попап Счет будет позже
        this.storeModals.openBillLater();
      }
      // если отменен НЕ из-за таймаута - выходим и выводится ошибка
    }

    this.setIsLoadingBooking(false);
  }

  *onClickContinue() {
    if (this.isSuccessBooking) {
      this.navigate(SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS);
      return;
    }

    if (this.isTypeLegal) {
      yield this.checkFillCompany();

      if (this.isFillCompany && this.validateAll()) {
        yield this.getBookingBill();
      }
    } else {
      if (this.validateAll()) {
        yield this.getBookingBill();
      }
    }
  }

  // проверка на заполненность компании
  *checkFillCompany() {
    yield this.storeRoot.checkFilledCompany(
      this.storeAuthentication?.isAuth ?? false
    );

    if (!this.storeRoot.isFilledCompany) {
      this.storeModals.openFillCompany();
    }
    this.setIsFillCompany(this.storeRoot.isFilledCompany);
  }

  *fillFromProfile() {
    if (this.storeAuthentication.isAuth) {
      yield this.storeRoot.checkFilledInputsProfile([
        EInputsNames.LastName,
        EInputsNames.FirstName,
      ]);

      if (this.storeRoot.isFilledInputsProfile) {
        this.writeDataFromProfile();
        this.clearErrors();
      } else {
        this.storeModals.openFillProfilePopup();
      }
    }
  }

  openPaymentPopup(billId: number, documentId: number | null = null) {
    this.storeChoosePay.openChoosePay({
      id: billId,
      type: EChoosePayType.Pawn,
      documentId: documentId,
    });
  }

  writeDataFromProfile() {
    this.setLastName(this.storeRoot.userProfile.lastName ?? "");
    this.setFirstName(this.storeRoot.userProfile.firstName ?? "");
    this.setMiddleName(this.storeRoot.userProfile.middleName ?? "");
    this.setEmail(this.storeRoot.userProfile.email ?? "");
  }

  clearErrors() {
    this.validateErrors.lastName = [];
    this.validateErrors.firstName = [];
    this.validateErrors.middleName = [];
    this.validateErrors.email = [];
  }

  // ---- changes
  changeLastName(value: string) {
    this.setLastName(value);

    if (this.validateErrors.lastName.length) {
      this.validateErrors.lastName = [];
    }
  }

  changeFirstName(value: string) {
    this.setFirstName(value);

    if (this.validateErrors.firstName.length) {
      this.validateErrors.firstName = [];
    }
  }

  changeMiddleName(value: string) {
    this.setMiddleName(value);

    if (this.validateErrors.middleName.length) {
      this.validateErrors.middleName = [];
    }
  }

  changeEmail(value: string) {
    this.setEmail(value);

    if (this.validateErrors.email.length) {
      this.validateErrors.email = [];
    }
  }

  // ---- validation
  validateLastName() {
    if (!this.lastName.length) {
      this.validateErrors.lastName = [
        ...this.validateErrors.lastName,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.lastName = [];
    }

    if (this.lastName.length < 2) {
      this.validateErrors.lastName = [
        ...this.validateErrors.lastName,
        ERROR_MIN_CHARS_TWO,
      ];
      return false;
    } else {
      this.validateErrors.lastName = [];
    }
    return true;
  }

  validateFirstName() {
    if (!this.firstName.length) {
      this.validateErrors.firstName = [
        ...this.validateErrors.firstName,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.firstName = [];
    }

    if (this.firstName.length < 2) {
      this.validateErrors.firstName = [
        ...this.validateErrors.firstName,
        ERROR_MIN_CHARS_TWO,
      ];
      return false;
    } else {
      this.validateErrors.firstName = [];
    }
    return true;
  }

  validateMiddleName() {
    if (this.middleName.length > 0 && this.middleName.length < 2) {
      this.validateErrors.middleName = [
        ...this.validateErrors.middleName,
        ERROR_MIN_CHARS_TWO,
      ];
      return false;
    } else {
      this.validateErrors.middleName = [];
    }
    return true;
  }

  validateEmail() {
    let validEmail = this.validator.check("email", this.email);

    if (!this.email.length) {
      this.validateErrors.email = [
        ...this.validateErrors.email,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validateErrors.email = [];
    }

    if (!validEmail.passed) {
      this.validateErrors.email = [
        ...this.validateErrors.email,
        validEmail.errors[0],
      ];
      return false;
    } else {
      this.validateErrors.email = [];
    }

    return true;
  }

  validateAll() {
    this.validateLastName();
    this.validateFirstName();
    this.validateMiddleName();
    this.validateEmail();

    return (
      this.validateLastName() &&
      this.validateFirstName() &&
      this.validateMiddleName() &&
      this.validateEmail()
    );
  }

  // ---- helpers
  getReadyName(roomsTitle: string, square: number) {
    let _square = square ? Math.round(square) : 0;
    return `${roomsTitle ? roomsTitle : ""}, ${String(_square)} м²`;
  }

  reverseAndChangeSeparator(
    str: string,
    fromSeparator: string,
    toSeparator: string
  ) {
    if (str && fromSeparator && toSeparator) {
      let elemArr = str.split(fromSeparator).reverse();
      return elemArr.join(toSeparator);
    }
    return "";
  }

  convertDaysToValidity(amountDays: number) {
    return amountDays
      ? `${amountDays} ${declensionWordDays(amountDays)}`
      : "-- дней";
  }

  // ---- navigations

  toProfile() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_PERSONAL);
    enableScroll();
  }

  toCompany() {
    // передаем параметр при переходе на заполнение компании, что нужно вернуться после сохранения
    this.navigate(SCREENS.SCREEN_ACCOUNT_COMPANY, {
      state: { toBack: BACK_TO_ORDER },
    });
    enableScroll();
  }

  toMyBooking() {
    this.navigate(PAGE_RENT_BY_ID(String(this.bookingId)));
  }

  toMyBookings() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS);
  }

  toOrderingData() {
    if (this.bookingId)
      this.navigate(PAGE_ORDERING_DATA_BY_ID(String(this.bookingId)));
  }

  // setters
  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setLastName(value: string) {
    this.lastName = value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, "");
  }

  setFirstName(value: string) {
    this.firstName = value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, "");
  }

  setMiddleName(value: string) {
    this.middleName = value.replace(/[^a-zA-ZА-Я а-яЁё]/gi, "");
  }

  setEmail(value: string) {
    this.email = value;
  }

  setIsLoadingBooking(value: boolean) {
    this.isLoadingBooking = value;
  }

  setBookingId(value: number | null) {
    this.bookingId = value;
  }

  setIsSuccessBooking(value: boolean) {
    this.isSuccessBooking = value;
  }

  setIsFillCompany(value: boolean) {
    this.isFillCompany = value;
  }

  setIsTypeLegal(value: boolean) {
    this.isTypeLegal = value;
  }

  setServerErrors(value: string[]) {
    this.serverErrors = value;
  }
}
