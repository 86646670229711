import { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { Hint } from "../Hint";

import { ITab } from "../../interfaces";

import styles from "./styles/tabs.module.scss";

interface IProps {
  bookingId: string | null;
  activeTabName: string | null;
  className?: string;
  tabs: ITab[];
  onClick: (id: string, tabName: string) => void;
  isButtonMode?: boolean;
  classNameButtonHintText?: string;
}

export const Tabs: FC<IProps> = observer(
  ({
    bookingId = null,
    activeTabName = null,
    tabs = [],
    className = "",
    onClick = () => {},
    isButtonMode = false,
    classNameButtonHintText = "",
  }) => {
    let [visibleHint, setVisibleHint] = useState(false);
    let [currentHintId, setCurrentHintId] = useState<number | null>(null);

    const _onClick = (tabName: string, disabled: boolean) => {
      if (bookingId && !disabled) {
        onClick(bookingId, tabName);
      }
    };

    const showHint = (haveWarningText: boolean, id: number) => {
      if (isButtonMode && haveWarningText) {
        setVisibleHint(true);
        setCurrentHintId(id);
      }
    };

    const hideHint = (haveWarningText: boolean) => {
      if (isButtonMode && haveWarningText) {
        setVisibleHint(false);
        setCurrentHintId(null);
      }
    };

    return (
      <div
        className={`${styles.tabs}  ${
          isButtonMode ? styles.tabs_buttonMode : ""
        } ${className}`}
      >
        {tabs?.map((tab) => (
          <div
            key={tab.id}
            onClick={() => _onClick(tab.tabName, tab.disabled ?? false)}
            className={`${styles.tabs__tab} ${
              activeTabName && activeTabName === tab.tabName
                ? styles.tabs__tab_selected
                : ""
            }`}
            onMouseEnter={() =>
              showHint(Boolean(tab.miniTabWarningText), tab.id)
            }
            onMouseLeave={() => hideHint(Boolean(tab.miniTabWarningText))}
          >
            <span>{tab.label}</span>

            {!isButtonMode &&
              tab.bigTabUpperText &&
              tab.bigTabUpperText.length && (
                <span className={styles.tabs__upperText}>
                  {tab.bigTabUpperText}
                </span>
              )}

            {isButtonMode &&
              tab.miniTabWarningText &&
              tab.miniTabWarningText.length > 0 && (
                <Hint
                  text={tab.miniTabWarningText}
                  className={styles.tabs__hint}
                  classNameIcon={styles.tabs__hintIcon}
                  classNameText={`${styles.tabs__hintText} ${classNameButtonHintText}`}
                  withVisibleFromParent={true}
                  visibleFromParent={visibleHint && currentHintId === tab.id}
                />
              )}
          </div>
        ))}
      </div>
    );
  }
);
