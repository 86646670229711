import { makeAutoObservable } from "mobx";

import { getDocumentPdf } from "../../../api/Documents";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { IFilePdf } from "../../../interfaces/Documents";

// общие методы для любого вида документов (договора/ счета/ акты/ )
export class ModelDocuments implements Store.IDocumentsStore {
  documentBase64: string = "";

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // funstions

  *getDocumentPdf(documentId: number) {
    let response: TResponseApi<IFilePdf> = yield getDocumentPdf({ documentId });
    if (response.isSuccess && response.data !== null) {
      this.setDocumentBase64(response.data.file);
    }
  }

  // setters

  setDocumentBase64(value: string) {
    this.documentBase64 = value;
  }
}
