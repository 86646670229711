import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { AccountNav } from "./PersonalNav";
import { Footer } from "../../components/Footer";
import { Loader } from "../../components/Loader";
import { ModalReuse } from "../../components/Modals/ModalReuse";

import { Store } from "../../stores/types";
import { EPageType } from "../../types";

import { RootContext } from "../../stores/Root";
import { StoreUI } from "./storeUI/accountStore";

import {
  DESCRIPTION_PAGE_DEFAULT,
  NAV_BOTTOM,
  NAV_BOTTOM_COMPANY,
  NAV_DOCS,
  NAV_TOP,
  PAGE_TITLE_PERSONAL,
} from "../../constants";

import { SCREENS } from "../../navigation/endpoints";

import styles from "./styles/personal.module.scss";
import stylesAccount from "./styles/accountBlock.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
}

interface IPropsAccount {
  storeAuthentication: Store.IStoreAuthentication;
  className?: string;
}
const AccountBlock: FC<IPropsAccount> = observer(
  ({ className = "", storeAuthentication }) => {
    const navigate = useNavigate();
    const rootStore = useContext(RootContext);

    const [storeUI] = useState(
      () => new StoreUI(storeAuthentication, rootStore, navigate)
    );

    useEffect(() => {
      storeUI.init();
    }, [storeUI]);

    // длины строк для уменьшения шрифта (применяются только здесь)
    const LENGTH_NORMAL_STYLE = 30;
    const LENGTH_SMALL_STYLE = 50;

    const getClass = (lengthNum: number) => {
      if (lengthNum > LENGTH_NORMAL_STYLE && lengthNum <= LENGTH_SMALL_STYLE) {
        return `small`;
      }

      if (lengthNum > LENGTH_SMALL_STYLE) {
        return `supersmall`;
      }
    };

    let isTypeAccountLegal = storeUI.personalType === "legal";

    return (
      <div className={`${stylesAccount.account} ${className}`}>
        <div className={stylesAccount.account__menu}>
          {rootStore.isMainLoading ? (
            <div className={styles.account__profileEmpty}>
              <Loader />
            </div>
          ) : (
            <>
              <div className={stylesAccount.account__profile}>
                <div className={stylesAccount.account__profileAvatarWrapper}>
                  <img
                    className={stylesAccount.account__profileAvatar}
                    src={storeUI.avatarImg}
                    alt="profile ava"
                  />
                </div>
                <p
                  className={`${stylesAccount.account__profileName} ${
                    storeUI.name
                      ? stylesAccount[
                          `account__profileName_${getClass(
                            storeUI.name.length
                          )}`
                        ]
                      : ""
                  }`}
                >
                  {storeUI.name}
                </p>
              </div>
              <nav className={stylesAccount.account__navTop}>
                {NAV_TOP.map((item) => (
                  <div key={item.id}>
                    <NavLink
                      to={String(item.path)}
                      className={({ isActive }) =>
                        isActive
                          ? `${stylesAccount.account__navTitle} ${stylesAccount.account__navTitle_active}`
                          : `${stylesAccount.account__navTitle}`
                      }
                      onClick={() =>
                        storeUI.clickToDocs(item.title === NAV_DOCS)
                      }
                    >
                      <span>{item.title}</span>
                    </NavLink>
                  </div>
                ))}
              </nav>

              {isTypeAccountLegal ? (
                <nav className={stylesAccount.account__navBottom}>
                  {NAV_BOTTOM_COMPANY.map((item) => (
                    <NavLink
                      to={String(item.path)}
                      key={item.id}
                      className={({ isActive }) =>
                        isActive
                          ? `${stylesAccount.account__navTitle} ${stylesAccount.account__navTitle_active}`
                          : `${stylesAccount.account__navTitle}`
                      }
                    >
                      {item.title}
                    </NavLink>
                  ))}

                  {rootStore.haveArchivedOrActiveBooking && (
                    <Link
                      to={SCREENS.SCREEN_TEXT_RULES}
                      className={stylesAccount.account__navTitle}
                      target="_blank"
                    >
                      Правила
                    </Link>
                  )}
                </nav>
              ) : (
                <nav className={stylesAccount.account__navBottom}>
                  {NAV_BOTTOM.map((item) => (
                    <NavLink
                      to={String(item.path)}
                      key={item.id}
                      className={({ isActive }) =>
                        isActive
                          ? `${stylesAccount.account__navTitle} ${stylesAccount.account__navTitle_active}`
                          : `${stylesAccount.account__navTitle}`
                      }
                    >
                      {item.title}
                    </NavLink>
                  ))}
                  {rootStore.haveArchivedOrActiveBooking && (
                    <Link
                      to={SCREENS.SCREEN_TEXT_RULES}
                      className={stylesAccount.account__navTitle}
                      target="_blank"
                    >
                      Правила
                    </Link>
                  )}
                </nav>
              )}

              <div
                className={`${stylesAccount.account__exit} ${
                  isTypeAccountLegal
                    ? stylesAccount.account__exit_legalMode
                    : ""
                }`}
              >
                <button
                  onClick={storeUI.openExit}
                  className={stylesAccount.account__quitBtn}
                >
                  Выйти
                </button>
              </div>
            </>
          )}
        </div>
        <ModalReuse
          isShowModal={storeUI.isExit}
          setIsShowModal={storeUI.closeExit}
          onClick={storeUI.logout}
          btnLeft="да"
          btnRight="нет"
          type="buttons"
          isLoading={storeUI.isLoading}
        >
          <h2>Вы уверены, что хотите выйти из аккаунта?</h2>
        </ModalReuse>
      </div>
    );
  }
);

export const Account: FC<IProps> = ({ storeAuthentication }) => {
  const rootStore = useContext(RootContext);

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.Personal
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_PERSONAL}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );
  return (
    <div className={styles.personal}>
      {helmet}
      <div className={styles.personal__wrapper}>
        <AccountBlock
          className={styles.personal__menu}
          storeAuthentication={storeAuthentication}
        />
        <div className={styles.personal__content}>
          <AccountNav storeAuthentication={storeAuthentication} />
        </div>
      </div>
      <Footer className={styles.personal__footer} />
    </div>
  );
};
