import { FC } from "react";

import { Button } from "../../Button";
import { Loader } from "../../Loader";
import { Counter } from "../../../pages/Apartments/Apartment/Booking/Counter";
import { DateInline } from "../../DateInline";
import { stopPropagation } from "../../../helpers/modalsFunc";

import { IDatesInterval } from "../../../interfaces";

import {
  MIN_COUNT_ADULTS,
  TEXT_DATE_IN,
  TEXT_DATE_OUT,
} from "../../../constants";

import Close from "../../../assets/images/close.svg";

import styles from "./styles/book.module.scss";

interface IProps {
  isBooking: boolean;
  startDay: Date | null;
  endDay: Date | null;
  minEndDate: Date | null;
  price: number;
  adultsCount: number;
  chlidrenCount: number;
  petsCount?: number;
  isDisableMaxAdults: boolean;
  isDisableMaxChildren: boolean;
  isDisableMaxPets?: boolean;
  isLoading: boolean;
  errors: string[];
  className?: string;
  disabledDates?: IDatesInterval[];
  startDate?: Date | null;
  withPets: boolean;
  checkinDate: string | null;
  isBusy: boolean;
  closeBooking(): void;
  getIsActiveDay(date: Date): boolean;
  addToBasket(): void;
  changeCountAdults(value: number): void;
  changeCountChildren(value: number): void;
  changeCountPets?(value: number): void;
  changeStartDay(date: Date): void;
  changeEndDay(date: Date): void;
}

export const Book: FC<IProps> = ({
  isBooking = false,
  withPets = false,
  adultsCount = 1,
  chlidrenCount = 0,
  petsCount = 0,
  isDisableMaxAdults = false,
  isDisableMaxChildren = false,
  isDisableMaxPets = false,
  startDay = null,
  endDay = null,
  minEndDate = null,
  className = "",
  price = 0,
  isLoading = false,
  errors = [],
  disabledDates = [],
  startDate = null,
  checkinDate = null,
  isBusy = false,
  closeBooking = () => {},
  addToBasket = () => false,
  changeCountAdults = () => {},
  changeCountChildren = () => {},
  changeCountPets = () => {},
  changeStartDay = () => {},
  changeEndDay = () => {},
  getIsActiveDay = () => true,
}) => {
  const _toBasket = () => {
    addToBasket();
    closeBooking();
  };

  return (
    <>
      {isBooking && (
        <div className={`${styles.book} ${className}`} onClick={closeBooking}>
          <div className={styles.book__popupWrapper}>
            <div className={styles.book__popup} onClick={stopPropagation}>
              <div className={styles.book__content}>
                <img
                  onClick={closeBooking}
                  className={styles.book__close}
                  src={Close}
                  alt="close"
                />
                <h2 className={styles.book__mainTitle}>Бронирование</h2>
                <p className={styles.book__range}>Минимальный срок: 90 дней</p>
                <div className={styles.book__guests}>
                  <h3 className={styles.book__guestsTitle}>Гости</h3>

                  <div className={styles.book__counters}>
                    <Counter
                      title="Взрослые"
                      subtitle="Старше 18 лет"
                      persons={adultsCount}
                      setPersons={changeCountAdults}
                      minPersons={MIN_COUNT_ADULTS}
                      isDisableMax={isDisableMaxAdults}
                      classNameButton={styles.book__counterButton}
                    />

                    <Counter
                      title="Дети"
                      subtitle="от 0 до 18 лет"
                      persons={chlidrenCount}
                      setPersons={changeCountChildren}
                      isDisableMax={isDisableMaxChildren}
                      classNameButton={styles.book__counterButton}
                    />

                    {withPets && (
                      <Counter
                        title="Питомцы"
                        subtitle="Домашние животные"
                        persons={petsCount}
                        setPersons={changeCountPets}
                        isDisableMax={isDisableMaxPets}
                        classNameButton={styles.book__counterButton}
                      />
                    )}
                  </div>
                </div>

                <div className={styles.book__arrival}>
                  <h3 className={styles.book__datepickerTitle}>
                    {TEXT_DATE_IN}
                  </h3>
                  <DateInline
                    currentDay={startDay}
                    changeCurrentDay={changeStartDay}
                    disabledDates={disabledDates}
                    minDate={startDate ? startDate : "now"}
                  />
                </div>
                <div className={styles.book__leaving}>
                  <h3 className={styles.book__datepickerTitle}>
                    {TEXT_DATE_OUT}
                  </h3>
                  <DateInline
                    currentDay={endDay}
                    changeCurrentDay={changeEndDay}
                    minDate={minEndDate}
                    disabledDates={disabledDates}
                    getIsActiveDay={getIsActiveDay}
                  />

                  {checkinDate && !isBusy && (
                    <span> *Ближайшая дата заселения {checkinDate}</span>
                  )}
                </div>
                <div className={styles.book__priceWrapper}>
                  {errors.length !== 0 && (
                    <span className={styles.book__error}>{errors[0]}</span>
                  )}
                  <h3 className={styles.book__price}>{price} ₽ / мес.</h3>

                  {isLoading && <Loader />}
                </div>

                <Button
                  title="ЗАБРОНИРОВАТЬ"
                  type="submit"
                  theme="gold"
                  onClick={_toBasket}
                  disabled={isLoading}
                  isDisabledStyle={!startDay || !endDay}
                  className={styles.book__button}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
