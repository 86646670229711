import { FC } from "react";

import { ReactComponent as OpenBtn } from "../../../../assets/images/openBtn.svg";
import { ReactComponent as CloseBtn } from "../../../../assets/images/closeBtn.svg";

import styles from "./styles/roundBtn.module.scss";

interface IProps {
  isOpen: boolean;
  openDescription: () => void;
}

export const RoundBtn: FC<IProps> = ({
  isOpen = false,
  openDescription = () => {},
}) => {
  return (
    <>
      {isOpen ? (
        <CloseBtn className={styles.button} onClick={openDescription} />
      ) : (
        <OpenBtn className={styles.button} onClick={openDescription} />
      )}
    </>
  );
};
