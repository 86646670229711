import { FC } from "react";

import { stopPropagation } from "../../../helpers/modalsFunc";

import { ReactComponent as CloseBtn } from "../../../assets/images/close.svg";

import styles from "./styles/photo.module.scss";

interface IProps {
  isVisible: boolean;
  src: string;
  onClose(): void;
}

export const Photo: FC<IProps> = ({
  isVisible = false,
  src = "",
  onClose = () => {},
}) => {
  return (
    <>
      {isVisible && (
        <div className={styles.photo} onClick={onClose}>
          <div className={styles.photo__popup} onClick={stopPropagation}>
            <div className={styles.photo__close} onClick={onClose}>
              <CloseBtn />
            </div>
            {src ? (
              <img src={src} alt="flat view" />
            ) : (
              <div className={styles.photo__empty}>
                Не удалось загрузить изображение
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
