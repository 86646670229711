import React, { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { Success } from "../../components/Modals/Success";
import { Agreement } from "../../components/Modals/Agreement";
import { Loader } from "../../components/Loader";
import { Footer } from "../../components/Footer";
import { StepRenter } from "./StepRenter";
import { StepTenants } from "./StepTenants";
import { StepPets } from "./StepPets";
import { StepArrivalDate } from "./StepArrivalDate";

import { StoreUI } from "./storeUI";

import { RootContext } from "../../stores/Root";

import { Store } from "../../stores/types";
import { EPageType, EStepOrder } from "../../types";

import {
  DESCRIPTION_PAGE_DEFAULT,
  PAGE_ORDERING_DATA_BY_ID,
  PAGE_TITLE_ORDERING_DATA,
} from "../../constants";

import styles from "./styles/payOrder.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
}

export const OrderingData: FC<IProps> = observer(({ storeAuthentication }) => {
  const params = useParams();
  const navigate = useNavigate();
  const rootStore = React.useContext(RootContext);
  const location = useLocation();

  const [storeUI] = useState(
    () => new StoreUI(storeAuthentication, rootStore, navigate)
  );

  useEffect(() => {
    (async () => {
      await storeUI.init(params.id ?? "");
    })();
  }, [
    rootStore,
    storeAuthentication.isAuth,
    storeUI,
    storeUI.personalType,
    params.id,
  ]);

  useEffect(() => {
    let { state } = location;
    if (state) {
      let { fromProfile }: any = state;

      if (fromProfile && params.id) {
        storeUI.storeRenter.saveUpdatedProfileData(params.id);
        navigate(PAGE_ORDERING_DATA_BY_ID(params.id));
      }
    }
  }, [location, navigate, params.id, storeUI.storeRenter]);

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.OrderingData
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_ORDERING_DATA}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <div className={styles.payOrder}>
      {helmet}
      {storeUI.isLoading ? (
        <div className={styles.payOrder__empty}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.payOrder__stepsWrapper}>
            {storeUI.step === EStepOrder.Renter && (
              <StepRenter
                storeRenter={storeUI.storeRenter}
                formReadonly={false}
                stepTitle={storeUI.stepTitle.renter}
                personalType={storeUI.personalType ?? ""}
              />
            )}
            {storeUI.step === EStepOrder.Tenants && (
              <StepTenants
                storeTenants={storeUI.storeTenants}
                onBack={storeUI.toStepRenter}
                stepTitle={storeUI.stepTitle.tenants}
                canPets={storeUI.canPets}
                isDisabledAll={storeUI.isDisabledAllForm}
              />
            )}
            {storeUI.step === EStepOrder.Pets && (
              <StepPets
                storePets={storeUI.storePets}
                onBack={storeUI.toStepTenants}
                stepTitle={storeUI.stepTitle.pets}
                isDisabledAll={storeUI.isDisabledAllForm}
                toMyBookings={storeUI.toMyBookings}
              />
            )}

            {storeUI.step === EStepOrder.ArrivalDate && (
              <StepArrivalDate
                storeArrivalDate={storeUI.storeArrivalDate}
                stepTitle={storeUI.stepTitle.arrivalDate}
                onBack={storeUI.onBackArrivalDate}
              />
            )}
          </div>
        </>
      )}

      <Footer className={styles.payOrder__footer} />
      <Agreement
        modal={storeUI.storeModals.isVisibleAgreementModal}
        setModal={storeUI.storeModals.closeAgreementModal}
        onClickConfirm={storeUI.onConfirmAgreement}
        linkAgreement={storeUI.dataAgreement}
        isChecked={storeUI.storeModals.isAgree}
        changeCheckbox={storeUI.storeModals.changeIsAgree}
        checkboxErrors={storeUI.storeModals.errorsIsAgree}
        clickAgreement={storeUI.openAgreementPdf}
        errors={storeUI.errorsBooking}
        isLoading={storeUI.isLoadingBooking}
      />
      <Success
        modal={storeUI.storeModals.isVisiblePaymentSuccess}
        setModal={storeUI.storeModals.closePaymentSuccessModal}
        goToBills={storeUI.toBills}
      />
    </div>
  );
});
