import { makeAutoObservable } from "mobx";
import { Validator } from "validator-pyrobyte";

import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";
import {
  ERROR_TEXT_CHARS_NUM_SEVEN,
  ERROR_TEXT_EMPTY,
} from "../../../constants";
import { newRules } from "../../../helpers/validation";

import { IValidErrors } from "../../../interfaces/QuestionModal";
import { IStoreUI } from "./interfaces";

import { Store } from "../../../stores/types";

export class StoreUI implements IStoreUI {
  storeFaq: Store.IFaq;
  isLoading: boolean = false;
  isLoadingPopup: boolean = false;
  isSuccessModal: boolean = false;
  openedQuestion: number | null = null;

  // для попапа Задать вопрос
  storeQuestion: Store.IQuestion;
  validator: Validator;

  isVisiblePopup: boolean = false;

  name: string = "";
  email: string = "";
  text: string = "";

  validationErrors: IValidErrors = {
    name: [],
    email: [],
    text: [],
  };

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeFaq = storeRoot.storeFaq;
    this.storeQuestion = storeRoot.storeQuestionModal;

    this.validator = new Validator(
      {
        name: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
      },
      newRules
    );
  }

  // getters

  get faqItems() {
    return this.storeFaq.faqItems;
  }

  get nameErrors() {
    return this.validationErrors.name.concat(this.storeQuestion.errors);
  }

  get emailErrors() {
    return this.validationErrors.email.concat(this.storeQuestion.errors);
  }

  get textErrors() {
    return this.validationErrors.text.concat(this.storeQuestion.errors);
  }

  // functions

  *init() {
    enableScroll();
    this.setIsLoading(true);
    yield this.storeFaq.getFaq();
    this.setIsLoading(false);
  }

  *onSendQuestion() {
    this.validateEmail();
    this.validateName();
    this.validateText();

    if (this.validateAll()) {
      let _params = {
        name: this.name,
        email: this.email,
        text: this.text,
      };

      this.setIsLoadingPopup(true);
      yield this.storeQuestion.sendQuestion(_params);

      if (this.storeQuestion.isSuccessSend) {
        this.closePopupQuestion();
        this.openSuccessModal();
      }
      this.setIsLoadingPopup(false);
    }
  }

  checkIsOpenedQuestion(id: number) {
    return this.openedQuestion === id;
  }

  toggleOpen(id: number) {
    if (this.openedQuestion === id) {
      this.setOpenedQuestion(null);
    } else {
      this.openedQuestion = id;
    }
    this.checkIsOpenedQuestion(id);
  }

  openPopupQuestion() {
    this.setIsVisiblePopup(true);
    disableScroll();
  }

  closePopupQuestion() {
    this.setIsVisiblePopup(false);
    this.clearPopup();
    enableScroll();
  }

  openSuccessModal() {
    this.isSuccessModal = true;
    disableScroll();
  }

  closeSuccessModal() {
    this.isSuccessModal = false;
    enableScroll();
  }

  clearPopup() {
    this.setName("");
    this.setEmail("");
    this.setText("");
    this.storeQuestion.clearErrors();
    this.validationErrors.email = [];
    this.validationErrors.name = [];
    this.validationErrors.text = [];
  }

  // validation

  validateName() {
    if (this.name.trim().length === 0) {
      this.validationErrors.name = [
        ...this.validationErrors.name,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validationErrors.name = [];
    }

    return true;
  }

  validateEmail() {
    let validEmail = this.validator.check("email", this.email);

    if (this.email.trim().length === 0) {
      this.validationErrors.email = [
        ...this.validationErrors.email,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validationErrors.email = [];
    }

    if (this.email.trim().length < 7) {
      this.validationErrors.email = [
        ...this.validationErrors.email,
        ERROR_TEXT_CHARS_NUM_SEVEN,
      ];
      return false;
    } else {
      this.validationErrors.email = [];
    }

    if (!validEmail.passed) {
      this.validationErrors.email = [
        ...this.validationErrors.email,
        validEmail.errors[0],
      ];
      return false;
    } else {
      this.validationErrors.email = [];
    }

    return true;
  }

  validateText() {
    if (this.text.trim().length === 0) {
      this.validationErrors.text = [
        ...this.validationErrors.text,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.validationErrors.text = [];
    }

    return true;
  }

  validateAll() {
    return this.validateEmail() && this.validateName() && this.validateText();
  }

  changeName(value: string) {
    this.setName(value);

    if (this.validationErrors.name.length) {
      this.validationErrors.name = [];
    }
  }

  changeEmail(value: string) {
    this.setEmail(value);

    if (this.validationErrors.email.length) {
      this.validationErrors.email = [];
    }
  }

  changeText(value: string) {
    this.setText(value);

    if (this.validationErrors.text.length) {
      this.validationErrors.text = [];
    }
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setOpenedQuestion(value: number | null) {
    this.openedQuestion = value;
  }

  setIsVisiblePopup(value: boolean) {
    this.isVisiblePopup = value;
  }

  setName(value: string) {
    this.name = value;
  }

  setEmail(value: string) {
    this.email = value;
  }

  setText(value: string) {
    this.text = value;
  }

  setIsLoadingPopup(value: boolean) {
    this.isLoadingPopup = value;
  }
}
