import { observer } from "mobx-react-lite";
import { useState } from "react";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { Button } from "../../Button";

import { EDepositStatus } from "../../../types";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/prolongResult.module.scss";

interface IProps {
  visible: boolean;
  status: EDepositStatus | null;
  depositPrice: string;
  buttonTitle: string;
  onClose(): void;
  onClickButton(): void;
}

export const ProlongResult = observer(
  ({
    visible = false,
    status = null,
    depositPrice = "",
    buttonTitle = "",
    onClose = () => {},
    onClickButton = () => {},
  }: IProps) => {
    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        onClose();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    return (
      <>
        {visible && (
          <div
            className={styles.prolongResult}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.prolongResult__popupWrapper}>
              <div
                className={styles.prolongResult__popup}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                <Close
                  className={styles.prolongResult__close}
                  onClick={onClose}
                />

                <h3 className={styles.prolongResult__title}>
                  {status ? "Проживание продлено" : "Ошибка"}
                </h3>
                {status === EDepositStatus.Pay && (
                  <div className={styles.prolongResult__content}>
                    <p className={styles.prolongResult__description}>
                      Вам необходимо оплатить разницу в размере депозита
                    </p>
                    <p
                      className={`${styles.prolongResult__description} ${styles.prolongResult__description_yellow} ${styles.prolongResult__payPrice}`}
                    >
                      Нужно оплатить {depositPrice} ₽
                    </p>
                  </div>
                )}

                {status === EDepositStatus.Give && (
                  <div className={styles.prolongResult__content}>
                    <p className={styles.prolongResult__description}>
                      Вам будет возвращена разница в стоимости депозита{" "}
                      <span
                        className={`${styles.prolongResult__description} ${styles.prolongResult__description_yellow}`}
                      >
                        {depositPrice} ₽
                      </span>
                      . Пожалуйста, обратитесь к администратору
                    </p>
                  </div>
                )}

                {status === EDepositStatus.Nothing && (
                  <div className={styles.prolongResult__content}>
                    <p className={styles.prolongResult__description}>
                      Вы можете ознакомиться с информацией в разделе Мои брони
                    </p>
                  </div>
                )}

                {!status && (
                  <div className={styles.prolongResult__content}>
                    <p className={styles.prolongResult__description}>
                      Произошла ошибка. Статус депозита неизвестен
                    </p>
                  </div>
                )}

                {status !== null && (
                  <Button
                    title={buttonTitle}
                    onClick={onClickButton}
                    theme={"gold"}
                    className={styles.prolongResult__button}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
