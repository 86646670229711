import { makeAutoObservable } from "mobx";
import { Location, NavigateFunction } from "react-router-dom";

import {
  BACK_TO_ORDER,
  ERROR_BIK_LENGTH,
  ERROR_BILL_LENGTH,
  ERROR_INN_LENGTH,
  ERROR_MIN_CHARS_THREE,
  ERROR_NUMBER_LENGTH,
  ERROR_OGRN_LENGTH,
  ERROR_TEXT_EMPTY,
  ERROR_TEXT_PHONE,
  NUMBER_BIK_LENGTH,
  NUMBER_BILL_LENGTH,
  NUMBER_INN_LENGTH,
  NUMBER_OGRN_LENGTH,
} from "../../../../../constants";

import { disableScroll, enableScroll } from "../../../../../helpers/modalsFunc";

import { ICompanyErrors, IStoreUI } from "./interfaces";

import { Store } from "../../../../../stores/types";
import { checkStateLocationForToBack } from "../../../../../helpers/checkStateLocationForToBack";

import { SCREENS } from "../../../../../navigation/endpoints";

enum ECompanyInputs {
  Name = "name",
  Inn = "inn",
  Ogrn = "ogrn",
  Director = "director",
  SettlementAccount = "settlementAccount",
  CorrespondentAccount = "correspondentAccount",
  Bik = "bik",
  Phone = "phone",
  Address = "address",
  AddressIndex = "addressIndex",
  BankName = "bankName",
}

export class StoreUI implements IStoreUI {
  storeRoot: Store.IRootStore;
  storeCompany: Store.ICompanyStore;
  storeMyBookings: Store.IMyBookings;
  location: Location;
  navigate: NavigateFunction;
  isLoading: boolean = false;
  isLoadingSend: boolean = false;
  isModalCantChangeData: boolean = false;
  isModalSave: boolean = false;
  isCompany: boolean = false;
  name: string = "";
  inn: string = "";
  ogrn: string = "";
  director: string = "";
  settlementAccount: string = "";
  correspondentAccount: string = "";
  bik: string = "";
  phone: string = "";
  address: string = "";
  addressIndex: string = "";
  bankName: string = "";

  hintsAddress: string[] = [];

  validateErrors: ICompanyErrors = {
    [ECompanyInputs.Name]: [],
    [ECompanyInputs.Inn]: [],
    [ECompanyInputs.Ogrn]: [],
    [ECompanyInputs.Director]: [],
    [ECompanyInputs.SettlementAccount]: [],
    [ECompanyInputs.CorrespondentAccount]: [],
    [ECompanyInputs.Bik]: [],
    [ECompanyInputs.Phone]: [],
    [ECompanyInputs.Address]: [],
    [ECompanyInputs.AddressIndex]: [],
    [ECompanyInputs.BankName]: [],
  };

  constructor(
    storeRoot: Store.IRootStore,
    navigate: NavigateFunction,
    location: Location
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeRoot = storeRoot;
    this.storeCompany = storeRoot.storeCompany;
    this.storeMyBookings = storeRoot.storeMyBookings;
    this.navigate = navigate;
    this.location = location;
  }

  get errorsServer() {
    return this.storeCompany.serverErrors;
  }

  get isCompanyArray() {
    return Array.isArray(this.storeCompany.company);
  }

  get haveActiveApartments() {
    return Boolean(this.storeMyBookings.bookings.length);
  }

  *init() {
    this.setIsLoading(true);

    yield this.storeCompany.getCompany();
    yield this.storeMyBookings.getBookings();
    this.setName(this.storeCompany.company.name || "");
    this.setInn(this.storeCompany.company.inn || "");
    this.setOgrn(this.storeCompany.company.ogrn || "");
    this.setDirector(this.storeCompany.company.director || "");
    this.setSettlementAccount(
      this.storeCompany.company.settlement_account || ""
    );
    this.setCorrespondentAccount(
      this.storeCompany.company.correspondent_account || ""
    );
    this.setBik(this.storeCompany.company.bik || "");
    this.setAddress(this.storeCompany.company.address || "");
    this.setAddressIndex(this.storeCompany.company.addressIndex || "");
    this.setPhone(this.storeCompany.company.phone || "");
    this.setBankName(this.storeCompany.company.bankName || "");

    this.setIsLoading(false);
  }

  *createCompany() {
    if (this.validateAll()) {
      let params = {
        name: this.name,
        inn: this.inn,
        ogrn: this.ogrn,
        director: this.director,
        settlement_account: this.settlementAccount,
        correspondent_account: this.correspondentAccount,
        bik: this.bik,
        phone: this.phone.replaceAll(" ", ""),
        address: this.address,
        addressIndex: this.addressIndex,
        bankName: this.bankName,
      };

      this.setIsLoadingSend(true);
      yield this.storeCompany.createCompany(params);
      yield this.storeCompany.getCompany();
      this.setIsLoadingSend(false);

      if (this.storeCompany.isSuccessUpdatedCompany) {
        yield this.storeRoot.getBasket();
        if (
          checkStateLocationForToBack(BACK_TO_ORDER, this.location) &&
          this.storeRoot.haveApartmentInBasket
        ) {
          this.toOrder();
          return;
        }
        this.openSaveModal();
      }
      return this.storeCompany.isSuccessUpdatedCompany;
    }
  }

  private *getHintByString(value: string) {
    yield this.storeRoot.userStore.getHintByString(value);

    if (this.storeRoot.userStore.hintStrings.length > 0) {
      return this.storeRoot.userStore.hintStrings;
    }
    return [];
  }

  onClickHintAddress(value: string) {
    this._onChangeAddress(value);
    this.setHintsAddress([]);
  }

  toOrder() {
    this.navigate(SCREENS.SCREEN_ORDER);
  }

  //----- validation ------
  validateName() {
    if (!this.name.length) {
      this.addValidationError(ECompanyInputs.Name, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Name);
    }

    if (this.name.length < 3) {
      this.addValidationError(ECompanyInputs.Name, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Name);
    }

    return true;
  }

  validateInn() {
    if (!this.inn.length) {
      this.addValidationError(ECompanyInputs.Inn, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Inn);
    }

    if (this.inn.length < NUMBER_INN_LENGTH) {
      this.addValidationError(ECompanyInputs.Inn, ERROR_INN_LENGTH);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Inn);
    }
    return true;
  }

  validateOgrn() {
    if (!this.ogrn.length) {
      this.addValidationError(ECompanyInputs.Ogrn, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Ogrn);
    }

    if (this.ogrn.length < NUMBER_OGRN_LENGTH) {
      this.addValidationError(ECompanyInputs.Ogrn, ERROR_OGRN_LENGTH);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Ogrn);
    }
    return true;
  }

  validateDirector() {
    if (!this.director.length) {
      this.addValidationError(ECompanyInputs.Director, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Director);
    }

    if (this.director.length < 3) {
      this.addValidationError(ECompanyInputs.Director, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Director);
    }

    return true;
  }

  validateSettlementAccount() {
    if (!this.settlementAccount.length) {
      this.addValidationError(
        ECompanyInputs.SettlementAccount,
        ERROR_TEXT_EMPTY
      );
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.SettlementAccount);
    }

    if (this.settlementAccount.length < NUMBER_BILL_LENGTH) {
      this.addValidationError(
        ECompanyInputs.SettlementAccount,
        ERROR_BILL_LENGTH
      );
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.SettlementAccount);
    }
    return true;
  }

  validateCorrespondentAccount() {
    if (!this.correspondentAccount.length) {
      this.addValidationError(
        ECompanyInputs.CorrespondentAccount,
        ERROR_TEXT_EMPTY
      );
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.CorrespondentAccount);
    }

    if (this.correspondentAccount.length < NUMBER_BILL_LENGTH) {
      this.addValidationError(
        ECompanyInputs.CorrespondentAccount,
        ERROR_BILL_LENGTH
      );
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.CorrespondentAccount);
    }
    return true;
  }

  validateBik() {
    if (!this.bik.length) {
      this.addValidationError(ECompanyInputs.Bik, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Bik);
    }

    if (this.bik.length < NUMBER_BIK_LENGTH) {
      this.addValidationError(ECompanyInputs.Bik, ERROR_BIK_LENGTH);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Bik);
    }
    return true;
  }

  validatePhone() {
    if (!this.phone.length) {
      this.addValidationError(ECompanyInputs.Phone, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Phone);
    }

    if (this.phone.replaceAll(" ", "").length < 12) {
      this.addValidationError(ECompanyInputs.Phone, ERROR_TEXT_PHONE);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Phone);
    }
    return true;
  }

  validateAddress() {
    if (this.address.length === 0) {
      this.addValidationError(ECompanyInputs.Address, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Address);
    }

    if (this.address.trim().length < 3) {
      this.addValidationError(ECompanyInputs.Address, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.Address);
    }
    return true;
  }

  validateIndex() {
    if (!this.addressIndex.length) {
      this.addValidationError(ECompanyInputs.AddressIndex, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.AddressIndex);
    }

    if (this.addressIndex.length < 6) {
      this.addValidationError(ECompanyInputs.AddressIndex, ERROR_NUMBER_LENGTH);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.AddressIndex);
    }
    return true;
  }

  validateBankName() {
    if (!this.bankName.length) {
      this.addValidationError(ECompanyInputs.BankName, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.BankName);
    }

    if (this.bankName.length < 3) {
      this.addValidationError(ECompanyInputs.BankName, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearValidationError(ECompanyInputs.BankName);
    }
    return true;
  }

  validateAll() {
    this.validateName();
    this.validateInn();
    this.validateOgrn();
    this.validateDirector();
    this.validateSettlementAccount();
    this.validateCorrespondentAccount();
    this.validateBik();
    this.validatePhone();
    this.validateAddress();
    this.validateIndex();
    this.validateBankName();
    return (
      this.validateName() &&
      this.validateInn() &&
      this.validateOgrn() &&
      this.validateDirector() &&
      this.validateSettlementAccount() &&
      this.validateCorrespondentAccount() &&
      this.validateBik() &&
      this.validatePhone() &&
      this.validateAddress() &&
      this.validateIndex() &&
      this.validateBankName()
    );
  }

  addValidationError(type: ECompanyInputs, value: string) {
    this.validateErrors[type] = [...this.validateErrors[type], value];
  }

  clearValidationError(type: ECompanyInputs) {
    this.validateErrors[type] = [];
  }

  //----- changes -----
  onChangeName(value: string) {
    this.setName(value);

    if (this.validateErrors.name.length) {
      this.clearValidationError(ECompanyInputs.Name);
    }
  }

  onChangeInn(value: string) {
    this.setInn(value.replace(/[^0-9]+/, ""));

    if (this.validateErrors.inn.length && this.inn.length) {
      this.clearValidationError(ECompanyInputs.Inn);
    }
  }

  onChangeOgrn(value: string) {
    this.setOgrn(value.replace(/[^0-9]+/, ""));

    if (this.validateErrors.ogrn.length && this.ogrn.length) {
      this.clearValidationError(ECompanyInputs.Ogrn);
    }
  }

  onChangeDirector(value: string) {
    this.setDirector(value.replace(/[^a-zA-ZА-Я а-яЁё]/gi, ""));

    if (this.validateErrors.director.length) {
      this.clearValidationError(ECompanyInputs.Director);
    }
  }

  onChangeSettlementAccount(value: string) {
    this.setSettlementAccount(value.replace(/[^0-9]+/, ""));

    if (
      this.validateErrors.settlementAccount.length &&
      this.settlementAccount.length
    ) {
      this.clearValidationError(ECompanyInputs.SettlementAccount);
    }
  }

  onChangeCorrespondentAccount(value: string) {
    this.setCorrespondentAccount(value.replace(/[^0-9]+/, ""));

    if (
      this.validateErrors.correspondentAccount.length &&
      this.correspondentAccount.length
    ) {
      this.clearValidationError(ECompanyInputs.CorrespondentAccount);
    }
  }

  onChangeBik(value: string) {
    this.setBik(value.replace(/[^0-9]+/, ""));

    if (this.validateErrors.bik.length && this.bik.length) {
      this.clearValidationError(ECompanyInputs.Bik);
    }
  }

  *onChangeAddress(value: string) {
    this._onChangeAddress(value);
    if (value.length > 0) {
      let _hints: string[] = yield this.getHintByString(value);
      this.setHintsAddress(_hints);
    }
  }

  private _onChangeAddress(value: string) {
    this.setAddress(value);

    if (this.validateErrors.address.length) {
      this.clearValidationError(ECompanyInputs.Address);
    }
  }

  onChangeIndex(value: string) {
    this.setAddressIndex(value.replace(/[^0-9]+/, ""));

    if (this.validateErrors.addressIndex.length && this.addressIndex.length) {
      this.clearValidationError(ECompanyInputs.AddressIndex);
    }
  }

  onChangePhone(value: string) {
    this.setPhone(value);

    if (this.validateErrors.phone.length && this.phone.length) {
      this.clearValidationError(ECompanyInputs.Phone);
    }
  }

  onChangeBankName(value: string) {
    this.setBankName(value);

    if (this.validateErrors.bankName.length && this.bankName.length) {
      this.clearValidationError(ECompanyInputs.BankName);
    }
  }

  //------ modals ---
  openModalCantChangeData() {
    this.isModalCantChangeData = true;
    disableScroll();
  }

  closeModalCantChangeData() {
    this.isModalCantChangeData = false;
    enableScroll();
  }

  openSaveModal() {
    this.isModalSave = true;
    disableScroll();
  }

  closeSaveModal() {
    this.isModalSave = false;
    enableScroll();
  }

  openIsCompany() {
    this.isCompany = true;
  }

  closeIsCompany() {
    this.isCompany = false;
  }

  // setters

  setName(value: string) {
    this.name = value;
  }

  setDirector(value: string) {
    this.director = value;
  }

  setInn(value: string) {
    this.inn = value;
  }

  setOgrn(value: string) {
    this.ogrn = value;
  }

  setSettlementAccount(value: string) {
    this.settlementAccount = value;
  }

  setCorrespondentAccount(value: string) {
    this.correspondentAccount = value;
  }

  setBik(value: string) {
    this.bik = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingSend(value: boolean) {
    this.isLoadingSend = value;
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setAddress(value: string) {
    this.address = value;
  }

  setAddressIndex(value: string) {
    this.addressIndex = value;
  }

  setBankName(value: string) {
    this.bankName = value;
  }

  setHintsAddress(value: string[]) {
    this.hintsAddress = value;
  }
}
