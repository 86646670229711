import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Input } from "../../Input";
import { Button } from "../../Button";
import { InputPhone } from "../../InputPhone";
import { Loader } from "../../Loader";

import { SCREENS } from "../../../navigation/endpoints";
import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import close from "../../../assets/images/close.svg";

import styles from "./styles/leaveRequest.module.scss";

interface IProps {
  visible: boolean;
  description: string;
  changeName(value: string): void;
  changePhone(value: string): void;
  changeEmail(value: string): void;
  onClose(): void;
  name: string;
  email: string;
  phone: string;
  isLoading: boolean;
  changeName: (value: string) => void;
  changeEmail: (value: string) => void;
  changePhone: (value: string) => void;
  sendAmoLeaveRequest: () => void;
  errorsName: string[];
  errorsEmail: string[];
  errorsPhone: string[];
}

export const LeaveRequest = observer(
  ({
    visible = false,
    name = "",
    phone = "",
    email = "",
    description = "",
    errorsName = [],
    errorsPhone = [],
    errorsEmail = [],
    isLoading = false,
    changeName = () => {},
    changePhone = () => {},
    changeEmail = () => {},
    sendAmoLeaveRequest = () => {},
    onClose = () => {},
  }: IProps) => {
    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        onClose();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    return (
      <>
        {visible && (
          <div
            className={styles.leaveRequest}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.leaveRequest__popupWrapper}>
              {isLoading && <Loader />}
              <div
                className={styles.leaveRequest__popup}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                <img
                  onClick={onClose}
                  className={styles.leaveRequest__close}
                  src={close}
                  alt="close"
                />
                <form className={styles.leaveRequest__contentWrapper}>
                  <h3 className={styles.leaveRequest__title}>
                    Оставьте заявку
                  </h3>
                  <p className={styles.leaveRequest__subtitle}>{description}</p>

                  <Input
                    value={name}
                    onChange={changeName}
                    label="Имя"
                    placeholder="Введите имя"
                    className={styles.leaveRequest__input}
                    errors={errorsName}
                    maxLength={50}
                    readonly={isLoading}
                  />

                  <InputPhone
                    value={phone}
                    onChange={changePhone}
                    label="Ваш номер телефона"
                    errors={errorsPhone}
                    className={styles.leaveRequest__input}
                    readOnly={isLoading}
                  />

                  <Input
                    value={email}
                    onChange={changeEmail}
                    label="E-mail"
                    placeholder="example@mail.ru"
                    className={styles.leaveRequest__input}
                    errors={errorsEmail}
                    readonly={isLoading}
                  />

                  <Button
                    title="ПОДАТЬ ЗАЯВКУ"
                    type="submit"
                    theme="gold"
                    onClick={sendAmoLeaveRequest}
                    className={styles.leaveRequest__button}
                    disabled={isLoading}
                    isDisabledStyle={isLoading || !phone || !name}
                  />

                  <p className={styles.leaveRequest__politics}>
                    Нажимая на кнопку, вы подтверждаете, что согласны с{" "}
                    <Link
                      to={SCREENS.SCREEN_TEXT_PERSONAL_DATA}
                      className={styles.leaveRequest__personal}
                      target="_blank"
                    >
                      Политикой обработки данных
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
