// для попапа Обратного звонка
import { makeAutoObservable } from "mobx";

import { sendFeedback } from "../../../api/FeedbackModal";

import { TResponseApi } from "../../../api/types";
import { ICommonResponse } from "../../../interfaces";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

export class ModelFeedback {
  errorsServer: string[] = [];

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  *sendFeedback(fio: string, phone: string) {
    let _phone = phone.replaceAll(" ", "");

    let response: TResponseApi<ICommonResponse> = yield sendFeedback({
      fio: fio,
      phone: _phone,
    });

    if (response.isError) {
      this.addErrorServer(ERROR_TEXT_SERVER_COMMON);
    }

    return response.isSuccess;
  }

  addErrorServer(value: string) {
    this.errorsServer = [...this.errorsServer, value];
  }
}
