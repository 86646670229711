import { makeAutoObservable } from "mobx";

import {
  generateReconciliationAct,
  getActs,
} from "../../../../api/Documents/Acts";

import { IAct } from "./../../../../interfaces/Documents/index";
import { ICommonResponse } from "../../../../interfaces";

import { TResponseApi } from "../../../../api/types";
import { Store } from "../../../types";

import { ERROR_TEXT_SERVER_COMMON } from "../../../../constants";

export class ModelActs implements Store.IActsStore {
  acts: IAct[] = [];
  isGenerationAct: boolean = false;
  errorsGeneration: string[] = [];

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getActs(bookingId: number) {
    let response: TResponseApi<IAct[]> = yield getActs({ bookingId });
    if (response.isSuccess && response.data !== null) {
      this.setActs(response.data);
    }
  }

  *generateReconciliationAct(params: {
    startDate: string;
    endDate: string;
    bookingId: number;
  }) {
    let response: TResponseApi<ICommonResponse> =
      yield generateReconciliationAct(params);

    if (response.isSuccess && response.data !== null) {
      this.setIsGenerationAct(response.data.result === "success");
    }

    if (response.isError) {
      this.addGenerationError(
        response.error?.message
          ? response.error.message
          : ERROR_TEXT_SERVER_COMMON
      );
    } else {
      this.clearGenerationErrors();
    }
  }

  addGenerationError(value: string) {
    this.errorsGeneration = [...this.errorsGeneration, value];
  }

  clearGenerationErrors() {
    this.errorsGeneration = [];
  }

  // setters

  setActs(value: IAct[]) {
    this.acts = value;
  }

  setIsGenerationAct(value: boolean) {
    this.isGenerationAct = value;
  }
}
