import { makeAutoObservable } from "mobx";

import { newRules } from "../../../helpers/validation";
import { Validator } from "validator-pyrobyte";

import {
  ERROR_TEXT_CHARS_NUM,
  ERROR_TEXT_CODE,
  ERROR_TEXT_CONFIRM_PASSWORD,
  ERROR_TEXT_EMPTY,
  ERROR_TEXT_PHONE,
  TYPE_PERSON_INDIVIDUAL,
  TYPE_PERSON_LEGAL,
} from "../../../constants";

import {
  IRegistrationParams,
  IValidationErrorsRegister,
} from "../../../interfaces/Registration";
import { IRegistrationUI } from "./interfaces";
import { IRadioItem } from "../../../interfaces";

import { EStepsRegister, TTypePerson } from "../../../types/Registration";
import { EStepAuth } from "../../../types/StoreAuthentication";
import { EMethodSend } from "../../../types";
import { Store } from "../../types";

import { ModelRegistration } from "../../models/Authetication/registration";

import { enableScroll } from "../../../helpers/modalsFunc";

enum EInputs {
  LastName = "lastName",
  FirstName = "firstName",
  MiddleName = "middleName",
  Phone = "phone",
  Password = "password",
  ConfirmPassword = "confirmPassword",
  Code = "code",
}

export class StoreRegistration implements IRegistrationUI {
  storeRegistration: Store.IRegistration;
  storeAuthentication: Store.IStoreAuthentication;

  step: EStepsRegister = EStepsRegister.EnterPhone;
  stepAfterEnterCode: EStepsRegister = EStepsRegister.EnterFio;

  personType: TTypePerson = TYPE_PERSON_INDIVIDUAL;
  lastName: string = "";
  firstName: string = "";
  middleName: string = "";
  phone: string = "";
  password: string = "";
  confirmPassword: string = "";
  code: string = "";

  isLoading: boolean = false;
  isRegisterSuccess: boolean = false;
  withFioAndType: boolean = false;

  validator: Validator;

  validationErrors: IValidationErrorsRegister = {
    [EInputs.LastName]: [],
    [EInputs.FirstName]: [],
    [EInputs.MiddleName]: [],
    [EInputs.Phone]: [],
    [EInputs.Password]: [],
    [EInputs.ConfirmPassword]: [],
    [EInputs.Code]: [],
  };

  radioButtons: IRadioItem[] = [
    {
      value: TYPE_PERSON_INDIVIDUAL,
      label: "Физическое лицо",
      id: 1,
    },

    {
      value: TYPE_PERSON_LEGAL,
      label: "Юридическое лицо",
      id: 2,
    },
  ];

  activeRadioButton: IRadioItem = {
    value: TYPE_PERSON_INDIVIDUAL,
    label: "Физическое лицо",
    id: 1,
  };

  constructor(storeLayout: Store.IStoreAuthentication) {
    this.storeRegistration = new ModelRegistration();
    this.storeAuthentication = storeLayout;

    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.validator = new Validator(
      {
        password: {
          password: true,
          required: true,
        },
        specialChars: {
          specialChars: true,
        },
      },
      newRules
    );
  }

  //getters

  get errorsLastName() {
    return this.validationErrors[EInputs.LastName];
  }

  get errorsFirstName() {
    return this.validationErrors[EInputs.FirstName];
  }

  get errorsMiddleName() {
    return this.validationErrors[EInputs.MiddleName];
  }

  get errorsPhone() {
    return this.validationErrors[EInputs.Phone].concat(
      this.storeRegistration.serverErrors.phone
    );
  }

  get errorsPassword() {
    return this.validationErrors[EInputs.Password].concat(
      this.storeRegistration.serverErrors.password
    );
  }

  get errorsConfirmPassword() {
    return this.validationErrors[EInputs.ConfirmPassword].concat(
      this.storeRegistration.serverErrors.confirmPassword
    );
  }

  get errorsCode() {
    return this.validationErrors[EInputs.Code].concat(
      this.storeRegistration.serverErrors.code
    );
  }

  get isDisabledButtonStyle() {
    return this.phone.length === 0;
  }

  // functions

  // шаг 1 - ввод телефона
  *getCodeToCall() {
    // получение кода звонком
    if (this.validateStepPhone()) {
      this.setIsLoading(true);

      let _phone = this.phone.trim().split(" ").join("");

      yield this.storeRegistration.getNeedFill({ phone: _phone });
      this.setWithFioAndType(this.storeRegistration.needFill);

      let response: boolean = yield this.storeRegistration.sendCode(
        _phone,
        EMethodSend.Call
      );

      // если нет серверных ошибок из запроса needFill и код отправлен
      if (response && this.errorsPhone.length === 0) {
        this.toStepEnterCode();
      }
      this.setIsLoading(false);
    }
  }

  *getCodeToSms() {
    // получение кода по смс
    this.setIsLoading(true);
    yield this.storeRegistration.sendCode(this.phone, EMethodSend.Sms);
    this.setIsLoading(false);
  }

  // шаг 2 ввод проверочного кода
  *onSendCode() {
    // отправка кода
    this.validateCode();

    if (this.validateCode()) {
      // проверить код

      this.setIsLoading(true);
      let response: boolean =
        yield this.storeRegistration.checkVerificationCode(
          this.phone,
          this.code
        );

      if (response) {
        if (this.withFioAndType) {
          this.toStepEnterFio();
        } else {
          this.toStepEnterPasswords();
        }
      }
      this.setIsLoading(false);
    }
  }

  onContinueFio() {
    if (this.validateStepFio()) {
      this.toStepEnterPasswords();
    }
  }

  // шаг 4 ввод паролей и отправка всей регистрации
  *onSignin() {
    this.validatePassword();
    this.validateConfirmPassword();

    if (this.validatePassword() && this.validateConfirmPassword()) {
      let _phone = this.phone.replaceAll(" ", "");

      let params: IRegistrationParams = {
        phone: _phone,
        password: this.password,
        confirmPassword: this.confirmPassword,
        code: this.code,
      };

      if (this.withFioAndType) {
        params.firstName = this.firstName.trim();
        params.lastName = this.lastName.trim();
        params.personType = this.personType;

        let _middleName = this.middleName.trim();

        if (_middleName.length) {
          params.middleName = _middleName;
        }
      }

      this.setIsLoading(true);

      //метод регистрации
      let response: boolean = yield this.storeRegistration.sendRegistration(
        params
      );

      if (response) {
        let isAuth: boolean = yield this.storeRegistration.authorization(
          this.phone,
          this.password
        );

        if (isAuth) {
          this.storeAuthentication.closeAuthModal();
          this.storeAuthentication.setIsAuth(isAuth);
          enableScroll();
        }
      }
      this.setIsLoading(false);
    }
  }

  // ---- validation
  validateLastName() {
    if (this.lastName.length === 0) {
      this.addValidationError(EInputs.LastName, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.lastName = [];
    }

    if (this.lastName.trim().length < 2) {
      this.addValidationError(EInputs.LastName, ERROR_TEXT_CHARS_NUM);
      return false;
    } else {
      this.validationErrors.lastName = [];
    }
    return true;
  }

  validateFirstName() {
    if (this.firstName.length === 0) {
      this.addValidationError(EInputs.FirstName, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.firstName = [];
    }

    if (this.firstName.trim().length < 2) {
      this.addValidationError(EInputs.FirstName, ERROR_TEXT_CHARS_NUM);
      return false;
    } else {
      this.validationErrors.firstName = [];
    }
    return true;
  }

  validateMiddleName() {
    if (
      this.middleName.trim().length > 0 &&
      this.middleName.trim().length < 2
    ) {
      this.addValidationError(EInputs.MiddleName, ERROR_TEXT_CHARS_NUM);
      return false;
    } else {
      this.validationErrors.middleName = [];
    }
    return true;
  }

  validatePhone() {
    if (this.phone.length === 0) {
      this.addValidationError(EInputs.Phone, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.phone = [];
    }

    if (this.phone.replaceAll(" ", "").length < 12) {
      this.addValidationError(EInputs.Phone, ERROR_TEXT_PHONE);
      return false;
    } else {
      this.validationErrors.phone = [];
    }
    return true;
  }

  validateCode() {
    if (this.code.length === 0) {
      this.addValidationError(EInputs.Code, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.code = [];
    }

    if (this.code.length < 4) {
      this.addValidationError(EInputs.Code, ERROR_TEXT_CODE);
      return false;
    } else {
      this.validationErrors.code = [];
    }
    return true;
  }

  validatePassword() {
    let validPassword = this.validator.check("password", this.password);

    if (this.password.length === 0) {
      this.addValidationError(EInputs.Password, ERROR_TEXT_EMPTY);
    } else {
      this.validationErrors.password = [];
    }

    if (!validPassword.passed) {
      this.addValidationError(EInputs.Password, validPassword.errors[0]);
    } else {
      this.validationErrors.password = [];
    }
    return this.validationErrors.password.length === 0;
  }

  validateConfirmPassword() {
    if (
      this.password !== this.confirmPassword ||
      this.confirmPassword.length === 0
    ) {
      this.addValidationError(
        EInputs.ConfirmPassword,
        ERROR_TEXT_CONFIRM_PASSWORD
      );
    } else {
      this.validationErrors.confirmPassword = [];
    }

    return this.validationErrors.confirmPassword.length === 0;
  }

  validateStepPhone() {
    return this.validatePhone();
  }

  validateStepFio() {
    this.validateFirstName();
    this.validateLastName();
    this.validateMiddleName();

    return (
      this.validateFirstName() &&
      this.validateLastName() &&
      this.validateMiddleName()
    );
  }

  // ---- steps
  toStepEnterPhone() {
    this.step = EStepsRegister.EnterPhone;
  }

  toStepEnterFio() {
    this.step = EStepsRegister.EnterFio;
  }

  toStepEnterCode() {
    this.step = EStepsRegister.EnterCode;
  }

  toStepEnterPasswords() {
    this.step = EStepsRegister.EnterPasswords;
  }

  toAuthorization() {
    this.storeAuthentication.setStepAuth(EStepAuth.Authorization);
    this.clearValues();
  }

  // ---- changes

  changeLastName(value: string) {
    this.setLastName(value);

    if (this.validationErrors.lastName.length) {
      this.validationErrors.lastName = [];
    }
  }

  changeFirstName(value: string) {
    this.setFirstName(value);

    if (this.validationErrors.firstName.length) {
      this.validationErrors.firstName = [];
    }
  }

  changeMiddleName(value: string) {
    this.setMiddleName(value);

    if (this.validationErrors.middleName.length) {
      this.validationErrors.middleName = [];
    }
  }

  changePhone(value: string) {
    this.setPhone(value);

    if (this.validationErrors.phone.length) {
      this.validationErrors.phone = [];
    }
  }

  changePassword(value: string) {
    this.setPassword(value);

    if (this.validationErrors.password.length) {
      this.validationErrors.password = [];
    }

    if (
      this.validationErrors.confirmPassword.length &&
      this.password === this.confirmPassword
    ) {
      this.validationErrors.confirmPassword = [];
    }
  }

  changeConfirmPassword(value: string) {
    this.setConfirmPassword(value);
    this.validateConfirmPassword();

    if (
      this.validationErrors.confirmPassword.length &&
      this.password === this.confirmPassword
    ) {
      this.validationErrors.confirmPassword = [];
    }
  }

  changeCode(value: string) {
    this.setCode(value);

    if (this.validationErrors.code.length && this.code.length) {
      this.validationErrors.code = [];
    }
  }

  changeActiveRadioButton(radioItem: IRadioItem) {
    this.setActiveRadioButton(radioItem);
    if (
      radioItem.value === TYPE_PERSON_INDIVIDUAL ||
      radioItem.value === TYPE_PERSON_LEGAL
    ) {
      this.setPersonType(radioItem.value);
    }
  }

  //  добавление ошибок
  addValidationError(type: EInputs, value: string) {
    this.validationErrors[type] = [...this.validationErrors[type], value];
  }

  // очистка формы и переход на изначальный шаг
  resetModal() {
    this.toStepEnterPhone();
    this.clearValues();
  }

  clearValues() {
    this.setLastName("");
    this.setFirstName("");
    this.setMiddleName("");
    this.setPhone("");
    this.setPassword("");
    this.setConfirmPassword("");
    this.setCode("");
    this.validationErrors.lastName = [];
    this.validationErrors.firstName = [];
    this.validationErrors.middleName = [];
    this.validationErrors.phone = [];
    this.validationErrors.code = [];
    this.validationErrors.password = [];
    this.validationErrors.confirmPassword = [];
    this.storeRegistration.clearServerErrors();
  }

  // setters
  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setLastName(value: string) {
    this.lastName = value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, "");
  }

  setFirstName(value: string) {
    this.firstName = value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, "");
  }

  setMiddleName(value: string) {
    this.middleName = value.replace(/[^a-zA-ZА-Я а-яЁё/]/gi, "");
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setCode(value: string) {
    this.code = value.replace(/[^0-9]/gi, "");
  }

  setPassword(value: string) {
    this.password = value;
  }

  setConfirmPassword(value: string) {
    this.confirmPassword = value;
  }

  setPersonType(value: TTypePerson) {
    this.personType = value;
  }

  setActiveRadioButton(value: IRadioItem) {
    this.activeRadioButton = value;
  }

  setWithFioAndType(value: boolean) {
    this.withFioAndType = value;
  }

  setStepAfterEnterCode(value: EStepsRegister) {
    this.stepAfterEnterCode = value;
  }
}
