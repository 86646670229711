import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { BackTo } from "../../../components/BackTo";
import { Booking } from "./Booking";
import { Review } from "./Review";
import { Button } from "../../../components/Button";
import { Footer } from "../../../components/Footer";
import { Book } from "../../../components/Modals/Book";
import { FavoriteIcon } from "./FavoriteIcon";
import Slider from "./Slider";
import { Loader } from "../../../components/Loader";
import { SliderPhoto } from "../../../components/Modals/SliderPhoto";
import { LeaveRequest } from "../../../components/Modals/LeaveRequest";
import { ModalReuse } from "../../../components/Modals/ModalReuse";

import { changeSpaceInNumber } from "../../../helpers";
import { getCoordinatesByWindow } from "../../../helpers/getCoordinatesByWindow";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

import { SCREENS } from "../../../navigation/endpoints";
import {
  AMO_DESCRIPTION_BUSY_APARTMENT,
  AMO_DESCRIPTION_FREE_APARTMENT,
  DESCRIPTION_PAGE_DEFAULT,
  HEADER_HEIGHT,
  PAGE_TITLE_APARTMENT_DEFAULT,
} from "../../../constants";

import { Store } from "../../../stores/types";
import { EPageType } from "../../../types";

import { StoreUI } from "./storeUI/apartment";
import { RootContext } from "../../../stores/Root";

import { ReactComponent as Watched } from "../../../assets/images/watched.svg";

import styles from "./styles/apartment.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
}

export const Apartment: FC<IProps> = observer(
  ({ storeAuthentication }: IProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const rootStore = useContext(RootContext);
    const [storeUI] = useState(
      () =>
        new StoreUI(
          navigate,
          rootStore,
          storeAuthentication,
          rootStore.storeAmo
        )
    );

    useEffect(() => {
      (async () => {
        await storeUI.init(Number(params.id));
      })();
    }, [params.id, storeAuthentication.isAuth, storeUI]);

    const _toFloor = () => {
      navigate(`${SCREENS.SCREEN_FLOOR}/${storeUI.apartment.floor}`);
    };

    // для прокрутки к блоку букинг / кнопке забронировать (адаптив),
    // если есть ошибка в букинге после нажатия кнопки Забронировать в попапе плана квартиры
    const refBlockBooking = useRef<HTMLDivElement>(null);
    const refButtonBookingAdaptive = useRef<HTMLButtonElement>(null);

    let coordinatesDesktop = getCoordinatesByWindow(refBlockBooking);
    let coordinatesAdaptive = getCoordinatesByWindow(refButtonBookingAdaptive);

    let isDesktop = useMediaQuery(575);
    const offsetYForShowButtonError = 70;

    const _onClickPlanApartment = () => {
      if (
        typeof refBlockBooking.current?.getBoundingClientRect().top !==
        "undefined"
      ) {
        let offsetYCoordinate = isDesktop
          ? coordinatesDesktop.top ?? 0
          : (coordinatesAdaptive.top ?? 0) - offsetYForShowButtonError;

        // учитывать высоту хедера при прокрутке к элементу, тк хедер в позиции fixed
        let _top = offsetYCoordinate - HEADER_HEIGHT;

        rootStore.storeModals.openApartmentPlanFlatMode(
          storeUI.apartment.id,
          true,
          storeUI.addToBasket,
          _top
        );
      }
    };

    let descriptionPage = useMemo(() => {
      return rootStore.metaDescriptionsList.find(
        (item) => item.page === EPageType.Apartment
      );
    }, [rootStore.metaDescriptionsList]);

    let helmet = useMemo(() => {
      let _title =
        descriptionPage?.title ||
        storeUI.apartmentName ||
        PAGE_TITLE_APARTMENT_DEFAULT;

      return (
        <Helmet>
          <title>{_title}</title>
          <meta
            name="description"
            content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
          />
        </Helmet>
      );
    }, [descriptionPage, storeUI.apartmentName]);

    return (
      <div className={styles.apartment}>
        {helmet}
        {storeUI.isLoading ? (
          <div className={styles.apartment__empty}>
            <Loader />
          </div>
        ) : (
          <div className={styles.apartment__content}>
            <BackTo
              className={styles.apartment__back}
              text="Квартиры"
              onClick={storeUI.toApartments}
            />
            <div className={styles.apartment__header}>
              <div className={styles.apartment__viewTitleWrapper}>
                <p className={styles.apartment__title}>
                  {storeUI.apartmentName}
                </p>
                <div className={styles.apartment__view}>
                  <Watched />
                  <span className={styles.apartment__viewSpan}>
                    {storeUI.apartment.views}
                  </span>
                </div>
              </div>

              <div className={styles.apartment__headerIcons}>
                <div className={styles.apartment__viewMobile}>
                  <Watched />
                  <span className={styles.apartment__viewSpan}>
                    {storeUI.apartment.views}
                  </span>
                </div>
                <div className={styles.apartment__favorite}>
                  <FavoriteIcon
                    isActive={storeUI.isFavorite}
                    addFavorite={storeUI.addToFavorite}
                    deleteFavorite={storeUI.deleteFavorite}
                    isAuth={storeAuthentication.isAuth}
                    openAuthModal={storeAuthentication.openAuthModal}
                  />
                </div>
              </div>
            </div>
            <div className={styles.apartment__bookingSliderWrapper}>
              <div className={styles.apartment__slider}>
                <Slider
                  slides={storeUI.apartment.images}
                  openSliderInner={storeUI.openSliderInner}
                />
                {/* Внутренний слайдер */}
                <SliderPhoto
                  modal={storeUI.isVisibleSliderInner}
                  closeModal={storeUI.closeSliderInner}
                  slides={storeUI.apartment.images}
                  isEnabledAllows={storeUI.apartment.images.length > 1}
                />
              </div>
              <Booking
                className={styles.apartment__infoBooking}
                price={storeUI.apartment.price}
                startDay={storeUI.startSelectedDay}
                endDay={storeUI.endSelectedDay}
                changeStartDay={storeUI.setStartSelectedDay}
                changeEndDay={storeUI.changeEndDay}
                minEndDate={storeUI.minEndDate}
                addToBasket={storeUI.addToBasket}
                adultsCount={storeUI.countAdults}
                chlidrenCount={storeUI.countChildren}
                changeCountAdults={storeUI.onChangeCountAdults}
                changeCountChildren={storeUI.onChangeCountChildren}
                isLoading={storeUI.isLoadingBooking}
                errors={storeUI.errorsAddBasket}
                startDate={storeUI.startOpenDate}
                isDisableMaxAdults={storeUI.isDisableMax}
                isDisableMaxChildren={storeUI.isDisableMax}
                getIsActiveDay={storeUI.getIsActiveDay}
                refBlock={refBlockBooking}
                withPets={storeUI.canPets}
                isDisableMaxPets={storeUI.isDisableMaxPets}
                petsCount={storeUI.countPets}
                changeCountPets={storeUI.onChangePets}
                checkinDate={storeUI.checkinDate}
                isBusy={storeUI.isBusy}
              />
            </div>
            {storeUI.errorsAddBasket && (
              <span className={styles.apartment__bookErrorAdaptive}>
                {storeUI.errorsAddBasket[0]}
              </span>
            )}
            <Button
              className={styles.apartment__bookBtn}
              title="ЗАБРОНИРОВАТЬ"
              theme="gold"
              onClick={storeUI.openBooking}
              refBtn={refButtonBookingAdaptive}
            />
            <h3 className={styles.apartment__price}>
              {changeSpaceInNumber(storeUI.apartment.price)} ₽ / мес.
            </h3>

            <p className={styles.apartment__desc}>
              {storeUI.descriptionParagraphs.length > 0 &&
                storeUI.descriptionParagraphs.map((item) => <p>{item}</p>)}
            </p>

            <div className={styles.apartment__perks}>
              <div className={styles.apartment__perksIcons}>
                {storeUI.apartment.facilities?.map((perk) => (
                  <div className={styles.apartment__perkIcon} key={perk.id}>
                    <img
                      src={perk.icon}
                      alt="иконка"
                      className={styles.apartment__perkIconImg}
                    />
                  </div>
                ))}
                {storeUI.apartment.terms?.map((term) => (
                  <div className={styles.apartment__perkIcon} key={term.id}>
                    <img
                      src={term.icon}
                      alt="иконка"
                      className={styles.apartment__perkIconImg}
                    />
                  </div>
                ))}
              </div>

              <div className={styles.apartment__buttons}>
                <Button
                  className={styles.apartment__buttonFloor}
                  title="ПЛАН ЭТАЖА"
                  theme="transparent"
                  onClick={_toFloor}
                />
                <Button
                  className={styles.apartment__buttonFlat}
                  title="ПЛАН КВАРТИРЫ"
                  theme="transparent"
                  onClick={_onClickPlanApartment}
                />
              </div>
            </div>

            <div className={styles.reviews}>
              <div className={styles.reviews__header}>
                <p className={styles.reviews__title}>Отзывы</p>
              </div>
              {storeUI.isLoadingReviews ? (
                <Loader className={styles.reviews__loader} />
              ) : (
                <div className={styles.reviews__review}>
                  {storeUI.reviews.length > 0 ? (
                    storeUI.reviews.map((review) => (
                      <Review
                        avatar={review.user.image}
                        name={review.user.fio}
                        text={review.text}
                        rating={review.rating}
                        key={review.id}
                      />
                    ))
                  ) : (
                    <div className={styles.reviews__empty}>
                      Отзывов пока нет
                    </div>
                  )}
                </div>
              )}

              {storeUI.reviewsTotal > 3 &&
                storeUI.reviews.length < storeUI.reviewsTotal && (
                  <div className={styles.reviews__showAllButton}>
                    <Button
                      className={styles.reviews__button}
                      title={`${"ВСЕ ОТЗЫВЫ"} (${storeUI.reviewsTotal})`}
                      theme="transparent"
                      onClick={storeUI.getAllReviews}
                    />
                  </div>
                )}
            </div>
            <Book
              isBooking={storeUI.isBooking}
              closeBooking={storeUI.closeBooking}
              price={storeUI.apartment.price}
              startDay={storeUI.startSelectedDay}
              endDay={storeUI.endSelectedDay}
              changeStartDay={storeUI.setStartSelectedDay}
              changeEndDay={storeUI.changeEndDay}
              minEndDate={storeUI.minEndDate}
              addToBasket={storeUI.addToBasket}
              adultsCount={storeUI.countAdults}
              chlidrenCount={storeUI.countChildren}
              changeCountAdults={storeUI.onChangeCountAdults}
              changeCountChildren={storeUI.onChangeCountChildren}
              isLoading={storeUI.isLoadingBooking}
              errors={storeUI.errorsAddBasket}
              startDate={storeUI.startOpenDate}
              isDisableMaxAdults={storeUI.isDisableMax}
              isDisableMaxChildren={storeUI.isDisableMax}
              getIsActiveDay={storeUI.getIsActiveDay}
              withPets={storeUI.canPets}
              petsCount={storeUI.countPets}
              changeCountPets={storeUI.onChangePets}
              isDisableMaxPets={storeUI.isDisableMaxPets}
              checkinDate={storeUI.checkinDate}
              isBusy={storeUI.isBusy}
            />
            <Footer className={styles.apartment__footer} />
          </div>
        )}
        <LeaveRequest
          visible={storeUI.isVisibleSendAmo}
          onClose={storeUI.closeAmoModal}
          description={
            storeUI.isBusy
              ? AMO_DESCRIPTION_BUSY_APARTMENT
              : AMO_DESCRIPTION_FREE_APARTMENT
          }
          isLoading={storeUI.isLoadingModal}
          name={storeUI.name}
          phone={storeUI.phone}
          email={storeUI.email}
          errorsName={storeUI.errorsName}
          errorsPhone={storeUI.errorsPhone}
          errorsEmail={storeUI.errorsEmail}
          changeName={storeUI.changeName}
          changePhone={storeUI.changePhone}
          changeEmail={storeUI.changeEmail}
          sendAmoLeaveRequest={storeUI.sendAmoLeaveRequest}
        />
        <ModalReuse
          isShowModal={storeUI.isSendedAmo}
          setIsShowModal={storeUI.closeSendedAmoModal}
          btnTitle="Хорошо"
          type="button"
        >
          <h2 className={styles.main__modalSave}>
            Ваша заявка отправлена. Мы дадим ответ в ближайшее время
          </h2>
        </ModalReuse>
      </div>
    );
  }
);
