import styles from "./styles/index.module.scss";

interface IProps {
  className?: string;
}

const Loader = ({ className = "" }: IProps) => {
  return (
    <div className={`${styles.loader} ${className}`}>
      <span className={styles.loader__inner}></span>
    </div>
  );
};

export { Loader };
