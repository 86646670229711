import { makeAutoObservable } from "mobx";

import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";
import {
  ERROR_TEXT_EMPTY,
  ERROR_TEXT_CHARS_NUM,
  ERROR_TEXT_PHONE,
} from "../../../constants";

import { IErrors, IErrorsValid } from "../../../interfaces/FeedbackModal";

import { ModelFeedback } from "../../models/FeedbackModal/feedback";
import { ModelAmo } from "../../models/Amo";

export class StoreFeedback {
  storeFeedback: ModelFeedback;
  storeAmo: ModelAmo;
  isVisible: boolean = false;
  isModalSave: boolean = false;

  name: string = "";
  phone: string = "";

  isLoading: boolean = false;

  errors: IErrors = {
    name: [],
    phone: [],
  };

  validationErrors: IErrorsValid = {
    name: [],
    phone: [],
  };

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeFeedback = new ModelFeedback();
    this.storeAmo = new ModelAmo();
  }

  // getters

  get errorsName() {
    return this.errors.name.concat(
      this.validationErrors.name,
      this.storeFeedback.errorsServer
    );
  }

  get errorsPhone() {
    return this.errors.phone.concat(
      this.validationErrors.phone,
      this.storeFeedback.errorsServer
    );
  }

  // functions

  // запросы на сервер

  *sendFeedback() {
    this.validateName();
    this.validatePhone();

    if (this.checkValidAll()) {
      this.setIsLoading(true);
      let response: boolean = yield this.storeFeedback.sendFeedback(
        this.name,
        this.phone
      );

      new Promise(() => {
        this.storeAmo.sendAmoFeedback(this.name, this.phone);
      });

      if (response) {
        this.closeFeedback();
        this.openSaveModal();
        enableScroll();
      }
      this.setIsLoading(false);
    }
  }

  // валидация

  validateName() {
    if (this.name.length === 0) {
      this.addValidationErrorsName(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.name = [];
    }

    if (this.name.length < 2) {
      this.addValidationErrorsName(ERROR_TEXT_CHARS_NUM);
      return false;
    } else {
      this.validationErrors.name = [];
    }

    return true;
  }

  validatePhone() {
    if (this.phone.length === 0) {
      this.addValidationErrorsPhone(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.phone = [];
    }

    if (this.phone.replaceAll(" ", "").length < 12) {
      this.addValidationErrorsPhone(ERROR_TEXT_PHONE);
      return false;
    } else {
      this.validationErrors.phone = [];
    }
    return true;
  }

  checkValidAll() {
    return this.validateName() && this.validatePhone();
  }

  changeName(value: string) {
    this.setName(value);

    if (this.validationErrors.name.length && this.name.length) {
      this.validationErrors.name = [];
    }
  }

  changePhone(value: string) {
    this.setPhone(value);

    if (this.validationErrors.phone.length) {
      this.validationErrors.phone = [];
    }
  }

  // сеттеры

  setName(value: string) {
    this.name = value.replace(/[^a-zA-ZА-Я а-яЁё]/gi, "");
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  // добавление ошибок

  addValidationErrorsPhone(value: string) {
    this.validationErrors.phone = [...this.validationErrors.phone, value];
  }

  addValidationErrorsName(value: string) {
    this.validationErrors.name = [...this.validationErrors.name, value];
  }

  // открыть/закрыть попап

  openFeedback() {
    this.isVisible = true;
    disableScroll();
  }

  closeFeedback() {
    this.isVisible = false;
    this.clearForm();
    enableScroll();
  }

  openSaveModal() {
    this.isModalSave = true;
    disableScroll();
  }

  closeSaveModal() {
    this.isModalSave = false;
    enableScroll();
  }

  // очистка формы

  clearForm() {
    this.name = "";
    this.phone = "";
    this.errors.name = [];
    this.errors.phone = [];
    this.validationErrors.name = [];
    this.validationErrors.phone = [];
  }
}
