export const stopPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};

export const enableScroll = () => {
  document.body.style.overflow = "visible";
  document.body.style.paddingRight = "0";
};

export const disableScroll = () => {
  let paddingOffset = window.innerWidth - document.body.offsetWidth + "px";
  document.body.style.paddingRight = paddingOffset;
  document.body.style.overflow = "hidden";
};
