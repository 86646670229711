import { useNavigate } from "react-router-dom";

import { ACCOUNT_DOCUMENTS_BILLS } from "../../../../../constants/api";

import { Button } from "../../../../../components/Button";

import { ReactComponent as Warning } from "../../../../../assets/images/warning.svg";

import styles from "./styles/payWarning.module.scss";

interface IProps {
  className: string;
  id: number;
  rentBill: boolean;
  depositBill: boolean;
}

export const PayWarning = ({
  className = "",
  id = 0,
  rentBill = false,
  depositBill = false,
}: IProps) => {
  const navigate = useNavigate();

  const _toBills = () => {
    navigate(ACCOUNT_DOCUMENTS_BILLS(id));
  };

  const check = () => {
    if (rentBill && depositBill) {
      return "Оплатите счета за аренду и депозит для подтверждения";
    }
    if (depositBill) {
      return "Оплатите счет за депозит для подтверждения";
    }
    if (rentBill) {
      return "Оплатите счет за аренду для подтверждения";
    }
  };

  return (
    <div className={`${styles.warning} ${className}`}>
      <div className={styles.warning__content}>
        <div className={styles.warning__leftContent}>
          <Warning />
          <p className={styles.warning__title}>{check()}</p>
        </div>
        <Button
          className={styles.warning__button}
          theme="gold"
          title="Перейти в счета"
          onClick={_toBills}
          type="submit"
        />
      </div>
    </div>
  );
};
