import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";

import { Input } from "../../../../components/Input";
import { Button } from "../../../../components/Button/index";
import { ModalReuse } from "../../../../components/Modals/ModalReuse";
import { Loader } from "../../../../components/Loader";
import { Empty } from "../../../../components/Empty";
import { InputPhone } from "../../../../components/InputPhone";
import { InputWithHint } from "../../../../components/InputWitnHint";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../../stores/Root";

import { ReactComponent as CompanyEmpty } from "../../../../assets/images/company.svg";

import { NUMBER_PASSPORT_ADDRESS_LENGTH } from "../../../../constants";

import styles from "./styles/company.module.scss";

export const Company: FC = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const rootStore = React.useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore, navigate, location));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI, rootStore.storeCompany, rootStore.storeMyBookings]);

  return (
    <div className={styles.company}>
      <div className={styles.company__content}>
        {!storeUI.isLoading ? (
          <>
            {!storeUI.isCompanyArray || storeUI.isCompany ? (
              <div className={styles.company__inputsWrapper}>
                <h5 className={styles.company__title}>Основное</h5>

                {storeUI.isLoadingSend && (
                  <Loader className={styles.company__loaderInner} />
                )}

                <div className={styles.company__mainInfo}>
                  <Input
                    className={styles.company__organization}
                    label="Наименование организации"
                    value={storeUI.name}
                    errors={storeUI.validateErrors.name}
                    onChange={storeUI.onChangeName}
                    placeholder="ООО Компания"
                    maxLength={80}
                    readonly={storeUI.isLoadingSend}
                  />
                  <Input
                    className={styles.company__inn}
                    label="ИНН"
                    value={storeUI.inn}
                    errors={storeUI.validateErrors.inn}
                    onChange={storeUI.onChangeInn}
                    placeholder="3811443697"
                    maxLength={10}
                    readonly={storeUI.isLoadingSend}
                  />
                  <Input
                    className={styles.company__registration}
                    label="ОГРН"
                    value={storeUI.ogrn}
                    errors={storeUI.validateErrors.ogrn}
                    onChange={storeUI.onChangeOgrn}
                    placeholder="381201001"
                    maxLength={13}
                    readonly={storeUI.isLoadingSend}
                  />
                  <Input
                    className={styles.company__director}
                    label="Директор"
                    value={storeUI.director}
                    errors={storeUI.validateErrors.director}
                    onChange={storeUI.onChangeDirector}
                    placeholder="Иванов Петр Алексеевич"
                    maxLength={80}
                    readonly={storeUI.isLoadingSend}
                  />
                </div>
                <h5 className={styles.company__requisites}>Реквизиты</h5>
                <div className={styles.company__mainInfo}>
                  <Input
                    className={styles.company__calculatedBill}
                    label="р/с"
                    value={storeUI.settlementAccount}
                    errors={storeUI.validateErrors.settlementAccount}
                    onChange={storeUI.onChangeSettlementAccount}
                    placeholder="40817810900031297377"
                    maxLength={20}
                    readonly={storeUI.isLoadingSend}
                  />
                  <Input
                    className={styles.company__correspondentBill}
                    label="к/с"
                    value={storeUI.correspondentAccount}
                    errors={storeUI.validateErrors.correspondentAccount}
                    onChange={storeUI.onChangeCorrespondentAccount}
                    placeholder="30101810145250000411"
                    maxLength={20}
                    readonly={storeUI.isLoadingSend}
                  />
                  <div className={styles.company__phoneBikContainer}>
                    <InputPhone
                      className={styles.company__phone}
                      label="Телефон"
                      value={storeUI.phone}
                      errors={storeUI.validateErrors.phone}
                      onChange={storeUI.onChangePhone}
                      readOnly={storeUI.isLoadingSend}
                    />

                    <Input
                      className={styles.company__bik}
                      label="БИК"
                      value={storeUI.bik}
                      errors={storeUI.validateErrors.bik}
                      onChange={storeUI.onChangeBik}
                      placeholder="045004774"
                      maxLength={9}
                      readonly={storeUI.isLoadingSend}
                    />
                  </div>

                  <Input
                    className={styles.company__index}
                    label="Индекс"
                    value={storeUI.addressIndex}
                    errors={storeUI.validateErrors.addressIndex}
                    onChange={storeUI.onChangeIndex}
                    placeholder="000000"
                    maxLength={6}
                    readonly={storeUI.isLoadingSend}
                  />

                  <InputWithHint
                    className={styles.company__address}
                    label="Адрес регистрации"
                    value={storeUI.address}
                    placeholder="Город"
                    errors={storeUI.validateErrors.address}
                    hintStrings={storeUI.hintsAddress}
                    onChange={storeUI.onChangeAddress}
                    onClickHint={storeUI.onClickHintAddress}
                    maxLength={NUMBER_PASSPORT_ADDRESS_LENGTH}
                    readonly={storeUI.isLoadingSend}
                  />

                  <Input
                    className={styles.company__bankName}
                    label="Наименование банка"
                    value={storeUI.bankName}
                    errors={storeUI.validateErrors.bankName}
                    onChange={storeUI.onChangeBankName}
                    placeholder="ООО Сбербанк"
                    maxLength={80}
                    readonly={storeUI.isLoadingSend}
                  />
                </div>

                <Button
                  className={styles.company__saveBtn}
                  theme="gold"
                  title="СОХРАНИТЬ"
                  onClick={
                    storeUI.haveActiveApartments
                      ? storeUI.openModalCantChangeData
                      : storeUI.createCompany
                  }
                  type="submit"
                  disabled={storeUI.isLoadingSend}
                  isDisabledStyle={storeUI.isLoadingSend}
                />
              </div>
            ) : (
              <div className={styles.company__empty}>
                <Empty
                  title="Здесь будет информация о вашей компании"
                  titleBtn="Добавить компанию"
                  Icon={CompanyEmpty}
                  onClick={storeUI.openIsCompany}
                />
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
        <ModalReuse
          isShowModal={storeUI.isModalSave}
          setIsShowModal={storeUI.closeSaveModal}
          btnTitle="Хорошо"
          type="button"
        >
          <h2>Ваши данные успешно сохранены</h2>
        </ModalReuse>

        <ModalReuse
          isShowModal={storeUI.isModalCantChangeData}
          setIsShowModal={storeUI.closeModalCantChangeData}
          btnTitle="Хорошо"
          type="button"
        >
          <h2>Нельзя изменить данные компании при действующем бронировании</h2>
        </ModalReuse>
      </div>
    </div>
  );
});
