import { FC, useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";

import { Input } from "../../../../components/Input";
import { InputDate } from "../../../../components/InputDate";
import { Button } from "../../../../components/Button/index";
import { ModalReuse } from "../../../../components/Modals/ModalReuse";
import { Loader } from "../../../../components/Loader";
import { InputPhone } from "../../../../components/InputPhone";
import { Select } from "../../../../components/Select";
import { InputWithMask } from "../../../../components/InputWithMask";
import { InputWithHint } from "../../../../components/InputWitnHint";

import {
  IMG_TYPES,
  MAX_AVATAR_SIZE_MB,
  MAX_RESOLUTION,
  NUMBER_FIRST_NAME_LENGTH,
  NUMBER_LAST_NAME_LENGTH,
  NUMBER_MIDDLE_NAME_LENGTH,
  NUMBER_PASSPORT_ADDRESS_LENGTH,
  NUMBER_PASSPORT_LENGTH,
  NUMBER_PLACE_BIRTH_LENGTH,
  SERIES_PASSPORT_LENGTH,
} from "../../../../constants";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../../stores/Root";

import { Store } from "../../../../stores/types";

import styles from "./styles/personal.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
}

export const Personal: FC<IProps> = observer(({ storeAuthentication }) => {
  const navigate = useNavigate();
  let location = useLocation();
  const rootStore = useContext(RootContext);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [storeUI] = useState(
    () => new StoreUI(storeAuthentication, rootStore, navigate, location)
  );

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI, storeAuthentication.isAuth, rootStore.userProfile]);

  return (
    <div className={styles.personal}>
      <div className={styles.personal__content}>
        {!storeUI.isLoading ? (
          <>
            <h5 className={styles.personal__title}>Основное</h5>
            {storeUI.isLoadingSave && <Loader />}
            <div className={styles.personal__avatar}>
              <div className={styles.personal__avatarImgWrapper}>
                <img
                  className={styles.personal__image}
                  src={storeUI.avatarImg}
                  alt={"avatar"}
                />
              </div>
              <label className={styles.personal__avatarWrapper}>
                <span className={styles.personal__span}>
                  <input
                    ref={fileInputRef}
                    accept={`.${IMG_TYPES.join(", .")}`}
                    onChange={storeUI.setUserAvatar}
                    type={"file"}
                    value=""
                    className={styles.personal__spanFile}
                  />
                  Сменить фото
                </span>
              </label>
            </div>

            <div className={styles.personal__info}>
              <Input
                className={styles.personal__fioData}
                value={storeUI.lastName}
                errors={storeUI.validateErrors.lastName}
                onChange={storeUI.onChangeLastName}
                label="Фамилия"
                placeholder="Фамилия"
                maxLength={NUMBER_LAST_NAME_LENGTH}
              />
              <Input
                className={styles.personal__fioData}
                value={storeUI.firstName}
                errors={storeUI.validateErrors.firstName}
                onChange={storeUI.onChangeFirstName}
                label="Имя"
                placeholder="Имя"
                maxLength={NUMBER_FIRST_NAME_LENGTH}
              />
              <Input
                className={styles.personal__fioData}
                value={storeUI.middleName}
                errors={storeUI.validateErrors.middleName}
                onChange={storeUI.onChangeMiddleName}
                label="Отчество"
                placeholder="Отчество"
                maxLength={NUMBER_MIDDLE_NAME_LENGTH}
              />
              <InputDate
                className={styles.personal__date}
                value={storeUI.birthdate}
                onChange={storeUI.onChangeDateBirth}
                label={"Дата рождения"}
                placeholder={"ДД.ММ.ГГГГ"}
                classNameInput={styles.personal__dateInput}
                errors={storeUI.validateErrors.birthdate}
              />
              <InputPhone
                className={styles.personal__phone}
                value={storeUI.phone}
                errors={[]}
                onChange={storeUI.onChangePhone}
                label="Телефон"
                readOnly={true}
              />
              <Input
                className={styles.personal__email}
                label="E-mail"
                value={storeUI.email}
                errors={storeUI.validateErrors.email}
                onChange={storeUI.onChangeEmail}
                placeholder="Example@mail.ru"
              />

              <InputWithHint
                className={styles.personal__placeBirth}
                value={storeUI.placeBirth}
                errors={storeUI.validateErrors.placeBirth}
                onChange={storeUI.onChangePlaceBirth}
                label="Место рождения"
                placeholder="Город"
                maxLength={NUMBER_PLACE_BIRTH_LENGTH}
                hintStrings={storeUI.hintStringsPlaceBirth}
                onClickHint={storeUI.onClickHintPlaceBirth}
              />
            </div>

            <h5 className={styles.personal__passportTitle}>
              Паспортные данные
            </h5>

            <div
              className={`${styles.personal__info} ${styles.personal__infoMargin}`}
            >
              <div className={styles.personal__infoInputs}>
                <Select
                  className={styles.personal__gender}
                  value={storeUI.gender}
                  errors={storeUI.validateErrors.gender}
                  onChange={storeUI.onChangeGender}
                  label="Пол"
                  options={storeUI.genderOptions}
                  placeholder="Пол"
                  isGenderMode={true}
                />
                <Input
                  className={styles.personal__serial}
                  label="Серия"
                  value={storeUI.passportSeries}
                  errors={storeUI.validateErrors.passportSeries}
                  onChange={storeUI.onChangePassportSeries}
                  placeholder="0000"
                  maxLength={SERIES_PASSPORT_LENGTH}
                />
                <Input
                  className={styles.personal__numberPassport}
                  label="Номер паспорта"
                  value={storeUI.passportNumber}
                  errors={storeUI.validateErrors.passportNumber}
                  onChange={storeUI.onChangePassportNumber}
                  placeholder="000000"
                  maxLength={NUMBER_PASSPORT_LENGTH}
                />
              </div>
              <InputDate
                className={styles.personal__datePassport}
                value={storeUI.passportDateIssue}
                onChange={storeUI.onChangePassportDateIssue}
                label={"Дата выдачи"}
                placeholder={"ДД.ММ.ГГГГ"}
                classNameInput={styles.personal__dateInput}
                errors={storeUI.validateErrors.passportDateIssue}
              />
              <Input
                className={styles.personal__placeOfIssue}
                label="Кем выдан"
                value={storeUI.passportIssued}
                errors={storeUI.validateErrors.passportIssued}
                onChange={storeUI.onChangePassportIssued}
                placeholder="ТП УФМС России"
              />
              <InputWithMask
                className={styles.personal__passportCode}
                label="Код подразделения"
                value={storeUI.passportSubdivisionCode}
                errors={storeUI.validateErrors.passportSubdivisionCode}
                onChange={storeUI.onChangePassportCode}
                placeholder="000-000"
                mask={"999-999"}
              />

              <InputWithHint
                label="Адрес регистрации"
                value={storeUI.passportAddressRegister}
                errors={storeUI.validateErrors.passportAddressRegister}
                onChange={storeUI.onChangeAddressRegister}
                placeholder="Город, улица, дом, квартира"
                hintStrings={storeUI.hintStringsAddress}
                onClickHint={storeUI.onClickHintAddress}
                className={styles.personal__address}
                maxLength={NUMBER_PASSPORT_ADDRESS_LENGTH}
              />
            </div>
            <div className={styles.personal__buttons}>
              {storeUI.errorsServer.length ? (
                <h3 className={styles.personal__warning}>
                  {storeUI.errorsServer[0]}
                </h3>
              ) : (
                <button
                  className={styles.personal__deleteBtn}
                  onClick={storeUI.openDeleteModal}
                  disabled={storeUI.isLoadingSave}
                >
                  Удалить аккаунт
                </button>
              )}
              <Button
                className={styles.personal__saveBtn}
                theme="gold"
                title="СОХРАНИТЬ"
                onClick={storeUI.updateProfile}
                type="submit"
                disabled={storeUI.isLoadingSave}
                isDisabledStyle={storeUI.isLoadingSave}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
        <ModalReuse
          isShowModal={storeUI.isModalSave}
          setIsShowModal={storeUI.closeSaveModal}
          btnTitle="Хорошо"
          type="button"
        >
          <h2>Ваши данные успешно сохранены</h2>
        </ModalReuse>
        <ModalReuse
          isShowModal={storeUI.isModalDelete}
          setIsShowModal={storeUI.closeDeleteModal}
          onClick={storeUI.deleteProfile}
          btnLeft="да"
          btnRight="нет"
          type="buttons"
          isLoading={storeUI.isLoadingDelete}
        >
          <h2>Вы уверены, что хотите удалить свой аккаунт?</h2>
        </ModalReuse>
        <ModalReuse
          isShowModal={storeUI.visibleModalFileSize}
          setIsShowModal={storeUI.closeModalFileSize}
          btnTitle="Хорошо"
          type="button"
        >
          <h2 className={styles.personal__modalAvatar}>
            Попробуйте загрузить другой аватар
          </h2>
          <h3 className={styles.personal__modalAvatarText}>
            {`Загруженное фото весит > ${MAX_AVATAR_SIZE_MB} МБ или его разрешение > ${MAX_RESOLUTION[0]} х ${MAX_RESOLUTION[1]}`}
          </h3>
        </ModalReuse>
        <ModalReuse
          isShowModal={storeUI.isModalDeleteSuccess}
          setIsShowModal={storeUI.closeDeleteModalSuccess}
          btnTitle="На главную"
          type="button"
        >
          <h2>Пользователь успешно удален</h2>
        </ModalReuse>
      </div>
    </div>
  );
});
