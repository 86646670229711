import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";

import useIsVisible from "./hooks/useIsVisible";
import useWindowScrollPositions from "./hooks/useWindowScrollPositions";

import { Header } from "./components/Header";
import { Navigation } from "./navigation";
import { FeedBack } from "./components/Modals/FeedBack";
import { FlatPlan } from "./components/Modals/FlatPlan";
import { ScrollToTop } from "./components/ScrollToTop";
import { ChoosePay } from "./components/Modals/ChoosePay";
import { Deposit } from "./components/Modals/Deposit";

import { StoreFeedback } from "./stores/ui/FeedbackModal/feedback";
import { RootContext, StoreRoot } from "./stores/Root/index";

import { EChoosePayType } from "./types";

import { FILTERS } from "./constants";

import styles from "./app.module.scss";

export const App = observer(() => {
  const [storeRoot] = useState(StoreRoot);
  const [storeFeedback] = useState(() => new StoreFeedback());
  const navigate = useNavigate();

  const headerRef = useRef<HTMLDivElement>(null);
  const isVisibleHeader = useIsVisible(headerRef);
  const headerHeight = headerRef.current?.clientHeight ?? 0;
  const positions = useWindowScrollPositions();
  const indentFromHeader = 40;

  let { storeChoosePay } = storeRoot.storeModals;

  useEffect(() => {
    (async () => {
      await storeRoot.init(navigate);
    })();
  }, [storeRoot, storeRoot.storeAuthentication.isAuth, navigate]);

  useEffect(() => {
    storeRoot.activateFloatHeader({
      headerVisible: isVisibleHeader,
      headerHeight: headerHeight,
      scrollPositions: positions.scrollPositions,
      additionalOffset: indentFromHeader,
    });
  }, [headerHeight, isVisibleHeader, positions.scrollPositions, storeRoot]);

  const removeFiltersData = () => localStorage.removeItem(FILTERS);

  useEffect(() => {
    window.addEventListener("beforeunload", removeFiltersData);
    return () => window.removeEventListener("beforeunload", removeFiltersData);
  }, []);

  useEffect(() => {
    storeRoot.activateFloatHeader({
      headerVisible: isVisibleHeader,
      headerHeight: headerHeight,
      scrollPositions: positions.scrollPositions,
      additionalOffset: indentFromHeader,
    });
  }, [headerHeight, isVisibleHeader, positions.scrollPositions, storeRoot]);

  return (
    <RootContext.Provider value={StoreRoot}>
      <div className={styles.app}>
        <div className={styles.app__aside} />
        <div className={styles.app__wrapper}>
          <Header
            innerRef={headerRef}
            basketCount={storeRoot.numberItemsInBasket}
            storeAuthentication={storeRoot.storeAuthentication}
          />

          {StoreRoot.isVisibleFloatHeader && (
            <Header
              basketCount={storeRoot.numberItemsInBasket}
              storeAuthentication={storeRoot.storeAuthentication}
              isFloatMode={true}
            />
          )}
          <ScrollToTop />
          <Navigation
            storeAuthentication={storeRoot.storeAuthentication}
            openFeedback={storeFeedback.openFeedback}
            isModalSave={storeFeedback.isModalSave}
            closeSaveModal={storeFeedback.closeSaveModal}
          />
          <FeedBack storeUI={storeFeedback} />
          {storeRoot.storeModals.visibleApartmentPlan && (
            <FlatPlan
              showFlat={storeRoot.storeModals.visibleApartmentPlan}
              closeFlatModal={storeRoot.storeModals.closeApartmentPlan}
            />
          )}

          {storeChoosePay.visibleChoosePay && (
            <ChoosePay
              visible={storeChoosePay.visibleChoosePay}
              methods={storeChoosePay.paymentMethods}
              chooseMethod={storeChoosePay.changePaymentMethod}
              onClose={() =>
                storeChoosePay.closeChoosePay({ type: EChoosePayType.Base })
              }
              onContinue={storeChoosePay.onContinue}
              isLoading={storeChoosePay.isLoading}
              errors={storeChoosePay.paymentCreateErrors}
              isAuth={storeChoosePay.isAuth}
              checkIsAuth={storeChoosePay.checkIsAuth}
              loginSberBusiness={storeChoosePay.loginSberBusiness}
              selectedPaymentMethod={storeChoosePay.selectedPaymentMethod}
            />
          )}

          {storeChoosePay.visibleChoosePayPdf && (
            <ChoosePay
              visible={storeChoosePay.visibleChoosePayPdf}
              methods={storeChoosePay.paymentMethods}
              chooseMethod={storeChoosePay.changePaymentMethod}
              onClose={() =>
                storeChoosePay.closeChoosePay({ type: EChoosePayType.WithPdf })
              }
              onContinue={storeChoosePay.onContinue}
              pdfLink={storeChoosePay.pdfLink}
              pdfLinkTitle="Счет для оплаты"
              pdfName="Счет"
              isLoading={storeChoosePay.isLoading}
              isAuth={storeChoosePay.isAuth}
              checkIsAuth={storeChoosePay.checkIsAuth}
              loginSberBusiness={storeChoosePay.loginSberBusiness}
              selectedPaymentMethod={storeChoosePay.selectedPaymentMethod}
            />
          )}

          {storeChoosePay.visibleChoosePayPawn && (
            <Deposit
              visible={storeChoosePay.visibleChoosePayPawn}
              methods={storeChoosePay.paymentMethods}
              onClose={() =>
                storeChoosePay.closeChoosePay({ type: EChoosePayType.Pawn })
              }
              onContinue={storeChoosePay.onContinue}
              onSelectMethod={storeChoosePay.changePaymentMethod}
              isLoading={storeChoosePay.isLoading}
              checkIsAuth={storeChoosePay.checkIsAuth}
              loginSberBusiness={storeChoosePay.loginSberBusiness}
              isAuth={storeChoosePay.isAuth}
              selectedPaymentMethod={storeChoosePay.selectedPaymentMethod}
              storeUser={storeRoot.userStore}
            />
          )}
        </div>
      </div>
    </RootContext.Provider>
  );
});
