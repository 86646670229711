import { FC, FormEvent } from "react";
import { observer } from "mobx-react-lite";
import InputMask from "react-input-mask";

import { ReactComponent as CalendarIcon } from "../../assets/images/inputDate.svg";

import styles from "./styles/inputDate.module.scss";

interface IProps {
  value: string;
  errors: string[];
  placeholder?: string;
  label?: string;
  classNameInput?: string;
  type?: string;
  className?: string;
  readonly?: boolean;
  onChange: (value: string) => void;
  onBlur?: (e: any) => void;
  onClick?: () => void;
  innerRef?: React.LegacyRef<HTMLInputElement> | undefined;
  calendarIsBlack?: boolean;
  withIcon?: boolean;
}

export const InputDate: FC<IProps> = observer(
  ({
    placeholder = "",
    label = "",
    value = "",
    className = "",
    classNameInput = "",
    onChange = () => {},
    onBlur = () => {},
    onClick = () => {},
    type = "",
    errors = [],
    readonly = false,
    innerRef = undefined,
    calendarIsBlack = false,
    withIcon = false,
  }) => {
    const _onChange = (e: FormEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    };
    return (
      <div className={`${styles.inputBlock} ${className}`} ref={innerRef}>
        <label className={styles.inputBlock__label}>{label}</label>
        <span className={styles.inputBlock__inputWrapper}>
          <InputMask
            className={`${styles.inputBlock__input} ${classNameInput}`}
            mask={"99.99.9999"}
            value={value}
            onChange={_onChange}
            placeholder={placeholder}
            type={type}
            onBlur={onBlur}
            readOnly={readonly}
            onClick={onClick}
          />
          {withIcon && (
            <CalendarIcon
              onClick={onClick}
              className={`${styles.inputBlock__iconCalendar} ${
                calendarIsBlack ? styles.inputBlock__iconCalendar_blackMode : ""
              }`}
            />
          )}
          {errors?.length !== 0 && (
            <span className={styles.inputBlock__errorText}>{errors[0]}</span>
          )}
        </span>
      </div>
    );
  }
);
