import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { Button } from "../../../../../Button";
import { InputPhone } from "../../../../../InputPhone";
import { Loader } from "../../../../../Loader";

import { SCREENS } from "../../../../../../navigation/endpoints";

import { IRegistrationUI } from "../../../../../../stores/ui/Authetication/interfaces";

import styles from "./styles/index.module.scss";

interface IProps {
  storeUI: IRegistrationUI;
}

export const EnterRegisterPhone = observer(({ storeUI }: IProps) => {
  return (
    <div className={styles.enterNamePhone}>
      <p className={styles.enterNamePhone__desc}>
        На ваш номер телефона будет сделан звонок, введите последние 4 цифры
        номера для подтверждения
      </p>

      {storeUI.isLoading && <Loader />}

      <InputPhone
        label="Телефон"
        value={storeUI.phone}
        onChange={storeUI.changePhone}
        errors={storeUI.errorsPhone}
        className={styles.enterNamePhone__input}
      />
      <p className={styles.enterNamePhone__accept}>
        Нажимая на кнопку, вы принимаете{" "}
        <Link to={SCREENS.SCREEN_TEXT_PRIVACY} target="_blank">
          <span>политику конфиденциальности</span>
        </Link>{" "}
        и даете согласие на{" "}
        <Link to={SCREENS.SCREEN_TEXT_PERSONAL_DATA} target="_blank">
          <span>обработку персональных данных</span>
        </Link>
      </p>
      <Button
        className={styles.enterNamePhone__button}
        isDisabledStyle={storeUI.isDisabledButtonStyle}
        disabled={storeUI.isLoading}
        title="ПОЛУЧИТЬ КОД"
        theme="gold"
        onClick={storeUI.getCodeToCall}
      />
      <div
        className={styles.enterNamePhone__login}
        onClick={storeUI.toAuthorization}
      >
        <h6>Авторизация</h6>
      </div>
    </div>
  );
});
