import { ApiManager } from "../index";

import { FEEDBACK } from "../../constants/api";

import { IFeedbackParams } from "../../interfaces/FeedbackModal";

import { TResponseApi } from "../types";

export async function sendFeedback(
  params: IFeedbackParams
): Promise<TResponseApi<IFeedbackParams>> {
  return await ApiManager.request<IFeedbackParams>(
    {
      url: FEEDBACK,
      data: params,
      method: "POST",
    },
    true
  );
}
