import { format, parseISO } from "date-fns";

import {
  DECLENSION_APARTMENT_FREE,
  DECLENSION_METER,
  DECLENSION_TENANTS,
  DECLENSION_PERSONS,
  DECLENSION_DAYS,
  IMG_TYPES,
  MAX_RESOLUTION,
  FORMET_DAY_MONTH_YEAR,
  FORMAT_DAY_MONTH,
} from "../constants";

let Smart = require("smart-plurals");
let pluralRule = Smart.Plurals.getRule("ru");

/* Пробел после тысячи в стоимости */
export function changeSpaceInNumber(num: number) {
  let n = String(num);
  return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + " ");
}

/* Проверка размера загруженного аватара */
export const checkFile = async (
  file: File,
  maxSize: number,
  openModalFileSize: () => void,
  onSuccessFunc: (file: File) => void
) => {
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.onload = async () => {
        const height = image.height;
        const width = image.width;
        const successResolution =
          (height < MAX_RESOLUTION[0] && width < MAX_RESOLUTION[1]) ||
          (height < MAX_RESOLUTION[1] && width < MAX_RESOLUTION[0]);

        if (
          file.size <= maxSize &&
          IMG_TYPES.includes(file.type.replace("image/", "")) &&
          successResolution
        ) {
          await onSuccessFunc(file);
        } else if (IMG_TYPES.includes(file.type.replace("image/", ""))) {
          openModalFileSize();
        }
      };
      image.src = e.target?.result?.toString() || "";
    };
    reader.readAsDataURL(file);
  }
};

// функции для склонения конкретных слов
export const declensionWordTenants = (amount: number) => {
  return pluralRule(amount, DECLENSION_TENANTS);
};

export const declensionWordPersons = (amount: number) => {
  return pluralRule(amount, DECLENSION_PERSONS);
};

export const declensionWordDays = (amount: number) => {
  return pluralRule(amount, DECLENSION_DAYS);
};

export const declensionWordMeter = (amount: number) => {
  return pluralRule(amount, DECLENSION_METER);
};

export const declensionWordApartment = (amount: number) => {
  return pluralRule(amount, DECLENSION_APARTMENT_FREE);
};

// Формат даты "день, месяц (словом), год" (20 октября 2022)
export const fullDateFormat = (date: string) => {
  const { ru } = require("date-fns/locale");
  return format(parseISO(date), "dd MMMM yyyy", {
    locale: ru,
  });
};

// Формат даты "день, месяц, год" (20.04.2022)
export const fullNumbersFormat = (date: string) => {
  const { ru } = require("date-fns/locale");
  return format(parseISO(date), FORMET_DAY_MONTH_YEAR, {
    locale: ru,
  });
};

// Формат даты "день, месяц" (20.04)
export const dayMonthFormat = (date: string) => {
  const { ru } = require("date-fns/locale");
  return format(parseISO(date), FORMAT_DAY_MONTH, {
    locale: ru,
  });
};

// Формат даты "год, месяц, день" (2022-04-22)
export const fullNumbersDashFormat = (date: Date) => {
  const { ru } = require("date-fns/locale");
  return format(date, "yyyy-MM-dd", {
    locale: ru,
  });
};

// Текущий год
export const currentYear = new Date().getFullYear();

// добавить ноль к цене (200.3 -> 200.30)
export function addZeroToEndPrice(price: string) {
  if (price.includes(".")) {
    let priceArr = price.split(".");

    switch (priceArr[1].length) {
      case 1:
        return `${price}0`;
      case 2:
        return price;
    }
  } else {
    return `${price}.00`;
  }

  return "";
}

// сокращение строки до кол-ва символов, добавить троеточие
export function shortenString(
  text: string,
  countLetters: number,
  callbackMore?: Function
) {
  let result = "";

  if (text.length <= countLetters) {
    result = text;
  } else {
    const _text = text.slice(0, countLetters);
    if (text[countLetters - 1] === " " || text[countLetters] === " ") {
      result = _text + "...";
    } else {
      const index = _text.lastIndexOf(" ");
      result = _text.slice(0, index) + "...";
    }

    if (callbackMore) {
      callbackMore();
    }
  }

  return result;
}
