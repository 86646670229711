import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { disableScroll, enableScroll } from "../../../../../helpers/modalsFunc";
import { SCREENS } from "../../../../../navigation/endpoints";

import { StoreFavorites } from "../../../../../stores/models/Account/favorite";

import { Store } from "../../../../../stores/types";

import { IStoreUI } from "./interfaces";

export class StoreUI implements IStoreUI {
  storeFavorites: Store.IFavorites;
  navigate: NavigateFunction;
  isLoading: boolean = false;
  isLoadingDelete: boolean = false;
  isDeleteModal: boolean = false;
  apartmentId: number = 0;

  constructor(navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeFavorites = StoreFavorites;
    this.navigate = navigate;
  }

  get favorites() {
    return this.storeFavorites.favorites;
  }

  get favoritesCount() {
    return this.storeFavorites.favorites?.length || 0;
  }

  *init() {
    this.setIsLoading(true);
    yield this.storeFavorites.getFavorites();
    this.setIsLoading(false);
  }

  *deleteCard(apartmentId: number) {
    this.setIsLoadingDelete(true);
    yield this.storeFavorites.deleteFavorite(apartmentId);
    this.closeDeleteModal();
    this.setIsLoadingDelete(false);
  }

  toApartment = (apartmentId: number) => {
    this.navigate(`${SCREENS.SCREEN_APARTMENTS}/${apartmentId}`);
  };

  toApartments = () => {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  };

  openDeleteModal(apartmentId: number) {
    this.isDeleteModal = true;
    this.setApartment(apartmentId);
    disableScroll();
  }

  closeDeleteModal() {
    this.isDeleteModal = false;
    enableScroll();
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setApartment(value: number) {
    this.apartmentId = value;
  }

  setIsLoadingDelete(value: boolean) {
    this.isLoadingDelete = value;
  }
}
