import { IRulesValidation, RULES_VALIDATION } from "validator-pyrobyte";

function email(value: string) {
  let isRus = /[А-Яа-яЁё]/i.test(value);
  let valid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    );

  return !isRus && valid;
}

function password(value: string): boolean {
  let isRus = /[а-яЁё]$/.test(value);
  let valid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[0-9a-zA-Z!+_]{6,32}$/g.test(
    value
  );
  return !isRus && valid;
}

export const newRules: IRulesValidation = {
  ...RULES_VALIDATION,
  password: {
    handler: password,
    errorText: () => "Пароль не соответствует требованиям",
  },
  email: {
    handler: email,
    errorText: () => "Неверный формат Email",
  },
};
