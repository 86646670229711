import { makeAutoObservable } from "mobx";

import { enableScroll } from "../../../helpers/modalsFunc";

import { FIRST_NEWS, LIMIT_SHOW_NEWS } from "../../../constants";

import { IStoreUI } from "./interfaces";
import { INews } from "../../../interfaces/News";

import { Store } from "../../../stores/types";

export class StoreUI implements IStoreUI {
  storeNews: Store.IAllNews;
  isLoading: boolean = true;
  countShowMoreNews: number = LIMIT_SHOW_NEWS;

  constructor(storeNews: Store.IAllNews) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeNews = storeNews;
  }

  get news() {
    return this.storeNews.news?.map((news: INews) => {
      return {
        id: news.id,
        name: news.name,
        image: news.image,
        text: news.text,
        created_at: news.created_at,
      };
    });
  }

  get total() {
    return this.storeNews.total;
  }

  *init() {
    enableScroll();
    this.setIsLoading(true);
    yield this.storeNews.getNews(FIRST_NEWS);
    this.setIsLoading(false);
  }

  *showMoreNews() {
    this.setCountShowMoreNews();
    yield this.storeNews.getNews(this.countShowMoreNews);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setCountShowMoreNews() {
    this.countShowMoreNews = this.countShowMoreNews + FIRST_NEWS;
  }
}
