//TODO: лучше перенести в одну из папок
class StoreRouting {
  history: any;

  setHistory(history: any) {
    this.history = history;
  }

  to(path: any) {
    this.history.push(path);
  }
}

export const storeRouting = new StoreRouting();
