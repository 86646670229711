import { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";

import squareImg from "../../../assets/images/flats/square.svg";
import people from "../../../assets/images/flats/people.svg";
import pets from "../../../assets/images/flats/pets.svg";

import { declensionWordMeter } from "../../../helpers";

import { TFlatInfoItem } from "../../../types";

import styles from "./styles/infoItem.module.scss";

interface IProps {
  type: TFlatInfoItem;
  square?: string;
  minPeople?: string;
  maxPeople?: string;
}

export const InfoItem: FC<IProps> = observer(
  ({ type = "square", square = "0", minPeople = "1", maxPeople = "" }) => {
    let typeStyle = useMemo(() => {
      switch (type) {
        case "square":
          return (
            <>
              <img src={squareImg} alt="square" />
              <p className={styles.container__text}>
                {square} {declensionWordMeter(Number(square))}
                <sup>2</sup>
              </p>
            </>
          );
        case "people":
          return (
            <>
              <img src={people} alt="people" />
              <p className={styles.container__text}>
                {maxPeople ? (
                  <span>
                    {minPeople}-{maxPeople}
                  </span>
                ) : (
                  <span>{minPeople}</span>
                )}
              </p>
            </>
          );
        case "pets":
          return (
            <>
              <img src={pets} alt="pets" />
              <p className={styles.container__text}>С животными</p>
            </>
          );
      }
    }, [maxPeople, minPeople, square, type]);

    return <div className={styles.container}>{typeStyle}</div>;
  }
);
