import { TResponseApi } from "../types";
import { ApiManager } from "../index";

import {
  IBasket,
  ICheckApartment,
  ICheckApartmentResponse,
} from "../../interfaces/Basket";
import { ICommonResponse } from "../../interfaces";

import {
  BASKET,
  BASKET_APARTMENT,
  BASKET_DELETE,
  BOOKING_CHECK_APARTMENT,
} from "../../constants/api";

export async function getBasket(): Promise<TResponseApi<IBasket>> {
  return await ApiManager.request<IBasket>(
    {
      url: BASKET,
      method: "GET",
    },
    true
  );
}

export async function clearBasket(): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BASKET_DELETE,
      method: "DELETE",
    },
    true
  );
}

export async function deleteApartment(params: {
  basketCalendarId: number;
}): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BASKET_APARTMENT,
      data: params,
      method: "DELETE",
    },
    true
  );
}

export async function checkFreeApartment(
  params: ICheckApartment
): Promise<TResponseApi<ICheckApartmentResponse>> {
  return await ApiManager.request<ICheckApartmentResponse>(
    {
      url: BOOKING_CHECK_APARTMENT,
      params: params,
      method: "GET",
    },
    true
  );
}
