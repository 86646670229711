import { ApiManager } from "..";

import { CONTACT_CONTACT } from "../../constants/api";

import { IContact } from "../../interfaces/Contacts";

import { TResponseApi } from "../types";

export async function getContacts(): Promise<TResponseApi<IContact[]>> {
  return await ApiManager.request<IContact[]>({
    url: CONTACT_CONTACT,
    method: "GET",
  });
}
