import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { ReactComponent as HintIcon } from "../../assets/images/hint-mini.svg";

import styles from "./styles/rentalInfo.module.scss";

interface IProps {
  apartmentName: string;
  apartmentNumber: string;
  floor: string;
  period: string;
  amountOfDays: string;
  monthPrice: string;
  deposit: string;
  className?: string;
}

export const RentalInfo = observer(
  ({
    apartmentName = "",
    apartmentNumber = "",
    floor = "",
    period = "",
    amountOfDays = "",
    monthPrice = "",
    deposit = "",
    className = "",
  }: IProps) => {
    let rentalProps = useMemo(() => {
      return [
        {
          title: "Период:",
          hintText: null,
          value: period,
        },
        {
          title: "Срок:",
          hintText: null,
          value: amountOfDays,
        },
        {
          title: "Ежемесячный платеж:",
          hintText: null,
          value: `${monthPrice} руб.`,
        },
        {
          title: "Депозит:",
          hintText: "Минимальная сумма депозита",
          value: `${deposit} руб.`,
        },
      ];
    }, [amountOfDays, deposit, monthPrice, period]);

    return (
      <div className={`${styles.rentalInfo} ${className}`}>
        <div className={styles.rentalInfo__top}>
          <p className={styles.rentalInfo__title}>{apartmentName}</p>
          <p className={styles.rentalInfo__number}>
            №{apartmentNumber} Этаж: {floor}
          </p>
        </div>

        <div className={styles.rentalInfo__bottom}>
          <div className={styles.rentalInfo__propBlock}>
            {rentalProps.slice(0, 2).map((item) => (
              <div className={styles.rentalInfo__propWrapper}>
                <span className={styles.rentalInfo__propTitle}>
                  {item.title}
                </span>
                <span className={styles.rentalInfo__propValue}>
                  {item.value}
                </span>
              </div>
            ))}
          </div>

          <div className={styles.rentalInfo__propBlock}>
            {rentalProps.slice(2, rentalProps.length).map((item) => (
              <div className={styles.rentalInfo__propWrapper}>
                <span className={styles.rentalInfo__propTitle}>
                  {item.title}
                  {item.hintText && (
                    <span className={styles.rentalInfo__hint}>
                      <HintIcon className={styles.rentalInfo__hintIcon} />
                      <span className={styles.rentalInfo__hintTextWrapper}>
                        {item.hintText}
                      </span>
                    </span>
                  )}
                </span>

                <span className={styles.rentalInfo__propValue}>
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
);
