export enum ELoading {
  Main = "main",
  MainMyServices = "mainMyServices",
  MainAvailable = "mainAvailable",
  MainSelected = "mainSelected",
  Popup = "popup",
  Delete = "delete",
  CreateBill = "createBill",
}

export enum EPaginationData {
  MyServices = "myServices",
  AvailableServices = "availableServices",
  SelectedServices = "selectedServices",
}

export enum EVisiblePopup {
  DetailService = "detailService",
  Delete = "delete",
  SuccessAddService = "successAddService",
  BillPaymentLater = "billPaymentLater",
  ParkingInfo = "parkingInfo",
}
