import { makeAutoObservable } from "mobx";

import { getArchives, sendReview } from "../../../api/Account";
import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { ICommonResponse } from "../../../interfaces";

import { IArchiveItem } from "../../../interfaces/Account";

export class ModelArchive implements Store.IArchive {
  archivesItems: IArchiveItem[] = [];
  errorsReview: string[] = [];

  bookingsIdSet: Set<number> = new Set();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // functions

  *getArchives() {
    let response: TResponseApi<IArchiveItem[]> = yield getArchives();
    if (response.isSuccess && response.data !== null) {
      this.clearBookingIdSet();

      this.setArchives(response.data);
      response.data?.map((item) => this.addBookingId(item.id));
    }
  }

  *sendReview(
    apartmentId: number,
    bookingId: number,
    rating: number,
    text: string
  ) {
    let response: TResponseApi<ICommonResponse> = yield sendReview({
      apartmentId,
      bookingId,
      rating,
      text,
    });

    if (response.isError) {
      if (response.error?.message) {
        this.addErrorReview(response.error?.message);
      } else {
        this.addErrorReview(ERROR_TEXT_SERVER_COMMON);
      }
    }
    if (response.isSuccess && response.data !== null) {
      return response.data.result;
    }
  }

  addErrorReview(value: string) {
    this.errorsReview = [...this.errorsReview, value];
  }

  addBookingId(value: number) {
    this.bookingsIdSet.add(value);
  }

  clearBookingIdSet() {
    this.bookingsIdSet.clear();
  }

  checkBookingId(bookingId: string) {
    return this.bookingsIdSet.has(Number(bookingId));
  }

  clearErrorReview() {
    this.errorsReview = [];
  }

  // setters

  setArchives(archivesItems: IArchiveItem[]) {
    this.archivesItems = archivesItems;
  }
}
