import { makeAutoObservable } from "mobx";
import { getBasket } from "../../../api/Basket";

import {
  addServiceToBasket,
  deleteServiceToBasket,
  getAvailableServices,
  getServices,
} from "../../../api/Services";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { ICommonResponse } from "../../../interfaces";
import { IBasket, IServiceOfBasket } from "../../../interfaces/Basket";
import {
  IAvailableService,
  IService,
  IServicesResponse,
} from "../../../interfaces/Services";

export class ModelServices implements Store.IServices {
  storeRoot: Store.IRootStore;
  services: IService[] = [];
  availableServicesId: number[] = [];
  addedServicesId: number[] = [];
  limit: number = 0;
  total: number = 0;
  haveApartment: boolean = false;

  servicesInBasket: IServiceOfBasket[] = [];

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
  }

  // functions

  *getServices(limit: number) {
    let response: TResponseApi<IServicesResponse> = yield getServices({
      limit,
    });

    if (response.isSuccess && response.data !== null) {
      if (response.data.services?.length > 0) {
        this.setServices(response.data.services);
      }

      this.setLimit(response.data.limit);
      this.setTotal(response.data.total);
    }
  }

  *getBasket() {
    let response: TResponseApi<IBasket> = yield getBasket();

    if (response.isSuccess && response.data !== null) {
      this.setServiceInBasket(response.data.services);
      this.setHaveApartment(response.data.apartments?.length !== 0);
      this.addedServicesId = [];
      if (response.data.services?.length) {
        response.data.services.forEach((service) => {
          if (!this.addedServicesId.includes(service.serviceId)) {
            this.addServicesId(service.serviceId);
          }
        });
      }
    }
  }

  *addServiceToBasket(serviceId: number) {
    let response: TResponseApi<ICommonResponse> = yield addServiceToBasket({
      serviceId,
    });

    if (response.isSuccess && response.data !== null) {
      return response.data?.result === "success";
    }
    return false;
  }

  *deleteService(basketServiceId: number) {
    let response: TResponseApi<ICommonResponse> = yield deleteServiceToBasket({
      basketServiceId,
    });
    if (response.isSuccess) {
      return response.data?.result === "success";
    }
    return false;
  }

  *getAvailableServicesId() {
    let response: TResponseApi<IAvailableService[]> =
      yield getAvailableServices();

    if (response.isSuccess && response.data !== null) {
      this.setAvailableServicesId([]);
      if (response.data?.length) {
        response.data.map((service) => this.addAvailableServicesId(service.id));
      }
    }
  }

  addServicesId(value: number) {
    this.addedServicesId = [...this.addedServicesId, value];
    this.storeRoot.setNumberServices(this.addedServicesId.length);
  }

  deleteServiceId(value: number) {
    let myIndex = this.addedServicesId?.indexOf(value);
    if (myIndex !== -1) {
      this.addedServicesId?.splice(myIndex, 1);
    }
    this.storeRoot.setNumberServices(this.addedServicesId.length);
  }

  addAvailableServicesId(value: number) {
    this.availableServicesId = [...this.availableServicesId, value];
  }

  // setters

  setServices(value: IService[]) {
    this.services = value;
  }

  setLimit(value: number) {
    this.limit = value;
  }

  setTotal(value: number) {
    this.total = value;
  }

  setHaveApartment(value: boolean) {
    this.haveApartment = value;
  }

  setServiceInBasket(value: IServiceOfBasket[]) {
    this.servicesInBasket = value;
  }

  setAvailableServicesId(value: number[]) {
    this.availableServicesId = value;
  }
}
