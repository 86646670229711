import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";
import { format, differenceInCalendarDays, addDays } from "date-fns";

import { disableScroll, enableScroll } from "../../../../../helpers/modalsFunc";
import { SCREENS } from "../../../../../navigation/endpoints";
import {
  BILLS,
  ERROR_EMPTY_DATE,
  NUMBER_RANGE_DAYS_FOR_DATE_DEPARTURE,
  PAGE_DOCUMENTS_BY_ID,
  PAGE_ORDERING_DATA_BY_ID,
  PAGE_RESIDENTS_BY_ID,
  TODAY,
  FORMAT_DAY_MONTH,
} from "../../../../../constants";

import { BILL_NOT_PAID, IStoreUI } from "./interfaces";
import { IApartmentItem } from "../../../../../interfaces/Account";

import { Store } from "../../../../../stores/types";
import {
  EBookingCalendarStatus,
  EBookingType,
  EDepositStatus,
  ETypeBill,
} from "../../../../../types";
import { ETabsResidents } from "./../../../../../types/index";

import {
  changeSpaceInNumber,
  declensionWordDays,
  fullNumbersDashFormat,
} from "../../../../../helpers";
import { getNextDay } from "../../../../../helpers/getNextDay";

export class StoreUI implements IStoreUI {
  storeMyApartment: Store.IMyApartment;
  storeBooking: Store.IBooking;
  storeBills: Store.IBillsStore;
  storeAdditionalBookingData: Store.IAdditionalBookingData;
  storeRoot: Store.IRootStore;
  navigate: NavigateFunction;
  isLoading: boolean = false;
  isLoadingPopup: boolean = false;

  // попапы
  visibleExtension: boolean = false;
  visibleExtensionResult: boolean = false;
  visibleInfoPopup: boolean = false;

  // возможно ли продление (показывать ли селект)
  isCanBeExtended: boolean = false;

  // id букинга из url
  bookingIdByUrl: string = "";

  // выбранный день текущего календаря
  selectedDay: Date | null = null;
  errorsDate: string[] = [];

  constructor(storeRoot: Store.IRootStore, navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.navigate = navigate;
    this.storeRoot = storeRoot;
    this.storeBills = storeRoot.storeBills;
    this.storeMyApartment = storeRoot.storeMyApartment;
    this.storeBooking = storeRoot.storeBooking;
    this.storeAdditionalBookingData = storeRoot.storeAdditionalBookingData;
  }

  // getters
  get apartmentInfo() {
    if (this.storeMyApartment.apartmentDetail?.bookingCalendar) {
      return this.getReadyApartment(
        this.storeMyApartment.apartmentDetail.bookingCalendar
      );
    }

    return null;
  }

  get bills() {
    return (
      this.storeBills.bills?.map((item) => ({
        type: item.type,
        status: item.status,
      })) || []
    );
  }

  get rentBill() {
    return this.bills
      .map(
        (item) => item.type === ETypeBill.Rent && item.status === BILL_NOT_PAID
      )
      .some((item) => item === true);
  }

  get depositBill() {
    return this.bills
      .map(
        (item) =>
          item.type === ETypeBill.Deposit && item.status === BILL_NOT_PAID
      )
      .some((item) => item === true);
  }

  get checkBills() {
    return this.rentBill || this.depositBill;
  }

  get haveArrivalDate() {
    return Boolean(this.apartmentInfo?.arrivalDate);
  }

  get bookingId() {
    return this.storeMyApartment.apartmentDetail?.id ?? 0;
  }

  get bookingCalendarId() {
    return this.storeMyApartment.apartmentDetail?.bookingCalendar.id ?? 0;
  }

  get errorsSendBooking() {
    return this.errorsDate.concat(this.storeMyApartment.errorsSendBooking);
  }

  get depositStatus() {
    return this.storeMyApartment.responseExtension?.depositStatus || null;
  }

  get depositPrice() {
    return this.storeMyApartment.responseExtension?.depositDifference
      ? changeSpaceInNumber(
          this.storeMyApartment.responseExtension?.depositDifference
        )
      : "";
  }

  get depositStatusButtonTitle() {
    switch (this.depositStatus) {
      case EDepositStatus.Pay:
        return "Перейти в счета";
      case EDepositStatus.Give:
        return "Хорошо";
      case EDepositStatus.Nothing:
        return "Хорошо";
      default:
        return "";
    }
  }

  get hideAddPerson() {
    let _capacity =
      this.storeMyApartment.apartmentDetail?.bookingCalendar.capacity;

    let _countResidents =
      Number(
        this.storeMyApartment.apartmentDetail?.bookingCalendar.countAdults
      ) +
      Number(
        this.storeMyApartment.apartmentDetail?.bookingCalendar.countChildren
      );

    let _blockedStatus =
      this.storeMyApartment.apartmentDetail?.bookingCalendar.status !==
      EBookingCalendarStatus.Waiting;

    return _capacity === _countResidents || _blockedStatus;
  }

  get bookingEndDate() {
    return (
      this.storeMyApartment.apartmentDetail?.bookingCalendar?.endDate ?? null
    );
  }

  // минимальная дата для продления аренды (следующая после конца аренды)
  get minDate(): Date | null {
    return this.bookingEndDate
      ? addDays(new Date(this.bookingEndDate), 1)
      : null;
  }

  // максимальная дата, после которой календарь неактивен
  get maxDate(): Date | null {
    // нужно отнять 1 от диапазона, т к библиотека не включает при отсчете this.minDate
    return this.minDate
      ? addDays(this.minDate, NUMBER_RANGE_DAYS_FOR_DATE_DEPARTURE - 1)
      : null;
  }

  // functions

  *init(bookingId: string) {
    this.setIsLoading(true);
    yield this.storeRoot.getBookingsList(EBookingType.Active);

    if (
      bookingId &&
      this.storeRoot.checkBookingIdByUrl(bookingId, EBookingType.Active)
    ) {
      this.setBookingIdByUrl(bookingId);
      yield this.storeMyApartment.getBookingById(Number(bookingId));

      // проверка на возможность продления
      if (
        this.bookingCalendarId &&
        this.storeMyApartment.apartmentDetail?.bookingCalendar.arrivalDate
      ) {
        yield this.storeMyApartment.getCanExtension(this.bookingCalendarId);
        this.setIsCanBeExtended(this.storeMyApartment.canExtension);
      }
    } else {
      this.navigate(SCREENS.SCREEN_404);
    }
    this.setIsLoading(false);
  }

  *sendBookingExtension() {
    this.setIsLoadingPopup(true);

    let isValidDay = this.validateSelectedDay();

    if (this.selectedDay && isValidDay && this.bookingCalendarId) {
      yield this.storeMyApartment.sendBookingExtension(
        fullNumbersDashFormat(this.selectedDay),
        this.bookingCalendarId
      );

      if (this.storeMyApartment.responseExtension?.bookingId) {
        this.closeExtension();
        this.openExtensionResult();
      }
    }

    this.setIsLoadingPopup(false);
  }

  validateSelectedDay() {
    this.errorsDate = !this.selectedDay
      ? [...this.errorsDate, ERROR_EMPTY_DATE]
      : [];
    return this.selectedDay;
  }

  depositClickButton() {
    switch (this.depositStatus) {
      case EDepositStatus.Pay:
        return this.toBills(this.bookingIdByUrl);
      case EDepositStatus.Give:
        return this.closeExtensionResult();
      case EDepositStatus.Nothing:
        return this.toMyBookings();
      default:
        return null;
    }
  }

  private getReadyApartment(apartment: IApartmentItem) {
    let countDays = differenceInCalendarDays(
      new Date(apartment.startDate),
      TODAY
    );
    let _isVisibleEndBooking = countDays <= 0;

    return {
      image: apartment.image || "",
      rooms: apartment.rooms || "",
      square: Math.round(apartment.square) || 0,
      floor: apartment.floor || 0,
      flatNumber: apartment.flatNumber || 0,
      status: apartment.status || "",
      tenants: apartment.countAdults + apartment.countChildren || 0,
      startDate: this.convertDate(apartment.startDate) || "",
      endDate: this.convertDate(apartment.endDate) || "",
      endDateOriginal: apartment.endDate || "",
      datePeriod: apartment.datePeriod
        ? `${apartment.datePeriod} ${declensionWordDays(apartment.datePeriod)}`
        : "",
      dateEnding: apartment.dateEnding || 0,
      isVisibleEndBooking: _isVisibleEndBooking || false,
      arrivalDate: apartment.arrivalDate,
      capacity: apartment.capacity,
      deposit: apartment.deposit,
      checkinDate: getNextDay(apartment.startDate, FORMAT_DAY_MONTH),
    };
  }

  convertDate(date: string | Date) {
    return format(new Date(date), "dd.MM.yyyy");
  }

  // ---- navigation

  toApartments() {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  }

  toTenants(idBooking: number) {
    this.navigate(
      PAGE_RESIDENTS_BY_ID(String(idBooking), ETabsResidents.Tenants)
    );
  }

  toBills(idBooking: string) {
    this.navigate(PAGE_DOCUMENTS_BY_ID(idBooking, BILLS));
  }

  toMyBookings() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS);
  }

  toOrderingData() {
    this.navigate(PAGE_ORDERING_DATA_BY_ID(this.bookingIdByUrl));
  }

  changeSelectedDay(value: Date | null) {
    this.setSelectedDay(value);
    this.errorsDate = [];
  }

  resetProlong() {
    this.storeMyApartment.clearErrorsSendBooking();
    this.errorsDate = [];
    this.setSelectedDay(null);
  }

  // ---- modals

  openExtension() {
    this.setVisibleExtension(true);
    disableScroll();
  }

  closeExtension() {
    this.setVisibleExtension(false);
    this.resetProlong();
    enableScroll();
  }

  openExtensionResult() {
    this.setVisibleExtensionResult(true);
    disableScroll();
  }

  closeExtensionResult() {
    this.setVisibleExtensionResult(false);
    enableScroll();
  }

  openInfoPopup() {
    this.setVisibleInfoPopup(true);
    disableScroll();
  }

  closeInfoPopup() {
    this.setVisibleInfoPopup(false);
    enableScroll();
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingPopup(value: boolean) {
    this.isLoadingPopup = value;
  }

  setIsCanBeExtended(value: boolean) {
    this.isCanBeExtended = value;
  }

  setVisibleExtension(value: boolean) {
    this.visibleExtension = value;
  }

  setVisibleExtensionResult(value: boolean) {
    this.visibleExtensionResult = value;
  }

  setVisibleInfoPopup(value: boolean) {
    this.visibleInfoPopup = value;
  }

  setBookingIdByUrl(value: string) {
    this.bookingIdByUrl = value;
  }

  setSelectedDay(value: Date | null) {
    this.selectedDay = value;
  }
}
