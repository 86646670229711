import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import { ISliderItem } from "../../../../interfaces/Apartment";

import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";

import "./styles/slider.scss";

interface IProps {
  slides: ISliderItem[];
  isEnabledAllows?: boolean;
}

export const Slider: FC<IProps> = ({ slides = [], isEnabledAllows = true }) => {
  return (
    <div className="slider-photo">
      <div
        className={`sliderWrapper ${
          !isEnabledAllows && "sliderWrapper_onePhoto"
        }`}
      >
        <Swiper
          className="photos"
          grabCursor={true}
          scrollbar={true}
          loop={true}
          navigation={{
            prevEl: ".button-prev",
            nextEl: ".button-next",
          }}
          modules={[Pagination, Navigation]}
          freeMode={true}
          watchSlidesProgress={true}
          allowSlideNext={isEnabledAllows}
          allowSlidePrev={isEnabledAllows}
          pagination={{
            el: ".swiper-pagination",
            type: "fraction",
          }}
        >
          <div className="swiper-pagination"></div>
          {slides.map((photo) => (
            <SwiperSlide key={photo.id}>
              <div className="photo-block">
                <img className="photo" src={photo.image} alt="view flat" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="navigation">
        <button className="button-prev"></button>
        <button className="button-next"></button>
      </div>
    </div>
  );
};
