import { ApiManager } from "../index";

import { AMO_BACK_CALL, AMO_BOOKING } from "../../constants/api";
import { TIMEOUT_AMO } from "../../constants";

import { IAmoFeedbackRequest, IAmoLeaveRequest } from "../../interfaces/Amo";

import { TResponseApi } from "../types";

export async function sendAmoLeaveRequest(
  params: IAmoLeaveRequest
): Promise<TResponseApi<IAmoLeaveRequest>> {
  return await ApiManager.request<IAmoLeaveRequest>(
    {
      url: AMO_BOOKING,
      data: params,
      method: "POST",
      timeout: TIMEOUT_AMO,
    },
    true
  );
}

export async function sendAmoFeedback(
  params: IAmoFeedbackRequest
): Promise<TResponseApi<IAmoFeedbackRequest>> {
  return await ApiManager.request<IAmoFeedbackRequest>(
    {
      url: AMO_BACK_CALL,
      data: params,
      method: "POST",
      timeout: TIMEOUT_AMO,
    },
    true
  );
}
