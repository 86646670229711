import { observer } from "mobx-react-lite";

import styles from "./styles/counter.module.scss";

interface IProps {
  title?: string;
  subtitle?: string;
  persons: number;
  setPersons: (persons: number) => void;
  bigMode?: boolean;
  maxPersons?: number;
  minPersons?: number;
  isDisableMax?: boolean;
  isDisabledMaxStyle?: boolean;
  isDisabledAll?: boolean;
  className?: string;
  classNameButton?: string;
  classNameCount?: string;
}

export const Counter = observer(
  ({
    title = "",
    subtitle = "",
    persons = 0,
    bigMode = false,
    setPersons = () => {},
    maxPersons = 255,
    minPersons = 0,
    isDisableMax = false,
    isDisabledMaxStyle = false,
    isDisabledAll = false,
    className = "",
    classNameButton = "",
    classNameCount = "",
  }: IProps) => {
    const increment = () => {
      if (maxPersons > persons) {
        setPersons(persons + 1);
      }
    };
    const decrement = () => {
      if (persons === 0) return;
      if (minPersons < persons) {
        setPersons(persons - 1);
      }
    };

    let _isDisabledStylePlus =
      isDisableMax || isDisabledMaxStyle || isDisabledAll;
    let _isDisabledStyleMinus = isDisabledAll || persons === 0;

    return (
      <div
        className={`${styles.counter} ${
          bigMode ? styles.counter_bigMode : ""
        } ${className}`}
      >
        <div>
          {title && <h6 className={styles.counter__title}>{title}</h6>}
          {subtitle && <h6 className={styles.counter__subtitle}>{subtitle}</h6>}
        </div>

        <div className={styles.counter__buttonsWrapper}>
          <button
            onClick={decrement}
            className={`${styles.counter__button} ${
              _isDisabledStyleMinus ? styles.counter__button_disabled : ""
            } ${classNameButton}`}
            disabled={isDisabledAll}
          >
            <span>-</span>
          </button>
          <h6 className={`${styles.counter__count} ${classNameCount}`}>
            {persons}
          </h6>
          <button
            onClick={increment}
            disabled={isDisableMax || isDisabledAll}
            className={`${styles.counter__button} ${
              _isDisabledStylePlus ? styles.counter__button_disabled : ""
            }  ${classNameButton}`}
          >
            <span className={styles.counter__plus}>+</span>
          </button>
        </div>
      </div>
    );
  }
);
