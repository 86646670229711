import React, { FC, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";

import { RootContext } from "../../../../stores/Root";

import { BackTo } from "../../../../components/BackTo";
import { Rent } from "../Rent";
import { Tabs } from "../../../../components/Tabs";
import { Documents } from "../Documents";
import { Residents } from "../Residents";
import { ServicesPersonal } from "../Services";

import { StoreUI } from "./storeUI/index";

import { ETabsMyBookings } from "../../../../types";

import { SCREENS } from "../../../../navigation/endpoints";

import styles from "./styles/myBooking.module.scss";

export const MyBooking: FC = observer(() => {
  const params = useParams();
  const navigate = useNavigate();
  const rootStore = React.useContext(RootContext);

  const [storeUI] = useState(() => new StoreUI(navigate, rootStore));

  useEffect(() => {
    (async () => {
      await storeUI.init(params.id ?? "");
    })();
  }, [storeUI, params.id]);

  const toMyBookings = () => {
    navigate(SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS);
  };

  let activeTabPage = useMemo(() => {
    switch (params.tabName) {
      case ETabsMyBookings.Rent:
        return <Rent />;
      case ETabsMyBookings.Documents:
        return <Documents />;
      case ETabsMyBookings.Residents:
        return <Residents />;
      case ETabsMyBookings.Services:
        return <ServicesPersonal />;
      default:
        return "";
    }
  }, [params.tabName]);

  return (
    <div className={styles.myBooking}>
      <BackTo
        text={"Вернуться к моим броням"}
        onClick={toMyBookings}
        className={styles.myBooking__backBtn}
      />

      <div className={styles.myBooking__tabsContainer}>
        <Tabs
          bookingId={params.id ?? ""}
          activeTabName={params.tabName ?? ETabsMyBookings.Rent}
          tabs={storeUI.tabs}
          onClick={storeUI.onClickTab}
          className={styles.myBooking__tabs}
        />
      </div>
      {activeTabPage}
    </div>
  );
});
