import { FC } from "react";

import styles from "./styles/socialMedia.module.scss";

interface IProps {
  icon: string;
}

export const SocialMedia: FC<IProps> = ({ icon = "" }) => {
  return (
    <div className={styles.container}>
      <img src={icon} alt="icon" />
    </div>
  );
};
