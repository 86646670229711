import { makeAutoObservable } from "mobx";

import { getBlockedApartments, getFloorPlan } from "../../../api/Floor";

import { TResponseApi } from "../../../api/types";
import {
  IBlockedApartment,
  IBlockedApartmentsRequest,
  IFloorPlanApartment,
} from "../../../interfaces/Floor";
import { Store } from "../../types";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

export class ModelFloor implements Store.IFloor {
  floorPlan: IFloorPlanApartment[] = [];
  blockedApartments: IBlockedApartment[] = [];
  errorFloorPlan: string = "";
  errorBlockedApartments: string = "";

  isSuccessGetBlocked: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getFloorPlan(floorNumber: number) {
    let response: TResponseApi<IFloorPlanApartment[]> = yield getFloorPlan(
      floorNumber
    );

    if (response.isSuccess && response.data !== null) {
      this.setErrorFloorPlan("");
      this.setFloorPlan(response.data);
    }

    if (response.isError) {
      if (response.error?.message) {
        this.setErrorFloorPlan(response.error?.message);
      }
      this.setErrorFloorPlan(ERROR_TEXT_SERVER_COMMON);
    }
  }

  *getBlockedApartments(params: IBlockedApartmentsRequest) {
    let response: TResponseApi<IBlockedApartment[]> =
      yield getBlockedApartments(params);

    if (response.isSuccess && response.data !== null) {
      this.setErrorBlockedApartments("");
      this.setBlockedApartments(response.data);
    }

    if (response.isError) {
      if (response.error?.message) {
        this.setErrorBlockedApartments(response.error?.message);
      }
      this.setErrorBlockedApartments(ERROR_TEXT_SERVER_COMMON);
    }

    this.setIsSuccessGetBlocked(response.isSuccess);
  }

  // setters

  setFloorPlan(value: IFloorPlanApartment[]) {
    this.floorPlan = value;
  }

  setBlockedApartments(value: IBlockedApartment[]) {
    this.blockedApartments = value;
  }

  setIsSuccessGetBlocked(value: boolean) {
    this.isSuccessGetBlocked = value;
  }

  setErrorFloorPlan(value: string) {
    this.errorFloorPlan = value;
  }
  setErrorBlockedApartments(value: string) {
    this.errorBlockedApartments = value;
  }
}
