import { FC } from "react";
import { observer } from "mobx-react-lite";

import { SortingDrop } from "./SortingDrop";
import { useOutSide } from "../../../hooks/useOutSide";

import { ISorting } from "../../../interfaces/Catalog";

import { ReactComponent as ArrowOrange } from "../../../assets/images/arrowOrange.svg";

import styles from "./styles/sorting.module.scss";

interface IProps {
  isOpen?: boolean;
  changeSort: (value: ISorting) => void;
  sortSelected: ISorting;
  sortList: ISorting[];
  className?: string;
}

export const Sorting: FC<IProps> = observer(
  ({
    changeSort = () => {},
    sortSelected = { value: "", type: null },
    className = "",
    sortList = [],
  }) => {
    const { ref, isShow, setIsShow } = useOutSide(false);
    return (
      <div className={`${styles.sort} ${className}`}>
        Сортировка:{" "}
        <div
          className={styles.sort__sorting}
          onClick={() => setIsShow(!isShow)}
          ref={ref}
        >
          <span className={styles.sort__type}>{sortSelected?.value}</span>
          <ArrowOrange
            className={`${styles.sort__arrow} ${
              isShow ? styles.sort__rotate : ""
            }`}
          />
          {isShow && (
            <SortingDrop changeSort={changeSort} sortList={sortList} />
          )}
        </div>
      </div>
    );
  }
);
