import { makeAutoObservable } from "mobx";

import { getApartmentPlan } from "../../../api/FlatPlan";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";
import { IApartmentPlanResponse } from "../../../interfaces/FlatPlan";

export class ModelApartmentPlan implements Store.IApartmentPlan {
  error: string = "";
  apartmentPlanInfo: IApartmentPlanResponse = {
    id: 0,
    number: 0,
    square: 0,
    floor: 0,
    building: 0,
    typeApartment: 0,
    apartmentPlan: "",
  };

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getApartmentPlan(apartmentId: number) {
    let response: TResponseApi<IApartmentPlanResponse> = yield getApartmentPlan(
      apartmentId
    );

    if (response.isSuccess && response.data !== null) {
      this.setApartmentPlanInfo(response.data);
    }

    if (response.isError) {
      if (response.error?.message) {
        this.setError(response.error?.message);
      }
      this.setError(ERROR_TEXT_SERVER_COMMON);
    } else {
      this.setError("");
    }
  }

  clearInfo() {
    this.setApartmentPlanInfo({
      id: 0,
      number: 0,
      square: 0,
      floor: 0,
      building: 0,
      typeApartment: 0,
      apartmentPlan: "",
    });
    this.setError("");
  }

  // setters
  setError(value: string) {
    this.error = value;
  }

  setApartmentPlanInfo(value: IApartmentPlanResponse) {
    this.apartmentPlanInfo = value;
  }
}
