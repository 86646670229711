import { makeAutoObservable } from "mobx";

import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";

import { ERROR_AGREE_AGREEMENT } from "../../../constants";

import { IStoreUI } from "./interfaces";
import { Store } from "../../../stores/types";

export class ModalsStoreUI implements Store.IModalsOrderingData {
  storeParernt: IStoreUI;

  isVisibleFillProfile: boolean = false;
  isVisiblePaymentModal: boolean = false;
  isVisiblePaymentSuccess: boolean = false;
  isVisibleAgreementModal: boolean = false;
  isVisibleDepositModal: boolean = false;

  isLoadingPopup: boolean = false;

  isAgree: boolean = false; // согласие с договором (чекбокс)
  errorsIsAgree: string[] = [];

  constructor(mainStoreUI: IStoreUI) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeParernt = mainStoreUI;
  }

  // functions

  // ---- validation
  // валидация чекбокса согласия с договором
  validateIsAgree() {
    if (!this.isAgree) {
      this.errorsIsAgree = [...this.errorsIsAgree, ERROR_AGREE_AGREEMENT];
      return false;
    }
    return true;
  }

  // ---- changes
  changeIsAgree() {
    this.setIsAgree(!this.isAgree);
  }

  clearIsAgreeErrors() {
    this.errorsIsAgree = [];
  }

  // ---- open ~ close

  openFillProfile() {
    this.setIsVisibleFillProfile(true);
    disableScroll();
  }

  closeFillProfile() {
    this.setIsVisibleFillProfile(false);
    enableScroll();
  }

  openPaymentModal() {
    this.setIsVisiblePaymentModal(true);
    disableScroll();
  }

  closePaymentModal() {
    this.setIsVisiblePaymentModal(false);
    enableScroll();
  }

  openPaymentSuccessModal() {
    this.setIsVisiblePaymentSuccess(true);
    disableScroll();
  }

  closePaymentSuccessModal() {
    this.setIsVisiblePaymentSuccess(false);
    enableScroll();
  }

  openAgreementModal() {
    this.setIsVisibleAgreementModal(true);
    this.clearIsAgreeErrors();
    this.setIsAgree(false);
    disableScroll();
  }

  closeAgreementModal() {
    this.setIsVisibleAgreementModal(false);
    enableScroll();
  }

  openDepositModal() {
    this.setVisibleDepositModal(true);
    disableScroll();
  }

  closeDepositModal() {
    this.setVisibleDepositModal(false);
    enableScroll();
  }

  // setters

  setIsVisibleFillProfile(value: boolean) {
    this.isVisibleFillProfile = value;
  }

  setIsVisiblePaymentModal(value: boolean) {
    this.isVisiblePaymentModal = value;
  }

  setIsVisiblePaymentSuccess(value: boolean) {
    this.isVisiblePaymentSuccess = value;
  }

  setIsVisibleAgreementModal(value: boolean) {
    this.isVisibleAgreementModal = value;
  }

  setVisibleDepositModal(value: boolean) {
    this.isVisibleDepositModal = value;
  }

  setIsLoadingPopup(value: boolean) {
    this.isLoadingPopup = value;
  }

  setIsAgree(value: boolean) {
    this.isAgree = value;
  }
}
