import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { Tenant } from "../Tenant";
import { Button } from "../../../components/Button";
import { Loader } from "../../../components/Loader";
import { RentalInfo } from "../../../components/RentalInfo";
import { ModalReuse } from "../../../components/Modals/ModalReuse";

import { EFormType, EInputsNames, TPersonalType } from "../../../types";
import { Store } from "../../../stores/types";

import styles from "./styles/stepRenter.module.scss";

interface IProps {
  storeRenter: Store.IRenterOrderingData | null;
  formReadonly: boolean;
  stepTitle: string;
  personalType: TPersonalType | string;
}

export const StepRenter = observer(
  ({
    storeRenter = null,
    formReadonly = false,
    personalType = "",
    stepTitle = "Шаг 0 из 0",
  }: IProps) => {
    const disabledInputsForIndividual = [
      EInputsNames.FirstName,
      EInputsNames.LastName,
      EInputsNames.MiddleName,
    ];

    const namesDisabledInputs = useMemo(() => {
      return personalType === "individual" ? disabledInputsForIndividual : [];
    }, [personalType]);

    return (
      storeRenter && (
        <div className={styles.stepRenter}>
          {storeRenter.isLoading && (
            <Loader className={styles.stepRenter__loader} />
          )}
          <div className={styles.stepRenter__header}>
            <h5 className={styles.stepRenter__title}>
              Заполните форму для договора
            </h5>
            <span className={styles.stepRenter__stepNum}>{stepTitle}</span>
          </div>

          <div className={styles.stepRenter__rentalInfo}>
            <h5 className={styles.stepRenter__rentalTitle}>
              Информация по аренде
            </h5>
            {storeRenter?.apartmentInfo && (
              <RentalInfo
                apartmentName={storeRenter.apartmentInfo.apartmentName}
                apartmentNumber={storeRenter.apartmentInfo.apartmentNumber}
                floor={storeRenter.apartmentInfo.floor}
                period={storeRenter.apartmentInfo.period}
                amountOfDays={storeRenter.apartmentInfo.amountDays}
                monthPrice={storeRenter.apartmentInfo.monthPrice}
                deposit={storeRenter.apartmentInfo.deposit}
              />
            )}
          </div>

          <div className={styles.stepRenter__infoRenter}>
            <div className={styles.stepRenter__renterHeader}>
              <h5 className={styles.stepRenter__renterTitle}>Арендатор</h5>
            </div>

            <button
              onClick={storeRenter.fillByProfile}
              className={`${styles.stepRenter__renterFillBtn} ${styles.stepRenter__renterFillBtn_adaptive}`}
              disabled={storeRenter.isLoadingSend}
            >
              Заполнить данные из профиля
            </button>

            {storeRenter.formData && (
              <Tenant
                typeForm={EFormType.Renter}
                valuePhone={storeRenter.formData.phone}
                valueLastName={storeRenter.formData.lastName}
                valueFirstName={storeRenter.formData.firstName}
                valueMiddleName={storeRenter.formData.middleName}
                valueDateBirth={storeRenter.formData.birthdate}
                valueSeries={storeRenter.formData.series}
                valueNumberPassport={storeRenter.formData.number}
                valueDateIssue={storeRenter.formData.dateIssue}
                valueIssuedBy={storeRenter.formData.issuedBy}
                valueRegistrationAddress={
                  storeRenter.formData.registrationAddress
                }
                valueGender={storeRenter.formData.gender}
                valueEmail={storeRenter.formData.email}
                genderOptions={storeRenter.formData.genderOptions}
                valueCode={storeRenter.formData.subdivisionCode}
                valuePlace={storeRenter.formData.placeBirth}
                errorsPhone={storeRenter.formData.validateErrors.phone}
                errorsLastName={storeRenter.formData.validateErrors.lastName}
                errorsFirstName={storeRenter.formData.validateErrors.firstName}
                errorsMiddleName={
                  storeRenter.formData.validateErrors.middleName
                }
                errorsDateBirth={storeRenter.formData.validateErrors.birthdate}
                errorsSeries={storeRenter.formData.validateErrors.series}
                errorsNumberPassport={
                  storeRenter.formData.validateErrors.number
                }
                errorsDateIssue={storeRenter.formData.validateErrors.dateIssue}
                errorsIssuedBy={storeRenter.formData.validateErrors.issuedBy}
                errorsRegistrationAddress={
                  storeRenter.formData.validateErrors.registrationAddress
                }
                errorsGender={storeRenter.formData.validateErrors.gender}
                errorsPlace={storeRenter.formData.validateErrors.placeBirth}
                errorsCode={storeRenter.formData.validateErrors.subdivisionCode}
                errorsEmail={storeRenter.formData.validateErrors.email}
                changePhone={storeRenter.formData.changePhone}
                changeLastName={storeRenter.formData.changeLastName}
                changeFirstName={storeRenter.formData.changeFirstName}
                changeMiddleName={storeRenter.formData.changeMiddleName}
                changeDateBirth={storeRenter.formData.changeDateBirth}
                changeSeries={(value: string) =>
                  storeRenter.formData?.changeSeries(value, true)
                }
                changeNumberPassport={storeRenter.formData.changeNumberPassport}
                changeDateIssue={storeRenter.formData.changeDateIssue}
                changeIssuedBy={storeRenter.formData.changeIssuedBy}
                changeRegistrationAddress={
                  storeRenter.changeRegistrationAddress
                }
                changeGender={storeRenter.formData.changeGender}
                changeCode={storeRenter.formData.changeSubdivisionCode}
                changeEmail={storeRenter.formData.changeEmail}
                changePlace={storeRenter.changePlaceBirth}
                isReadonly={formReadonly || storeRenter.isLoadingSend}
                hintAddressStrings={storeRenter.formData.hintStringsAddress}
                onClickHintAddress={(value: string) =>
                  storeRenter.onClickHintAddress(value)
                }
                hintStringsPlaceBirth={
                  storeRenter.formData.hintStringsPlaceBirth
                }
                onClickHintPlaceBirth={(value: string) =>
                  storeRenter.onClickHintPlaceBirth(value)
                }
                disabledInputs={namesDisabledInputs}
              />
            )}
          </div>

          <div className={styles.stepRenter__renterFillBtnContainer}>
            <button
              onClick={storeRenter.fillByProfile}
              className={styles.stepRenter__renterFillBtn}
              disabled={storeRenter.isLoadingSend}
            >
              Заполнить данные из профиля
            </button>
          </div>

          {storeRenter.isLoadingSend && <Loader />}
          <Button
            title={"Продолжить"}
            onClick={storeRenter.onContinue}
            theme={"gold"}
            className={styles.stepRenter__button}
            disabled={storeRenter.isLoadingSend}
            isDisabledStyle={storeRenter.isLoadingSend}
          />

          <ModalReuse
            isShowModal={storeRenter.isVisibleFillProfile}
            setIsShowModal={storeRenter.setIsVisibleFillProfile}
            type={"button"}
            btnTitle={"ПЕРЕЙТИ В ПРОФИЛЬ"}
            classNameModal={styles.payOrder__modalReuse}
            onClickSingleButton={storeRenter.toProfile}
          >
            <h2 className={styles.payOrder__modalReuseTitle}>
              Заполните свой профиль <br /> полностью
            </h2>
          </ModalReuse>
        </div>
      )
    );
  }
);
