import { FC, useState } from "react";
import { Link } from "react-router-dom";

import { Input } from "../../Input";
import { Button } from "../../Button";
import { TextArea } from "../../TextArea";
import { Loader } from "../../Loader";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { SCREENS } from "../../../navigation/endpoints";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/askQuestion.module.scss";

interface IProps {
  visible: boolean;
  isLoading: boolean;
  name: string;
  email: string;
  text: string;
  emailErrors: string[];
  textErrors: string[];
  nameErrors: string[];
  changeName(value: string): void;
  changeEmail(value: string): void;
  changeText(value: string): void;
  close(): void;
  onSend(): void;
}

export const AskQuestion: FC<IProps> = ({
  visible = false,
  isLoading = false,
  name = "",
  email = "",
  text = "",
  emailErrors = [],
  textErrors = [],
  nameErrors = [],
  changeName = () => {},
  changeEmail = () => {},
  changeText = () => {},
  close = () => {},
  onSend = () => {},
}) => {
  let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
    useState<EventTarget | null>(null);

  let _onSend = () => {
    onSend();
    enableScroll();
  };

  const _closeModal = () => {
    if (backgroundOnDownTarget) {
      close();
      enableScroll();
    }

    setBackgroundOnDownTarget(null);
  };

  return (
    <>
      {visible && (
        <div
          className={styles.askQuestion}
          onClick={_closeModal}
          onMouseDown={(e) => {
            setBackgroundOnDownTarget(e.target);
          }}
        >
          <div className={styles.askQuestion__popupWrapper}>
            <div
              className={styles.askQuestion__popup}
              onClick={stopPropagation}
              onMouseDown={stopPropagation}
            >
              <Close onClick={close} className={styles.askQuestion__close} />
              <div className={styles.askQuestion__content}>
                {isLoading && <Loader className={styles.askQuestion__loader} />}
                <p className={styles.askQuestion__title}>Задайте свой вопрос</p>

                <Input
                  value={name}
                  onChange={changeName}
                  errors={nameErrors}
                  label={window.innerWidth <= 576 ? "Как вас зовут?" : "Имя"}
                  placeholder="Введите имя"
                  className={styles.askQuestion__inputName}
                  maxLength={30}
                />

                <Input
                  value={email}
                  onChange={changeEmail}
                  errors={emailErrors}
                  label="E-mail"
                  placeholder="Введите email"
                  maxLength={30}
                  className={styles.askQuestion__inputEmail}
                />

                <TextArea
                  value={text}
                  onChange={changeText}
                  errors={textErrors}
                  label="Вопрос"
                  placeholder="Задайте вопрос"
                  maxLength={500}
                  className={styles.askQuestion__textarea}
                  classNameWrapper={styles.askQuestion__textareaWrap}
                />

                <div className={styles.askQuestion__buttonPoliticsWrapper}>
                  <Button
                    title="ОТПРАВИТЬ ЗАЯВКУ"
                    type="submit"
                    theme="gold"
                    onClick={_onSend}
                    className={styles.askQuestion__button}
                    disabled={isLoading}
                    isDisabledStyle={
                      !name.length || !text.length || !email.length || isLoading
                    }
                  />

                  <p className={styles.askQuestion__politics}>
                    Нажимая на кнопку, вы подтверждаете, что согласны <br /> с{" "}
                    <Link
                      to={SCREENS.SCREEN_TEXT_PERSONAL_DATA}
                      target="_blank"
                    >
                      <span>Политикой обработки данных</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
