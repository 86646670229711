import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { SCREENS } from "../../../../navigation/endpoints";

import { StoreSocials } from "../../../../stores/models/MainPage/socials";

import { IMainSocialsList } from "../../../../interfaces/MainPage";

export class StoreUI {
  storeSocials: IMainSocialsList;
  navigate: NavigateFunction;
  onToScrollTop: boolean = false;
  isLoading: boolean = true;

  constructor(navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeSocials = StoreSocials;
    this.navigate = navigate;
  }

  get socials() {
    return this.storeSocials.socials;
  }

  *init() {
    this.setIsLoading(true);
    yield this.storeSocials.getSocials();
    this.setIsLoading(false);
  }

  showScrollTop() {
    this.onToScrollTop = true;
  }

  closeScrollTop() {
    this.onToScrollTop = false;
  }

  toApartments() {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
