import { makeAutoObservable } from "mobx";

import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";

import { TenantStoreUI } from "./forms/tenantStoreUI";

import {
  ERROR_LIMIT_CAPACITY,
  ERROR_TEXT_FORM_COMMON,
  GENDER_FEMALE,
  GENDER_MALE,
  OPTION_GENDER_FEMALE,
  OPTION_GENDER_MALE,
  RENTER_COUNT,
} from "../../../constants";

import { IStoreUI, ITenantStoreUI } from "./interfaces";
import { IAdditionTenant } from "../../../interfaces/Booking";
import { ITenant } from "../../../interfaces/Tenants";
import { IOptionCommon } from "../../../interfaces";

import { Store } from "./../../../stores/types/index";
import { TGender } from "../../../types";

export class TenantsStoreUI implements Store.ITenantsOrderingData {
  storeParent: IStoreUI;
  storeRoot: Store.IRootStore;
  storeAdditionalBookingData: Store.IAdditionalBookingData;
  tenantsForms: ITenantStoreUI[] = [];
  tenantsForSend: IAdditionTenant[] = [];

  isLoading: boolean = false;

  validationErrorsTenants: string[] = [];

  capacityTenants: number = 0;
  countAdults: number = 0;

  mainValidationErrors: string[] = [];

  isVisibleDeletePopup: boolean = false;
  currentIdForDelete: number | null = null;

  constructor(storeParent: IStoreUI) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeParent = storeParent;
    this.storeRoot = this.storeParent.storeRoot;
    this.storeAdditionalBookingData =
      this.storeParent.storeAdditionalBookingData;
  }

  // getters
  get disabledAddTenant() {
    return this.tenantsForms.length >= this.capacityTenants;
  }

  get canPets() {
    return this.storeParent.canPets;
  }

  get bookingId() {
    return this.storeParent.bookingId;
  }

  get mainErrors() {
    return this.storeAdditionalBookingData.errorsAdditionTenants.concat(
      this.mainValidationErrors
    );
  }

  // functions
  init() {
    if (this.bookingId) {
      let countFilledAdults = this.tenantsForms.length;
      let countAddedAdultsFromBooking = this.countAdults;

      if (
        countAddedAdultsFromBooking &&
        countAddedAdultsFromBooking < this.countAdults
      ) {
        let countEmptyForms = countAddedAdultsFromBooking - countFilledAdults;

        Array.from({ length: countEmptyForms }).forEach((_) => {
          this.addTenant();
        });
      }
    }
  }

  *onContinue() {
    this.setIsLoading(true);
    this.validateAllTenants();
    this.clearMainErrors();

    if (this.checkValidateAllTenants() && this.bookingId) {
      this.addTenantsForSend();

      let params = {
        tenants: this.tenantsForSend,
      };

      yield this.storeAdditionalBookingData.sendAdditionTenants(
        this.bookingId,
        params
      );

      if (this.storeAdditionalBookingData.isSuccessAdditionTenants) {
        // если для квартиры доступны питомцы, переходим на шаг питомцев
        if (this.canPets) {
          this.storeParent.toStepPets();
        } else {
          // иначе
          if (this.bookingId) {
            // отправляем пустой массив животных
            yield this.storeAdditionalBookingData.sendAdditionPets(
              this.bookingId,
              []
            );

            //  формирование и вывод попапа с pdf Договора
            yield this.storeParent.createAgreement();
          }
        }
      }
    }
    this.setIsLoading(false);
  }

  // ---- предзаполнение данными с бэка
  fillFormsPrevEnteredData(tenants: ITenant[]) {
    this.setIsLoading(true);

    if (
      tenants.length &&
      this.tenantsForms.length < tenants.length - RENTER_COUNT
    ) {
      tenants.forEach((tenant, index) => {
        let _formId = index + 1;
        if (!tenant.renter) {
          this.fillTenantForm(tenant, _formId);
        }
      });
    }
    this.setIsLoading(false);
  }

  writeCounts({ capacityTenants = 0, countAdults = 0, countChildren = 0 }) {
    this.setCapacityTenants(capacityTenants);
    this.setCountAdults(countAdults);
  }

  // ---- добавление жильца
  addTenant() {
    if (this.disabledAddTenant) {
      this.addMainValidationError(ERROR_LIMIT_CAPACITY);
    } else {
      this.clearMainErrors();
      //добавить форму, если есть жильцы кроме арендатора
      if (this.tenantsForms.length) {
        let lastElem = this.tenantsForms[this.tenantsForms.length - 1];
        let lastId = lastElem.localId; // берем id последнего жильца
        this.createTenant(lastId + 1); // и прибавляем 1
      } else {
        let firstId = 1;
        this.createTenant(firstId);
      }
    }
  }

  createTenant(id: number): TenantStoreUI {
    let tenant = new TenantStoreUI(); // создать экземпляр Формы жильца
    tenant.setLocalId(id); // записываем id формы жильца, чтобы потом обращаться по нему
    this.tenantsForms.push(tenant); // отправляем в массив форм

    return tenant;
  }

  // ---- удаление жильца

  deleteTenant(tetantId: number) {
    let _tetant = this.tenantsForms.find(
      (tetant) => tetant.localId === tetantId
    );

    if (_tetant) {
      let myIndex = this.tenantsForms.indexOf(_tetant);
      if (myIndex !== -1) {
        this.tenantsForms.splice(myIndex, 1);
      }
    }

    if (!this.disabledAddTenant) {
      this.clearMainErrors();
    }

    this.closeDeletePopup();
  }

  // ---- helpers

  // найти конкретную форму жильца в массиве форм по id
  searchTenant(idTenant: number) {
    return this.tenantsForms.find((tenant) => tenant.localId === idTenant);
  }

  reverseAndChangeSeparator(
    str: string,
    fromSeparator: string,
    toSeparator: string
  ) {
    if (str && fromSeparator && toSeparator) {
      let elemArr = str.split(fromSeparator).reverse();
      return elemArr.join(toSeparator);
    }
    return "";
  }

  addTenantsForSend() {
    // добавление отформатированных данных для отправки на бэк
    let readyTenants: IAdditionTenant[] = this.tenantsForms.map((tenant) => {
      let _gender: TGender = GENDER_MALE;

      if (
        tenant.gender?.value === GENDER_MALE ||
        tenant.gender?.value === GENDER_FEMALE
      ) {
        _gender = tenant.gender?.value;
      }

      let _tenant: IAdditionTenant = {
        lastName: tenant.lastName,
        firstName: tenant.firstName,
        placeBirth: tenant.placeBirth,
        relationTenant: tenant.relationTenant,
        phone: tenant.phone.replaceAll(" ", ""),
        birthdate: this.reverseAndChangeSeparator(tenant.birthdate, ".", "-"),
        series: tenant.series,
        number: tenant.number,
        dateIssue: this.reverseAndChangeSeparator(tenant.dateIssue, ".", "-"),
        issuedBy: tenant.issuedBy,
        registrationAddress: tenant.registrationAddress,
        gender: _gender,
      };

      if (tenant.middleName.length > 0) {
        _tenant.middleName = tenant.middleName;
      }

      return _tenant;
    });
    // добавляем готовые объекты в массив для отправки
    this.setTenantsForSend(readyTenants);
  }

  fillTenantForm(tenant: IAdditionTenant, formId: number) {
    let tenantForm: ITenantStoreUI = this.createTenant(formId);

    if (tenantForm) {
      tenantForm.changeFirstName(tenant.firstName || "");
      tenantForm.changeLastName(tenant.lastName || "");
      tenantForm.changeMiddleName(tenant.middleName || "");
      tenantForm.changeDateBirth(
        this.reverseAndChangeSeparator(tenant.birthdate || "", "-", ".") || ""
      );
      tenantForm.changePhone(tenant.phone || "");
      tenantForm.changePlaceBirth(tenant.placeBirth || "");
      tenantForm.changeSeries(tenant.series || "", false);
      tenantForm.changeNumberPassport(tenant.number || "");
      tenantForm.changeDateIssue(
        this.reverseAndChangeSeparator(tenant.dateIssue, "-", ".") || "" || ""
      );
      tenantForm.changeIssuedBy(tenant.issuedBy || "");
      tenantForm.changeRegistrationAddress(tenant.registrationAddress || "");
      tenantForm.changeRelationTenant(tenant.relationTenant || "");
      tenantForm.changeGender(this.getGenderOption(tenant.gender));
    }
  }

  // ---- validation

  // вызов валидации всех полей форм жильцов
  validateAllTenants() {
    this.tenantsForms.forEach((tenant) => {
      tenant.validateAllForTenant();
    });
  }

  // проверка что все поля всех форм жильцов валидны
  checkValidateAllTenants() {
    let validArray: Array<boolean> = [];

    this.tenantsForms.forEach((tenant) => {
      validArray.push(tenant.validateAllForTenant());
    });

    if (validArray.includes(false)) {
      this.addMainValidationError(ERROR_TEXT_FORM_COMMON);
    }

    return !validArray.includes(false);
  }

  addMainValidationError(value: string) {
    this.mainValidationErrors = [...this.mainValidationErrors, value];
  }

  clearMainErrors() {
    this.mainValidationErrors = [];
    this.storeAdditionalBookingData.clearErrorsAdditionTenants();
  }

  getGenderOption(value: TGender) {
    switch (value) {
      case GENDER_MALE:
        return OPTION_GENDER_MALE;
      case GENDER_FEMALE:
        return OPTION_GENDER_FEMALE;
      default:
        return null;
    }
  }

  // ---- подсказки по строке

  onClickHintAddress(value: string, idTenant: number) {
    if (idTenant) {
      let _tenant = this.searchTenant(idTenant);

      if (_tenant) {
        _tenant.changeRegistrationAddress(value);
        _tenant.setHintStringsAddress([]);
      }
    }
  }

  onClickHintPlaceBirth(value: string, idTenant: number) {
    if (idTenant) {
      let _tenant = this.searchTenant(idTenant);

      if (_tenant) {
        _tenant.changePlaceBirth(value);
        _tenant.setHintStringsPlaceBirth([]);
      }
    }
  }

  *getHintByString(value: string) {
    yield this.storeRoot.userStore.getHintByString(value);

    if (this.storeRoot.userStore.hintStrings.length > 0) {
      return this.storeRoot.userStore.hintStrings;
    }

    return [];
  }

  // ---- changes
  changeLastName(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeLastName(value);
    }
  }

  changeFirstName(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeFirstName(value);
    }
  }

  changeMiddleName(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeMiddleName(value);
    }
  }

  changePhone(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changePhone(value);
    }
  }

  changeDateBirth(date: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);

    if (_tenant) {
      _tenant.changeDateBirth(date);
    }
  }

  changeSeries(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeSeries(value, false);
    }
  }

  changeNumberPassport(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeNumberPassport(value);
    }
  }

  changeDateIssue(date: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);

    if (_tenant) {
      _tenant.changeDateIssue(date);
    }
  }

  changeIssuedBy(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeIssuedBy(value);
    }
  }

  *changeAddress(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeRegistrationAddress(value);

      if (value.length > 0) {
        let _hints: string[] = yield this.getHintByString(value);
        _tenant.setHintStringsAddress(_hints);
      }
    }
  }

  *changePlaceBirth(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);

    if (_tenant) {
      _tenant.changePlaceBirth(value);

      if (value.length > 0) {
        let _hints: string[] = yield this.getHintByString(value);
        _tenant.setHintStringsPlaceBirth(_hints);
      }
    }
  }

  changeRelationTenant(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeRelationTenant(value);
    }
  }

  changeGender(value: IOptionCommon | null, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeGender(value);
    }
  }

  openDeletePopup(localId: number) {
    this.setCurrentIdForDelete(localId);
    this.setIsVisibleDeletePopup(true);
    disableScroll();
  }

  closeDeletePopup() {
    this.setCurrentIdForDelete(null);
    this.setIsVisibleDeletePopup(false);
    enableScroll();
  }

  // setters

  setCapacityTenants(value: number) {
    this.capacityTenants = value;
  }

  setCountAdults(value: number) {
    this.countAdults = value;
  }

  setTenantsForSend(value: IAdditionTenant[]) {
    this.tenantsForSend = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsVisibleDeletePopup(value: boolean) {
    this.isVisibleDeletePopup = value;
  }

  setCurrentIdForDelete(value: number | null) {
    this.currentIdForDelete = value;
  }
}
