import { EPartnerTextCode } from "./../../../constants/index";
import { makeAutoObservable } from "mobx";

import { getPartners, sendPartnerRequest } from "../../../api/Partner";
import { getTextConctreteBlock } from "../../../api/MainPage";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

import { ICommonResponse } from "../../../interfaces";
import { IPartnerItem } from "../../../interfaces/Partner";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";
import { TTextPage } from "../../../types";

export class ModelPartner implements Store.IPartner {
  partners: IPartnerItem[] = [];
  errorsServer: string[] = [];
  partnersDescription: string = "";

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *getPartners() {
    let response: TResponseApi<IPartnerItem[]> = yield getPartners();
    if (response.isSuccess && response.data !== null) {
      this.setPartners(response.data);
    }
  }

  *sendPartnerRequest(
    name: string,
    organization: string,
    phone: string,
    text: string
  ) {
    let response: TResponseApi<ICommonResponse> = yield sendPartnerRequest({
      name,
      organization,
      phone,
      text,
    });

    if (response.isError) {
      this.addErrorServer(ERROR_TEXT_SERVER_COMMON);
    }

    if (response.isSuccess && response.data !== null) {
      return response.data.result;
    }
  }

  *getPartnerDescription() {
    let response: TResponseApi<TTextPage[]> = yield getTextConctreteBlock({
      code: EPartnerTextCode.PartnersDescription,
    });

    if (response.isSuccess && response.data !== null) {
      this.setPartnersDescription(response.data[0]?.text ?? "");
    }
  }

  addErrorServer(value: string) {
    this.errorsServer = [...this.errorsServer, value];
  }

  setPartners(partners: IPartnerItem[]) {
    this.partners = partners;
  }

  setPartnersDescription(value: string) {
    this.partnersDescription = value;
  }
}

export const StorePartner = new ModelPartner();
