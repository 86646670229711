import { ApiManager } from "../..";

import {
  DOCUMENT_AGREEMENT,
  DOCUMENT_AGREEMENT_PDF,
} from "../../../constants/api";
import { TIMEOUT_PDF } from "../../../constants";

import { IFilePdf, IDocumentsAgreement } from "./../../../interfaces/Documents";

import { TResponseApi } from "../../types";

export async function getAgreements(params: {
  bookingId: number;
}): Promise<TResponseApi<IDocumentsAgreement>> {
  return await ApiManager.request<IDocumentsAgreement>(
    {
      url: DOCUMENT_AGREEMENT,
      method: "GET",
      params: params,
    },
    true
  );
}

export async function getAgreementPdf(params: {
  agreementId: number;
}): Promise<TResponseApi<IFilePdf>> {
  return await ApiManager.request<IFilePdf>(
    {
      url: DOCUMENT_AGREEMENT_PDF,
      method: "GET",
      params: params,
      timeout: TIMEOUT_PDF,
    },
    true
  );
}
