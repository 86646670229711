import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tabs } from "../../../../components/Tabs";
import { PopupService } from "../../../../components/Modals/PopupService";
import { Loader } from "../../../../components/Loader";
import { ModalReuse } from "../../../../components/Modals/ModalReuse";
import { SelectedServices } from "./SelectedServices";
import { AvailableServices } from "./AvailableServices";
import { MyServices } from "./MyServices";

import { RootContext } from "../../../../stores/Root";

import { ETabsServices } from "../../../../types";

import { EVisiblePopup } from "./storeUI/enums";

import { StoreUI } from "./storeUI";

import styles from "./styles/servicesPersonal.module.scss";

export const ServicesPersonal = observer(() => {
  let params = useParams();
  let splatParams = params["*"];

  const navigate = useNavigate();
  const rootStore = useContext(RootContext);

  const [storeUI] = useState(() => new StoreUI(navigate, rootStore));

  useEffect(() => {
    (async () => {
      await storeUI.init(params.id ?? "", splatParams ?? "");
    })();
  }, [storeUI, params.id, splatParams]);

  return (
    <div className={styles.servicesPersonal}>
      <Tabs
        bookingId={params.id ?? null}
        activeTabName={splatParams ?? null}
        tabs={storeUI.tabs}
        onClick={storeUI.onClickTab}
        isButtonMode={true}
        className={styles.servicesPersonal__tabs}
      />

      {storeUI.loading.main ? (
        <div className={styles.servicesPersonal__loadingBlock}>
          <Loader className={styles.servicesPersonal__loader} />
        </div>
      ) : (
        <div className={styles.servicesPersonal__cardsContainer}>
          {splatParams === ETabsServices.Selected && (
            <SelectedServices
              services={storeUI.servicesSelected}
              toAvailableServices={storeUI.toAvailableServices}
              openPopupDelete={storeUI.openPopupDelete}
              onClickPay={storeUI.onClickPay}
              paginationData={storeUI.selectedPaginationData}
              onClickSelectDate={(serviceId: number) =>
                storeUI.openServiceSettingsPopup(
                  serviceId,
                  ETabsServices.Selected
                )
              }
              errors={storeUI.errorsSelected}
              isLoading={storeUI.loading.mainSelected}
              isLoadingCreateBill={storeUI.loading.createBill}
              isSuccessCreateBill={
                storeUI.storeBookingServices.isSuccessCreateBillByServices
              }
              toBills={storeUI.toBills}
            />
          )}

          {splatParams === ETabsServices.Available && (
            <AvailableServices
              isLoading={storeUI.loading.mainAvailable}
              services={storeUI.servicesAvailable}
              onClickMore={storeUI.onClickMoreFromAvailable}
              paginationData={storeUI.availablePaginationData}
            />
          )}

          {splatParams === ETabsServices.MyServices && (
            <MyServices
              isLoading={storeUI.loading.mainMyServices}
              services={storeUI.myServices}
              paginationData={storeUI.myServicesPaginationData}
              toAvailableServices={storeUI.toAvailableServices}
            />
          )}
        </div>
      )}

      <PopupService
        isLoading={storeUI.loading.popup}
        visible={storeUI.visiblePopup.detailService}
        img={storeUI.settingsCurrentService.image}
        title={storeUI.settingsCurrentService.title}
        description={storeUI.settingsCurrentService.description}
        price={storeUI.settingsCurrentService.price}
        date={storeUI.settingsCurrentService.date}
        comment={storeUI.settingsCurrentService.comment}
        changeDate={storeUI.changeCurrentDate}
        changeComment={storeUI.changeCurrentComment}
        onClickOrder={storeUI.onClickOrder}
        closeModal={storeUI.closePopupService}
        errorsDate={storeUI.settingsCurrentService.errorsDate}
        errorsCommon={storeUI.serviceCommonErrors}
      />

      <ModalReuse
        isShowModal={storeUI.visiblePopup.delete}
        type={"buttons"}
        btnLeft="Да"
        btnRight="Нет"
        onClick={storeUI.onClickDeleteService}
        setIsShowModal={(isShowModal) =>
          storeUI.setVisiblePopup(EVisiblePopup.Delete, isShowModal)
        }
        isLoading={storeUI.loading.delete}
      >
        <h2>Вы действительно хотите удалить услугу?</h2>
      </ModalReuse>

      <ModalReuse
        isShowModal={storeUI.visiblePopup.successAddService}
        type={"button"}
        btnTitle={storeUI.successAddedDataForPopup.buttonTitle}
        onClickSingleButton={storeUI.successAddedDataForPopup.onClick}
        setIsShowModal={(isShowModal) =>
          storeUI.setVisiblePopup(EVisiblePopup.SuccessAddService, isShowModal)
        }
      >
        <h2>{storeUI.successAddedDataForPopup.title}</h2>
      </ModalReuse>

      <ModalReuse
        isShowModal={storeUI.visiblePopup.billPaymentLater}
        type={"button"}
        btnTitle="Перейти в счета"
        onClickSingleButton={storeUI.toBills}
        setIsShowModal={(isShowModal) =>
          storeUI.setVisiblePopup(EVisiblePopup.BillPaymentLater, isShowModal)
        }
      >
        <h2>Счет будет сформирован позже. Он появится во вкладке счета</h2>
      </ModalReuse>

      <ModalReuse
        isShowModal={storeUI.visiblePopup.parkingInfo}
        type={"button"}
        btnTitle="Хорошо"
        onClickSingleButton={storeUI.closeParkingInfo}
        setIsShowModal={(isShowModal) =>
          storeUI.setVisiblePopup(EVisiblePopup.ParkingInfo, isShowModal)
        }
      >
        <h2>Для аренды парковки свяжитесь с менеджером</h2>
      </ModalReuse>
    </div>
  );
});
