import { makeAutoObservable } from "mobx";

import { openPdf } from "../../../../../../helpers/openPdf";
import { downloadPdf } from "../../../../../../helpers/downloadPdf";

import { PDF_NAME_OTHER } from "../../../../../../constants";

import { IStoreUI } from "./interfaces";

import { Store } from "../../../../../../stores/types";

export class StoreUI implements IStoreUI {
  storeOther: Store.IOtherDocumentsStore;
  storeDocuments: Store.IDocumentsStore;

  isLoading: boolean = false;
  isMainLoading: boolean = false;
  isLoadingDownload: boolean = false;

  currentLoadingId: number | null = null;

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeOther = storeRoot.storeOtherDocuments;
    this.storeDocuments = storeRoot.storeDocuments;
  }

  // getters

  get otherDocuments() {
    return this.storeOther.othersDocuments.map((item) => ({
      id: item.documentId,
      title: item.name,
      date: "00.00.0000", // TODO: поправить когда будет на бэке
    }));
  }

  // functions

  *init(bookingId: number) {
    this.setIsMainLoading(true);
    yield this.storeOther.getOthersDocuments(bookingId);
    this.setIsMainLoading(false);
  }

  *onShowPdf(documentId: number) {
    this.setCurrentLoadingId(documentId);
    let agreement: string = yield this.getAgreementData(documentId);
    this.setCurrentLoadingId(null);
    openPdf(agreement);
  }

  *onDownloadPdf(documentId: number) {
    this.setCurrentLoadingId(documentId);
    let documentOther: string = yield this.getAgreementData(documentId);

    if (documentOther.length) {
      downloadPdf(documentOther, PDF_NAME_OTHER);
    }
    this.setCurrentLoadingId(null);
  }

  *getAgreementData(id: number) {
    yield this.storeDocuments.getDocumentPdf(id);

    if (this.storeDocuments?.documentBase64.length) {
      return this.storeDocuments?.documentBase64;
    }
    return "";
  }

  // setters

  setIsMainLoading(value: boolean) {
    this.isMainLoading = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setCurrentLoadingId(value: number | null) {
    this.currentLoadingId = value;
  }
}
