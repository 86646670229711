import { FC, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { Filters } from "./Filters";
import { Facilities } from "../../components/Modals/Facilities";
import { Flat } from "../../components/Flat";
import { Footer } from "../../components/Footer";
import { FiltersBtn } from "./Filters/FiltersBtn";
import { Sorting } from "./Sorting";
import Pagination from "../../components/Pagintaion";
import { Loader } from "../../components/Loader";
import { ModalReuse } from "../../components/Modals/ModalReuse";

import { RootContext } from "../../stores/Root";

import {
  DESCRIPTION_PAGE_DEFAULT,
  FLOORS_SELECT,
  PAGE_TITLE_APARTMENTS,
} from "../../constants";

import { StoreUI } from "./storeUI/catalog";
import { StoreOptions } from "./storeUI/filters";

import { EPageType } from "../../types";

import styles from "./styles/apartments.module.scss";

interface IProps {
  openFeedback(): void;
  closeSaveModal(): void;
  isModalSave: boolean;
}

export const Apartments: FC<IProps> = observer(
  ({
    openFeedback = () => {},
    closeSaveModal = () => {},
    isModalSave = false,
  }: IProps) => {
    const rootStore = useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(rootStore));
    const [storeOptions] = useState(
      () => new StoreOptions(rootStore.storeFilters)
    );

    useEffect(() => {
      (async () => {
        await storeUI.init();
        await storeOptions.init();
      })();
    }, [storeUI, storeOptions]);

    let descriptionPage = useMemo(() => {
      return rootStore.metaDescriptionsList.find(
        (item) => item.page === EPageType.Apartments
      );
    }, [rootStore.metaDescriptionsList]);

    let helmet = useMemo(
      () => (
        <Helmet>
          <title>{descriptionPage?.title ?? PAGE_TITLE_APARTMENTS}</title>
          <meta
            name="description"
            content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
          />
        </Helmet>
      ),
      [descriptionPage]
    );

    return (
      <div className={styles.apartments}>
        {helmet}
        <div className={styles.apartments__content}>
          <div className={styles.apartments__wrapper}>
            <Sorting
              changeSort={storeUI.changeSorting}
              sortList={storeUI.sortList}
              sortSelected={storeUI.sortSelected}
              className={styles.apartments__sorting}
            />
            <div className={styles.apartments__filtersBtn}>
              <FiltersBtn title="Фильтры" onClick={storeUI.openPopupFilters} />
            </div>
            <Filters
              className={styles.apartments__filters}
              openModalFilters={storeUI.openPopupFilters}
              count={storeUI.countFilters}
              submit={storeUI.getApartaments}
              fromPrice={storeUI.fromPrice}
              changeFromPrice={storeUI.changeFromPrice}
              toPrice={storeUI.toPrice}
              changeToPrice={storeUI.changeToPrice}
              floorOptions={FLOORS_SELECT}
              flatsOptions={storeOptions.rooms}
              floor={storeUI.floor}
              countOfRooms={storeUI.rooms}
              onChangeFloor={storeUI.changeFloor}
              onChangeCount={storeUI.changeRooms}
              sortSelected={storeUI.sortSelected}
              sortList={storeUI.sortList}
              changeSort={storeUI.changeSorting}
              countApartaments={storeUI.countApartaments}
              getApartments={storeUI.getApartamentsForCounter}
              onClearAll={storeUI.clearAllFilters}
              errorsPrices={storeUI.errorsPrices}
              isCheckedAvailable={storeUI.isCheckedAvailable}
              onChangeCheckedAvailable={storeUI.changeIsCheckedAvailable}
            />
            <Facilities
              modal={storeUI.isVisiblePopupFilters}
              closeModal={storeUI.onSaveFacilities}
              changeTerm={storeUI.toggleSelectTerms}
              changeTermAdaptive={storeUI.toggleSelectTermsAdaptive}
              changeFacilities={storeUI.toggleSelectFacilities}
              changeFacilitiesAdaptive={storeUI.toggleSelectFacilitiesAdaptive}
              floorOptions={FLOORS_SELECT}
              flatsOptions={storeOptions.rooms}
              submit={storeUI.getApartaments}
              fromPrice={storeUI.fromPrice}
              changeFromPrice={storeUI.changeFromPrice}
              toPrice={storeUI.toPrice}
              changeToPrice={storeUI.changeToPrice}
              floor={storeUI.floor}
              countOfRooms={storeUI.rooms}
              onChangeFloor={storeUI.changeFloor}
              onChangeCount={storeUI.changeRooms}
              countApartaments={storeUI.countApartaments}
              onClearAll={storeUI.clearAllFilters}
              facilities={storeUI.facilities}
              terms={storeUI.terms}
              onSave={storeUI.onSaveFacilities}
              errorsPrices={storeUI.errorsPrices}
              clearTermsFacilities={storeUI.clearTermsFacilities}
              isCheckedAvailable={storeUI.isCheckedAvailable}
              onChangeCheckedAvailable={storeUI.changeIsCheckedAvailable}
            />
            <div className={styles.apartments__flats}>
              {storeUI.isLoading ? (
                <div className={styles.apartments__flatsEmpty}>
                  <Loader />
                </div>
              ) : !storeUI.apartments || storeUI.apartments?.length === 0 ? (
                <div className={styles.apartments__empty}>
                  Ничего не найдено
                </div>
              ) : (
                <>
                  {storeUI.apartments?.map((flat, index) => (
                    <Flat
                      id={flat.id}
                      image={flat.image}
                      square={flat.square}
                      minPeople={flat.minPeople}
                      maxPeople={flat.maxPeople}
                      pets={flat.pets}
                      flatNumber={flat.flatNumber}
                      floor={flat.floor}
                      className={styles.apartments__flat}
                      key={index}
                      closeModal={storeUI.closePopupText}
                      modal={storeUI.isVisiblePopupText}
                      openFeedback={openFeedback}
                      price={flat.price}
                      onClickFlatCallback={storeUI.writeFiltersToLocalStorage}
                    />
                  ))}
                </>
              )}
            </div>
            <Pagination
              className={styles.apartments__pagination}
              currentPage={storeUI.paginateData.page}
              totalCount={
                storeUI.isSubmit
                  ? storeUI.paginateData.total
                  : storeUI.prevTotal
              }
              onPageChange={storeUI.getApartaments}
              pageSize={storeUI.paginateData.limit}
              siblingCount={1}
            />
          </div>

          <Footer className={styles.apartments__footer} />
        </div>
        <ModalReuse
          isShowModal={isModalSave}
          setIsShowModal={closeSaveModal}
          btnTitle="Хорошо"
          type="button"
        >
          <h2 className={styles.apartments__modalSave}>
            Ваша заявка отправлена. Мы дадим ответ в ближайшее время
          </h2>
        </ModalReuse>
      </div>
    );
  }
);
