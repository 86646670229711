import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { SCREENS } from "../../../navigation/endpoints";

import { enableScroll } from "../../../helpers/modalsFunc";
import { openPdf } from "./../../../helpers/openPdf";

import {
  ERROR_PAYMENT_METHODS,
  RENTER_COUNT,
  HEADER_PDF,
  PAGE_DOCUMENTS_BY_ID,
  PAGE_RENT_BY_ID,
} from "../../../constants";

import { ModalsStoreUI } from "./modals";
import { RenterStoreUI } from "./renter";
import { TenantsStoreUI } from "./tenants";
import { IApartmentItem } from "../../../interfaces/Account";
import { PetsStoreUI } from "./pets";

import { Store } from "../../../stores/types";
import { EBookingType, EStepOrder, EPaymentMethods } from "../../../types";
import { ETabsDocuments } from "./../../../types/index";

import { IStoreUI } from "./interfaces";
import { ArrivalDateStoreUI } from "./arrivalDate";

export class StoreUI implements IStoreUI {
  // stores
  storeBooking: Store.IBooking;
  storeAuthentication: Store.IStoreAuthentication;
  storeAdditionalBookingData: Store.IAdditionalBookingData;
  storeMyApartment: Store.IMyApartment;
  storeRoot: Store.IRootStore;

  storeModals: Store.IModalsOrderingData;
  storeRenter: Store.IRenterOrderingData;
  storeTenants: Store.ITenantsOrderingData;
  storePets: Store.IPetsOrderingData;
  storeArrivalDate: Store.IArrivalDateOrderingData;

  step: EStepOrder = EStepOrder.Renter;

  navigate: NavigateFunction;

  isLoading: boolean = false;
  isLoadingAgreement: boolean = false;
  isLoadingRenter: boolean = false;
  isLoadingBooking: boolean = false;
  isDisabledAllForm: boolean = false;

  // метод оплаты
  method: EPaymentMethods | null = null;
  errorsMethod: string[] = [];

  isSuccessPayment: boolean = false;
  isFilledProfile: boolean = false;

  validationErrorsTenants: string[] = [];

  bookingId: string | null = null;

  constructor(
    storeAuthentication: Store.IStoreAuthentication,
    storeRoot: Store.IRootStore,
    navigate: NavigateFunction
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeAuthentication = storeAuthentication;
    this.storeRoot = storeRoot;
    this.storeBooking = storeRoot.storeBooking;
    this.storeAdditionalBookingData = storeRoot.storeAdditionalBookingData;
    this.storeMyApartment = storeRoot.storeMyApartment;
    this.navigate = navigate;

    this.storeModals = new ModalsStoreUI(this);
    this.storeRenter = new RenterStoreUI(
      this.storeRoot,
      this.storeAuthentication,
      this.navigate,
      this.toStepTenants
    );
    this.storeTenants = new TenantsStoreUI(this);
    this.storePets = new PetsStoreUI(this);
    this.storeArrivalDate = new ArrivalDateStoreUI(this);
  }

  // getters
  get apartmentDetail() {
    return this.storeMyApartment.apartmentDetail?.bookingCalendar || null;
  }

  get canPets() {
    // имеет ли квартира возможность заселения с животными
    return this.storeRenter.apartmentInfo.canPets ?? false;
  }

  get stepTitle() {
    return {
      renter: this.getStepTitle(EStepOrder.Renter),
      tenants: this.getStepTitle(EStepOrder.Tenants),
      pets: this.getStepTitle(EStepOrder.Pets),
      arrivalDate: this.getStepTitle(EStepOrder.ArrivalDate),
    };
  }

  get dataAgreement() {
    if (this.storeBooking.isSuccessCreatePdf) {
      return HEADER_PDF + this.storeBooking.filePdfBase64;
    }
    return "";
  }

  get errorsBooking() {
    return this.storeBooking.errorsBooking;
  }

  get personalType() {
    return this.storeRoot.userProfile.personalType;
  }

  // functions

  *init(bookingId: string) {
    enableScroll();
    this.setIsLoading(true);
    enableScroll();
    // для проверки booking id
    yield this.storeRoot.getBookingsList(EBookingType.Active);

    if (
      bookingId &&
      this.storeRoot.checkBookingIdByUrl(bookingId, EBookingType.Active)
    ) {
      this.setBookingId(bookingId);

      yield this.storeMyApartment.getBookingById(Number(bookingId));

      if (this.storeMyApartment.apartmentDetail?.bookingCalendar.arrivalDate) {
        this.navigate(SCREENS.SCREEN_404);
      }

      yield this.storeRoot.storeBookingResidents.getResidents(
        Number(bookingId)
      );

      // запись данных о проживающих
      if (this.storeRoot.storeBookingResidents.pets.length) {
        this.storePets.fillFormsPrevEnteredData(
          this.storeRoot.storeBookingResidents.pets
        );
      }

      if (this.storeRoot.storeBookingResidents.tenants.length) {
        this.storeRenter.fillFormsPrevEnteredData(
          this.storeRoot.storeBookingResidents.tenants
        );

        this.storeTenants.fillFormsPrevEnteredData(
          this.storeRoot.storeBookingResidents.tenants
        );
      }

      if (this.storeMyApartment.apartmentDetail?.bookingCalendar) {
        // запись данных о квартире для сторов
        this.writeBookingDataToStores(
          this.storeMyApartment.apartmentDetail?.bookingCalendar
        );
      }

      // инициализация сторов
      this.storeRenter.init(bookingId);
      this.storeTenants.init();
      this.storePets.init();
      this.storeArrivalDate.init();
    } else {
      this.navigate(SCREENS.SCREEN_404);
    }
    this.setIsLoading(false);
  }

  *createAgreement() {
    this.setIsLoadingAgreement(true);
    if (this.bookingId) {
      yield this.storeBooking.getBookingPdf(Number(this.bookingId));

      if (this.storeBooking.isSuccessCreatePdf) {
        this.storeModals.openAgreementModal();
      }
    }

    this.setIsLoadingAgreement(false);
  }

  writeBookingDataToStores(bookingData: IApartmentItem) {
    this.storeRenter.writeApartmentInfo(bookingData);

    this.storeTenants.writeCounts({
      capacityTenants: bookingData.capacity - RENTER_COUNT,
      countAdults: bookingData.countAdults - RENTER_COUNT,
      countChildren: bookingData.countChildren,
    });
  }

  onConfirmAgreement() {
    this.storeModals.validateIsAgree();

    if (
      this.storeBooking.isSuccessCreatePdf &&
      this.storeModals.validateIsAgree()
    ) {
      this.toStepArrivalDate();
      this.storeModals.closeAgreementModal();
      this.clearAgreementModal();
    }
  }

  *onContinueDeposit() {
    // TODO: доработать когда будет в разработке Оплата
    /*  this.storeModals.closeDepositModal();

    if (this.bookingId) {
      yield this.storeAdditionalBookingData.getArrivalDate(this.bookingId);

      if (this.storeAdditionalBookingData.arrivalDate.length) {
        this.storeModals.writeArrivalDatePeriod(
          this.storeAdditionalBookingData.arrivalDate
        );
      }
    } */
  }

  *fillFormsPrevEnteredData(bookingId: number) {
    yield this.storeRoot.storeBookingResidents.getResidents(bookingId);
  }

  // ---- конвертеры данных в другой формат ----------

  getStepTitle(typeStep: EStepOrder) {
    if (this.canPets) {
      switch (typeStep) {
        case EStepOrder.Renter:
          return "Шаг 1 из 4";
        case EStepOrder.Tenants:
          return "Шаг 2 из 4";
        case EStepOrder.Pets:
          return "Шаг 3 из 4";
        case EStepOrder.ArrivalDate:
          return "Шаг 4 из 4";
        default:
          return "Шаг 0 из 0";
      }
    } else {
      switch (typeStep) {
        case EStepOrder.Renter:
          return "Шаг 1 из 3";
        case EStepOrder.Tenants:
          return "Шаг 2 из 3";
        case EStepOrder.ArrivalDate:
          return "Шаг 3 из 3";
        default:
          return "Шаг 0 из 0";
      }
    }
  }

  // валидация для попапа выбора метода оплаты
  validateMethodsPayment() {
    if (!this.method) {
      this.addErrorMethod(ERROR_PAYMENT_METHODS);
      return false;
    } else {
      this.errorsMethod = [];
      return true;
    }
  }

  addErrorMethod(value: string) {
    this.errorsMethod = [...this.errorsMethod, value];
  }

  clearAgreementModal() {
    this.storeBooking.clearErrorsBooking();
    this.storeModals.errorsIsAgree = [];
  }

  openAgreementPdf() {
    if (this.storeBooking.filePdfBase64) {
      openPdf(this.storeBooking.filePdfBase64);
    }
  }

  // навигация

  toBills() {
    if (this.storeBooking.bookingId) {
      this.navigate(
        PAGE_DOCUMENTS_BY_ID(
          String(this.storeBooking.bookingId),
          ETabsDocuments.Bills
        )
      );
    }
  }

  toProfile() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_PERSONAL);
    enableScroll();
  }

  toMyApartment() {
    if (this.bookingId) {
      this.navigate(PAGE_RENT_BY_ID(this.bookingId));
    }
  }

  toMyAgreements() {
    if (this.bookingId) {
      this.navigate(
        PAGE_DOCUMENTS_BY_ID(this.bookingId, ETabsDocuments.Agreements)
      );
    }
  }

  toMyBookings() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_MY_BOOKINGS);
  }

  toStepRenter() {
    this.setStep(EStepOrder.Renter);
  }

  toStepTenants() {
    this.setStep(EStepOrder.Tenants);
  }

  toStepPets() {
    this.setStep(EStepOrder.Pets);
  }

  toStepArrivalDate() {
    this.setStep(EStepOrder.ArrivalDate);
  }

  onBackArrivalDate() {
    this.canPets ? this.toStepPets() : this.toStepTenants();
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setMethod(value: EPaymentMethods) {
    this.method = value;
  }
  setIsSuccessPayment(value: boolean) {
    this.isSuccessPayment = value;
  }
  setStep(value: EStepOrder) {
    this.step = value;
  }

  setIsLoadingAgreement(value: boolean) {
    this.isLoadingAgreement = value;
  }

  setIsLoadingRenter(value: boolean) {
    this.isLoadingRenter = value;
  }
  setIsLoadingBooking(value: boolean) {
    this.isLoadingBooking = value;
  }

  setBookingId(value: string | null) {
    this.bookingId = value;
  }
}
