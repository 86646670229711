import { FC } from "react";

import styles from "./styles/partner.module.scss";

interface IProps {
  title: string;
  description: string;
  subtitle: string;
  url: string;
  maxDescriptionLength?: number | null;
}

export const Partner: FC<IProps> = ({
  title = "",
  description = "",
  url = "",
  maxDescriptionLength = null,
  subtitle = "",
}) => {
  return (
    <div className={styles.partnerItem}>
      <div className={styles.partnerItem__content}>
        <h4 className={styles.partnerItem__title}>{title}</h4>
        {Boolean(subtitle) && (
          <h4 className={styles.partnerItem__subtitle}>{subtitle}</h4>
        )}
        <p className={styles.partnerItem__description}>
          {maxDescriptionLength
            ? description.slice(0, maxDescriptionLength)
            : description}
        </p>
      </div>
      <a
        className={styles.partnerItem__link}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        Перейти на сайт
      </a>
    </div>
  );
};
