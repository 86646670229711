import { makeAutoObservable } from "mobx";

import { getSocials } from "../../../api/MainPage";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { IMainSocials } from "../../../interfaces/MainPage";

class ModelSocials implements Store.ISocials {
  socials: IMainSocials[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *getSocials() {
    let response: TResponseApi<IMainSocials[]> = yield getSocials();
    if (response.isSuccess && response.data !== null) {
      this.setSocials(response.data);
    }
  }

  setSocials(socials: IMainSocials[]) {
    this.socials = socials;
  }
}

export const StoreSocials = new ModelSocials();
