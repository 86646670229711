import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { ITab } from "../../../../../../interfaces";
import { IStoreUI } from "./interfaces";

import { Store } from "../../../../../../stores/types";
import { EBookingType, ETabsDocuments } from "../../../../../../types";

import {
  ACTS,
  AGREEMENTS,
  BILLS,
  OTHERS,
  PAGE_ARCHIVE_DOCS_TAB_BY_ID,
} from "../../../../../../constants";
import { SCREENS } from "../../../../../../navigation/endpoints";

export class StoreUI implements IStoreUI {
  navigate: NavigateFunction;
  storeRoot: Store.IRootStore;
  isModalReview: boolean = false;
  isLoading: boolean = true;
  rating: number = 0;
  apartmentId: number = 0;
  isDeleteModal: boolean = false;
  bookingId: number = 0;
  hoverRating: undefined | number = undefined;
  stars: number[] = Array(5).fill(0);
  text: string = "";

  isLoadingDelete: boolean = false;

  tabs: ITab[] = [
    {
      id: 1,
      label: "Договора",
      tabName: ETabsDocuments.Agreements,
    },
    {
      id: 2,
      label: "Счета",
      tabName: ETabsDocuments.Bills,
    },
    {
      id: 3,
      label: "Акты",
      tabName: ETabsDocuments.Acts,
    },
    {
      id: 4,
      label: "Другое",
      tabName: ETabsDocuments.Others,
    },
  ];

  constructor(storeRoot: Store.IRootStore, navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
    this.navigate = navigate;
  }

  // functions

  *init(archiveId: string) {
    this.setIsLoading(true);
    yield this.storeRoot.getBookingsList(EBookingType.Archive);

    if (
      (archiveId &&
        !this.storeRoot.checkBookingIdByUrl(archiveId, EBookingType.Archive)) ||
      !archiveId
    ) {
      this.navigate(SCREENS.SCREEN_404);
    }

    this.setIsLoading(false);
  }

  onClickTab(id: string, tabName: string) {
    switch (tabName) {
      case AGREEMENTS:
        this.navigate(
          PAGE_ARCHIVE_DOCS_TAB_BY_ID(id, ETabsDocuments.Agreements)
        );
        break;

      case BILLS:
        this.navigate(PAGE_ARCHIVE_DOCS_TAB_BY_ID(id, ETabsDocuments.Bills));
        break;

      case ACTS:
        this.navigate(PAGE_ARCHIVE_DOCS_TAB_BY_ID(id, ETabsDocuments.Acts));
        break;

      case OTHERS:
        this.navigate(PAGE_ARCHIVE_DOCS_TAB_BY_ID(id, ETabsDocuments.Others));
        break;

      default:
        this.navigate(
          PAGE_ARCHIVE_DOCS_TAB_BY_ID(id, ETabsDocuments.Agreements)
        );
    }
  }

  //setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
