import { observer } from "mobx-react-lite";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BackTo } from "../../../../../components/BackTo";
import { Tabs } from "../../../../../components/Tabs";
import { Acts } from "../../Documents/Acts";
import { Agreements } from "../../Documents/Agreements";
import { Bills } from "../../Documents/Bills";
import { DocumentOthers } from "../../Documents/DocumentOthers";

import { SCREENS } from "../../../../../navigation/endpoints";

import { ETabsDocuments } from "../../../../../types";
import { RootContext } from "../../../../../stores/Root";

import { StoreUI } from "./storeUI";

import styles from "./styles/archiveDocuments.module.scss";

export const ArchiveDocuments = observer(() => {
  const rootStore = useContext(RootContext);
  const navigate = useNavigate();
  const params = useParams();

  const [storeUI] = useState(() => new StoreUI(rootStore, navigate));

  useEffect(() => {
    (async () => {
      await storeUI.init(params.id ?? "");
    })();
  }, [storeUI, params.id]);

  const toArchive = () => {
    navigate(SCREENS.SCREEN_ACCOUNT_ARCHIVE);
  };

  let activeTabPage = useMemo(() => {
    switch (params.tabName) {
      case ETabsDocuments.Agreements:
        return (
          <Agreements bookingId={Number(params.id)} isArchiveMode={true} />
        );
      case ETabsDocuments.Bills:
        return <Bills bookingId={Number(params.id)} isArchiveMode={true} />;
      case ETabsDocuments.Acts:
        return <Acts bookingId={Number(params.id)} isArchiveMode={true} />;
      case ETabsDocuments.Others:
        return (
          <DocumentOthers bookingId={Number(params.id)} isArchiveMode={true} />
        );
      default:
        return "";
    }
  }, [params.tabName, params.id]);

  return (
    <div className={styles.archiveDocuments}>
      <BackTo text={"Вернуться к архиву"} onClick={toArchive} />

      <div className={styles.archiveDocuments__tabsContainer}>
        <Tabs
          bookingId={params.id ?? ""}
          activeTabName={params.tabName ?? ETabsDocuments.Agreements}
          tabs={storeUI.tabs}
          onClick={storeUI.onClickTab}
          className={styles.archiveDocuments__tabs}
        />
      </div>

      {activeTabPage}
    </div>
  );
});
