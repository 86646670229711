import { makeAutoObservable } from "mobx";
import { IPageDescription } from "../../../interfaces/PageDescription";
import { getPagesDescription } from "../../../api/PageDescription";
import { TResponseApi } from "../../../api/types";

// стор для получения редактируемых метаданных из админки

export class ModelMetaData {
  descriptionsList: IPageDescription[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // functions

  *getPagesDescription() {
    let response: TResponseApi<IPageDescription[]> =
      yield getPagesDescription();

    if (response.isSuccess && response.data !== null) {
      this.setDescriptionsList(response.data);
    }
  }

  // setters

  setDescriptionsList(value: IPageDescription[]) {
    this.descriptionsList = value;
  }
}
