import { observer } from "mobx-react-lite";
import { useState } from "react";

import { InputDate } from "../../InputDate";
import { Button } from "../../Button";
import { Loader } from "../../Loader";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/popupActRevise.module.scss";

interface IProps {
  isLoading?: boolean;
  visible: boolean;
  dateFrom: string;
  errorsDateFrom: string[];
  changeDateFrom(value: string): void;
  onContinue(): void;
  closeModal(): void;
}

export const PopupActRevise = observer(
  ({
    isLoading = false,
    visible = false,
    dateFrom = "",
    errorsDateFrom = [],
    changeDateFrom = () => {},
    onContinue = () => {},
    closeModal = () => {},
  }: IProps) => {
    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        closeModal();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };
    return (
      <>
        {visible && (
          <div
            className={styles.popupActRevise}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.popupActRevise__popupWrapper}>
              {isLoading && (
                <Loader className={styles.popupActRevise__loader} />
              )}
              <div
                className={styles.popupActRevise__popup}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                <Close
                  className={styles.popupActRevise__close}
                  onClick={closeModal}
                />

                <h2 className={styles.popupActRevise__title}>
                  Запросить акт сверки
                </h2>

                <InputDate
                  label="дата от"
                  value={dateFrom}
                  errors={errorsDateFrom}
                  placeholder="01.01.2023"
                  onChange={changeDateFrom}
                  className={`${styles.popupActRevise__inputDate} ${styles.popupActRevise__inputDate_dateFrom}`}
                  classNameInput={styles.popupActRevise__input}
                  readonly={isLoading}
                />

                <div className={styles.popupActRevise__buttonsWrapper}>
                  <button
                    className={styles.popupActRevise__buttonCancel}
                    onClick={closeModal}
                  >
                    Отмена
                  </button>
                  <Button
                    className={styles.popupActRevise__button}
                    title="Продолжить"
                    theme="gold"
                    onClick={onContinue}
                    disabled={isLoading}
                    isDisabledStyle={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
