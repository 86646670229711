import { makeAutoObservable } from "mobx";

import { Validator } from "validator-pyrobyte";
import { newRules } from "../../../helpers/validation";

import { Store } from "../../types";
import { IAuthorizationUI } from "./interfaces";
import { EStepAuth } from "../../../types/StoreAuthentication";

import { ERROR_TEXT_EMPTY, ERROR_TEXT_PHONE } from "../../../constants";

import { ModelAuthorization } from "../../models/Authetication/authorization";

import { enableScroll } from "../../../helpers/modalsFunc";

export type TErros = {
  phone: string[];
  password: string[];
};

export class StoreAuthorization implements IAuthorizationUI {
  storeAuthorization: Store.IAuthorization;
  storeAuthentication: Store.IStoreAuthentication;
  validator: Validator;

  phone: string = "";
  password: string = "";

  isLoading: boolean = false;

  errors: TErros = {
    phone: [],
    password: [],
  };

  validationErrors: TErros = {
    phone: [],
    password: [],
  };

  withInfoIcon: boolean = true;

  constructor(storeAuthentication: Store.IStoreAuthentication) {
    this.storeAuthorization = new ModelAuthorization();
    this.storeAuthentication = storeAuthentication;

    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.validator = new Validator(
      {
        password: {
          password: true,
          required: true,
        },

        specialChars: {
          specialChars: true,
        },
      },
      newRules
    );
  }

  //getters

  get errorsPhone() {
    return this.errors.phone.concat(
      this.validationErrors.phone,
      this.storeAuthorization.serverErrors
    );
  }

  get errorsPassword() {
    if (
      this.storeAuthorization.serverErrors.length &&
      !this.validationErrors.password.length
    ) {
      this.setWithInfoIcon(false);
    } else {
      this.setWithInfoIcon(true);
    }
    return this.errors.password.concat(
      this.validationErrors.password,
      this.storeAuthorization.serverErrors
    );
  }

  //functions

  // получение данных с сервера

  *onSend() {
    this.validatePhone();
    this.validatePassword();

    if (this.validatePhone() && this.validatePassword()) {
      this.setIsLoading(true);

      let isAuth: boolean = yield this.storeAuthorization.sendAuthorization(
        this.phone,
        this.password
      );

      if (isAuth) {
        this.storeAuthentication.closeAuthModal();
        this.storeAuthentication.setIsAuth(isAuth);
        this.clearValues();
        enableScroll();
      }
      this.setIsLoading(false);
    }
  }

  *logout() {
    let isLogout: boolean = yield this.storeAuthorization.logout();
    return isLogout;
  }

  // валидация

  validatePhone() {
    if (this.phone.length === 0) {
      this.addValidationErrorsPhone(ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.validationErrors.phone = [];
    }

    if (this.phone.replaceAll(" ", "").length < 12) {
      this.addValidationErrorsPhone(ERROR_TEXT_PHONE);
      return false;
    } else {
      this.validationErrors.phone = [];
    }
    return true;
  }

  validatePassword() {
    let validPassword = this.validator.check("password", this.password);

    if (this.password.length === 0) {
      this.addValidationErrorsPassword(ERROR_TEXT_EMPTY);
    } else {
      this.validationErrors.password = [];
    }

    if (!validPassword.passed) {
      this.addValidationErrorsPassword(validPassword.errors[0]);
    } else {
      this.validationErrors.password = [];
    }
    return this.validationErrors.password.length === 0;
  }

  toPasswordRecovery() {
    this.storeAuthentication.setStepAuth(EStepAuth.PasswordRecovery);
    this.clearValues();
  }

  toRegister() {
    this.storeAuthentication.setStepAuth(EStepAuth.Registration);
    this.clearValues();
  }

  // добавление ошибок

  addValidationErrorsPassword(value: string) {
    this.validationErrors.password = [...this.validationErrors.password, value];
  }

  addValidationErrorsPhone(value: string) {
    this.validationErrors.phone = [...this.validationErrors.phone, value];
  }

  changePhone(value: string) {
    this.setPhone(value);

    if (this.validationErrors.phone.length) {
      this.validationErrors.phone = [];
    }
  }

  changePassword(value: string) {
    this.setPassword(value);

    if (this.validationErrors.password.length) {
      this.validationErrors.password = [];
    }
  }

  // сеттеры

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setPassword(value: string) {
    this.password = value;
  }

  setWithInfoIcon(value: boolean) {
    this.withInfoIcon = value;
  }

  // очистка формы

  clearValues() {
    this.setPhone("");
    this.setPassword("");
    this.errors.phone = [];
    this.errors.password = [];
    this.validationErrors.phone = [];
    this.validationErrors.password = [];
    this.storeAuthorization.clearErrors();
  }
}
