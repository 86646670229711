import { makeAutoObservable } from "mobx";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

import {
  checkIsAuth,
  createPayment,
  getPaymentExists,
  loginSberBusiness,
  registerSberBusiness,
  updatePayment,
} from "../../../api/Payment";

import { TResponseApi } from "../../../api/types";
import { EPaymentMethods } from "../../../types";
import { Store } from "../../types";

import {
  IExistsPaymentResponse,
  ILoginResponse,
  IPaymentCreateResponse,
  IPaymentUpdateResponse,
} from "../../../interfaces/Payment";

export class ModelPayment implements Store.IPayment {
  paymentId: number | null = null;
  paymentCreateErrors: string[] = [];

  bookingId: number | null = null;
  filePdfBase64: string = "";

  isSuccessPayment: boolean = false;
  isSuccessCreatePdf: boolean = false;
  isAuth: boolean = false;
  paymentResult: boolean = false;
  paymentStatus: string = "";
  paymentStrategy: string = "";

  isPaymentExist: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions
  *createPayment(paymentMethod: EPaymentMethods, billId: number) {
    let response: TResponseApi<IPaymentCreateResponse> = yield createPayment({
      strategy: paymentMethod,
      billId: billId,
    });

    if (response.isSuccess && response.data !== null) {
      this.setPaymentResult(response.data.result);
      window.open(response.data?.url, "_self");
    }

    if (response.isError) {
      this.addErrorPaymentCreate(
        response.error?.message
          ? response.error.message
          : ERROR_TEXT_SERVER_COMMON
      );
    } else {
      this.paymentCreateErrors = [];
    }

    this.setIsSuccessPayment(response.isSuccess);
  }

  *updatePayment(paymentId: number) {
    let response: TResponseApi<IPaymentUpdateResponse> = yield updatePayment(
      paymentId
    );

    if (response.isSuccess && response.data !== null) {
      this.setPaymentResult(response.data.result);
      this.setPaymentStatus(response.data.status);
      this.setPaymentStrategy(response.data.strategy);
      return true;
    } else {
      return false;
    }
  }

  *getExistPayment(billId: number) {
    let response: TResponseApi<IExistsPaymentResponse> = yield getPaymentExists(
      billId
    );

    if (response.isSuccess && response.data !== null) {
      this.setIsPaymentExist(response.data.result);
    }
  }

  *checkIsAuth() {
    let response: TResponseApi<IPaymentUpdateResponse> = yield checkIsAuth();

    if (response.data?.result) {
      this.setIsAuth(response.data?.result);
    }
  }

  *loginSberBusiness() {
    let response: TResponseApi<ILoginResponse> = yield loginSberBusiness();

    if (response.data?.result) {
      window.open(response.data?.result, "_self");
    }
  }

  *registerSberBusiness(code: string, state: string, nonce: string) {
    let response: TResponseApi<IPaymentUpdateResponse> =
      yield registerSberBusiness({ code, state, nonce });

    if (response.data?.result) {
      checkIsAuth();
      return true;
    } else {
      return false;
    }
  }

  addErrorPaymentCreate(value: string) {
    this.paymentCreateErrors = [...this.paymentCreateErrors, value];
  }

  clearPaymentCreateErrors() {
    this.paymentCreateErrors = [];
  }

  // setters
  setPaymentStatus(value: string) {
    this.paymentStatus = value;
  }

  setPaymentResult(value: boolean) {
    this.paymentResult = value;
  }

  setPaymentStrategy(value: string) {
    this.paymentStrategy = value;
  }

  setIsSuccessPayment(value: boolean) {
    this.isSuccessPayment = value;
  }

  setIsAuth(value: boolean) {
    this.isAuth = value;
  }

  setBookingId(value: number | null) {
    this.bookingId = value;
  }

  setFilePdfBase64(value: string) {
    this.filePdfBase64 = value;
  }

  setIsSuccessCreatePdf(value: boolean) {
    this.isSuccessCreatePdf = value;
  }

  setIsPaymentExist(value: boolean) {
    this.isPaymentExist = value;
  }
}
