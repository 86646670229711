import { FC, useState } from "react";
import QRCode from "react-qr-code";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";
import { WELCODOM_SITE } from "../../../constants";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/mobileVersion.module.scss";

interface IProps {
  modal: boolean;
  closeModal: (modal: boolean) => void;
}

export const MobileVersion: FC<IProps> = ({
  modal = false,
  closeModal = () => {},
}) => {
  let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
    useState<EventTarget | null>(null);

  const _closeModal = () => {
    closeModal(false);
    enableScroll();
  };

  const _closeModalBackground = () => {
    if (backgroundOnDownTarget) {
      _closeModal();
    }

    setBackgroundOnDownTarget(null);
  };

  return modal ? (
    <div
      className={styles.mobile}
      onClick={_closeModalBackground}
      onMouseDown={(e) => {
        setBackgroundOnDownTarget(e.target);
      }}
    >
      <div className={styles.mobile__popupWrapper}>
        <div
          className={styles.mobile__popup}
          onClick={stopPropagation}
          onMouseDown={stopPropagation}
        >
          <Close className={styles.mobile__close} onClick={_closeModal} />
          <div className={styles.mobile__content}>
            <div className={styles.qrCode}>
              <QRCode
                className={styles.qrCode__code}
                value={WELCODOM_SITE}
                viewBox={`0 0 256 256`}
              />
              <h2 className={styles.qrCode__text}>Мобильная версия сайта</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
