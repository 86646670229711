import { ApiManager } from "..";
import { PARTNER } from "../../constants/api";

import { IPartnerItem, IPartnerRequest } from "../../interfaces/Partner";

import { TResponseApi } from "../types";

export async function getPartners(): Promise<TResponseApi<IPartnerItem[]>> {
  return await ApiManager.request<IPartnerItem[]>(
    {
      url: PARTNER,
      method: "GET",
    },
    true
  );
}

export async function sendPartnerRequest(
  params: IPartnerRequest
): Promise<TResponseApi<IPartnerRequest>> {
  return await ApiManager.request<IPartnerRequest>(
    {
      url: PARTNER,
      data: params,
      method: "POST",
    },
    true
  );
}
