import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser";

import { Floor } from "./Floor";
import { RoundBtn } from "./RoundBtn";
import { Description } from "./Description";
import { Button } from "../../../components/Button";
import { RevealAnimation } from "../RevealAnimation";
import { ModalReuse } from "../../../components/Modals/ModalReuse";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../stores/Root";

import building from "../../../assets/images/building.png";
import buildingMobile from "../../../assets/mobile/buildingMobile.png";
import { ReactComponent as Floor1 } from "../../../assets/images/floors/floor1.svg";
import { ReactComponent as Floor2 } from "../../../assets/images/floors/floor2.svg";
import { ReactComponent as Floor3 } from "../../../assets/images/floors/floor3.svg";
import { ReactComponent as Floor4 } from "../../../assets/images/floors/floor4.svg";
import { ReactComponent as Floor5 } from "../../../assets/images/floors/floor5.svg";
import { ReactComponent as Floor6 } from "../../../assets/images/floors/floor6.svg";
import { ReactComponent as Floor7 } from "../../../assets/images/floors/floor7.svg";
import { ReactComponent as Floor8 } from "../../../assets/images/floors/floor8.svg";
import { ReactComponent as Floor9 } from "../../../assets/images/floors/floor9.svg";
import { ReactComponent as Floor10 } from "../../../assets/images/floors/floor10.svg";
import { ReactComponent as Floor11 } from "../../../assets/images/floors/floor11.svg";
import { ReactComponent as Floor12 } from "../../../assets/images/floors/floor12.svg";
import { ReactComponent as Floor13 } from "../../../assets/images/floors/floor13.svg";
import { ReactComponent as Floor14 } from "../../../assets/images/floors/floor14.svg";
import { ReactComponent as Floor15 } from "../../../assets/images/floors/floor15.svg";
import { ReactComponent as Floor16 } from "../../../assets/images/floors/floor16.svg";
import { ReactComponent as Floor17 } from "../../../assets/images/floors/floor17.svg";

import styles from "./styles/main.module.scss";

const floorsSvg = [
  {
    id: 1,
    Img: Floor1,
  },
  {
    id: 2,
    Img: Floor2,
  },
  {
    id: 3,
    Img: Floor3,
  },
  {
    id: 4,
    Img: Floor4,
  },
  {
    id: 5,
    Img: Floor5,
  },
  {
    id: 6,
    Img: Floor6,
  },
  {
    id: 7,
    Img: Floor7,
  },
  {
    id: 8,
    Img: Floor8,
  },
  {
    id: 9,
    Img: Floor9,
  },
  {
    id: 10,
    Img: Floor10,
  },
  {
    id: 11,
    Img: Floor11,
  },
  {
    id: 12,
    Img: Floor12,
  },
  {
    id: 13,
    Img: Floor13,
  },
  {
    id: 14,
    Img: Floor14,
  },
  {
    id: 15,
    Img: Floor15,
  },
  {
    id: 16,
    Img: Floor16,
  },
  {
    id: 17,
    Img: Floor17,
  },
];

interface IProps {
  openFeedback(): void;
  closeSaveModal(): void;
  isModalSave: boolean;
}

export const MainPart: FC<IProps> = observer(
  ({
    openFeedback = () => {},
    closeSaveModal = () => {},
    isModalSave = false,
  }) => {
    const navigate = useNavigate();
    const rootStore = React.useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(rootStore, navigate));

    return (
      <div className={styles.main}>
        <div className={styles.leftColumn}>
          <div className={styles.leftColumn__content}>
            {storeUI.storeRoot.isMainLoading ? (
              <div className={styles.leftColumn__empty} />
            ) : (
              <>
                <div className={styles.leftColumn__textBlock}>
                  <RevealAnimation
                    cascade
                    damping={0.05}
                    className={styles.leftColumn__textBlockAnimate}
                  >
                    {parse(storeUI.content)}
                  </RevealAnimation>
                </div>
                <RevealAnimation
                  delay={600}
                  className={styles.leftColumn__buttonsWrapper}
                >
                  <div className={styles.leftColumn__buttons}>
                    <Button
                      title="Показать свободные квартиры"
                      theme="white"
                      onClick={storeUI.toApartments}
                      className={styles.leftColumn__button}
                    />
                    <button
                      className={styles.leftColumn__request}
                      onClick={openFeedback}
                    >
                      ОСТАВИТЬ ЗАЯВКУ
                    </button>
                  </div>
                </RevealAnimation>
              </>
            )}
          </div>
        </div>
        <div className={styles.rightColumn}>
          <img
            className={styles.rightColumn__buildingMobile}
            src={buildingMobile}
            alt="buildingMobile"
          />
          <div className={styles.rightColumn__buttons}>
            <img
              className={styles.rightColumn__building}
              src={building}
              alt="building"
            />
            {/*    <div className={`${styles.floors} ${styles.floorInfo}`}>
              {floorsSvg
                .map((floor) => (
                  <div
                    className={`${styles[`floor${floor.id}`]} ${styles.floor}`}
                    onMouseEnter={() => storeUI.setShow(floor.id)}
                    onMouseLeave={() => storeUI.setUnShow()}
                    key={floor.id}
          
                    onClick={() => {
                      if (floor.id !== 1) {
                        storeUI.showConcreteFloor(floor.id);
                      }
                    }}
                  >
                    {storeUI.show === floor.id && <floor.Img />}
                  </div>
                ))
                .reverse()}
            </div> */}

            <div className={`${styles.floors} ${styles.floorInfo}`}>
              {floorsSvg
                .map((floor) => (
                  <div
                    className={`${styles[`floor${floor.id}`]} ${styles.floor}`}
                    onMouseEnter={() => storeUI.setShow(floor.id)}
                    onMouseLeave={() => storeUI.setUnShow()}
                    key={floor.id}
                    /* запретить клик по первому этажу */
                    onClick={() => {
                      if (floor.id !== 1) {
                        storeUI.showConcreteFloor(floor.id);
                      }
                    }}
                  >
                    {storeUI.show === floor.id && <floor.Img />}
                  </div>
                ))
                .reverse()}
            </div>
            <div className={styles.floors__floorsDescriptionsWrapper}>
              {floorsSvg
                .map((floor) => (
                  <>
                    {storeUI.show === floor.id && (
                      <Floor
                        floor={floor.id}
                        className={`${styles[`floors__cardInfo${floor.id}`]} ${
                          styles.floors__floorDescription
                        }`}
                      />
                    )}
                  </>
                ))
                .reverse()}
            </div>

            <div className={styles.rightColumn__parking}>
              <RoundBtn
                isOpen={storeUI.isParking}
                openDescription={storeUI.openParking}
              />
              {storeUI.isParking && <Description title="Парковка" moreInfo />}
            </div>
            <div className={styles.rightColumn__kids}>
              <RoundBtn
                isOpen={storeUI.isKidsclub}
                openDescription={storeUI.openKidsClub}
              />
              {storeUI.isKidsclub && (
                <Description title="Детский клуб “Лунтик”" />
              )}
            </div>
            <div className={styles.rightColumn__fit}>
              <RoundBtn
                isOpen={storeUI.isFit}
                openDescription={storeUI.openFit}
              />
              {storeUI.isFit && (
                <Description
                  title="Вэлко фит"
                  className={styles.rightColumn__btnDescription}
                />
              )}
            </div>
            <div className={styles.rightColumn__supermarket}>
              <RoundBtn
                isOpen={storeUI.isSupermarket}
                openDescription={storeUI.openSupermarket}
              />
              {storeUI.isSupermarket && (
                <Description title="Продуктовый супермаркет “Пятерочка”" />
              )}
            </div>
            <div className={styles.rightColumn__cleaning}>
              <RoundBtn
                isOpen={storeUI.isCleaning}
                openDescription={storeUI.openCleaning}
              />
              {storeUI.isCleaning && (
                <Description
                  className={styles.rightColumn__cleaningDesc}
                  title="Клининговая компания “Все в порядке”"
                />
              )}
            </div>
          </div>
        </div>
        <ModalReuse
          isShowModal={isModalSave}
          setIsShowModal={closeSaveModal}
          btnTitle="Хорошо"
          type="button"
        >
          <h2 className={styles.main__modalSave}>
            Ваша заявка отправлена. Мы дадим ответ в ближайшее время
          </h2>
        </ModalReuse>
      </div>
    );
  }
);
