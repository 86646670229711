import { makeAutoObservable } from "mobx";

import { getFaq } from "../../../api/FAQ";

import { TResponseApi } from "../../../api/types";
import { IFaqItem } from "../../../interfaces/FAQ";
import { Store } from "../../types";

export class ModelFaq implements Store.IFaq {
  faqItems: IFaqItem[] = [];

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getFaq() {
    let response: TResponseApi<IFaqItem[]> = yield getFaq();

    if (response.isSuccess && response.data !== null) {
      this.setFaqItems(response.data);
    }
  }

  // setters

  setFaqItems(value: IFaqItem[]) {
    this.faqItems = value;
  }
}
