import { FC } from "react";

import calendar from "../../../../../assets/images/calendar.svg";

import styles from "./styles/prolongBtn.module.scss";

interface IProps {
  onClick: () => void;
  className?: string;
}

export const ProlongBtn: FC<IProps> = ({
  onClick = () => {},
  className = "",
}) => {
  return (
    <div className={`${styles.button} ${className}`} onClick={onClick}>
      <img className={styles.button__img} src={calendar} alt="calendar" />
      Продлить проживание
    </div>
  );
};
