import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { shortenString } from "../../../../../helpers";

import { Button } from "../../../../../components/Button";

import { ETabsServices } from "../../../../../types";

import { ReactComponent as Close } from "../../../../../assets/images/close.svg";

import styles from "./styles/serviceCard.module.scss";

interface IProps {
  id: number | null;
  image: string | null;
  serviceTitle: string;
  price: string;
  typeTab: ETabsServices | null;
  isPaid?: boolean;
  dateOfUse?: string | null;
  status?: string;
  descriptionService?: string | null;
  haveDate?: boolean;
  disabledButtons?: boolean;
  onClickDelete?(serviceId: number): void;
  onClickMore?(serviceId: number, typeTab: ETabsServices): void;
  onClickSelectDate?(serviceId: number): void;
}

export const ServiceCard = observer(
  ({
    id = null,
    image = null,
    serviceTitle = "",
    dateOfUse = "",
    price = "",
    status = "",
    descriptionService = null,
    typeTab = null,
    isPaid = false,
    haveDate = false,
    disabledButtons = false,
    onClickDelete = () => {},
    onClickMore = () => {},
    onClickSelectDate = () => {},
  }: IProps) => {
    let maxLengthDescription = 67;
    let maxLengthLongTitle = 37;

    let _serviceClass = useMemo(() => {
      switch (typeTab) {
        case ETabsServices.Selected:
          return styles.serviceCard_selectedMode;
        case ETabsServices.MyServices:
          return styles.serviceCard_myServicesMode;
        default:
          return styles.serviceCard_availableMode;
      }
    }, [typeTab]);

    let _description = useMemo(() => {
      return descriptionService
        ? shortenString(descriptionService, maxLengthDescription)
        : "";
    }, [descriptionService, maxLengthDescription]);

    let isVisiblePropsContainer = useMemo(
      () =>
        typeTab === ETabsServices.Selected ||
        typeTab === ETabsServices.MyServices,
      [typeTab]
    );

    let isVisibleDateButton = useMemo(
      () => typeTab === ETabsServices.Selected && !isPaid && !haveDate,
      [typeTab, isPaid, haveDate]
    );

    let isLongTitle = useMemo(
      () => serviceTitle.length >= maxLengthLongTitle,
      [maxLengthLongTitle, serviceTitle.length]
    );

    return (
      <div className={`${styles.serviceCard} ${_serviceClass}`}>
        {image?.length ? (
          <div
            className={`${styles.serviceCard__photoWrapper} ${
              isLongTitle ? styles.serviceCard__photoWrapper_roundCorners : ""
            }`}
          >
            <img
              className={styles.serviceCard__photo}
              src={image}
              alt="service"
            />
          </div>
        ) : (
          <div className={styles.serviceCard__photoEmpty}>
            Не удалось загрузить изображение
          </div>
        )}

        <div className={styles.serviceCard__infoContainer}>
          <h3 className={styles.serviceCard__serviceTitle}>{serviceTitle}</h3>

          {isVisiblePropsContainer && (
            <div className={styles.serviceCard__propsContainer}>
              {isVisibleDateButton && id && typeTab ? (
                <Button
                  title={"Выбрать дату"}
                  onClick={() => onClickSelectDate(id)}
                  theme={"white"}
                  className={styles.serviceCard__buttonSelectDate}
                  disabled={disabledButtons}
                  isDisabledStyle={disabledButtons}
                />
              ) : (
                <div
                  className={`${styles.serviceCard__props} ${
                    isVisibleDateButton
                      ? styles.serviceCard__props_withBtnDate
                      : ""
                  }`}
                >
                  <p className={styles.serviceCard__propsName}>
                    День использования
                  </p>
                  <p className={styles.serviceCard__propsValue}>{dateOfUse}</p>
                </div>
              )}

              <div
                className={`${styles.serviceCard__props} ${
                  isVisibleDateButton
                    ? styles.serviceCard__props_withBtnDate
                    : ""
                }`}
              >
                <p className={styles.serviceCard__propsName}>Стоимость</p>
                <p className={styles.serviceCard__propsValue}>{price} ₽</p>
              </div>
            </div>
          )}

          {typeTab === ETabsServices.Available && _description.length > 0 && (
            <p className={styles.serviceCard__description}>{_description}</p>
          )}

          {typeTab === ETabsServices.Available && (
            <div className={styles.serviceCard__morePriceWrapper}>
              {id !== null && (
                <Button
                  title={"Подробнее"}
                  onClick={() => onClickMore(id, typeTab)}
                  theme={"transparent"}
                  className={styles.serviceCard__buttonMore}
                  disabled={disabledButtons}
                  isDisabledStyle={disabledButtons}
                />
              )}

              <span className={styles.serviceCard__price}>{price} ₽</span>
            </div>
          )}

          {typeTab === ETabsServices.Selected &&
            !isPaid &&
            id !== null &&
            !disabledButtons && (
              <button
                className={styles.serviceCard__buttonCancel}
                onClick={() => onClickDelete(id)}
                disabled={disabledButtons}
              >
                <Close />
              </button>
            )}
        </div>
      </div>
    );
  }
);
