import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser";

import { Button } from "../../../components/Button";
import { SCREENS } from "../../../navigation/endpoints";
import { RevealAnimation } from "../RevealAnimation";
import { Slider } from "./Slider";

import { RootContext } from "../../../stores/Root";
import { StoreUI } from "./storeUI";

import styles from "./styles/flats.module.scss";

export const Flats: FC = observer(() => {
  const rootStore = React.useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore));

  const navigate = useNavigate();

  const _toApartments = () => {
    navigate(SCREENS.SCREEN_APARTMENTS);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__left}>
        {storeUI.storeRoot.isMainLoading ? (
          <div className={styles.container__empty} />
        ) : (
          <>
            <div className={styles.container__textBlock}>
              <RevealAnimation
                cascade
                damping={0.05}
                className={styles.container__textBlockAnimate}
              >
                {parse(storeUI.content)}
              </RevealAnimation>
            </div>
            <RevealAnimation
              delay={500}
              className={styles.container__buttonWrapper}
            >
              <Button
                title="ЗАБРОНИРОВАТЬ КВАРТИРУ"
                theme="white"
                onClick={_toApartments}
                className={styles.container__button}
              />
            </RevealAnimation>
          </>
        )}
      </div>
      <div className={styles.container__right}>
        <Slider />
      </div>
    </div>
  );
});
