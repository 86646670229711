import { ApiManager } from "..";
import { TIMEOUT_SEND_ARRIVAL_DATE } from "../../constants";

import {
  BOOKING_ADDITION_ARRIVAL_DATE,
  BOOKING_ADDITION_PETS,
  BOOKING_ADDITION_RENTER,
  BOOKING_ADDITION_TENANTS,
} from "../../constants/api";

import { IArrivalDate, ICommonResponse } from "../../interfaces";
import {
  IAdditionalPet,
  IAdditionalRenter,
} from "../../interfaces/AdditionalBookingData";
import { IAdditionTenants } from "../../interfaces/Booking";

import { TResponseApi } from "../types";

// внести информацию по арендатору
export async function sendAdditionRenter(
  bookingId: string,
  params: {
    renter: IAdditionalRenter;
  }
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BOOKING_ADDITION_RENTER(bookingId),
      method: "POST",
      data: params,
    },
    true
  );
}

// внести информацию по жильцам
export async function sendAdditionTenants(
  bookingId: string,
  params: IAdditionTenants
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BOOKING_ADDITION_TENANTS(bookingId),
      method: "POST",
      data: params,
    },
    true
  );
}

// внести информацию по питомцам
export async function sendAdditionPets(
  bookingId: string,
  params: {
    pets: IAdditionalPet[];
  }
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BOOKING_ADDITION_PETS(bookingId),
      method: "POST",
      data: params,
    },
    true
  );
}

// получить дату с которой можно выбрать заселение
export async function getArrivalDate(
  id: string
): Promise<TResponseApi<IArrivalDate>> {
  return await ApiManager.request<IArrivalDate>(
    {
      url: BOOKING_ADDITION_ARRIVAL_DATE(id),
      method: "GET",
    },
    true
  );
}

// внести дату заселения
export async function sendArrivalDate(
  id: string,
  params: { date: string }
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BOOKING_ADDITION_ARRIVAL_DATE(id),
      method: "POST",
      data: params,
      timeout: TIMEOUT_SEND_ARRIVAL_DATE,
    },
    true
  );
}
