import { makeAutoObservable } from "mobx";

import {
  getArrivalDate,
  sendAdditionPets,
  sendAdditionRenter,
  sendAdditionTenants,
  sendArrivalDate,
} from "../../../api/AdditionalBookingData";

import { ERROR_TEXT_SERVER_COMMON } from "../../../constants";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { IArrivalDate, ICommonResponse } from "../../../interfaces";
import {
  IAdditionalPet,
  IAdditionalRenter,
} from "../../../interfaces/AdditionalBookingData";
import { IAdditionTenants } from "../../../interfaces/Booking";

export class ModelAdditionalBookingData
  implements Store.IAdditionalBookingData
{
  arrivalDate: string = "";
  isSuccessSendArrivalDate: boolean = false;

  errorsAdditionTenants: string[] = [];
  errorsAdditionPets: string[] = [];
  errorsArrivalDate: string[] = [];

  isSuccessAdditionRenter: boolean = false;
  isSuccessAdditionTenants: boolean = false;
  isSuccessAdditionPets: boolean = false;

  isTimeoutEndSendArrivalDate: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions
  *sendAdditionRenter(bookingId: string, renterInfo: IAdditionalRenter) {
    let response: TResponseApi<ICommonResponse> = yield sendAdditionRenter(
      bookingId,
      { renter: renterInfo }
    );

    this.setIsSuccessAdditionRenter(
      response.isSuccess && response.data?.result === "success"
    );
  }

  *sendAdditionTenants(bookingId: string, params: IAdditionTenants) {
    let response: TResponseApi<ICommonResponse> = yield sendAdditionTenants(
      bookingId,
      params
    );

    this.setIsSuccessAdditionTenants(
      response.isSuccess && response.data?.result === "success"
    );

    if (response.isError) {
      this.addErrorAdditionTenants(
        response.error?.message
          ? response.error?.message
          : ERROR_TEXT_SERVER_COMMON
      );
    }
  }

  *sendAdditionPets(bookingId: string, pets: IAdditionalPet[]) {
    let response: TResponseApi<ICommonResponse> = yield sendAdditionPets(
      bookingId,
      { pets }
    );

    this.setIsSuccessAdditionPets(
      response.isSuccess && response.data?.result === "success"
    );

    if (response.isError) {
      this.addErrorAdditionPets(
        response.error?.message
          ? response.error?.message
          : ERROR_TEXT_SERVER_COMMON
      );
    }
  }

  *getArrivalDate(id: string) {
    let response: TResponseApi<IArrivalDate> = yield getArrivalDate(id);

    if (response.isSuccess && response.data !== null) {
      this.setArrivalDate(response.data.arrivalDate);
    }
  }

  *sendArrivalDate(id: string, params: { date: string }) {
    let response: TResponseApi<ICommonResponse> = yield sendArrivalDate(
      id,
      params
    );

    this.setIsTimeoutEndSendArrivalDate(response.isTimeoutEnd);

    this.setIsSuccessSendArrivalDate(
      response.isSuccess && response.data?.result === "success"
    );

    if (response.isSuccess && response.data !== null) {
      this.clearErrorsAdditionTenants();
    }

    if (response.isError && !response.isTimeoutEnd) {
      this.addErrorArrivalDate(
        response.error?.message
          ? response.error?.message
          : ERROR_TEXT_SERVER_COMMON
      );
    } else {
      this.clearErrorsArrivalDate();
    }
  }

  addErrorArrivalDate(value: string) {
    this.errorsArrivalDate = [...this.errorsArrivalDate, value];
  }

  addErrorAdditionTenants(value: string) {
    this.errorsAdditionTenants = [...this.errorsAdditionTenants, value];
  }

  addErrorAdditionPets(value: string) {
    this.errorsAdditionPets = [...this.errorsAdditionTenants, value];
  }

  clearErrorsArrivalDate() {
    this.errorsArrivalDate = [];
  }

  clearErrorsAdditionTenants() {
    this.errorsAdditionTenants = [];
  }

  // setters
  setArrivalDate(value: string) {
    this.arrivalDate = value;
  }

  setIsSuccessSendArrivalDate(value: boolean) {
    this.isSuccessSendArrivalDate = value;
  }

  setIsSuccessAdditionRenter(value: boolean) {
    this.isSuccessAdditionRenter = value;
  }

  setIsSuccessAdditionTenants(value: boolean) {
    this.isSuccessAdditionTenants = value;
  }

  setIsSuccessAdditionPets(value: boolean) {
    this.isSuccessAdditionPets = value;
  }

  setIsTimeoutEndSendArrivalDate(value: boolean) {
    this.isTimeoutEndSendArrivalDate = value;
  }
}
