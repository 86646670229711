import { makeAutoObservable } from "mobx";

import { enableScroll } from "../../../helpers/modalsFunc";

import { ADMIN, HOUSE } from "../../../constants";

import { Store } from "../../../stores/types";
import { EContact } from "../../../types";
import { IStoreUI } from "./interfaces";

export class StoreUI implements IStoreUI {
  storeContacts: Store.IContacts;
  isLoading: boolean = false;

  constructor(storeContacts: Store.IContacts) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeContacts = storeContacts;
  }

  // getters

  get contactsInfo() {
    return this.storeContacts.contacts?.map((contact) => {
      return {
        id: contact.id,
        icon: contact.icon,
        title: this.getReadyTitle(contact.name),
        text: contact.value,
      };
    });
  }

  get socials() {
    return this.storeContacts.socials?.map((social) => {
      return {
        ...social,
        name: this.toUpperFirstChar(social.name),
      };
    });
  }

  // functions

  *init() {
    enableScroll();
    this.setIsLoading(true);
    yield this.storeContacts.getSocials();
    yield this.storeContacts.getContacts();
    this.setIsLoading(false);
  }

  getReadyTitle(value: EContact) {
    switch (value) {
      case EContact.Address:
        return HOUSE;
      case EContact.Phone:
        return ADMIN;
      default:
        return "";
    }
  }

  toUpperFirstChar(value: string) {
    let bigChar = value.split("")[0].toUpperCase();
    return bigChar + value.slice(1, value.length);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
