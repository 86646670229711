import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Input } from "../../Input";
import { TextArea } from "../../TextArea";
import { Button } from "../../Button";
import { InputPhone } from "../../InputPhone";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";
import { SCREENS } from "../../../navigation/endpoints";

import { ReactComponent as Сlose } from "../../../assets/images/close.svg";
import building from "../../../assets/images/partnerPopup.png";

import styles from "./styles/bePartner.module.scss";

interface IProps {
  modal: boolean;
  isLoading: boolean;
  closeModal: () => void;
  name: string;
  organization: string;
  phone: string;
  text: string;
  changeName: (value: string) => void;
  changeOrganization: (value: string) => void;
  changePhone: (value: string) => void;
  changeText: (value: string) => void;
  sendPartnerRequest: () => void;
  errorsName: string[];
  errorsOrganization: string[];
  errorsPhone: string[];
  errorsText: string[];
}

export const BePartner: FC<IProps> = observer(
  ({
    modal = false,
    isLoading = false,
    closeModal = () => {},
    name = "",
    organization = "",
    phone = "",
    text = "",
    changeName = () => {},
    changeOrganization = () => {},
    changePhone = () => {},
    changeText = () => {},
    sendPartnerRequest = () => {},
    errorsName = [],
    errorsOrganization = [],
    errorsPhone = [],
    errorsText = [],
  }) => {
    const requsetValidation =
      name === "" || organization === "" || phone === "" || text === "";

    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        closeModal();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    return (
      <>
        {modal && (
          <div
            className={styles.modal}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.modal__contentWrapper}>
              <div
                className={styles.modal__content}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                <img
                  className={styles.modal__img}
                  src={building}
                  alt="building"
                />
                <div className={styles.modal__rightContent}>
                  <Сlose onClick={closeModal} className={styles.modal__close} />
                  <h2 className={styles.modal__title}>Стать партнером</h2>
                  <div className={styles.modal__input}>
                    <Input
                      value={name}
                      onChange={changeName}
                      label={"Как вас зовут?"}
                      placeholder="Введите имя"
                      className={styles.modal__input_size}
                      errors={errorsName}
                      maxLength={30}
                    />
                  </div>
                  <div className={styles.modal__input}>
                    <InputPhone
                      value={phone}
                      onChange={changePhone}
                      label="Номер телефона"
                      errors={errorsPhone}
                    />
                  </div>
                  <div className={styles.modal__input}>
                    <Input
                      value={organization}
                      onChange={changeOrganization}
                      label="Организация"
                      placeholder="Название организации"
                      className={styles.modal__input_size}
                      errors={errorsOrganization}
                      maxLength={30}
                    />
                  </div>
                  <div className={styles.modal__textArea}>
                    <TextArea
                      value={text}
                      onChange={changeText}
                      label="Вопрос/комментарий/предложение"
                      placeholder=" Текст сообщения"
                      errors={errorsText}
                      maxLength={1000}
                    />
                  </div>
                  <div className={styles.modal__buttonTop}>
                    <Button
                      title="ОТПРАВИТЬ ЗАЯВКУ"
                      type="submit"
                      theme="gold"
                      onClick={sendPartnerRequest}
                      disabled={isLoading}
                      isDisabledStyle={requsetValidation || isLoading}
                      className={styles.modal__button}
                    />
                  </div>
                  <p className={styles.modal__politics}>
                    Нажимая кнопку «Отправить заявку» вы подтверждаете свое
                    согласие на{" "}
                    <Link
                      to={SCREENS.SCREEN_TEXT_PERSONAL_DATA}
                      className={styles.modal__personal}
                      target="_blank"
                    >
                      обработку персональных данных
                    </Link>
                  </p>
                  <div className={styles.modal__buttonBot}>
                    <Button
                      title="ОТПРАВИТЬ ЗАЯВКУ"
                      type="submit"
                      theme="gold"
                      onClick={sendPartnerRequest}
                      disabled={isLoading}
                      isDisabledStyle={requsetValidation || isLoading}
                      className={styles.modal__button}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
