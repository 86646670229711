import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";

import { TButtonTheme, TButtonType } from "../../types";

import styles from "./styles/button.module.scss";

interface IProps {
  title: string;
  onClick: () => void;
  theme: TButtonTheme;
  disabled?: boolean;
  className?: string;
  type?: TButtonType;
  isDisabledStyle?: boolean;
  refBtn?: React.LegacyRef<HTMLButtonElement> | undefined;
}

export const Button: FC<IProps> = observer(
  ({
    title = "",
    theme = "white",
    disabled = false,
    className = "",
    type = "button",
    isDisabledStyle = false,
    onClick = () => {},
    refBtn,
  }) => {
    let _onClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      onClick();
    };

    let themeStyle = useMemo(() => {
      switch (theme) {
        case "gold":
          return `${styles.button_gold}`;
        case "transparent":
          return `${styles.button_transparent}`;
        default:
          return `${styles.button_white}`;
      }
    }, [theme]);

    let disabledStyle = useMemo(() => {
      if (isDisabledStyle) {
        switch (theme) {
          case "gold":
            return `${styles.button_goldDisabled}`;
          case "transparent":
            return `${styles.button_transparentDisabled}`;
          default:
            return `${styles.button_whiteDisabled}`;
        }
      } else {
        return "";
      }
    }, [isDisabledStyle, theme]);

    return (
      <button
        className={`${styles.button} ${
          !isDisabledStyle ? themeStyle : ""
        } ${className} ${
          isDisabledStyle ? styles.button_disabled : ""
        } ${disabledStyle}`}
        disabled={disabled}
        onClick={_onClick}
        type={type}
        ref={refBtn}
      >
        {title}
      </button>
    );
  }
);
