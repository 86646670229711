import { ApiManager } from "..";

import { TEXT_PAGE } from "../../constants/api";

import { TTextPage } from "../../types";
import { TResponseApi } from "../types";

export async function getText(code: string): Promise<TResponseApi<TTextPage>> {
  return await ApiManager.request<TTextPage>({
    url: TEXT_PAGE,
    method: "GET",
    params: { code },
  });
}
