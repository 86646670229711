import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, FreeMode } from "swiper";
import { Helmet } from "react-helmet";

import { ThumbsOptions } from "swiper/types";

import { Footer } from "../../components/Footer";
import { BackTo } from "../../components/BackTo";
import { Select } from "../../components/Select";
import { Loader } from "../../components/Loader";
import { FloorPlan } from "./FloorPlan";

import { ReactComponent as Direction } from "../../assets/images/direction.svg";

import { EFloorPlan, EPageType } from "../../types";

import { RootContext } from "./../../stores/Root/index";
import { StoreUI } from "./storeUI";

import { DESCRIPTION_PAGE_DEFAULT, PAGE_TITLE_FLOOR } from "../../constants";

import styles from "./styles/index.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./styles/bullets.scss";

type SwiperSlides = ThumbsOptions["swiper"];

export const Floor: FC = observer(() => {
  const rootStore = React.useContext(RootContext);
  const navigate = useNavigate();
  const [storeUI] = useState(() => new StoreUI(rootStore.storeFloor, navigate));
  const params = useParams();

  useEffect(() => {
    (async () => {
      await storeUI.init(Number(params.id));
    })();
  }, [params.id, storeUI]);

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperSlides>(null);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const nav = {
    nextEl: navigationNextRef.current,
    prevEl: navigationPrevRef.current,
  };

  const _toBack = () => {
    window.history.back();
  };

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.Floor
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_FLOOR}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <div className={styles.floor}>
      {helmet}
      <div className={styles.floor__mainContent}>
        <BackTo
          text={"Назад"}
          onClick={_toBack}
          className={styles.floor__backBtn}
        />
        <div
          className={`${styles.floor__content} ${
            storeUI.typeFloorBySelect === EFloorPlan.NoApartments &&
            styles.floor__content_noApartments
          }`}
        >
          {storeUI.typeFloorBySelect !== EFloorPlan.NoApartments && (
            <div className={styles.floor__leftSection}>
              <h1 className={styles.floor__numberFloor}>
                {storeUI.currentFloor} этаж
              </h1>

              <div className={styles.floor__statusDescription}>
                <div className={styles.floor__statusItem}>
                  <div
                    className={`${styles.floor__statusCircle} ${styles.floor__statusCircle_free}`}
                  />
                  Свободно
                </div>

                <div className={styles.floor__statusItem}>
                  <div className={styles.floor__statusCircle} />
                  Занято
                </div>

                <Direction className={styles.floor__direction} />
              </div>
            </div>
          )}

          <div className={styles.floor__rightSection}>
            <div
              className={`${styles.floor__sliderWrapper} ${
                storeUI.typeFloorBySelect === EFloorPlan.NoApartments &&
                styles.floor__sliderWrapper_noApartments
              }`}
            >
              {storeUI.errorPlan.length > 0 && (
                <span className={styles.floor__sliderError}>
                  {storeUI.errorPlan}
                </span>
              )}
              {storeUI.typeFloorBySelect !== EFloorPlan.NoApartments && (
                <div className={styles.floor__streetsWrapper}>
                  <div className={styles.floor__streetGorizontal}>
                    25 Октября
                  </div>
                  <div className={styles.floor__streetsVerticalWrapper}>
                    <div className={styles.floor__streetVertical}>
                      <span>Трилиссера</span>
                    </div>
                    <div className={styles.floor__streetVertical}>
                      <span>4ая Советская</span>
                    </div>
                  </div>

                  <div className={styles.floor__streetGorizontal}> Седова</div>
                </div>
              )}
              <Swiper
                loop={false}
                direction={"vertical"}
                spaceBetween={5}
                navigation={nav}
                modules={[FreeMode, Navigation, Thumbs]}
                className={styles.floor__sliderFloor}
                initialSlide={Number(params.id) - 1}
                thumbs={{
                  swiper: !thumbsSwiper?.destroyed ? thumbsSwiper : null,
                }}
                onSlideChange={storeUI.onSlideChange}
                onSwiper={storeUI.setSwiper}
                allowSlideNext={!storeUI.isLoadingSlider}
                allowSlidePrev={!storeUI.isLoadingSlider}
                threshold={15}
              >
                {storeUI.floorsPlansList.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className={`${styles.floor__slide} ${
                        storeUI.typeFloorBySelect === EFloorPlan.NoApartments &&
                        styles.floor__slide_noApartments
                      }`}
                    >
                      {item.typeFloor === EFloorPlan.NoApartments ? (
                        <div className={styles.floor__noApartments}>
                          На этом этаже не сдаются квартиры
                        </div>
                      ) : (
                        <FloorPlan
                          typeFloor={item.typeFloor}
                          floorApartments={storeUI.floorApartments}
                          blockedApartments={storeUI.blockedApartments}
                          onClickApartment={
                            rootStore.storeModals.openApartmentPlan
                          }
                          toApartmentPage={storeUI.toApartmentPage}
                        />
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className={styles.floor__sliderNavigation}>
                {storeUI.isLoadingSlider && (
                  <Loader className={styles.floor__sliderLoader} />
                )}
                <button
                  className={`${styles.floor__btnPrev} ${
                    storeUI.isLoadingSlider && styles.floor__btnPrev_disabled
                  }`}
                  ref={navigationPrevRef}
                  onClick={storeUI.decrementFloor}
                  disabled={storeUI.isLoadingSlider}
                />

                {/* блок пагинации с цифрами */}
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={0}
                  slidesPerView={10}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className={`${styles.floor__bulletsWrap} ${"bulletsWrapper"}`}
                  direction="vertical"
                  initialSlide={Number(params.id) - 1}
                >
                  {storeUI.floorsPlansList.map((item, index) => (
                    /* убрала клик на пагинации, т к есть метод на событии onSlideChange */
                    <SwiperSlide
                      key={index}
                      className={styles.floor__bulletSlide}
                    >
                      <div className={styles.floor__bullet}>
                        <span> {item.numberFloor}</span>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <button
                  className={`${styles.floor__btnNext} ${
                    storeUI.isLoadingSlider && styles.floor__btnNext_disabled
                  }`}
                  ref={navigationNextRef}
                  onClick={storeUI.incrementFloor}
                  disabled={storeUI.isLoadingSlider}
                />
              </div>
            </div>
          </div>

          {/* Верстка для ширина экрана <= 767px */}
          <div className={styles.floor__adaptive}>
            <BackTo
              text={"Назад"}
              onClick={_toBack}
              className={styles.floor__adaptiveBack}
            />

            <div className={styles.floor__adaptiveInfoWrapper}>
              <Select
                placeholder={"Выберите этаж"}
                value={storeUI.currentFloorForSelect}
                onChange={storeUI.changeCurrentFloorForSelect}
                options={storeUI.floorsListForOptions}
                className={styles.floor__adaptiveSelectFloor}
              />
              {storeUI.errorBlockedApartment.length > 0 && (
                <span
                  className={`${styles.floor__calendarError} ${styles.floor__calendarError_adaptive}`}
                >
                  {storeUI.errorBlockedApartment}
                </span>
              )}

              <div
                className={`${styles.floor__statusDescription} ${styles.floor__statusDescription_adaptive}`}
              >
                <div className={styles.floor__statusItem}>
                  <div
                    className={`${styles.floor__statusCircle} ${styles.floor__statusCircle_free}`}
                  />
                  Свободно
                </div>

                <div className={styles.floor__statusItem}>
                  <div className={styles.floor__statusCircle} />
                  Занято
                </div>
              </div>

              <Direction
                className={`${styles.floor__direction} ${styles.floor__direction_adaptive}`}
              />
              {storeUI.isLoading && (
                <Loader className={styles.floor__adaptiveLoader} />
              )}
            </div>

            <div className={styles.floor__backgroundFloor}>
              {storeUI.errorPlan.length > 0 && (
                <span className={styles.floor__sliderError}>
                  {storeUI.errorPlan}
                </span>
              )}
              <div
                className={`${styles.floor__planAdaptiveWrapper} ${
                  storeUI.typeFloorBySelect === EFloorPlan.NoApartments &&
                  styles.floor__planAdaptiveWrapper_noApartments
                }`}
              >
                {storeUI.typeFloorBySelect !== EFloorPlan.NoApartments && (
                  <div className={styles.floor__streetsWrapper}>
                    <div className={styles.floor__streetGorizontal}>
                      25 Октября
                    </div>
                    <div className={styles.floor__streetsVerticalWrapper}>
                      <div className={styles.floor__streetVertical}>
                        <span>Трилиссера</span>
                      </div>
                      <div className={styles.floor__streetVertical}>
                        <span>4ая Советская</span>
                      </div>
                    </div>

                    <div className={styles.floor__streetGorizontal}>Седова</div>
                  </div>
                )}
                <div className={styles.floor__slideAdaptive}>
                  {storeUI.typeFloorBySelect === EFloorPlan.NoApartments ? (
                    <div className={styles.floor__noApartments}>
                      <span>На этом этаже не сдаются квартиры</span>
                    </div>
                  ) : (
                    <FloorPlan
                      typeFloor={storeUI.typeFloorBySelect}
                      floorApartments={storeUI.floorApartments}
                      blockedApartments={storeUI.blockedApartments}
                      onClickApartment={rootStore.storeModals.openApartmentPlan}
                      toApartmentPage={storeUI.toApartmentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer className={styles.floor__footer} />
      </div>
    </div>
  );
});
