import { FC } from "react";
import { Link } from "react-router-dom";

import { Button } from "../../../components/Button";
import { ServiceItem } from "./ServiceItem";

import { SCREENS } from "../../../navigation/endpoints";
import { BASKET_SERVICES_VISIBLE } from "../../../constants";

import { IServiceOfBasket } from "../../../interfaces/Basket";

import styles from "./styles/additional.module.scss";

interface IProps {
  services: IServiceOfBasket[];
  countServices: number;
  price: string;
  className?: string;
  haveApartment: boolean;
  mainError?: string;
  onDeleteService(serviceId: number): void;
  onClickOrder(): void;
  onClearBasket(): void;
  onClickAllServices(): void;
}

export const Additional: FC<IProps> = ({
  services = [],
  price = "",
  className = "",
  haveApartment = false,
  onDeleteService = () => {},
  onClearBasket = () => {},
  onClickOrder = () => {},
  onClickAllServices = () => {},
  countServices = 0,
  mainError = "",
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <button
        onClick={onClearBasket}
        disabled={!haveApartment}
        className={styles.container__clearList}
      >
        Очистить корзину
      </button>
      <div className={styles.container__content}>
        <p className={styles.container__title}>Доп. услуги</p>
        <div className={styles.container__services}>
          {services?.length !== 0 ? (
            <>
              {services.map((service) => (
                <ServiceItem
                  name={service.name}
                  key={service.serviceId}
                  className={styles.container__service}
                  onDelete={() => onDeleteService(service.id)}
                />
              ))}
              {countServices > BASKET_SERVICES_VISIBLE && (
                <button
                  onClick={onClickAllServices}
                  className={styles.container__allServices}
                >
                  Все услуги ({countServices})
                </button>
              )}
            </>
          ) : (
            <span className={styles.container__servicesText}>
              Вы можете добавить дополнительные услуги
            </span>
          )}
        </div>
        <Link
          to={SCREENS.SCREEN_SERVICES}
          className={styles.container__addService}
        >
          Перейти к услугам
        </Link>
        {haveApartment && (
          <div className={styles.container__price}>
            <p className={styles.container__total}>Конечная стоимость:</p>
            <p className={styles.container__title}>{price} ₽</p>
          </div>
        )}
      </div>
      <Button
        className={`${styles.container__button} ${
          !haveApartment && styles.container__button_disabled
        }
        `}
        title="ОФОРМИТЬ ЗАКАЗ"
        theme="gold"
        onClick={onClickOrder}
      />
      {mainError.length > 0 && (
        <span className={styles.container__error}>{mainError}</span>
      )}
    </div>
  );
};
