import { observer } from "mobx-react-lite";

import { Button } from "../../../../../../components/Button";
import { Loader } from "../../../../../../components/Loader";

import styles from "./styles/act.module.scss";

interface IProps {
  id: number;
  actNumber: string;
  dateOfIssue: string;
  isLoadingDownload: boolean;
  isReviseAct?: boolean;
  onClickShow(id: number): void;
  onDownload(id: number): void;
}

export const Act = observer(
  ({
    id = 0,
    actNumber = "",
    dateOfIssue = "",
    isReviseAct = false,
    isLoadingDownload = false,
    onClickShow = () => {},
    onDownload = () => {},
  }: IProps) => {
    let actTitleLength = 17;

    return (
      <div
        className={`${styles.act} ${isReviseAct ? styles.act_reviseMode : ""}`}
      >
        {isLoadingDownload && <Loader className={styles.act__loader} />}
        {isReviseAct && <span className={styles.act__title}>Акт сверки</span>}
        <div className={styles.act__header}>
          <span
            className={`${styles.act__number} ${
              actNumber.length >= actTitleLength ? styles.act__number_small : ""
            }`}
          >
            {actNumber}
          </span>
          <span className={styles.act__date}>От {dateOfIssue}</span>
        </div>

        <div className={styles.act__buttonsWrapper}>
          <Button
            title={"просмотр"}
            onClick={() => onClickShow(id)}
            theme={"transparent"}
            className={`${styles.act__button} ${styles.act__button_look}`}
            disabled={isLoadingDownload}
            isDisabledStyle={isLoadingDownload}
          />

          <Button
            title={"скачать"}
            onClick={() => onDownload(id)}
            theme={"transparent"}
            className={styles.act__button}
            disabled={isLoadingDownload}
            isDisabledStyle={isLoadingDownload}
          />
        </div>
      </div>
    );
  }
);
