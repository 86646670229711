import { makeAutoObservable } from "mobx";

import { getTextConctreteBlock } from "../../../api/MainPage";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { TTextPage } from "../../../types";

export class ModelMain implements Store.IMain {
  textBlocks: TTextPage[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // functions

  *getTextConcreteBlock(code: string) {
    let response: TResponseApi<TTextPage[]> = yield getTextConctreteBlock({
      code,
    });

    if (response.isSuccess && response.data !== null) {
      this.setTextBlocks(response.data);
    }
  }

  // setters

  setTextBlocks(value: TTextPage[]) {
    this.textBlocks = value;
  }
}
