import { observer } from "mobx-react-lite";

import { useOutSide } from "../../../../../../hooks/useOutSide";

import { Button } from "../../../../../../components/Button";
import { Loader } from "../../../../../../components/Loader";
import { MenuDots } from "../../../../../../components/MenuDots";

import { ReactComponent as Menu } from "../../../../../../assets/images/menuDots.svg";

import styles from "./styles/otherCard.module.scss";

interface IProps {
  id: number;
  titleDocument: string;
  date: string;
  isLoadingDownload: boolean;
  onShowDocument(id: number): void;
  onClickDownload(id: number): void;
}

export const OtherCard = observer(
  ({
    id = 0,
    titleDocument = "",
    date = "",
    isLoadingDownload = false,
    onShowDocument = () => {},
    onClickDownload = () => {},
  }: IProps) => {
    const { ref, isShow, setIsShow } = useOutSide(false);

    return (
      <div className={styles.otherCard}>
        <div className={styles.otherCard__titleDateWrapper}>
          <span className={styles.otherCard__title}>{titleDocument}</span>
          <span className={styles.otherCard__date}>{date}</span>
        </div>

        <div className={styles.otherCard__buttons}>
          <Button
            title="Просмотр"
            theme="white"
            onClick={() => onShowDocument(id)}
            className={styles.otherCard__button}
            disabled={isLoadingDownload}
            isDisabledStyle={isLoadingDownload}
          />

          {!isLoadingDownload && (
            <Button
              title="Скачать"
              theme="white"
              onClick={() => onClickDownload(id)}
              className={styles.otherCard__downloadAdaptive}
            />
          )}
          {isLoadingDownload ? (
            <div className={styles.otherCard__menuLoading}>
              <Loader className={styles.otherCard__loader} />
            </div>
          ) : (
            <span
              className={styles.otherCard__menuWrapper}
              ref={ref}
              onClick={() => setIsShow(!isShow)}
            >
              <Menu className={styles.otherCard__menu} />
              {isShow && (
                <MenuDots
                  className={styles.otherCard__dropList}
                  onClickDownlooad={() => onClickDownload(id)}
                />
              )}
            </span>
          )}
        </div>
      </div>
    );
  }
);
