import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { RevealAnimation } from "../RevealAnimation";
import { Button } from "../../../components/Button";
import { Flat } from "../../../components/Flat";

import { StoreUI } from "./storeUI/catalog";
import { RootContext } from "../../../stores/Root";

import styles from "./styles/ourFlats.module.scss";

interface IProps {
  openFeedback(): void;
}

export const OurFlats: FC<IProps> = observer(
  ({ openFeedback = () => {} }: IProps) => {
    const navigate = useNavigate();
    const rootStore = useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(navigate, rootStore));

    useEffect(() => {
      (async () => {
        await storeUI.init();
      })();
    }, [storeUI]);

    return (
      <div className={styles.container}>
        <div className={styles.container__content}>
          <RevealAnimation>
            <h1 className={styles.container__header}>НАШИ КВАРТИРЫ</h1>
          </RevealAnimation>
          <RevealAnimation>
            <div className={styles.container__flats}>
              {storeUI.apartments?.map((flat) => (
                <Flat
                  key={flat.id}
                  id={flat.id}
                  image={flat.image}
                  square={flat.square}
                  minPeople={flat.minPeople}
                  maxPeople={flat.maxPeople}
                  pets={flat.pets}
                  flatNumber={flat.flatNumber}
                  floor={flat.floor}
                  openFeedback={openFeedback}
                  price={flat.price}
                />
              ))}
            </div>
          </RevealAnimation>
          <RevealAnimation className={styles.container__buttonWrapper}>
            <Button
              title="ПОКАЗАТЬ ЕЩЁ КВАРТИРЫ"
              theme="transparent"
              onClick={storeUI.toApartments}
              className={styles.container__button}
            />
          </RevealAnimation>
        </div>
      </div>
    );
  }
);
