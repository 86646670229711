import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { SCREENS } from "../../../navigation/endpoints";

import { IStoreUI } from "./interfaces";

import { Store } from "../../../stores/types";
import { EParamsSberResult } from "../../../types";

export class StoreUI implements IStoreUI {
  storePayment: Store.IPayment;
  navigate: NavigateFunction;

  code: string | null = null;
  state: string | null = null;
  nonce: string | null = null;
  paymentId: string | null = "";

  isSuccessSberAuth: boolean = false;

  isLoading: boolean = true;

  constructor(storePayment: Store.IPayment, navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storePayment = storePayment;
    this.navigate = navigate;
  }

  get paymentStatus() {
    return this.storePayment.paymentStatus;
  }

  get paymentStrategy() {
    return this.storePayment.paymentStrategy;
  }

  init() {
    this.getParamsFromUrlAuth();
    this.getPaymentIdFromUrl();
  }

  *registerSberBusiness() {
    this.setIsLoading(true);

    if (this.code && this.state && this.nonce) {
      let response: boolean = yield this.storePayment.registerSberBusiness(
        String(this.code),
        String(this.state),
        String(this.nonce)
      );

      this.setIsSuccessSberAuth(response);
    }

    this.setIsSuccessSberAuth(false);

    this.setIsLoading(false);
  }

  *updatePaymentStatus() {
    this.setIsLoading(true);

    yield this.storePayment.updatePayment(Number(this.paymentId));

    this.setIsLoading(false);
  }

  getParamsFromUrlAuth() {
    let params = new URL(window.location.href).searchParams;

    this.setCode(params.get(EParamsSberResult.Code) ?? null);
    this.setState(params.get(EParamsSberResult.State) ?? null);
    this.setNonce(params.get(EParamsSberResult.Nonce) ?? null);
  }

  getPaymentIdFromUrl() {
    let path = window.location.pathname.split("/");
    this.setPaymentId(path[path.length - 1]);
  }

  toDocuments() {
    this.navigate(SCREENS.SCREEN_ACCOUNT_DOCUMENTS);
  }

  setCode(value: string | null) {
    this.code = value;
  }

  setState(value: string | null) {
    this.state = value;
  }

  setNonce(value: string | null) {
    this.nonce = value;
  }

  setPaymentId(value: string | null) {
    this.paymentId = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsSuccessSberAuth(value: boolean) {
    this.isSuccessSberAuth = value;
  }
}
