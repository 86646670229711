import { makeAutoObservable } from "mobx";

import { PetStoreUI } from "./forms/petStoreUI";

import {
  MAX_COUNT_PETS,
  OPTION_HAVE_VET_PASSPORT,
  OPTION_NOT_HAVE_VET_PASSPORT,
} from "../../../constants";

import { IPetStoreUI, IStoreUI } from "./interfaces";
import { IOptionCommon, IRadioItem } from "../../../interfaces";
import { IPet } from "../../../interfaces/Booking";
import { IAdditionalPet } from "../../../interfaces/AdditionalBookingData";

import { Store } from "../../../stores/types";

export class PetsStoreUI implements Store.IPetsOrderingData {
  storeParent: IStoreUI;
  storeAdditionalBookingData: Store.IAdditionalBookingData;
  storeBookingResidents: Store.IBookingResidents;
  petsForms: IPetStoreUI[] = [];

  petsForSend: IPet[] = [];

  isLoading: boolean = false;
  isVisibleDeletePopup: boolean = false;

  // для определения показывать ли формы животных (для радио кнопок да/нет)
  withPetsCurrent: IRadioItem = { id: 1, label: "Да", value: true };
  withPetsOptions: IRadioItem[] = [
    { id: 1, label: "Да", value: true },
    { id: 2, label: "Нет", value: false },
  ];

  currentIdForDelete: number | null = 0;

  constructor(storeParent: IStoreUI) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeParent = storeParent;
    this.storeAdditionalBookingData = storeParent.storeAdditionalBookingData;
    this.storeBookingResidents = storeParent.storeRoot.storeBookingResidents;
  }

  // getters
  get bookingId() {
    return this.storeParent.bookingId;
  }

  get mainErrors() {
    return this.storeAdditionalBookingData.errorsAdditionPets;
  }

  get withPets() {
    // выбрал ли пользователь заселение с животными
    return Boolean(this.withPetsCurrent.value);
  }

  get disabledAddPet() {
    return this.petsForms.length === MAX_COUNT_PETS;
  }

  // functions
  init() {
    if (this.storeParent.canPets) {
      if (!this.petsForms.length) {
        this.createPet(1);
      }
    }
  }

  *createAgreement() {
    this.setIsLoading(true);
    this.addPetsForSend();

    if (this.bookingId && this.validateAllPets()) {
      yield this.sendPets(this.bookingId);

      if (this.storeAdditionalBookingData.isSuccessAdditionPets) {
        yield this.storeParent.createAgreement();
      }
    }

    this.setIsLoading(false);
  }

  *sendPets(bookingId: string) {
    let _petForSend: IAdditionalPet[] = [];

    if (
      this.withPetsCurrent.value &&
      this.petsForSend.length > 0 &&
      this.validateAllPets()
    ) {
      _petForSend = this.petsForSend;
    }
    yield this.storeAdditionalBookingData.sendAdditionPets(
      bookingId,
      _petForSend
    );
  }

  fillFormsPrevEnteredData(pets: IPet[]) {
    if (pets.length && this.petsForms.length < pets.length) {
      pets.forEach((pet, index) => {
        let formId = index + 1;
        this.fillPetForm(pet, formId);
      });
    }
  }

  // ---- validation

  validateAllPets() {
    if (!this.withPetsCurrent.value) {
      return true;
    } else {
      let validArray: Array<boolean> = [];

      this.petsForms.forEach((pet) => {
        validArray.push(pet.validateAll());
      });

      return !validArray.includes(false);
    }
  }

  addPet(): void {
    if (!this.disabledAddPet) {
      let lastElem = this.petsForms[this.petsForms.length - 1];
      let lastId = lastElem.localId; // берем id последнего питомца
      this.createPet(lastId + 1); // и прибавляем 1
    }
  }

  // ---- helpers
  createPet(id: number) {
    let pet = new PetStoreUI(); // создать экземпляр Формы питомца
    pet.setLocalId(id); //записываем id, чтобы потом обращаться по нему
    this.petsForms.push(pet);

    return pet;
  }

  addPetsForSend() {
    // Питомцы. добавляем отформатированные данные для отправки на бэк
    let readyPets: IPet[] = this.petsForms.map((pet) => {
      return {
        type: pet.type,
        breed: pet.breed,
        veterinaryPassport: Boolean(
          pet.veterinaryPassport?.value ? pet.veterinaryPassport?.value : false
        ),
      };
    });

    this.setPetsForSend(readyPets);
  }

  getOptionByBoolean(
    value: boolean,
    optionTrue: IOptionCommon,
    optionFalse: IOptionCommon
  ): IOptionCommon {
    return value ? optionTrue : optionFalse;
  }

  // найти конкретную форму питомца в массиве форм по id
  searchPet(idPet: number) {
    return this.petsForms.find((pet) => pet.localId === idPet);
  }

  fillPetForm(pet: IPet, formId: number) {
    let petForm: IPetStoreUI = this.createPet(formId);

    petForm.changeBreed(pet.breed || "");
    petForm.changeType(pet.type || "");
    petForm.changeVeterinaryPassport(
      this.getOptionByBoolean(
        pet.veterinaryPassport,
        OPTION_HAVE_VET_PASSPORT,
        OPTION_NOT_HAVE_VET_PASSPORT
      )
    );
  }

  clearErrorsPet() {
    this.petsForms.forEach((item) => {
      item.clearErrors();
    });
  }

  deletePet(currentFormId: number) {
    let _pet = this.petsForms.find((pet) => pet.localId === currentFormId);

    if (_pet) {
      let myIndex = this.petsForms.indexOf(_pet);
      if (myIndex !== -1) {
        this.petsForms.splice(myIndex, 1);
      }
    }
    this.closeDeletePopup();
  }

  openDeletePopup(localId: number) {
    this.setCurrentIdForDelete(localId);
    this.setIsVisibleDeletePopup(true);
  }

  closeDeletePopup() {
    this.setCurrentIdForDelete(null);
    this.setIsVisibleDeletePopup(false);
  }

  // ---- changes

  changeVetPassport(value: IOptionCommon, idPet: number) {
    let _pet = this.searchPet(idPet);
    if (_pet) {
      _pet.changeVeterinaryPassport(value);
    }
  }

  changeType(value: string, idPet: number) {
    let _pet = this.searchPet(idPet);
    if (_pet) {
      _pet.changeType(value);
    }
  }

  changeBreed(value: string, idPet: number) {
    let _pet = this.searchPet(idPet);
    if (_pet) {
      _pet.changeBreed(value);
    }
  }

  changeWithPet(radioItem: IRadioItem) {
    this.setWithPetsCurrent(radioItem);

    if (radioItem.label.toLowerCase() === "нет") {
      this.clearErrorsPet();
    }
  }

  // setters
  setPetsForSend(value: IPet[]) {
    this.petsForSend = value;
  }

  setWithPetsCurrent(value: IRadioItem) {
    this.withPetsCurrent = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsVisibleDeletePopup(value: boolean) {
    this.isVisibleDeletePopup = value;
  }

  setCurrentIdForDelete(value: number | null) {
    this.currentIdForDelete = value;
  }
}
