import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Photo } from "../Photo";
import { PlanWithPoints } from "./PlanWithPoints";
import { Button } from "../../Button";
import { Loader } from "../../Loader";

import { RootContext } from "../../../stores/Root";
import { StoreUI } from "./storeUI";

import { NUMBER_OF_FLOORS } from "../../../constants";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import photoFlat from "../../../assets/images/photoFlat.png";

import styles from "./styles/flatPlan.module.scss";

interface IProps {
  showFlat: boolean;
  closeFlatModal: () => void;
  className?: string;
}

// временно (такого плана будут приходить поинты в будущем)
const points = [
  { id: 5, src: photoFlat },
  { id: 38, src: photoFlat },
  { id: 41, src: photoFlat },
];

export const FlatPlan: FC<IProps> = observer(
  ({ showFlat = false, closeFlatModal = () => {}, className = "" }) => {
    const navigate = useNavigate();
    const rootStore = React.useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(rootStore, navigate));

    useEffect(() => {
      (async () => {
        await storeUI.init();
      })();
    }, [storeUI]);

    window.onpopstate = function (event) {
      storeUI.closePopup();
    };

    return (
      <>
        {showFlat && (
          <div className={styles.flatPlan}>
            <div className={styles.flatPlan__popupWrapper}>
              <div className={styles.flatPlan__popup}>
                <div className={styles.flatPlan__popupAside}></div>
                <div className={styles.flatPlan__content}>
                  <Close
                    onClick={closeFlatModal}
                    className={styles.flatPlan__close}
                  />
                  {storeUI.isLoading ? (
                    <div className={styles.flatPlan__empty}>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div className={styles.flatPlan__leftSection}>
                        <p className={styles.flatPlan__title}>
                          Квартира №{storeUI.apartmentsPlanInfo.number}
                        </p>
                        <div className={styles.flatPlan__flatInfo}>
                          <div>
                            <p>Общая площадь</p>
                            <h5>{storeUI.apartmentsPlanInfo.square} м2</h5>
                          </div>
                          <div>
                            <p>Этаж</p>
                            <h5>
                              {storeUI.apartmentsPlanInfo.floor} из{" "}
                              {NUMBER_OF_FLOORS}
                            </h5>
                          </div>
                        </div>
                        <Button
                          className={styles.flatPlan__button}
                          title="Забронировать"
                          theme="gold"
                          onClick={() =>
                            storeUI.onBook(storeUI.apartmentsPlanInfo.id)
                          }
                        />
                      </div>

                      {storeUI.apartmentsPlanInfo.apartmentPlan.length > 0 ? (
                        <div className={styles.flatPlan__rightSection}>
                          <PlanWithPoints
                            pointsList={points}
                            planImage={storeUI.apartmentsPlanInfo.apartmentPlan}
                            openInnerPhoto={storeUI.openPhotoPopup}
                          />
                        </div>
                      ) : (
                        <div className={styles.flatPlan__planEmpty}>
                          Не удалось загрузить изображение
                        </div>
                      )}

                      <Button
                        className={styles.flatPlan__buttonAdaptive}
                        title="Забронировать"
                        theme="gold"
                        onClick={() =>
                          storeUI.onBook(storeUI.apartmentsPlanInfo.id)
                        }
                      />

                      <Photo
                        isVisible={storeUI.isVisiblePhoto}
                        onClose={storeUI.closePhotoPopup}
                        src={photoFlat}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
