import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { RentItem } from "./RentItem";
import { PayWarning } from "./PayWarning";
import { Empty } from "../../../../components/Empty";
import { Loader } from "../../../../components/Loader";
import { Prolong } from "../../../../components/Modals/Prolong";
import { ProlongResult } from "../../../../components/Modals/ProlongResult";
import { ModalReuse } from "../../../../components/Modals/ModalReuse";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../../stores/Root";

import { ReactComponent as Lock } from "../../../../assets/images/lock.svg";

import styles from "./styles/rent.module.scss";

export const Rent: FC = observer(() => {
  const params = useParams();
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore, navigate));

  useEffect(() => {
    (async () => {
      await storeUI.init(params.id ?? "");
    })();
  }, [
    storeUI,
    rootStore,
    params.id,
    rootStore.storeAdditionalBookingData.isSuccessSendArrivalDate,
  ]);

  return (
    <div className={styles.rent}>
      {!storeUI.isLoading ? (
        storeUI.apartmentInfo ? (
          <div className={styles.rent__content}>
            <div className={styles.rent__flats}>
              <RentItem
                id={storeUI.bookingId}
                rooms={storeUI.apartmentInfo.rooms}
                square={storeUI.apartmentInfo.square}
                floor={storeUI.apartmentInfo.floor}
                flatNumber={storeUI.apartmentInfo.flatNumber}
                tenants={storeUI.apartmentInfo.tenants}
                deposit={storeUI.apartmentInfo.deposit}
                startDate={storeUI.apartmentInfo.startDate}
                datePeriod={storeUI.apartmentInfo.datePeriod}
                dateEnding={storeUI.apartmentInfo.dateEnding}
                openExtension={storeUI.openExtension}
                onClickSelectArrivalDate={storeUI.openInfoPopup}
                dateEnd={storeUI.apartmentInfo.endDate}
                toTenants={() => storeUI.toTenants(storeUI.bookingId)}
                isVisibleEndBooking={Boolean(
                  storeUI.apartmentInfo.isVisibleEndBooking
                )}
                haveCheckInDate={storeUI.haveArrivalDate}
                checkinDate={storeUI.apartmentInfo.checkinDate}
              />
            </div>
            {storeUI.checkBills && (
              <PayWarning
                className={styles.rent__warning}
                id={storeUI.bookingId}
                depositBill={storeUI.depositBill}
                rentBill={storeUI.rentBill}
              />
            )}
          </div>
        ) : (
          <div className={styles.rent__empty}>
            <Empty
              title="Вы пока ничего не арендовали"
              titleBtn="ВЫБРАТЬ КВАРТИРУ"
              Icon={Lock}
              onClick={storeUI.toApartments}
            />
          </div>
        )
      ) : (
        <Loader />
      )}
      <Prolong
        visible={storeUI.visibleExtension}
        closeModal={storeUI.closeExtension}
        isCanBeExtended={storeUI.isCanBeExtended}
        isLoading={storeUI.isLoadingPopup}
        onClickContinue={storeUI.sendBookingExtension}
        errors={storeUI.errorsSendBooking}
        maxDate={storeUI.maxDate}
        minDate={storeUI.minDate}
        selectedDay={storeUI.selectedDay}
        changeSelectedDay={storeUI.changeSelectedDay}
      />

      <ProlongResult
        visible={storeUI.visibleExtensionResult}
        status={storeUI.depositStatus}
        depositPrice={storeUI.depositPrice}
        onClose={storeUI.closeExtensionResult}
        buttonTitle={storeUI.depositStatusButtonTitle}
        onClickButton={storeUI.depositClickButton}
      />

      <ModalReuse
        isShowModal={storeUI.visibleInfoPopup}
        type={"button"}
        btnTitle="Продолжить"
        onClickSingleButton={storeUI.toOrderingData}
        setIsShowModal={storeUI.setVisibleInfoPopup}
      >
        <p className={styles.rent__modalText}>
          Перед выбором даты заселения заполните необходимую информацию
        </p>
      </ModalReuse>
    </div>
  );
});
