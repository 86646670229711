import { makeAutoObservable } from "mobx";

import { disableScroll } from "../../../helpers/modalsFunc";

import { IModalsStoreUI, IStoreUI } from "./interfaces";

export class ModalsStoreUI implements IModalsStoreUI {
  storeParent: IStoreUI;

  isVisibleFillProfile: boolean = false;
  isVisibleFillCompany: boolean = false;
  isVisibleBillLater: boolean = false;
  isVisibleFillOrderData: boolean = false;

  constructor(mainStoreUI: IStoreUI) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeParent = mainStoreUI;
  }

  // functions

  openFillProfilePopup() {
    this.setIsVisibleFillProfile(true);
    disableScroll();
  }

  openFillCompany() {
    this.setIsVisibleFillCompany(true);
    disableScroll();
  }

  openBillLater() {
    this.setIsVisibleBillLater(true);
    disableScroll();
  }

  openFillOrderingData() {
    this.setIsVisibleFillOrderData(true);
    disableScroll();
  }

  // setters

  setIsVisibleFillCompany(value: boolean) {
    this.isVisibleFillCompany = value;
  }

  setIsVisibleFillProfile(value: boolean) {
    this.isVisibleFillProfile = value;
  }

  setIsVisibleBillLater(value: boolean) {
    this.isVisibleBillLater = value;
  }

  setIsVisibleFillOrderData(value: boolean) {
    this.isVisibleFillOrderData = value;
  }
}
