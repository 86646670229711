import { makeAutoObservable } from "mobx";

import {
  getAgreementPdf,
  getAgreements,
} from "../../../../api/Documents/Agreements";

import {
  IDocumentsAgreement,
  IFilePdf,
  IGeneratedAgreement,
  IUploadedAgreement,
} from "./../../../../interfaces/Documents/index";

import { TResponseApi } from "../../../../api/types";
import { Store } from "../../../types";

export class ModelAgreements implements Store.IAgreementsStore {
  generatedAgreements: IGeneratedAgreement[] = [];
  uploadedAgreements: IUploadedAgreement[] = [];

  agreementPdfById: string = "";

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  //functions

  *getAgreements(bookingId: number) {
    let response: TResponseApi<IDocumentsAgreement> = yield getAgreements({
      bookingId,
    });
    if (response.isSuccess && response.data !== null) {
      this.setGeneratedAgreements(response.data.agreements);
      this.setUploadedAgreements(response.data.documentAgreements);
    }
  }

  *getAgreementPdf(agreementId: number) {
    let response: TResponseApi<IFilePdf> = yield getAgreementPdf({
      agreementId,
    });

    if (response.isSuccess && response.data !== null) {
      this.setAgreementPdfById(response.data.file);
    }
  }

  //setters
  setGeneratedAgreements(value: IGeneratedAgreement[]) {
    this.generatedAgreements = value;
  }

  setUploadedAgreements(value: IUploadedAgreement[]) {
    this.uploadedAgreements = value;
  }

  setAgreementPdfById(value: string) {
    this.agreementPdfById = value;
  }
}
