import { ApiManager } from "../..";

import { IAct } from "./../../../interfaces/Documents/index";
import { ICommonResponse } from "../../../interfaces";

import {
  DOCUMENT_ACT,
  DOCUMENT_RECONCILIATION_ACT,
} from "../../../constants/api";

import { TResponseApi } from "../../types";

export async function getActs(params: {
  bookingId: number;
}): Promise<TResponseApi<IAct[]>> {
  return await ApiManager.request<IAct[]>(
    {
      url: DOCUMENT_ACT,
      method: "GET",
      params: params,
    },
    true
  );
}

export async function generateReconciliationAct(params: {
  startDate: string;
  endDate: string;
  bookingId: number;
}): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: DOCUMENT_RECONCILIATION_ACT,
      method: "POST",
      params: params,
    },
    true
  );
}
