import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { disableScroll, enableScroll } from "../../../../../helpers/modalsFunc";
import { SCREENS } from "../../../../../navigation/endpoints";
import {
  ERROR_LIMIT_ADULTS_BY_CHILDREN,
  GENDER_FEMALE,
  GENDER_FEMALE_LABEL,
  GENDER_MALE,
  GENDER_MALE_LABEL,
  OPTION_GENDER_FEMALE,
  OPTION_GENDER_MALE,
  OPTION_PASSPORT_FALSE,
  OPTION_PASSPORT_TRUE,
  PAGE_ORDERING_DATA_BY_ID,
  PAGE_RESIDENTS_BY_ID,
  PERSONAL_TAB_ID_PETS,
  PERSONAL_TAB_ID_RENTER,
  PERSONAL_TAB_ID_TENANTS,
  RENTER_COUNT,
} from "../../../../../constants";

import { IStoreUI, ITenantStoreUI } from "./interfaces";
import {
  IPetSend,
  ITenant,
  ITenantsAllSend,
  ITenantSendCommon,
} from "../../../../../interfaces/Tenants";
import { IOptionCommon, ITab } from "../../../../../interfaces";
import { ITypes } from "../../../../../interfaces/Catalog";
import { IPetStoreUI } from "../../../../OrderingData/storeUI/interfaces";

import {
  EBookingCalendarStatus,
  EBookingType,
  EFormType,
  ETabsResidents,
  TGender,
} from "../../../../../types";

import { Store } from "../../../../../stores/types";

import { TenantStoreUI } from "./tenantStoreUI";

interface ICurrentTenantId {
  tenantId: number;
  localId: number;
}

const COUNT_RENTER = 1;
export class StoreUI implements IStoreUI {
  storeBookingResidents: Store.IBookingResidents;
  storeUser: Store.IUser;
  storeRoot: Store.IRootStore;
  storeMyApartment: Store.IMyApartment;
  navigate: NavigateFunction;
  isLoading: boolean = true;
  isDeleteAddTenant: boolean = false;
  isModalSave: boolean = false;
  isLoadingSend: boolean = false;

  currentTenantId: ICurrentTenantId = {
    tenantId: 0,
    localId: 0,
  };
  validationErrors: string[] = [];

  // жильцы из форм
  tenants: ITenantStoreUI[] = [];
  renter: ITenantStoreUI | null = null;
  petsForm: IPetStoreUI[] = [];

  // формы + бэк на отправку
  tenantsForSend: ITenantSendCommon[] = [];
  petForSend: IPetSend[] = [];

  tenantsErrors: string[] = [];

  // опции для селектов
  genderOptions: IOptionCommon[] = [
    { id: 1, label: GENDER_MALE_LABEL, value: GENDER_MALE },
    { id: 2, label: GENDER_FEMALE_LABEL, value: GENDER_FEMALE },
  ];
  veterinaryOptions: IOptionCommon[] = [
    { id: 1, label: "Присутствует", value: true },
    { id: 2, label: "Отсутствует", value: false },
  ];

  tabName: string = "";

  bookingId: string | null = null;

  constructor(
    storeRoot: Store.IRootStore,
    navigate: NavigateFunction,
    tabName: string
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.navigate = navigate;
    this.storeRoot = storeRoot;
    this.storeUser = storeRoot.userStore;
    this.storeBookingResidents = storeRoot.storeBookingResidents;
    this.storeMyApartment = storeRoot.storeMyApartment;
    this.tabName = tabName;
  }

  // GETTERS

  get pets() {
    return this.storeBookingResidents.pets?.map((pet) => ({
      ...pet,
      veterinaryPassport: this.getVetPassportOption(pet.veterinaryPassport),
    }));
  }

  get errorsAddTenant() {
    return this.storeBookingResidents.serverErrors.addTenant.concat(
      this.validationErrors
    );
  }

  get personalType() {
    return this.storeUser.profile.personalType;
  }

  get renterAgreement() {
    return this.storeBookingResidents.tenants[0];
  }

  get isDisabledAll() {
    if (this.storeMyApartment.apartmentDetail?.bookingCalendar.status) {
      return (
        this.storeMyApartment.apartmentDetail?.bookingCalendar.status !==
          EBookingCalendarStatus.Waiting ||
        Boolean(
          this.storeMyApartment.apartmentDetail?.bookingCalendar.arrivalDate
        )
      );
    }
    return true;
  }

  get countTenants() {
    return this.tenants.length;
  }

  get capacity() {
    return (
      (this.storeMyApartment.apartmentDetail?.bookingCalendar?.capacity ??
        COUNT_RENTER) - COUNT_RENTER
    );
  }

  get isVisibleAdded() {
    return this.capacity > this.countTenants;
  }

  get disabledAddTenant() {
    return this.tenants.length >= this.capacity;
  }

  get canPets() {
    return (
      this.storeMyApartment.apartmentDetail?.bookingCalendar.canPets ?? false
    );
  }

  get canArrivalDate() {
    return Boolean(
      this.storeMyApartment.apartmentDetail?.bookingCalendar?.arrivalDate
    );
  }

  get tabs(): ITab[] {
    let _tabs: ITab[] = [
      {
        id: PERSONAL_TAB_ID_RENTER,
        label: "Арендатор",
        tabName: ETabsResidents.Renter,
      },
      {
        id: PERSONAL_TAB_ID_TENANTS,
        label: `Жильцы ${this.countTenants}/${this.capacity}`,
        tabName: ETabsResidents.Tenants,
      },
    ];

    if (this.canPets) {
      _tabs.push({
        id: PERSONAL_TAB_ID_PETS,
        label: "Животные",
        tabName: ETabsResidents.Pets,
        miniTabWarningText:
          this.pets.length > 0
            ? ""
            : "Вы  выбрали проживание без животных при заключении договора",
        disabled: this.pets.length === 0,
      });
    }

    return _tabs;
  }

  // FUNCTIONS

  *init(bookingId: string) {
    this.setIsLoading(true);
    yield this.storeRoot.getBookingsList(EBookingType.Active);

    if (
      bookingId &&
      this.storeRoot.checkBookingIdByUrl(bookingId, EBookingType.Active)
    ) {
      this.setBookingId(bookingId);
      yield this.storeBookingResidents.getResidents(Number(bookingId));
      yield this.storeRoot.storeAgreements.getAgreements(Number(bookingId));

      if (!this.storeMyApartment.apartmentDetail?.bookingCalendar?.capacity) {
        yield this.storeMyApartment.getBookingById(Number(bookingId));
      }

      if (!this.checkUrlByPets(this.canPets)) {
        this.navigate(SCREENS.SCREEN_404);
      }

      this.writeCountTenantsForCounter(true);

      // создаем формы для арендатора/жильцов и прокидываем в них данные с бэка
      if (this.storeBookingResidents.tenants.length > 0) {
        this.storeBookingResidents.tenants.forEach((tenant, index) => {
          // если жилец помечен как арендатор
          if (tenant.renter) {
            this._createRenter(tenant);
          } else {
            // иначе это жилец
            this._createTenant(index, tenant);
          }
        });
      }
    } else {
      this.navigate(SCREENS.SCREEN_404);
    }

    this.setIsLoading(false);
  }

  checkUrlByPets(canPets: boolean) {
    let urlItems = window.location.href.split("/");
    let lastItem = urlItems[urlItems.length - 1];
    // если url содержит ссылку на раздел pets
    if (lastItem === ETabsResidents.Pets) {
      // url валидный только если для квартиры доступпны животные
      return canPets;
    }
    return true;
  }

  writeCountTenantsForCounter(isInit: boolean = false) {
    if (isInit) {
      if (!this.storeRoot.currentCountTenantsFromMyBooking) {
        this.storeRoot.setCurrentCountTenantsFromMyBooking(
          this.countTenants + RENTER_COUNT
        );
      }
    } else {
      this.storeRoot.setCurrentCountTenantsFromMyBooking(
        this.countTenants + RENTER_COUNT
      );
    }
  }

  // получить подсказки по строке
  *getHintByString(value: string) {
    yield this.storeRoot.userStore.getHintByString(value);

    if (this.storeRoot.userStore.hintStrings.length > 0) {
      return this.storeRoot.userStore.hintStrings;
    }
    return [];
  }

  //----------- onClick

  *onClickSendAllTenants(bookingId: number) {
    this.clearAllErrors();
    this.validateAllTenants();

    if (this.checkValidateAll()) {
      this.addTenantsForSend();
      this.addPetForSend();

      let params: ITenantsAllSend = {
        tenants: this.tenantsForSend,
      };

      if (this.petForSend.length > 0) {
        params.pets = this.petForSend;
      }

      this.setIsLoadingSend(true);

      yield this.storeBookingResidents.addTenant(params, bookingId);

      this.setIsLoadingSend(false);
      if (this.storeBookingResidents.isSuccessAddTenant) {
        this.openSaveModal();
        this.writeCountTenantsForCounter();

        this._clearAllTenants();

        yield this.init(String(bookingId));
      }
      return this.storeBookingResidents.isSuccessAddTenant;
    }
  }

  *onClickDeleteTenant(
    tenantId: number,
    tenantLocalId: number,
    bookingId: string
  ) {
    this.setIsLoading(true);
    let _tenantForm = this.tenants.find(
      (tenant) => tenant.localId === tenantLocalId
    );

    let isFreshForm = _tenantForm?.idTenant ? false : true;

    if (isFreshForm) {
      // если новая форма, то просто удаляем из вью и стора
      this._deleteLocalForm(tenantLocalId);
    } else {
      yield this.storeBookingResidents.deleteTenant(
        String(bookingId),
        tenantId
      );

      if (this.storeBookingResidents.isSuccessDeleteTenant) {
        this.closeDeleteTenantModal();
        this.writeCountTenantsForCounter();

        this._deleteLocalForm(tenantLocalId);
      }
    }

    this.writeCountTenantsForCounter();
    this.setIsLoading(false);
  }

  onClickHintAddress(value: string, typeForm: EFormType, idTenant?: number) {
    // АДРЕС РЕГИСТРАЦИИ клик по строке подсказки
    if (typeForm === EFormType.Tenant && (idTenant || idTenant === 0)) {
      let _tenant = this.searchTenant(idTenant);

      if (_tenant) {
        _tenant.changeRegistrationAddress(value);
        _tenant.setHintStringsAddress([]);
      }
    }

    if (typeForm === EFormType.Renter && this.renter) {
      this.renter.changeRegistrationAddress(value);
      this.renter.setHintStringsAddress([]);
    }
  }

  onClickHintPlaceBirth(value: string, typeForm: EFormType, idTenant?: number) {
    // МЕСТО РОЖДЕНИЯ клик по строке подсказки
    if (typeForm === EFormType.Tenant && (idTenant || idTenant === 0)) {
      let _tenant = this.searchTenant(idTenant);

      if (_tenant) {
        _tenant.changePlaceBirth(value);
        _tenant.setHintStringsPlaceBirth([]);
      }
    }

    if (typeForm === EFormType.Renter && this.renter) {
      this.renter.changePlaceBirth(value);
      this.renter.setHintStringsPlaceBirth([]);
    }
  }

  onClickTab(id: string, tabName: string) {
    this.navigate(PAGE_RESIDENTS_BY_ID(id, tabName));
    this.clearAllErrors();
  }

  onClickAddTenant() {
    if (this.disabledAddTenant) {
      this.addTenantsError(ERROR_LIMIT_ADULTS_BY_CHILDREN);
    } else {
      this.clearTenantsErrors();
      // добавление новой формы по кнопке
      if (this.tenants.length >= 1) {
        // достаем id последнего жильца и прибавляем 1
        let lastElem = this.tenants[this.tenants.length - 1];
        let lastId = lastElem.localId;
        this._createTenant(lastId + 1);
      } else {
        this._createTenant(0);
      }
    }
    this.writeCountTenantsForCounter();
  }

  // ------- private create / delete / add

  // первоначальное создание формы арендатора и заполнение данными с бэка
  private _createRenter(tenantFromBack: ITenant) {
    this.renter = new TenantStoreUI({ isRenterMode: true });
    this.writeInfoByBackend(this.renter, tenantFromBack, true);
  }

  // создание формы арендатора и заполнение данными с бэка, если жильцы пришли
  private _createTenant(id: number, tenantFromBack: ITenant | null = null) {
    // проверка на наличие формы с таким же id
    let isExists = this.tenants.find((tenant) => tenant.localId === id);

    if (!isExists) {
      let tenant = new TenantStoreUI({ isRenterMode: false }); // создать экземпляр Формы жильца
      tenant.setLocalId(id); // записываем id формы жильца, чтобы потом обращаться по нему

      if (tenantFromBack) {
        // если в созданную форму нужно прокинуть данные с бэка
        this.writeInfoByBackend(tenant, tenantFromBack, false);
      }
      // отправляем в массив форм
      this.tenants.push(tenant);
    }
  }

  private _deleteLocalForm(tenantId: number) {
    let _tenant = this.tenants.find((tenant) => tenant.localId === tenantId);

    if (_tenant) {
      let myIndex = this.tenants.indexOf(_tenant);
      if (myIndex !== -1) {
        this.tenants.splice(myIndex, 1);
        this.closeDeleteTenantModal();
      }
    }
  }

  addTenantsForSend() {
    // добавляем данные для отправки на бэк
    // форматируем объекты форм под запрос бэка
    if (this.tenants.length > 0) {
      let tenantsForSend: ITenantSendCommon[] = this.tenants.map((tenant) => {
        return {
          localId: tenant.localId,
          lastName: tenant.lastName,
          firstName: tenant.firstName,
          middleName: tenant.middleName,
          phone: tenant.phone.replaceAll(" ", ""),
          birthdate: this.convertDate(tenant.birthdate),
          series: tenant.series,
          number: tenant.number,
          dateIssue: this.convertDate(tenant.dateIssue),
          issuedBy: tenant.issuedBy,
          registrationAddress: tenant.registrationAddress,
          relationTenant: tenant.relationTenant,
          placeBirth: tenant.placeBirth,
          gender: tenant.gender?.value,
        };
      });

      // добавляем готовые объекты в массив для отправки
      tenantsForSend.forEach((tenant) => this._addTenantForSend(tenant));
    }
  }

  private _addTenantForSend(value: ITenantSendCommon) {
    let isExists = this.tenantsForSend.find(
      (tenant) => tenant.localId === value.localId
    );
    if (!isExists) {
      this.tenantsForSend.push(value);
    }
  }

  addPetForSend() {
    // добавляем данные для отправки на бэк
    /*  let pets: IPetSend[] = this.pet.map((pet) => {
      return {
        type: pet.type,
        breed: pet.breed,
        veterinaryPassport: Boolean(pet.veterinaryPassport?.value),
      };
    }); */
    /*  this.setPetForSend(pets); */
  }

  private _clearAllTenants() {
    this.setTenants([]);
    this.setTenantsForSend([]);
  }

  //------------------ helpers

  searchTenant(idTenant: number) {
    return this.tenants.find((tenant) => tenant.localId === idTenant);
  }

  getGenderOption(value: TGender) {
    switch (value) {
      case GENDER_MALE:
        return OPTION_GENDER_MALE;
      case GENDER_FEMALE:
        return OPTION_GENDER_FEMALE;
      default:
        return null;
    }
  }

  getVetPassportOption(value: boolean) {
    return value ? OPTION_PASSPORT_TRUE : OPTION_PASSPORT_FALSE;
  }

  convertDate(date: string) {
    if (date) {
      let dateArr = date.split(".").reverse();
      return dateArr.join("-");
    }
    return "";
  }

  convertOldDate(date: string) {
    if (date && date.length) {
      let arr = date.split("-");
      let arrReverse = arr.reverse();

      return arrReverse.join(".");
    }
  }

  writeInfoByBackend(
    // запись данных с бэка в форму
    tenantForm: ITenantStoreUI, // экземпляр формы
    tenantFromBack: ITenant, // данные с бэка
    isRenterMode: boolean // режим арендатора
  ) {
    if (isRenterMode) {
      tenantForm.changeLastName(tenantFromBack.lastName ?? "");
      tenantForm.changeFirstName(tenantFromBack.firstName ?? "");
      tenantForm.changeMiddleName(tenantFromBack.middleName ?? "");
      tenantForm.changeDateBirth(
        this.convertOldDate(tenantFromBack.birthdate) ?? ""
      );
      tenantForm.changePhone(tenantFromBack.phone ?? "");
      tenantForm.changeGender(
        this.getGenderOption(tenantFromBack.gender) ?? null
      );
      tenantForm.changePlaceBirth(tenantFromBack.placeBirth ?? "");
      tenantForm.changeSeries(tenantFromBack.series ?? "");
      tenantForm.changeNumberPassport(tenantFromBack.number ?? "");
      tenantForm.changeDateIssue(
        this.convertOldDate(tenantFromBack.dateIssue) ?? ""
      );
      tenantForm.changeIssuedBy(tenantFromBack.issuedBy ?? "");
      tenantForm.changeSubdivisionCode(tenantFromBack.subdivisionCode ?? "");
      tenantForm.changeRegistrationAddress(
        tenantFromBack.registrationAddress ?? ""
      );
    } else {
      tenantForm.changeLastName(tenantFromBack.lastName ?? "");
      tenantForm.changeFirstName(tenantFromBack.firstName ?? "");
      tenantForm.changeMiddleName(tenantFromBack.middleName ?? "");
      tenantForm.changeDateBirth(
        this.convertOldDate(tenantFromBack.birthdate) ?? ""
      );
      tenantForm.changeGender(
        this.getGenderOption(tenantFromBack.gender) ?? null
      );
      tenantForm.changePhone(tenantFromBack.phone ?? "");
      tenantForm.changeRelationTenant(tenantFromBack.relationTenant ?? "");
      tenantForm.changePlaceBirth(tenantFromBack.placeBirth ?? "");
      tenantForm.changeSeries(tenantFromBack.series ?? "");
      tenantForm.changeNumberPassport(tenantFromBack.number ?? "");
      tenantForm.changeDateIssue(
        this.convertOldDate(tenantFromBack.dateIssue) ?? ""
      );
      tenantForm.changeIssuedBy(tenantFromBack.issuedBy ?? "");
      tenantForm.changeRegistrationAddress(
        tenantFromBack.registrationAddress ?? ""
      );
      tenantForm.setIdTetant(tenantFromBack.id ?? 0);
    }
  }

  //------------------ modals

  openDeleteTenantModal(tenantId: number, localId: number) {
    this.isDeleteAddTenant = true;
    this.setCurrentTenantId({
      tenantId,
      localId,
    });
    disableScroll();
  }

  closeDeleteTenantModal() {
    this.isDeleteAddTenant = false;
    enableScroll();
  }

  openSaveModal() {
    this.isModalSave = true;
    disableScroll();
  }

  closeSaveModal() {
    this.isModalSave = false;
    enableScroll();
  }

  //--------- navigation

  toApartments = () => {
    this.navigate(SCREENS.SCREEN_APARTMENTS);
  };

  toOrderingData() {
    if (this.bookingId) {
      this.navigate(PAGE_ORDERING_DATA_BY_ID(this.bookingId));
    }
  }

  //--------- validation

  validateAllTenants() {
    this.tenants.forEach((tenant) => {
      tenant.validateAll();
    });
  }

  checkValidateAll() {
    let validArray: Array<boolean> = [];

    this.tenants.forEach((tenant) => {
      validArray.push(tenant.checkValidateAll());
    });

    return !validArray.includes(false);
  }

  addTenantsError(value: string) {
    this.tenantsErrors = [...this.tenantsErrors, value];
  }

  clearTenantsErrors() {
    this.tenantsErrors = [];
  }

  clearAllErrors() {
    this.clearTenantsErrors();
  }
  //--------- changes

  changeLastName(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeLastName(value);
    }
  }

  changeFirstName(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeFirstName(value);
    }
  }

  changeMiddleName(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeMiddleName(value);
    }
  }

  changePhone(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changePhone(value);
    }
  }

  changeDateBirth(date: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);

    if (_tenant) {
      _tenant.changeDateBirth(date);
    }
  }

  changeSeries(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeSeries(value);
    }
  }

  changeNumberPassport(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeNumberPassport(value);
    }
  }

  changeDateIssue(date: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);

    if (_tenant) {
      _tenant.changeDateIssue(date);
    }
  }

  changeGender(value: ITypes | null, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeGender(value);
    }
  }

  changeIssuedBy(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeIssuedBy(value);
    }
  }

  changeSubdivisionCode(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeSubdivisionCode(value);
    }
  }

  changeRelationTenant(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeRelationTenant(value);
    }
  }

  *changeAddress(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);
    if (_tenant) {
      _tenant.changeRegistrationAddress(value);

      if (value.length > 0) {
        let _hints: string[] = yield this.getHintByString(value);
        _tenant.setHintStringsAddress(_hints);
      }
    }
  }

  *changePlaceBirth(value: string, idTenant: number) {
    let _tenant = this.searchTenant(idTenant);

    if (_tenant) {
      _tenant.changePlaceBirth(value);

      if (value.length > 0) {
        let _hints: string[] = yield this.getHintByString(value);
        _tenant.setHintStringsPlaceBirth(_hints);
      }
    }
  }

  // SETTERS
  setPetForSend(value: IPetSend[]) {
    this.petForSend = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setCurrentTenantId(value: ICurrentTenantId) {
    this.currentTenantId = value;
  }

  setTenants(value: ITenantStoreUI[]) {
    this.tenants = value;
  }

  setTenantsForSend(value: ITenantSendCommon[]) {
    this.tenantsForSend = value;
  }

  setBookingId(value: string | null) {
    this.bookingId = value;
  }

  setIsLoadingSend(value: boolean) {
    this.isLoadingSend = value;
  }
}
