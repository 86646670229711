import { FC } from "react";

import { Button } from "../Button";

import styles from "./styles/empty.module.scss";

interface IProps {
  title: string;
  titleBtn: string;
  Icon: string;
  onClick: () => void;
}

export const Empty: FC<IProps> = ({
  title = "",
  titleBtn = "",
  Icon = "",
  onClick = () => {},
}) => {
  return (
    <div className={styles.content}>
      <div className={styles.content__img}>
        <Icon />
      </div>
      <p className={styles.content__title}>{title}</p>
      <Button
        className={styles.content__button}
        title={titleBtn}
        theme="gold"
        onClick={onClick}
      />
    </div>
  );
};
