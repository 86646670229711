import { makeAutoObservable } from "mobx";

import { IRooms } from "../../../interfaces/Catalog";
import { IOptionsStoreUI } from "./interfaces";

import { Store } from "./../../../stores/types/index";

import { ROOMS_SELECT_ALL } from "../../../constants";

export class StoreOptions implements IOptionsStoreUI {
  storeFilters: Store.ICatalogFilters;
  isLoading: boolean = true;

  roomsAll: IRooms = {
    id: 1000,
    label: ROOMS_SELECT_ALL,
  };

  constructor(storeFilters: Store.ICatalogFilters) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeFilters = storeFilters;
  }

  get rooms() {
    let _roomsAll = [this.roomsAll];

    return _roomsAll.concat(this.storeFilters.rooms).map((room) => {
      return {
        value: room.label,
        label: room.label,
        id: room.id,
      };
    });
  }

  *init() {
    this.setIsLoading(true);
    yield this.storeFilters.getRooms();
    this.setIsLoading(false);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
