import { ApiManager } from "..";

import { NEWS, NEWS_BY_ID } from "../../constants/api";

import { INewsDetails, INewsResponse } from "../../interfaces/News";

import { TResponseApi } from "../types";

export async function getNews(params: {
  limit: number;
}): Promise<TResponseApi<INewsResponse>> {
  return await ApiManager.request<INewsResponse>(
    {
      url: NEWS,
      method: "GET",
      params: params,
    },
    true
  );
}

export async function getNewsDetails(
  newsId: number
): Promise<TResponseApi<INewsDetails>> {
  return await ApiManager.request<INewsDetails>({
    method: "GET",
    url: NEWS_BY_ID(newsId),
  });
}
