import { FC } from "react";

import { ReactComponent as Active } from "../../assets/images/active.svg";
import { ReactComponent as Inactive } from "../../assets/images/inactive.svg";

import styles from "./styles/checkbox.module.scss";

interface IProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

export const CheckBox: FC<IProps> = ({
  checked = false,
  setChecked = () => {},
}) => {
  return (
    <>
      {checked ? (
        <Active
          className={styles.checkBox}
          onClick={() => setChecked(!checked)}
        />
      ) : (
        <Inactive
          className={`${styles.checkBox} ${styles.checkBox__inactive}`}
          onClick={() => setChecked(!checked)}
        />
      )}
    </>
  );
};
