import { ApiManager } from "../index";

import {
  IApartmentFromBasket,
  IApartmentDetail,
  IReviewsApartment,
} from "../../interfaces/Apartment";
import { TResponseApi } from "../types";

import {
  APARTMENT_BY_ID,
  REVIEWS,
  BASKET_APARTMENT,
  APARTMENT_VIEW,
} from "../../constants/api";
import { ICommonResponse } from "../../interfaces";

export async function getApartment(
  apartmentId: number
): Promise<TResponseApi<IApartmentDetail>> {
  return await ApiManager.request<IApartmentDetail>({
    method: "GET",
    url: APARTMENT_BY_ID(apartmentId),
  });
}

export async function getReviews(params: {
  apartmentId: number;
  limit?: number;
}): Promise<TResponseApi<IReviewsApartment>> {
  return await ApiManager.request<IReviewsApartment>({
    method: "GET",
    url: REVIEWS,
    params: params,
  });
}

export async function addApartmentToBasket(
  params: IApartmentFromBasket
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      method: "POST",
      url: BASKET_APARTMENT,
      data: params,
    },
    true
  );
}

export async function addView(params: {
  apartmentId: number;
}): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      method: "POST",
      url: APARTMENT_VIEW,
      data: params,
    },
    true
  );
}
