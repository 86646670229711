import { makeAutoObservable } from "mobx";

import { getBills } from "../../../../api/Documents/Bills";

import { IBill } from "./../../../../interfaces/Documents/index";

import { TResponseApi } from "../../../../api/types";
import { Store } from "../../../types";
import { EStatusBill } from "../../../../types";

export class ModelBills implements Store.IBillsStore {
  bills: IBill[] = [];

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  *getBills(bookingId: number) {
    let response: TResponseApi<IBill[]> = yield getBills({ bookingId });

    if (response.isSuccess && response.data !== null) {
      let readyBills: IBill[] = response.data.map((item) => ({
        ...item,
        status: item.status
          ? this.getStatusPaymentLowerCase(item.status)
          : null,
      }));

      this.setBills(readyBills);
    }
  }

  getStatusPaymentLowerCase(status: string): EStatusBill | null {
    let _status = status.toLocaleLowerCase();

    switch (_status) {
      case EStatusBill.NotPaid:
        return EStatusBill.NotPaid;
      case EStatusBill.Paid:
        return EStatusBill.Paid;
      case EStatusBill.ParticallyPaid:
        return EStatusBill.ParticallyPaid;
      default:
        return null;
    }
  }

  setBills(value: IBill[]) {
    this.bills = value;
  }
}
