import { makeAutoObservable } from "mobx";

import {
  ERROR_MIN_CHARS_ONE,
  ERROR_TEXT_EMPTY,
  ERROR_TEXT_PHONE,
} from "../../../constants";
import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";

import { IPartnerRequestErrors, IStoreUI } from "./interfaces";

import { Store } from "../../../stores/types";

export class StoreUI implements IStoreUI {
  storePartner: Store.IPartner;
  isModalPartner: boolean = false;
  isLoading: boolean = false;
  isModalSave: boolean = false;
  visibleAllPartners: boolean = false;

  name: string = "";
  organization: string = "";
  phone: string = "";
  text: string = "";

  errors: IPartnerRequestErrors = {
    name: [],
    organization: [],
    phone: [],
    text: [],
  };

  constructor(storePartner: Store.IPartner) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storePartner = storePartner;
  }

  get partners() {
    return this.storePartner.partners;
  }

  get partnersDescription() {
    return this.storePartner.partnersDescription;
  }

  *init() {
    enableScroll();
    this.setIsLoading(true);
    yield this.storePartner.getPartners();
    yield this.storePartner.getPartnerDescription();
    this.setIsLoading(false);
  }

  *sendPartnerRequest() {
    this.validateName();
    this.validatePhone();
    this.validateOrganization();
    this.validateText();

    if (this.checkValidAll()) {
      this.setIsLoading(true);
      let response: boolean = yield this.storePartner.sendPartnerRequest(
        this.name,
        this.organization,
        this.phone,
        this.text
      );

      if (response) {
        this.closePartnerModal();
        this.openSaveModal();
      }
      this.setIsLoading(false);

      return true;
    }

    return false;
  }

  validateName() {
    if (this.name.trim().length === 0) {
      this.errors.name = [...this.errors.name, ERROR_TEXT_EMPTY];

      return false;
    } else {
      this.errors.name = [];
    }

    if (this.name.length < 1) {
      this.errors.name = [...this.errors.name, ERROR_MIN_CHARS_ONE];
      return false;
    } else {
      this.errors.name = [];
    }

    return true;
  }

  validatePhone() {
    if (this.phone.length === 0) {
      this.errors.phone = [...this.errors.phone, ERROR_TEXT_EMPTY];
      return false;
    } else {
      this.errors.phone = [];
    }

    if (this.phone.replaceAll(" ", "").length < 12) {
      this.errors.phone = [...this.errors.phone, ERROR_TEXT_PHONE];

      return false;
    } else {
      this.errors.phone = [];
    }
    return true;
  }

  validateOrganization() {
    if (this.organization.trim().length === 0) {
      this.errors.organization = [
        ...this.errors.organization,
        ERROR_TEXT_EMPTY,
      ];
      return false;
    } else {
      this.errors.organization = [];
    }

    if (this.organization.length < 1) {
      this.errors.organization = [
        ...this.errors.organization,
        ERROR_MIN_CHARS_ONE,
      ];
      return false;
    } else {
      this.errors.organization = [];
    }

    return true;
  }

  validateText() {
    if (this.text.trim().length === 0) {
      this.errors.text = [...this.errors.text, ERROR_TEXT_EMPTY];
      return false;
    } else {
      this.errors.text = [];
    }

    if (this.text.length < 1) {
      this.errors.text = [...this.errors.text, ERROR_MIN_CHARS_ONE];
      return false;
    } else {
      this.errors.text = [];
    }

    return true;
  }

  checkValidAll() {
    return (
      this.validateText() &&
      this.validateName() &&
      this.validateOrganization() &&
      this.validatePhone()
    );
  }

  clearForm() {
    this.setName("");
    this.setOrganization("");
    this.setPhone("");
    this.setText("");
    this.errors.name = [];
    this.errors.organization = [];
    this.errors.phone = [];
    this.errors.text = [];
  }

  onChangeName(value: string) {
    this.setName(value);

    if (this.errors.name.length) {
      this.errors.name = [];
    }
  }

  onChangeOrganization(value: string) {
    this.setOrganization(value);

    if (this.errors.organization.length) {
      this.errors.organization = [];
    }
  }

  onChangePhone(value: string) {
    this.setPhone(value.replaceAll(" ", ""));

    if (this.errors.phone.length) {
      this.errors.phone = [];
    }
  }

  onChangeText(value: string) {
    this.setText(value);

    if (this.errors.text.length) {
      this.errors.text = [];
    }
  }

  openPartnerModal() {
    this.isModalPartner = true;
    disableScroll();
  }

  closePartnerModal() {
    this.isModalPartner = false;
    this.clearForm();
    enableScroll();
  }

  openAllPartners() {
    this.visibleAllPartners = !this.visibleAllPartners;
  }

  openSaveModal() {
    this.isModalSave = true;
  }

  closeSaveModal() {
    this.isModalSave = false;
  }

  setName(value: string) {
    this.name = value;
  }

  setOrganization(value: string) {
    this.organization = value;
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setText(value: string) {
    this.text = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}
