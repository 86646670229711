import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Input } from "../../Input";
import { Button } from "../../Button";
import { InputPhone } from "../../InputPhone";
import { Loader } from "../../Loader";

import { SCREENS } from "../../../navigation/endpoints";
import { stopPropagation } from "../../../helpers/modalsFunc";

import { StoreFeedback } from "../../../stores/ui/FeedbackModal/feedback";

import close from "../../../assets/images/close.svg";

import styles from "./styles/feedback.module.scss";

interface IProps {
  storeUI: StoreFeedback;
}

export const FeedBack: FC<IProps> = observer(({ storeUI }) => {
  let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
    useState<EventTarget | null>(null);

  let _onClose = () => {
    if (backgroundOnDownTarget) {
      storeUI.closeFeedback();
    }
    setBackgroundOnDownTarget(null);
  };

  return (
    <>
      {storeUI.isVisible && (
        <div
          className={styles.feedback}
          onClick={_onClose}
          onMouseDown={(e) => {
            setBackgroundOnDownTarget(e.target);
          }}
        >
          <div className={styles.feedback__popupWrapper}>
            <div
              className={styles.feedback__popup}
              onMouseDown={stopPropagation}
              onClick={stopPropagation}
            >
              <img
                onClick={storeUI.closeFeedback}
                className={styles.feedback__close}
                src={close}
                alt="close"
              />
              <form className={styles.feedback__contentWrapper}>
                <p className={styles.feedback__title}>
                  Заказать обратный звонок
                </p>
                {storeUI.isLoading && <Loader />}

                <Input
                  value={storeUI.name}
                  onChange={storeUI.changeName}
                  label="Как вас зовут?"
                  placeholder="Иванов Петр Алексеевич"
                  className={styles.feedback__input}
                  errors={storeUI.errorsName}
                  maxLength={50}
                />

                <InputPhone
                  value={storeUI.phone}
                  onChange={storeUI.changePhone}
                  label="Ваш номер телефона"
                  errors={storeUI.errorsPhone}
                  className={styles.feedback__input}
                />

                <p className={styles.feedback__politics}>
                  Нажимая кнопку «Отправить» вы подтверждаете свое согласие на{" "}
                  <Link
                    to={SCREENS.SCREEN_TEXT_PERSONAL_DATA}
                    className={styles.feedback__personal}
                    target="_blank"
                  >
                    обработку персональных данных
                  </Link>
                </p>

                <Button
                  title="ОТПРАВИТЬ"
                  type="submit"
                  theme="gold"
                  onClick={storeUI.sendFeedback}
                  disabled={storeUI.isLoading}
                  isDisabledStyle={storeUI.phone === "" || storeUI.name === ""}
                  className={styles.feedback__button}
                />
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
