import { makeAutoObservable } from "mobx";

import { getNews, getNewsDetails } from "../../../api/News";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { INews, INewsDetails, INewsResponse } from "../../../interfaces/News";

export class ModelNews implements Store.IAllNews {
  news: INews[] = [];
  limit: number = 0;
  total: number = 0;
  newsDetails: INewsDetails = {
    id: 0,
    image: "",
    name: "",
    text: "",
    created_at: "",
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *getNews(limit: number) {
    let response: TResponseApi<INewsResponse> = yield getNews({
      limit,
    });

    if (response.isSuccess && response.data !== null) {
      if (response.data.list?.length > 0) {
        this.setNews(response.data.list);
      }

      this.setLimit(response.data.limit);
      this.setTotal(response.data.total);
    }
  }

  *getNewsDetails(newsId: number) {
    let response: TResponseApi<INewsDetails> = yield getNewsDetails(newsId);
    if (response.isSuccess && response.data !== null) {
      this.setNewsDetails(response.data);
    }
  }

  setLimit(value: number) {
    this.limit = value;
  }

  setTotal(value: number) {
    this.total = value;
  }

  setNews(value: INews[]) {
    this.news = value;
  }

  setNewsDetails(value: INewsDetails) {
    this.newsDetails = value;
  }
}
