import { makeAutoObservable } from "mobx";

import { getContacts } from "../../../api/Contacts";
import { getSocials } from "../../../api/MainPage";
import { TResponseApi } from "../../../api/types";
import { IMainSocials } from "../../../interfaces/MainPage";
import { Store } from "../../types";

import { IContact } from "./../../../interfaces/Contacts/index";

export class ModelContacts implements Store.IContacts {
  contacts: IContact[] = [];
  socials: IMainSocials[] = [];

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  // functions

  *getSocials() {
    let response: TResponseApi<IMainSocials[]> = yield getSocials();
    if (response.isSuccess && response.data !== null) {
      this.setSocials(response.data);
    }
  }

  *getContacts() {
    let response: TResponseApi<IContact[]> = yield getContacts();

    if (response.isSuccess && response.data !== null) {
      this.setContacts(response.data);
    }
  }

  // setters

  setContacts(value: IContact[]) {
    this.contacts = value;
  }

  setSocials(value: IMainSocials[]) {
    this.socials = value;
  }
}
