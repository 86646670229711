import { ApiManager } from "../..";

import { IOther } from "./../../../interfaces/Documents/index";

import { DOCUMENT_OTHER } from "../../../constants/api";

import { TResponseApi } from "../../types";

export async function getOthers(params: {
  bookingId: number;
}): Promise<TResponseApi<IOther[]>> {
  return await ApiManager.request<IOther[]>(
    {
      url: DOCUMENT_OTHER,
      method: "GET",
      params: params,
    },
    true
  );
}
