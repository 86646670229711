import { makeAutoObservable } from "mobx";
import { Validator } from "validator-pyrobyte";
import { parse, isValid, differenceInYears } from "date-fns";

import { newRules } from "../../../../helpers/validation";

import { ITenantErrors, ITenantStoreUI } from "../interfaces";
import { ITypes } from "../../../../interfaces/Catalog";
import { IOptionCommon } from "../../../../interfaces/index";

import {
  ADULTHOOD,
  ERROR_CODE_LENGTH,
  ERROR_CORRECT_DATE,
  ERROR_GENDER,
  ERROR_MIN_CHARS_THREE,
  ERROR_MIN_CHARS_TWO,
  ERROR_NUMBER_LENGTH,
  ERROR_SERIES_LENGTH,
  ERROR_TEXT_EMPTY,
  ERROR_TEXT_PHONE,
  ERROR_YEARS_OLD,
  NUMBER_PASSPORT_LENGTH,
  SERIES_PASSPORT_LENGTH,
  SUBDIVISION_CODE_LENGTH,
  OPTION_GENDER_MALE,
  OPTION_GENDER_FEMALE,
  ERROR_SERIAL_TENANT,
  SERIES_BIRTH_CERTIFICATE_LENGTH,
} from "../../../../constants";

enum EInputs {
  LastName = "lastName",
  FirstName = "firstName",
  MiddleName = "middleName",
  Phone = "phone",
  Gender = "gender",
  Birthdate = "birthdate",
  Series = "series",
  Number = "number",
  DateIssue = "dateIssue",
  IssuedBy = "issuedBy",
  RegistrationAddress = "registrationAddress",
  SubdivisionCode = "subdivisionCode",
  PlaceBirth = "placeBirth",
  RelationTenant = "relationTenant",
  Email = "email",
}

export class TenantStoreUI implements ITenantStoreUI {
  validator: Validator;
  idTenant: number | null = null;
  localId: number = 0;

  lastName: string = "";
  firstName: string = "";
  middleName: string = "";
  phone: string = "";
  birthdate: string = "";
  series: string = "";
  number: string = "";
  dateIssue: string = "";
  issuedBy: string = "";
  registrationAddress: string = "";
  placeBirth: string = "";
  email: string = "";

  hintStringsAddress: string[] = [];
  hintStringsPlaceBirth: string[] = [];

  // для арендатора
  gender: IOptionCommon | null = null;

  genderOptions: IOptionCommon[] = [OPTION_GENDER_MALE, OPTION_GENDER_FEMALE];

  subdivisionCode: string = "";

  // только для жильца
  relationTenant: string = "";

  validateErrors: ITenantErrors = {
    [EInputs.LastName]: [],
    [EInputs.FirstName]: [],
    [EInputs.MiddleName]: [],
    [EInputs.Phone]: [],
    [EInputs.Gender]: [],
    [EInputs.Birthdate]: [],
    [EInputs.Series]: [],
    [EInputs.Number]: [],
    [EInputs.DateIssue]: [],
    [EInputs.IssuedBy]: [],
    [EInputs.RegistrationAddress]: [],
    [EInputs.SubdivisionCode]: [],
    [EInputs.PlaceBirth]: [],
    [EInputs.RelationTenant]: [],
    [EInputs.Email]: [],
  };

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.validator = new Validator(
      {
        email: {
          email: true,
          required: true,
        },

        specialChars: {
          specialChars: true,
        },
      },
      newRules
    );
  }

  //FUNCTIONS

  // validation

  validateLastName() {
    if (!this.lastName.length) {
      this.addError(EInputs.LastName, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.LastName);
    }

    if (this.lastName.length < 2) {
      this.addError(EInputs.LastName, ERROR_MIN_CHARS_TWO);
      return false;
    } else {
      this.clearError(EInputs.LastName);
    }
    return true;
  }

  validateFirstName() {
    if (!this.firstName.length) {
      this.addError(EInputs.FirstName, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.FirstName);
    }

    if (this.firstName.length < 2) {
      this.addError(EInputs.FirstName, ERROR_MIN_CHARS_TWO);
      return false;
    } else {
      this.clearError(EInputs.FirstName);
    }
    return true;
  }

  validateMiddleName() {
    if (this.middleName.length > 0 && this.middleName.length < 2) {
      this.addError(EInputs.MiddleName, ERROR_MIN_CHARS_TWO);
      return false;
    } else {
      this.clearError(EInputs.MiddleName);
    }
    return true;
  }

  validatePhone({ isRenter = false }) {
    if (isRenter && !this.phone.length) {
      this.addError(EInputs.Phone, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.Phone);
    }

    if (this.phone.length && this.phone.replaceAll(" ", "").length < 12) {
      this.addError(EInputs.Phone, ERROR_TEXT_PHONE);
      return false;
    } else {
      this.clearError(EInputs.Phone);
    }
    return true;
  }

  validateDateBirth(adulthoodСheck: boolean = false) {
    if (!this.birthdate.length) {
      this.addError(EInputs.Birthdate, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.Birthdate);
    }

    //проверка на ввод будущих дат
    let birthdate = parse(this.birthdate, "dd.MM.yyyy", new Date());
    let now = new Date();

    let nowTime = now.getTime();
    let birthdateTime = birthdate.getTime();
    let isNextDate = birthdateTime > nowTime;

    let isCorrect = isValid(birthdate);

    if (!isCorrect || isNextDate || birthdate.getFullYear() <= 1899) {
      this.addError(EInputs.Birthdate, ERROR_CORRECT_DATE);
      return false;
    } else {
      this.clearError(EInputs.Birthdate);
    }

    if (adulthoodСheck) {
      // проверка на достижение minYearsOld лет
      let age = differenceInYears(now, birthdate); // кол-во полных лет с учетом др

      if (age < ADULTHOOD) {
        this.addError(EInputs.Birthdate, ERROR_YEARS_OLD);
        return false;
      } else {
        this.clearError(EInputs.Birthdate);
      }
    }

    return true;
  }

  validateSeries({ isRenter = false }) {
    if (!this.series.length) {
      this.addError(EInputs.Series, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.Series);
    }

    if (isRenter) {
      if (this.series.length < SERIES_PASSPORT_LENGTH) {
        this.addError(EInputs.Series, ERROR_SERIES_LENGTH);
        return false;
      } else {
        this.clearError(EInputs.Series);
      }
    } else {
      let invalid =
        this.series.length < SERIES_PASSPORT_LENGTH ||
        (this.series.length > SERIES_PASSPORT_LENGTH &&
          this.series.length < SERIES_BIRTH_CERTIFICATE_LENGTH);

      if (invalid) {
        this.addError(EInputs.Series, ERROR_SERIAL_TENANT);
        return false;
      } else {
        this.clearError(EInputs.Series);
      }
    }

    return true;
  }

  validatePassportNumber() {
    if (!this.number.length) {
      this.addError(EInputs.Number, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.Number);
    }

    if (this.number.length < NUMBER_PASSPORT_LENGTH) {
      this.addError(EInputs.Number, ERROR_NUMBER_LENGTH);
      return false;
    } else {
      this.clearError(EInputs.Number);
    }
    return true;
  }

  validateDate() {
    if (!this.dateIssue.length) {
      this.addError(EInputs.DateIssue, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.DateIssue);
    }

    //проверка на ввод будущих дат
    let birthdate = parse(this.dateIssue, "dd.MM.yyyy", new Date());
    let now = new Date();

    let nowTime = now.getTime();
    let birthdateTime = birthdate.getTime();
    let isNextDate = birthdateTime > nowTime;

    let isCorrect = isValid(birthdate);

    if (!isCorrect || isNextDate || birthdate.getFullYear() <= 1899) {
      this.addError(EInputs.DateIssue, ERROR_CORRECT_DATE);
      return false;
    } else {
      this.clearError(EInputs.DateIssue);
    }

    return true;
  }

  validateIssuedBy() {
    if (!this.issuedBy.length) {
      this.addError(EInputs.IssuedBy, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.IssuedBy);
    }

    if (this.issuedBy.length < 3) {
      this.addError(EInputs.IssuedBy, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearError(EInputs.IssuedBy);
    }

    return true;
  }

  validateAddress() {
    if (!this.registrationAddress.length) {
      this.addError(EInputs.RegistrationAddress, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.RegistrationAddress);
    }

    if (this.registrationAddress.length < 3) {
      this.addError(EInputs.RegistrationAddress, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearError(EInputs.RegistrationAddress);
    }

    return true;
  }

  validateGender() {
    if (!this.gender) {
      this.addError(EInputs.Gender, ERROR_GENDER);
      return false;
    } else {
      this.clearError(EInputs.Gender);
    }

    return true;
  }

  validatePlaceBirth() {
    if (!this.placeBirth.length) {
      this.addError(EInputs.PlaceBirth, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.PlaceBirth);
    }

    if (this.placeBirth.length < 3) {
      this.addError(EInputs.PlaceBirth, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearError(EInputs.PlaceBirth);
    }

    return true;
  }

  validateSubdivisionCode() {
    if (!this.subdivisionCode.length) {
      this.addError(EInputs.SubdivisionCode, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.SubdivisionCode);
    }

    if (
      this.subdivisionCode.replaceAll("_", "").length < SUBDIVISION_CODE_LENGTH
    ) {
      this.addError(EInputs.SubdivisionCode, ERROR_CODE_LENGTH);
      return false;
    } else {
      this.clearError(EInputs.SubdivisionCode);
    }

    return true;
  }

  validateRealtionTenant() {
    if (!this.relationTenant.length) {
      this.addError(EInputs.RelationTenant, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.RelationTenant);
    }

    if (this.relationTenant.length < 2) {
      this.addError(EInputs.RelationTenant, ERROR_MIN_CHARS_TWO);
      return false;
    } else {
      this.clearError(EInputs.RelationTenant);
    }

    return true;
  }

  validateEmail() {
    let validEmail = this.validator.check("email", this.email);

    if (!this.email.length) {
      this.addError(EInputs.Email, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearError(EInputs.Email);
    }

    if (!validEmail.passed) {
      this.addError(EInputs.Email, validEmail.errors[0]);
      return false;
    } else {
      this.clearError(EInputs.Email);
    }

    return true;
  }

  addError(typeInput: EInputs, value: string) {
    this.validateErrors[typeInput] = [...this.validateErrors[typeInput], value];
  }

  clearError(typeInput: EInputs) {
    this.validateErrors[typeInput] = [];
  }

  validateAllForTenant() {
    this.validateLastName();
    this.validateFirstName();
    this.validateMiddleName();
    this.validatePhone({ isRenter: false });
    this.validateDateBirth();
    this.validatePlaceBirth();
    this.validateSeries({ isRenter: false });
    this.validatePassportNumber();
    this.validateDate();
    this.validateIssuedBy();
    this.validateAddress();
    this.validateRealtionTenant();
    this.validateGender();

    return (
      this.validateLastName() &&
      this.validateFirstName() &&
      this.validateMiddleName() &&
      this.validatePhone({ isRenter: false }) &&
      this.validateDateBirth() &&
      this.validateSeries({ isRenter: false }) &&
      this.validatePassportNumber() &&
      this.validateDate() &&
      this.validateIssuedBy() &&
      this.validateAddress() &&
      this.validatePlaceBirth() &&
      this.validateRealtionTenant() &&
      this.validateGender()
    );
  }

  validateAllForRenter() {
    this.validateLastName();
    this.validateFirstName();
    this.validateMiddleName();
    this.validateDateBirth(true);
    this.validatePlaceBirth();
    this.validatePhone({ isRenter: true });
    this.validateGender();
    this.validateSeries({ isRenter: true });
    this.validatePassportNumber();
    this.validateDate();
    this.validateIssuedBy();
    this.validateSubdivisionCode();
    this.validateAddress();
    this.validateEmail();

    return (
      this.validateLastName() &&
      this.validateFirstName() &&
      this.validateMiddleName() &&
      this.validateDateBirth(true) &&
      this.validatePhone({ isRenter: true }) &&
      this.validateGender() &&
      this.validateSeries({ isRenter: true }) &&
      this.validatePassportNumber() &&
      this.validateDate() &&
      this.validateIssuedBy() &&
      this.validateAddress() &&
      this.validatePlaceBirth() &&
      this.validateSubdivisionCode() &&
      this.validateEmail()
    );
  }

  changeLastName(value: string) {
    this.setLastName(value);

    if (this.validateErrors.lastName.length) {
      this.clearError(EInputs.LastName);
    }
  }

  changeFirstName(value: string) {
    this.setFirstName(value);

    if (this.validateErrors.firstName.length) {
      this.clearError(EInputs.FirstName);
    }
  }

  changeMiddleName(value: string) {
    this.setMiddleName(value);

    if (this.validateErrors.middleName.length) {
      this.clearError(EInputs.MiddleName);
    }
  }

  changeDateBirth(value: string) {
    this.setDateBirth(value);

    if (this.validateErrors.birthdate.length && this.birthdate.length) {
      this.clearError(EInputs.Birthdate);
    }
  }

  changePhone(value: string) {
    this.setPhone(value);

    if (this.validateErrors.phone.length) {
      this.clearError(EInputs.Phone);
    }
  }

  changeSeries(value: string, isRenter: boolean) {
    let reg = isRenter ? /[^0-9]/gi : /[^0-9][-]/gi;

    this.setSeries(value.replace(reg, ""));

    if (this.validateErrors.series.length && this.series.length) {
      this.clearError(EInputs.Series);
    }
  }

  changeNumberPassport(value: string) {
    this.setNumberPassport(value);

    if (this.validateErrors.number.length && this.number.length) {
      this.clearError(EInputs.Number);
    }
  }

  changeDateIssue(value: string) {
    this.setDateIssue(value);

    if (this.validateErrors.dateIssue.length && this.dateIssue.length) {
      this.clearError(EInputs.DateIssue);
    }
  }

  changeIssuedBy(value: string) {
    this.setIssuedBy(value);

    if (this.validateErrors.issuedBy.length) {
      this.clearError(EInputs.IssuedBy);
    }
  }

  changeRegistrationAddress(value: string) {
    this.setRegistrationAddress(value);

    if (this.validateErrors.registrationAddress.length) {
      this.clearError(EInputs.RegistrationAddress);
    }
  }

  changeGender(value: ITypes | null) {
    this.setGender(value);

    if (this.validateErrors.gender.length) {
      this.clearError(EInputs.Gender);
    }
  }

  changeSubdivisionCode(value: string) {
    this.setSubdivisionCode(value);

    if (this.validateErrors.subdivisionCode.length) {
      this.clearError(EInputs.SubdivisionCode);
    }
  }

  changePlaceBirth(value: string) {
    this.setPlaceBirth(value);

    if (this.validateErrors.placeBirth.length) {
      this.clearError(EInputs.PlaceBirth);
    }
  }

  changeRelationTenant(value: string) {
    this.setRelationTenant(value);

    if (this.validateErrors.relationTenant.length) {
      this.clearError(EInputs.RelationTenant);
    }
  }

  changeEmail(value: string) {
    this.setEmail(value);

    if (this.validateErrors.email.length) {
      this.clearError(EInputs.Email);
    }
  }

  // setters

  setIdTetant(value: number) {
    this.idTenant = value;
  }

  setLocalId(value: number) {
    this.localId = value;
  }

  setLastName(value: string) {
    this.lastName = value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, "");
  }

  setFirstName(value: string) {
    this.firstName = value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, "");
  }

  setMiddleName(value: string) {
    this.middleName = value.replace(/[^a-zA-ZА-Я а-яЁё]/gi, "");
  }

  setDateBirth(value: string) {
    this.birthdate = value;
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setSeries(value: string) {
    this.series = value;
  }

  setNumberPassport(value: string) {
    this.number = value.replace(/[^0-9]/gi, "");
  }

  setDateIssue(value: string) {
    this.dateIssue = value;
  }

  setIssuedBy(value: string) {
    this.issuedBy = value;
  }

  setRegistrationAddress(value: string) {
    this.registrationAddress = value;
  }

  setGender(value: IOptionCommon | null) {
    this.gender = value;
  }

  setSubdivisionCode(value: string) {
    this.subdivisionCode = value;
  }

  setPlaceBirth(value: string) {
    this.placeBirth = value;
  }

  setRelationTenant(value: string) {
    this.relationTenant = value;
  }

  setHintStringsAddress(value: string[]) {
    this.hintStringsAddress = value;
  }

  setHintStringsPlaceBirth(value: string[]) {
    this.hintStringsPlaceBirth = value;
  }

  setEmail(value: string) {
    this.email = value;
  }
}
