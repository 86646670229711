import { observer } from "mobx-react-lite";
import { FC } from "react";

import styles from "./styles/radioButton.module.scss";

interface IProps {
  label: string;
  checked: boolean;
  onChangeValue(): void;
}

export const RadioButton: FC<IProps> = observer(
  ({ checked = false, label = "", onChangeValue = () => {} }: IProps) => {
    return (
      <span className={styles.radioButton}>
        <span className={styles.radioButton__inputWrapper}>
          <input
            className={styles.radioButton__hiddenInput}
            type={"radio"}
            checked={checked}
            onChange={onChangeValue}
          />
          <span
            className={`${styles.radioButton__visibleInput} ${
              checked ? styles.radioButton__visibleInput_checked : ""
            }`}
          />
        </span>

        <label>{label}</label>
      </span>
    );
  }
);
