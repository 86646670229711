import { TResponseApi } from "../types";
import { ApiManager } from "../index";
import { AUTHORIZATION, LOGOUT } from "../../constants/api";
import { IAuthorizationRequest } from "../../interfaces/Authorization";

export async function authorization(params: {
  phone: string;
  password: string;
}): Promise<TResponseApi<IAuthorizationRequest>> {
  return await ApiManager.request<IAuthorizationRequest>({
    url: AUTHORIZATION,
    data: params,
    method: "POST",
  });
}

export async function logout() {
  return await ApiManager.request<unknown>(
    {
      url: LOGOUT,
      method: "POST",
    },
    true
  );
}
