import { FC } from "react";

import styles from "./styles/menuDots.module.scss";

interface IProps {
  className?: string;
  withShow?: boolean;
  onClickDownlooad(): void;
  onClickShow?(): void;
}

export const MenuDots: FC<IProps> = ({
  className = "",
  withShow = false,
  onClickDownlooad = () => {},
  onClickShow = () => {},
}) => {
  return (
    <ul className={`${styles.list} ${className}`}>
      <li className={styles.list__item}>
        <button className={styles.list__itemBtn} onClick={onClickDownlooad}>
          Скачать
        </button>
      </li>

      {withShow && (
        <li className={styles.list__item}>
          <button className={styles.list__itemBtn} onClick={onClickShow}>
            Просмотр
          </button>
        </li>
      )}
    </ul>
  );
};
