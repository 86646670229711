import { ApiManager } from "../";

import { IFilePdf } from "./../../interfaces/Documents/index";

import { DOCUMENT_PDF } from "../../constants/api";

import { TResponseApi } from "../types";

// получение загруженного через админку документа
export async function getDocumentPdf(params: {
  documentId: number;
}): Promise<TResponseApi<IFilePdf>> {
  return await ApiManager.request<IFilePdf>(
    {
      url: DOCUMENT_PDF,
      method: "GET",
      params: params,
    },
    true
  );
}
