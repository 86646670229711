import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser";

import { Button } from "../../../components/Button";
import { RevealAnimation } from "../RevealAnimation";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../stores/Root";

import styles from "./styles/advantages.module.scss";

interface IProps {
  openFeedback(): void;
}

export const Advantages: FC<IProps> = observer(
  ({ openFeedback = () => {} }) => {
    const rootStore = React.useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(rootStore));

    return (
      <section className={styles.advantages}>
        <div className={styles.advantages__content}>
          {storeUI.storeRoot.isMainLoading ? (
            <div className={styles.advantages__empty} />
          ) : (
            <>
              <div className={styles.advantages__textContent}>
                <RevealAnimation
                  className={styles.advantages__textContentAnimate}
                >
                  {parse(storeUI.content)}
                </RevealAnimation>
              </div>
              <div className={styles.advantages__buttonBlock}>
                <RevealAnimation className={styles.advantages__buttonWrapper}>
                  <Button
                    title="Записаться на презентацию"
                    theme="gold"
                    onClick={openFeedback}
                    className={styles.advantages__button}
                  />
                </RevealAnimation>
              </div>
            </>
          )}
        </div>
      </section>
    );
  }
);
