import { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../Button";
import { TextArea } from "../../TextArea";
import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";
import { Star } from "./Star/index";
import { Loader } from "../../Loader";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/review.module.scss";

interface IProps {
  reviewModal: boolean;
  closeReviewModal: () => void;
  sendReview: (apartmentId: number, bookingId: number) => void;
  isLoading: boolean;
  errorsText: string[];
  errorsRating: string[];
  text: string;
  rating: number;
  stars: number[];
  onChangeText: (value: string) => void;
  hoverRating: undefined | number;
  setHoverRating: (value: number | undefined) => void;
  setRating: (value: number) => void;
  apartmentId: number;
  bookingId: number;
}

export const LeaveReview: FC<IProps> = observer(
  ({
    reviewModal = false,
    closeReviewModal = () => {},
    sendReview = () => {},
    onChangeText = () => {},
    setHoverRating = () => {},
    setRating = () => {},
    errorsText = [],
    errorsRating = [],
    text = "",
    rating = 0,
    stars = [],
    isLoading = false,
    hoverRating = undefined,
    apartmentId = 0,
    bookingId = 0,
  }) => {
    const _sendReview = async () => {
      await sendReview(apartmentId, bookingId);
      enableScroll();
    };

    let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
      useState<EventTarget | null>(null);

    const _closeModal = () => {
      if (backgroundOnDownTarget) {
        closeReviewModal();
        enableScroll();
      }

      setBackgroundOnDownTarget(null);
    };

    return (
      <>
        {reviewModal && (
          <div
            className={styles.modal}
            onClick={_closeModal}
            onMouseDown={(e) => {
              setBackgroundOnDownTarget(e.target);
            }}
          >
            <div className={styles.modal__popupWrapper}>
              <div
                className={styles.modal__review}
                onClick={stopPropagation}
                onMouseDown={stopPropagation}
              >
                {isLoading && <Loader className={styles.modal__loader} />}
                <Close
                  onClick={closeReviewModal}
                  className={styles.modal__contentClose}
                />
                <p className={styles.modal__contentTitle}>
                  Напишите свой отзыв
                </p>
                <TextArea
                  value={text}
                  onChange={onChangeText}
                  label="Отзыв"
                  placeholder="Все было замечательно!"
                  className={styles.modal__contentTextArea}
                  classNameWrapper={styles.modal__contentTextAreaWrap}
                  errors={errorsText}
                  disabled={isLoading}
                />
                <div className={styles.modal__contentStars}>
                  {stars.map((_, index) => (
                    <Star
                      key={index}
                      starId={index}
                      rating={rating || hoverRating}
                      onClick={() => setRating(index + 1)}
                      onMouseOver={() => setHoverRating(index + 1)}
                      onMouseLeave={() => setHoverRating(undefined)}
                    />
                  ))}
                  {errorsRating.length !== 0 && (
                    <span className={styles.modal__ratingError}>
                      {errorsRating[0]}
                    </span>
                  )}
                </div>
                <Button
                  title="ОТПРАВИТЬ"
                  type="submit"
                  theme="gold"
                  onClick={_sendReview}
                  disabled={isLoading}
                  isDisabledStyle={text === "" || rating === 0 || isLoading}
                  className={styles.modal__contentButton}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
