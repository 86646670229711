import { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { IBlockedApartment } from "../../../interfaces/Floor";

import styles from "./styles/index.module.scss";

interface IProps {
  id: number;
  viewBow: string;
  points: string;
  className: string;
  onClick(id: number): void;
  blockedApartments?: IBlockedApartment[];
  toApartmentPage(id: number): void;
}

export const Polygon: FC<IProps> = observer(
  ({
    id = 0,
    viewBow = "",
    points = "",
    className = "",
    onClick = () => {},
    blockedApartments = [],
    toApartmentPage = () => {},
  }: IProps) => {
    let _isBlocked = blockedApartments?.some((item) => item.id === id);

    const _onClick = () => (_isBlocked ? toApartmentPage(id) : onClick(id));

    let _blockedItem = useMemo(
      () => blockedApartments?.find((item) => item.id === id),
      [id, blockedApartments]
    );

    return (
      <div className={`${styles.polygonItem} ${className}`} onClick={_onClick}>
        {_blockedItem && (
          <div className={styles.polygonItem__card}>
            {_blockedItem.endDate ? (
              <>
                <p className={styles.polygonItem__cardTitle}>
                  Здесь уже живут ваши ВЭЛКО-соседи
                </p>
                <span className={styles.polygonItem__cardSubtitle}>
                  До {_blockedItem.endDate}
                </span>
              </>
            ) : (
              <p className={styles.polygonItem__cardTitle}>
                Квартира временно недоступна
              </p>
            )}
          </div>
        )}

        <svg
          viewBox={viewBow}
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.polygonItem__svg}  ${
            _isBlocked && styles.polygonItem__svg_blocked
          }`}
        >
          <polygon points={points} className={styles.polygonItem__polygon} />
        </svg>
      </div>
    );
  }
);
