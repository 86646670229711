import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser";

import { Button } from "../../../components/Button";
import { MainMap } from "../../../components/Map";
import { RevealAnimation } from "../RevealAnimation";

import { LATITUDE_ADDRESS, LONGITUDE_ADDRESS } from "../../../constants";
import { SCREENS } from "../../../navigation/endpoints";

import { RootContext } from "../../../stores/Root";
import { StoreUI } from "./storeUI";

import styles from "./styles/infrastructure.module.scss";

export const Infrastructure: FC = observer(() => {
  const navigate = useNavigate();
  const rootStore = React.useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore));

  const _toApartments = () => {
    navigate(SCREENS.SCREEN_APARTMENTS);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__leftColumn}>
        <div className={styles.container__content}>
          <div className={styles.container__textBlock}>
            {storeUI.storeRoot.isMainLoading ? (
              <div className={styles.container__empty} />
            ) : (
              <>
                <RevealAnimation
                  className={styles.container__textContentAnimate}
                >
                  {parse(storeUI.content)}
                </RevealAnimation>
                <RevealAnimation className={styles.container__buttonWrapper}>
                  <Button
                    title="ЗАБРОНИРОВАТЬ КВАРТИРУ"
                    theme="white"
                    onClick={_toApartments}
                    className={styles.container__button}
                  />
                </RevealAnimation>
              </>
            )}
          </div>
        </div>
      </div>
      <MainMap
        longitude={LONGITUDE_ADDRESS}
        latitude={LATITUDE_ADDRESS}
        className={styles.container__map}
      />
    </div>
  );
});
