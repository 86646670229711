import { ApiManager } from "../index";

import { FAQ } from "../../constants/api";

import { IFaqItem } from "../../interfaces/FAQ";

import { TResponseApi } from "../types";

export async function getFaq(): Promise<TResponseApi<IFaqItem[]>> {
  return await ApiManager.request<IFaqItem[]>({
    method: "GET",
    url: FAQ,
  });
}
