import { FC, useContext, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react-lite";

import { enableScroll } from "../../helpers/modalsFunc";

import { MainPart } from "./MainPart";
import { Advantages } from "./Advantages";
import { Services } from "./Services";
import { Flats } from "./Flats";
import { OurFlats } from "./OurFlats";
import { Infrastructure } from "./Infrastructure";
import { Request } from "./Request";
import { MainFooter } from "./Footer";
import { Loader } from "../../components/Loader";

import { RootContext } from "../../stores/Root";

import { Store } from "../../stores/types";
import { EPageType } from "../../types";

import {
  DESCRIPTION_PAGE_DEFAULT,
  PAGE_TITLE_MAIN,
  TITLE_TEXT_PAGE_DEFAULT,
} from "../../constants";

import styles from "./styles/main.module.scss";

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
  openFeedback(): void;
  closeSaveModal(): void;
  isModalSave: boolean;
}

export const Main: FC<IProps> = observer(
  ({
    storeAuthentication,
    openFeedback = () => {},
    closeSaveModal = () => {},
    isModalSave = false,
  }: IProps) => {
    const rootStore = useContext(RootContext);

    let descriptionPage = useMemo(() => {
      return rootStore.metaDescriptionsList.find(
        (item) => item.page === EPageType.Main
      );
    }, [rootStore.metaDescriptionsList]);

    let helmet = useMemo(
      () => (
        <Helmet>
          <title>{descriptionPage?.title ?? TITLE_TEXT_PAGE_DEFAULT}</title>
          <meta
            name="description"
            content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
          />
        </Helmet>
      ),
      [descriptionPage]
    );

    useEffect(() => {
      enableScroll();
    }, []);

    return (
      <div className={styles.main}>
        {helmet}
        {rootStore.isMainLoading && <Loader className={styles.main__loader} />}
        <MainPart
          openFeedback={openFeedback}
          isModalSave={isModalSave}
          closeSaveModal={closeSaveModal}
        />
        <Advantages openFeedback={openFeedback} />
        <Services />
        <Flats />
        <OurFlats openFeedback={openFeedback} />
        <Infrastructure />
        <Request />
        <MainFooter />
      </div>
    );
  }
);
