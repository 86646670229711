import { ApiManager } from "../index";

import {
  PASSWORD_RESET_SEND,
  PASSWORD_RESET_CHECK,
  PASSWORD_RESET_CHANGE,
} from "../../constants/api";

import {
  IResetChange,
  IResetCheck,
  IResetSend,
} from "../../interfaces/PasswordRecovery";
import { ICommonResponse } from "./../../interfaces/index";

import { TResponseApi } from "../types";

export async function getPasswordResetCode(
  params: IResetSend
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>({
    url: PASSWORD_RESET_SEND,
    data: params,
    method: "POST",
  });
}

export async function checkPasswordResetCode(
  params: IResetCheck
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>({
    url: PASSWORD_RESET_CHECK,
    data: params,
    method: "POST",
  });
}

export async function passwordResetChange(
  params: IResetChange
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>({
    url: PASSWORD_RESET_CHANGE,
    data: params,
    method: "PUT",
  });
}
