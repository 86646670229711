import { makeAutoObservable } from "mobx";

import {
  checkFreeApartment,
  clearBasket,
  deleteApartment,
  getBasket,
} from "../../../api/Basket";
import { deleteServiceToBasket } from "../../../api/Services";

import { TResponseApi } from "../../../api/types";
import { Store } from "../../types";

import { ICommonResponse } from "../../../interfaces";
import {
  IApartmentOfBasket,
  IBasket,
  ICheckApartment,
  IServiceOfBasket,
  ICheckApartmentResponse,
} from "../../../interfaces/Basket";

export class ModelBasket implements Store.IBasket {
  storeRoot: Store.IRootStore;
  apartmentsList: IApartmentOfBasket[] = [];
  servicesList: IServiceOfBasket[] = [];
  price: number = 0;

  numberApartments: number = 0;

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
  }

  // functions

  *getBasket() {
    let response: TResponseApi<IBasket> = yield getBasket();

    if (response.isSuccess && response.data !== null) {
      this.setApartmentsList(response.data.apartments);
      this.setServicesList(response.data.services);
      this.setPrice(response.data.price);

      this.setNumberApartments(response.data.apartments?.length || 0);
      this.storeRoot.setNumberServices(response.data.services?.length || 0);
    }
  }

  *clearBasket() {
    let response: TResponseApi<ICommonResponse> = yield clearBasket();

    if (response.isSuccess) {
      yield this.getBasket();
      this.setNumberApartments(0);
      this.storeRoot.setNumberServices(0);
    }
    return response.isSuccess;
  }

  *deleteApartment(basketCalendarId: number) {
    let response: TResponseApi<ICommonResponse> = yield deleteApartment({
      basketCalendarId,
    });

    if (response.isSuccess) {
      yield this.getBasket();
    }

    return response.isSuccess;
  }

  *checkFreeApartment(params: ICheckApartment) {
    let response: TResponseApi<ICheckApartmentResponse> =
      yield checkFreeApartment(params);

    if (response.isSuccess && response.data !== null) {
      return response.data.result;
    }
    return null;
  }

  *deleteService(basketServiceId: number) {
    let response: TResponseApi<ICommonResponse> = yield deleteServiceToBasket({
      basketServiceId,
    });
    if (response.isSuccess) {
      yield this.getBasket();
    }
    return response.isSuccess;
  }

  // setters

  setApartmentsList(value: IApartmentOfBasket[]) {
    this.apartmentsList = value;
  }

  setServicesList(value: IServiceOfBasket[]) {
    this.servicesList = value;
  }

  setPrice(value: number) {
    this.price = value;
  }

  setNumberApartments(value: number) {
    this.numberApartments = value;
  }
}
