import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { Main } from "../pages/Main";
import { Apartments } from "../pages/Apartments";
import { Apartment } from "../pages/Apartments/Apartment";
import { Services } from "../pages/Services";
import { News } from "../pages/News";
import { NewsDetails } from "../pages/News/NewsDetails";
import { Contacts } from "../pages/Contacts";
import { Account } from "../pages/PersonalAccount";
import { Basket } from "../pages/Basket";
import { FAQ } from "../pages/FAQ";
import { Partners } from "../pages/Partners";
import { TextPage } from "../pages/TextPage";
import { Floor } from "../pages/Floor";
import { NotFound } from "../pages/NotFound";
import { Order } from "../pages/Order";
import { OrderingData } from "../pages/OrderingData";
import { SberAuth } from "../pages/PaymentResult/SberAuth";

import { SCREENS } from "./endpoints";

import { storeRouting } from "../stores/Routing";

import { Store } from "../stores/types";
import { BankResult } from "../pages/PaymentResult/BankResult";

const ROUTES = [
  {
    key: 1,
    code: "home",
    path: SCREENS.SCREEN_MAIN,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void,
      isModalSave: boolean,
      closeSaveModal: () => void
    ) => (
      <Main
        storeAuthentication={storeAuth}
        openFeedback={openFeedback}
        isModalSave={isModalSave}
        closeSaveModal={closeSaveModal}
      />
    ),
  },
  {
    key: 2,
    code: "apartments",
    path: SCREENS.SCREEN_APARTMENTS,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void,
      isModalSave: boolean,
      closeSaveModal: () => void
    ) => (
      <Apartments
        openFeedback={openFeedback}
        isModalSave={isModalSave}
        closeSaveModal={closeSaveModal}
      />
    ),
  },
  {
    key: 3,
    code: "apartment",
    path: SCREENS.SCREEN_APARTMENTS_DETAILS,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <Apartment storeAuthentication={storeAuth} />,
  },
  {
    key: 4,
    code: "services",
    path: SCREENS.SCREEN_SERVICES,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <Services />,
  },
  {
    key: 5,
    code: "news",
    path: SCREENS.SCREEN_NEWS,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <News />,
  },
  {
    key: 6,
    code: "new_details",
    path: SCREENS.SCREEN_NEWS_DETAILS,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <NewsDetails />,
  },
  {
    key: 7,
    code: "contacts",
    path: SCREENS.SCREEN_CONTACTS,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <Contacts />,
  },
  {
    key: 8,
    code: "account",
    path: SCREENS.SCREEN_ACCOUNT,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <Account storeAuthentication={storeAuth} />,
    access: {
      auth: true,
    },
  },
  {
    key: 9,
    code: "basket",
    path: SCREENS.SCREEN_BASKET,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <Basket storeAuthentication={storeAuth} />,
  },
  {
    key: 10,
    code: "order",
    path: SCREENS.SCREEN_ORDER,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <Order storeAuthentication={storeAuth} />,
  },
  {
    key: 11,
    code: "faq",
    path: SCREENS.SCREEN_FAQ,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <FAQ />,
  },
  {
    key: 12,
    code: "partners",
    path: SCREENS.SCREEN_PARTNERS,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <Partners />,
  },
  {
    key: 13,
    code: "not_found",
    path: SCREENS.SCREEN_NOT_FOUND,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <NotFound />,
  },
  {
    key: 14,
    code: "textPage",
    path: "/text/:address",
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <TextPage />,
  },
  {
    key: 15,
    code: "floor",
    path: SCREENS.SCREEN_FLOOR_PlAN,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <Floor />,
  },

  {
    key: 16,
    code: "orderingData",
    path: SCREENS.SCREEN_ORDERING_DATA,
    element: (
      storeAuth: Store.IStoreAuthentication,
      openFeedback: () => void
    ) => <OrderingData storeAuthentication={storeAuth} />,
  },
  {
    key: 17,
    code: "sberAuth",
    path: SCREENS.SCREEN_REGISTRATION_SBER,
    element: () => <SberAuth />,
  },
  {
    key: 18,
    code: "paymentResult",
    path: SCREENS.SCREEN_PAYMENT_RESULT,
    element: () => <BankResult />,
  },
];

interface IProps {
  storeAuthentication: Store.IStoreAuthentication;
  openFeedback(): void;
  closeSaveModal(): void;
  isModalSave: boolean;
}

export const Navigation: FC<IProps> = ({
  storeAuthentication,
  openFeedback = () => {},
  closeSaveModal = () => {},
  isModalSave = false,
}) => {
  useEffect(() => {
    storeRouting.setHistory(window.history);
  }, []);

  const hasAccessRoute = (route: boolean) => {
    if (route) {
      return storeAuthentication.isAuth;
    }
    return true;
  };

  return (
    <Routes>
      {ROUTES.map((route) => {
        return (
          hasAccessRoute(Boolean(route.access?.auth)) && (
            <Route
              path={route.path}
              element={route.element(
                storeAuthentication,
                openFeedback,
                isModalSave,
                closeSaveModal
              )}
              key={route.key}
            />
          )
        );
      })}
    </Routes>
  );
};
