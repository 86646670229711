import { HEADER_PDF } from "../constants";

export function downloadPdf(documentData: string, documentName: string) {
  if (documentData.length) {
    let link = document.createElement("a");
    link.href = HEADER_PDF + documentData;
    link.download = documentName;
    link.dispatchEvent(new MouseEvent("click"));
  }
}
