import { FC } from "react";
import { observer } from "mobx-react-lite";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

import { ReactComponent as FaqOpen } from "../../../assets/images/faqOpen.svg";
import { ReactComponent as FaqClose } from "../../../assets/images/faqClose.svg";

import styles from "./styles/question.module.scss";

const customAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface IProps {
  id: number;
  title: string;
  text: string;
  isOpen: boolean;
  toggleOpen(id: number): void;
}

export const Question: FC<IProps> = observer(
  ({
    id = 0,
    title = "",
    text = "",
    isOpen = false,
    toggleOpen = () => {},
  }) => {
    return (
      <div className={`${styles.question} ${isOpen && styles.question_opened}`}>
        <div onClick={() => toggleOpen(id)}>
          {isOpen ? (
            <FaqClose className={styles.question__button} />
          ) : (
            <FaqOpen className={styles.question__button} />
          )}
        </div>
        <div className={styles.question__info}>
          <h5 className={styles.question__title} onClick={() => toggleOpen(id)}>
            {title}
          </h5>
          {isOpen && (
            <Reveal keyframes={customAnimation}>
              <p className={styles.question__text}>{text}</p>
            </Reveal>
          )}
        </div>
      </div>
    );
  }
);
