import { FC } from "react";

import { ReactComponent as ServiceClose } from "../../../../assets/images/serviceClose.svg";

import styles from "./styles/serviceItem.module.scss";

interface IProps {
  name: string;
  onDelete(): void;
  className?: string;
}

export const ServiceItem: FC<IProps> = ({
  name = "",
  className = "",
  onDelete = () => {},
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.container__title}>{name}</div>
      <ServiceClose className={styles.container__close} onClick={onDelete} />
    </div>
  );
};
