export enum SCREENS {
  SCREEN_PYROBYTE = "https://pyrobyte.ru/",
  SCREEN_MAIN = "/",
  SCREEN_APARTMENTS = "/apartments",
  SCREEN_APARTMENTS_DETAILS = "/apartments/:id",
  SCREEN_SERVICES = "/services",
  SCREEN_NEWS = "/news",
  SCREEN_NEWS_DETAILS = "/news/:id",
  SCREEN_CONTACTS = "/contacts",
  SCREEN_ACCOUNT = "/account/*",
  SCREEN_ACCOUNT_RENT = "/account/my-bookings/:id/rent",
  SCREEN_ACCOUNT_MY_BOOKINGS = "/account/my-bookings",
  SCREEN_ACCOUNT_MY_BOOKING = "/account/my-bookings/:id/:tabName?",
  SCREEN_ACCOUNT_PERSONAL = "/account/personal",
  SCREEN_ACCOUNT_RESIDENTS = "/account/my-bookings/:id/residents/:tabName",
  SCREEN_ACCOUNT_COMPANY = "/account/company",
  SCREEN_ACCOUNT_FAVORITES = "/account/favorites",
  SCREEN_ACCOUNT_ARCHIVE = "/account/archive",
  SCREEN_ACCOUNT_DOCUMENTS = "/account/my-bookings",
  SCREEN_ACCOUNT_DOCUMENTS_BILLS = `/account/my-bookings/:id/bills`,
  SCREEN_ACCOUNT_DOCUMENTS_ACTS = "/account/my-bookings/:id/acts",
  SCREEN_ACCOUNT_DOCUMENTS_AGREEMENTS = "/account/my-bookings/:id/agreements",
  SCREEN_ORDERING_DATA = "/ordering-data/:id",
  SCREEN_ACCOUNT_FINANCE = "/account/finance",
  SCREEN_BASKET = "/basket",
  SCREEN_ORDER = "/order",
  SCREEN_FAQ = "/faq",
  SCREEN_PARTNERS = "/partners",
  SCREEN_NOT_FOUND = "*",
  SCREEN_TEXT_PRIVACY = "/text/privacy",
  SCREEN_TEXT_PERSONAL_DATA = "/text/personal-data",
  SCREEN_TEXT_RULES = "/text/rules",
  SCREEN_404 = "/404",
  SCREEN_FLOOR = "/floor",
  SCREEN_FLOOR_PlAN = "/floor/:id",
  SCREEN_REGISTRATION_SBER = "/auth/sber",
  SCREEN_PAYMENT_RESULT = "/oplata/platezh/:id",
}
