import { ApiManager } from "../index";

import { BOOKING_TENANTS_BY_ID, DELETE_TENANT } from "../../constants/api";

import { IResidents, ITenantsAllSend } from "../../interfaces/Tenants";
import { ICommonResponse } from "../../interfaces";

import { TResponseApi } from "../types";

export async function getResidents(
  bookingId: number
): Promise<TResponseApi<IResidents>> {
  return await ApiManager.request<IResidents>(
    {
      url: BOOKING_TENANTS_BY_ID(bookingId),
      method: "GET",
    },
    true
  );
}

export async function changeTenants(
  params: ITenantsAllSend,
  bookingId: number
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BOOKING_TENANTS_BY_ID(bookingId),
      method: "POST",
      data: params,
    },
    true
  );
}

export async function deleteTenant(
  bookingId: string,
  tenantId: number
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: DELETE_TENANT(bookingId, tenantId),
      method: "DELETE",
    },
    true
  );
}
