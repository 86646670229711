import { makeAutoObservable } from "mobx";

import { getOthers } from "../../../../api/Documents/Others";

import { TResponseApi } from "../../../../api/types";
import { Store } from "../../../types";

import { IOther } from "../../../../interfaces/Documents";

export class ModelOtherDocuments implements Store.IOtherDocumentsStore {
  othersDocuments: IOther[] = [];

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  *getOthersDocuments(bookingId: number) {
    let response: TResponseApi<IOther[]> = yield getOthers({ bookingId });

    if (response.isSuccess && response.data !== null) {
      this.setOthersDocuments(response.data);
    }
  }

  setOthersDocuments(value: IOther[]) {
    this.othersDocuments = value;
  }
}
