// динамически изменять путь (последний)
// example.ru/floor/old -> example.ru/floor/newPath
// где old - текущее значение, а newPath - любое новое значение пути

export function changeLastUrlPath(newPath: string) {
  let url = new URL(window.location.href);
  let newUrl = new URL(String(newPath), url);

  window.history.replaceState({}, "", newUrl);
}
