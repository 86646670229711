import { FC } from "react";
import { Link } from "react-router-dom";

import { PYROBYTE_SITE } from "../../constants";
import { SCREENS } from "../../navigation/endpoints";
import { currentYear } from "../../helpers";

import { ReactComponent as Pyrobyte } from "../../assets/images/pyrobyte.svg";

import styles from "./styles/footer.module.scss";

interface IProps {
  className?: string;
}

export const Footer: FC<IProps> = ({ className = "" }) => {
  return (
    <footer className={`${styles.container} ${className}`}>
      <div className={styles.container__content}>
        <div className={styles.container__line} />
        <p className={styles.container__copyTop}>
          © Клубный дом на 25 октября {currentYear}
        </p>
        <a
          href={PYROBYTE_SITE}
          target="_blank"
          className={styles.container__dev}
          rel="noreferrer"
        >
          <Pyrobyte />
          <p className={styles.container__devText}>Создание сайта</p>
        </a>
        <a href={PYROBYTE_SITE} className={styles.container__design}>
          <Pyrobyte />
          <p className={styles.container__designText}>Дизайн сайта</p>
        </a>
        <p className={styles.container__copyBottom}>
          © Клубный дом на 25 октября {currentYear}
        </p>
        <Link
          to={SCREENS.SCREEN_TEXT_PERSONAL_DATA}
          target="_blank"
          className={styles.container__agreement}
        >
          Пользовательское соглашение
        </Link>
        <Link
          to={SCREENS.SCREEN_TEXT_PERSONAL_DATA}
          className={styles.container__personal}
        >
          Политика обработки персональных данных
        </Link>
      </div>
    </footer>
  );
};
