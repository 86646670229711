import { observer } from "mobx-react-lite";

import { Input } from "../../../../../Input";
import { Button } from "../../../../../Button";
import { RadioTabs } from "../../../../../RadioTabs";

import {
  NUMBER_LAST_NAME_LENGTH,
  NUMBER_FIRST_NAME_LENGTH,
  NUMBER_MIDDLE_NAME_LENGTH,
} from "../../../../../../constants";

import { IRegistrationUI } from "../../../../../../stores/ui/Authetication/interfaces";

import styles from "./styles/enterFio.module.scss";

interface IProps {
  storeUI: IRegistrationUI;
}

export const EnterFio = observer(({ storeUI }: IProps) => {
  return (
    <div className={styles.enterFio}>
      <p className={styles.enterFio__desc}>Введите ФИО</p>
      <RadioTabs
        currentValue={storeUI.activeRadioButton}
        items={storeUI.radioButtons}
        className={styles.enterFio__radioButtonsWrapper}
        onChange={storeUI.changeActiveRadioButton}
      />

      <Input
        value={storeUI.lastName}
        onChange={storeUI.changeLastName}
        errors={storeUI.errorsLastName}
        className={styles.enterFio__input}
        label="Фамилия"
        placeholder="Иванов"
        maxLength={NUMBER_LAST_NAME_LENGTH}
      />

      <Input
        value={storeUI.firstName}
        onChange={storeUI.changeFirstName}
        errors={storeUI.errorsFirstName}
        className={styles.enterFio__input}
        label="Имя"
        placeholder="Иван"
        maxLength={NUMBER_FIRST_NAME_LENGTH}
      />

      <Input
        value={storeUI.middleName}
        onChange={storeUI.changeMiddleName}
        errors={storeUI.errorsMiddleName}
        className={styles.enterFio__input}
        label="Отчество"
        placeholder="Иванович"
        maxLength={NUMBER_MIDDLE_NAME_LENGTH}
      />

      <Button
        className={styles.enterFio__button}
        isDisabledStyle={storeUI.isDisabledButtonStyle}
        disabled={storeUI.isLoading}
        title="Продолжить"
        theme="gold"
        onClick={storeUI.onContinueFio}
      />
    </div>
  );
});
