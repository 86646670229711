import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { IEnterCodeStore } from "../../../../../interfaces";

import { SCREENS } from "../../../../../navigation/endpoints";

import { CODE_LENGTH } from "../../../../../constants";

import { Button } from "../../../../Button";
import { Input } from "../../../../Input";
import { Loader } from "../../../../Loader";

import styles from "./styles/enterCode.module.scss";

interface IProps {
  storeUI: IEnterCodeStore;
}

export const EnterCode = observer(({ storeUI }: IProps) => {
  // timer
  let seconds = 90;
  const [over, setOver] = useState(false);
  const [second, setTime] = useState(seconds);

  const tick = () => {
    if (over) return;

    if (second === 0) {
      setOver(true);
    } else {
      setTime(second - 1);
    }
  };

  const reset = () => {
    setTime(seconds);
    setOver(false);
  };

  const onClickAgain = (e: React.MouseEvent) => {
    e.preventDefault();
    storeUI.getCodeToSms();
    reset();
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  return (
    <div className={styles.enterCode}>
      <p className={styles.enterCode__desc}>
        Введите последние 4 цифры номера,
        <br /> который Вам звонит на номер
      </p>
      <h5 className={styles.enterCode__number}>
        {storeUI.phone.replace("+", "")}
      </h5>
      {storeUI.isLoading && <Loader />}
      <Input
        className={styles.enterCode__input}
        label="Код подтверждения"
        placeholder="0000"
        value={storeUI.code}
        onChange={storeUI.changeCode}
        maxLength={CODE_LENGTH}
        errors={storeUI.errorsCode}
      />

      <button
        className={`${styles.enterCode__sendAgainBtn} ${
          second === 0 && styles.enterCode__sendAgainBtn_active
        }`}
        onClick={onClickAgain}
      >
        Отправить код по смс {second !== 0 && `через:`}
        <span
          className={`${styles.enterCode__sendAgainSeconds} ${
            second === 0 && styles.enterCode__sendAgainSeconds_hide
          }`}
        >
          {` (${second.toString().padStart(2, "00")}c)`}
        </span>
      </button>

      <p className={styles.enterCode__accept}>
        Нажимая на кнопку, вы принимаете{" "}
        <Link to={SCREENS.SCREEN_TEXT_PRIVACY} target="_blank">
          <span>политику конфиденциальности</span>
        </Link>
        и даете согласие на{" "}
        <Link to={SCREENS.SCREEN_TEXT_PERSONAL_DATA} target="_blank">
          <span>обработку персональных данных</span>
        </Link>
      </p>
      <Button
        className={styles.enterCode__button}
        isDisabledStyle={storeUI.code === ""}
        disabled={storeUI.isLoading}
        title="ДАЛЕЕ"
        theme="gold"
        onClick={storeUI.onSendCode}
      />
    </div>
  );
});
