import { FC, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react-lite";

import { Button } from "../../components/Button";

import { SCREENS } from "../../navigation/endpoints";

import {
  DESCRIPTION_PAGE_DEFAULT,
  PAGE_TITLE_NOT_FOUND,
} from "../../constants";

import { RootContext } from "../../stores/Root";

import { EPageType } from "../../types";

import page404 from "../../assets/images/page404.png";
import page404m from "../../assets/mobile/page404m.png";

import styles from "./styles/notFound.module.scss";

export const NotFound: FC = observer(() => {
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);

  const _toMain = () => {
    navigate(SCREENS.SCREEN_MAIN);
  };

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.NotFound
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(
    () => (
      <Helmet>
        <title>{descriptionPage?.title ?? PAGE_TITLE_NOT_FOUND}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    ),
    [descriptionPage]
  );

  return (
    <div className={styles.container}>
      {helmet}
      <Button
        className={styles.container__button}
        title="На главную"
        theme="gold"
        onClick={_toMain}
      />
      <img className={styles.container__page404} src={page404} alt="page404" />
      <img
        className={styles.container__page404m}
        src={page404m}
        alt="page404"
      />
      <h3 className={styles.container__title}>
        Мы не нашли подходящий ключ к этой странице
      </h3>
      <Button
        className={styles.container__buttonMobile}
        title="На главную"
        theme="gold"
        onClick={_toMain}
      />
    </div>
  );
});
