import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { IPasswordRecoveryUI } from "../../../../../../stores/ui/Authetication/interfaces";

import { Button } from "../../../../../Button";
import { InputPhone } from "../../../../../InputPhone";

import { SCREENS } from "../../../../../../navigation/endpoints";

import styles from "./styles/index.module.scss";

interface IProps {
  storeUI: IPasswordRecoveryUI;
}

const EnterPhone = observer(({ storeUI }: IProps) => {
  return (
    <div className={styles.enterPhone}>
      <p className={styles.enterPhone__desc}>
        Для восстановления пароля введите номер вашего телефона
      </p>
      <InputPhone
        value={storeUI.phone}
        onChange={storeUI.changePhone}
        className={styles.enterPhone__input}
        label="Телефон"
        errors={storeUI.errorsPhone}
      />
      <p className={styles.enterPhone__accept}>
        Нажимая на кнопку, вы принимаете{" "}
        <Link to={SCREENS.SCREEN_TEXT_PRIVACY} target="_blank">
          <span>политику конфиденциальности</span>
        </Link>{" "}
        и даете согласие на обработку{" "}
        <Link to={SCREENS.SCREEN_TEXT_PERSONAL_DATA} target="_blank">
          <span>персональных данных</span>
        </Link>
      </p>
      <Button
        className={styles.enterPhone__button}
        isDisabledStyle={storeUI.phone === ""}
        disabled={storeUI.isLoading}
        title="ПОЛУЧИТЬ КОД"
        theme="gold"
        onClick={storeUI.getCodeToCall}
      />
      <div className={styles.enterPhone__signup} onClick={storeUI.toRegister}>
        <h6>Регистрация</h6>
      </div>
    </div>
  );
});

export { EnterPhone };
