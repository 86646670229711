import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Input } from "../../../../../components/Input";
import { InputPhone } from "../../../../../components/InputPhone";
import { InputDate } from "../../../../../components/InputDate";
import { InputWithHint } from "../../../../../components/InputWitnHint";
import { Select } from "../../../../../components/Select";
import { InputWithMask } from "../../../../../components/InputWithMask";

import {
  NUMBER_PASSPORT_LENGTH,
  SERIES_PASSPORT_LENGTH,
} from "../../../../../constants";

import { ITypes } from "../../../../../interfaces/Catalog";

import { ReactComponent as TrashCan } from "../../../../../assets/images/trashCan.svg";

import styles from "./styles/resident.module.scss";

interface IProps {
  countTenant?: number;
  lastName: string;
  firstName: string;
  middleName: string;
  phone: string;
  series: string;
  number: string;
  dateIssue: string;
  dateBirth: string;
  issuedBy: string;
  registrationAddress: string;
  placeBirth?: string;
  subdivisionCode?: string;
  relationTenant?: string;
  hintAddressStrings?: string[];
  hintStringsPlaceBirth?: string[];
  personalType?: string;
  errorsFirstName?: string[];
  errorsLastName?: string[];
  errorsPhone?: string[];
  errorsDateBirth?: string[];
  errorsSeries?: string[];
  errorsNumberPassport?: string[];
  errorsDateIssue?: string[];
  errorsIssuedBy?: string[];
  errorsRegistrationAddress?: string[];
  errorsPlaceBirth?: string[];
  errorsSubdivisionCode?: string[];
  errorsRelationTenant?: string[];
  errorsGender?: string[];
  gender?: ITypes | null;
  isReadonly?: boolean;
  changeLastName?: (value: string) => void;
  changeFirstName?: (value: string) => void;
  changeMiddleName?: (value: string) => void;
  changePhone?: (value: string) => void;
  changeDateBirth?: (value: string) => void;
  changeSeries?: (value: string) => void;
  changeNumberPassport?: (value: string) => void;
  changeDateIssue?: (value: string) => void;
  changeIssuedBy?: (value: string) => void;
  changeGender?: (value: ITypes) => void;
  changeRegistrationAddress?: (value: string) => void;
  changePlaceBirth?: (value: string) => void;
  changeSubdivisionCode?: (value: string) => void;
  changeRelationTenant?: (value: string) => void;
  onClickHintAddress?: (value: string) => void;
  onClickHintPlaceBirth?: (value: string) => void;
  openDeleteModal?: () => void;
  genderOptions?: ITypes[];
  isRenterMode?: boolean;
  isTenantMode?: boolean;
}

export const Resident: FC<IProps> = observer(
  ({
    countTenant = 0,
    lastName = "",
    firstName = "",
    middleName = "",
    phone = "",
    series = "",
    number = "",
    dateIssue = "",
    dateBirth = "",
    issuedBy = "",
    registrationAddress = "",
    placeBirth = "",
    subdivisionCode = "",
    relationTenant = "",
    gender = null,
    errorsFirstName = [],
    errorsLastName = [],
    errorsPhone = [],
    errorsDateBirth = [],
    errorsSeries = [],
    errorsNumberPassport = [],
    errorsDateIssue = [],
    errorsIssuedBy = [],
    errorsRegistrationAddress = [],
    errorsPlaceBirth = [],
    errorsGender = [],
    errorsRelationTenant = [],
    errorsSubdivisionCode = [],
    hintAddressStrings = [],
    hintStringsPlaceBirth = [],
    personalType = "individual",
    isReadonly = false,
    changeLastName = () => {},
    changeFirstName = () => {},
    changeMiddleName = () => {},
    changePhone = () => {},
    changeDateBirth = () => {},
    changeSeries = () => {},
    changeNumberPassport = () => {},
    changeDateIssue = () => {},
    changeIssuedBy = () => {},
    changeGender = () => {},
    changeRegistrationAddress = () => {},
    changePlaceBirth = () => {},
    changeSubdivisionCode = () => {},
    changeRelationTenant = () => {},
    openDeleteModal = () => {},
    onClickHintAddress = () => {},
    onClickHintPlaceBirth = () => {},
    genderOptions = [],
    isRenterMode = false,
    isTenantMode = false,
  }) => {
    return (
      <div
        className={`${styles.resident} ${
          isRenterMode ? styles.resident_renterMode : ""
        }`}
      >
        {!isRenterMode && (
          <div className={styles.resident__header}>
            <h5 className={styles.resident__person}>{countTenant} жилец</h5>

            <button
              className={`${styles.resident__trashCan} ${
                isReadonly ? styles.resident__trashCan_hide : ""
              }`}
              onClick={openDeleteModal}
            >
              <TrashCan />
            </button>
          </div>
        )}

        <div className={styles.resident__inputs}>
          <Input
            value={lastName}
            onChange={changeLastName}
            className={styles.resident__name}
            label="Фамилия"
            placeholder="Фамилия"
            maxLength={18}
            errors={errorsLastName}
            readonly={isReadonly || isRenterMode}
          />
          <Input
            value={firstName}
            onChange={changeFirstName}
            className={styles.resident__name}
            label="Имя"
            placeholder="Имя"
            maxLength={18}
            errors={errorsFirstName}
            readonly={isReadonly || isRenterMode}
          />
          <Input
            value={middleName}
            onChange={changeMiddleName}
            className={styles.resident__name}
            label="Отчество"
            placeholder="Отчество"
            maxLength={18}
            errors={[]}
            readonly={isReadonly || isRenterMode}
          />
          <InputDate
            value={dateBirth}
            onChange={changeDateBirth}
            label={"Дата рождения"}
            placeholder={"ДД.ММ.ГГГГ"}
            className={styles.resident__date}
            classNameInput={styles.resident__dateInput}
            errors={errorsDateBirth}
            readonly={isReadonly || isRenterMode}
          />
          <InputPhone
            value={phone}
            onChange={changePhone}
            label={"Телефон"}
            className={styles.resident__phone}
            errors={errorsPhone}
            readOnly={isReadonly || isRenterMode}
          />
          {isTenantMode && (
            <Input
              value={relationTenant}
              onChange={changeRelationTenant}
              className={styles.resident__attitude}
              label="Отношение к арендатору"
              placeholder="Жена"
              errors={errorsRelationTenant}
              readonly={isReadonly || isRenterMode}
              maxLength={20}
            />
          )}

          <Select
            className={styles.resident__gender}
            value={gender}
            errors={errorsGender}
            onChange={changeGender}
            label="Пол"
            options={genderOptions}
            placeholder="Пол"
            isGenderMode={true}
            disabled={isReadonly || isRenterMode}
            isSearchable={isReadonly || isRenterMode}
          />

          <InputWithHint
            className={styles.resident__placeBirth}
            value={placeBirth}
            errors={errorsPlaceBirth}
            onChange={changePlaceBirth}
            label="Место рождения"
            placeholder="Город"
            maxLength={80}
            hintStrings={hintStringsPlaceBirth}
            onClickHint={onClickHintPlaceBirth}
            readonly={isReadonly || isRenterMode}
          />
          <div className={styles.resident__inputsRow}>
            <Input
              value={series}
              onChange={changeSeries}
              className={styles.resident__serial}
              label="Серия"
              placeholder="0000"
              maxLength={SERIES_PASSPORT_LENGTH}
              errors={errorsSeries}
              readonly={isReadonly || isRenterMode}
            />
            <Input
              value={number}
              onChange={changeNumberPassport}
              className={styles.resident__numberPassport}
              label="Номер паспорта"
              placeholder="000000"
              maxLength={NUMBER_PASSPORT_LENGTH}
              errors={errorsNumberPassport}
              readonly={isReadonly || isRenterMode}
            />
          </div>
          <Input
            value={issuedBy}
            onChange={changeIssuedBy}
            className={styles.resident__reg}
            label="Кем выдан"
            placeholder="ТП УФМС России"
            maxLength={255}
            errors={errorsIssuedBy}
            readonly={isReadonly || isRenterMode}
          />
          <InputDate
            value={dateIssue}
            onChange={changeDateIssue}
            label={"Дата выдачи"}
            placeholder={"ДД.ММ.ГГГГ"}
            className={styles.resident__dateIssued}
            classNameInput={styles.resident__dateInput}
            errors={errorsDateIssue}
            readonly={isReadonly || isRenterMode}
          />
          {isRenterMode && (
            <InputWithMask
              mask={"999-999"}
              label="Код подразделения"
              placeholder="000-000"
              value={subdivisionCode}
              onChange={changeSubdivisionCode}
              errors={errorsSubdivisionCode}
              className={styles.resident__divisionCode}
              readOnly={true}
            />
          )}
          <InputWithHint
            value={registrationAddress}
            onChange={changeRegistrationAddress}
            className={styles.resident__address}
            label="Адрес регистрации"
            placeholder="Город, улица, дом, квартира"
            maxLength={255}
            errors={errorsRegistrationAddress}
            hintStrings={hintAddressStrings}
            onClickHint={onClickHintAddress}
            readonly={isReadonly || isRenterMode}
          />
        </div>
      </div>
    );
  }
);
