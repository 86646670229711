import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";

import { ITypes } from "../../../interfaces/Catalog";
import { IOptionCommon } from "../../../interfaces";

import { ReactComponent as TrashCan } from "../../../assets/images/trashCan.svg";

import {
  NUMBER_PET_TYPE_LENGTH,
  NUMBER_PET_BREED_LENGTH,
} from "../../../constants";

import styles from "./styles/petForm.module.scss";

interface IProps {
  className?: string;
  valueType: string;
  valueBreed: string;
  valueVetPassport: ITypes | null;
  vetPassportOptions: ITypes[];
  errorsType: string[];
  errorsBreed: string[];
  errorsVetPassport: string[];
  isDisabled: boolean;
  changeType(value: string): void;
  changeBreed(value: string): void;
  changeVetPassport: (value: IOptionCommon) => void;
  onDelete?(): void;
  isTenantsMode?: boolean;
  showTrashCan?: boolean;
}

export const PetForm: FC<IProps> = observer(
  ({
    className = "",
    valueType = "",
    valueBreed = "",
    valueVetPassport = null,
    vetPassportOptions = [],
    errorsType = [],
    errorsBreed = [],
    errorsVetPassport = [],
    isDisabled = false,
    changeType = () => {},
    changeBreed = () => {},
    changeVetPassport = () => {},
    onDelete = () => {},
    isTenantsMode = false,
    showTrashCan = false,
  }) => {
    return (
      <div
        className={`${styles.petForm} ${className} ${
          isTenantsMode ? styles.petForm_tenantsMode : null
        }`}
      >
        {showTrashCan && (
          <div className={styles.petForm__header}>
            <button className={styles.petForm__trashCan} onClick={onDelete}>
              <TrashCan />
            </button>
          </div>
        )}
        <div className={styles.petForm__inputs}>
          <Input
            value={valueType}
            onChange={changeType}
            errors={errorsType}
            className={styles.petForm__type}
            label="Вид"
            placeholder="Кошка"
            maxLength={NUMBER_PET_TYPE_LENGTH}
            readonly={isDisabled}
          />

          <Input
            value={valueBreed}
            onChange={changeBreed}
            className={styles.petForm__breed}
            label="Порода"
            placeholder="Бенгальская"
            errors={errorsBreed}
            maxLength={NUMBER_PET_BREED_LENGTH}
            readonly={isDisabled}
          />

          <Select
            placeholder={"Отсутствует"}
            label={"Ветеринарный паспорт"}
            className={styles.petForm__vetPassport}
            value={valueVetPassport}
            onChange={changeVetPassport}
            options={vetPassportOptions}
            errors={errorsVetPassport}
            disabled={isDisabled}
          />
        </div>
      </div>
    );
  }
);
