import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { BackTo } from "../../../components/BackTo";
import { Footer } from "../../../components/Footer";
import { Loader } from "../../../components/Loader";

import { fullDateFormat } from "../../../helpers";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../stores/Root";

import {
  DESCRIPTION_PAGE_DEFAULT,
  PAGE_TITLE_NEW_DEFAULT,
} from "../../../constants";

import styles from "./styles/newsDetails.module.scss";
import { EPageType } from "../../../types";

export const NewsDetails: FC = observer(() => {
  const navigate = useNavigate();
  const params = useParams();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(rootStore.storeNews, navigate));

  useEffect(() => {
    (async () => {
      await storeUI.init(Number(params.id));
    })();
  }, [storeUI, params.id]);

  let descriptionPage = useMemo(() => {
    return rootStore.metaDescriptionsList.find(
      (item) => item.page === EPageType.NewItem
    );
  }, [rootStore.metaDescriptionsList]);

  let helmet = useMemo(() => {
    let _title =
      descriptionPage?.title ||
      storeUI.newsDetails.name ||
      PAGE_TITLE_NEW_DEFAULT;
    return (
      <Helmet>
        <title>{_title}</title>
        <meta
          name="description"
          content={descriptionPage?.description ?? DESCRIPTION_PAGE_DEFAULT}
        />
      </Helmet>
    );
  }, [descriptionPage, storeUI.newsDetails]);

  return (
    <div className={styles.news}>
      {helmet}
      {storeUI.isLoading ? (
        <div className={styles.news__empty}>
          <Loader />
        </div>
      ) : (
        <div className={styles.news__content}>
          <BackTo text="К списку новостей" onClick={storeUI.toNews} />
          <h2 className={styles.news__title}>{storeUI.newsDetails.name}</h2>
          <p className={styles.news__date}>
            {storeUI.newsDetails.created_at &&
              fullDateFormat(storeUI.newsDetails.created_at)}
          </p>
          <img
            className={styles.news__img}
            src={storeUI.newsDetails.image}
            alt="news"
          />
          <p className={styles.news__text}>
            {storeUI.newsParagraphs.length > 0 &&
              storeUI.newsParagraphs.map((item) => <p>{item}</p>)}
          </p>
        </div>
      )}
      <div className={styles.footerBlock}>
        <Footer className={styles.footerBlock__footer} />
      </div>
    </div>
  );
});
