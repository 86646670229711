import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Loader } from "../../../components/Loader";
import { BackTo } from "../../../components/BackTo";
import { SelectDate } from "../../../components/DatePicker";
import { Button } from "../../../components/Button";
import { ModalReuse } from "../../../components/Modals/ModalReuse";

import { Store } from "../../../stores/types";

import styles from "./styles/stepArrivalDate.module.scss";

interface IProps {
  storeArrivalDate: Store.IArrivalDateOrderingData | null;
  stepTitle: string;
  onBack(): void;
}

export const StepArrivalDate = observer(
  ({ storeArrivalDate = null, stepTitle = "", onBack = () => {} }: IProps) => {
    const [modalDate, setModalDate] = useState<boolean>(false);

    return (
      storeArrivalDate && (
        <div className={styles.stepArrivalDate}>
          {storeArrivalDate.isLoading && (
            <Loader className={styles.stepArrivalDate__loader} />
          )}

          <BackTo
            text={"Вернуться назад"}
            onClick={onBack}
            className={styles.stepArrivalDate__backBtn}
            disabled={storeArrivalDate.isLoading}
          />

          <div className={styles.stepArrivalDate__header}>
            <h5 className={styles.stepArrivalDate__title}>
              Выберите дату заселения
            </h5>
            <span className={styles.stepArrivalDate__stepNum}>{stepTitle}</span>
          </div>

          <p className={styles.stepArrivalDate__description}>
            Дата заселения не может быть выбрана в день бронирования, так как
            требуется время на подготовку квартиры. <br /> Дата заселения будет
            являться датой заключения договора. В случае изменения даты
            заселения с вами свяжется администратор.
          </p>

          <SelectDate
            type="info"
            title={"Дата заселения"}
            label={"Дата заселения"}
            isShowModal={modalDate}
            setIsShowModal={setModalDate}
            placeholder={"01.01.2023"}
            className={styles.stepArrivalDate__input}
            classNameWrapper={styles.stepArrivalDate__inputDate}
            currentDay={storeArrivalDate.selectedDate}
            changeCurrentDay={storeArrivalDate.changeSelectedDate}
            maxDate={storeArrivalDate.datePeriod.maxDate}
            minDate={storeArrivalDate.datePeriod.minDate}
            errors={storeArrivalDate.errors}
            calendarIsBlack={true}
            classNameCalendar={styles.stepArrivalDate__calendar}
            classNameCalendarContainer={
              styles.stepArrivalDate__calendarContainer
            }
          />

          <Button
            title={"Подтвердить"}
            onClick={storeArrivalDate.sendArrivalDate}
            theme={"gold"}
            className={styles.stepArrivalDate__button}
          />

          <ModalReuse
            isShowModal={storeArrivalDate.isVisibleTimeoutPopup}
            setIsShowModal={storeArrivalDate.setIsVisibleTimeoutPopup}
            type={"button"}
            btnTitle={"Перейти в договора"}
            onClickSingleButton={storeArrivalDate.toAgreements}
          >
            <h2>
              Договор будет сформирован позже. Он появится во вкладке Документы
              / Договора
            </h2>
          </ModalReuse>
        </div>
      )
    );
  }
);
