import { FC } from "react";

import styles from "./styles/duration.module.scss";

interface IProps {
  placeholder?: string;
  value?: string;
  innerRef?: React.LegacyRef<HTMLInputElement> | undefined;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onClick?: () => void;
}

export const Duration: FC<IProps> = ({
  placeholder = "",
  value = "",
  onChange = () => {},
  onClick = () => {},
  innerRef = undefined,
}) => {
  return (
    <input
      ref={innerRef}
      className={styles.input}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onClick={onClick}
    />
  );
};
