import { FC } from "react";

import styles from "./styles/askBtn.module.scss";

interface IProps {
  onClick: () => void;
  Logo: string;
}

export const AskBtn: FC<IProps> = ({ onClick = () => {}, Logo = "" }) => {
  return (
    <button className={styles.ask} onClick={onClick}>
      <Logo />
      <p className={styles.ask__title}>Задать вопрос</p>
    </button>
  );
};
