import { FC, useState } from "react";

import { Button } from "../../Button";

import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { OPEN_MENU_DOCS } from "../../../constants";

import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as SuccessImg } from "../../../assets/images/success.svg";

import styles from "./styles/success.module.scss";

interface IProps {
  modal: boolean;
  goToBills(): void;
  setModal(modal: boolean): void;
}

export const Success: FC<IProps> = ({
  modal = false,
  goToBills = () => {},
  setModal = () => {},
}) => {
  let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
    useState<EventTarget | null>(null);

  const _closeModal = () => {
    setModal(false);
    enableScroll();
  };

  const _closeModalBackground = () => {
    if (backgroundOnDownTarget) {
      _closeModal();
    }

    setBackgroundOnDownTarget(null);
  };

  const _goToBills = () => {
    setModal(false);
    goToBills();
    enableScroll();
    // чтобы меню Документы было сразу развернуто
    localStorage.setItem(OPEN_MENU_DOCS, "open");
  };

  return (
    <>
      {modal && (
        <div
          className={styles.success}
          onClick={_closeModalBackground}
          onMouseDown={(e) => {
            setBackgroundOnDownTarget(e.target);
          }}
        >
          <div className={styles.success__popupWrapper}>
            <div
              className={styles.success__popup}
              onClick={stopPropagation}
              onMouseDown={stopPropagation}
            >
              <Close className={styles.success__close} onClick={_closeModal} />
              <div className={styles.success__content}>
                <div className={styles.success__titleImgWrapper}>
                  <p className={styles.success__title}>
                    Для подтверждения бронирования оплатите счет в разделе счета
                  </p>
                  <SuccessImg className={styles.success__img} />
                </div>
                <Button
                  title="Перейти в раздел счета"
                  type="submit"
                  theme="gold"
                  onClick={_goToBills}
                  className={styles.success__button}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
