import { FC } from "react";
import { Link } from "react-router-dom";

import { RevealAnimation } from "../RevealAnimation";

import { SCREENS } from "../../../navigation/endpoints";
import { PYROBYTE_SITE } from "../../../constants";
import { currentYear } from "../../../helpers";

import { ReactComponent as Pyrobyte } from "../../../assets/images/pyrobyte.svg";

import styles from "./styles/mainFooter.module.scss";

export const MainFooter: FC = () => {
  return (
    <div className={styles.footer}>
      <RevealAnimation duration={800}>
        <p className={styles.footer__copyTop}>
          © Клубный дом на 25 октября {currentYear}
        </p>
      </RevealAnimation>
      <a
        href={PYROBYTE_SITE}
        target="_blank"
        className={styles.footer__dev}
        rel="noreferrer"
      >
        <RevealAnimation duration={800}>
          <Pyrobyte />
          <p className={styles.footer__text}>Создание сайта</p>
        </RevealAnimation>
      </a>
      <RevealAnimation duration={800}>
        <Link
          to={SCREENS.SCREEN_TEXT_PERSONAL_DATA}
          target="_blank"
          className={styles.footer__agreement}
        >
          Пользовательское соглашение
        </Link>
      </RevealAnimation>
    </div>
  );
};
