import { FC, useState } from "react";

import { Button } from "../../Button";
import { enableScroll, stopPropagation } from "../../../helpers/modalsFunc";

import { Loader } from "../../Loader";

import { TButtonsModalReuse } from "../../../types";

import { ReactComponent as Close } from "../../../assets/images/close.svg";

import styles from "./styles/modalReuse.module.scss";

interface IProps {
  isShowModal: boolean;
  type: TButtonsModalReuse;
  children: React.ReactNode;
  isLoading?: boolean;
  btnTitle?: string;
  btnLeft?: string;
  btnRight?: string;
  classNameModal?: string;
  setIsShowModal(isShowModal: boolean): void;
  onClick?(): void;
  onClickSingleButton?(): void | null;
}

export const ModalReuse: FC<IProps> = ({
  isShowModal = false,
  isLoading = false,
  children = "",
  btnTitle = "",
  btnLeft = "",
  btnRight = "",
  type = "button",
  classNameModal = "",
  onClickSingleButton = null,
  setIsShowModal = () => {},
  onClick = () => {},
}) => {
  let [backgroundOnDownTarget, setBackgroundOnDownTarget] =
    useState<EventTarget | null>(null);

  const _closeModal = () => {
    setIsShowModal(false);
    enableScroll();
  };

  const _closeModalBackground = () => {
    if (backgroundOnDownTarget) {
      _closeModal();
    }

    setBackgroundOnDownTarget(null);
  };

  const _onClick = () => {
    onClick();
    enableScroll();
  };

  return (
    <>
      {isShowModal && (
        <div
          className={styles.modal}
          onClick={_closeModalBackground}
          onMouseDown={(e) => {
            setBackgroundOnDownTarget(e.target);
          }}
        >
          <div className={styles.modal__contentWrapper}>
            {isLoading && <Loader className={styles.modal__loader} />}
            <div
              className={`${styles.modal__content} ${classNameModal}`}
              onClick={stopPropagation}
              onMouseDown={stopPropagation}
            >
              <Close onClick={_closeModal} className={styles.modal__close} />
              <div className={styles.modal__text}>{children}</div>
              {type === "button" ? (
                <Button
                  title={btnTitle.toUpperCase()}
                  type="submit"
                  theme="gold"
                  onClick={
                    onClickSingleButton ? onClickSingleButton : _closeModal
                  }
                  className={styles.modal__button}
                  disabled={isLoading}
                />
              ) : (
                <div className={styles.modal__buttons}>
                  <Button
                    title={btnLeft.toUpperCase()}
                    type="submit"
                    theme="transparent"
                    onClick={_onClick}
                    disabled={isLoading}
                    className={styles.modal__buttonsLeft}
                  />
                  <Button
                    title={btnRight.toUpperCase()}
                    type="button"
                    theme="gold"
                    onClick={_closeModal}
                    className={styles.modal__buttonsRight}
                    disabled={isLoading}
                    isDisabledStyle={isLoading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
