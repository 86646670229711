import { ApiManager } from "..";
import {
  BOOKING_EXTENSION,
  FAVORITE,
  FAVORITE_DELETE,
  BOOKING,
  REVIEWS,
  ARCHIVE,
  BOOKING_BY_ID,
  BOOKING_CAN_EXTENSION,
} from "../../constants/api";

import { TResponseApi } from "../types";

import {
  ICanExtensionResponse,
  ICommonResponse,
  IProlongBookingResponse,
} from "../../interfaces";
import {
  IArchiveItem,
  IBookingApartmentDetail,
  IBookingItem,
  IFavoritesResponse,
  IReviewRequest,
} from "../../interfaces/Account";

export async function getFavorite(): Promise<TResponseApi<IFavoritesResponse>> {
  return await ApiManager.request<IFavoritesResponse>(
    {
      url: FAVORITE,
      method: "GET",
    },
    true
  );
}

export async function deleteFavorite(
  apartmentId: number
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: FAVORITE_DELETE(apartmentId),
      method: "DELETE",
    },
    true
  );
}

export async function getBookings(): Promise<TResponseApi<IBookingItem[]>> {
  return await ApiManager.request<IBookingItem[]>(
    {
      url: BOOKING,
      method: "GET",
    },
    true
  );
}

export async function getBookingById(
  bookingId: number
): Promise<TResponseApi<IBookingApartmentDetail>> {
  return await ApiManager.request<IBookingApartmentDetail>(
    {
      url: BOOKING_BY_ID(bookingId),
      method: "GET",
    },
    true
  );
}

export async function getArchives(): Promise<TResponseApi<IArchiveItem[]>> {
  return await ApiManager.request<IArchiveItem[]>(
    {
      url: ARCHIVE,
      method: "GET",
    },
    true
  );
}

export async function getCanExtension(params: {
  bookingCalendarId: number;
}): Promise<TResponseApi<ICanExtensionResponse>> {
  return await ApiManager.request<ICanExtensionResponse>(
    {
      url: BOOKING_CAN_EXTENSION,
      method: "GET",
      params: params,
    },
    true
  );
}

export async function sendReview(
  params: IReviewRequest
): Promise<TResponseApi<IReviewRequest>> {
  return await ApiManager.request<IReviewRequest>(
    {
      url: REVIEWS,
      data: params,
      method: "POST",
    },
    true
  );
}

export async function sendBookingExtension(params: {
  date: string;
  bookingCalendarId: number;
}): Promise<TResponseApi<IProlongBookingResponse>> {
  return await ApiManager.request<IProlongBookingResponse>(
    {
      url: BOOKING_EXTENSION,
      method: "POST",
      data: params,
    },
    true
  );
}
