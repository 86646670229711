import { observer } from "mobx-react-lite";
import { useState } from "react";

import { IPaymentMethod } from "../../interfaces/Payment";
import { EPaymentMethods } from "../../types";

import { Check } from "../Check";

import { ReactComponent as SberCheckIcon } from "../../assets/images/sberMiniCheck.svg";

import styles from "./styles/paymentMethods.module.scss";

interface IProps {
  methods: IPaymentMethod[];
  onSelectMethod(method: EPaymentMethods): void;
}

export const PaymentMethods = observer(
  ({ methods = [], onSelectMethod = () => {} }: IProps) => {
    const [isSelectedSber, setIsSelectedSber] = useState(false);

    const _onSelectMethod = (method: EPaymentMethods) => {
      onSelectMethod(method);
      setIsSelectedSber(method === EPaymentMethods.SberBusiness);
    };
    return (
      <div className={styles.paymentMethods}>
        {methods?.map((item, index) =>
          item.method === EPaymentMethods.SberBusiness ? (
            <button
              className={`${styles.paymentMethods__sberMiniButton} ${
                item.isSelected
                  ? styles.paymentMethods__sberMiniButton_checked
                  : ""
              }`}
              onClick={() => _onSelectMethod(item.method)}
            >
              <SberCheckIcon />

              <span className={styles.paymentMethods__sberMiniButtonText}>
                Оплатить через СберБизнес
              </span>
            </button>
          ) : (
            <Check
              key={index}
              id={index}
              title={item.name}
              selected={() => _onSelectMethod(item.method)}
              isSelected={item.isSelected}
              className={`${styles.paymentMethods__check} ${
                isSelectedSber ? styles.paymentMethods__check_disabled : ""
              }`}
              withCircleCheck={false}
            />
          )
        )}
      </div>
    );
  }
);
