import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { disableScroll, enableScroll } from "../../../helpers/modalsFunc";

import { IStoreUI } from "./interfaces";

import { Store } from "../../../stores/types";
import {
  ETabsDocuments,
  ETabsResidents,
  ETabsServices,
} from "./../../../types/index";

import { SCREENS } from "../../../navigation/endpoints";

import {
  PERSONAL,
  FAVORITES,
  ARCHIVE,
  SERVICES,
  DOCUMENTS,
  MY_BOOKINGS,
  OPEN_MENU_DOCS,
  RENT,
  RESIDENTS,
  COMPANY,
  FINANCE,
} from "./../../../constants";

import defaultAvatar from "../../../assets/images/defaultAvatar.png";

export class StoreUI implements IStoreUI {
  storeAuthentication: Store.IStoreAuthentication;
  storeRoot: Store.IRootStore;
  isExit: boolean = false;
  storeUser: Store.IUser;
  navigate: NavigateFunction;
  isLoading: boolean = false;
  chosenAvatar: File | null = null;
  image: string = "";
  id: number = 0;
  subMenu: boolean = false;

  constructor(
    storeAuthentication: Store.IStoreAuthentication,
    storeRoot: Store.IRootStore,
    navigate: NavigateFunction
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeRoot = storeRoot;
    this.storeUser = storeRoot.userStore;
    this.storeAuthentication = storeAuthentication;
    this.navigate = navigate;
  }

  // getters

  get avatarImg() {
    return this.storeUser.profile.image || defaultAvatar;
  }

  get name() {
    return `${this.storeUser.profile.lastName || "Гость"} ${
      this.storeUser.profile.firstName || ""
    } ${this.storeUser.profile.middleName || ""}`;
  }

  get personalType() {
    return this.storeUser.profile.personalType;
  }

  // functions

  init() {
    enableScroll();
    if (localStorage.getItem(OPEN_MENU_DOCS)) {
      this.openSubMenu();
    }

    this.checkURL();
  }

  *logout() {
    this.setIsLoading(true);
    yield this.storeAuthentication.logout(this.navigate);
    this.setIsLoading(false);
  }

  checkURL() {
    let urlItems = window.location.href.split("/");

    let isMyBookings = urlItems.includes(MY_BOOKINGS);
    let isPersonal = urlItems.includes(PERSONAL);
    let isFavorites = urlItems.includes(FAVORITES);
    let isArchive = urlItems.includes(ARCHIVE);
    let isCompany = urlItems.includes(COMPANY);
    let isFinance = urlItems.includes(FINANCE);

    let isValidUrl = false;

    if (isMyBookings) {
      isValidUrl = this._checkUrlByTypePage(MY_BOOKINGS, urlItems);
    }

    if (isPersonal) {
      isValidUrl = this._checkUrlByTypePage(PERSONAL, urlItems);
    }

    if (isFavorites) {
      isValidUrl = this._checkUrlByTypePage(FAVORITES, urlItems);
    }

    if (isArchive) {
      isValidUrl = this._checkUrlByTypePage(ARCHIVE, urlItems);
    }

    if (isCompany) {
      isValidUrl = this._checkUrlByTypePage(COMPANY, urlItems);
    }

    if (isFinance) {
      isValidUrl = this._checkUrlByTypePage(FINANCE, urlItems);
    }

    if (!isValidUrl) {
      this.navigate(SCREENS.SCREEN_404);
    }
  }

  private _checkUrlByTypePage(type: string, urlItems: string[]): boolean {
    let urlLastItem = urlItems[urlItems.length - 1];
    let urlPreLastItem = urlItems[urlItems.length - 2];

    let isContainsId = urlItems.find((item) => {
      if (Number(item)) {
        return item;
      }
      return null;
    });

    switch (type) {
      case MY_BOOKINGS:
        let isRent = urlItems.includes(RENT);
        let isResidents = urlItems.includes(RESIDENTS);
        let isServices = urlItems.includes(SERVICES);

        let isValidLastResidentsTab =
          urlLastItem === ETabsResidents.Renter ||
          urlLastItem === ETabsResidents.Tenants ||
          urlLastItem === ETabsResidents.Pets;

        let isDocs = urlItems.includes(DOCUMENTS);

        let isValidLastDocsTab =
          urlLastItem === ETabsDocuments.Acts ||
          urlLastItem === ETabsDocuments.Agreements ||
          urlLastItem === ETabsDocuments.Bills ||
          urlLastItem === ETabsDocuments.Others;

        let isOnlyMyBookings =
          urlItems.includes(MY_BOOKINGS) && urlLastItem === MY_BOOKINGS;

        let isBooking = urlPreLastItem === MY_BOOKINGS;

        let isValidServiceTab =
          urlLastItem === ETabsServices.Selected ||
          urlLastItem === ETabsServices.Available ||
          urlLastItem === ETabsServices.MyServices;

        return (
          isOnlyMyBookings ||
          (Boolean(isContainsId) &&
            (isBooking ||
              (isRent && urlLastItem === RENT) ||
              (isResidents &&
                urlPreLastItem === RESIDENTS &&
                isValidLastResidentsTab) ||
              (isDocs && urlPreLastItem === DOCUMENTS && isValidLastDocsTab) ||
              (isServices && urlPreLastItem === SERVICES && isValidServiceTab)))
        );

      case PERSONAL:
        return urlLastItem === PERSONAL;
      case FAVORITES:
        return urlLastItem === FAVORITES;
      case ARCHIVE:
        let isValidDocumentTab =
          urlLastItem === ETabsDocuments.Agreements ||
          urlLastItem === ETabsDocuments.Bills ||
          urlLastItem === ETabsDocuments.Acts;

        let isArchive = urlItems.includes(ARCHIVE);
        let isOnlyArchive =
          urlItems.includes(ARCHIVE) && urlLastItem === ARCHIVE;

        return isOnlyArchive || (isArchive && isValidDocumentTab);

      case COMPANY:
        return urlLastItem === COMPANY;

      case FINANCE:
        return urlLastItem === FINANCE;

      default:
        return false;
    }
  }

  clickToDocs(isDocs: boolean) {
    if (isDocs) {
      localStorage.setItem(OPEN_MENU_DOCS, "open");
    } else {
      localStorage.removeItem(OPEN_MENU_DOCS);
    }
  }

  openExit() {
    this.setIsExit(true);
    disableScroll();
  }

  closeExit() {
    this.setIsExit(false);
    enableScroll();
  }

  openSubMenu() {
    this.setSubMenu(true);
  }

  closeSubMenu() {
    this.setSubMenu(false);
  }

  toggleSubMenu() {
    this.setSubMenu(!this.subMenu);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsExit(value: boolean) {
    this.isExit = value;
  }

  setSubMenu(value: boolean) {
    this.subMenu = value;
  }
}
