import { makeAutoObservable } from "mobx";
import parse from "html-react-parser";

import { ETextMainId } from "../../../../constants";

import { Store } from "../../../../stores/types";
import { TParsedContent } from "../../../../types";

import { IStoreUI } from "./interfaces";

const classTitle = "h2";
const classServiceLeft = "serviceLeft";
const classServiceRight = "serviceRight";

export class StoreUI implements IStoreUI {
  storeRoot: Store.IRootStore;

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
  }

  // getters

  get content() {
    if (this.storeRoot.mainTextBlocks?.length) {
      return (
        this.storeRoot.mainTextBlocks.find(
          (block) => block.id === ETextMainId.ThirdBlockId
        )?.text || ""
      );
    }
    return "";
  }

  get parsedContent(): TParsedContent {
    if (this.content) {
      return parse(this.content);
    }

    return "";
  }

  get title() {
    let title: JSX.Element | null = null;

    if (this.parsedContent && Array.isArray(this.parsedContent)) {
      title =
        this.parsedContent.find((item) => item.type === classTitle) ?? null;
    }

    return title ?? "Не удалось загрузить контент";
  }

  get leftItems() {
    let leftItems: JSX.Element[] | null = null;

    if (this.parsedContent && Array.isArray(this.parsedContent)) {
      leftItems =
        this.parsedContent.filter(
          (item) => item.props?.className === classServiceLeft
        ) ?? null;
    }

    return leftItems ?? "Не удалось загрузить контент";
  }

  get rightItems() {
    let rightItems: JSX.Element[] | null = null;

    if (this.parsedContent && Array.isArray(this.parsedContent)) {
      rightItems =
        this.parsedContent.filter(
          (item) => item.props?.className === classServiceRight
        ) ?? null;
    }

    return rightItems ?? "Не удалось загрузить контент";
  }
}
