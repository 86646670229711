import { useMemo } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../../../../components/Button";
import { MenuDots } from "../../../../../../components/MenuDots";
import { Loader } from "../../../../../../components/Loader";

import { useOutSide } from "../../../../../../hooks/useOutSide";

import { EStatusBill } from "../../../../../../types";

import { ReactComponent as Menu } from "../../../../../../assets/images/menuDots.svg";

import styles from "./styles/bill.module.scss";

interface IProps {
  id: number;
  documentId: number;
  number: string;
  status: EStatusBill | null;
  readyStatus: string | null;
  date: string;
  dateEnd: string;
  price: string;
  disablePaidButton: boolean;
  isLoadingDownload: boolean;
  isArchiveMode?: boolean;
  onDownload(documentId: number): void;
  onShow(documentId: number): void;
  onPaid(id: number): void;
}

export const Bill = observer(
  ({
    id = 0,
    documentId = 0,
    number = "",
    status = null,
    readyStatus = "",
    date = "",
    dateEnd = "",
    price = "",
    isLoadingDownload = false,
    isArchiveMode = false,
    disablePaidButton = false,
    onDownload = () => {},
    onShow = () => {},
    onPaid = () => {},
  }: IProps) => {
    const { ref, isShow, setIsShow } = useOutSide(false);

    let statusClass = useMemo(() => {
      switch (status) {
        case EStatusBill.Paid:
          return styles.bill__status_paid;
        case EStatusBill.NotPaid:
          return styles.bill__status_unpaid;
        case EStatusBill.ParticallyPaid:
          return styles.bill__status_particallyPaid;
        default:
          return "";
      }
    }, [status]);

    let _isDisabledButton = useMemo(() => {
      return disablePaidButton || isArchiveMode || isLoadingDownload;
    }, [disablePaidButton, isArchiveMode, isLoadingDownload]);

    return (
      <div className={styles.bill}>
        <div className={styles.bill__top}>
          <div className={styles.bill__numberStatusWrapper}>
            <h5 className={styles.bill__number}>№{number}</h5>
            <h6 className={`${styles.bill__status} ${statusClass}`}>
              {readyStatus ?? "-"}
            </h6>
          </div>
          <div className={styles.bill__buttons}>
            <Button
              title="Оплатить"
              theme="white"
              onClick={() => onPaid(id)}
              className={`${styles.bill__button} ${
                disablePaidButton ? styles.bill__button_disabled : ""
              }`}
              isDisabledStyle={_isDisabledButton}
              disabled={_isDisabledButton}
            />

            {isLoadingDownload ? (
              <div className={styles.bill__menuLoading}>
                <Loader className={styles.bill__loader} />
              </div>
            ) : (
              <span
                className={styles.bill__menuWrapper}
                onClick={() => setIsShow(!isShow)}
                ref={ref}
              >
                <Menu className={styles.bill__menu} />
                {isShow && (
                  <MenuDots
                    className={styles.bill__dropList}
                    withShow={true}
                    onClickDownlooad={() => onDownload(documentId)}
                    onClickShow={() => onShow(documentId)}
                  />
                )}
              </span>
            )}
          </div>
        </div>
        <div className={styles.bill__bottom}>
          <div>
            <h6 className={styles.bill__title}>Выставлен от</h6>
            <p className={styles.bill__info}>{date}</p>
          </div>
          <div className={styles.bill__payTill}>
            <h6 className={styles.bill__title}>Оплатить до</h6>
            <p className={styles.bill__info}>{dateEnd}</p>
          </div>
          <div className={styles.bill__price}>
            <h6 className={styles.bill__title}>Сумма</h6>
            <p className={styles.bill__info}>{price} руб</p>
          </div>
        </div>
      </div>
    );
  }
);
