import { makeAutoObservable } from "mobx";

import { IStoreUI } from "./interfaces";

import { openPdf } from "../../../../../../helpers/openPdf";
import { downloadPdf } from "../../../../../../helpers/downloadPdf";

import { Store } from "../../../../../../stores/types";
import { EAgreementStatus } from "../../../../../../types";

import {
  PDF_NAME_AGREEMENT,
  STATUS_AGREEMENT_TERMINATED,
  STATUS_AGREEMENT_NEW,
  STATUS_AGREEMENT_ACTIVE,
  STATUS_AGREEMENT_UPLOADED,
} from "../../../../../../constants";

export class StoreUI implements IStoreUI {
  storeAgreements: Store.IAgreementsStore;
  storeDocuments: Store.IDocumentsStore;
  isMainLoading: boolean = false;
  isLoadingDownload: boolean = false;

  currentLoadingId: number | null = null;

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeAgreements = storeRoot.storeAgreements;
    this.storeDocuments = storeRoot.storeDocuments;
  }

  //getters

  get generatedAgreements() {
    return this.storeAgreements.generatedAgreements.map((item) => ({
      id: item.id,
      numberDocument: item.numberAgreement,
      status: this.getReadyStatus(item.status),
      isUploaded: false,
    }));
  }

  get uploadedAgreements() {
    return this.storeAgreements.uploadedAgreements.map((item) => ({
      id: item.documentId,
      numberDocument: item.name,
      status: STATUS_AGREEMENT_UPLOADED,
      isUploaded: true,
    }));
  }

  // functions

  *init(bookingId: number) {
    this.setIsMainLoading(true);
    yield this.storeAgreements.getAgreements(bookingId);
    this.setIsMainLoading(false);
  }

  *getAgreementData(id: number, isUploaded: boolean) {
    if (isUploaded) {
      yield this.storeDocuments.getDocumentPdf(id);

      if (this.storeDocuments?.documentBase64.length) {
        return this.storeDocuments?.documentBase64;
      }
      return "";
    }

    yield this.storeAgreements.getAgreementPdf(id);

    if (this.storeAgreements?.agreementPdfById.length) {
      return this.storeAgreements?.agreementPdfById;
    }
    return "";
  }

  getReadyStatus(status: EAgreementStatus) {
    switch (status) {
      case EAgreementStatus.New:
        return STATUS_AGREEMENT_NEW;
      case EAgreementStatus.Terminated:
        return STATUS_AGREEMENT_TERMINATED;
      case EAgreementStatus.Active:
        return STATUS_AGREEMENT_ACTIVE;
      default:
        return "";
    }
  }

  *onShowPdf(agreementId: number, isUploaded: boolean) {
    this.setCurrentLoadingId(agreementId);
    let agreement: string = yield this.getAgreementData(
      agreementId,
      isUploaded
    );

    openPdf(agreement);
    this.setCurrentLoadingId(null);
  }

  *onDownloadPdf(agreementId: number, isUploaded: boolean) {
    this.setCurrentLoadingId(agreementId);
    let agreement: string = yield this.getAgreementData(
      agreementId,
      isUploaded
    );

    if (agreement.length) {
      downloadPdf(agreement, PDF_NAME_AGREEMENT);
    }
    this.setCurrentLoadingId(null);
  }

  // setters

  setIsMainLoading(value: boolean) {
    this.isMainLoading = value;
  }

  setIsLoadingDownload(value: boolean) {
    this.isLoadingDownload = value;
  }

  setCurrentLoadingId(value: number | null) {
    this.currentLoadingId = value;
  }
}
