import { makeAutoObservable } from "mobx";
import { parse, isValid } from "date-fns";
import { Validator } from "validator-pyrobyte";
import { Location, NavigateFunction } from "react-router-dom";

import { newRules } from "../../../../../helpers/validation";
import { checkFile } from "../../../../../helpers";
import { disableScroll, enableScroll } from "../../../../../helpers/modalsFunc";
import { checkStateLocationForToBack } from "../../../../../helpers/checkStateLocationForToBack";

import {
  BACK_TO_ORDERING_DATA,
  ERROR_CORRECT_DATE,
  ERROR_MIN_CHARS_THREE,
  ERROR_MIN_CHARS_TWO,
  ERROR_NUMBER_LENGTH,
  ERROR_SERIES_LENGTH,
  ERROR_TEXT_EMPTY,
  GENDER_FEMALE,
  GENDER_FEMALE_LABEL,
  GENDER_MALE,
  GENDER_MALE_LABEL,
  MAX_AVATAR_SIZE_B,
  MIN_YEAR,
  NUMBER_PASSPORT_LENGTH,
  OPTION_GENDER_FEMALE,
  OPTION_GENDER_MALE,
  PAGE_ORDERING_DATA_BY_ID,
  SERIES_PASSPORT_LENGTH,
  SUBDIVISION_CODE_LENGTH,
} from "../../../../../constants";

import { IProfileErrors, IStoreUI } from "./interfaces";
import { IOptionCommon } from "../../../../../interfaces";
import { IUserProfile } from "../../../../../interfaces/User";

import { TGender } from "../../../../../types";

import { SCREENS } from "../../../../../navigation/endpoints";

import { Store } from "../../../../../stores/types";

import defaultAvatar from "../../../../../assets/images/defaultAvatar.png";

enum EInputs {
  Email = "email",
  LastName = "lastName",
  FirstName = "firstName",
  MiddleName = "middleName",
  Birthdate = "birthdate",
  PlaceBirth = "placeBirth",
  Gender = "gender",
  PassportSeries = "passportSeries",
  PassportNumber = "passportNumber",
  PassportSubdivisionCode = "passportSubdivisionCode",
  PassportDateIssue = "passportDateIssue",
  PassportIssued = "passportIssued",
  PassportAddressRegister = "passportAddressRegister",
}

export class StoreUI implements IStoreUI {
  storeAuthentication: Store.IStoreAuthentication;
  storeRoot: Store.IRootStore;
  storeUser: Store.IUser;
  navigate: NavigateFunction;
  location: Location;
  isLoading: boolean = false;
  isLoadingDelete: boolean = false;
  isLoadingSave: boolean = false;
  isModalDelete: boolean = false;
  isModalDeleteSuccess: boolean = false;
  isModalSave: boolean = false;
  isModalDate: boolean = false;
  image: string = "";
  id: number = 0;
  email: string = "";
  lastName: string = "";
  firstName: string = "";
  middleName: string = "";
  birthdate: string = "";
  placeBirth: string = "";
  phone: string = "";
  genderOptions: IOptionCommon[] = [
    { id: 1, label: GENDER_MALE_LABEL, value: GENDER_MALE },
    { id: 2, label: GENDER_FEMALE_LABEL, value: GENDER_FEMALE },
  ];
  gender: IOptionCommon | null = null;
  passportSeries: string = "";
  passportNumber: string = "";
  passportSubdivisionCode: string = "";
  passportIssued: string = "";
  passportDateIssue: string = "";
  passportAddressRegister: string = "";
  visibleModalFileSize: boolean = false;
  validator: Validator;

  validateErrors: IProfileErrors = {
    [EInputs.Email]: [],
    [EInputs.LastName]: [],
    [EInputs.FirstName]: [],
    [EInputs.MiddleName]: [],
    [EInputs.Birthdate]: [],
    [EInputs.PlaceBirth]: [],
    [EInputs.Gender]: [],
    [EInputs.PassportSeries]: [],
    [EInputs.PassportNumber]: [],
    [EInputs.PassportSubdivisionCode]: [],
    [EInputs.PassportDateIssue]: [],
    [EInputs.PassportIssued]: [],
    [EInputs.PassportAddressRegister]: [],
  };

  hintStringsAddress: string[] = [];
  hintStringsPlaceBirth: string[] = [];

  constructor(
    storeAuthentication: Store.IStoreAuthentication,
    storeRoot: Store.IRootStore,
    navigate: NavigateFunction,
    location: Location
  ) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeRoot = storeRoot;
    this.storeUser = storeRoot.userStore;
    this.storeAuthentication = storeAuthentication;
    this.navigate = navigate;
    this.location = location;

    this.validator = new Validator(
      {
        name: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
      },
      newRules
    );
  }

  get avatarImg() {
    return this.storeUser.profile.image || defaultAvatar;
  }

  get errorsServer() {
    return this.storeUser.serverErrors;
  }

  get isSuccessUpdate() {
    return this.storeUser.isSuccessUpdatedProfile;
  }

  get chosenAvatar() {
    return this.storeUser.chosenAvatar;
  }

  getGenderOption(value: TGender) {
    switch (value) {
      case GENDER_MALE:
        return OPTION_GENDER_MALE;
      case GENDER_FEMALE:
        return OPTION_GENDER_FEMALE;
      default:
        return null;
    }
  }

  init() {
    this.setIsLoading(true);
    this.setLastName(this.storeUser.profile.lastName || "");
    this.setFirstName(this.storeUser.profile.firstName || "");
    this.setMiddleName(this.storeUser.profile.middleName || "");
    this.setPlaceBirth(this.storeUser.profile.placeBirth || "");
    this.setDateBirth(
      this.convertDateForInit(this.storeUser.profile.birthdate)
    );
    this.setEmail(this.storeUser.profile.email || "");
    this.setGender(this.getGenderOption(this.storeUser.profile.gender || null));
    this.setPhone(this.storeUser.profile.phone);
    this.setPassportSeries(this.storeUser.profile.passportSeries || "");
    this.setPassportNumber(this.storeUser.profile.passportNumber || "");
    this.setPassportCode(this.storeUser.profile.passportSubdivisionCode || "");
    this.setPassportDateIssue(
      this.convertDateForInit(this.storeUser.profile.passportDateIssue)
    );
    this.setPassportIssued(this.storeUser.profile.passportIssued || "");
    this.setPassportAddressRegister(
      this.storeUser.profile.passportAddressRegister || ""
    );

    this.setIsLoading(false);
  }

  *changeUserAvatar() {
    yield this.storeUser.changeUserAvatar();
    yield this.storeUser.getPublicProfile();
  }

  *updateProfile() {
    this.validateAll();
    if (this.checkValidateAll()) {
      let _gender: TGender = GENDER_MALE;

      if (
        this.gender?.value === GENDER_MALE ||
        this.gender?.value === GENDER_FEMALE
      ) {
        _gender = this.gender.value;
      }

      let params: IUserProfile = {
        lastName: this.lastName,
        firstName: this.firstName,
        middleName: this.middleName,
        birthdate: this.convertDateForSend(this.birthdate),
        phone: this.phone,
        placeBirth: this.placeBirth,
        email: this.email,
        gender: _gender,
        passportSeries: this.passportSeries,
        passportNumber: this.passportNumber,
        passportSubdivisionCode: this.passportSubdivisionCode,
        passportIssued: this.passportIssued,
        passportDateIssue: this.convertDateForSend(this.passportDateIssue),
        passportAddressRegister: this.passportAddressRegister,
      };

      this.setIsLoadingSave(true);
      yield this.storeUser.updateProfile(params);
      yield this.storeUser.getPublicProfile();

      if (this.storeUser.isSuccessUpdatedProfile) {
        if (checkStateLocationForToBack(BACK_TO_ORDERING_DATA, this.location)) {
          this.toOrderData();
          return;
        }
        this.openSaveModal();
      }

      this.setIsLoadingSave(false);
      return this.storeUser.isSuccessUpdatedProfile;
    }
  }

  toOrderData() {
    // вытаскиваем объект state из локации
    let { state } = this.location;

    if (state) {
      let { bookingId }: any = state;

      // если у него есть bookingId, переходим на страницу Заполнение данных заказа
      // с передачей параметром fromProfile чтобы там вызвать функцию
      if (bookingId) {
        this.navigate(PAGE_ORDERING_DATA_BY_ID(bookingId), {
          state: { fromProfile: true },
        });
      }
    }
  }

  *setUserAvatar(value: React.ChangeEvent<HTMLInputElement>) {
    this.setIsLoadingSave(true);
    if (value.target.files) {
      const [image] = value.target.files;
      yield checkFile(
        image,
        MAX_AVATAR_SIZE_B,
        this.openModalFileSize,
        (file: File) => {
          this.setChosenAvatar(file);
          this.changeUserAvatar();
        }
      );
    }
    this.setIsLoadingSave(false);
  }

  *deleteProfile() {
    this.setIsLoadingDelete(true);
    let response: boolean = yield this.storeUser.deleteProfile();
    if (response) {
      this.closeDeleteModal();
      this.openDeleteModalSuccess();
    }
    this.setIsLoadingDelete(false);
  }

  *getHintByString(value: string) {
    yield this.storeRoot.userStore.getHintByString(value);

    if (this.storeRoot.userStore.hintStrings.length > 0) {
      return this.storeRoot.userStore.hintStrings;
    }
    return [];
  }

  onClickHintAddress(value: string) {
    this._onChangePassportAddressRegister(value);
    this.setHintStringsAddress([]);
  }

  onClickHintPlaceBirth(value: string) {
    this._onChangePlaceBirth(value);
    this.setHintStringsPlaceBirth([]);
  }

  convertDateForInit(date: string) {
    if (date) {
      let dateArr = date.split("-").reverse();
      return dateArr.join(".");
    }
    return "";
  }

  convertDateForSend(date: string) {
    if (date) {
      let dateArr = date.split(".").reverse();
      return dateArr.join("-");
    }
    return "";
  }

  validateLastName() {
    if (!this.lastName.length) {
      this.addValidationError(EInputs.LastName, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.LastName);
    }

    if (this.lastName.length < 2) {
      this.addValidationError(EInputs.LastName, ERROR_MIN_CHARS_TWO);
      return false;
    } else {
      this.clearValidationErrors(EInputs.LastName);
    }

    return true;
  }

  validateFirstName() {
    if (!this.firstName.length) {
      this.addValidationError(EInputs.FirstName, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.FirstName);
    }

    if (this.firstName.length < 2) {
      this.addValidationError(EInputs.FirstName, ERROR_MIN_CHARS_TWO);
      return false;
    } else {
      this.clearValidationErrors(EInputs.FirstName);
    }

    return true;
  }

  validateDateBirth() {
    if (!this.birthdate.length) {
      this.addValidationError(EInputs.Birthdate, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.Birthdate);
    }

    //проверка на ввод будущих дат
    let birthdate = parse(this.birthdate, "dd.MM.yyyy", new Date());
    let now = new Date();

    let nowTime = now.getTime();
    let birthdateTime = birthdate.getTime();
    let isNextDate = birthdateTime > nowTime;

    let isCorrect = isValid(birthdate);

    if (!isCorrect || isNextDate || birthdate.getFullYear() <= MIN_YEAR) {
      this.addValidationError(EInputs.Birthdate, ERROR_CORRECT_DATE);
      return false;
    } else {
      this.clearValidationErrors(EInputs.Birthdate);
    }

    return true;
  }

  validatePlaceBirth() {
    if (!this.placeBirth.length) {
      this.addValidationError(EInputs.PlaceBirth, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PlaceBirth);
    }

    if (this.placeBirth.length < 3) {
      this.addValidationError(EInputs.PlaceBirth, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PlaceBirth);
    }

    return true;
  }

  validateEmail() {
    let validEmail = this.validator.check("email", this.email);

    if (!this.email.length) {
      this.addValidationError(EInputs.Email, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.Email);
    }

    if (!validEmail.passed) {
      this.addValidationError(EInputs.Email, validEmail.errors[0]);
      return false;
    } else {
      this.clearValidationErrors(EInputs.Email);
    }

    return true;
  }

  validateGender() {
    if (!this.gender) {
      this.addValidationError(EInputs.Gender, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.Gender);
    }
    return true;
  }

  validateSeries() {
    if (!this.passportSeries.length) {
      this.addValidationError(EInputs.PassportSeries, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportSeries);
    }

    if (this.passportSeries.length < SERIES_PASSPORT_LENGTH) {
      this.addValidationError(EInputs.PassportSeries, ERROR_SERIES_LENGTH);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportSeries);
    }
    return true;
  }

  validatePassportNumber() {
    if (!this.passportNumber.length) {
      this.addValidationError(EInputs.PassportNumber, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportNumber);
    }

    if (this.passportNumber.length < NUMBER_PASSPORT_LENGTH) {
      this.addValidationError(EInputs.PassportNumber, ERROR_NUMBER_LENGTH);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportNumber);
    }
    return true;
  }

  validatePassportCode() {
    if (!this.passportSubdivisionCode.length) {
      this.addValidationError(
        EInputs.PassportSubdivisionCode,
        ERROR_TEXT_EMPTY
      );
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportSubdivisionCode);
    }

    if (
      this.passportSubdivisionCode.replaceAll("_", "").length <
      SUBDIVISION_CODE_LENGTH
    ) {
      this.addValidationError(
        EInputs.PassportSubdivisionCode,
        ERROR_NUMBER_LENGTH
      );
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportSubdivisionCode);
    }

    return true;
  }

  validateDate() {
    if (!this.passportDateIssue.length) {
      this.addValidationError(EInputs.PassportDateIssue, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportDateIssue);
    }

    //проверка на ввод будущих дат
    let birthdate = parse(this.passportDateIssue, "dd.MM.yyyy", new Date());
    let now = new Date();

    let nowTime = now.getTime();
    let birthdateTime = birthdate.getTime();
    let isNextDate = birthdateTime > nowTime;

    let isCorrect = isValid(birthdate);

    if (!isCorrect || isNextDate || birthdate.getFullYear() <= MIN_YEAR) {
      this.addValidationError(EInputs.PassportDateIssue, ERROR_CORRECT_DATE);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportDateIssue);
    }

    return true;
  }

  validateIssuedBy() {
    if (!this.passportIssued.length) {
      this.addValidationError(EInputs.PassportIssued, ERROR_TEXT_EMPTY);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportIssued);
    }

    if (this.passportIssued.length < 3) {
      this.addValidationError(EInputs.PassportIssued, ERROR_MIN_CHARS_THREE);
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportIssued);
    }

    return true;
  }

  validateAddress() {
    if (!this.passportAddressRegister.length) {
      this.addValidationError(
        EInputs.PassportAddressRegister,
        ERROR_TEXT_EMPTY
      );
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportAddressRegister);
    }

    if (this.passportAddressRegister.length < 3) {
      this.addValidationError(
        EInputs.PassportAddressRegister,
        ERROR_MIN_CHARS_THREE
      );
      return false;
    } else {
      this.clearValidationErrors(EInputs.PassportAddressRegister);
    }

    return true;
  }

  addValidationError(type: EInputs, value: string) {
    this.validateErrors[type] = [...this.validateErrors[type], value];
  }

  clearValidationErrors(type: EInputs) {
    this.validateErrors[type] = [];
  }

  validateAll() {
    this.validateLastName();
    this.validateFirstName();
    this.validatePlaceBirth();
    this.validateDateBirth();
    this.validateEmail();
    this.validateGender();
    this.validateSeries();
    this.validatePassportNumber();
    this.validatePassportCode();
    this.validateDate();
    this.validateIssuedBy();
    this.validateAddress();
  }

  checkValidateAll() {
    return (
      this.validateLastName() &&
      this.validateFirstName() &&
      this.validatePlaceBirth() &&
      this.validateDateBirth() &&
      this.validateEmail() &&
      this.validateGender() &&
      this.validateSeries() &&
      this.validatePassportNumber() &&
      this.validatePassportCode() &&
      this.validateDate() &&
      this.validateIssuedBy() &&
      this.validateAddress()
    );
  }

  onChangeLastName(value: string) {
    this.setLastName(value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, ""));

    if (this.validateErrors.lastName.length) {
      this.clearValidationErrors(EInputs.LastName);
    }
  }

  onChangeFirstName(value: string) {
    this.setFirstName(value.replace(/[^a-zA-ZА-Я а-яЁё\-/]/gi, ""));

    if (this.validateErrors.firstName.length) {
      this.clearValidationErrors(EInputs.FirstName);
    }
  }

  onChangeMiddleName(value: string) {
    this.setMiddleName(value.replace(/[^a-zA-ZА-Я а-яЁё]/gi, ""));

    if (this.validateErrors.middleName.length) {
      this.clearValidationErrors(EInputs.MiddleName);
    }
  }

  onChangeDateBirth(value: string) {
    this.setDateBirth(value);

    if (this.validateErrors.birthdate.length && this.birthdate.length) {
      this.clearValidationErrors(EInputs.Birthdate);
    }
  }

  onChangeEmail(value: string) {
    this.setEmail(value);

    if (this.validateErrors.email.length) {
      this.clearValidationErrors(EInputs.Email);
    }
  }

  onChangePhone(value: string) {
    this.setPhone(value);
  }

  onChangePassportSeries(value: string) {
    this.setPassportSeries(value.replace(/[^0-9]+/, ""));

    if (
      this.validateErrors.passportSeries.length &&
      this.passportSeries.length
    ) {
      this.clearValidationErrors(EInputs.PassportSeries);
    }
  }

  onChangePassportNumber(value: string) {
    this.setPassportNumber(value.replace(/[^0-9]+/, ""));

    if (
      this.validateErrors.passportNumber.length &&
      this.passportNumber.length
    ) {
      this.clearValidationErrors(EInputs.PassportNumber);
    }
  }

  onChangePassportCode(value: string) {
    this.setPassportCode(
      value.replace(/(\d{3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + "-")
    );

    if (
      this.validateErrors.passportSubdivisionCode.length &&
      this.passportSubdivisionCode.length
    ) {
      this.clearValidationErrors(EInputs.PassportSubdivisionCode);
    }
  }

  onChangePassportDateIssue(value: string) {
    this.setPassportDateIssue(value);

    if (
      this.validateErrors.passportDateIssue.length &&
      this.passportDateIssue.length
    ) {
      this.clearValidationErrors(EInputs.PassportDateIssue);
    }
  }

  onChangePassportIssued(value: string) {
    this.setPassportIssued(value);

    if (this.validateErrors.passportIssued.length) {
      this.clearValidationErrors(EInputs.PassportIssued);
    }
  }

  *onChangeAddressRegister(value: string) {
    this._onChangePassportAddressRegister(value);
    if (value.length > 0) {
      let _hints: string[] = yield this.getHintByString(value);
      this.setHintStringsAddress(_hints);
    }
  }

  private _onChangePassportAddressRegister(value: string) {
    this.setPassportAddressRegister(value);

    if (this.validateErrors.passportAddressRegister.length) {
      this.clearValidationErrors(EInputs.PassportAddressRegister);
    }
  }

  *onChangePlaceBirth(value: string) {
    this._onChangePlaceBirth(value);
    if (value.length > 0) {
      let _hints: string[] = yield this.getHintByString(value);
      this.setHintStringsPlaceBirth(_hints);
    } else {
      this.hintStringsPlaceBirth = [];
    }
  }

  private _onChangePlaceBirth(value: string) {
    this.setPlaceBirth(value);

    if (this.validateErrors.placeBirth.length) {
      this.clearValidationErrors(EInputs.PlaceBirth);
    }
  }

  onChangeGender(gender: IOptionCommon | null) {
    this.setGender(gender);
  }

  openModalFileSize() {
    this.visibleModalFileSize = true;
    disableScroll();
  }

  closeModalFileSize() {
    this.visibleModalFileSize = false;
    enableScroll();
  }

  openDeleteModal() {
    this.isModalDelete = true;
    disableScroll();
  }

  closeDeleteModal() {
    this.isModalDelete = false;
    enableScroll();
  }

  openDeleteModalSuccess() {
    this.isModalDeleteSuccess = true;
    disableScroll();
  }

  closeDeleteModalSuccess() {
    this.isModalDeleteSuccess = false;
    this.navigate(SCREENS.SCREEN_MAIN);
    this.storeAuthentication.deleteToken();
    this.storeAuthentication.setIsAuth(false);
    enableScroll();
  }

  openSaveModal() {
    this.isModalSave = true;
    disableScroll();
  }

  closeSaveModal() {
    this.isModalSave = false;
    enableScroll();
  }

  openDateModal() {
    this.isModalDate = true;
    disableScroll();
  }

  closeDateModal() {
    this.isModalDate = false;
    enableScroll();
  }

  // setters

  setLastName(value: string) {
    this.lastName = value;
  }

  setFirstName(value: string) {
    this.firstName = value;
  }

  setMiddleName(value: string) {
    this.middleName = value;
  }

  setPlaceBirth(value: string) {
    this.placeBirth = value;
  }

  setDateBirth(value: string) {
    this.birthdate = value;
  }

  setEmail(value: string) {
    this.email = value;
  }

  setGender(gender: IOptionCommon | null) {
    this.gender = gender;
  }

  setPhone(value: string) {
    this.phone = value;
  }

  setPassportSeries(value: string) {
    this.passportSeries = value;
  }

  setPassportNumber(value: string) {
    this.passportNumber = value;
  }

  setPassportCode(value: string) {
    this.passportSubdivisionCode = value;
  }

  setPassportIssued(value: string) {
    this.passportIssued = value;
  }

  setPassportDateIssue(value: string) {
    this.passportDateIssue = value;
  }

  setPassportAddressRegister(value: string) {
    this.passportAddressRegister = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsLoadingDelete(value: boolean) {
    this.isLoadingDelete = value;
  }

  setIsLoadingSave(value: boolean) {
    this.isLoadingSave = value;
  }

  setChosenAvatar(value: File) {
    this.storeUser.chosenAvatar = value;
  }

  setHintStringsAddress(value: string[]) {
    this.hintStringsAddress = value;
  }

  setHintStringsPlaceBirth(value: string[]) {
    this.hintStringsPlaceBirth = value;
  }
}
