import { RefObject, useEffect, useMemo, useState } from "react";

// виден ли элемент на странице
export default function useIsVisible(ref: RefObject<HTMLElement>) {
  const [isVisible, setIsVisible] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }),
    [ref]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [observer, ref]);

  return isVisible;
}
