import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { Store } from "../../../../stores/types";
import { IStoreUI } from "./interfaces";

import { SCREENS } from "../../../../navigation/endpoints";

export class StoreUI implements IStoreUI {
  storeApartmentPlan: Store.IApartmentPlan;
  storeRoot: Store.IRootStore;
  storeModals: Store.IModals;
  navigate: NavigateFunction;

  isVisiblePhoto: boolean = false;
  isLoading: boolean = false;

  constructor(storeRoot: Store.IRootStore, navigate: NavigateFunction) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.storeRoot = storeRoot;
    this.storeModals = storeRoot.storeModals;
    this.storeApartmentPlan = storeRoot.storeApartmentPlan;
    this.navigate = navigate;
  }

  // getters
  get apartmentsPlanInfo() {
    return this.storeApartmentPlan.apartmentPlanInfo;
  }

  get isApartmentModeToPlan() {
    return this.storeModals.isApartmentModeToPlan;
  }

  get callbackPlan() {
    return this.storeModals.planCallback;
  }

  // functions
  *init() {
    this.setIsLoading(true);
    let _id = this.storeModals.visibleApartmentPlanId;

    if (_id) {
      yield this.storeApartmentPlan.getApartmentPlan(_id);
    }

    this.setIsLoading(false);
  }

  onBook(id: number) {
    if (this.isApartmentModeToPlan) {
      this.callbackPlan();
      this.closePopup();
    } else {
      this.toApartment(id);
    }
  }

  toApartment(id: number) {
    this.navigate(`${SCREENS.SCREEN_APARTMENTS}/${id}`);
    this.closePopup();
  }

  closePopup() {
    this.storeModals.closeApartmentPlan();
    this.storeApartmentPlan.clearInfo();
  }

  openPhotoPopup(pointId: number) {
    this.setIsVisiblePhoto(true);
  }

  closePhotoPopup() {
    this.setIsVisiblePhoto(false);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsVisiblePhoto(value: boolean) {
    this.isVisiblePhoto = value;
  }
}
