import { observer } from "mobx-react-lite";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { HistoryCard } from "./HistoryCard";
import { Loader } from "../../../../components/Loader";

import { EFinanceItemType, EHistotyCard } from "../../../../types";

import { StoreUI } from "./storeUI";

import { RootContext } from "../../../../stores/Root";

import styles from "./styles/finance.module.scss";

export const Finance = observer(() => {
  const navigate = useNavigate();
  const rootStore = useContext(RootContext);
  const [storeUI] = useState(() => new StoreUI(navigate, rootStore));

  useEffect(() => {
    (async () => {
      await storeUI.init();
    })();
  }, [storeUI]);

  return (
    <div className={styles.finance}>
      <h2 className={styles.finance__title}>Финансы</h2>

      {storeUI.isLoading ? (
        <div className={styles.finance__loadingBlock}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.finance__topCardsWrapper}>
            <div className={styles.finance__topCard}>
              <span className={styles.finance__topCardTitle}>Баланс</span>
              <span className={styles.finance__topCardValue}>
                {storeUI.balance} ₽
              </span>
            </div>

            <div className={styles.finance__topCard}>
              <span className={styles.finance__topCardTitle}>Депозит</span>
              <span className={styles.finance__topCardValue}>
                {storeUI.deposit} ₽
              </span>
            </div>
          </div>

          <div className={styles.finance__historyCardsWrapper}>
            {storeUI.financeItems.map((item, index) => {
              switch (item.type) {
                case EFinanceItemType.Credit:
                  return (
                    <HistoryCard
                      key={index}
                      billId={item.billId}
                      typeCard={EHistotyCard.Accrual}
                      price={item.sum}
                      numberDocument={item.number}
                      status={item.status}
                      dateFrom={item.dateFrom}
                      dateTo={item.dateTo}
                      onClickPay={() => storeUI.onPay(item.billId)}
                      isPaid={item.isPaid}
                    />
                  );

                case EFinanceItemType.Debit:
                  return (
                    <HistoryCard
                      key={index}
                      typeCard={EHistotyCard.Payment}
                      price={item.sum}
                      isPaid={item.isPaid}
                    />
                  );

                default:
                  return "";
              }
            })}
          </div>
        </>
      )}
    </div>
  );
});
