import { makeAutoObservable } from "mobx";

import { disableScroll, enableScroll } from "../../helpers/modalsFunc";

import { ChoosePay } from "./choosePay";

import { Store } from "../types";

export class Modals implements Store.IModals {
  storeChoosePay: Store.IChoosePay;
  storeRoot: Store.IRootStore;
  visibleApartmentPlan: boolean = false;
  visibleApartmentPlanId: number | null = null;
  isApartmentModeToPlan: boolean = false;
  planCallback: Function = () => {};

  visibleLeaveRequest: boolean = false;

  // координата Y, до которой будет скроллиться страница после закрытия попапа
  // плана квартиры, если при нажатии на Забронировать в попапе
  // в блоке Бронирование появилась ошибка
  coordinateScrollYAfterClose: number | null = null;

  constructor(storeRoot: Store.IRootStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeChoosePay = new ChoosePay(
      storeRoot.storePayment,
      storeRoot.storeDocuments,
      storeRoot.userStore
    );
    this.storeRoot = storeRoot;
  }

  // functions
  openApartmentPlan(id: number) {
    this.setIsApartmentModeToPlan(false);
    if (id !== 0 && id !== null) {
      this.setVisibleApartmentPlan(true);
      this.setVisibleapartmentPlanId(id);
      disableScroll();
    }
  }

  openApartmentPlanFlatMode(
    id: number,
    isApartmentMode: boolean = false,
    callback: Function = () => {},
    coordinateScrollYAfterClose: number | null = null
  ) {
    if (id !== 0 && id !== null) {
      this.setVisibleApartmentPlan(true);
      this.setVisibleapartmentPlanId(id);

      if (isApartmentMode) {
        this.setIsApartmentModeToPlan(isApartmentMode);
        this.setPlanCallback(callback);

        let _coordinateY =
          typeof coordinateScrollYAfterClose === "number" &&
          coordinateScrollYAfterClose >= 0
            ? coordinateScrollYAfterClose
            : null;
        this.setСoordinateScrollYAfterClose(_coordinateY);
      }
      disableScroll();
    }
  }

  closeApartmentPlan() {
    this.setVisibleApartmentPlan(false);
    this.setVisibleapartmentPlanId(null);
    enableScroll();

    if (
      this.isApartmentModeToPlan &&
      this.storeRoot.isBookingAddBasketError &&
      typeof this.coordinateScrollYAfterClose === "number"
    ) {
      window.scrollTo({
        top: this.coordinateScrollYAfterClose,
        behavior: "smooth",
      });
    }
  }

  openLeaveRequest() {
    this.setVisibleLeaveRequest(true);
  }

  closeLeaveRequest() {
    this.setVisibleLeaveRequest(false);
  }

  // setters
  setVisibleApartmentPlan(value: boolean) {
    this.visibleApartmentPlan = value;
  }

  setVisibleapartmentPlanId(value: number | null) {
    this.visibleApartmentPlanId = value;
  }

  setIsApartmentModeToPlan(value: boolean) {
    this.isApartmentModeToPlan = value;
  }

  setPlanCallback(value: Function) {
    this.planCallback = value;
  }

  setVisibleLeaveRequest(value: boolean) {
    this.visibleLeaveRequest = value;
  }

  setСoordinateScrollYAfterClose(value: number | null) {
    this.coordinateScrollYAfterClose = value;
  }
}
