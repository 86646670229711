import { FC } from "react";

import { ReactComponent as Back } from "../../assets/images/backTo.svg";

import styles from "./styles/backto.module.scss";

interface IProps {
  text: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

export const BackTo: FC<IProps> = ({
  text = "",
  onClick = () => {},
  className = "",
  disabled = false,
}) => {
  const _onClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div className={`${styles.navigate} ${className}`}>
      <Back className={styles.navigate__button} onClick={_onClick} />
      <p className={styles.navigate__text}>{text}</p>
    </div>
  );
};
