import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router-dom";

import { StorePasswordRecovery } from "./Authetication/passwordRecovery";
import { StoreAuthorization } from "./Authetication/authorization";
import { StoreRegistration } from "./Authetication/registration";
import { ACCESS_TOKEN } from "./../../constants/index";
import { ApiManager } from "../../api";

import { EStepAuth } from "../../types/StoreAuthentication";
import { Store } from "../types";

import { disableScroll, enableScroll } from "../../helpers/modalsFunc";

import { SCREENS } from "../../navigation/endpoints";

export class StoreAuthentication implements Store.IStoreAuthentication {
  registrationStoreUI: StoreRegistration;
  authorizationStore: StoreAuthorization;
  passwordRecoveryStoreUI: StorePasswordRecovery;

  stepAuth: EStepAuth = EStepAuth.Authorization;
  isVisible: boolean = false;

  isAuth: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.registrationStoreUI = new StoreRegistration(this);
    this.passwordRecoveryStoreUI = new StorePasswordRecovery(this);
    this.authorizationStore = new StoreAuthorization(this);
  }

  init() {
    this.setIsAuth(this.checkIsAuth());
  }

  *logout(navigate: NavigateFunction) {
    let isLogout: boolean = yield this.authorizationStore.logout();
    if (isLogout) {
      this.setIsAuth(false);
      navigate(SCREENS.SCREEN_MAIN);
    }
  }

  hardLogout(navigate: NavigateFunction) {
    // выход из аккаунта без запроса на бэк (при удаленном аккаунте на одном устройстве)
    this.deleteToken();
    this.setIsAuth(false);
    navigate(SCREENS.SCREEN_MAIN);
  }

  openAuthModal() {
    this.isVisible = true;
    disableScroll();
  }

  deleteToken() {
    ApiManager.deleteToken();
  }

  closeAuthModal() {
    this.isVisible = false;
    this.setStepAuth(EStepAuth.Authorization);
    this.registrationStoreUI.resetModal();
    this.passwordRecoveryStoreUI.resetModal();
    this.authorizationStore.clearValues();
    enableScroll();
  }

  checkIsAuth() {
    return localStorage.getItem(ACCESS_TOKEN) ? true : false;
  }

  setStepAuth(value: EStepAuth) {
    this.stepAuth = value;
  }

  setIsAuth(value: boolean) {
    this.isAuth = value;
  }
}
