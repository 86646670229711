import { ApiManager } from "..";
import { TIMEOUT_CREATE_BILL } from "../../constants";

import {
  BOOKING_SERVICES,
  BOOKING_SERVICES_AVAILABLE,
  BOOKING_SERVICE_CREATE_BILL,
  BOOKING_SERVICE_DELETE,
} from "../../constants/api";

import { ICommonResponse, IPaginateParamsCommon } from "../../interfaces";
import {
  IAccountAddServiceParams,
  IAccountChangeServiceParams,
  IAccountService,
  IAccountServiceAvailable,
  IAccountServicesParams,
} from "../../interfaces/AccountService";
import { IBillByServices } from "../../interfaces/Documents";

import { TResponseApi } from "../types";

// получение услуг (выбранные / мои)
export async function getAccountServices(
  bookingId: string,
  params: IAccountServicesParams
): Promise<TResponseApi<IAccountService>> {
  return await ApiManager.request<IAccountService>(
    {
      url: BOOKING_SERVICES(bookingId),
      method: "GET",
      params: params,
    },
    true
  );
}

// получение доступных услуг
export async function getAccountAvailableServices(
  bookingId: string,
  params: IPaginateParamsCommon
): Promise<TResponseApi<IAccountServiceAvailable>> {
  return await ApiManager.request<IAccountServiceAvailable>(
    {
      url: BOOKING_SERVICES_AVAILABLE(bookingId),
      method: "GET",
      params: params,
    },
    true
  );
}

// добавление  услуги
export async function addAccountService(
  bookingId: string,
  params: IAccountAddServiceParams
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BOOKING_SERVICES(bookingId),
      method: "POST",
      data: params,
    },
    true
  );
}

// изменение услуги (прим. при выборе незаданной даты)
export async function changeAccountService(
  bookingId: string,
  params: IAccountChangeServiceParams
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BOOKING_SERVICES(bookingId),
      method: "PUT",
      data: params,
    },
    true
  );
}

// удаление услуги из выбранных
export async function deleteAccountService(
  bookingId: string,
  serviceId: string
): Promise<TResponseApi<ICommonResponse>> {
  return await ApiManager.request<ICommonResponse>(
    {
      url: BOOKING_SERVICE_DELETE(bookingId, serviceId),
      method: "DELETE",
    },
    true
  );
}

// создать счета на выбранные услуги
export async function createBillByServices(
  bookingId: string
): Promise<TResponseApi<IBillByServices>> {
  return await ApiManager.request<IBillByServices>(
    {
      url: BOOKING_SERVICE_CREATE_BILL(bookingId),
      method: "POST",
      timeout: TIMEOUT_CREATE_BILL,
    },
    true
  );
}
