import { ApiManager } from "..";

import { PAGE_DESCRIPTION } from "../../constants/api";

import { IPageDescription } from "../../interfaces/PageDescription";

import { TResponseApi } from "../types";

export async function getPagesDescription(): Promise<
  TResponseApi<IPageDescription[]>
> {
  return await ApiManager.request<IPageDescription[]>(
    {
      url: PAGE_DESCRIPTION,
      method: "GET",
    },
    true
  );
}
