import { FC } from "react";
import { observer } from "mobx-react-lite";

import filter from "../../../../assets/images/filter.svg";

import styles from "./styles/filterbtn.module.scss";

interface IProps {
  count: number;
  onClick: () => void;
  className?: string;
}

export const FilterBtn: FC<IProps> = observer(
  ({ count, onClick = () => {}, className = "" }) => {
    return (
      <div>
        <div className={`${styles.button} ${className}`} onClick={onClick}>
          <img src={filter} alt="filter" />
        </div>
        <div className={styles.button__count}>{count}</div>
      </div>
    );
  }
);
