//------------------------- TYPES ----
export type TTextPage = {
  code: string;
  id: number;
  text: string;
  title: string;
};

export type TDatePicker = "info" | "book";

export type TDateMinMax = "now" | "disable" | Date | null;

export type TStatusBooking = "paid" | "completed";

// варианты для селекта пола
export type TGender = "male" | "female" | null;

// тип аккаунта
export type TPersonalType = "individual" | "legal";

// статус квартиры свободна/занята при проверке в корзине
export type TStatusAvailability = "busy" | "free";

// для кнопки Добавить жильца
export type TAddPersonBtnTneme = "white" | "gold";

// для основной кнопки
export type TButtonTheme = "white" | "gold" | "transparent";
export type TButtonType = "button" | "submit" | "reset";

// для информационного попапа (1 или 2 кнопки)
export type TButtonsModalReuse = "button" | "buttons";

// тип плашки в карточке квартиры
export type TFlatInfoItem = "square" | "people" | "pets";

export type CallbackFunction = () => void;

// id для табов в ЛК Жильцы
/* export type TPersonalTenantsTabsId = 10 | 20 | 30; */

//--------------------------- ENUMS -----

export enum ESort {
  PriceGrow = "price_grow",
  PriceDown = "price_down",
}

export enum EContact {
  Address = "address",
  Phone = "phone",
}

// типы этажей - 8/10 квартирные
export enum EFloorPlan {
  NoApartments = "no_apartments",
  EightApartments = "eight_apartments",
  EightApartmentsSpace = "eight_apartments_space",
  TenApartments = "ten_apartments",
}

// тип запроса отправки кода для регистрации / восстановления пароля
export enum EMethodSend {
  Call = "call",
  Sms = "sms",
}

// тип формы для оформления аренды (форма для арендатора / форма для жильца)
export enum EFormType {
  Renter = "renter",
  Tenant = "tenant",
}

// шаги при оформлении заказа
export enum EStepOrder {
  Renter = "renter",
  Tenants = "tenants",
  Pets = "pets",
  ArrivalDate = "arrivalDate",
}

// статусы Договора в ЛК
export enum EAgreementStatus {
  New = "new",
  Terminated = "terminated",
  Active = "active",
}

export enum EStatusAvailability {
  Busy = "busy",
  Free = "free",
}

// табы для ЛК / мои брони
export enum ETabsMyBookings {
  Rent = "rent",
  Documents = "documents",
  Residents = "residents",
  Services = "services",
}

// табы в ЛК / жильцы
export enum ETabsResidents {
  Renter = "renter",
  Tenants = "tenants",
  Pets = "pets",
}

// табы в ЛК / документы
export enum ETabsDocuments {
  Agreements = "agreements",
  Bills = "bills",
  Acts = "acts",
  Others = "others",
}

// табы в ЛК / услуги
export enum ETabsServices {
  Selected = "selected",
  MyServices = "myServices",
  Available = "available",
}

export enum EBookingType {
  Active = "active",
  Archive = "archive",
}

// статус депозита при продлении проживания
export enum EDepositStatus {
  Pay = "pay", // если клиент должен доплатить
  Give = "give", // если клиенту должны вернуть деньги
  Nothing = "nothing", // если ничего никто не доплачивает
}

// статус Услуги в ЛК
export enum EServiceStatus {
  Paid = "paid",
  Waiting = "waiting",
}

// тип карточки истории в ЛК Финансы
export enum EHistotyCard {
  Accrual = "accrual",
  Payment = "payment",
}

// статус Booking Calendar в ЛК
export enum EBookingCalendarStatus {
  Paid = "paid",
  Waiting = "waiting",
}

// тип финансовой операции
export enum EFinanceItemType {
  Credit = "credit",
  Debit = "debit",
}

export enum EFinanceItemStatus {
  Unpaid = "unpaid",
  Paid = "paid",
  Empty = "",
  PartiallyPaid = "partially paid",
}

export enum EStatusBill {
  Paid = "paid",
  NotPaid = "not paid",
  ParticallyPaid = "partially paid",
}

export enum EInputsNames {
  LastName = "lastName",
  FirstName = "firstName",
  MiddleName = "middleName",
  Email = "email",
  Birthdate = "birthdate",
  PlaceBirth = "placeBirth",
  Phone = "phone",
  Gender = "gender",
  PassportSeries = "passportSeries",
  PassportNumber = "passportNumber",
  PassportDateIssue = "passportDateIssue",
  PassportIssued = "passportIssued",
  PassportAddressRegister = "passportAddressRegister",
  PassportSubdivisionCode = "passportSubdivisionCode",
}

export enum EPaymentMethods {
  SberBusiness = "sberBusiness",
  YooKassa = "yooKassa",
  Bill = "bill",
}

export enum EChoosePayType {
  Pawn = "pawn",
  Base = "base",
  WithPdf = "withPdf",
}

export enum EServiceCode {
  Parking = "parking",
}

export enum EStatusBooking {
  Paid = "paid",
  ReCreate = "reCreate",
  Waiting = "waiting",
}

export enum ETypeBill {
  Rent = "Rent",
  Deposit = "Deposit",
  Booking = "Booking",
}

export enum EPageType {
  Main = "main",
  Apartments = "apartments",
  Apartment = "apartment",
  Basket = "basket",
  Contacts = "contacts",
  Faq = "faq",
  Floor = "floor",
  News = "news",
  NewItem = "newItem",
  NotFound = "notFound",
  Partners = "partners",
  Personal = "personal",
  Services = "services",
  PaymentResult = "paymentResult",
  Booking = "booking",
  OrderingData = "orderingData",
}

export enum EParamsSberResult {
  Code = "code",
  State = "state",
  Nonce = "nonce",
}

export type TParsedContent = string | JSX.Element | JSX.Element[];
