import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Act } from "./Act";
import { PopupActRevise } from "../../../../../components/Modals/PopupActRevise";
import { Loader } from "../../../../../components/Loader";
import { ModalReuse } from "../../../../../components/Modals/ModalReuse";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../../../stores/Root";

import styles from "./styles/acts.module.scss";

export interface IProps {
  bookingId: number;
  isArchiveMode?: boolean;
}

export const Acts = observer(
  ({ bookingId = 0, isArchiveMode = false }: IProps) => {
    const rootStore = useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(rootStore));

    useEffect(() => {
      (async () => {
        await storeUI.init(bookingId);
      })();
    }, [storeUI, bookingId]);

    return (
      <div className={styles.acts}>
        {storeUI.isLoading ? (
          <div className={styles.acts__loadingBlock}>
            <Loader className={styles.acts__loader} />
          </div>
        ) : (
          <>
            <div className={styles.acts__simpleActs}>
              {storeUI.acts?.length > 0 ? (
                <div className={styles.acts__cardsContainer}>
                  {storeUI.acts.map((item) => (
                    <Act
                      key={item.documentId}
                      id={item.documentId}
                      actNumber={item.name}
                      dateOfIssue={item.date}
                      onClickShow={storeUI.onShowPdf}
                      onDownload={storeUI.onDownloadPdf}
                      isLoadingDownload={
                        item.documentId === storeUI.currentLoadingId
                      }
                    />
                  ))}
                </div>
              ) : (
                <span className={styles.acts__empty}>
                  {isArchiveMode ? "У вас нет актов" : "У вас пока нет актов"}
                </span>
              )}
            </div>

            <div className={styles.acts__buttonWrapper}>
              <span className={styles.acts__line} />
              <button
                className={styles.acts__reviseButton}
                onClick={storeUI.openActRevisePopup}
              >
                Запросить акт сверки
              </button>
            </div>
          </>
        )}
        <PopupActRevise
          isLoading={storeUI.isLoadingGenerateAct}
          visible={storeUI.isVisibleActRevice}
          dateFrom={storeUI.dateFrom}
          changeDateFrom={storeUI.setDateFrom}
          onContinue={storeUI.sendRequestAct}
          closeModal={storeUI.closeActRevisePopup}
          errorsDateFrom={storeUI.errors.dateFrom}
        />

        <ModalReuse
          isShowModal={storeUI.isVisibleSuccessRevice}
          btnTitle="Хорошо"
          type={"button"}
          setIsShowModal={storeUI.setIsVisibleSuccessRevice}
          onClickSingleButton={storeUI.closeSuccessRevice}
        >
          <h2>Отправлено успешно. Акт сверки появится в актах</h2>
        </ModalReuse>
      </div>
    );
  }
);
