import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { PASSWORD_INFO_TEXT } from "../../../../../constants";

import { IEnterPasswordsStore } from "../../../../../interfaces";

import { SCREENS } from "../../../../../navigation/endpoints";

import { Button } from "../../../../Button";
import { InputPassword } from "../../../../InputPassword";
import { Loader } from "../../../../Loader";

import styles from "./styles/enterPassword.module.scss";

interface IProps {
  storeUI: IEnterPasswordsStore;
  isRegister: boolean;
  checkIsAuth?(): boolean;
}

const EnterPasswords = observer(
  ({ storeUI, checkIsAuth = () => false, isRegister = false }: IProps) => {
    const navigate = useNavigate();

    const _toAccount = () => {
      navigate(SCREENS.SCREEN_ACCOUNT_PERSONAL);
    };

    const _onSignin = async () => {
      await storeUI.onSignin();
      if (await checkIsAuth()) {
        _toAccount();
      }
    };

    return (
      <div className={styles.enterPassword}>
        <p className={styles.enterPassword__desc}>
          Теперь придумайте надежный пароль
        </p>
        {storeUI.isLoading && <Loader />}
        <InputPassword
          label={"Пароль"}
          placeholder={"Пароль"}
          value={storeUI.password}
          onChange={storeUI.changePassword}
          errors={storeUI.errorsPassword}
          maxLength={32}
          infoIconText={PASSWORD_INFO_TEXT}
          className={styles.enterPassword__input}
        />

        <InputPassword
          label={"Подтвердите пароль"}
          placeholder={"Подтвердите пароль"}
          value={storeUI.confirmPassword}
          onChange={storeUI.changeConfirmPassword}
          errors={storeUI.errorsConfirmPassword}
          maxLength={32}
          infoIconText={PASSWORD_INFO_TEXT}
          classNameInput={styles.enterPassword__input}
          className={styles.enterPassword__contentInputPasswordTwo}
        />
        <Button
          className={styles.enterPassword__button}
          isDisabledStyle={
            storeUI.password === "" || storeUI.confirmPassword === ""
          }
          disabled={storeUI.isLoading}
          title="ВОЙТИ"
          theme="gold"
          onClick={isRegister ? _onSignin : storeUI.onSignin}
        />
      </div>
    );
  }
);

export { EnterPasswords };
