import { makeAutoObservable } from "mobx";
import { addDays, format } from "date-fns";

import {
  COUNT_DAYS_FOR_CHECK_IN_DATE,
  ERROR_EMPTY_DATE,
} from "../../../constants";

import { IStoreUI } from "./interfaces";
import { IDatePeriod } from "../../PersonalAccount/pages/Rent/storeUI/interfaces";

import { Store } from "../../../stores/types";

export class ArrivalDateStoreUI implements Store.IArrivalDateOrderingData {
  storeParent: IStoreUI;
  storeModals: Store.IModalsOrderingData;
  storeAdditionalBookingData: Store.IAdditionalBookingData;

  isLoading: boolean = false;
  isVisibleTimeoutPopup: boolean = false;

  selectedDate: Date | null = null;

  validateErrors: string[] = [];

  // период для выбора даты заселения
  datePeriod: IDatePeriod = {
    minDate: null,
    maxDate: null,
  };

  constructor(storeParent: IStoreUI) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.storeParent = storeParent;
    this.storeModals = storeParent.storeModals;
    this.storeAdditionalBookingData = storeParent.storeAdditionalBookingData;
  }

  // getters

  get bookingId() {
    return this.storeParent.bookingId;
  }

  get errors() {
    return this.validateErrors.concat(
      this.storeAdditionalBookingData.errorsArrivalDate
    );
  }

  // functions

  *init() {
    if (this.bookingId) {
      yield this.storeAdditionalBookingData.getArrivalDate(this.bookingId);

      if (this.storeAdditionalBookingData.arrivalDate.length) {
        this.writeArrivalDatePeriod(
          this.storeAdditionalBookingData.arrivalDate
        );
      }
    }
  }

  *sendArrivalDate() {
    if (this.bookingId && this.validateArrivalDate() && this.selectedDate) {
      this.setIsLoading(true);
      let readyDate = format(this.selectedDate, "yyyy-MM-dd");
      yield this.storeAdditionalBookingData.sendArrivalDate(this.bookingId, {
        date: readyDate,
      });

      if (this.storeAdditionalBookingData.isTimeoutEndSendArrivalDate) {
        this.openTimeoutPopup();
      }

      if (this.storeAdditionalBookingData.isSuccessSendArrivalDate) {
        this.storeParent.toMyApartment();
      }

      this.setIsLoading(false);
    }
  }

  toAgreements() {
    this.storeParent.toMyAgreements();
  }

  // ---- helpers

  writeArrivalDatePeriod(minDate: string) {
    if (minDate.length) {
      let _maxDate = addDays(
        new Date(minDate),
        COUNT_DAYS_FOR_CHECK_IN_DATE - 1
      ); // -1 т к добавляет начиная со следующего дня

      this.datePeriod.minDate = new Date(minDate);
      this.datePeriod.maxDate = _maxDate;
    }
  }

  // ---- validation

  validateArrivalDate() {
    if (!this.selectedDate) {
      this.validateErrors = [...this.validateErrors, ERROR_EMPTY_DATE];
      return false;
    } else {
      this.setValidateErrors([]);
    }

    return true;
  }

  // ---- changes
  changeSelectedDate(value: Date | null) {
    this.setSelectedDate(value);

    if (this.validateErrors.length) {
      this.setValidateErrors([]);
    }
  }

  openTimeoutPopup() {
    this.setIsVisibleTimeoutPopup(true);
  }

  // setters

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setSelectedDate(value: Date | null) {
    this.selectedDate = value;
  }

  setValidateErrors(value: string[]) {
    this.validateErrors = value;
  }

  setIsVisibleTimeoutPopup(value: boolean) {
    this.isVisibleTimeoutPopup = value;
  }
}
