export function openPdf(base64: string) {
  const binary = window.atob(base64);
  const len = binary.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; ++i) {
    bytes[i] = binary.charCodeAt(i);
  }

  let blob = new Blob([bytes], { type: "application/pdf" });
  let url = URL.createObjectURL(blob);
  window.open(url, "pdf");
}
