import { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { Input } from "../../../components/Input";
import { InputDate } from "../../../components/InputDate";
import { InputPhone } from "../../../components/InputPhone";
import { Select } from "../../../components/Select";
import { InputWithMask } from "../../../components/InputWithMask";
import { InputWithHint } from "./../../../components/InputWitnHint/index";

import {
  NUMBER_FIRST_NAME_LENGTH,
  NUMBER_LAST_NAME_LENGTH,
  NUMBER_MIDDLE_NAME_LENGTH,
  NUMBER_PASSPORT_ADDRESS_LENGTH,
  NUMBER_PASSPORT_ISSUED_BY_LENGTH,
  NUMBER_PASSPORT_LENGTH,
  NUMBER_PLACE_BIRTH_LENGTH,
  NUMBER_RELATION_TENANT_LENGTH,
  SERIES_BIRTH_CERTIFICATE_LENGTH,
  SERIES_PASSPORT_LENGTH,
} from "../../../constants";

import { EFormType, EInputsNames } from "../../../types";
import { ITypes } from "../../../interfaces/Catalog";

import { ReactComponent as TrashCan } from "../../../assets/images/trashCan.svg";

import styles from "./styles/tenant.module.scss";

export interface IProps {
  typeForm: EFormType;
  isReadonly?: boolean;
  valueLastName: string;
  valueFirstName: string;
  valueMiddleName: string;
  valuePhone: string;
  valueDateBirth: string;
  valueSeries: string;
  valueNumberPassport: string;
  valueDateIssue: string;
  valueIssuedBy: string;
  valueRegistrationAddress: string;
  valuePlace: string;
  errorsLastName: string[];
  errorsFirstName: string[];
  errorsMiddleName: string[];
  errorsPhone: string[];
  errorsDateBirth: string[];
  errorsSeries: string[];
  errorsNumberPassport: string[];
  errorsDateIssue: string[];
  errorsIssuedBy: string[];
  errorsRegistrationAddress: string[];
  errorsPlace: string[];
  hintAddressStrings: string[];
  hintStringsPlaceBirth: string[];
  changeLastName(value: string): void;
  changeFirstName(value: string): void;
  changeMiddleName(value: string): void;
  changePhone(value: string): void;
  changeDateBirth(value: string): void;
  changeSeries(value: string): void;
  changeNumberPassport(value: string): void;
  changeDateIssue(value: string): void;
  changeIssuedBy(value: string): void;
  changeRegistrationAddress(value: string): void;
  changePlace(value: string): void;
  onClickHintAddress(value: string): void;
  onClickHintPlaceBirth(value: string): void;
  onDelete?(): void;
  valueGender?: ITypes | null;
  genderOptions?: ITypes[];
  errorsGender?: string[];
  changeGender?: (value: ITypes) => void;
  valueCode?: string;
  changeCode?(value: string): void;
  errorsCode?: string[];
  valueRelationTenant?: string;
  changeRelationTenant?(value: string): void;
  errorsRelationTenant?: string[];
  valueEmail?: string;
  changeEmail?(value: string): void;
  errorsEmail?: string[];
  className?: string;
  isNoFirstForm?: boolean;
  showTrashCan?: boolean;
  disabledInputs?: EInputsNames[];
}

export const Tenant: FC<IProps> = observer(
  ({
    typeForm = EFormType.Tenant,
    isReadonly = false,
    valueLastName = "",
    valueFirstName = "",
    valueMiddleName = "",
    valuePhone = "",
    valueDateBirth = "",
    valueSeries = "",
    valueNumberPassport = "",
    valueDateIssue = "",
    valueIssuedBy = "",
    valueRegistrationAddress = "",
    valuePlace = "",
    errorsLastName = [],
    errorsFirstName = [],
    errorsMiddleName = [],
    errorsPhone = [],
    errorsDateBirth = [],
    errorsSeries = [],
    errorsNumberPassport = [],
    errorsDateIssue = [],
    errorsIssuedBy = [],
    errorsRegistrationAddress = [],
    errorsPlace = [],
    errorsGender = [],
    hintAddressStrings = [],
    hintStringsPlaceBirth = [],
    changeLastName = () => {},
    changeFirstName = () => {},
    changeMiddleName = () => {},
    changePhone = () => {},
    changeDateBirth = () => {},
    changeSeries = () => {},
    changeNumberPassport = () => {},
    changeDateIssue = () => {},
    changeIssuedBy = () => {},
    changeRegistrationAddress = () => {},
    changePlace = () => {},
    onDelete = () => {},
    onClickHintAddress = () => {},
    onClickHintPlaceBirth = () => {},
    valueGender = null,
    genderOptions = [],
    changeGender = () => {},
    valueCode = "",
    changeCode = () => {},
    errorsCode = [],
    valueRelationTenant = "",
    changeRelationTenant = () => {},
    errorsRelationTenant = [],
    valueEmail = "",
    changeEmail = () => {},
    errorsEmail = [],
    className = "",
    isNoFirstForm = false,
    showTrashCan = true,
    disabledInputs = [],
  }: IProps) => {
    let isRenter = useMemo(() => typeForm === EFormType.Renter, [typeForm]);

    const getIsDisabled = (inputName: EInputsNames) => {
      return isReadonly || disabledInputs.includes(inputName);
    };

    return (
      <div
        className={`${styles.tenant} ${
          typeForm === EFormType.Tenant ? styles.tenant__tenantFormMode : ""
        } ${className}`}
      >
        <div
          className={`${styles.tenant__header} ${
            isNoFirstForm ? styles.tenant__header_noFirstMode : ""
          }`}
        >
          {typeForm === EFormType.Tenant && showTrashCan && (
            <button
              className={styles.tenant__trashCan}
              onClick={onDelete}
              disabled={isReadonly}
            >
              <TrashCan />
            </button>
          )}
        </div>
        <div className={styles.tenant__inputs}>
          <div className={styles.tenant__inputsFirstBlock}>
            <div className={styles.tenant__fioWrapper}>
              <Input
                value={valueLastName}
                onChange={changeLastName}
                errors={errorsLastName}
                className={styles.tenant__lastName}
                label="Фамилия"
                placeholder="Иванов"
                maxLength={NUMBER_LAST_NAME_LENGTH}
                readonly={getIsDisabled(EInputsNames.LastName)}
              />

              <Input
                value={valueFirstName}
                onChange={changeFirstName}
                errors={errorsFirstName}
                className={styles.tenant__firstName}
                label="Имя"
                placeholder="Иван"
                maxLength={NUMBER_FIRST_NAME_LENGTH}
                readonly={getIsDisabled(EInputsNames.FirstName)}
              />

              <Input
                value={valueMiddleName}
                onChange={changeMiddleName}
                errors={errorsMiddleName}
                className={styles.tenant__middleName}
                label="Отчество"
                placeholder="Иванович"
                maxLength={NUMBER_MIDDLE_NAME_LENGTH}
                readonly={getIsDisabled(EInputsNames.MiddleName)}
              />
            </div>
            <div className={styles.tenant__birthPhoneWrapper}>
              <InputDate
                value={valueDateBirth}
                onChange={changeDateBirth}
                label={"Дата рождения"}
                placeholder={"ДД.ММ.ГГГГ"}
                className={styles.tenant__date}
                classNameInput={styles.tenant__dateInput}
                errors={errorsDateBirth}
                readonly={getIsDisabled(EInputsNames.Birthdate)}
              />

              <InputPhone
                value={valuePhone}
                errors={errorsPhone}
                onChange={changePhone}
                label={"Телефон"}
                className={styles.tenant__phone}
                readOnly={getIsDisabled(EInputsNames.Phone)}
              />
            </div>
          </div>

          <div className={styles.tenant__inputsMiddleBlock}>
            <Input
              className={styles.tenant__email}
              label="E-mail"
              value={valueEmail}
              errors={errorsEmail}
              onChange={changeEmail}
              placeholder="example@mail.ru"
              readonly={getIsDisabled(EInputsNames.Email)}
            />

            <InputWithHint
              value={valuePlace}
              onChange={changePlace}
              className={styles.tenant__placeBirth}
              label="Место рождения"
              placeholder="Регион, район, населенный пункт"
              errors={errorsPlace}
              readonly={getIsDisabled(EInputsNames.PlaceBirth)}
              maxLength={NUMBER_PLACE_BIRTH_LENGTH}
              hintStrings={hintStringsPlaceBirth}
              onClickHint={onClickHintPlaceBirth}
            />

            <div className={styles.tenant__genderPassportWrapper}>
              <Select
                placeholder={"М"}
                label={"Пол"}
                className={styles.tenant__gender}
                value={valueGender}
                onChange={changeGender}
                options={genderOptions}
                isGenderMode={true}
                errors={errorsGender}
                disabled={getIsDisabled(EInputsNames.Gender)}
              />

              <Input
                value={valueSeries}
                onChange={changeSeries}
                className={`${styles.tenant__serial} ${
                  typeForm === EFormType.Tenant
                    ? styles.tenant__serial_tenantMode
                    : ""
                }`}
                label="Серия"
                placeholder={isRenter ? "0000" : "000000"}
                maxLength={
                  isRenter
                    ? SERIES_PASSPORT_LENGTH
                    : SERIES_BIRTH_CERTIFICATE_LENGTH
                }
                errors={errorsSeries}
                readonly={getIsDisabled(EInputsNames.PassportSeries)}
              />
              <Input
                value={valueNumberPassport}
                onChange={changeNumberPassport}
                className={styles.tenant__passportNumber}
                label="Номер документа"
                placeholder="000000"
                maxLength={NUMBER_PASSPORT_LENGTH}
                errors={errorsNumberPassport}
                readonly={getIsDisabled(EInputsNames.PassportNumber)}
              />

              <InputDate
                value={valueDateIssue}
                onChange={changeDateIssue}
                label={"Дата выдачи"}
                placeholder={"ДД.ММ.ГГГГ"}
                className={styles.tenant__dateOfIssue}
                classNameInput={styles.tenant__dateOfIssueInput}
                errors={errorsDateIssue}
                readonly={getIsDisabled(EInputsNames.PassportDateIssue)}
              />
            </div>
          </div>

          <div className={styles.tenant__inputsLastBlock}>
            <Input
              value={valueIssuedBy}
              onChange={changeIssuedBy}
              className={styles.tenant__issuedBy}
              label="Кем выдан"
              placeholder="ТП УФМС России"
              maxLength={NUMBER_PASSPORT_ISSUED_BY_LENGTH}
              errors={errorsIssuedBy}
              readonly={getIsDisabled(EInputsNames.PassportIssued)}
            />

            <div className={styles.tenant__codeAddressAttitudeWrapper}>
              {isRenter && (
                <InputWithMask
                  mask={"999-999"}
                  label="Код подразделения"
                  placeholder="000-000"
                  value={valueCode}
                  onChange={changeCode}
                  errors={errorsCode}
                  className={styles.tenant__divisionCode}
                  readOnly={getIsDisabled(EInputsNames.PassportSubdivisionCode)}
                />
              )}

              <InputWithHint
                value={valueRegistrationAddress}
                onChange={changeRegistrationAddress}
                className={styles.tenant__registrationAddress}
                label="Адрес регистрации"
                placeholder="Город, улица, дом, квартира"
                maxLength={NUMBER_PASSPORT_ADDRESS_LENGTH}
                errors={errorsRegistrationAddress}
                readonly={getIsDisabled(EInputsNames.PassportAddressRegister)}
                hintStrings={hintAddressStrings}
                onClickHint={onClickHintAddress}
              />

              {typeForm === EFormType.Tenant && (
                <Input
                  value={valueRelationTenant}
                  onChange={changeRelationTenant}
                  className={styles.tenant__attitude}
                  label="Отношение к арендатору"
                  placeholder="Жена"
                  errors={errorsRelationTenant}
                  readonly={isReadonly}
                  maxLength={NUMBER_RELATION_TENANT_LENGTH}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
