import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Bill } from "./Bill";
import { Loader } from "../../../../../components/Loader";
import { ModalReuse } from "../../../../../components/Modals/ModalReuse";

import { StoreUI } from "./storeUI";
import { RootContext } from "../../../../../stores/Root";

import { PAYMENT_EXIST_POPUP_TEXT } from "../../../../../constants";

import styles from "./styles/bills.module.scss";

interface IProps {
  bookingId: number;
  isArchiveMode?: boolean;
}

export const Bills = observer(
  ({ bookingId = 0, isArchiveMode = false }: IProps) => {
    const rootStore = useContext(RootContext);
    const [storeUI] = useState(() => new StoreUI(rootStore));

    useEffect(() => {
      (async () => {
        await storeUI.init(bookingId);
      })();
    }, [storeUI, bookingId]);

    return (
      <div className={styles.content}>
        {storeUI.isLoading ? (
          <div className={styles.content__agreementsEmpty}>
            <Loader className={styles.content__loader} />
          </div>
        ) : (
          <>
            {storeUI.bills?.length > 0 ? (
              <div className={styles.content__bills}>
                {storeUI.bills?.map((bill) => (
                  <Bill
                    key={bill.id}
                    id={bill.id}
                    documentId={bill.documentId}
                    status={bill.status}
                    disablePaidButton={bill.disablePaid}
                    readyStatus={bill.readyStatus}
                    number={bill.number}
                    date={bill.date}
                    dateEnd={bill.endDate}
                    price={bill.amount}
                    onDownload={storeUI.onDownloadPdf}
                    onShow={storeUI.onShowPdf}
                    onPaid={() => storeUI.onClickPay(bill.id, bill.documentId)}
                    isLoadingDownload={
                      bill.documentId === storeUI.currentLoadingId
                    }
                    isArchiveMode={isArchiveMode}
                  />
                ))}
              </div>
            ) : (
              <span className={styles.content__empty}>
                {isArchiveMode ? "У вас нет счетов" : "У вас пока нет счетов"}
              </span>
            )}
          </>
        )}

        <ModalReuse
          isShowModal={storeUI.isVisiblePopupExist}
          setIsShowModal={storeUI.setIsVisiblePopupExist}
          type={"button"}
          btnTitle={"Продолжить"}
          classNameModal={styles.content__modalReuse}
          onClickSingleButton={storeUI.closePopupExist}
        >
          <p className={styles.content__modalReuseTitle}>
            {PAYMENT_EXIST_POPUP_TEXT}
          </p>
        </ModalReuse>
      </div>
    );
  }
);
